import React, { useEffect, useRef, useState } from "react";
import SchoolProfileForm from "./SchoolProfileForm";
import SchoolPackageImg from "../../../images/school_package.png";
import { useParams } from "react-router-dom";
import axios from "../../api";
import NewSchoolProfile from "./NewSchoolProfile";

function FillSchoolProfileForm() {
  const schoolFormRef = useRef(null);
  const params = useParams();
  const [schoolPkg, setSchoolPkg] = useState();
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(false);
  useEffect(() => {
    axios
      .get("api/package", { params: { slug: params.slug } })
      .then((res) => {
        setSchoolPkg(res.data);
      })
      .catch((err) => {
        setErr(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const showText = (text) => {
    if (loading) {
      return (
        <div className="typing-animation">
          <div className="dot" style={{ "--delay": "200ms" }}></div>
          <div className="dot" style={{ "--delay": "300ms" }}></div>
          <div className="dot" style={{ "--delay": "400ms" }}></div>
        </div>
      );
    }
    return text;
  };
  return (
    <div>
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="d-flex flex-column gap-3">
              <div>
                <h4 className="fs-3 fw-bolder lh-sm">
                  Secure Your Child's Health: School Health Package
                </h4>
              </div>
              <div>
                <span className="fs-5 fw-light">
                  Our school health package offers the ultimate preventive
                  healthcare solution for your child. With regular check-ups,
                  screenings, and expert care, we prioritize your child's
                  well-being. Invest in their health today for a brighter,
                  healthier future.
                </span>
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    schoolFormRef.current.scrollIntoView({ block: "start" })
                  }
                >
                  <span className="fs-6">Join Now</span>
                </button>
              </div>
              <div className="mt-5 mb-5 mb-lg-0">
                <div className="row g-2">
                  <div className="col-md-4 d-flex gap-1">
                    <div className="d-flex flex-column gap-2 pe-4 py-1 w-100">
                      <div className="text-center">
                        <span className="text-muted">Registration Fee</span>
                      </div>
                      <div className="text-center">
                        <span className="fs-5 fw-bold">
                          {showText(`Rs. ${schoolPkg?.registration_fee}/-`)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex gap-1 px-4 border border-2 border-top-0 border-bottom-0 border-x py-1">
                    <div className="d-flex flex-column gap-2 w-100">
                      <div className="text-center">
                        <span className="text-muted">Number of Visits</span>
                      </div>
                      <div className="text-center">
                        <span className="fs-5 fw-bold">
                          {showText(`${schoolPkg?.visits} times/year`)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex gap-1 ps-4 py-1">
                    <div className="d-flex flex-column gap-2 w-100">
                      <div className="text-center">
                        <span className="text-muted">Monthly Fee</span>
                      </div>
                      <div className="text-center">
                        <span className="fs-5 fw-bold">
                          {showText(`Rs. ${schoolPkg?.monthly_fee} per person`)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="d-flex justify-content-end">
              <img src={SchoolPackageImg} alt="" style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="py-5" ref={schoolFormRef}>
        <div className="container">
          <NewSchoolProfile />
        </div>
      </div>
    </div>
  );
}

export default FillSchoolProfileForm;
