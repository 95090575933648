import {Box, Grid} from '@mui/material'
import React from 'react'
import ProfileInfo from './components/PackageBookInfo'
import BookingInfomrations from './components/BookingInfomrations'
import BookingDetails from './components/BookingDetails'

function PackageBookingPage() {
    return (
        <div className='package-booking'>
            <div className="container">
                <Grid container>
                    <Grid item xs={3}>
                        <ProfileInfo/>
                    </Grid>
                    <Grid item xs={4.5}>
                        <BookingInfomrations/>
                    </Grid>
                    <Grid item xs={4.5}>
                        <BookingDetails/>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default PackageBookingPage
