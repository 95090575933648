import http from "../utils/http/http.utils";

class TermsAndConditionService {
  /**
   * Uploads a vaccination document.
   *
   * @return {Promise} A promise that resolves to the result of the upload.
   * @param params
   */

  static async get() {
    try {
      const response = await http().get(
        `/api/terms-conditions?type=footer-terms-conditions`
      );
      console.log(response, "res");
      console.log(";inside");

      return response;
    } catch (e) {
      console.log(e);
    }
  }
}

export default TermsAndConditionService;
