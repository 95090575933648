import React from "react";
import { Link } from "react-router-dom";
import axios from "../../api";
import "./PackagePage.scss";
import PackageItem from "./PackageItem";
import Loader from "../Loader";
import PackageSkeleton from "./components/PackageSkeleton";

function PackagePage() {
  const [pack, setPack] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    axios
      .get("api/package")
      .then((res) => {
        setPack(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <PackageSkeleton />;
  }

  return (
    <div className="package-page">
      <div className="container">
        <div className="pt-5 text-center package-header">
          <h1>Ready to get started with</h1>
          <h1> Ghargharma Doctor</h1>
          <h4>Choose a plan tailored to your needs</h4>
        </div>

        <div className="mb-4 d-flex w-100 justify-content-end">
          <Link to="/package-compare" className="btn btn-primary">
            Compare Package
          </Link>
        </div>

        <div className="packages-list">
          <div className="pb-5 row g-3">
            {pack
              .filter((pg) => pg.id === 10 || pg.id === 11)
              .map((pg) => {
                console.log(pg, ";pg");
                return (
                  <div className="col-12 col-md-6 " key={pg.id}>
                    <PackageItem data={pg} />
                  </div>
                );
              })}
          </div>
          {/* <div className="py-5 row g-3">
                        <div className="col-12 col-md-6 col-lg-4">
                            <PackageItem />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <PackageItem className="center-package" />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <PackageItem />
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
}

export default PackagePage;
