import { Document, Image, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
    page: {
        // backgroundColor: 'red',
        color: 'black',
        fontSize: '12px',
    },
    viewer: {
        width: '100%',
        height: window.innerHeight,
    },
    section: {
        padding: 30,
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    topInfo: {
        marginBottom: '10px',
    },
    textHighlight: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    logoStyle: {
        height: '120px'
    },
    toStyle: {
        backgroundColor: '#F4F4F4',
    },
    table: {
        display: 'table',
        width: '100%',
        // borderStyle: 'solid',
        // borderWidth: 1,
        borderColor: '#bfbfbf',
    },
    tableRow: {
        width: '100%',
        margin: 'auto',
        flexDirection: 'row',
    },
    tableCell: {
        // width: '25%',
        textAlign: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        padding: 5
    },
    tableHeader: {
        backgroundColor: '#0D59A7',
        color: 'white',
    },
    tableGrid: {
        width: '100%',
        gridTemplateColumn: '1fr 3fr 2fr 3fr 3fr',
    },
    snItem: {
        width: '8%',
    },
    otherItems: {
        width: '23%',
    },
    colHide: {
        border: 'none'
    },
    paidStatus: {
        backgroundColor:'#0DA826',
        color: 'white',
        padding: '16px 30px',
        marginLeft:'auto',
        marginRight: 30,
    },
    payText: {
        fontSize: '35px',
        fontWeight:'800'
    }
})

function InvoiceTemplate() {
    return (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.flex}>
                        <View style={styles.paidStatus}>
                            <Text style={styles.payText}>PAID</Text>
                        </View>
                    </View>
                    <View style={styles.section}>
                        <View style={styles.flex}>
                            <View>
                                <Image
                                    src='http://localhost:3000/static/media/blue-logo.e8a3b3f1000e4d7812ed.png'
                                    style={styles.logoStyle}
                                />
                            </View>
                            <View>
                                <Text style={{ ...styles.topInfo, ...styles.textHighlight }}>Ghargharma Doctor Pvt. Ltd.</Text>
                                <Text style={styles.topInfo}>VAT No: 606601586</Text>
                                <Text style={styles.topInfo}>Phone: 01-5917322</Text>
                                <Text style={styles.topInfo}>Email:info@ghargharmadoctor.com</Text>
                                <Text style={styles.topInfo}>Web:www.ghargharmadoctor.com</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ ...styles.section, ...styles.toStyle }}>
                        <Text style={{ ...styles.topInfo, ...styles.textHighlight }}>
                            Invoice To:
                        </Text>
                        <Text style={styles.topInfo}>Invoice No.: #50vn7lit</Text>
                        <Text style={styles.topInfo}>Order Date: 12/02/2023</Text>
                        <Text style={styles.topInfo}>Email: sandesh.shrestha407@gmail.com</Text>
                        <Text style={styles.topInfo}>ATTN: Sandesh Shrestha</Text>
                        <Text style={styles.topInfo}>Address: Kathmandu, Nepal</Text>
                    </View>
                    <View style={styles.section}>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={{ ...styles.tableCell, ...styles.tableHeader, ...styles.snItem }}>
                                    <Text>S.N.</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.tableHeader, ...styles.otherItems }}>
                                    <Text>Service Type</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.tableHeader, ...styles.otherItems }}>
                                    <Text>Family Size</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.tableHeader, ...styles.otherItems }}>
                                    <Text>Payment Interval</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.tableHeader, ...styles.otherItems }}>
                                    <Text>Total Amount</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{ ...styles.tableCell, ...styles.snItem }}>
                                    <Text>1</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>Gold Package</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>3</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>1 year</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>600</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{ ...styles.tableCell, ...styles.snItem }}>
                                    <Text>1</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>Gold Package</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>3</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>1 year</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>600</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{ ...styles.tableCell, ...styles.snItem, ...styles.colHide }} />
                                <View style={{ ...styles.tableCell, ...styles.otherItems, ...styles.colHide }} />
                                <View style={{ ...styles.tableCell, ...styles.otherItems, ...styles.colHide }} />
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>Sub Total</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>Rs. 1200</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{ ...styles.tableCell, ...styles.snItem, ...styles.colHide }} />
                                <View style={{ ...styles.tableCell, ...styles.otherItems, ...styles.colHide }} />
                                <View style={{ ...styles.tableCell, ...styles.otherItems, ...styles.colHide }} />
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>VAT (13%)</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>Rs. 1356</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{ ...styles.tableCell, ...styles.snItem, ...styles.colHide }} />
                                <View style={{ ...styles.tableCell, ...styles.otherItems, ...styles.colHide }} />
                                <View style={{ ...styles.tableCell, ...styles.otherItems, ...styles.colHide }} />
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>Discount Amount</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>Rs. 200</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{ ...styles.tableCell, ...styles.snItem, ...styles.colHide }} />
                                <View style={{ ...styles.tableCell, ...styles.otherItems, ...styles.colHide }} />
                                <View style={{ ...styles.tableCell, ...styles.otherItems, ...styles.colHide }} />
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>Total</Text>
                                </View>
                                <View style={{ ...styles.tableCell, ...styles.otherItems }}>
                                    <Text>Rs. 2756</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
}

export default InvoiceTemplate