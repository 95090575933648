import {Modal} from '@mui/material'
import React, {createContext} from 'react'
import {useState} from 'react'
import {forwardRef} from 'react'
import {useImperativeHandle} from 'react'

const modalContext = createContext({})

const FormModal = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false)
    const [childComp, setChildComp] = useState(null)
    useImperativeHandle(ref, () => ({
        openModal(component, user = null) {
            setOpen(true)
            setChildComp(component)
        }
    }))

    function handleClose() {
        setOpen(false)
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                '& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop': {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)'
                }
            }}
        >
            <>
                {childComp && React.cloneElement(childComp, {
                    handleClose: handleClose
                })}
            </>
            {/* <h1>Hello</h1> */}
        </Modal>
    )
})

function useOpenModal() {
    // modalContext.
}


export {FormModal}
