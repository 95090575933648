import React from 'react'
import ChatBot from 'react-simple-chatbot';
import {ThemeProvider} from 'styled-components';
import Mascot from '../../images/mascot.png'
import './Bot.css'
import {Box} from '@mui/material';

const CustomCompon = () => {
    return (
        <Box position='sticky' top={0} left={0}>
            <button className="btn btn-primary">
                Call Consultant
            </button>
        </Box>
    )
}

const theme = {
    background: '#f5f8fb',
    fontFamily: 'Helvetica Neue',
    headerBgColor: '#0259A7',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#0259A7',
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a',
};
// const steps = [{}]
const steps = [
    {
        id: "0",
        // message:
        //   "Hi Welcome to Ghargharma doctor PVT.LTD. What are you looking for ?",
        // This calls the next id
        // i.e. id 1 in this case
        component: (<CustomCompon/>),
        trigger: "1",
    },
    {
        id: "1",
        // This message appears in
        // the bot chat bubble
        message: "What is Your Name",
        trigger: "2",
    },
    {
        id: "2",
        // Here we want the user
        // to enter input
        user: true,
        trigger: "3",
    },
    {
        id: "3",
        message: " hi {previousValue}, how can I help you?",
        trigger: 4,
    },
    {
        id: "4",
        options: [
            {value: 1, label: "View Packages", trigger: "5"},
            {value: 2, label: "Contact Us", trigger: "6"},
            {value: 3, label: "Services", trigger: "7"},
            {value: 4, label: "Ambulance", trigger: "8"},
        ],
        // end: false
    },
    {
        id: "5",
        options: [
            {value: 1, label: "Basic Package"},
            {value: 2, label: "Silver Package"},
            {value: 3, label: "Gold Package"},
            {value: 4, label: "VIP Package"},
            {value: 5, label: "Platinum Package"},
            {value: 6, label: "Main Menu", trigger: "4"},
        ],
    },
    {
        id: "6",
        options: [
            {value: 1, label: "Ghargharmadoctor Pvt Ltd.", trigger: 8},
            {value: 2, label: "Main Menu", trigger: "4"},
        ],
    },
    {
        id: "7",
        options: [
            {value: 1, label: "Call An Ambulance"},
            {value: 2, label: "Doctor Consultation"},
            {value: 3, label: "Physiotheraphy at Home"},
            {value: 4, label: "Nursing Care"},
            {value: 5, label: "Laboratory Test at Home"},
            {value: 6, label: "Short Procedure"},
            {value: 7, label: "Main Menu", trigger: "4"},
        ],
    },
    {
        id: '8',
        component: (
            <div><a href='/ambulance'>Link to see all ambulance available</a></div>

        )
    },
    {
        id: "9",
        message: "Bye",
        end: true,
    },
];

function Bot() {

    return (
        <div className="container text-end bot">
            <div className="chatbot">
                <ThemeProvider theme={theme}>
                    <div>
                        <ChatBot steps={steps} height='400px' headerTitle='Doctor Saab' floating="true"
                                 floatingIcon={Mascot} botAvatar={Mascot}/>
                    </div>
                </ThemeProvider>
            </div>
        </div>
    );
}


export default Bot;

