import React from 'react'
import StudentLogin from './StudentLogin'

function StudentReport() {
    return (
        <StudentLogin />
    )
}

export default StudentReport
