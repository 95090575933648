import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useQuery} from '@tanstack/react-query';
import React from 'react'
import {useParams} from 'react-router-dom'
import axios from '../../../api.js';
import Loader from '../../Loader.js';
import {LoginStateContext} from '../../../../contexts/LoginStateContext.js';
import {useContext} from 'react';
import TestBooking from './TestBooking.js';
import ProfileBooking from './ProfileBooking.js';

const LabTestBookingDetail = () => {
    const params = useParams();
    const loginContext = useContext(LoginStateContext)
    const userData = loginContext.state.data

    const labBookingsDetailQuery = useQuery(
        [`lab-test-bookings-detail-${params.id}`],
        async () => {
            return axios.get('/api/admin/lab-test?id=' + params.id, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )

    if (labBookingsDetailQuery.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }
    const labDetails = labBookingsDetailQuery.data;

    return (
        <>
            {labDetails.labprofile_id === null &&
                <TestBooking labDetails={labDetails} userData={userData}/>
            }
            {labDetails.labtest_id === null &&
                <ProfileBooking labDetails={labDetails} userData={userData}/>
            }

        </>
    )
}

export default LabTestBookingDetail
