import React, { useState } from "react";
import SchoolService from "../../../../services/school.service";
import StudentUnpaid from "./StudentUnpaid";
import AsyncSelect from "../../../select/async-select";

const Filter = (props) => {
  const { applyFilter, searchParams, setSearchParams } = props;
  const [filter, setFilter] = useState({});

  const clearFilter = () => {
    setFilter({});
    setSearchParams({});
  };
  const handleChange = (type, value) => {
    setFilter({ ...filter, [type]: value });
  };
  /*
   * Save filter
   * always resetting the current page
   * */
  const saveFilter = () => applyFilter({ ...filter });

  const handleClear = (key) => {
    const newObject = { ...Object.fromEntries(searchParams), ...filter };
    if (newObject[key]) {
      delete newObject[key];
    }
    setFilter(newObject);
    setSearchParams(newObject);
  };

  return (
    <div>
      <div>
        <span className="fs-4 fw-bold">Student Lists</span>
        <div>
          <span className="fs-6 fw-light">View Your Students</span>
        </div>
      </div>
      <div className="mb-4">
        <StudentUnpaid />
      </div>
      <div className="filter-section">
        <div>
          <AsyncSelect
            label="Academic Year"
            value={filter["year"] || searchParams.get("year")}
            queryKey="year"
            handleChange={handleChange}
            handleClear={handleClear}
            filterFetchData={SchoolService.getYears}
          />
        </div>
        <div>
          <AsyncSelect
            label="Class"
            value={filter["class"] || searchParams.get("class")}
            queryKey="class"
            handleChange={handleChange}
            handleClear={handleClear}
            filterFetchData={SchoolService.getClasses}
          />
        </div>
        <div>
          <AsyncSelect
            label="Section"
            value={filter["section"] || searchParams.get("section")}
            queryKey="section"
            handleChange={handleChange}
            handleClear={handleClear}
            filterFetchData={SchoolService.getSections}
          />
        </div>
        <div className="whitespace-nowrap">
          <button
            className="btn btn-primary"
            onClick={() => saveFilter(filter)}
          >
            Apply Filter
          </button>
        </div>
        <div>
          <button
            className="btn btn-danger"
            style={{ whiteSpace: "nowrap" }}
            onClick={clearFilter}
          >
            Clear Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
