import React from 'react'
import KhaltiCheckout from "khalti-checkout-web";
import KhaltiLogo from '../../../images/payment/khalti.png';
import axios from "../../api.js";
import swal from "sweetalert";
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import OrderVerifyingUI from '../../stores/checkout/OrderVerifyingUI';

const KhaltiLabTest = (props) => {
    const [verifying, setVerifying] = useState();
    const usertoken = localStorage.getItem('token');
    const amount = props.amount * 100;
    const id = props.id;
    const redirectLink = props.redirectLink;
    let navigate = useNavigate();
    const publicKey = process.env.REACT_APP_KhaltiPublicTestKey
    let config = {
        // replace this key with yours
        "publicKey": publicKey,
        "productIdentity": "1234567890",
        "productName": "Ghargharma Doctor",
        "productUrl": "https://react.ghargharmadoctor.com/",
        "eventHandler": {
            onSuccess(payload) {
                //console.log();
                // hit merchant api for initiating verfication
                setVerifying(true)
                axios
                    .post('/api/admin/lab-test/payment', {
                        token: payload.token,
                        amount: payload.amount,
                        id: id
                    }, {headers: {"Authorization": `Bearer ${usertoken}`}})
                    .then((res) => {
                        setVerifying(false)
                        if (res.data.success) {
                            swal({
                                title: res.data.success,
                                icon: "success",
                            }).then((value) => {
                                if (value) {
                                    navigate(redirectLink)
                                }
                            });
                        }
                        if (res.data.error) {
                            swal({
                                title: res.data.error,
                                icon: "warning",
                            }).then((value) => {
                                if (value) {
                                    navigate("/book-lab-test/payment", {state: {fee: props.amount, id: id}})
                                }
                            });
                        }
                    })

            },
            // onError handler is optional
            onError(error) {
                // handle errors
                console.log();
            },
            onClose() {
                // console.log();
            }
        },
        "paymentPreference": ["KHALTI"],
    };
    let checkout = new KhaltiCheckout(config);

    return (
        <div>
            <button onClick={() => checkout.show({amount: amount})} className="border-0 bg-transparent">
                <img src={KhaltiLogo} alt=""/>
            </button>
            {
                verifying && <OrderVerifyingUI/>
            }
        </div>
    )
}

export default KhaltiLabTest
