import React, { useEffect, useState } from "react";
import axios from "../../api.js";
import { Link } from "react-router-dom";
import Loader from "../Loader";

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`/api/service`)
      .then((res) => {
        setServices(res.data);
        setLoading(false);
      })
      .catch((err) => console.log());
  }, []);

  const renderServices = () => {
    return services.length > 0 ? (
      <div className="services-grid">
        {services.map((sv, index) => (
          <div key={index} className="services-grid-items">
            <div>
              <div className="service-image">
                <img src={sv?.icon_path} alt={"Services"} />
              </div>

              <h3 className="font-bold service-grid-title mt-3">
                {sv?.service_title}
              </h3>
            </div>
            <p className="text-gray-700 service-grid-description">
              {sv?.short_description}
            </p>

            <div className="d-flex align-items-end h-100">
              <Link
                to={`/service/${sv?.slug}`}
                className="text-blue-500 mt-4 inline-block"
              >
                Learn More
              </Link>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <p>No services available.</p>
    );
  };

  return loading ? (
    <div className="text-center">
      <Loader />
    </div>
  ) : (
    <>
      <div className="inner-package text-center py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb justify-content-center all-services-page">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Services
            </li>
          </ol>
        </nav>
      </div>
      <div className="container mx-auto py-10">{renderServices()}</div>
    </>
  );
};

export default Services;
