import React, {createContext} from 'react'
import {useState} from 'react'
import {useContext} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {LoginStateContext} from '../../contexts/LoginStateContext'
import BecomeAVendor from './BecomeAVendor'
import {NavPillItem, NavPillTab} from './components/NavPillTab'
import Logo from '../../images/white-logo.png';
import Background from '../../images/slider.png';
import axios from "../api"
import swal from 'sweetalert'
import BecomeDoctor from './BecomeDoctor'
import BecomeANurse from './BecomeANurse'
import BecomeADriver from './BecomeADriver'
import RORegister from './RORegister'
import {useEffect} from 'react'

const NavPillContext = createContext(null)

export const imageTypes = ["JPG", "PNG", "GIF"];
export const fileTypes = ["PDF"];


// function BecomeAMember() {
//     const loginContext = useContext(LoginStateContext)
//     let navigate = useNavigate()
//     const [image, setImage] = useState();
//     const [file, setFile] = useState();
//     const [captchErr, setCaptchaErr] = useState(null);
//     const [imageObj, setImageObj] = useState([]);
//     const [fileObj, setFileObj] = useState([]);
//     const [submitting, setSubmitting] = useState(false)
//     const [value, setValue] = useState(0)
//     const [errorList, setErrorList] = useState([])
//     const [termsAgree, setTermsAgree] = useState(false)
//     const [validateError, setValidateError] = useState({})
//     const [searchParams, setSearchParams] = useSearchParams()

//     const {
//         register: register3,
//         handleSubmit: handleSubmit3,
//         watch: watch3,
//         setValue: setFormValue,
//         formState: { errors: errors3 },
//         control: control3
//     } = useForm();

//     useEffect(() => {
//         const objectParams = Object.fromEntries(searchParams)
//         if (objectParams.marketing_supervisor_id) {
//             setValue(4)
//         }
//     }, [searchParams])

//     const convertToBase64 = (inputFile, fileSet) => {
//         var reader = new FileReader();
//         reader.readAsDataURL(inputFile);

//         var ext = inputFile.type.split('/')[1]
//         reader.onload = () => {
//             const strArr = reader.result.split('base64,')
//             const base64Str = `data:image/${ext};base64,${strArr[1]}`

//             fileSet(base64Str)
//         }
//         reader.onerror = error => {

//         }
//     }

//     const apiLink = () => {
//         return [
//             'api/vendor-profile/store',
//             'api/admin/doctor-profile/store',
//             'api/admin/nurses/store',
//             'api/admin/driver-profile/store',
//             'api/relation-manager'
//         ]
//     }

// const registerRole = (data, img, file) => {
//     const registerType = ['vendor-profile', 'doctor-profile', 'nurses', 'driver-profile']
//     data.image = img;
//     data.file = file;
//     // console.log()
//     axios.post(apiLink()[value], data, { 'headers': { 'Authorization': `Bearer ${loginContext.state.token}` } })
//         .then(res => {
//             setSubmitting(false)
//             if (res.data.error) {
//                 swal({
//                     title: "Error",
//                     text: res.data.error,
//                     icon: "error",
//                 }).then((value) => {
//                     if (value) {
//                         navigate("/", { replace: true });
//                     }
//                 });
//                 return
//             }
//             swal({
//                 title: "Request Sent",
//                 text: "Your Request is Sent. Will Notify you after verification",
//                 icon: "success",
//             }).then((value) => {
//                 if (value) {
//                     navigate("/", { replace: true });
//                 }
//             });
//         })
//         .catch((err) => {
//             setSubmitting(false)
//             setValidateError(err.response.data.message)
//             setErrorList(err.response.data.message);
//             if (err.response.data.error) {
//                 swal({
//                     title: "Error",
//                     text: err.response.data.error,
//                     icon: "error",
//                 }).then((value) => {
//                     if (value) {
//                         navigate("/", { replace: true });
//                     }
//                 });
//                 return
//             }

//         });
//     }

//     const onSubmit = async (data) => {

//         setSubmitting(true);

//         registerRole(data, image, file)
//     };

//     const handleImage = (image3) => {
//         setImageObj(URL.createObjectURL(image3))
//         convertToBase64(image3, setImage)
//     }
//     const handleFile = (file3) => {
//         setFileObj(URL.createObjectURL(file3))
//         convertToBase64(file3, setFile)
//     }

//     const renderFile = (file) => {
//         if (file == null || file.length == 0) {
//             return
//         }
//         return (
//             <div className="viewFileUpload">
//                 <iframe src={file} width="100%" />
//                 Review PDF <a href={file} target="_blank">Click here</a>
//             </div>

//         )
//     }

//     if (loginContext.state.data.member.roles.length >= 2) {
//         navigate("/")
//         return <></>
//     }
//     return (
//         <div className="container-fluid auth register">
//             <div className="row">
//                 <div className="col-lg-6 image-side px-0">
//                     <img src={Background} alt="" className='bg-image' />
//                     <div className="image-overlay"></div>
//                     <div className="text-center bg-logo mx-auto">
//                         <img src={Logo} alt="" />
//                     </div>
//                 </div>
//                 <div className="col-lg-6 offset-lg-6 login-form">
//                     <div className="card border-0">
//                         <div>
//                             <h3 className="card-title" >Become A Member</h3>
//                             <p>Fill the form below to become a member </p>
//                         </div>
//                         <NavPillTab value={value} onChange={(e, newVal) => { setValue(newVal) }}>
//                             <NavPillItem label="Vendor" />
//                             <NavPillItem label='Doctor' />
//                             <NavPillItem label='Nurse' />
//                             <NavPillItem label='Driver' />
//                             <NavPillItem label='RO' />
//                         </NavPillTab>
//                     </div>
//                     <form onSubmit={handleSubmit3(onSubmit)} className="form">
//                         <NavPillContext.Provider value={{
//                             imageObj,
//                             fileObj,
//                             register3,
//                             setFormValue,
//                             handleImage,
//                             handleFile,
//                             errors3,
//                             submitting,
//                             imageTypes,
//                             fileTypes,
//                             image,
//                             file,
//                             renderImage,
//                             renderFile
//                         }}>
//                             <NavPillContent value={value} />
//                         </NavPillContext.Provider>
//                     </form>
//                 </div>
//             </div>
//

function NavPillContent({value}) {
    switch (value) {
        case 0:
            return <BecomeAVendor/>
        case 1:
            return <BecomeDoctor/>
        case 2:
            return <BecomeANurse/>
        case 3:
            return <BecomeADriver/>
        case 4:
            return <RORegister/>
    }
}

function BecomeAMember() {
    const [value, setValue] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const objectParams = Object.fromEntries(searchParams)
        if (objectParams.marketing_supervisor_id) {
            setValue(4)
        }
    }, [searchParams])

    return (
        <div className="container-fluid auth register">
            <div className="row">
                <div className="col-lg-6 image-side px-0">
                    <img src={Background} alt="" className='bg-image'/>
                    <div className="image-overlay"></div>
                    <div className="text-center bg-logo mx-auto">
                        <img src={Logo} alt=""/>
                    </div>
                </div>
                <div className="col-lg-6 offset-lg-6 login-form">
                    <div className="card border-0">
                        <div>
                            <h3 className="card-title">Become A Member</h3>
                            <p>Fill the form below to become a member </p>
                        </div>
                        <NavPillTab value={value} onChange={(e, newVal) => {
                            setValue(newVal)
                        }}>
                            <NavPillItem label="Vendor"/>
                            <NavPillItem label='Doctor'/>
                            <NavPillItem label='Nurse'/>
                            <NavPillItem label='Driver'/>
                            <NavPillItem label='RO'/>
                        </NavPillTab>
                    </div>

                    <NavPillContext.Provider>
                        <NavPillContent value={value}/>
                    </NavPillContext.Provider>

                </div>
            </div>
        </div>
    )
}

export default BecomeAMember

export function renderImage(img) {
    if (img == null || img.length == 0) {
        return
    }


    return (
        <div className="imgUpload">
            <img src={img} alt="upload img"/>
        </div>

    )
}

export const renderFile = (file) => {
    if (file == null || file.length == 0) {
        return
    }
    return (
        <div className="viewFileUpload">
            <iframe src={file} width="100%"/>
            Review PDF <a href={file} target="_blank">Click here</a>
        </div>

    )
}

export const convertToBase64 = (inputFile, fileSet) => {
    var reader = new FileReader();
    reader.readAsDataURL(inputFile);

    var ext = inputFile.type.split('/')[1]
    reader.onload = () => {
        const strArr = reader.result.split('base64,')
        const base64Str = `data:image/${ext};base64,${strArr[1]}`
        fileSet(base64Str)
    }
    reader.onerror = error => {

    }
}

export function handleFiles(setObj, setFile) {
    return (file) => {
        setObj(URL.createObjectURL(file))
        convertToBase64(file, setFile)
    }
}

export const registerRole = (data, api, token, setSubmitting, navigate) => {
    // console.log()
    axios.post(api, data, {'headers': {'Authorization': `Bearer ${token}`}})
        .then(res => {
            setSubmitting(false)
            if (res.data.error) {
                swal({
                    title: "Error",
                    text: res.data.error,
                    icon: "error",
                }).then((value) => {
                    if (value) {
                        navigate("/", {replace: true});
                    }
                });
                return
            }
            swal({
                title: "Request Sent",
                text: "Your Request is Sent. Will Notify you after verification",
                icon: "success",
            }).then((value) => {
                if (value) {
                    navigate("/", {replace: true});
                }
            });
        })
        .catch((err) => {
            setSubmitting(false)
            // setValidateError(err.response.data.message)
            // setErrorList(err.response.data.message);
            if (err.response.data.error) {
                swal({
                    title: "Error",
                    text: err.response.data.error,
                    icon: "error",
                }).then((value) => {
                    if (value) {
                        navigate("/", {replace: true});
                    }
                });
                return
            }

        });
}

// export const handleImage = (image) => {
//     setImageObj(URL.createObjectURL(image))
//     convertToBase64(image, setImage)
// }

// export const handleFile = (file) => {
//     setFileObj(URL.createObjectURL(file))
//     convertToBase64(file, setFile)
// }
