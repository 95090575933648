import {useQuery} from '@tanstack/react-query'
import React, {useState} from 'react'
import {useContext} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import axios from "../../../api"
import Loader from '../../Loader'

const AppointmentReport = ({booking_id}) => {
    const loginContext = useContext(LoginStateContext)
    const {isLoading, data} = useQuery(
        ['appointment_file', booking_id],
        async () => {
            return axios.get("api/admin/user-profile/medical-report?booking_id=" + booking_id, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data).catch(err => {
                    console.log()
                })
        }
    )
    if (isLoading) {
        return <h4>Getting Report....</h4>
    }

    if (data.length === 0) {
        return (
            <div className="alert alert-warning">
                No Medical Reports.
            </div>
        )
    }
    if (data[0].image_path == null) {
        return (
            <div className="alert alert-warning">
                No Medical Reports.
            </div>
        )
    }

    // console.log()

    return (
        <div className='card'>
            <h4><b>Report</b></h4>
            <table className="my-3 table table-bordered">
                <thead>
                <tr>
                    <th style={{fontWeight: '700'}} width="20%">History</th>
                    <th>
                        <p dangerouslySetInnerHTML={{__html: `${data[0].history}`}} className="mb-0"></p>
                    </th>
                </tr>
                <tr>
                    <th style={{fontWeight: '700'}}>Examination</th>
                    <th>
                        <p dangerouslySetInnerHTML={{__html: `${data[0].examination}`}} className="mb-0"></p>
                    </th>
                </tr>
                <tr>
                    <th style={{fontWeight: '700'}}>Treatment</th>
                    <th>
                        <p dangerouslySetInnerHTML={{__html: `${data[0].treatment}`}} className="mb-0"></p>
                    </th>
                </tr>
                <tr>
                    <th style={{fontWeight: '700'}}>Progress Note</th>
                    <th>
                        <p dangerouslySetInnerHTML={{__html: `${data[0].progress}`}} className="mb-0"></p>
                    </th>
                </tr>
                </thead>
            </table>
        </div>
    )
}

export default AppointmentReport
