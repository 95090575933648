import {createContext, useContext, useState} from "react";

const DashboardContext = createContext(null)

function DashboardNotifyProvider({children}) {
    const [memberCount, setMemberCount] = useState(0)
    const [familyReqCount, setFamilyReqCount] = useState(0)
    const [myRequestCount, setMyRequestCount] = useState(0)


    return (<DashboardContext.Provider value={{memberCount, familyReqCount, myRequestCount}}>
            {children}
        </DashboardContext.Provider>)
}

function useFamilyNotify() {
    const {memberCount, familyReqCount, myRequestCount} = useContext(DashboardContext)
    return {memberCount, familyReqCount, myRequestCount}
}

export {DashboardNotifyProvider, useFamilyNotify}
