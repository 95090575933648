import React from 'react'
import {useQuery} from '@tanstack/react-query';
import {useContext} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import {useState} from 'react';
import axios from '../../../api.js';
import Esewa from "../../../../images/payment/esewa.png"
import IMEPay from "../../../../images/payment/imepay.png"
import Loader from '../../Loader';
import ConnectIPS from "../../../../images/payment/connectips.png"
import KhaltiPackageAdditional from '../../khalti/KhaltiPackageAdditional';
import {useParams} from 'react-router-dom';

const FamilyPackagePayment = () => {
    const loginContext = useContext(LoginStateContext)
    const userData = loginContext.state.data
    const [interval, setInterval] = useState('');
    const params = useParams();
    const familyId = params.id;
    const [additionalPayment, setAdditionalPayment] = useState();
    const [calcRes, setCalcRes] = useState({
        // registration: 0,
        // monthly: 0,
        totalMonth: 0,
        discountTotal: 0,
        discount: 0,
        result: 0,
        total: 0,
    })

    const packageDetailsQuery = useQuery(
        ['package-details-query', userData.id],
        async () => {
            return axios.get('/api/admin/userpackage', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )
    if (packageDetailsQuery.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }

    const pkg = packageDetailsQuery.data.package;
    const months = packageDetailsQuery.data.month_count;

    const calculateFee = (e) => {
        setInterval(e.target.value)
        const feeInterval = {
            Yearly: {
                month: 12,
                discount: 0.05
            },
            Half_Yearly: {
                month: 6,
                discount: 0.02
            },
            Quarterly: {
                month: 3,
                discount: 0.01
            },
            Monthly: {
                month: 1,
                discount: 0
            }
        }
        let paymentInterval = e.target.value;
        let totalmonth = 0;
        let discounttotal = 0;
        if (pkg.renew_status === 2) {
            totalmonth = pkg.familyfee.family_size * pkg.familyfee.two_monthly_fee * feeInterval[paymentInterval].month;
            discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
            axios.post(`/api/admin/userpackage/calculate-joint-payment/${pkg.id}`, {payment_interval: paymentInterval}, {headers: {Authorization: `Bearer ${loginContext.state.token}`}})
                .then((response) => {
                    setAdditionalPayment(response.data)
                    setCalcRes({
                        totalMonth: totalmonth,
                        discountTotal: discounttotal,
                        discount: feeInterval[paymentInterval].discount * 100,
                        result: discounttotal,
                        total: discounttotal + response.data.total_additonal_payment + response.data.total_newadditonal_payment
                    })
                })

        } else if (pkg.renew_status === 3) {
            totalmonth = pkg.familyfee.family_size * pkg.familyfee.three_monthly_fee * feeInterval[paymentInterval].month;
            discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
            axios.post(`/api/admin/userpackage/calculate-joint-payment/${pkg.id}`, {payment_interval: paymentInterval}, {headers: {Authorization: `Bearer ${loginContext.state.token}`}})
                .then((response) => {
                    setAdditionalPayment(response.data)
                    setCalcRes({
                        totalMonth: totalmonth,
                        discountTotal: discounttotal,
                        discount: feeInterval[paymentInterval].discount * 100,
                        result: discounttotal,
                        total: discounttotal + response.data.total_additonal_payment + response.data.total_newadditonal_payment
                    })
                })

        } else {
            totalmonth = pkg.familyfee.family_size * pkg.familyfee.one_monthly_fee * feeInterval[paymentInterval].month;
            discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
            axios.post(`/api/admin/userpackage/calculate-joint-payment/${pkg.id}`, {payment_interval: paymentInterval}, {headers: {Authorization: `Bearer ${loginContext.state.token}`}})
                .then((response) => {
                    // console.log()
                    setAdditionalPayment(response.data)
                    setCalcRes({
                        totalMonth: totalmonth,
                        discountTotal: discounttotal,
                        discount: feeInterval[paymentInterval].discount * 100,
                        result: discounttotal,
                        total: discounttotal + response.data.total_additonal_payment + response.data.total_newadditonal_payment
                    })
                })

        }

    }

    return (
        <div>
            <div className="alert alert-danger">
                You must pay subscription package payment and additional member payment in order to complete your family
                member joining process.
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <div className="card h-100">
                        <div className="card-body">
                            <h5 style={{fontWeight: 600}} className="mb-3">Subscription Payment</h5>
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th><b>Total Family Member</b></th>
                                    <td>{pkg.familyfee.family_size}</td>
                                </tr>
                                <tr>
                                    <th><b>Monthly Fee/Person</b></th>
                                    {pkg.renew_status === 1 && <td>Rs. {pkg.familyfee.one_monthly_fee}</td>}
                                    {pkg.renew_status === 2 && <td>Rs. {pkg.familyfee.two_monthly_fee}</td>}
                                    {pkg.renew_status === 3 && <td>Rs. {pkg.familyfee.three_monthly_fee}</td>}
                                </tr>
                                <tr>
                                    <th><b>Select Payment Interval</b></th>
                                    <td>
                                        <select className='form-select' name="payment_interval" onChange={calculateFee}
                                                required>
                                            <option value="">--Select--</option>
                                            {months === 0 ? <option value="Yearly">Yearly</option> : ''}
                                            {months >= 0 && months < 7 ?
                                                <option value="Half_Yearly">Half Yearly</option> : ''}
                                            {months >= 0 && months < 10 ?
                                                <option value="Quarterly">Quarterly</option> : ''}
                                            {months >= 0 ? <option value="Monthly">Monthly</option> : ''}
                                        </select>
                                    </td>
                                </tr>
                                </tbody>

                            </table>
                            <div className="text-end mt-2">
                                <h6><b>Total Monthly Fee:</b> Rs. {calcRes.totalMonth}</h6>
                                <h6><b>Total Payable Monthly Fee After
                                    Discount:</b> Rs. {calcRes.discountTotal} ({calcRes.discount}% Discount) </h6>
                                <h6><b>Total Payable Amount:</b> Rs. {calcRes.result}</h6>
                            </div>
                        </div>
                        {pkg.additonal_members > 0 && additionalPayment &&
                            <div className="card-body">
                                <h5 style={{fontWeight: 600}} className="mb-3">Subscription Payment (Additional
                                    Member)</h5>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <th><b>Total Additional Family Member</b></th>
                                        <td>{pkg.additonal_members}</td>
                                    </tr>
                                    <tr>
                                        <th><b>Daily fee</b></th>
                                        <td>Rs. {additionalPayment.daily_fee} </td>
                                    </tr>
                                    <tr>
                                        <th><b>Remaining Payment Days</b></th>
                                        <td>{additionalPayment.payment_days} days</td>
                                    </tr>
                                    </tbody>

                                </table>
                                <div className="text-end mt-2">
                                    <h6><b>Total Amount:</b><br/> Rs. {additionalPayment.total_additonal_payment}</h6>
                                </div>
                            </div>
                        }
                        {additionalPayment &&
                            <div className="card-body">
                                <h5 style={{fontWeight: 600}} className="mb-3">Subscription Payment (New Member)</h5>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <th><b>Total New Additional Family Member</b></th>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <th><b>Enrollment fee</b></th>
                                        <td>Rs. {additionalPayment.enrollment_fee}</td>
                                    </tr>
                                    <tr>
                                        <th><b>Daily fee</b></th>
                                        <td>Rs. {additionalPayment.daily_fee}</td>
                                    </tr>
                                    <tr>
                                        <th><b>Remaining Payment Days</b></th>
                                        <td>{additionalPayment.payment_days} days</td>
                                    </tr>
                                    </tbody>

                                </table>
                                <div className="text-end mt-2">
                                    <h6><b>Total Amount:</b><br/> Rs. {additionalPayment.total_newadditonal_payment}
                                    </h6>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-6 mb-3">
                    <div className="card h-100">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 style={{fontWeight: 600}} className="mb-3">Payment Details</h5>
                                <h5><b>Total Amount:</b> Rs. {calcRes.total}</h5>
                            </div>
                            {calcRes.total !== 0 &&
                                <>
                                    <h6 style={{fontWeight: 600}} className='mt-4 mb-3'>Pay Via:</h6>
                                    <div className="d-flex justify-content-between flex-wrap align-items-center">
                                        <div className='my-2'>
                                            <KhaltiPackageAdditional familyId={familyId}
                                                                     additionalPayment={additionalPayment}
                                                                     interval={interval} packageamount={calcRes.result}
                                                                     userpackageId={pkg.id} totalamount={calcRes.total}
                                                                     redirectLink="/user/family"/>
                                        </div>
                                        <div className='my-2'>
                                            <img src={Esewa} alt="Esewa"/>
                                        </div>
                                        <div className='my-2'>
                                            <img src={IMEPay} alt="IMEPay"/>
                                        </div>
                                        <div className='my-2'>
                                            <img src={ConnectIPS} alt="ConnectIPS"/>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FamilyPackagePayment
