import {useQuery} from '@tanstack/react-query'
import React, {useContext, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {LoginStateContext} from '../../../contexts/LoginStateContext'
import axios from '../../api'
import PackageStatus from '../../pages/dashboard/packageStatuses/PackageStatus'

function KYCUpdateInfo() {
    const loginContext = useContext(LoginStateContext)
    const location = useLocation()
    const {data, isLoading, refetch} = useQuery(
        ['package-user', loginContext.state.data.id],
        async () => (
            axios.get('/api/admin/userpackage', {headers: {'Authorization': `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        )
    )
    useEffect(() => {
        refetch()
    }, [location])
    if (isLoading) {
        return
    }
    if (loginContext.state.data.school_profile?.types == 'school') {
        return
    }
    return (
        <PackageStatus data={data?.package} type='header-status'/>
    )
}

export default KYCUpdateInfo
