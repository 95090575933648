import {useQuery} from '@tanstack/react-query'
import React, {useContext} from 'react'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import {DateFormat} from '../../../../DateFormat'

function PhysicalCard() {
    const loginContext = useContext(LoginStateContext)
    const user = loginContext.state.data
    // const userData = useQuery(
    //     ['userProfile', user.id],
    //     async () => {
    //         return axios.get("api/admin/user-profile", { headers: { "Authorization": `Bearer ${loginContext.state.token}` } })
    //             .then(res => res.data).catch(err => err)
    //     }
    // )

    return (
        <>
            <div className="card-title-outer">
                <h2 className="m-0 p-0 title-text-weight">
                    <b>Physical Details</b>
                </h2>
            </div>
            <div className="mb-2 shadow-sm card">
                <div className="card-body">
                    <h4 className="m-0 p-0" style={{fontWeight: "600"}}>
                        Blood Pressure - {user.bp == null ? "No Data" : user.bp + " mmHg"}
                    </h4>
                    {/* <span className="text-muted">
                        Long Description about blood pressure.
                    </span> */}
                    {/* <br /> */}
                    {
                        user.bp === null ?
                            <span className="text-primary">
                                <b>Not Updated</b>
                            </span>
                            :
                            <span className="text-primary">
                                <b>Last Updated On {DateFormat(user.updated_at).getFullDateText()}</b>
                            </span>
                    }

                </div>
            </div>
            <div className="mb-2 shadow-sm card">
                <div className="card-body">
                    <h4 className="m-0 p-0" style={{fontWeight: "600"}}>
                        Heart Rate - {user.heart_rate == null ? "No Data" : user.heart_rate + " bpm"}
                    </h4>
                    {/* <span className="text-muted">
                        Long Description about blood pressure.
                    </span> */}
                    {/* <br /> */}
                    {
                        user.heart_rate === null ?
                            <span className="text-primary">
                                <b>Not Updated</b>
                            </span>
                            :
                            <span className="text-primary">
                                <b>Last Updated On {DateFormat(user.updated_at).getFullDateText()}</b>
                            </span>
                    }
                </div>
            </div>
            <div className="shadow-sm card">
                <div className="card-body">
                    <h4 className="m-0 p-0" style={{fontWeight: "600"}}>
                        Body Mass Index
                        - {(user.weight == null || user.height == null) ? "--" : (user.weight / (user.height * user.height)).toFixed(2)}
                    </h4>
                    {/* <span className="text-muted">
                        Long Description about blood pressure.
                    </span> */}
                    {/* <br /> */}
                    <span className="text-primary">
                        <b>Last Updated On {DateFormat(user.updated_at).getFullDateText()}</b>
                    </span>
                </div>
            </div>
        </>
    )
}

export default PhysicalCard
