import {Box, Stack, Typography} from '@mui/material'
import React, {useContext} from 'react'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import axios from '../../../../api'
import {useQuery} from '@tanstack/react-query'
import ContentLoader from '../../../../custom_componets/ContentLoader'
import {DateFormat} from '../../../../DateFormat'

function ReferredList() {
    const loginContext = useContext(LoginStateContext)
    const {data, isLoading} = useQuery(
        ['referral-lists'],
        async () => (
            axios.get('api/referralFriend', {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => res.data)
        )
    )

    if (isLoading) {
        return <ContentLoader/>
    }
    return (
        <Box>
            <Stack>
                {
                    data.map((item, idx) => (
                        <Box py={2} borderBottom={1} borderColor='divider' display='flex' justifyContent='space-between'
                             alignItems='center'>
                            <Box className='rounded-lg' width='100px' bgcolor={'primary.light'} color='#fff' p={1}>
                                <Typography variant='subtitle1' sx={{p: '0 !important', m: '0 !important'}}>
                                    {
                                        DateFormat(item.created_at).getFullDateText()
                                    }
                                </Typography>
                            </Box>
                            <Typography variant='subtitle1'>{item.referral_email}</Typography>
                            <Box width={'150px'} textAlign='right'>
                                <Typography variant='subtitle1' color={'info.main'}>Invitation Sent</Typography>
                            </Box>
                        </Box>
                    ))
                }
            </Stack>
        </Box>
    )
}

export default ReferredList
