import {Box, Button, Card, CardContent, CardHeader} from '@mui/material'
import React, {useState} from 'react'

function ChatModal({title, description, handleConfirm, isOpen, onClose, closeChat, isLoading}) {
    const [loading, setLoading] = useState(false)
    if (!isOpen) {
        return
    }
    return (
        <Box position='fixed' top={0} left={0} bottom={0} width='100%' height='100vH' sx={{zIndex: 99999}}>
            <Box position='absolute' top={0} left={0} width='100%' height='100%' bgcolor='black' sx={{opacity: 0.7}}>
            </Box>
            <Box position='absolute' top='50%' left='50%' sx={{transform: 'translate(-50%, -50%)'}}>
                <Box bgcolor={'#fff'} borderRadius={1} boxShadow={1} maxWidth={'448px'} textAlign='left'>
                    <Box px={2} py={1}>
                        <span className='fs-5'>{title}</span>
                    </Box>
                    <Box px={2} py={1}>
                        <span className='fs-6 fw-light' style={{fontWeight: 100}}>
                            {description}
                        </span>
                    </Box>
                    <Box px={2} py={1}>
                        <Box display={'flex'} gap={1}>
                            <Box flexGrow={1}>
                                <Button variant='outlined' color='primary' fullWidth onClick={onClose}
                                        disabled={loading}>
                                    Cancel
                                </Button>
                            </Box>
                            <Box flexGrow={1}>
                                <Button variant='contained' disabled={loading} fullWidth
                                        onClick={() => handleConfirm(() => {
                                            onClose()
                                            closeChat()
                                        }, setLoading)}>
                                    {
                                        loading ? 'Calling..' : 'Confirm'
                                    }
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ChatModal
