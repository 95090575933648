import {KeyboardArrowDownOutlined} from '@mui/icons-material'
import {Box, Collapse, Grid, Stack, Typography} from '@mui/material'
import React from 'react'
import {useState} from 'react'
import ProfileDetails from './ProfileDetails'
import PackageBookInfo from './PackageInfo'

function BookingInfomrations() {
    return (
        <Box p={4} borderLeft={1} borderRight={1} borderColor='divider'>
            <Stack gap={4}>
                <ProfileDetails/>
                <PackageBookInfo/>
                {/* <InformationComponent /> */}
            </Stack>
        </Box>
    )
}

// function InformationComponent() {
//     const [expanded, setExpanded] = useState(true)
//     return (
//         <div>
//             <Box display='flex' justifyContent='space-between' alignItems='center' sx={{cursor:'pointer'}} onClick={()=>setExpanded(prev=>!prev)}>
//                 <Typography variant='h4' fontWeight={500}>Profile Information</Typography>
//                 <KeyboardArrowDownOutlined sx={{ fontSize: 30 }} />
//             </Box>
//             <Collapse in={expanded}>
//                 <Box mt={2} textAlign={'left'}>
//                     <Grid container spacing={2}>
//                         <Grid item xs={6}>
//                             <Typography variant='subtitle1' color={'text.disabled'}>Address</Typography>
//                             <Typography variant='subtitle1' fontWeight={500}>Bagmati, Kathmandu, Baneshwor</Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                             <Typography variant='subtitle1' color={'text.disabled'}>Phone number</Typography>
//                             <Typography variant='subtitle1' fontWeight={500}>+977 9860291829</Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                             <Typography variant='subtitle1' color={'text.disabled'}>Gender</Typography>
//                             <Typography variant='subtitle1' fontWeight={500}>Male</Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                             <Typography variant='subtitle1' color={'text.disabled'}>Date of birth</Typography>
//                             <Typography variant='subtitle1' fontWeight={500}>Jan 1st, 1997</Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                             <Typography variant='subtitle1' color={'text.disabled'}>Weight</Typography>
//                             <Typography variant='subtitle1' fontWeight={500}>80 kg</Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                             <Typography variant='subtitle1' color={'text.disabled'}>Height</Typography>
//                             <Typography variant='subtitle1' fontWeight={500}>6' 3''</Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                             <Typography variant='subtitle1' color={'text.disabled'}>Age</Typography>
//                             <Typography variant='subtitle1' fontWeight={500}>26 years</Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                             <Typography variant='subtitle1' color={'text.disabled'}>Blood group</Typography>
//                             <Typography variant='subtitle1' fontWeight={500}>AB +</Typography>
//                         </Grid>
//                     </Grid>
//                 </Box>
//             </Collapse>
//         </div>
//     )
// }

export default BookingInfomrations
