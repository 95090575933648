import React from "react";
import {
  Navigate,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Loader from "../components/pages/Loader";
import { LoginStateContext } from "../contexts/LoginStateContext";
import { Box, Stack } from "@mui/material";
import ErrorImg from "../images/error-icon.png";

let signupPaths = ["/become-partner"];

function ProtectedRoute({ userRole = [0] }) {
  const login = React.useContext(LoginStateContext);
  const location = useLocation();

  const navigate = useNavigate();

  const loadingScreenStyle = {
    height: "80vh",
  };

  if (login.state.loading) {
    return (
      <div className="loading-screen text-center" style={loadingScreenStyle}>
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="center-div text-center">
            <Loader />
            <h4>Verifying. Please Wait...</h4>
          </div>
        </div>
      </div>
    );
  }

  if (login.state.expired) {
    return (
      <Box
        width="100vW"
        minHeight="100vH"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "linear-gradient(to right, #57c7f7, #0259a7)",
        }}
      >
        <Box
          className="card"
          maxWidth={"600px"}
          width="100%"
          boxShadow={"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"}
        >
          <div className="card-body px-5 mx-2 my-5">
            <Stack gap={2} alignItems="center">
              <div>
                <img src={ErrorImg} alt="" style={{ height: "100px" }} />
              </div>
              <div className="text-center">
                <span className="fs-3">Your session has expired</span>
              </div>
              <div className="text-center">
                <span className="fs-5 fw-light">
                  You're being timed out due to inactivity. Please refresh the
                  page and login again to view this page.
                </span>
              </div>
              <div className="mt-2">
                <button
                  onClick={() => window.location.reload()}
                  className="btn btn-primary px-5"
                >
                  Refresh
                </button>
              </div>
            </Stack>
          </div>
        </Box>
      </Box>
    );
  }

  if (login.state.loggedIn) {
    let role =
      login.state.data.school_profile != null &&
      login.state.data.school_profile.types === "school"
        ? 1
        : 0;
    if (userRole.includes(role)) {
      return <Outlet />;
    } else {
      navigate("/");
    }
  }

  if (
    localStorage.getItem("queryString") &&
    signupPaths.includes(window.location.pathname)
  ) {
    localStorage.removeItem("queryString");
    return <Navigate to="/register" replace="true" />;
  }

  return (
    <Navigate
      to="/login"
      replace="true"
      state={{ redirect_pg: location.pathname }}
    />
  );
}

export default ProtectedRoute;
