import {Avatar, Box, Button, Stack, Typography} from '@mui/material'
import React from 'react'

function ProfileInfo() {
    return (
        <Box p={4} borderRight={1} borderColor='divider' position='sticky' top={100}>
            <Stack alignItems='center' gap={2}>
                <Avatar
                    src='https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/sam-worthington-avatar-the-way-of-the-water-1670323169.jpg?crop=0.528xw:1.00xh;0.134xw,0&resize=1200:*'
                    sx={{width: '60%', aspectRaio: '1/1', height: 'auto'}}
                />
                <Box textAlign='center'>
                    <Typography variant='h5' fontWeight={'700'}>
                        Jim Armstrong
                    </Typography>
                    <Typography variant='subtitle1'>
                        jim007
                    </Typography>
                    <Typography variant='subtitle1' color='primary.main'>
                        jim_arms@mail.com
                    </Typography>
                    <Button variant='contained'>
                        Edit Profile
                    </Button>
                </Box>
            </Stack>
        </Box>
    )
}

export default ProfileInfo
