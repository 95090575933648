
function getInch(footInch) {
    return Math.round(footInch % 12 )
}

function getFeet(footInch) {
    return Math.floor(footInch / 12) 
}


function meterToFeetInch(length) {
    let feet = getFeet(length *  39.370)
    let inch = getInch(length * 39.370)
    if(inch === 12) {
        feet += 1;
        inch = 0
    }
    return {
        feet, inch
    }
}

function feetInchText(length) {
    const {feet, inch} = meterToFeetInch(length)

    return `${feet}' ${inch}"`
}

export {feetInchText, meterToFeetInch}