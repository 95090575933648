import {
  AddCircle,
  CalendarMonthOutlined,
  LocalHospitalOutlined,
  Search,
} from "@mui/icons-material";
import { Avatar, Box, Button, InputBase } from "@mui/material";
import axios from "../../../api";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { LoginStateContext } from "../../../../contexts/LoginStateContext";
import DocImg from "../../../../images/receptionist.png";
import { DateFormat } from "../../../DateFormat";
import FormModalContext from "../../../custom_componets/globalFormModal/FormModalContext";
import PdfIcon from "../../../../images/pdf.png";
import MedicalHistoryPDF from "./MedicalHistoryPDF";
import Loader from "../../Loader";
import PackageStatus from "../packageStatuses/PackageStatus";
import NoDataUi from "../../../../images/nodata.jpg";

function MedicalHistory() {
  const navigate = useNavigate();
  const loginContext = useContext(LoginStateContext);
  const { openFormModal } = useContext(FormModalContext);
  const { isLoading, data, isFetchedAfterMount } = useQuery(
    ["medical-history"],
    async () => {
      return axios
        .get("/api/admin/user-profile/medical-history", {
          headers: { Authorization: `Bearer ${loginContext.state.token}` },
        })
        .then((res) => res.data)
        .catch((err) => err);
    }
  );
  const {
    data: pkgData,
    isLoading: pkgLoading,
    isError: pkgError,
    refetch: pkgRefetch,
  } = useQuery(["mypackage", loginContext.state.token], async () =>
    axios
      .get("/api/admin/userpackage", {
        headers: { Authorization: `Bearer ${loginContext.state.token}` },
      })
      .then((res) => {
        return res.data;
      })
  );
  let isMember =
    pkgData?.package === null ||
    pkgData?.package?.package_status === "Not Booked"
      ? false
      : true;
  const [historyData, setHistoryData] = useState([]);

  function handleSearch(e) {
    if (e.target.value === null || e.target.value === "") {
      setHistoryData(data);
      return;
    }
    const searchString = e.target.value.toLowerCase();
    console.log("Search string", searchString);
    const filtered = [];
    for (var i = 0; i < data.length; i++) {
      let searchStrArr = searchString;
      let strFiltered = [];
      if (data[i].doctor !== null) {
        for (var j = 0; j < data[i].doctor.user.name.length; j++) {
          if (searchStrArr.length == 0) {
            break;
          }
          const charStr = data[i].doctor.user.name[j].toLowerCase();
          if (charStr == searchStrArr[0]) {
            strFiltered.push(searchStrArr[0]);
            searchStrArr = searchStrArr.slice(1);
          } else {
            if (strFiltered.length > 0) {
              strFiltered = [];
              searchStrArr = searchString;
            }
          }
        }
      }
      let doctorName = data[i].doctor_name || "";
      for (var j = 0; j < doctorName.length; j++) {
        console.log(data[i].id, data[i].doctor_name[j]);
        if (searchStrArr.length == 0) {
          break;
        }
        const charStr = data[i].doctor_name[j].toLowerCase();
        if (charStr == searchStrArr[0]) {
          strFiltered.push(searchStrArr[0]);
          searchStrArr = searchStrArr.slice(1);
        } else {
          if (strFiltered.length > 0) {
            strFiltered = [];
            searchStrArr = searchString;
          }
        }
      }
      let dateStr = DateFormat(data[i].created_at).getFullDateText();
      for (var j = 0; j < dateStr.length; j++) {
        if (searchStrArr.length == 0) {
          break;
        }
        const charStr = dateStr.toLowerCase()[j];
        if (charStr == searchStrArr[0]) {
          strFiltered.push(searchStrArr[0]);
          searchStrArr = searchStrArr.slice(1);
        } else {
          if (strFiltered.length > 0) {
            strFiltered = [];
            searchStrArr = searchString;
          }
        }
      }
      if (data[i].department || data[i].is_other) {
        let depStr = data[i].department?.department || data[i].is_other;
        for (var j = 0; j < depStr.length; j++) {
          if (searchStrArr.length == 0) {
            break;
          }
          const charStr = depStr.toLowerCase()[j];
          if (charStr == searchStrArr[0]) {
            strFiltered.push(searchStrArr[0]);
            searchStrArr = searchStrArr.slice(1);
          } else {
            if (strFiltered.length > 0) {
              strFiltered = [];
              searchStrArr = searchString;
            }
          }
        }
      }

      let hospital = data[i].hospital;
      if (hospital !== null) {
        for (var j = 0; j < hospital.length; j++) {
          if (searchStrArr.length == 0) {
            break;
          }
          const charStr = hospital.toLowerCase()[j];
          if (charStr == searchStrArr[0]) {
            strFiltered.push(searchStrArr[0]);
            searchStrArr = searchStrArr.slice(1);
          } else {
            if (strFiltered.length > 0) {
              strFiltered = [];
              searchStrArr = searchString;
            }
          }
        }
      }

      if (strFiltered.length == searchString.length) {
        filtered.push(data[i]);
      }
    }
    setHistoryData(filtered);
  }

  useEffect(() => {
    if (!isLoading) {
      setHistoryData(data);
    }
  }, [isLoading]);

  useEffect(() => {
    setHistoryData(data);
  }, [isFetchedAfterMount]);

  return (
    <Box>
      <div>
        <div className="card">
          <div className="card-header">
            <div>
              <div>
                <span className="fs-5">External Medical History</span>
              </div>
              <span className="text-muted">
                These are your external medical history.
              </span>
              <br />
              {!isMember && (
                <div className="mt-2">
                  <PackageStatus
                    data={pkgData.package}
                    disclaimer={
                      "Buy Package in order to activate external medical history translation by GD Doctor."
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div className="card-body">
            {isLoading || pkgLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <>
                <Box className="d-flex justify-content-between">
                  <div>
                    <Button
                      startIcon={<AddCircle />}
                      variant="contained"
                      color="primary"
                      onClick={() => navigate("/user/medical-history/add")}
                    >
                      Add Medical History
                    </Button>
                    {isMember && (
                      <p>
                        <span className="text-danger">
                          * Please refer your original uploaded document incase
                          of confusion or doubt.
                        </span>
                      </p>
                    )}
                  </div>
                  {isMember && (
                    <Box
                      className="d-flex gap-1 align-items-center rounded mb-2 shadow-sm"
                      border={1}
                      borderColor="divider"
                      width={"250px"}
                    >
                      <div className="p-1">
                        <Search />
                      </div>
                      <Box className="flex-grow-1 p-1">
                        <InputBase
                          type="search"
                          placeholder="Search For Medical History"
                          fullWidth
                          onChange={handleSearch}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
                {data.length === 0 ? (
                  <div
                    className="w-100 d-flex justify-content-center align-items-center flex-column"
                    style={{ aspectRatio: "2.8/1" }}
                  >
                    <div style={{ height: "60%" }}>
                      <img src={NoDataUi} alt="No Data" className="h-100" />
                    </div>

                    <div>
                      <span className="fs-4  fw-light">
                        No Medical History Added Yet.
                      </span>
                    </div>
                  </div>
                ) : isMember ? (
                  <table className="table border-left border-right border-top">
                    <thead>
                      <Box component="tr" bgcolor={"#"}>
                        <th>
                          <span className="text-muted">S.N.</span>
                        </th>
                        <th>
                          <span className="text-muted">Medical PDF</span>
                        </th>
                        <th>
                          <span className="text-muted">Translated By</span>
                        </th>
                        <th>
                          <span className="text-muted">Checked By</span>
                        </th>
                        <th>
                          <span className="text-muted">Department</span>
                        </th>
                        <th>
                          <span className="text-muted">Status</span>
                        </th>
                        <th></th>
                      </Box>
                    </thead>
                    <tbody>
                      {historyData.length === 0 ? (
                        <tr>
                          <td colSpan={7}>
                            <div className="w-100 d-flex justify-content-center">
                              <span className="fs-6">No Data Found.</span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        historyData.map(
                          (item, idx) =>
                            item.medical_report.length > 0 && (
                              <tr key={item.id}>
                                <td>
                                  <span className="text-muted fs-6">
                                    {idx + 1}
                                  </span>
                                </td>
                                <td>
                                  <div
                                    className="d-flex gap-2 align-items-center"
                                    role="button"
                                    onClick={() =>
                                      openFormModal(
                                        <MedicalHistoryPDF
                                          pdf={
                                            item.medical_report[0].report_path
                                          }
                                        />
                                      )
                                    }
                                  >
                                    <div>
                                      <img
                                        src={PdfIcon}
                                        alt=""
                                        style={{ height: "40px" }}
                                      />
                                    </div>
                                    <div>
                                      <span
                                        className="fs-6"
                                        style={{ fontWeight: "550" }}
                                      >
                                        #
                                        {
                                          item.medical_report[0].report.split(
                                            "."
                                          )[0]
                                        }
                                      </span>
                                      <br />
                                      <span className="fs-6 fw-light">
                                        {DateFormat(
                                          item.created_at
                                        ).getFullDateText()}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {item.doctor === null ? (
                                    <span className="text-muted fs-6">
                                      Doctor Not Assigned
                                    </span>
                                  ) : (
                                    <div className="d-flex align-items-center gap-2">
                                      <Avatar
                                        src={item.doctor.image_path || DocImg}
                                      />
                                      <div>
                                        <div>
                                          <span
                                            className="fs-6"
                                            style={{ fontWeight: 550 }}
                                          >
                                            Dr. {item.doctor.user.name}
                                          </span>
                                        </div>
                                        {item.doctor.nmc_no && (
                                          <div>
                                            <span className="badge badge-info">
                                              {item.doctor.nmc_no}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <div>
                                    <span className="fs-6 me-1">
                                      {item.doctor_name || "-----"}
                                    </span>
                                    <small className="badge badge-success text-white">
                                      {item.doctor_nmc}
                                    </small>
                                  </div>
                                  {item.hospital && (
                                    <div className="d-flex align-items-center gap-1">
                                      <LocalHospitalOutlined className="fs-6" />
                                      <span className="fw-light fs-6">
                                        {item.hospital}
                                      </span>
                                    </div>
                                  )}
                                  {item.check_date && (
                                    <div className="d-flex align-items-center gap-1">
                                      <CalendarMonthOutlined className="fs-6" />
                                      <span className="fw-light text-muted fs-6">
                                        {DateFormat(
                                          item.check_date
                                        ).getFullDateText()}
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <span className="fs-6 text-muted">
                                    {item.department ? (
                                      <>{item.department.department}</>
                                    ) : item.is_other ? (
                                      <>{item.is_other}</>
                                    ) : (
                                      <>---</>
                                    )}
                                  </span>
                                </td>
                                <td>
                                  {item.status === 2 ? (
                                    <span className="badge badge-danger">
                                      Rejected
                                    </span>
                                  ) : item.status === 1 ? (
                                    <span className="badge badge-success">
                                      Approved
                                    </span>
                                  ) : (
                                    <span className="badge badge-warning">
                                      Pending
                                    </span>
                                  )}
                                  {/* <span className="fs-6 badge badge-warning"></span> */}
                                </td>
                                <td>
                                  <div className="d-flex justify-content-end">
                                    <Link
                                      to={`/user/lab-reports/medical-history/${item.id}`}
                                      className="btn btn-primary"
                                    >
                                      View Report
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            )
                        )
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div className="row mt-4">
                    {data.map(
                      (item, idx) =>
                        item.medical_report.length > 0 && (
                          <div className="col-3" key={item.id}>
                            <div className="card shadow">
                              <div className="card-body">
                                <div className="d-flex gap-2 align-items-center">
                                  <div>
                                    <img
                                      src={PdfIcon}
                                      alt=""
                                      style={{ height: "60px" }}
                                    />
                                  </div>
                                  <div>
                                    <span className="fw-light">
                                      Medical History
                                    </span>
                                    <br />
                                    <span className="fs-6">
                                      #
                                      {
                                        item.medical_report[0].report.split(
                                          "."
                                        )[0]
                                      }
                                    </span>
                                    <br />
                                    <span className="fs-6 fw-light">
                                      {DateFormat(
                                        item.medical_report[0].created_at
                                      ).getFullDateText()}
                                    </span>
                                  </div>
                                  <div className="ms-auto">
                                    <button
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        openFormModal(
                                          <MedicalHistoryPDF
                                            pdf={
                                              item.medical_report[0].report_path
                                            }
                                          />
                                        )
                                      }
                                    >
                                      View
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
}

export default MedicalHistory;
