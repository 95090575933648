const DATEFORMAT = "YYYY-MM-DD";
const FIELD_REQUIRED = "This field is required.";

const LabTestOptions = [
  "Hemoglobin (HB)",
  "Packed Cell Volume (PCV)",
  "Total Count (TC)",
  "Direct Count (DC)",
  "Platelet",
  "Red Blood Counts (RCB)",
  "Mean Corpuscular Volume (MCV)",
  "Mean Corpuscular Hemoglobin (MCH)",
  "Mean Corpuscular Hemoglobin Concentration (MCHC)",
  "Erythrocyte Sedimentation Rate (ESR)",
  "Calcium",
  "Blood Group",
  "Stool Checkup",
  "Urine Checkup",
];

const InsuranceInfoData = [
  { coverage: "Children Student (Age 3-19 Years)", amount: "" },
  {
    coverage: "1. Accidental Medical Expenses Reimbursement (AMR)",
    amount: "75,000/Event, in excess of 500",
  },
  { coverage: "2. Term Life", amount: "20,000" },
];

export { DATEFORMAT, FIELD_REQUIRED, LabTestOptions, InsuranceInfoData };
