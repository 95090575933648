import {lineHeight} from '@mui/system';
import React from 'react'
import {useEffect} from 'react';
import {useState} from 'react';
import {Link, useParams} from 'react-router-dom'
import axios from "../../api"
import './Team.css'

const Team = () => {
    const {slug} = useParams();
    const [teams, setTeams] = useState([]);
    const [category, setCategory] = useState('');
    useEffect(() => {
        axios.get(`/api/team/${slug}`)
            .then(res => {
                console.log();
                setTeams(res.data.team)
                setCategory(res.data.category)
            })
            .catch((error) => {
                console.log()
            })
    }, [slug])
    return (
        <div className='team'>
            {/* <div className="bg-team text-center py-5">
            <h1>{category.category_name}</h1>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{category.category_name}</li>
                </ol>
            </nav>
        </div>  */}
            <div className="bg-team text-center py-5 ">
                <h1>{category.category_name}</h1>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{category.category_name}</li>
                    </ol>
                </nav>
            </div>
            <div className="team-content-section">
                {teams.length !== 0 ?
                    <div className="container team-container my-5 ">
                        <div className="d-flex flex-wrap justify-content-center">
                            {teams.map((team) =>
                                <div className="flex-wrap-items my-2">
                                        <div className="card member-card border-0">
                                            <div className="card-body p-0">
                                                <img src={team.image_path} alt="" height='300px' width='100%'
                                                     style={{objectFit: 'cover'}}/>
                                            </div>
                                            <div className="member text-center px-1">
                                                <h4 className='mb-2'
                                                    style={{fontWeight: '600', lineHeight: "1em"}}>{team.name}</h4>
                                                <p className='mb-0' style={{
                                                    color: '#58CAF4',
                                                    lineHeight: "1.1em",
                                                    fontWeight: "500",
                                                    fontSize: '16px'
                                                }}>{team.position}</p>
                                            </div>
                                        </div>
                                    </div>
                            )}
                        </div>
                    </div>
                    :
                    <div className='container my-5'>

                    </div>
                }
            </div>
        </div>
    )
}

export default Team
