import React from 'react'
import "./AppointmentDetail.scss"
import ConnectIPS from "../../../../images/payment/connectips.png"
import Khalti from "../../../../images/payment/khalti.png"
import Esewa from "../../../../images/payment/esewa.png"
import IMEPay from "../../../../images/payment/imepay.png"
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';
import axios from "../../../api"
import KhaltiDoctor from "../../khalti/KhaltiDoctor"
import Loader from '../../Loader'
import {Rating} from '@mui/material'
import AppointmentReview from './AppointmentReview'
import AppointmentFiles from './AppointmentFiles'
import Recommendation from './Recommendation'
import AppointmentReport from './AppointmentReport'
import DoctorImgPlace from '../../../../images/receptionist.png'
import {useChkLogin} from '../../../../middlewares/LoginMiddleware'
// import ErrorIcon from '@mui/icons-material/Error';
import {Error} from '@mui/icons-material'

function AppointmentDetail() {
    const slug = useParams().slug;
    const {token} = useChkLogin()
    const {isLoading, data} = useQuery(
        ["user-appointment", slug],
        async () => {
            return axios.get('api/admin/booking-review?slug=' + slug, {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => res.data)
                .catch(err => err)
        },
    )

    // console.log("Appointment", data)

    if (isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }

    const doctorProfile = data?.doctor_profile

    return (
        <div className="appointment-detail">
            <div className="row g-3">
                <div className="col-xl-8">
                    <div className="row w-100 g-0">
                        <div className="col-lg-4">
                            <div className="card doctor-info h-100">
                                <div className="d-flex h-100 align-items-center justify-content-center">
                                    <div className="doctor-profile text-center">
                                        <div className="profile-image">
                                            <img src={doctorProfile?.image_path || DoctorImgPlace}
                                                 alt={`Doctor Namne`}/>
                                        </div>
                                        <div className="profile-name mt-2">
                                            <h3 className='m-0'><b>{data.slot?.bookings.doctor.user.name}</b></h3>
                                        </div>
                                        <div className="profile-email">
                                            <span className="text-muted">{data.slot?.bookings.doctor.user.email}</span>
                                        </div>
                                        {/* <div className="profile-message mt-2">
                                            <div className="btn btn-outline-secondary w-100">Send Message</div>
                                        </div> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card doctor-info h-100">
                                <div className="d-flex align-items-center justify-content-center h-100">
                                    <div className="doctor-profile-details px-4">
                                        <div className="row g-5 align-items-center w-100">
                                            <div className="col-sm-6">
                                                <div className="profile-label text-primary">
                                                    <b>NMC No.</b>
                                                </div>
                                                <div className="profile-detail">
                                                    {doctorProfile.nmc_no}
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="profile-label text-primary">
                                                    <b>Doctor Name:</b>
                                                </div>
                                                <div className="profile-detail">
                                                    {data.slot.bookings.doctor.user.name}
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="profile-label text-primary">
                                                    <b>Department</b>
                                                </div>
                                                <div className="profile-detail">
                                                    {doctorProfile.departments.department}
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="profile-label text-primary">
                                                    <b>Qualification</b>
                                                </div>
                                                <div className="profile-detail">
                                                    {doctorProfile.qualification}
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="profile-label text-primary">
                                                    <b>Experience</b>
                                                </div>
                                                <div className="profile-detail">
                                                    {doctorProfile.year_practiced} years
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="profile-label text-primary">
                                                    <b>Consultation Fee:</b>
                                                </div>
                                                <div className="profile-detail">
                                                    NPR. {doctorProfile.fee}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row w-100 g-0 mt-4">
                        <div className="col-12">
                            <div className="card details-appointment">
                                <h4><b>Appointment Details</b></h4>

                                <div className="card bg-light">
                                    <div className="appointment-list">
                                        <table className="table table-borderless">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <h5 className="m-0 p-0"><b>Appointment Date</b></h5>
                                                    {data.slot.bookings.date}
                                                </td>
                                                <td>
                                                    <h5 className="m-0 p-0"><b>Appointment Time</b></h5>
                                                    {data.slot.slot}
                                                </td>
                                                <td>
                                                    <h5 className="m-0 p-0"><b>Appointment Status</b></h5>
                                                    {data.booking_status}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        data.booking_status === "Completed" &&
                        <div className="row w-100 g-0">
                            <div className="col-12">
                                <AppointmentReview doctor_id={doctorProfile.id} appointment_id={data.id}/>
                            </div>
                        </div>
                    }
                </div>

                <div className="col-xl-4">
                    {
                        data?.booking_status === "Rejected" && (
                            <div className="row w-100">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="d-flex gap-2 align-items-center">
                                            <Error color='error'/>
                                            <span className="fw-bold fs-5 text-danger">Appointment Rejected</span>
                                        </div>
                                        <div className="mt-2 d-flex gap-2">
                                            <span className='fs-6'>Reject Reason: </span>
                                            "<span className='fs-6 fw-bold text-danger'
                                                   dangerouslySetInnerHTML={{__html: data?.cancel_reason}}></span>"
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="row w-100">
                        <div className="col-12 ">
                            <div className="card payment-details">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4><b>Payment Details</b></h4>
                                    {
                                        data.status === 0 ?
                                            <span className="badge badge-warning p-2">
                                                Payment Pending
                                            </span>
                                            :
                                            <span className="badge badge-success p-2">
                                                Payment Completed
                                            </span>
                                    }

                                </div>

                                {
                                    data.status === 0 ?
                                        <div className="pay-with">
                                            <div className="payment-amount my-3">
                                                <span className="amount-label"><b>Total Amount:</b></span>
                                                <span className="ms-3">NPR. {doctorProfile.fee}</span>
                                            </div>
                                            <small className='text-info'>Can only be paid with Khalti at the
                                                moment</small>
                                            <h5><b>Pay With:</b></h5>
                                            <div className="d-flex flex-wrap align-items-center">
                                                <div className="payment-item">
                                                    <img src={Esewa} alt="Esewa"/>
                                                </div>
                                                <div className="payment-item ms-2 d-flex align-items-center">
                                                    <KhaltiDoctor id={data.id} amount={doctorProfile.fee}
                                                                  redirectLink={window.location.pathname}/>
                                                </div>
                                                <div className="payment-item ms-2">
                                                    <img src={ConnectIPS} alt="Esewa"/>
                                                </div>
                                                <div className="payment-item ms-2">
                                                    <img src={IMEPay} alt="Esewa"/>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="payment-details">
                                            <div className="payment-amount my-3">
                                                <span className="amount-label"><b>Total Amount Paid: </b></span>
                                                <span className="ms-3">NPR. {doctorProfile.fee}</span>
                                            </div>
                                            <div className="paid-with d-flex align-items-center">
                                                <span className="paid-with-label"><b>Paid With:</b></span>
                                                <div className="payment-item ms-4">
                                                    {data.payment_method === 'Khalti' &&
                                                        <img src={Khalti} alt="Khalti"/>
                                                    }
                                                </div>
                                            </div>
                                            <div className="paid-at mt-2">
                                                <span className="paid-at-label"><b>Paid At: </b></span>
                                                <span>{data.payment_date}</span>
                                            </div>
                                        </div>
                                }

                            </div>
                        </div>
                        {data.booking_status === 'Completed' ?
                            <div className="col-12">
                                <AppointmentFiles booking_id={data.id}/>
                            </div>
                            :
                            ''
                        }

                        {/* <div className="col-12">
                            <Recommendation booking_id={data.id}/>
                        </div> */}

                    </div>
                </div>

                {data.booking_status === 'Completed' ?
                    <div className="col-xl-12">
                        <AppointmentReport booking_id={data.id}/>
                    </div>
                    :
                    ''
                }
            </div>
        </div>
    )
}

export default AppointmentDetail
