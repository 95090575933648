import { Backdrop, Box, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import UploadFile from "../../custom_componets/UploadFile";
import {
  InputField,
  NepaliDateInput,
} from "../../custom_componets/FormComponents";
import Loader from "../Loader";
import swal from "sweetalert";
import { useChkLogin } from "../../../middlewares/LoginMiddleware";
import axios from "../../api";
import { Check } from "@mui/icons-material";
import AddressModal from "./AddressModal";
import { schoolRegistrationInitialState } from "./school.schema";
import classNames from "classnames";
import { useMutation, useQuery } from "@tanstack/react-query";
import PackageService from "../../../services/package.service";
import {
  checkValidEmail,
  phoneNumberRegex,
  landlineNumberRegex,
  validatePassword,
} from "../../../utils/common/common.utils";
import { useNavigate } from "react-router-dom";
import * as dayjs from "dayjs";

const debounceSearch = (searchFn) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => searchFn(...args), 500);
  };
};

function SchoolProfileForm(props) {
  const { isLoading, isLoggedIn } = useChkLogin();

  const [mapOpen, setMapOpen] = useState(false);
  const formattedToday = new Date().toISOString().split("T")[0];
  const [inputs, setInputs] = useState({ ...schoolRegistrationInitialState });
  const [errors, setErrors] = useState({});
  const [emailExist, setEmailExist] = useState(false);

  const [packageInfo, setPackage] = useState(undefined);
  const navigate = useNavigate();

  const { isLoading: submitting, mutate: registrationMutate } = useMutation({
    mutationFn: (data) => PackageService.registration(data),
    onSuccess: (response) => {
      swal({
        title: "Success",
        text: `Your form is submitted successfully. ${
          isLoggedIn ? "" : "Please verify your email to login."
        }`,
        icon: "success",
        closeOnClickOutside: false,
      }).then((confirm) => {
        if (confirm) {
          navigate("/login");
        }
      });
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        let errData = error.response.data;
        if (errData) {
          setErrors(errData.errors);
        }
      } else {
        swal({
          title: "Error",
          text: "Something went wrong. Please try again later.",
          icon: "error",
          closeOnClickOutside: false,
        });
      }
    },
  });

  useEffect(() => {
    if (localStorage.getItem("selected_package")) {
      setPackage(JSON.parse(localStorage.getItem("selected_package")));
    }
  }, []);

  const chkDuplicateEmail = useCallback(
    debounceSearch((email) => {
      axios
        .get(`api/check-existing-email?email=${email}`)
        .then((res) => {
          if (res.data.message === "Email Already Existed.") {
            setEmailExist(true);
          } else {
            setEmailExist(false);
          }
        })
        .catch((err) => console.log(err));
    }),
    []
  );

  const { data: provinceOptions } = useQuery({
    queryKey: ["province"],
    queryFn: async () => axios.get("api/provinces"),
  });

  const { data: DistrictOptions } = useQuery({
    queryKey: ["district", inputs?.province_id],
    queryFn: async () => axios.get(`api/districts/${inputs?.province_id}`),
    enabled: Boolean(inputs?.province_id),
  });

  const { data: municipalitiesOptions } = useQuery({
    queryKey: ["municipalities", inputs?.district_id],
    queryFn: async () => axios.get(`api/municipalities/${inputs?.district_id}`),
    enabled: Boolean(inputs?.district_id),
  });

  const chkValidEmail = useCallback(checkValidEmail, []);

  const handleChange = (e) => {
    if (e.target.name === "email") {
      chkDuplicateEmail(e.target.value);
    }
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    if (name === "province_id") {
      handleChange(e);
      setInputs((prev) => ({
        ...prev,
        province_id: value,
        district_id: "",
        municipality_id: "",
        ward_no: "1",
      }));
    } else if (name === "district_id") {
      handleChange(e);
      setInputs((prev) => ({
        ...prev,
        district_id: value,
        municipality_id: "",
        ward_no: "1",
      }));
    } else if (name === "municipality_id") {
      handleChange(e);
      setInputs((prev) => ({
        ...prev,
        municipality_id: value,
        ward_no: "1",
      }));
    } else {
      handleChange(e);
    }
  };

  function setAddressText(addr) {
    setInputs({ ...inputs, company_address: addr });
  }

  if (isLoading) {
    return (
      <Box p={10} display="flex" justifyContent="center">
        <Loader />
      </Box>
    );
  }

  async function handleLatLng(lat, lng) {
    setInputs((prev) => ({ ...prev, latitude: lat, longitude: lng }));
  }

  function handleFile(inputFile, name) {
    setInputs({ ...inputs, [name]: inputFile });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const packageInformation = structuredClone(packageInfo);
    if (packageInformation?.type) {
      delete packageInformation.type;
    }

    const isPhoneNumberValid = (number) => phoneNumberRegex.test(number);
    const isLandlineNumberValid = (number) => landlineNumberRegex.test(number);

    const contactNumberValid = isPhoneNumberValid(inputs?.contact_number);
    const phoneValid = isPhoneNumberValid(inputs?.phone);
    const landlineValid = isLandlineNumberValid(inputs?.landline_number);

    if (contactNumberValid && phoneValid && landlineValid) {
      registrationMutate({ ...inputs, ...packageInformation });
      console.log(landlineValid);
    } else {
      const errors = {};
      if (!contactNumberValid) {
        errors.contact_number = ["Please enter a valid contact number"];
      }
      if (!phoneValid) {
        errors.phone = ["Please enter a valid phone number"];
      }
      if (!landlineValid) {
        errors.landline_number = ["Please enter a valid landline number"];
      }
      setErrors(errors);
    }
  }

  const formattedStartDate = dayjs(inputs.company_start_date).format(
    "YYYY-MM-DD"
  );

  const renderPackageInformation = () => {
    return (
      <div>
        <h3 className="m-0 mb-2 p-0">Package Details</h3>
        <div className="card mb-4">
          <div className="card-body">
            <div className="row g-3">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="text-muted">Package:</label>
                  <div className="input-group">
                    <span className="fs-6 fw-bold">{packageInfo?.type}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="text-muted">Size:</label>
                  <div className="input-group">
                    <span className="fs-6 fw-bold">
                      {packageInfo?.size} Members
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="text-muted">Payment Interval:</label>
                  <div className="input-group">
                    <span className="fs-6 fw-bold text-capitalize">
                      {packageInfo?.payment_interval}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <form className="form-horizontal" onSubmit={handleSubmit}>
        {packageInfo && renderPackageInformation()}
        <h3 className="m-0 mb-2 p-0">School Primary User Details</h3>
        <div className="card">
          <div className="card-body">
            <div className="row g-3">
              <div className="col-md-4">
                <InputField
                  label="First Name"
                  name="first_name"
                  value={inputs.first_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <InputField
                  label="Middle Name"
                  name="middle_name"
                  value={inputs.middle_name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-4">
                <InputField
                  label="Last Name"
                  name="last_name"
                  value={inputs.last_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <InputField
                  label="Email Address"
                  name="email"
                  type="email"
                  value={inputs.email}
                  error={errors?.hasOwnProperty("email")}
                  errorMsg={errors?.email ? errors.email[0] : ""}
                  onChange={handleChange}
                  appendContent={
                    chkValidEmail(inputs?.email) && !emailExist ? (
                      <Check color="success" />
                    ) : null
                  }
                  required
                />
                {inputs?.email?.length > 0 && emailExist && (
                  <small className="text-danger">Email Already Exists.</small>
                )}
              </div>
              <div className="col-md-6">
                <InputField
                  label="Phone Number"
                  name="phone"
                  type="phone"
                  value={inputs.phone}
                  error={errors?.hasOwnProperty("phone")}
                  errorMsg={errors?.phone ? errors.phone[0] : ""}
                  onChange={handleChange}
                  appendContent={
                    phoneNumberRegex.test(inputs?.phone) ? (
                      <Check color="success" />
                    ) : null
                  }
                  required
                />
              </div>
              <div className="col-md-6">
                <InputField
                  label="Password"
                  name="password"
                  type="password"
                  isPasswordToggleable={true}
                  value={inputs.password}
                  onChange={handleChange}
                  error={errors?.hasOwnProperty("password")}
                  errorMsg={errors?.password ? errors.password[0] : ""}
                  required
                />
                <ul
                  style={{ fontSize: 14, paddingLeft: 12 }}
                  className="pt-3 pl-0 opacity-75 d-flex gap-` flex-column"
                >
                  <li
                    className={classNames({
                      "text-success": validatePassword(inputs?.password)
                        ?.hasLength,
                    })}
                  >
                    Password must be at least 8 characters long
                  </li>
                  <li
                    className={classNames({
                      "text-success": validatePassword(inputs?.password)
                        ?.hasLowerCase,
                    })}
                  >
                    Password must be least one lowercase letter,
                  </li>
                  <li
                    className={classNames({
                      "text-success": validatePassword(inputs?.password)
                        ?.hasUpperCase,
                    })}
                  >
                    Password must be one uppercase letter
                  </li>
                  <li
                    className={classNames({
                      "text-success": validatePassword(inputs?.password)
                        ?.hasSpecialChar,
                    })}
                  >
                    Password must be one special character.
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <InputField
                  label="Confirm Password"
                  name="confirm_password"
                  type="password"
                  isPasswordToggleable={true}
                  value={inputs.confirm_password}
                  error={errors?.confirm_password}
                  errorMsg={
                    errors?.confirm_password
                      ? errors.confirm_password.message
                      : null
                  }
                  onChange={handleChange}
                  required
                />
                {inputs?.confirm_password?.length > 0 &&
                  inputs?.confirm_password !== inputs?.password && (
                    <small className="text-danger">
                      Password doesn't match
                    </small>
                  )}
              </div>
            </div>
          </div>
        </div>

        <h3 className="mt-4 p-0 mb-2">School Information</h3>
        <div className="card">
          <div className="card-body">
            <div className="row g-3">
              <div className="col-md-4">
                <InputField
                  label="Owner Name"
                  name="owner_name"
                  value={inputs.owner_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <InputField
                  label="School Name"
                  name="company_name"
                  value={inputs.company_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <InputField
                  label="School Address"
                  name="company_address"
                  value={inputs.company_address}
                  onClick={(e) => setMapOpen(true)}
                  required
                />
              </div>
              <div className="col-md-4">
                <NepaliDateInput
                  label="School Start Date"
                  name="company_start_date"
                  value={formattedStartDate}
                  onChange={handleChange}
                  max={formattedToday}
                  required
                />
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <InputField
                    label="Contact Number"
                    name="contact_number"
                    type="phone"
                    value={inputs.contact_number}
                    error={errors?.hasOwnProperty("contact_number")}
                    errorMsg={
                      errors?.contact_number ? errors.contact_number[0] : ""
                    }
                    appendContent={
                      phoneNumberRegex.test(inputs?.contact_number) ? (
                        <Check color="success" />
                      ) : null
                    }
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-4">
                <InputField
                  label="PAN Number"
                  type="number"
                  name="pan_number"
                  value={inputs.pan_number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <InputField
                  label="Landline"
                  type="phone"
                  name="landline_number"
                  value={inputs.landline_number}
                  error={errors?.hasOwnProperty("landline_number")}
                  errorMsg={
                    errors?.landline_number ? errors.landline_number[0] : ""
                  }
                  onChange={handleChange}
                  appendContent={
                    landlineNumberRegex.test(inputs?.landline_number) ? (
                      <Check color="success" />
                    ) : null
                  }
                />
              </div>

              <div className=" d-flex flex-column col-md-4">
                <label className="mb-2" for="province_id">
                  Select Province : <code>*</code>
                </label>
                <select
                  name="province_id"
                  className="form-select input-field"
                  onChange={handleFieldChange}
                  value={inputs.province_id}
                  required
                >
                  <option value="" disabled selected hidden>
                    Choose Province...
                  </option>
                  {provinceOptions?.data?.data?.provinces &&
                    Object.keys(provinceOptions?.data?.data?.provinces).map(
                      (key) => {
                        return (
                          <option value={key}>
                            {provinceOptions?.data?.data?.provinces[key]}{" "}
                          </option>
                        );
                      }
                    )}
                </select>
              </div>

              <div className="d-flex flex-column col-md-4">
                <label className="mb-2" htmlFor="district_id">
                  Select District: <code>*</code>
                </label>

                <select
                  name="district_id"
                  className={`form-select input-field ${
                    !inputs.province_id ? "pointer-events-none" : ""
                  }`}
                  onChange={handleFieldChange}
                  required
                  disabled={!inputs.province_id}
                >
                  <option value="" disabled selected hidden>
                    Choose District...
                  </option>
                  {inputs.province_id &&
                    DistrictOptions?.data?.data?.districts &&
                    Object.keys(DistrictOptions?.data?.data?.districts).map(
                      (key) => {
                        return (
                          <option key={key} value={key}>
                            {DistrictOptions?.data?.data?.districts[key]}
                          </option>
                        );
                      }
                    )}
                </select>
              </div>

              <div className="d-flex mb-2 flex-column col-md-4">
                <label className="mb-2" htmlFor="municipality_id">
                  Select Municipality: <code>*</code>
                </label>

                <select
                  name="municipality_id"
                  className={`form-select input-field ${
                    !inputs.district_id ? "pointer-events-none" : ""
                  }`}
                  onChange={handleFieldChange}
                  required
                  disabled={!inputs.district_id}
                >
                  <option value="" disabled selected hidden>
                    Choose Municipality...
                  </option>
                  {inputs.district_id &&
                    municipalitiesOptions?.data?.data?.municipality &&
                    municipalitiesOptions.data.data.municipality.map(
                      (municipality) => (
                        <option key={municipality.id} value={municipality.id}>
                          {municipality.english_name}
                        </option>
                      )
                    )}
                </select>
              </div>

              <div
                className={`col-md-4 ${
                  !inputs.municipality_id ? "pointer-events-none" : ""
                }`}
              >
                <InputField
                  label="Ward number"
                  type="number"
                  name="ward_no"
                  value={inputs.ward_no}
                  onChange={handleFieldChange}
                  required
                  placeholder="Select Ward Number (1-99)"
                />
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    School description<code>*</code>
                  </label>
                  <textarea
                    className="form-control mt-2"
                    rows="4"
                    name="description"
                    onChange={handleChange}
                    value={inputs.description}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 className="mt-4 mb-2 p-0">Document Upload</h3>
        <div className="card">
          <div className="card-body">
            <div className="grid row">
              <div className="col d-flex">
                <UploadFile
                  name="company_image"
                  type={"img"}
                  accept={"image/jpeg,image/png,image/jpg"}
                  acceptFileTypes={["jpeg", "png", "jpg"]}
                  label="School Logo"
                  value={inputs.company_image ? [inputs.company_image] : []}
                  setImageInput={(fileG) => {
                    handleFile(fileG[0], "company_image");
                  }}
                  required
                />
              </div>

              <div className="col d-flex">
                <UploadFile
                  name="paper_work_pdf"
                  type={"pdf"}
                  accept={"application/pdf,image/jpeg,image/png,image/jpg"}
                  acceptFileTypes={["pdf", "jpeg", "png", "jpg"]}
                  label="PAN/VAT document"
                  value={inputs.paper_work_pdf ? [inputs.paper_work_pdf] : []}
                  setImageInput={(fileG) => {
                    handleFile(fileG[0], "paper_work_pdf");
                  }}
                  required
                  setErrors={setErrors}
                />
              </div>

              <div className="col d-flex">
                <UploadFile
                  name="school_regd_file"
                  type={"pdf"}
                  accept={"application/pdf,image/jpeg,image/png,image/jpg,"}
                  acceptFileTypes={["pdf", "jpeg", "png", "jpg"]}
                  label="School registration file"
                  value={
                    inputs.school_regd_file ? [inputs.school_regd_file] : []
                  }
                  setImageInput={(fileG) => {
                    handleFile(fileG[0], "school_regd_file");
                  }}
                  setErrors={setErrors}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <button
            className="btn btn-primary d-flex gap-2 align-items-center"
            type="submit"
          >
            {submitting ? <CircularProgress color="inherit" size={20} /> : ""}
            <span> {submitting ? "Loading" : "Submit"}</span>
          </button>
        </div>
      </form>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AddressModal
        open={mapOpen}
        handleClose={() => setMapOpen(false)}
        setLatLong={handleLatLng}
        lat={inputs.latitude}
        lng={inputs.longitude}
        setAddress={setAddressText}
      />
    </div>
  );
}

export default SchoolProfileForm;
