import { Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import Slider from "react-slick";
import UploadImg from "../../images/uploadFile.png";
import "./UploadFileSlick.scss";

function UploadFile(props) {
  const {
    name,
    type,
    label,
    setImageInput,
    value = [],
    accept,
    acceptFileTypes,
    multiple,
    aspectRatio = "1.6/1",
    required = false,
    error,
  } = props;

  const [selFiles, setSelFiles] = useState(
    value === null || value.length === 0 ? null : imgObjectArr(value)
  );

  const fileViewerStyle = {
    height: "80%",
    aspectRatio: "1/1",
    overflow: "hidden",
    padding: "20px 0",
  };
  const iframeStyle = {
    width: "100%",
    height: "100%",
    margin: "auto",
  };

  const containerStyle = {
    backgroundColor: "#e5e4e2",
    width: "100%",
    aspectRatio: aspectRatio,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 10,
  };

  function handleChange(e) {
    const inputFiles = e.target.files;
    handleFile(inputFiles);
  }

  function handleFile(inputFiles) {
    const imgInput = [];
    const files = [];
    Object.keys(inputFiles).forEach((key) => {
      imgInput.push(inputFiles[key]);
      files.push(URL.createObjectURL(inputFiles[key]));
    });
    if (props.setImageInput) {
      setImageInput(imgInput);
    }
    setSelFiles(files);
  }

  function handleDrop(e) {
    e.preventDefault();
    const fileArr = [];
    Object.keys(e.dataTransfer.items).forEach((key) => {
      fileArr.push(e.dataTransfer.items[key].getAsFile());
    });
    handleFile(fileArr);
  }

  function handleDrag(e) {
    e.preventDefault();
    console.log();
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="upload-file-container rounded-lg" style={containerStyle}>
        <div
          className="file-viewer"
          style={fileViewerStyle}
          onDrop={handleDrop}
          onDragOver={handleDrag}
        >
          {selFiles === null ? (
            <img
              src={UploadImg}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                opacity: 0.7,
              }}
            />
          ) : (
            <Slider {...settings}>
              {selFiles.map((selFile, index) =>
                type === "img" ? (
                  <img
                    key={index}
                    src={selFile}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <iframe
                    key={index}
                    src={selFile}
                    frameborder="0"
                    style={iframeStyle}
                  ></iframe>
                )
              )}
            </Slider>
          )}
        </div>
        <label htmlFor={name} className="text-primary pb-2">
          <b>{label}</b>
        </label>
        {acceptFileTypes?.length && (
          <small className="text-xl-start pb-2">
            Please choose{" "}
            {acceptFileTypes.map((type, index) => (
              <b>
                {type} {index !== acceptFileTypes.length - 1 ? ", " : ""}
              </b>
            ))}{" "}
            file type <code>{required ? "*" : "(Optional)"}</code>
          </small>
        )}
        <input
          type="file"
          id={name}
          className="form-control"
          name={name}
          onChange={handleChange}
          accept={accept}
          multiple={multiple}
          required={selFiles ? false : required}
        />
      </div>
      <Typography variant="subtitle1" color="error">
        {error}
      </Typography>
    </>
  );
}

function imgObjectArr(value) {
  const fileArr = [];
  value.forEach((val) => {
    if (val instanceof File) {
      fileArr.push(URL.createObjectURL(val));
    } else {
      fileArr.push(val);
    }
  });
  return fileArr;
}

export default UploadFile;
