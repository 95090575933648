import {useQuery} from "@tanstack/react-query";
import {createContext, useContext, useState} from "react";
import GDBlogs from "./GDBlogs";
import axios from '../../api'
import {Outlet} from "react-router-dom";
import GDBlogHeader from "./GDBlogHeader";
import ErrorBoundary from "../../custom_componets/ErrorBoundry";

const GDBlogContext = createContext(null)


function GDBlogProvider({children}) {
    const menus = useQuery(
        ['blog_menus'],
        async () => (
            axios.get('/api/menu').then(res => res.data).catch(err => err)
        )
    )


    const blogsHome = useQuery(
        ['blogs_home'],
        async () => (
            axios.get('/api/homepage').then(res => res.data).catch(err => err)
        )
    )

    const nextStep = blogsHome.isLoading

    const blogsPopular = useQuery({
        queryKey: ['blogsPopular', nextStep],
        queryFn: async () => (
            axios.get('/api/homepage').then(res => res.data).catch(err => err)
        )
    })

    console.log("Blog Home", blogsHome.data, "Blog Popular", blogsPopular.data)

    // const blogsPopular = useQuery(
    //     ['blogs_popular'],
    //     async () => (
    //         axios.get('/api/homepage?take=4').then(res => res.data).catch(err => err)
    //     )
    // )

    const [blogs, setBlogs] = useState(null)

    return (
        <GDBlogContext.Provider
            value={{
                menus, blogsHome, blogsPopular, blogs, setBlogs
            }}
        >
            <div className="container">
                <GDBlogHeader/>
            </div>
            <ErrorBoundary>
                <Outlet/>
            </ErrorBoundary>
        </GDBlogContext.Provider>
    )
}

export function useBlogDetails() {
    const {blogs, setBlogs} = useContext(GDBlogContext)

    return {blogs, setBlogs}
}

export function useBlogMenu() {
    const {menus} = useContext(GDBlogContext)

    return {...menus}
}

export function useBlogsHome() {
    const {blogsHome} = useContext(GDBlogContext)

    return {...blogsHome}
}

export function useBlogsPopular() {
    const {blogsPopular} = useContext(GDBlogContext)

    return {...blogsPopular}
}

export default GDBlogProvider
