import Pusher from 'pusher-js'
import { useEffect, useState } from 'react';

export function usePusher(channelName="my-channel") {
    const [channel, setChannel] = useState(null)

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: "ap2",
    })

    useEffect(()=>{
        let mounted = true; 
        if(mounted) {
            const channelObj = pusher.subscribe(channelName)
            channelObj.bind('pusher:subscription_succeeded', ()=>{console.log("Channel subscribed", channelName)})
            // channelObj.bind('my-event', (data)=>{
            //     console.log()
            // })
            setChannel(channelObj)
        }

        return(()=>{
            pusher.unsubscribe(channelName)
            setChannel(null)
            mounted = false;
        })
    },[])

    return {channel}
}