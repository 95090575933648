import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Controller} from "react-hook-form";
import dayjs from "dayjs";
import {DATEFORMAT} from "../../../utils/constant/common";
import InputFieldLabel from "../../form/InputFieldLabel";

const DatePickerReactHookFrom = (props) => {
    const { label, control, error, name, required= false } = props;
    return (
        <div className="d-grid">

            <InputFieldLabel
                required={required}
            >
                {label}
            </InputFieldLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { onChange, value } }) => {
                        return (
                            <DatePicker
                                format={DATEFORMAT}
                                slotProps={{
                                    textField: {
                                        error: !!error,
                                    },
                                }}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: '44px',
                                    },
                                }}
                                onChange={(value) => onChange(dayjs(value).format(DATEFORMAT))}
                                value={value ? dayjs(value) : null}
                                className="w-full custom-datepicker bg-white rounded"
                            />
                        );
                    }}
                />
                {error ? <span className="text-danger pt-1" style={{fontSize: 12}}>{error}</span> : ""}
            </LocalizationProvider>
        </div>
    );
};

export default DatePickerReactHookFrom;
