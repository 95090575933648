import "./ProgressTracking.css";
import FillDetails from "./FillDetails"
import OrderPayment from "./OrderPayment"
import {useSearchParams} from "react-router-dom"
import {Box, Card, CardContent, CardHeader, Grid, Stack, Typography} from "@mui/material";
import OrderSummary from "./OrderSummary";
import {useState} from "react";

const ProgressTracking = (props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const currentParams = Object.fromEntries([...searchParams]);
    const [sideContent, setSideContent] = useState(<></>)

    return (
        <Box className='progress-track-tab'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <CardHeader title="Checkout Progress" subheader='Your Checkout Progress'/>
                                <CardContent>
                                    <div
                                        className="d-flex flex-row justify-content-between align-items-center align-content-center">
                                        <span
                                            className={"dot " + (currentParams.progress === "fill-details" ? "big-dot" : "")}/>
                                        <hr className="flex-fill track-line"/>
                                        <span
                                            className={"dot " + (currentParams.progress === "payment" ? "big-dot" : "")}/>
                                        <hr className="flex-fill track-line"/>
                                        <span
                                            className={"d-flex justify-content-center align-items-center dot " + (currentParams.progress === "confirm" ? "big-dot" : "")}>
                      <i className="fa fa-check text-white"/>
                    </span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <div className="d-flex flex-column align-items-start">
                                            <span>Fill in personal Details</span>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <span>Payment</span>
                                        </div>
                                        <div className="d-flex flex-column align-items-end">
                                            <span>Confirmation</span>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            {currentParams.progress === "fill-details" &&
                                <FillDetails setProgress={props.setProgress} setSideContent={setSideContent}/>}
                            {currentParams.progress === "payment" && <OrderPayment setSideContent={setSideContent}/>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box>
                                {sideContent}
                                {/* <OrderSummary /> */}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Card variant="outlined">
                <CardHeader title="Checkout Products" subheader="Review Checkout Products"/>
                <CardContent sx={{py:0}}>

                </CardContent>
              </Card> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
};

export default ProgressTracking;
