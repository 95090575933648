import React from "react";
import Insurance from "../images/insurance.png";
import Prevention from "../images/prevention.png";
import Treatment from "../images/treatment.png";
import GDPlus from "../images/GDPlus.png";
import "../components/Services.css";
import { Box, Card, Grid, Typography } from "@mui/material";

const Services = () => {
  // return (
  //   <div className="container text-white py-5" style={{ textAlign: 'left !important' }}>
  //     <div className="row">
  //       <div className="col-lg-3 col-6 my-2">
  //         <div className="card service-container py-2 h-100">
  //           <div className="card-body">
  //             <div className="d-flex align-items-center flex-wrap">
  //               <div className="col-xxl-6 col-lg-12 col-12 service-image-div">
  //                 <img src={Prevention} alt="" className="service-image" />
  //               </div>
  //               <div className="col-xxl-6 col-lg-12 col-12 align-self-center">
  //                 <h3>Prevention</h3>
  //                 <p className="mb-0">A ruppee spent in prevention today is worth thousands spent on treatment tomorrow.</p>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="col-lg-3 col-6 my-2">
  //         <div className="card service-container py-2 h-100">
  //           <div className="card-body">
  //             <div className="d-flex align-items-center flex-wrap">
  //               <div className="col-xxl-6 col-lg-12 col-5 service-image-div">
  //                 <img src={Treatment} alt="" className="service-image" />
  //               </div>
  //               <div className="col-xxl-6 col-lg-12 col-7 align-self-center">
  //                 <h3>Treatment</h3>
  //                 <p className="mb-0">Get in touch with Top Doctors of Nepal from the comfort of your home.</p>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="col-lg-3 col-6 my-2">
  //         <div className="card service-container py-2 h-100">
  //           <div className="card-body">
  //             <div className="d-flex align-items-center flex-wrap">
  //               <div className="col-xxl-6 col-lg-12 col-5 service-image-div">
  //                 <img src={Insurance} alt="" className="service-image" />
  //               </div>
  //               <div className="col-xxl-6 col-lg-12 col-7 align-self-center">
  //                 <h3>Insurance</h3>
  //                 <p className="mb-0">Stay carefree with Ghargharma doctor insurance protecting you and your family.</p>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="col-lg-3 col-6 my-2">
  //         <div className="card service-container py-2 h-100">
  //           <div className="card-body">
  //             <div className="d-flex align-items-center flex-wrap">
  //               <div className="col-xxl-6 col-lg-12 col-5 service-image-div">
  //                 <img src={Insurance} alt="" className="service-image" />
  //               </div>
  //               <div className="col-xxl-6 col-lg-12 col-7 align-self-center">
  //                 <h3>GD +</h3>
  //                 <p className="mb-0">Extended preventive health care services for all your needs.</p>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <Box className="container text-white py-5" textAlign="left !important">
      <Grid container spacing={1}>
        <GridServiceItem
          title="Prevention"
          description="A ruppee spent in prevention today is worth thousands spent on treatment tomorrow."
          imgSrc={Prevention}
        />
        <GridServiceItem
          title="Treatment"
          description="Get in touch with Top Doctors of Nepal from the comfort of your home."
          imgSrc={Treatment}
        />
        <GridServiceItem
          title="Insurance"
          description="Stay carefree with Ghargharma doctor insurance protecting you and your family."
          imgSrc={Insurance}
        />
        <GridServiceItem
          title="GD +"
          description="Extended preventive health care services for all your needs."
          imgSrc={GDPlus}
        />
      </Grid>
    </Box>
  );
};

function GridServiceItem({ title, description, imgSrc }) {
  return (
    <Grid item lg={3} xs={6}>
      <Card
        className="card service-container h-100"
        sx={{
          cursor: "pointer",
          bgcolor: "#0259A7",
          color: "white",
          borderRadius: 3,
          transition: "all 200ms",
          "&:hover": { transform: { lg: "translateY(-20px)" } },
        }}
      >
        <Box p={1} pb={3} height={"100% !important"} display="flex">
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            gap={1}
          >
            <Box
              sx={{
                width: { xs: "40%", md: "25%" },
                "& img": { width: "100%" },
              }}
              alignSelf={"center"}
              flexShrink={0}
            >
              <img src={imgSrc} alt="" />
            </Box>
            <Box textAlign={{ xs: "center" }}>
              <Typography variant="h4" fontWeight={600}>
                {title}
              </Typography>
              <Typography
                sx={{ mt: { xs: 1 } }}
                lineHeight={1.2}
                component="p"
                variant="subtitle1"
              >
                {description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
}

export default Services;
