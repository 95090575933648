import React, {useEffect} from "react";
import Footer from "../../Footer";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircleChevronRight} from "@fortawesome/free-solid-svg-icons";
import "./SinglePackage.css";
import {useLocation} from "react-router-dom";
import axios from '../../api.js';
import {useState} from "react";
import Loader from "../Loader";
import swal from "sweetalert";

const SinglePackage = () => {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const pkg = location.state.pkg;
    //console.log()
    // const package_id = location.state.package_id;
    // const package_type = location.state.package_type;
    // const description = location.state.description;
    // const visits = location.state.visits;
    // const additional_doctor = location.state.additional_doctor;
    // const fees = location.state.fees;
    // const screening = location.state.screening;
    const token = localStorage.getItem('token');
    const [detail, setDetail] = useState([]);
    const [regular, setRegular] = useState([]);
    const [count, setCount] = useState('');
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        axios.get('/api/screening-service/' + pkg.id)
            .then((res) => {
                //console.log();
                setDetail(res.data.detail);
                setRegular(res.data.regular);
                setCount(res.data.count);
                setLoading(false);
            })
            .catch((err) => {
                console.log()
            })
    }, [])

    useEffect(() => {
        axios
            .get("/api/admin/user-profile", {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                // console.log();
                setUser(res.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log();
            });
    }, [token]);

    const checkUser = () => {
        if (user.gender == null && user.dob == null) {
            swal({
                title: "User Profile",
                text: "Please update your profile!",
                icon: "warning",
            }).then((value) => {
                if (value) {
                    navigate("/user/profile")
                }
            });
        } else {
            navigate("/book-package", {state: {pkg: pkg}})
        }
    }

    const [familysize, setSize] = useState('');
    const [interval, setInterval] = useState('');
    const [registration, setRegistration] = useState('');
    const [monthly, setMonthly] = useState('');
    const [totalmonthly, setTotalMonthly] = useState('');
    const [discounttotalmonthly, setDiscountTotalMonthly] = useState('');
    const [result, setResult] = useState('');
    const [discount, setDiscount] = useState('');
    const calculateFee = () => {
        let fees = pkg.fees.filter(pg => pg.family_size == familysize);
        if (interval === 'Yearly') {
            fees.map((fee) => {
                setRegistration(fee.registration_fee);
                setMonthly(fee.monthly_fee);
                let totalmonth = familysize * fee.monthly_fee * 12;
                let discounttotal = totalmonth - totalmonth * 0.06;
                let result = fee.registration_fee + discounttotal;
                setTotalMonthly(totalmonth);
                setDiscountTotalMonthly(discounttotal);
                setDiscount('6%');
                setResult(result);
            })
        } else if (interval === 'Half Yearly') {
            fees.map((fee) => {
                setRegistration(fee.registration_fee);
                setMonthly(fee.monthly_fee);
                let totalmonth = familysize * fee.monthly_fee * 6;
                let discounttotal = totalmonth - totalmonth * 0.025;
                let result = fee.registration_fee + discounttotal;
                setTotalMonthly(totalmonth);
                setDiscountTotalMonthly(discounttotal);
                setDiscount('2.5%');
                setResult(result);
            })
        } else if (interval === 'Quarterly') {
            fees.map((fee) => {
                setRegistration(fee.registration_fee);
                setMonthly(fee.monthly_fee);
                let totalmonth = familysize * fee.monthly_fee * 4;
                let discounttotal = totalmonth - totalmonth * 0.01;
                let result = fee.registration_fee + discounttotal;
                setTotalMonthly(totalmonth);
                setDiscountTotalMonthly(discounttotal);
                setDiscount('1%');
                setResult(result);
            })
        }
    }

    return (
        <>
            {loading ?
                <div className="text-center">
                    <Loader/>
                </div>
                :
                <div className="silver-package py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <h1 style={{color: '#0259A7'}}> {pkg.package_type}</h1>
                                <h5><b>Total Number of Visits: </b>{pkg.visits}</h5>
                                {pkg.additional_doctor !== null &&
                                    <h5><b>Number of Additional Specialist Doctor: </b>{pkg.additional_doctor}</h5>
                                }
                                <div className="my-4">
                                    <h5><b>Package Description</b></h5>
                                    <p dangerouslySetInnerHTML={{__html: `${pkg.description}`}} className="mb-0"></p>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="mb-0">Package Fee Calculator</h5>
                                    </div>
                                    <div className="card-body">
                                        {/* <input type="number" placeholder="Enter your family size" className="form-control my-2" name="familysize" id="familysize" onChange={(e)=>setSize(e.target.value)}/> */}
                                        <select name="familysize" id="familysize" className="form-select my-2"
                                                onChange={(e) => setSize(e.target.value)}>
                                            <option value="" selected disabled>--Select Family Size--</option>
                                            {pkg.fees != null &&
                                                pkg.fees.map((fee) =>
                                                    <option value={fee.family_size}>{fee.family_size}</option>
                                                )
                                            }
                                        </select>
                                        <select name="interval" id="interval" className="form-select"
                                                onChange={(e) => setInterval(e.target.value)}>
                                            <option value="" selected disabled>--Select Payment Interval--</option>
                                            <option value="Yearly">Yearly</option>
                                            <option value="Half Yearly">Half Yearly</option>
                                            <option value="Quarterly">Quarterly</option>
                                        </select>
                                        {familysize !== '' && interval !== '' &&
                                            <div className="d-grid mt-4">
                                                <button className="btn btn-primary" onClick={calculateFee}>Calculate
                                                </button>
                                            </div>
                                        }
                                        {result ?
                                            <div className="results my-3">
                                                <p>
                                                    <b>Total Registration Fee :</b> Rs. {registration} <br/>
                                                    <b>Monthly Fee Per Person:</b> Rs. {monthly}<br/>
                                                    <b>Total Monthly Fee :</b> Rs. {totalmonthly} ({discount} discount)
                                                    = Rs. {discounttotalmonthly}<br/>
                                                    <b>Total Payable Amount :</b> Rs. {result}
                                                </p>
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container my-5">
                        {/* {pkg.fees !== null &&
            <div className="my-4" >
              <h5><b>Fee Structure</b></h5>
              <table className="table table-striped mt-3 table-responsive">
                <thead style={{backgroundColor:'#0259A7', color:'#fff'}}>
                  <tr>
                    <th>Family Size</th>
                    <th>Registration fee per family</th>
                    <th>Monthly fee per person</th>
                  </tr>
                </thead>
                <tbody>
                  { pkg.fees.map((fee) =>
                    <tr key={fee.id}>
                      <td>{fee.family_size}</td>
                      <td>Rs. {fee.registration_fee}</td>
                      <td>Rs. {fee.monthly_fee}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            } */}

                        <div className="package-category mt-5">
                            <h5><b>Pathological Details</b></h5>
                            <div className="card mt-3">
                                <div className="card-body">
                                    <div className="row content mt-3">
                                        <div className="col-md-6">
                                            <div className="card border-0">
                                                <div className="card-header">
                                                    <h5 style={{fontSize: '18px'}}><b>Detail Screening</b></h5>
                                                </div>
                                                <div className="card-body">
                                                    <ul className="list-unstyled">
                                                        <div className="row">
                                                            {Object.keys(detail).map((service) =>
                                                                <div className="col-md-6 my-2">
                                                                    <li className="mb-2">
                                                                        <FontAwesomeIcon
                                                                            icon={faCircleChevronRight}
                                                                            className="me-2 icon-content"
                                                                        />
                                                                        <span className="silver-text">{service}</span>
                                                                    </li>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card border-0">
                                                <div className="card-header">
                                                    <h5 style={{fontSize: '18px'}}><b>Regular Screening</b></h5>
                                                </div>
                                                <div className="card-body">
                                                    <ul className="list-unstyled">
                                                        <div className="row">
                                                            {Object.keys(regular).map((service) =>
                                                                <div className="col-md-6 my-2">
                                                                    <li className="mb-2">
                                                                        <FontAwesomeIcon
                                                                            icon={faCircleChevronRight}
                                                                            className="me-2 icon-content"
                                                                        />
                                                                        <span className="silver-text">{service}</span>
                                                                    </li>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="package-category mt-5">
                <h6><b>Package Contents</b> </h5>
                <div className="row content">
                  <div className="col-3">
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="me-2 icon-content"
                        />
                        <span className="silver-text">Blood Only </span>
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Sugar Checkup </span>{" "}
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Bone Checkup </span>
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Liver Checkup </span>
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Thriod Checkup</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-3">
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Kidney Checkup</span>{" "}
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Heart Checkup </span>{" "}
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Blood Checkup</span>{" "}
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Stool Checkup</span>{" "}
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Urinal Checkup</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-3">
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Serology</span>
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Vaccination </span>
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Deworming </span>
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Cancer Checkup</span>{" "}
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">RA Factor</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-3">
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">USG</span>
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">
                          General Body Examination/ Counseling
                        </span>
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Eye Checkup </span>
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Teeth Checkup</span>
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Normal X-ray</span>
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">ECG</span>
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <span className="silver-text">Diet Plan</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
                        </div>
                        <div className="container my-3 package-container text-end">
                            {
                                token !== null ? (
                                    <button onClick={checkUser} className="btn btn-primary px-5 py-2 text-white">Buy
                                        Package</button>
                                    // <Link to="/book-package" state={{pkg:pkg}} className="btn btn-primary px-5 py-2 text-white">Buy Package</Link>
                                ) : (
                                    <Link to="/login" className="btn btn-primary px-5 py-2 text-white">Buy
                                        Package</Link>
                                )
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default SinglePackage;
