import React from 'react'
import {Modal} from 'react-bootstrap'

function ReportModal(props) {
    return (
        <Modal
            {...props}
            size="xl"
            arial-labelledby="contained-modla-title-vcenter"
            centered
        >
            <Modal.Body>
                <iframe src={props.pdfLink} style={{height: "800px", width: "1100px"}}></iframe>
            </Modal.Body>
        </Modal>
    )
}

export default ReportModal
