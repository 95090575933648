import {Box, Button, Grid, Modal, Typography} from '@mui/material'
import React, {useState} from 'react'
import {DateFormat} from '../../../../DateFormat'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import InvoiceTemplate from '../../../../../pdfTemplate/InvoiceTemplate'
import KhaltiPayment from '../../../../../images/payment/khaltiPayment.png'
import EsewaPayment from '../../../../../images/payment/khaltiPayment.png'

function getPaymentMethod(payment_method) {
    switch (payment_method) {
        case 'Khalti' :
            return KhaltiPayment
        case 'Esewa':
            return EsewaPayment
        default:
            return
    }
}

function PaymentItem({data}) {
    const [showPdf, setShowPdf] = useState(false)
    const [pdfLink, setPdfLink] = useState(null)

    function handleClick() {
        setShowPdf(true)
    }

    return (
        <Box bgcolor={'#fff'} borderRadius={5} boxShadow={'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;'}>
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Box width={'100%'} sx={{'& img': {width: '80%', aspectRatio: '1/1', m: 'auto'}}}>
                            <img src={getPaymentMethod(data.payment_method)} alt=""/>
                        </Box>
                    </Grid>
                    <Grid item xs={7}>
                        <Box>
                            <Typography variant='h2' fontWeight='600 !important'>
                                Rs {data.amount}
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <Box>
                                <span className='text-muted'>Invoice No:</span>566885
                            </Box>
                            <Box>
                                <span
                                    className='text-muted'>Transaction Date:</span>{DateFormat(data.date).getFullDateText()}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={3} sx={{alignSelf: 'center'}}>
                        <Button variant='outlined' className='rounded-pill' onClick={handleClick}>
                            <Typography variant='h5' sx={{m: '0 !important'}}>
                                Invoice
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box p={2} borderTop={1} borderColor='divider'>
                <Box display='flex' justifyContent='space-between'>
                    <Box>
                        <span className='text-muted'>Address</span>
                        <Typography variant='h5'>{data.address}</Typography>
                    </Box>
                    <Box>
                        <span className='text-muted'>Service name</span>
                        <Typography variant='h5'>{data.service_name}</Typography>
                    </Box>
                </Box>
            </Box>
            <PDFModal
                open={showPdf}
                handleClose={() => setShowPdf(false)}
            />
        </Box>
    )
}

function PDFModal({open, handleClose, pdf}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {/* <iframe src={pdf} frameborder="0" style={{ width: '100%', height: '80vH' }}>
                </iframe> */}
                <InvoiceTemplate/>
            </Box>
        </Modal>
    )
}

export default PaymentItem
