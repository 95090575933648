import axios from "../../api";

export const submitCompanyProfile = async (inputs, token) => {
  try {
    const response = await axios.post(
      "/api/company-school-profile/school-profile",
      inputs,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting company profile:", error);
    throw error;
  }
};

export const getCompanyProfile = async (token) => {
  try {
    const response = await axios.get(
      "/api/company-school-profile/school-profile",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching company profile:", error);
    throw error;
  }
};
