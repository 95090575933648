import {Box, Button} from '@mui/material'
import React, {useContext, useState} from 'react'
import {useEffect} from 'react'
import {InputField, SelectInput, SelectOption} from '../../../../custom_componets/FormComponents'
import axios from "../../../../api"
import generateInitialState from '../helpers/generateInitialState'
import PhoneInput from 'react-phone-input-2'
import {useGlobalFormData} from '../GlobalFormProvider'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'

function AddressDetails({handleBack, handleNext, allFormData, setAllFormData}) {
    const {globalForm, refetchGlobalForm} = useGlobalFormData()
    console.log()
    const [inputs, setInputs] = useState(generateInitialState([
        "perm_house_number",
        "perm_province_id",
        "perm_district_id",
        "perm_municipality_id",
        "perm_ward_id",
        "perm_location",
        "temp_house_number",
        "temp_province_id",
        "temp_district_id",
        "temp_municipality_id",
        "temp_ward_id",
        "temp_location",
        "work_status",
        "account_purpose",
        "source_of_income",
        "annual_income",
        "occupation",
        "pan_number",
        "organization_name",
        "designation",
        "organization_address",
        "organization_number",
    ], globalForm))

    const [errors, setErrors] = useState({})
    const token = useContext(LoginStateContext).state.token

    const permanetLocations = useLocationDetails()
    const tempLocations = useLocationDetails()

    useEffect(() => {
        if (inputs.perm_province_id) permanetLocations.fetchDistrict(inputs.perm_province_id)
        if (inputs.perm_district_id) permanetLocations.fetchMunicipality(inputs.perm_district_id)
        if (inputs.perm_municipality_id) permanetLocations.fetchWard(inputs.perm_municipality_id)
        if (inputs.temp_province_id) tempLocations.fetchDistrict(inputs.temp_province_id)
        if (inputs.temp_district_id) tempLocations.fetchMunicipality(inputs.temp_district_id)
        if (inputs.temp_municipality_id) tempLocations.fetchWard(inputs.temp_municipality_id)
    }, [])

    function copyLocation(e) {
        if (e.target.checked) {
            const getLocation = permanetLocations.getLocation
            tempLocations.setAll(getLocation().district, getLocation().municipality, getLocation().ward)
            setInputs({
                ...inputs,
                temp_house_number: inputs.perm_house_number,
                temp_province_id: inputs.perm_province_id,
                temp_district_id: inputs.perm_district_id,
                temp_municipality_id: inputs.perm_municipality_id,
                temp_ward_id: inputs.perm_ward_id,
                temp_location: inputs.perm_location
            })
        }
    }

    function handleChange(e) {
        setInputs({...inputs, [e.target.name]: e.target.value})
    }

    function handleSubmit(e) {
        e.preventDefault()
        console.log()
        // setAllFormData({ ...allFormData, ...inputs })
        axios.post(`api/kyc/address-information/${globalForm.id}`, inputs, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            console.log()
            handleNext()
            refetchGlobalForm()
        }).catch(err => console.log())
        // handleNext()
    }

    return (
        <React.Fragment>
            <form className='form-horizontal' onSubmit={handleSubmit}>
                <div className="card">
                    <div className="card-header">
                        <h3 className="m-0 p-0">Occupation Details</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <InputField
                                    label="Work Status"
                                    name="work_status"
                                    value={inputs.work_status}
                                    error={errors?.work_status}
                                    errorMsg={errors?.work_status ? errors.work_status.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Account Purpose"
                                    name="account_purpose"
                                    value={inputs.account_purpose}
                                    error={errors?.account_purpose}
                                    errorMsg={errors?.account_purpose ? errors.account_purpose.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Source of Income"
                                    name="source_of_income"
                                    value={inputs.source_of_income}
                                    error={errors?.source_of_income}
                                    errorMsg={errors?.source_of_income ? errors.source_of_income.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Annual Income"
                                    type="number"
                                    name="annual_income"
                                    inputProps={{min: 1}}
                                    value={inputs.annual_income}
                                    error={errors?.annual_income}
                                    errorMsg={errors?.annual_income ? errors.annual_income.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <SelectInput
                                    label="Occupation"
                                    name="occupation"
                                    value={inputs.occupation}
                                    error={errors?.occupation}
                                    errorMsg={errors?.occupation ? errors.occupation.message : null}
                                    onChange={handleChange}
                                    required
                                >
                                    <SelectOption value='Student'>Student</SelectOption>
                                    <SelectOption value='Self Employed'>Self Employed</SelectOption>
                                    <SelectOption value='Salary'>Salary</SelectOption>
                                    <SelectOption value='Other'>Other</SelectOption>
                                </SelectInput>
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="PAN Number"
                                    type="number"
                                    name="pan_number"
                                    value={inputs.pan_number}
                                    error={errors?.pan_number}
                                    errorMsg={errors?.pan_number ? errors.pan_number.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Organization Name"
                                    name="organization_name"
                                    value={inputs.organization_name}
                                    error={errors?.organization_name}
                                    errorMsg={errors?.organization_name ? errors.organization_name.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Designation"
                                    name="designation"
                                    value={inputs.designation}
                                    error={errors?.designation}
                                    errorMsg={errors?.designation ? errors.designation.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Organization Address"
                                    name="organization_address"
                                    value={inputs.organization_address}
                                    error={errors?.organization_address}
                                    errorMsg={errors?.organization_address ? errors.organization_address.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <label className='form-label'>Organization Number</label>
                                <PhoneInput
                                    country={'np'}
                                    onlyCountries={['np']}
                                    disableDropdown
                                    countryCodeEditable={false}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                    }}
                                    value={inputs.organization_number}
                                    onChange={phone => setInputs({...inputs, organization_number: phone})}
                                    inputStyle={{paddingLeft: '48px'}}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h3 className="m-0 p-0">
                            Permanent Address Details
                        </h3>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <InputField
                                    label="Permanent House Number"
                                    type="number"
                                    name="perm_house_number"
                                    value={inputs.perm_house_number}
                                    error={errors?.perm_house_number}
                                    errorMsg={errors?.perm_house_number ? errors.perm_house_number.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <SelectInput
                                    label="Permanent Province"
                                    name="perm_province_id"
                                    value={inputs.perm_province_id}
                                    error={errors?.perm_province_id}
                                    errorMsg={errors?.perm_province_id ? errors.perm_province_id.message : null}
                                    onChange={(e) => {
                                        permanetLocations.fetchDistrict(e.target.value)
                                        handleChange(e)
                                    }}
                                    required
                                >
                                    {
                                        permanetLocations.getLocation().province.map((loc) => (
                                            <SelectOption value={loc.id}>{loc.english_name}</SelectOption>
                                        ))
                                    }
                                </SelectInput>
                            </div>
                            <div className="col-md-4">
                                <SelectInput
                                    label="Permanent District"
                                    name="perm_district_id"
                                    value={inputs.perm_district_id}
                                    error={errors?.perm_district_id}
                                    errorMsg={errors?.perm_district_id ? errors.perm_district_id.message : null}
                                    onChange={(e) => {
                                        permanetLocations.fetchMunicipality(e.target.value)
                                        handleChange(e)
                                    }}
                                    required
                                >
                                    {
                                        permanetLocations.getLocation().district.map((loc) => (
                                            <SelectOption value={loc.id}>{loc.english_name}</SelectOption>
                                        ))
                                    }
                                </SelectInput>
                            </div>
                            <div className="col-md-4">
                                <SelectInput
                                    label="Permanent Municipality"
                                    name="perm_municipality_id"
                                    value={inputs.perm_municipality_id}
                                    error={errors?.perm_municipality_id}
                                    errorMsg={errors?.perm_municipality_id ? errors.perm_municipality_id.message : null}
                                    onChange={(e) => {
                                        permanetLocations.fetchWard(e.target.value)
                                        handleChange(e)
                                    }}
                                    required
                                >
                                    {
                                        permanetLocations.getLocation().municipality.map((loc) => (
                                            <SelectOption value={loc.id}>{loc.english_name}</SelectOption>
                                        ))
                                    }
                                </SelectInput>
                            </div>
                            <div className="col-md-4">
                                <SelectInput
                                    label="Permanent Ward"
                                    name="perm_ward_id"
                                    value={inputs.perm_ward_id}
                                    error={errors?.perm_ward_id}
                                    errorMsg={errors?.perm_ward_id ? errors.perm_ward_id.message : null}
                                    onChange={handleChange}
                                    required
                                >
                                    {
                                        permanetLocations.getLocation().ward.map((loc) => (
                                            <SelectOption value={loc.id}>{loc.ward_name}</SelectOption>
                                        ))
                                    }
                                </SelectInput>
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Permanent Location"
                                    name="perm_location"
                                    value={inputs.perm_location}
                                    error={errors?.perm_location}
                                    errorMsg={errors?.perm_location ? errors.perm_location.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h3 className="m-0 p-0">
                            Temporary Address Details
                        </h3>
                    </div>

                    <div className="card-body">
                        <div className="mb-2 row">
                            <div className="col-4">
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="copy-location"
                                           onChange={copyLocation}/>
                                    <label className='form-check-label' htmlFor="copy-location">If Same As Permanent
                                        Address</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <InputField
                                    label="Temporary House Number"
                                    name="temp_house_number"
                                    value={inputs.temp_house_number}
                                    error={errors?.temp_house_number}
                                    errorMsg={errors?.temp_house_number ? errors.temp_house_number.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <SelectInput
                                    label="Temporary Province"
                                    name="temp_province_id"
                                    value={inputs.temp_province_id}
                                    error={errors?.temp_province_id}
                                    errorMsg={errors?.temp_province_id ? errors.temp_province_id.message : null}
                                    onChange={(e) => {
                                        tempLocations.fetchDistrict(e.target.value)
                                        handleChange(e)
                                    }}
                                    required
                                >
                                    {
                                        tempLocations.getLocation().province.map((loc) => (
                                            <SelectOption value={loc.id}>{loc.english_name}</SelectOption>
                                        ))
                                    }
                                </SelectInput>
                            </div>
                            <div className="col-md-4">
                                <SelectInput
                                    label="Temporary District"
                                    name="temp_district_id"
                                    value={inputs.temp_district_id}
                                    error={errors?.temp_district_id}
                                    errorMsg={errors?.temp_district_id ? errors.temp_district_id.message : null}
                                    onChange={(e) => {
                                        tempLocations.fetchMunicipality(e.target.value)
                                        handleChange(e)
                                    }}
                                    required
                                >
                                    {
                                        tempLocations.getLocation().district.map((loc) => (
                                            <SelectOption value={loc.id}>{loc.english_name}</SelectOption>
                                        ))
                                    }
                                </SelectInput>
                            </div>
                            <div className="col-md-4">
                                <SelectInput
                                    label="Temporary Municipality"
                                    name="temp_municipality_id"
                                    value={inputs.temp_municipality_id}
                                    error={errors?.temp_municipality_id}
                                    errorMsg={errors?.temp_municipality_id ? errors.temp_municipality_id.message : null}
                                    onChange={(e) => {
                                        tempLocations.fetchWard(e.target.value)
                                        handleChange(e)
                                    }}
                                    required
                                >
                                    {
                                        tempLocations.getLocation().municipality.map((loc) => (
                                            <SelectOption value={loc.id}>{loc.english_name}</SelectOption>
                                        ))
                                    }
                                </SelectInput>
                            </div>
                            <div className="col-md-4">
                                <SelectInput
                                    label="Temporary Ward"
                                    name="temp_ward_id"
                                    value={inputs.temp_ward_id}
                                    error={errors?.temp_ward_id}
                                    errorMsg={errors?.temp_ward_id ? errors.temp_ward_id.message : null}
                                    onChange={handleChange}
                                    required
                                >
                                    {
                                        tempLocations.getLocation().ward.map((loc) => (
                                            <SelectOption value={loc.id}>{loc.ward_name}</SelectOption>
                                        ))
                                    }
                                </SelectInput>
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Temporary Location"
                                    name="temp_location"
                                    value={inputs.temp_location}
                                    error={errors?.temp_location}
                                    errorMsg={errors?.temp_location ? errors.temp_location.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <React.Fragment>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Button
                            color="inherit"
                            onClick={() => handleBack(inputs)}
                            sx={{mr: 1}}
                        >
                            Back
                        </Button>
                        <Box sx={{flex: '1 1 auto'}}/>
                        <button type="submit" className="btn btn-primary" onClick={(e) => {
                            console.log()
                            // handleNext()
                        }}>
                            Next
                        </button>
                    </Box>
                </React.Fragment>
            </form>
        </React.Fragment>
    )
}

function useLocationDetails() {
    const [province, setProvince] = useState([])
    const [provinceLoading, setProvinceLoading] = useState(true)
    const [district, setDistrict] = useState([])
    const [districtLoading, setDistrictLoading] = useState(false)
    const [municipality, setMunicipality] = useState([])
    const [municipalityLoading, setMunicipalityLoading] = useState(false)
    const [ward, setWard] = useState([])
    const [wardLoading, setWardLoading] = useState(false)

    useEffect(() => {
        axios.get("api/fetchprovince")
            .then(res => {
                setProvince(res.data)
                setProvinceLoading(false)
            })
            .catch(err => {
                setProvinceLoading(false)
            })
    }, [])

    function fetchDistrict(province) {
        console.log()
        setDistrictLoading(true)
        setMunicipality([])
        setWard([])
        axios.get("api/fetchdistrict?province_id=" + province)
            .then(res => {
                setDistrict(res.data)
                setDistrictLoading(false)
            })
            .catch(err => {
                setDistrictLoading(false)
            })
    }

    function fetchMunicipality(district) {
        setMunicipalityLoading(true)
        setWard([])
        axios.get("api/fetchmun?district_id=" + district)
            .then(res => {
                setMunicipality(res.data)
                setMunicipalityLoading(false)
            })
            .catch(err => {
                setMunicipalityLoading(false)
            })
    }

    function fetchWard(municipality) {
        setWardLoading(true)
        axios.get("api/fetchward?municipality_id=" + municipality)
            .then(res => {
                setWard(res.data)
                setWardLoading(false)
            })
            .catch(err => {
                setWardLoading(false)
                console.log()
            })
    }

    function setAll(distr, muncip, war) {
        setDistrict(distr)
        setMunicipality(muncip)
        setWard(war)
    }

    function getLocation() {
        return {
            province: province,
            district: district,
            municipality: municipality,
            district: district,
            ward: ward,
        }
    }

    return {
        getLocation,
        fetchDistrict,
        fetchMunicipality,
        fetchWard,
        setAll
    }
}


export default AddressDetails
