import React, {useContext, useEffect, useState} from 'react'
import {FileUploader} from 'react-drag-drop-files'
import axios from "../api"
import AuthButton from '../custom_componets/AuthButton'
import {fileTypes, handleFiles, imageTypes, registerRole, renderFile, renderImage} from './BecomeAMember'
import {LoginStateContext} from '../../contexts/LoginStateContext'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

function BecomeDoctor({context}) {
    // const { imageObj, image, fileObj, file, register3, handleImage, handleFile, imageTypes, fileTypes, submitting, renderImage, renderFile, errors3 } = useContext(context)
    const [departments, setDepartment] = useState([])
    const [imageObj, setImageObj] = useState()
    const [image, setImage] = useState()
    const [fileObj, setFileObj] = useState()
    const [file, setFile] = useState()
    const [submitting, setSubmitting] = useState(false)
    const loginContext = useContext(LoginStateContext)
    const navigate = useNavigate()
    const {
        register: register3,
        handleSubmit: handleSubmit3,
        watch: watch3,
        setValue: setFormValue,
        formState: {errors: errors3},
        control: control3
    } = useForm();

    useEffect(() => {
        axios
            .get('/api/admin/department')
            .then((res) => {
                setDepartment(res.data);
            })
            .catch((err) => {
                console.log();
            });
    }, []);

    async function onSubmit(data) {
        setSubmitting(true)
        data.image = image;
        data.file = file;
        registerRole(data, 'api/admin/doctor-profile/store', loginContext.state.token, setSubmitting, navigate)
    }

    return (
        <form className="form" onSubmit={handleSubmit3(onSubmit)}>
            <div className="row">
                <input type="hidden" {...register3("role")} value="4"/>
                <input type="hidden" {...register3("is_verified")} value="0"/>
                <div className="col-md-6">
                    <label className="form-label">NMC/NAMC/NHPC Number</label>
                    <input
                        type="number"
                        className="form-control input-field"
                        required
                        {...register3("nmc_no")}
                    />
                    {errors3.nmc_no?.type === "required" && (
                        <p>Please enter your NMC Number.</p>
                    )}
                </div>
                <div className="col-md-6">
                    <label className="form-label">Salutation</label>
                    <select {...register3("salutation")} required className="form-select input-field">
                        <option value="Dr.">Dr.</option>
                        <option value="Asst. Prof.">Asst. Prof.</option>
                        <option value="Prof.">Prof.</option>
                    </select>
                </div>
                <div className="col-md-6">
                    <label className="form-label">Gender</label>
                    <select {...register3("gender")} required className="form-select input-field">
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                    </select>
                </div>
                <div className="col-md-6">
                    <label className="form-label">Department</label>
                    <select {...register3("department")} required className="form-select input-field">
                        <option value="">-- Select --</option>
                        {departments.map((department) =>
                            <option value={department.id} key={department.id}>{department.department}</option>
                        )}
                    </select>
                    {errors3.department?.type === "required" && (
                        <p>Please select your department.</p>
                    )}
                </div>
                <div className="col-md-6">
                    <label className="form-label">Specialization</label>
                    <input
                        type="text"
                        className="form-control input-field"
                        required
                        {...register3("specialization")}
                    />
                    {errors3.specialization?.type === "required" && (
                        <p>Please enter your specialization.</p>
                    )}
                </div>
                <div className="col-md-6">
                    <label className="form-label">Years Practiced</label>
                    <input
                        type="number"
                        className="form-control input-field"
                        required
                        {...register3("year_practiced")}
                    />
                    {errors3.year_practiced?.type === "required" && (
                        <p>Please enter your years practiced.</p>
                    )}
                </div>
                <div className="col-md-6">
                    <label className="form-label">Qualification</label>
                    <input
                        type="text"
                        className="form-control input-field"
                        required
                        {...register3("qualification")}
                    />
                    {errors3.qualification?.type === "required" && (
                        <p>Please enter your qualification.</p>
                    )}
                </div>
                <div className="col-md-6">
                    <label className="form-label">Address</label>
                    <input type="text" className="form-control input-field" required {...register3("address")} />
                </div>
                <div className="col-md-6">
                    <label htmlFor="password-confirm">
                        Upload your photo
                    </label>

                    <FileUploader handleChange={handleFiles(setImageObj, setImage)} name="image" types={imageTypes}
                                  hoverTitle="Drop here" required/>
                    {image ? <>{renderImage(imageObj)}<span className="text-success">File Name: {image.name}.Uploaded Successfully</span></> : ''}
                    {/* <input type="file" name="image" className="mb-4 form-control" onChange={handleImage} required/> */}
                </div>
                <div className="col-md-6">
                    <label htmlFor="password-confirm">
                        Upload file
                    </label>
                    <FileUploader handleChange={handleFiles(setFileObj, setFile)} name="file" types={fileTypes}
                                  hoverTitle="Drop here" required/>
                    {file ? <>{renderFile(fileObj)}<span className="text-success">File Name: {file.name}.Uploaded Successfully</span></> : ''}
                </div>
                <div className="mt-3 col-md-12">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="doctorChecked"/>
                        <label className="form-check-label" htmlFor="doctorChecked">
                            Agree to our Terms of use and Privacy Policy
                        </label>
                    </div>
                </div>
                <div className="mt-4 d-grid">
                    <AuthButton
                        type='submit'
                        component='button'
                        className='px-5 btn btn-primary'
                        label='Register'
                        loading={submitting}
                    />
                </div>
            </div>
        </form>
    )
}

export default BecomeDoctor
