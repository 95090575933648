import { Box, Button, CircularProgress } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import VaccinationService from "../../../../../services/vaccination.service";
import * as dayjs from "dayjs";
import MedicalHistoryPDF from "../../medical-history/partials/MedicalHistoryPDF";
import FormModalContext from "../../../../custom_componets/globalFormModal/FormModalContext";
import PdfIcon from "../../../../../images/pdf.png";

const UploadedVaccination = (props) => {
  const { childId } = props;
  const { openFormModal } = useContext(FormModalContext);

  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ["parent-children-uploaded-vaccination"],
    queryFn: () => VaccinationService.getVaccinationHistory(childId),
    enabled: Boolean(childId),
  });

  const renderLoader = () => {
    return (
      <td colSpan={6}>
        <div className="d-flex gap-3 justify-content-center align-items-center py-5">
          <CircularProgress /> Loading...
        </div>
      </td>
    );
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case "rejected_by_admin":
        return <span className="badge badge-danger">Rejected by Admin</span>;
      case "approved":
        return <span className="badge badge-success">Approved</span>;
      case "rejected_by_doctor":
        return <span className="badge badge-warning">Rejected by Doctor</span>;
      default:
        return <span className="badge badge-warning">Pending</span>;
    }
  };

  const renderUI = () => {
    if (isLoading) {
      return renderLoader();
    }

    if (data?.length) {
      return (
        <>
          {data?.map((item, index) => {
            return (
              <tr key={item.id}>
                <td data-cell="S.N.">{index + 1}</td>
                <td data-cell="File">
                  <div
                    role="button"
                    onClick={() =>
                      openFormModal(
                        <MedicalHistoryPDF
                          pdf={item.report_path}
                          name={"Vaccination Details"}
                        />
                      )
                    }
                  >
                    <div>
                      <img src={PdfIcon} alt="" style={{ height: "40px" }} />
                    </div>
                  </div>
                </td>
                <td data-cell="Vaccination Date">
                  {dayjs(item.vaccination_date).format("YYYY-MM-DD")}
                </td>
                <td data-cell="Transcribed by">
                  {item?.doctor === null ? "-" : item.doctor.user.name}
                </td>
                <td data-cell="Status">{getStatusBadge(item.status)}</td>
                <td data-cell="Remarks">{item.remarks ?? "-"}</td>
              </tr>
            );
          })}
        </>
      );
    }

    return (
      <tr>
        <td colSpan={6}>
          <p className="text-center">No data found.</p>
        </td>
      </tr>
    );
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-grid gap-2 mb-4">
          <div className="d-flex align-items-center justify-content-between pb-3">
            <h4 className="fs-5 fw-bolder lh-sm">
              Uploaded Vaccination Documents
            </h4>
            <Button
              startIcon={<AddCircle />}
              variant="contained"
              color="primary"
              onClick={() => navigate(`/parent/vaccination/add?id=${childId}`)}
            >
              Upload Vaccination Document
            </Button>
          </div>

          <table className="table border-left border-right border-top responsive-table">
            <thead>
              <Box component="tr" bgcolor={"#"}>
                <th data-cell="S.N.">
                  <span className="text-muted">S.N.</span>
                </th>
                <th data-cell="File">
                  <span className="text-muted">File</span>
                </th>
                <th data-cell="Vaccination Date">
                  <span className="text-muted">Vaccination Date</span>
                </th>
                <th data-cell="Transcribed by">
                  <span className="text-muted">Transcribed by</span>
                </th>
                <th data-cell="Status">
                  <span className="text-muted">Status</span>
                </th>
                <th data-cell="Remarks">
                  <span className="text-muted">Remarks</span>
                </th>
              </Box>
            </thead>
            <tbody>{renderUI()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UploadedVaccination;
