import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../api.js";
import SpecialityCard from "./SpecialityCard.js";
import "./AllSpeciality.css";

const AllSpeciality = () => {
  const [departments, setDepartment] = useState([]);
  useEffect(() => {
    axios
      .get("/api/admin/department")
      .then((res) => {
        setDepartment(res.data);
      })
      .catch((err) => {
        console.log();
      });
  }, []);
  return (
    <div className="container-fluid px-0 speciality">
      <div className="text-center py-5 bg-speciality">
        <h1>Our Specialities</h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb justify-content-center">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Specialities
            </li>
          </ol>
        </nav>
      </div>
      <div className="container my-5">
        <div className="d-flex">
          <h3 className="mb-4">Select a Speciality</h3>{" "}
          <p
            style={{ fontSize: "16px", marginTop: "10px", marginLeft: "16px" }}
          >
            * This section will be enabled soon. *
          </p>
        </div>

        <div className="row">
          {departments.map((department) => (
            <div className="col-lg-3 col-md-4 col-6 my-2">
              <SpecialityCard department={department} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllSpeciality;
