import React from 'react'
import {useQuery} from '@tanstack/react-query';
import axios from '../../../api.js';
import {useContext} from 'react';
import {LoginStateContext} from '../../../../contexts/LoginStateContext.js';
import Loader from '../../Loader.js';
import ScreeningTab from './ScreeningTab.js';
import './LabReport.css';
import {Alert, AlertTitle, Box, Card} from '@mui/material'
import MedicalHistory from '../medicalHistory/MedicalHistory.jsx';
import LabTestBooking from '../labTestBooking/LabTestBooking.js';
import RangeChart from './RangeChart.js';
import PackageStatus from '../packageStatuses/PackageStatus.jsx';
import LabReportsPage from '../labReports/LabReportsPage.jsx';
import LabReportContent from './LabReportContent.jsx';
import {useState} from 'react';
import PathologyReport from './PathologyReport.jsx';

const LabReport = () => {
    const [tabValue, setTabValue] = useState(0)
    const loginContext = useContext(LoginStateContext)
    const userData = loginContext.state.data
    const userpackagescreening = useQuery(
        ['package-screening-data', userData.id],
        async () => {
            return axios.get('/api/admin/userpackage', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )


    if (userpackagescreening.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }

    const screening = userpackagescreening.data

    // return (
    //     <LabReportsPage />
    // )

    return (
        <>
            <LabReportsPage setTabValue={setTabValue} tabValue={tabValue}/>
            <Box variant='outlined'>
                {
                    tabValue === 0 && <PathologyReport/>
                }
                {
                    tabValue === 1 && <LabTestBooking/>
                }
                {
                    tabValue === 2 && <MedicalHistory/>
                }
            </Box>

        </>
    )
}

// function backup() {
//     return (
//         <div className="mt-5 card">
//             <div className="card-body">
//                 <ul className="nav nav-tabs" id="myTab" role="tablist">

//                     <li className="nav-item" role="presentation">
//                         <button className="nav-link active" id="pathology-screening-tab" data-bs-toggle="tab" data-bs-target="#pathology-screening" type="button" role="tab" aria-controls="pathology-screening" aria-selected="true">Pathology Screening</button>
//                     </li>
//                     <li className="nav-item" role="presentation">
//                         <button className="nav-link" id="lab-test-tab" data-bs-toggle="tab" data-bs-target="#lab-test" type="button" role="tab" aria-controls="lab-test" aria-selected="true">Lab Test Report</button>
//                     </li>
//                     <li className="nav-item" role="presentation">
//                         <button className="nav-link" id="external-medical-tab" data-bs-toggle="tab" data-bs-target="#external-medical" type="button" role="tab" aria-controls="external-medical" aria-selected="true">External Medical Details</button>
//                     </li>
//                     {/* {screening.package !== null && screening.package.dates.length !== 0 ?
//                         screening.package.dates.filter(date => date.status === 'Completed').map((screenings) =>
//                             <li className="nav-item" role="presentation" key={screenings.id}>
//                                 <button className="nav-link" id={`${screenings.status}${screenings.id}-tab`} data-bs-toggle="tab" data-bs-target={`#${screenings.status}${screenings.id}`} type="button" role="tab" aria-controls={`${screenings.status}${screenings.id}`} aria-selected="false">{screenings.screening.screening}</button>
//                             </li>
//                         )
//                         :
//                         ''
//                     } */}
//                 </ul>
//                 <div className="tab-content" id="myTabContent">
//                     <div className="tab-pane fade show active" id="pathology-screening" role="tabpanel" aria-labelledby="pathology-screening-tab">
//                         {screening.package !== null && screening.package.dates.length !== 0 ?
//                             <>
//                                 {
//                                     screening.package.dates.filter(date => date.status === 'Completed').length > 0
//                                         ?
//                                         <>
//                                             <ul className="mb-0 p-2 nav nav-tabs screening_tab" id="screeningTab" role="tablist">
//                                                 {screening.package.dates.filter(date => date.status === 'Completed').slice(0, 1).map((screenings) =>
//                                                     <li className="nav-item" role="presentation" key={screenings.id}>
//                                                         <button className="nav-link active" id={`${screenings.status}${screenings.id}-tab`} data-bs-toggle="tab" data-bs-target={`#${screenings.status}${screenings.id}`} type="button" role="tab" aria-controls={`${screenings.status}${screenings.id}`} aria-selected="false">{screenings.screening.screening}</button>
//                                                     </li>
//                                                 )
//                                                 }
//                                                 {screening.package.dates.filter(date => date.status === 'Completed').slice(1).map((screenings) =>
//                                                     <li className="nav-item" role="presentation" key={screenings.id}>
//                                                         <button className="nav-link" id={`${screenings.status}${screenings.id}-tab`} data-bs-toggle="tab" data-bs-target={`#${screenings.status}${screenings.id}`} type="button" role="tab" aria-controls={`${screenings.status}${screenings.id}`} aria-selected="false">{screenings.screening.screening}</button>
//                                                     </li>
//                                                 )
//                                                 }
//                                             </ul>
//                                             <div className="border tab-content" id="screeningTabContent">
//                                                 <ScreeningTab screening={screening} />
//                                             </div>

//                                             <div className="mt-5 card">
//                                                 <div className="card-body">
//                                                     <h6 className='fontStyle' style={{ color: '#1976d2' }}>View Test Chart</h6>
//                                                     <RangeChart />
//                                                 </div>
//                                             </div>
//                                         </>
//                                         :
//                                         <Alert severity='error'>
//                                             <AlertTitle>No Report</AlertTitle>
//                                             Medical reports not published from lab yet.
//                                         </Alert>
//                                 }


//                             </>
//                             :
//                             <PackageStatus data={userpackagescreening.data.package} />
//                             // <Alert severity="error">
//                             //     <AlertTitle>No Pathology Records.</AlertTitle>
//                             //     Pathology screening not done yet!
//                             // </Alert>
//                         }
//                     </div>
//                     <div className="tab-pane fade show" id="lab-test" role="tabpanel" aria-labelledby="lab-test-tab">
//                         <LabTestBooking />
//                     </div>
//                     <div className="tab-pane fade show" id="external-medical" role="tabpanel" aria-labelledby="external-medical-tab">
//                         <MedicalHistory />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

export default LabReport
