import React from 'react'
import ContentContainer from './ContentContainer'
import {Box, Skeleton, Stack} from '@mui/material'

function BlogDetailSkeleton() {
    return (
        <ContentContainer>
            <Stack gap={6}>
                <Box
                    width='100%'
                    overflow={'hidden'}
                    sx={{
                        aspectRatio: '1.8/1',
                        borderRadius: 1,
                        '& img': {
                            width: '100%',
                            height: '100%'
                        }
                    }}
                >
                    <Skeleton width='100%' height='100%' variant='rectagular'/>
                </Box>
                <Stack gap={2}>
                    <Box width='100%'>
                        <Skeleton variant='rectangular' width='100%' height='24px'/>
                        <Skeleton variant='rectangular' width='20%' height='24px' sx={{mt: 1}}/>
                    </Box>
                    <Box
                        display='flex'
                        alignItems='center'
                        gap={1}
                    >
                        <Skeleton variant='circular' width='50px' height='50px'/>
                        <Stack gap={1}>
                            <Skeleton variant='rectangular' width={180} height={28}/>
                            <Box
                                display='flex'
                                alignItems={'center'}
                                gap={1}
                            >
                                <Skeleton variant='rectangular' width={160} height={24}/>
                            </Box>
                        </Stack>
                    </Box>
                    <Box
                        display='flex'
                        alignItems='center'
                        gap={2}
                    >
                        <Box
                            display='flex'
                            alignItems='center'
                            gap={1}
                        >
                            <Skeleton variant='rectangular' width={24} height={24}/>
                            <Skeleton variant='rectangular' width={72} height={24}/>
                        </Box>
                        <Box>
                            <Skeleton variant='rectangular' width={24} height={24}/>
                        </Box>
                        <Box>
                            <Skeleton variant='rectangular' width={24} height={24}/>
                        </Box>
                        <Box>
                            <Skeleton variant='rectangular' width={24} height={24}/>
                        </Box>
                    </Box>
                </Stack>
                <Box
                    sx={{
                        '& *': {
                            maxWidth: '98vW',
                            margin: 'auto',
                        }
                    }}
                    display='flex'
                    flexDirection='column'
                    gap={4}
                >
                    <Box width={'100%'}>
                        {
                            [...Array(15).keys()].map((item) => (
                                <Skeleton variant='text' sx={{fontSize: '1rem'}}/>
                            ))
                        }
                    </Box>
                    <Box width={'100%'} sx={{aspectRatio: '1.8/1'}}>
                        <Skeleton variant='rectangular' width='100%' height='100%'/>
                    </Box>
                    <Box width='100%'>
                        <Skeleton variant='text' sx={{fontSize: '1.8rem', ml: 0, mb: 1}} width='30%'/>
                        {
                            [...Array(20).keys()].map((item) => (
                                <Skeleton variant='text' sx={{fontSize: '1rem'}}/>
                            ))
                        }
                    </Box>
                </Box>
            </Stack>
        </ContentContainer>
    )
}

export default BlogDetailSkeleton
