import {ExpandMoreOutlined, ExpandOutlined} from '@mui/icons-material'
import {Accordion, AccordionDetails, AccordionSummary, styled, Typography} from '@mui/material'
import React, {useState} from 'react'

const ConsultAccordion = styled(Accordion)({})

const ConsultAccordionSummary = styled(AccordionSummary)({
    backgroundColor: '#88BDFB',
    margin: '0 !important',
    '& .MuiAccordionSummary-content': {
        margin: 0
    },
    '&.Mui-expanded': {
        margin: '0 !important',
    }
})

function ConsultationFindings({title, children}) {
    const [open, setOpen] = useState(true)
    return (
        <Accordion
            expanded={open}
        >
            <ConsultAccordionSummary expandIcon={<ExpandMoreOutlined/>} onClick={() => setOpen(prev => !prev)}>
                <Typography variant='h1' sx={{m: '0 !important', textTransform: 'uppercase'}}>{title}</Typography>
            </ConsultAccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default ConsultationFindings
