import React, { useEffect, useState } from "react";
import "../components/Appointment.css";
// import General from '../images/speciality/general.png'
// import Eye from '../images/speciality/eye.png'
// import Dentist from '../images/speciality/dentist.png'
// import Nutrition from '../images/speciality/nutrition.png'
// import Pediatric from '../images/speciality/pediatric.png'
import Slider from "react-slick";
import axios from "./api.js";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import SpecialityCard from "./pages/speciality/SpecialityCard";
import { Box, Skeleton, Stack } from "@mui/material";

const Appointment = () => {
  const [departments, setDepartment] = useState([]);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get("/api/admin/department")
      .then((res) => {
        setDepartment(res.data);
      })
      .catch((err) => {
        console.log();
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  if (isLoading) {
    return (
      <div className="appointment">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-8 heading">
              <p>Book an appointment for an in-person or video consultation</p>
              <p>Choose accurate doctor as per the specialization</p>
            </div>
            <div className="col-md-4 text-end">
              <Skeleton
                variant="rectangular"
                width="150px"
                height="40px"
                sx={{ ml: "auto" }}
              />
            </div>
          </div>
          {/* <div className="d-flex mt-4 justify-content-between flex-wrap"> */}
          <Slider {...settings} className="mt-4 p-5 custom-slick">
            {[...Array(10).keys()].map((item) => (
              <Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  alignItems={"center"}
                >
                  <Skeleton variant="circular" width={100} height={100} />
                  <Skeleton variant="rectangular" width={90} height={24} />
                  <Skeleton variant="rectangular" width={70} height={16} />
                  <Skeleton variant="rectangular" width={100} height={25} />
                </Box>
              </Box>
            ))}
          </Slider>
          {/* </div> */}
        </div>
      </div>
    );
  }
  return (
    <div className="appointment">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-8 heading">
            <p>Book an appointment for an in-person or video consultation</p>
            <div className="d-flex">
              <p
                style={{ fontSize: "18px", fontWeight: "400", color: "black" }}
              >
                Choose accurate doctor as per the specialization
              </p>
              <p
                style={{
                  fontSize: "16px",
                  marginLeft: "8px",
                  marginTop: "12px",
                }}
              >
                * This section will be enabled soon. *
              </p>
            </div>
          </div>
          <div className="col-md-4 text-end">
            <Link
              to="/specialities"
              className="btn btn-outline-light speciality-btn"
            >
              View all Specialities
            </Link>
          </div>
        </div>
        {/* <div className="d-flex mt-4 justify-content-between flex-wrap"> */}
        <Slider {...settings} className="mt-4 p-5 custom-slick">
          {departments.map((department) => (
            <SpecialityCard department={department} />
          ))}
        </Slider>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Appointment;
