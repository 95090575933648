import React from "react";
import InfoModal from "../infoModal";
import { Typography } from "@mui/material";
import { LabTestOptions } from "../../utils/constant/common";

const LabModal = () => {
  return (
    <Typography>
      <h6 className="fw-bold mb-4">Lab Tests</h6>
      {LabTestOptions.map((item, index) => (
        <div>
          {index + 1}. {item}
        </div>
      ))}
    </Typography>
  );
};

const LabInfo = ({ id }) => {
  return (
    <InfoModal
      title={"Click to view lab test informations."}
      modal={<LabModal />}
    />
  );
};

export default LabInfo;
