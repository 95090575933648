import axios from '../../../api'
import React, {useState} from 'react'
import {useReducer} from 'react'
import {useContext} from 'react'
import ReloadComponent, {ReloadContext} from '../../../custom_componets/ReloadComponent'

function ChangePassword() {
    return (
        <div className="change-pwd">
            <div className="row justify-content-center">
                <div className="col-8">
                    <div className="card">
                        <div className="card-header">
                            <h4><b>Change Password</b></h4>
                        </div>

                        <div className="card-body">
                            {/* <div className="alert alert-success">
                                Password Changed Successfully!
                            </div> */}
                            <ReloadComponent>
                                <ChangePwdForm/>
                            </ReloadComponent>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const initialState = {
    error: '',
    currentPwd: '',
    newPwd: '',
    confirmPwd: ''
}

const reducer = (state, action) => {
    switch (action.type) {
        case "CHANGE_CUR":
            return {
                ...state,
                currentPwd: action.payload
            }
        case "CHANGE_NEW":
            return {
                ...state,
                newPwd: action.payload
            }
        case "CHANGE_CONF":
            return {
                ...state,
                confirmPwd: action.payload
            }
    }
}


function ChangePwdForm() {
    const reloadContext = useContext(ReloadContext)
    // reloadContext.dispatch({type:"STATE_ADD", payload:})
    const [state, dispatch] = useReducer(initialState, reducer)
    const status = "success"
    const [data, setData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    })

    const token = localStorage.getItem('token')

    const submitForm = (e) => {
        e.preventDefault();
        reloadContext.setError('')
        const postData = {
            ['current-password']: data.currentPassword,
            ['new-password']: data.newPassword,
            ['new-password']: data.confirmPassword,
        }
        axios.post("api/change-old-password", postData,
            {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then(res => {
                if (res.status === 200) {
                    reloadContext.setSuccess(res.data.success)
                    reloadContext.setReload(true)
                    // console.log()
                }
            })
            .catch(err => {
                if (err.response.status === 400) {
                    reloadContext.setError(err.response.data.error)
                }
            })
    }

    return (
        <>
            {
                reloadContext.error &&
                <div className="alert alert-danger d-flex justify-content-between">
                    <span>Your Current Password Doesn't match</span>
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </div>
            }

            {
                reloadContext.success &&
                <div className="alert alert-success d-flex justify-content-between">
                    <span>{reloadContext.success}</span>
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </div>
            }

            <form onSubmit={submitForm}>
                <div className="form-group">
                    <label htmlFor="currentPwd">Current Password</label>
                    <input type="password" className="form-control" id="currentPwd" aria-describedby="pwdHelp"
                           placeholder="Enter Your Current Password" required
                           onChange={(e) => setData({...data, currentPassword: e.target.value})}/>
                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="form-group">
                    <label htmlFor="newPassword">New Password</label>
                    <input type="password" className="form-control" id="newPassword" placeholder="Enter new password"
                           required onChange={(e) => setData({...data, newPassword: e.target.value})}/>
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm Password"
                           required onChange={(e) => setData({...data, confirmPassword: e.target.value})}/>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </>
    )
}

export default ChangePassword
