import { useQuery } from "@tanstack/react-query";
import React, { useContext } from "react";
import axios from "../../../api";
import { useChkLogin } from "../../../../middlewares/LoginMiddleware";
import { Alert, AlertTitle, Avatar, Box, Tooltip } from "@mui/material";
import ImgPlaceHolder from "../../../../images/receptionist.png";
import Loader from "../../Loader";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import * as dayjs from "dayjs";
import {
  Cancel,
  Close,
  DateRangeOutlined,
  Done,
  Pending,
  PendingOutlined,
  PeopleOutline,
} from "@mui/icons-material";
import FormModalContext from "../../../custom_componets/globalFormModal/FormModalContext";
import StudentRequests from "./StudentRequests";

function StudentDeactivateRequest() {
  const loginObj = useChkLogin();
  const navigate = useNavigate();
  const { openFormModal } = useContext(FormModalContext);
  const { data, isLoading, refetch } = useQuery(
    ["deactivate-list", loginObj.token],
    async () =>
      axios
        .get(
          `api/company-school-profile/deactivate-requests/${loginObj.data.school_profile.id}`,
          { headers: { Authorization: `Bearer ${loginObj.token}` } }
        )
        .then((res) => res.data)
        .catch((err) => err)
  );
  console.log("Deactivate requests", data);
  const { data: pkg, isLoading: pkgLoading } = useQuery(
    ["my-package", loginObj.token],
    async () =>
      axios
        .get("/api/admin/userpackage", {
          headers: { Authorization: `Bearer ${loginObj.token}` },
        })
        .then((res) => {
          console.log(res.data);
          return res.data;
        })
  );

  function cancelDeactivation(e, id) {
    axios
      .post(
        `api/company-school-profile/cancel-deactivate-users/${id}`,
        { reject_reason: "cancelled" },
        { headers: { Authorization: `Bearer ${loginObj.token}` } }
      )
      .then((res) => {
        refetch();
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
      });
  }

  if (isLoading || pkgLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center gap-4">
        <Loader />
      </div>
    );
  }
  console.log("Data is", data);
  return (
    <div className="container">
      <div className="row">
        {/* <div className="col-4">
                    <div className="card">
                        <div className="card-header">
                            <span className='fs-5 fw-bold'>Deactivate Requests</span>
                            <br />
                            <span className='fs-6 fw-light'>These are your deactivate requests</span>
                        </div>
                        <div className="card-body">
                            {
                                isLoading ? <h2>Loading..</h2>
                                    :
                                    data.length === 0 ?
                                        <Alert severity='error'>
                                            <AlertTitle>No Requests Found.</AlertTitle>
                                        </Alert>
                                        :
                                        <Box className="row" sx={{
                                            '& > div:not(.active) > .card-header': {
                                                bgcolor:''
                                            },
                                            '& > .active > .card-header': {
                                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;'
                                            }
                                        }}>
                                            <div className="col-12">
                                                <div className="card-header">
                                                    <div className="row align-items-center">
                                                        <div className='col-xl-7'>
                                                            <div>
                                                                <span className="fs-6 fw-bold">
                                                                    July 1st, 2023
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span className="fs-6" style={{ fontWeight: 550 }}>
                                                                    12:30 PM
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-5 d-flex">
                                                            <span className="badge badge-success p-2">
                                                                Request Successful
                                                            </span>
                                                        </div>
                                                        <div className='col-xl-4'>
                                                            <span className="fs-6 fw-light" role='button'>
                                                                View All Students
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-12 active">
                                                <div className="card-header border-top">
                                                    <div className="row align-items-center">
                                                        <div className='col-7'>
                                                            <div>
                                                                <span className="fs-6 fw-bold">
                                                                    July 1st, 2023
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span className="fs-6" style={{ fontWeight: 550 }}>
                                                                    12:30 PM
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-5 d-flex">
                                                            <span className="badge badge-success p-2">
                                                                Request Successful
                                                            </span>
                                                        </div>
                                                        <div className='col-4'>
                                                            <span className="fs-6 fw-light" role='button'>
                                                                View All Students
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="card-header border-top">
                                                    <div className="row align-items-center">
                                                        <div className='col-7'>
                                                            <div>
                                                                <span className="fs-6 fw-bold">
                                                                    July 1st, 2023
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span className="fs-6" style={{ fontWeight: 550 }}>
                                                                    12:30 PM
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-5 d-flex">
                                                            <span className="badge badge-success p-2">
                                                                Request Successful
                                                            </span>
                                                        </div>
                                                        <div className='col-4'>
                                                            <span className="fs-6 fw-light" role='button'>
                                                                View All Students
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="card-header border-top">
                                                    <div className="row align-items-center">
                                                        <div className='col-7'>
                                                            <div>
                                                                <span className="fs-6 fw-bold">
                                                                    July 1st, 2023
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span className="fs-6" style={{ fontWeight: 550 }}>
                                                                    12:30 PM
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-5 d-flex">
                                                            <span className="badge badge-success p-2">
                                                                Request Successful
                                                            </span>
                                                        </div>
                                                        <div className='col-4'>
                                                            <span className="fs-6 fw-light" role='button'>
                                                                View All Students
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </Box>
                            }

                        </div>
                    </div>
                </div> */}
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div>
                <span className="fw-bold fs-5">
                  Deactivate Student Requests
                </span>
              </div>
              <div>
                {/* <span className="fs-6 fw-light">July 1st, 2023 12:30PM</span> */}
              </div>
            </div>
            <div className="card-body">
              <table className="table border-left border-right border-top responsive-table">
                <thead>
                  <Box component="tr" bgcolor={"#"}>
                    <th>
                      <span className="text-muted">S.N.</span>
                    </th>
                    <th>
                      <span className="text-muted">Request Date</span>
                    </th>
                    <th>
                      <span className="text-muted">Total Students</span>
                    </th>
                    <th>
                      <span className="text-muted">Status</span>
                    </th>
                    <th>
                      <span className="text-muted">Action</span>
                    </th>
                  </Box>
                </thead>
                <tbody>
                  {data.map((item, idx) => (
                    <tr key={item.id}>
                      <td data-cell="S.N.">
                        <span className="">{idx + 1}</span>
                      </td>
                      <td data-cell="Request Date">
                        <div className="d-flex gap-1 align-items-center">
                          <DateRangeOutlined className="fs-6" />
                          <span className="">
                            {dayjs(item.created_at).format("YYYY-MM-DD")}
                          </span>
                        </div>
                      </td>
                      <td data-cell="Total Students">
                        <div className="d-flex gap-1 align-items-center">
                          <PeopleOutline className="fs-6" />
                          <span className="">
                            {item.students.length} students
                          </span>
                        </div>
                      </td>
                      <td data-cell="Status">
                        {item.status === 0 ? (
                          <div
                            className="d-flex align-items-center gap-1 badge badge-info"
                            style={{ width: "fit-content" }}
                          >
                            <Pending className="fs-6" />
                            <span className="">Pending</span>
                          </div>
                        ) : item.status === 1 ? (
                          <div
                            className="d-flex align-items-center gap-1 badge badge-success"
                            style={{ width: "fit-content" }}
                          >
                            <Done className="fs-6" />
                            <span className="">Approved</span>
                          </div>
                        ) : item.status === 2 ? (
                          <div
                            className="d-flex align-items-center gap-1 badge badge-danger"
                            style={{ width: "fit-content" }}
                          >
                            <Cancel className="fs-6" />
                            <span className="">Rejected</span>
                          </div>
                        ) : (
                          <div
                            className="d-flex align-items-center gap-1 badge badge-warning"
                            style={{ width: "fit-content" }}
                          >
                            <Cancel className="fs-6" />
                            <span className="">Cancelled</span>
                          </div>
                        )}
                      </td>
                      <td data-cell="Action">
                        <button
                          className="btn btn-primary me-2"
                          onClick={(e) =>
                            openFormModal(<StudentRequests data={item} />)
                          }
                        >
                          View
                        </button>
                        {item.status === 0 && (
                          <button
                            className="btn btn-danger"
                            onClick={(e) => cancelDeactivation(e, item.id)}
                          >
                            Cancel
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* <table className='table border-left border-right border-top'>
                                <thead>
                                    <Box component='tr' bgcolor={'#'}>
                                        <th className='text-muted'>S.N.</th>
                                        <th><span className='text-muted'>Student Name</span></th>
                                        <th><span className="text-muted">Username</span></th>
                                        <th><span className="text-muted">Request Date</span></th>
                                        <th><span className='text-muted'>Request Status</span></th>
                                        <th><span className="text-muted">Request Rejected Reason</span></th>
                                    </Box>
                                </thead>
                                <tbody>
                                    {
                                        (function () {
                                            let count = 1;
                                            return (
                                                data.map((requests, num) => {
                                                    return (
                                                        requests.students.map((item, idx) => (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    <span className="fs-6">{count++}</span>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <div>
                                                                            <div>
                                                                                <span className='fs-6'>{item.user.name}</span>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span className='fs-6'>{item.user.user_name}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span className="fs-6">{DateFormat(requests.created_at).getFullDateText()}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        {
                                                                            requests.status === 0 ?
                                                                                <span className="badge badge-warning">
                                                                                    Pending
                                                                                </span>
                                                                                :
                                                                                requests.status === 1 ?
                                                                                    <span className="badge badge-success">
                                                                                        Approved
                                                                                    </span>
                                                                                    :
                                                                                    <span className="badge badge-danger">
                                                                                        Rejected
                                                                                    </span>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        requests.status === 2 ? (
                                                                            requests.reject_reason == null ?
                                                                                <span className="fs-5">--</span>
                                                                                :
                                                                                <div>
                                                                                    <span
                                                                                        className='fs-6 text-danger'
                                                                                        dangerouslySetInnerHTML={{ __html: requests.reject_reason }}
                                                                                    />
                                                                                </div>
                                                                        ) : (
                                                                            <span className="fs-5">--</span>
                                                                        )
                                                                    }

                                                                </td>
                                                            </tr>
                                                        ))
                                                    )
                                                })
                                            )
                                        })()
                                    }
                                </tbody>
                            </table> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentDeactivateRequest;
