import React, {useContext} from 'react'
import {useSearchParams} from 'react-router-dom';
import ViewMore from '../../components/ViewMore';
import {GDVendorContext} from '../../gdvendors/GDVendorContext'
import {ProductFilterContext} from '../ProductFilterContext';
import {FilterItem, FilterItemCheck} from './FilterItem';

function ProductFilterBrand() {
    const {data, isLoading} = useContext(GDVendorContext).brands;
    const [searchParams, setSearchParams] = useSearchParams()
    const {selectedBrands, setSelectedBrands, setFilters, filters} = useContext(ProductFilterContext);
    if (isLoading) return
    if (data.length === 0) return
    console.log()

    function handleChange(e, brand) {
        let queryParams = Object.fromEntries(searchParams)
        if (brand.id == selectedBrands.id) {
            delete queryParams.brand
            delete filters.brand
            setFilters(filters)
            setSelectedBrands({})
            setSearchParams(queryParams)
            return
        }
        setSearchParams({...queryParams, brand: brand.id})
        setSelectedBrands(brand)
    }

    return (
        <FilterItem filterTitle='Brands'>
            <ViewMore data={data} limit={2}>
                {
                    (item, idx) => (
                        <FilterItemCheck checked={item.id == selectedBrands.id} label={item.brand_name} key={{idx}}
                                         onChange={(e) => handleChange(e, item)}>{item.brand_name}</FilterItemCheck>
                    )
                }
            </ViewMore>
        </FilterItem>
    )
}

export default ProductFilterBrand
