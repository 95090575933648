import {Box, CircularProgress, Typography} from '@mui/material'
import {useQueries, useQuery} from '@tanstack/react-query'
import React from 'react'
import {useParams} from 'react-router-dom'
import {GDVendorContext} from './GDVendorContext'
import axios from "../../../api"

function GDVendorProvider({children}) {
    const slug = useParams().slug
    const vendor = useQuery(
        ['vendorpage', slug],
        async () => (
            axios.get(`/api/vendor-details?slug=${slug}`)
                .then(res => res.data).catch(err => err)
        )
    )
    let vendorId = undefined;
    if (vendor.data) {
        if (vendor.data.length > 0) {
            vendorId = vendor.data[0].id
        }
    }
    const category = useQuery({
        queryKey: ['vendor_category', vendorId],
        queryFn: async () => (
            axios.get(`/api/vendor/category/${vendor.data[0].slug}`).then(res => res.data).catch(err => err)
        ),
        enabled: !!vendorId,
    })
    const brands = useQuery({
        queryKey: ['vendor_brands', vendorId],
        queryFn: async () => (
            axios.get(`/api/vendor/${vendor.data[0].slug}`).then(res => res.data).catch(err => err)
        ),
        enabled: !!vendorId,
    })

    if (vendor.isLoading) {
        return <LoadingScreen/>
    }

    if (vendor.data.length === 0) {
        return (
            <div className="container">
                <Box sx={{display: 'flex', height: '70vH', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography variant="h2">404:Not Found</Typography>
                </Box>
            </div>
        )
    }

    console.log()

    return (
        <GDVendorContext.Provider value={{
            vendor: vendor.data[0],
            refetchVendor: vendor.refetch,
            category: category,
            brands: brands
        }}>
            {children}
        </GDVendorContext.Provider>
    )
}

function LoadingScreen() {
    return (
        <div className="container">
            <Box sx={{height: '60vH', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress size={80}/>
            </Box>
        </div>
    )
}

export default GDVendorProvider
