import React from 'react'
import {useLocation} from "react-router-dom";
import "../payment/Payment.css";

const Payment = () => {
    const location = useLocation();
    const package_id = location.state.package_id;
    const package_type = location.state.package_type;
    const packages = location.state.packages;
    const description = location.state.package_description
    const token = localStorage.getItem('token');
    return (
        <div className='payment'>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-6">
                        <h6>Payment Details</h6>
                        <span>{package_id}</span>
                        <hr/>
                        <h6>{package_type}</h6>
                        <ul className="list-unstyled">
                            <li>
                                <span>{description}</span>
                            </li>
                            <li>
                                <span>{packages}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <h6>Patient Information</h6>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Payment
