import {Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography, useMediaQuery} from '@mui/material'
import React, {useContext, useEffect, useState} from 'react'
import {GDVendorContext} from '../../gdvendors/GDVendorContext'
import {ProductFilterContext} from '../ProductFilterContext'
import {useFetchedProducts} from '../ProductFIlterProvider'

function ProductFilterHeader() {
    const {filters, selectedCat} = useContext(ProductFilterContext)
    const {products, totalItems} = useFetchedProducts()
    console.log()
    const {vendor} = useContext(GDVendorContext)
    const [title, setTitle] = useState(vendor.store_name)
    const matches = useMediaQuery('(max-width:760px)');
    const display = {
        display: matches ? 'none' : 'block'
    }


    useEffect(() => {
        if (selectedCat.id) {
            setTitle(`${selectedCat.name} - ${vendor.store_name}`)
        }
    }, [selectedCat])

    return (
        <Stack pt={2} pb={3} flexDirection='row' justifyContent='space-between' alignItems='center'>
            <Box sx={{display}}>
                <Typography variant='h6'>{title}</Typography>
                <Typography variant='subtitle1' color='text.secondary'><b>{totalItems}</b> items found
                    for <b>{title}</b></Typography>
            </Box>
            <Box sx={{minWidth: 230}}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                    >
                        <MenuItem value={10}>Latest</MenuItem>
                        <MenuItem value={20}>Price: Low To High</MenuItem>
                        <MenuItem value={30}>Price: High To Low</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Stack>
    )
}

export default ProductFilterHeader
