import {Box, Button} from '@mui/material'
import React from 'react'
import Slider from 'react-slick'
import ReactDOMServer from "react-dom/server";
import {useState} from 'react';
import {useEffect} from 'react';
import {ArrowBackIosNewOutlined, ArrowBackIosOutlined, ArrowForwardIosOutlined} from '@mui/icons-material';
import {IconButton} from '@mui/material';

function SliderStyle2({children, sx = {}, numItems = 1, settingOverride = {}}) {
    const [muiPrevIcon, setMuiPrevIcon] = useState();
    const [muiNextIcon, setMuiNextIcon] = useState();

    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: numItems,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        ...settingOverride
    }

    return (
        <Box sx={{px: 2.5, ...sx}}>
            <Slider {...settings}>
                {children}
            </Slider>
        </Box>
    )
}

function PrevArrow(props) {
    const {className, style, onClick} = props
    const sx = {
        // display:'block',
        position: 'absolute',
        top: '50%',
        left: '-30px',
        transform: 'translate(0, -50%)',
        zIndex: 1,
        padding: 0
    }
    return (
        <IconButton sx={sx} onClick={onClick} size='large'>
            <ArrowBackIosOutlined fontSize='large'/>
        </IconButton>
    )
}

function NextArrow(props) {
    const {className, style, onClick} = props
    const sx = {
        // display:'block',
        position: 'absolute',
        top: '50%',
        right: '-30px',
        transform: 'translate(0, -50%)',
        padding: 0,
    }
    return (
        <IconButton sx={sx} onClick={onClick} size='large'>
            <ArrowForwardIosOutlined fontSize='large'/>
        </IconButton>
    )
}

export default SliderStyle2
