import {Box, Button, Link, Rating, Stack, Typography, useMediaQuery} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {FilterItem, FilterItemCheck, FilterItemSelect, FilterTagItem, FilterTextLabel} from './FilterItem'
import ProductFilterBrand from './ProductFilterBrand'
import ProductFilterCategory from './ProductFilterCategory'
import ProductFilterPrice from './ProductFilterPrice'
import ProductFilterRating from './ProductFilterRating'
import MenuIcon from '@mui/icons-material/Menu';
import {display} from '@mui/system'
import {Expand} from '@mui/icons-material'
import './ProductFilterBar.css';


function ProductFilterBar({hasSearchBar, sx}) {
    // const matches = useMediaQuery('(max-width:768px)');
    // const remove = {
    //     display: matches ? 'none' : 'block'
    // }

    const [expand, setExpand] = useState(false);

    function togglenav() {
        setExpand(!expand)

        // }
        // useEffect(() => {
        //     if (matches) {
        //         setExpand(true)
        //     }
        //     else {
        //         setExpand(false)
        //     }
        // }, [matches])

        // if (expand || !matches) {
        //     return (
        //         <MenuIcon onClick={togglenav}>
        //         </MenuIcon>
        //     )
        // }
    }

    return (


        // <>
        //     <button
        //         onClick={(handleClick) => setToggle(!toggle)}
        //         class="btn btn-primary mb-2">
        //         {/* < MenuIcon sx={hide}></MenuIcon> */}
        //     </button>
        //     {toggle && (

        <box sx={{maxHeight: '80vH', overflowY: 'scroll', ...sx, '&::-webkit-scrollbar-thumb': {width: '10px'}}}>
            <MenuIcon className='button2' onClick={togglenav}></MenuIcon>
            <ProductFilterCategory/>
            <ProductFilterBrand/>


            <FilterItem className='filter' filterTitle="Tags">
                <FilterTagItem>Headache</FilterTagItem>
                <FilterTagItem>Nausea</FilterTagItem>
                <FilterTagItem>Fever</FilterTagItem>
                <FilterTagItem>Skin Rashes</FilterTagItem>
                <Typography color='primary' variant='button' display='block' sx={{mt: 1, cursor: 'pointer'}}>View
                    More</Typography>
            </FilterItem>
            <ProductFilterPrice className='price'/>
            <ProductFilterRating className='rating'/>
        </box>

        // )}
        // </>

    )
}

export default ProductFilterBar
