import React from "react";
import {useFieldArray} from "react-hook-form";
import {CHEQUES} from "../../../../../utils/constant/payment";
import PayChequeItem from "./PayChequeItem";

const PayCheque = (props) => {
    const {form} = props

    const {fields, append, remove} = useFieldArray({
        control: form.control,
        name: CHEQUES,
    });

    return (
        <div className="d-grid gap-4">
            {fields.map((item, index) => {
                return <PayChequeItem append={append} remove={remove} form={form} key={index} index={index} fieldsLength={fields.length}/>
            })}
        </div>
    )
}

export default PayCheque
