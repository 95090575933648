import {Backdrop, Box, Button, CircularProgress} from '@mui/material'
import {Autocomplete, GoogleMap, Marker} from '@react-google-maps/api'
import axios from '../../../../api'
import React, {useContext, useState} from 'react'
import {useEffect} from 'react'
import UploadFile from '../../../../custom_componets/UploadFile'
import {InputField} from '../../../../custom_componets/FormComponents'
import swal from 'sweetalert'
import {useGlobalFormData} from '../GlobalFormProvider'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import LeafletMap from '../../../../leafletMap/LeafletMap'

function generateInitial(initialObj, allFormData) {
    const stateObj = {}
    initialObj.forEach((obj) => {
        stateObj[obj] = allFormData[obj] || ""
    })
    return stateObj
}

function DocumentUpload({handleBack, handleNext, allFormData, setAllFormData, errors, setErrors, status}) {
    const {globalForm, refetchGlobalForm} = useGlobalFormData()
    const [inputs, setInputs] = useState(generateInitial([
        'self_image',
        'citizenship_front',
        'citizenship_back',
        'latitude',
        'longitude',
    ], globalForm))
    const [formSubmit, setFormSubmit] = useState(false)
    // const token = localStorage.getItem('token')
    const [open, setOpen] = useState(false)
    const [showUpload, setShowUpload] = useState(true)
    const token = useContext(LoginStateContext).state.token


    useEffect(() => {
        if (allFormData?.self_image_path) setInputs((prev) => ({...prev, self_image: allFormData.self_image_path}))
        if (allFormData?.citizenship_front_path) setInputs((prev) => ({
            ...prev,
            citizenship_front: allFormData.citizenship_front_path
        }))
        if (allFormData?.citizenship_back_path) setInputs((prev) => ({
            ...prev,
            citizenship_back: allFormData.citizenship_back_path
        }))
    }, [])


    async function readFileAsDataURL(file) {
        let result_base64 = await new Promise((resolve) => {
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
        })
        console.log()
        return result_base64
    }

    function handleSubmit(e) {
        e.preventDefault()
        const inputFiles = ['self_image', 'citizenship_front', 'citizenship_back']
        let visited = 0
        inputFiles.forEach(async (inFile, index) => {
            var i = inputFiles.indexOf(inFile)
            console.log()
            console.log()
            if (inputs[inFile] instanceof File === false) {
                // console.log()
                delete inputs[inFile]
            } else {
                let result = await readFileAsDataURL(inputs[inFile])
                inputs[inFile] = result
            }
            visited++
            console.log()
            if (inputFiles.length === visited) {
                // console.log()
                // postData(fd)
                axios.post(`api/kyc/upload-document/${globalForm.id}`, inputs, {headers: {"Authorization": `Bearer ${token}`}})
                    .then(res => {
                        console.log()
                        handleNext()
                        refetchGlobalForm()
                    })
            }
        })
        // setAllFormData({ ...allFormData, ...inputs })

    }

    function handleChange(e) {
        setInputs({...inputs, [e.target.name]: e.target.value})
    }

    function handleKeyDown(e) {
        if (e.keyCode === 13) {
            e.preventDefault()
        }
    }

    return (
        <React.Fragment>
            <form className='form-horizontal' onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                <div className="card">
                    <div className="card-header">
                        <h3 className="m-0 p-0">Document Upload</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <UploadFile
                                    name="self_image"
                                    type="img"
                                    label="Upload Self Image"
                                    value={inputs.self_image == '' ? [] : [allFormData?.self_image_path ? allFormData.self_image_path : inputs.self_image]}
                                    setImageInput={(imgFile) => {
                                        setInputs({...inputs, self_image: imgFile[0]})
                                    }}
                                    required
                                />
                            </div>

                            <div className="col-md-4">
                                <UploadFile
                                    name="citizenship_front"
                                    type="img"
                                    label="Upload Citizenship Front"
                                    value={inputs.citizenship_front == '' ? [] : [allFormData?.citizenship_front_path ? allFormData.citizenship_front_path : inputs.citizenship_front]}
                                    setImageInput={(imgFile) => {
                                        setInputs({...inputs, citizenship_front: imgFile[0]})
                                    }}
                                    required
                                />
                            </div>

                            <div className="col-md-4">
                                <UploadFile
                                    name="citizenship_back"
                                    type="img"
                                    label="Upload Citizenship Back"
                                    value={inputs.citizenship_back == '' ? [] : [allFormData?.citizenship_back_path ? allFormData.citizenship_back_path : inputs.citizenship_back]}
                                    setImageInput={(imgFile) => {
                                        setInputs({...inputs, citizenship_back: imgFile[0]})
                                    }}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h3 className='m-0 p-0'>Select Latitude and Longitude</h3>
                    </div>
                    <div className="card-body">
                        <div className="row g-4">
                            <div className="col-lg-6">
                                <InputField
                                    label="Latitude"
                                    name="latitude"
                                    value={inputs.latitude}
                                    error={errors?.latitude}
                                    errorMsg={errors?.latitude ? errors.latitude.message : null}
                                    required
                                    disabled
                                />
                            </div>
                            <div className="col-lg-6">
                                <InputField
                                    label="Longitude"
                                    name="longitude"
                                    value={inputs.longitude}
                                    error={errors?.longitude}
                                    errorMsg={errors?.longitude ? errors.longitude.message : null}
                                    required
                                    disabled
                                />
                            </div>
                            <div className="col-lg-12">
                                {/* <div id="map" /> */}
                                <LeafletMap
                                    aspectRatio={"3/1"}
                                    autoComplete
                                    getLatLng={(latlng) => {
                                        setInputs({...inputs, latitude: latlng.lat, longitude: latlng.lng})
                                    }}
                                    // initPosition={{lat:inputs.latitude, lng:inputs.longitude}}
                                />
                                {/* <MapInput
                                    setLatLong={(lat, lng) => {
                                        setInputs({ ...inputs, latitude: lat, longitude: lng })
                                    }}
                                    lat={inputs.latitude}
                                    lng={inputs.longitude}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>

                <React.Fragment>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Button
                            color="inherit"
                            onClick={() => handleBack(inputs)}
                            sx={{mr: 1}}
                        >
                            Back
                        </Button>
                        <Box sx={{flex: '1 1 auto'}}/>
                        <button type="submit" className="btn btn-primary">
                            Next
                        </button>
                    </Box>
                </React.Fragment>
            </form>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={open}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </React.Fragment>
    )
}

function MapInput({
                      setLatLong = () => {
                      }, lat = null, lng = null
                  }) {
    const [latLng, setLatLng] = useState({
        lat: (lat === null || lat === "") ? null : Number(lat),
        lng: (lng === null || lng == "") ? null : Number(lng)
    })

    const [center, setCenter] = useState({
        lat: (lat === null || lat === "") ? 27.68322 : Number(lat),
        lng: (lng === null || lng == "") ? 85.33582 : Number(lng)
    })

    const [autocomplete, setAutocomplete] = useState(null)

    function clickedMap(e) {
        // console.log(), e.latLng.lng())
        setLatAndLng(e.latLng.lat(), e.latLng.lng())
    }

    function setLatAndLng(lat, lng) {
        setLatLng({...latLng, lat: lat, lng: lng})
        setCenter({...latLng, lat: lat, lng: lng})
        setLatLong(lat, lng)
    }

    return (
        <>
            <h4>Place the marker on your location</h4>

            <GoogleMap
                mapContainerStyle={{width: "100%", aspectRatio: "3/1"}}
                center={center}
                zoom={15}
                onClick={clickedMap}
            >
                <Autocomplete
                    restrictions={{country: 'np'}}
                    types={'cities'}
                    onLoad={(autocomp) => {
                        setAutocomplete(autocomp)
                    }}
                    onPlaceChanged={() => {
                        if (autocomplete !== null) {
                            // console.log())
                            let selLocation = autocomplete.getPlace().geometry.location;
                            setLatAndLng(selLocation.lat(), selLocation.lng())
                        }
                    }}
                >
                    <input
                        type="text"
                        placeholder="Enter Your Location"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `50%`,
                            height: `40px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `18px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: "absolute",
                            top: "1%",
                            left: "25%",

                        }}
                    />
                </Autocomplete>
                <Marker
                    position={latLng}
                    draggable={true}
                    onDragEnd={clickedMap}
                />
            </GoogleMap>
        </>
    )
}


export default DocumentUpload
