import React from "react";
import "../components/MainServices.css";
import axios from "./api.js";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SliderOne from "./slider-carousels/SliderOne";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";

const slickSettings = {
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const MainServices = () => {
  const [services, setServices] = useState([]);

  const servicesBtn = {};

  servicesBtn[2] = (
    <Link to="/booking/doctor" className="btn btn-success">
      Consult Doctor
    </Link>
  );

  servicesBtn[3] = (
    <Link to="/gd-store" className="btn btn-success">
      Fitness Center
    </Link>
  );

  servicesBtn[5] = (
    <Link to="/gd-store" className="btn btn-success">
      Go To Pharmacy
    </Link>
  );

  servicesBtn[6] = (
    <Link to="/ambulance" className="btn btn-success">
      View Ambulance
    </Link>
  );

  useEffect(() => {
    axios
      .get("/api/service")
      .then((res) => setServices(res.data))
      .catch((err) => console.log());
  }, []);

  const renderServices = () => {
    return (
      services.length > 0 && (
        <SliderOne
          numItems={4}
          gap={1}
          py="10px"
          settingOverride={slickSettings}
        >
          {services.map((sv, index) => (
            <ServiceCard data={sv} />
          ))}
        </SliderOne>
      )
    );
  };
  return (
    <Box>
      <div className="container py-5 main-services">
        <div className="row row-eq-height">
          <div className="col-md-8 heading">
            <p>Services we offer</p>
            <p>Access all health care services easily from our platform</p>
          </div>
          <div className="col-md-4 text-end">
            <a href="/service" className="btn btn-outline-dark services-btn">
              View all Services
            </a>
          </div>
        </div>
        <div className="row text-white mt-4">{renderServices()}</div>
      </div>
    </Box>
  );
};

function ServiceCard({ data }) {
  return (
    <Box
      px={1}
      height="100%"
      sx={{
        "&:hover img": {
          transform: "scale(1.2)",
        },
      }}
    >
      <Card sx={{ borderRadius: "15px", height: "100%" }} elevation={4}>
        <CardContent sx={{ height: "100%" }}>
          <Stack gap={2} alignItems="center" sx={{ height: "100%" }}>
            <Box
              width="100%"
              overflow="hidden"
              sx={{
                aspectRatio: "1.6/1",
                "& img": {
                  width: "70%",
                  ml: "auto",
                  mr: "auto",
                  height: "100%",
                  objectFit: "cover",
                  transition: "transform 300ms",
                },
              }}
            >
              <img src={data.image_path} alt="" />
            </Box>
            <Typography
              variant="h6"
              lineHeight={1}
              fontWeight={600}
              textAlign="center"
            >
              {data.service_title}
            </Typography>
            <Typography
              component="p"
              lineHeight={1.4}
              variant="subtitle1"
              textAlign="center"
            >
              {data.short_description}
            </Typography>
            <Button
              variant="contained"
              sx={{ minWidth: "60%", borderRadius: "50px", mt: "auto" }}
            >
              <Link to={`/service/${data.slug}`} className="text-white">
                More
              </Link>
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

export default MainServices;
