import {Box} from '@mui/material'
import React from 'react'
import CarouselSlider from './CarouselSlider'
import SliderItem from './SliderItem'

function BannerNAdvertisement() {
    return (
        <Box bgcolor={'#FAF9F6'} width='100%' pt={1.2}>
            <div className="container">
                <CarouselSlider>
                    <div>
                        <SliderItem
                            bgimage={'http://icms-image.slatic.net/images/ims-web/75ae6433-741e-43cf-a183-c894df1b44d5.jpg'}/>
                    </div>
                    <div>
                        <SliderItem
                            bgimage={"https://icms-image.slatic.net/images/ims-web/01c7447d-851f-4b17-85b1-cfaf5b541d8d.jpg"}/>
                    </div>
                </CarouselSlider>
            </div>
        </Box>
    )
}

export default BannerNAdvertisement
