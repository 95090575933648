import http from "../utils/http/http.utils";

class ConsultationService {
  static async getHistory(id) {
    try {
      const response = await http().get(`api/parent/${id}/meeting-history`);
      return response?.data?.data;
    } catch (e) {
      console.log(e);
    }
  }

  static async getDetails(id) {
    try {
      const response = await http().get(
        `api/parent/194/meeting-history-details?id=${id}`
      );
      return response?.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  static async fetchSearchedConsultationHistory(search) {
    try {
      const response = await http().get(
        `api/parent/194/meeting-history?search=${search}`
      );
      return response.data;
    } catch (e) {
      console.log(e);
      throw new Error("Failed to fetch medical history");
    }
  }
}

export default ConsultationService;
