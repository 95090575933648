import SelectReactHookForm from "../../../../select/select-react-hook-form";
import DatePickerReactHookForm from "../../../../date-picker/date-picker-react-hook-form";
import { FormControl, InputLabel } from "@mui/material";
import { FIELD_REQUIRED } from "../../../../../utils/constant/common";
import React from "react";
import { useFieldArray } from "react-hook-form";
import {
  DEFAULT_VALUE_ONLINE_TRANSFER,
  ONLINE_TRANSFER,
  ONLINE_TRANSFERS,
} from "../../../../../utils/constant/payment";
import { grey } from "@mui/material/colors";
import DeleteRow from "../../../../form/DeleteRow";
import InputField from "../../../../form/InputField";
import InputFieldLabel from "../../../../form/InputFieldLabel";

const OnlineTransfer = (props) => {
  const { form } = props;
  const {
    register,
    formState: { errors },
  } = form;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: form.control,
      name: ONLINE_TRANSFERS,
    }
  );

  const sources = [
    {
      label: "Bank",
      value: "bank",
    },
    {
      label: "IPS",
      value: "connectips",
    },
    { label: "Others", value: "others" },
  ];

  const handleAppend = () => append(DEFAULT_VALUE_ONLINE_TRANSFER);
  const handleRemove = (index) => remove(index);

  return (
    <div className="d-grid gap-3">
      <div className="d-grid gap-4">
        {fields.map((item, index) => {
          return (
            <div
              className="p-4 d-grid gap-2"
              style={{ background: grey["300"], borderRadius: 8 }}
              key={index}
            >
              <div className="row">
                <div className="col-3 label-fix">
                  <SelectReactHookForm
                    label={"Transaction Source"}
                    placeholder
                    required={true}
                    name={`${ONLINE_TRANSFERS}.${index}.transaction_source`}
                    error={
                      errors[ONLINE_TRANSFERS] &&
                      errors[ONLINE_TRANSFERS][index]?.transaction_source
                        ?.message
                    }
                    options={sources}
                    form={form}
                  />
                </div>
                <div className="col-3">
                  <FormControl variant="standard" fullWidth color="primary">
                    <InputFieldLabel required={true}>
                      Transferred Amount
                    </InputFieldLabel>
                    <InputField
                      {...register(
                        `${ONLINE_TRANSFERS}.${index}.amount_transferred`
                      )}
                      error={Boolean(
                        errors &&
                          errors[ONLINE_TRANSFERS] &&
                          errors[ONLINE_TRANSFERS][index]?.amount_transferred
                            ?.message
                      )}
                      helperText={
                        errors &&
                        errors[ONLINE_TRANSFERS] &&
                        errors[ONLINE_TRANSFERS][index]?.amount_transferred
                          ?.message
                      }
                    />
                  </FormControl>
                </div>
                <div className="col-3">
                  <DatePickerReactHookForm
                    label="Transfer Date"
                    required={true}
                    control={form.control}
                    name={`${ONLINE_TRANSFERS}.${index}.transaction_date`}
                    error={
                      errors[ONLINE_TRANSFERS] &&
                      errors[ONLINE_TRANSFERS][index]?.transaction_date?.message
                    }
                  />
                </div>
                <div className="col-3">
                  <InputFieldLabel required={true}>
                    Transaction Receipt
                  </InputFieldLabel>
                  <input
                    type="file"
                    multiple={false}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      form.setValue(
                        `${ONLINE_TRANSFERS}.${index}.transaction_receipt`,
                        file
                      );
                    }}
                    accept="image/jpeg, image/jpg, image/png"
                  />
                  {Boolean(
                    errors[ONLINE_TRANSFERS] &&
                      errors[ONLINE_TRANSFERS][index]?.transaction_receipt
                        ?.message
                  ) && <span className="text-danger">{FIELD_REQUIRED}</span>}
                </div>
              </div>
              <DeleteRow
                classes="justify-content-end"
                index={index}
                fieldsLength={fields.length}
                handleRemove={handleRemove}
                handleAppend={handleAppend}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OnlineTransfer;
