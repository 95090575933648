import {KeyboardArrowDownOutlined} from '@mui/icons-material'
import {Box, Button, Grid, Tab, Tabs} from '@mui/material'
import React, {useContext, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {GDVendorContext} from '../GDVendorContext'

function GDVendorNavigation({tabValue, setSearchParams}) {
    // const [value, setValue] = useState(0)
    const {vendor} = useContext(GDVendorContext)
    const navigate = useNavigate()

    function changeTab(link) {
        navigate(`/storetest/seller-store/${vendor.slug}?pg=${link}`)
    }

    function addLink(link) {
        return {
            onClick: (e) => {
                changeTab(link)
            }
        }
    }

    return (
        <Grid container alignItems={'center'} justifyContent='space-between'
              sx={{borderTop: 1, borderColor: 'divider', mt: 2}}>
            <Grid item xs='7'>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    {/* <Button endIcon={<KeyboardArrowDownOutlined />}>Categories</Button> */}
                    <Tabs value={tabValue} aria-label="basic tabs example">
                        {vendor.is_exculsive == 2 && <Tab label="Homepage" {...addLink('home')}/>}
                        <Tab label="All Products" {...addLink('all_products')}/>
                        <Tab label="Proflie" {...addLink('profile')}/>
                    </Tabs>
                </Box>
            </Grid>
            <Grid item xs="4">
                <input type="search" placeholder='Search Store' style={{width: "100%"}}/>
            </Grid>
        </Grid>
    )
}

export default GDVendorNavigation
