import "../stores/ProductPage.css";
import {Box, Rating, Skeleton, Tab, Tabs} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCartPlus,
    faBagShopping,
    faMinus,
    faPlus,
    faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "../../api.js";
import ReactImageMagnify from "react-image-magnify";
import ProductReview from "../../stores/ProductReview";
import {
    addToLocalStorage,
    getFromLocalStorage,
} from "../../stores/LocalDatabaseQ";
import swal from "sweetalert";
import {RatingContext} from "../../../contexts/RatingContext";
import {StoreContext} from "../../../contexts/StoreContext"
import Slider from 'react-slick'
import ProductDetails from "../../stores/ProductDetails";
import TabPanel from "../../TabPanel";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const ProductPage = () => {
    const relatedProdData = [];
    const [product, setProduct] = useState([]);
    const [productLoading, setProductLoading] = useState(true);
    const [avgRating, setAvgRating] = useState(true);
    const [ratingOneByOne, setRatingOneByOne] = useState([]);
    const [tabOption, setTabOption] = useState("description");
    const [trackUpdate, setTrackUpdate] = useState(false);
    const [trackPg, setTrackPg] = useState(false)
    const [tabValue, setTabValue] = useState(0)

    const storeContext = React.useContext(StoreContext)

    const navigate = useNavigate()

    let selected = {
        description: "selected",
        reviews: "",
        questionAnswers: "",
    };

    let total = 0;
    ratingOneByOne.map((rating) => {
        total += rating.total;
    });

    let param = useParams();

    const imgPath = product.image_path;

    useEffect(() => {
        setProductLoading(true)
        axios
            .get("api/products?slug=" + param.slug)
            .then((res) => {
                setProduct(res.data.data[0])
                setProductLoading(false);
            });
    }, [param]);

    useEffect(() => {
        setAvgRating(product.averageRating)
    }, [product, trackUpdate]);

    useEffect(() => {
        axios
            .get("api/admin/rating_onebyone", {params: {product_id: product.id}})
            .then((res) => {
                setRatingOneByOne(res.data);
                console.log();
            });
    }, [product, trackUpdate]);

    const addToCart = () => {
        // e.preventDefault();
        product.cartCount = 1;
        let res = addToLocalStorage("cart", product);
        if (res) {
            storeContext.setCartCount(storeContext.cartCount + 1)
            swal("Success", "Product Added To Cart", "success");
        } else {
            swal("Error", "Product Already Present In Cart", "error");
        }
    };

    function changeTab(e, newValue) {
        setTabValue(newValue)
    }

    const settings = {
        dots: true,
        infinite: false,
        initialSlide: 0,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
    };

    return (
        <RatingContext.Provider
            value={{avgRating, ratingOneByOne, trackUpdate, setTrackUpdate}}
        >
            <div className="container prod-page-container pt-5">
                <div className="prod-bas-detail">
                    <ProductDetails product={product} productLoading={productLoading} addToCart={addToCart}/>

                </div>
                {/* <div className="prod-details-tab mt-5">
          <div className="prod-details-tab-bar d-flex g-2">
            <div
              className={`tab-bar-item ${selected.description}`}
              id="tab-bar-item-desc"
            >
              <h6 onClick={changeTabItem} id="description">
                DESCRIPTION
              </h6>
            </div>
            <div
              className={`tab-bar-item ${selected.reviews}`}
              id="tab-bar-item-review"
            >
              <h6 onClick={changeTabItem} id="reviews">
                REVIEWS
              </h6>
            </div>
          </div>
          <div className="prod-details-tab-content py-4">{getTabContent()}</div>
        </div> */}
                <div className="mt-4">
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tabValue} onChange={changeTab} aria-label="basic tabs example">
                            <Tab label="Product Description" {...a11yProps(0)} />
                            <Tab label="Item Two" {...a11yProps(1)} />
                            <Tab label="Item Three" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        Item One
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        Item Two
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        Item Three
                    </TabPanel>
                </div>

                <div className="mt-4">
                    <ProductReview product={product}/>
                </div>
            </div>
        </RatingContext.Provider>
    );
};

export default ProductPage;
