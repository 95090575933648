import React from "react";
import {useContext} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {ProductContext} from "../ProductDetPage";
import {GDStoreContext} from "../../../../../contexts/GDStoreContext"
import {Box, Button, Card, Divider, Rating, Skeleton, Stack, Typography} from "@mui/material";
import ImageMagnify from "../../../../custom_componets/ImageMagnify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare, faMinus, faPlus, faShareNodes} from "@fortawesome/free-solid-svg-icons";
import {addToLocalStorage} from "../../../../stores/LocalDatabaseQ";
import swal from "sweetalert";
import axios from "../../../../api"
import {
    AddShoppingCartOutlined,
    DiscountOutlined,
    FacebookOutlined,
    Reddit,
    ShoppingBagOutlined,
    Twitter,
    WhatsApp
} from "@mui/icons-material";
import {styled} from "@mui/system";
import Slider from "react-slick";
import {useRef} from "react";
import QuantityStepper from "./QuantityStepper";
import DOMPurify from "dompurify";
import {useCart} from "../../../../../contexts/provider/GDCartProvider";

const LabelBox = styled(Box)({
    width: '150px',
})

function ProductDetail() {
    const gdCart = useCart()
    const productCont = useContext(ProductContext)
    const storeContext = useContext(GDStoreContext)
    const qtyRef = useRef(null)
    const navigate = useNavigate()
    const [cartCount, setCartCount] = React.useState(0);
    const [stateChanged, setStateChanged] = React.useState(false)
    const [outOfStock, setOutOfStock] = React.useState(false)
    const location = useLocation()

    const product = productCont.product
    const productLoading = productCont.isLoading

    let [productImage, setProductImage] = React.useState(null)


    const settings = {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
    }

    console.log()

    React.useEffect(() => {
        setCartCount(0)
        setOutOfStock(false)
    }, [location])

    React.useEffect(() => {
        if (productLoading) {
            return
        }
        const cart = JSON.parse(localStorage.getItem('cart'))
        const cartItem = cart.filter((i) => {
            return i.id == product.id
        })

        const count = cartItem.length > 0 ? cartItem[0].cartCount : 0

        if (product.stock == 0 || count >= product.stock) {
            setOutOfStock(true)
        }
        setProductImage(product.image_path)
    }, [productLoading])

    React.useEffect(() => {
        if (productLoading) {
            return
        }
        const cart = JSON.parse(localStorage.getItem('cart'))
        const cartItem = cart.filter((i) => {
            return i.id == product.id
        })
        axios.get("/api/products?slug=" + product.slug)
            .then(res => {
                console.log()
                if (res.data.data[0].stock == 0 || cartCount >= res.data.data[0].stock || cartItem[0].cartCount >= res.data.data[0].stock) {
                    console.log()
                    setOutOfStock(true)

                } else {
                    setOutOfStock(false)
                }

            })

    }, [cartCount, productLoading])

    React.useEffect(() => {
        let filtered = []
        const cart = JSON.parse(localStorage.getItem('cart'));
        if (!productLoading) {
            if (cart.length > 0) {
                filtered = cart.filter((c) => {
                    return c.id == product.id
                })
                if (filtered.length > 0) {
                    setCartCount(filtered[0].cartCount)
                }

            }
        }
    }, [stateChanged, productLoading])

    const addToCart = () => {
        // e.preventDefault();
        product.cartCount = 1;
        let res = addToLocalStorage("cart", product);
        if (res) {
            storeContext.setUpdate(prev => !prev)
            swal("Success", "Product Added To Cart", "success");
        } else {
            swal("Error", "Product Already Present In Cart", "error");
        }
    };

    const buyProduct = () => {
        const cart = JSON.parse(localStorage.getItem('cart'));
        let filtered = []

        if (cart.length > 0) {
            filtered = cart.filter((c) => {
                return c.id == product.id
            })
        }
        if (filtered.length == 0) {
            addToCart()
        }
        navigate("/cart")
    }

    const setCountCart = (action) => {

        if (action == "add") {
            if (!outOfStock)
                setCartCount(cartCount + 1)
        }
        if (action == "remove") {
            if (cartCount > 0) {
                setCartCount(cartCount - 1)
            }
        }

    }

    const addCart = () => {
        // storeContext.addToCart(product, cartCount)
        // alert("Adding To Cart")
        let res = gdCart.addToCart(product, qtyRef.current.value)
        if (res) {
            swal(
                'Cart',
                'Product Added To Cart Successfully',
                'success'
            )
        }
        return
        let cart = JSON.parse(localStorage.getItem('cart'))
        let cartFiltered = []
        cartFiltered = cart.filter((c) => {
            return c.id == product.id
        })
        if (cartFiltered.length > 0) {
            cartFiltered[0].cartCount = cartFiltered[0].cartCount + cartCount;
            let arr = cart.filter((c) => {
                return c.id != product.id
            })
            arr.push(cartFiltered[0])
            localStorage.setItem('cart', JSON.stringify(arr))
            swal("Success", "Product Added To Cart", "success");
        } else {
            let carti = product;
            carti.cartCount = cartCount
            let arr = cart.filter((c) => {
                return c.id != product.id
            })
            arr.push(carti)
            localStorage.setItem('cart', JSON.stringify(arr))
            swal("Success", "Product Added To Cart", "success");
        }
    }

    if (productLoading) return

    return (
        <div className="product-details">
            <div className="row g-4">
                <div className="col-12 col-md-5">
                    <div className="product-images">
                        {
                            (productLoading) ? <Skeleton variant="rectangular"
                                                         style={{height: "240px", width: "240px", margin: "auto"}}/> :
                                <div className="product-main-img">
                                    <ImageMagnify srcImage={<img src={productImage} alt=""/>}/>

                                </div>
                        }
                        <div className="mt-2 prouct-slide-img">
                            {
                                (!productLoading) &&
                                <div className="custom-slick">
                                    <Slider {...settings}>
                                        <div>
                                            <SliderImageComponent setProductImage={setProductImage}
                                                                  src={product.image_path}/>
                                        </div>
                                        {
                                            product.gallery.map((gallery) => (
                                                <div>
                                                    <SliderImageComponent setProductImage={setProductImage}
                                                                          src={gallery.image_path}/>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                </div>
                            }

                        </div>
                    </div>
                </div>
                {/* <div className="col-12 col-md-7">
                    {
                        productLoading ?
                            <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} animation="wave" /> :

                            <div className="product-name d-flex justify-content-between align-items-center">
                                <h2 className="me-3">{product.productName}</h2>
                                <FontAwesomeIcon icon={faShareNodes} size="xl" />
                            </div>
                    }
                    {
                        productLoading ?
                            <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} animation="wave" /> :

                            <div className="product-price d-flex justify-content-between align-items-center">
                                <div className="price d-flex align-items-center">
                                    {product.discountPercent != null && <s>RS. {product.sale_price}</s>}
                                    <h2>RS. {(product.discountPercent == null) ? product.sale_price : product.sale_price - ((product.discountPercent / 100) * product.sale_price)}</h2>
                                </div>
                                <div className="text-center discount text-primary d-flex align-items-center">
                                    {
                                        product.discountPercent != null &&
                                        <span>{`Upto ${product.discountPercent}% discount`}</span>
                                    }
                                </div>
                            </div>
                    }
                    {
                        productLoading ?
                            <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} animation="wave" /> :

                            <div className="my-3 product-rating d-flex justify-content-between align-items-center">

                                <div className="product-authenticity">
                                    <FontAwesomeIcon icon={faCheckSquare} size="lg" className='me-2' />
                                    100% Authentic
                                </div>
                                <div className="rating">
                                    <Rating name="read-only" value={product.averageRating} readOnly />
                                </div>
                            </div>
                    }
                    <div className="pt-3 product-add-details">
                        {
                            !productLoading &&
                            <>
                                {
                                    product.drug_type !== null &&
                                    <div className="pb-4 row g-2">
                                        <div className="col-4 col-md-3">
                                            <div className="left-label">
                                                <span className="text-danger">*Drug Type</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <div className="product-manufacturer right-info">
                                                <span className="text-danger">* {product.drug_type}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }

                        <div className="pb-4 row g-2">
                            <div className="col-4 col-md-3">
                                {
                                    productLoading ?
                                        <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} animation="wave" /> :

                                        <div className="left-label">
                                            <span>Manufacturer</span>
                                        </div>
                                }
                            </div>
                            <div className="col-8">
                                {
                                    productLoading ?
                                        <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} animation="wave" /> :

                                        <div className="product-manufacturer right-info">
                                            <span>ABC MANUFACTURER PVT LTD.</span>
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="pb-4 row g-2">
                            <div className="col-4 col-md-3">
                                {
                                    productLoading ?
                                        <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} animation="wave" /> :

                                        <div className="left-label">
                                            <span>Vendor</span>
                                        </div>
                                }
                            </div>
                            <div className="col-8">
                                {
                                    productLoading ?
                                        <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} animation="wave" /> :

                                        <div className="product-manufacturer right-info">
                                            <span>Pharmacy Vendor ABCD</span>
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="pb-4 row g-2">
                            <div className="col-4 col-md-3">
                                {
                                    productLoading ?
                                        <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} animation="wave" /> :

                                        <div className="left-label">
                                            <span>Quantity</span>
                                        </div>
                                }
                            </div>
                            <div className="col-8">
                                {
                                    productLoading ?
                                        <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} animation="wave" /> :

                                        <div className="product-quantity d-flex">
                                            <div className="prod-quan-stepper">
                                                <div className="item-count d-flex">
                                                    <button
                                                        className="minus"
                                                        onClick={() => setCountCart("remove")}
                                                    >
                                                        <FontAwesomeIcon icon={faMinus} size="lg" />
                                                    </button>
                                                    <input
                                                        type="text"
                                                        value={cartCount}
                                                        className="text-center"
                                                        min="1"
                                                    />
                                                    <button
                                                        className="plus"
                                                        onClick={() => setCountCart("add")}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} size="lg" />
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                outOfStock &&
                                                <div className="alert alert-danger text-muted">
                                                    Out of Stock
                                                </div>
                                            }

                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    {
                        productLoading ?
                            <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} animation="wave" /> :
                            <>
                                {
                                    product.stock > 0 ?
                                        <div className="mt-3 product-details-btn d-flex">

                                            <div className="product-buy me-2">
                                                <button className="px-5 btn btn-outline-info buy-btn" onClick={buyProduct}>
                                                    Buy Now
                                                </button>
                                            </div>
                                            <div className="product-add-cart">
                                                <button className="px-5 btn btn-info cart-btn" onClick={addCart}>
                                                    Add To Cart
                                                </button>
                                            </div>
                                        </div>
                                        :

                                        <div className="mt-3 product-details-btn d-flex">

                                            <div className="product-buy me-2">
                                                <button className="px-5 btn btn-outline-info buy-btn" disabled onClick={buyProduct}>
                                                    Buy Now
                                                </button>
                                            </div>
                                            <div className="product-add-cart">
                                                <button className="px-5 btn btn-info cart-btn" disabled onClick={addCart}>
                                                    Add To Cart
                                                </button>
                                            </div>
                                        </div>

                                }
                            </>


                    }

                </div> */}
                <div className="col-12 col-md-7">
                    <Box>
                        <Typography variant='h5'>{product.productName}</Typography>
                        <Divider sx={{width: '50px', fontWeight: '600', height: '2px', background: 'black', my: 2}}/>
                        <Box mb={1}>
                            <Stack direction='row' alignItems={'center'}>
                                <Typography variant="h6" sx={{
                                    fontWeight: '600',
                                    mr: 2
                                }}>{`NPR ${product.sale_price - product.discountPercent / 100 * product.sale_price}`}</Typography>
                                <Card elevation='0'
                                      sx={{bgcolor: '#52C8F4', px: 2, py: 0.2, display: 'flex', alignItems: 'center'}}>
                                    <DiscountOutlined sx={{color: 'white'}}/>
                                    <Typography variant='subtitle1' color={'white'}
                                                sx={{ml: 1}}>{`${product.discountPercent}% OFF`}</Typography>
                                </Card>
                            </Stack>
                            {
                                product.discountPercent !== null
                                &&
                                <Typography variant='caption'
                                            sx={{textDecoration: 'line-through'}}>NPR. {product.sale_price}</Typography>
                            }
                        </Box>

                        <Stack direction='row' alignItems='center'>
                            <Rating value={product.averageRating} readOnly size='small'/>
                            <Typography variant='caption'
                                        sx={{fontWeight: 550}}>({product.totalReviews} Reviews)</Typography>
                        </Stack>
                        {/* <Stack direction='row' alignItems='center' my={2} sx={{ '& svg': { cursor: 'pointer' } }}>
                            <Typography variant='subtitle1' sx={{ mr: 2 }}>Share:</Typography>
                            <FacebookOutlined color='primary' sx={{ mr: 2 }} />
                            <Twitter color='primary' sx={{ mr: 2 }} />
                            <Reddit color='secondary' sx={{ mr: 2 }} />
                            <WhatsApp color='success' />
                        </Stack> */}
                        <Box my={2}>
                            <Typography variant='subtitle1' lineHeight={1.5} mt={1}>
                                <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(product.shortDesc)}}/>
                                {/* Apple iPhone 12 was officially released on October 13, 2020. The phone is powered by the new Apple A14 Bionic processor. The smartphone comes with a 6.1 inches Super Retina XDR OLED capacitive touchscreen, 1170 x 2532 pixels resolution and HDR10 display, Wide color gamut, Dolby Vision, and True-tone */}
                            </Typography>
                        </Box>
                        <Box my={3}>
                            <Stack direction='row' alignItems='center' mb={2}>
                                <LabelBox>
                                    <Typography variant='subtitle1' sx={{mr: 2}}>Brand:</Typography>
                                </LabelBox>
                                <Typography variant='subtitle1' color='primary'
                                            sx={{cursor: 'pointer'}}>{product.brand_detail?.brand_name}</Typography>
                            </Stack>
                            <Stack direction='row' alignItems='center' mb={2}>
                                <LabelBox>
                                    <Typography variant='subtitle1' sx={{mr: 2}}>Store:</Typography>
                                </LabelBox>
                                <Typography variant='subtitle1' sx={{cursor: 'pointer'}} color='primary'
                                            onClick={() => navigate(`/storetest/seller-store/${product.vendor_details.slug}`)}>{product.vendor_details.store_name}</Typography>
                            </Stack>
                            <Stack direction='row' alignItems='center'>
                                <LabelBox>
                                    <Typography variant='subtitle1' sx={{mr: 2}}>Quantity: </Typography>
                                </LabelBox>
                                <QuantityStepper upperBound={product.stock} disabled={product.stock <= 0}
                                                 qtyRef={qtyRef}/>
                            </Stack>
                        </Box>
                        <Stack direction={'row'} alignItems='center'>
                            <Button variant='contained' startIcon={<AddShoppingCartOutlined/>} onClick={addCart}
                                    disabled={product.stock <= 0}>
                                Add To Cart
                            </Button>
                            <Button disabled={product.stok <= 0} variant='contained' color='info'
                                    startIcon={<ShoppingBagOutlined/>} sx={{ml: 2}} onClick={buyProduct}>
                                Buy Now
                            </Button>
                        </Stack>
                    </Box>
                </div>
            </div>
        </div>
    )
}

function SliderImageComponent({onClick, setProductImage, src}) {
    const imgRef = useRef(null)

    function handleClick(e) {
        setProductImage(imgRef.current.src)
    }

    return (
        <Box component='div'
             sx={{cursor: 'pointer', border: '1px solid', borderColor: 'divider', borderRadius: 2, overflow: 'hidden'}}
             onClick={handleClick}>
            <img src={src} alt="" style={{width: '100%', aspectRatio: '1/1'}} ref={imgRef}/>
        </Box>
    )
}

export default ProductDetail
