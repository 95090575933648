import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCartArrowDown,
    faHeart,
    faChevronRight,
    faBars,
    faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import axios from "../../api.js";
import {Rating, Badge} from "@mui/material";
import {useNavigate} from "react-router-dom";

import BreadCrumbs from "../../BreadCrumbs.js";
import {StoreContext} from "../../../contexts/StoreContext";
import "./StoreHeader.scss"

const StoreHeader = (props) => {
    const storeContext = React.useContext(StoreContext);
    const [searchFilter, setSearchFilter] = React.useState("");
    const [searchRes, setSearchRes] = React.useState([]);
    const navigate = useNavigate()

    const [displayStyle, setDisplayStyle] = React.useState({
        display: "none",
    });


    React.useEffect(() => {
        if (searchFilter != "") {
            const getParam = new URLSearchParams([["keyword", searchFilter]]);
            axios.get("api/products", {params: getParam}).then((res) => {
                setSearchRes(res.data.data);
            });
        }
    }, [searchFilter]);

    const searchHandler = (e) => {
        if (e.target.value == "") {
            setDisplayStyle({display: "none"});
        } else {
            setDisplayStyle({display: "block"});
            setSearchFilter(e.target.value);
        }
    };

    const getSearchContent = (e) => {
        console.log();
        if (searchRes.length == 0) {
            return <strong>No Results Found</strong>;
        }
        return (
            <ul>
                {searchRes.map((res) => {
                    return (
                        <>
                            <li className="d-flex justify-content-start align-items-center" onClick={(e) => {
                                navigate("/product/" + res.slug)
                            }}>
                                <div className="img-div d-flex justify-content-center align-items-center me-4">
                                    <img src={res.image_path} alt="Product Search"/>
                                </div>
                                <div className="prod-name">
                                    <strong>{res.productName}</strong>
                                    <div className="prod-rating d-flex align-items-center">
                                        <Rating
                                            name="read-only"
                                            value={res.averageRating}
                                            disabled
                                            className="prod-card-rating-star me-2"
                                        />
                                        <span>({res.totalReviews})</span>
                                    </div>
                                    <div className="prod-add-details d-flex align-items-center">
                                        <strong className="me-2 disable-text">CATEGORY/</strong>
                                        <strong className="disable-text">BRAND</strong>
                                    </div>
                                </div>
                            </li>
                            <hr/>
                        </>
                    );
                })}
            </ul>
        );
    };

    return (
        <div className="top-header-bar pt-2">
            <div className="card w-100">
                <div className="card-header w-100 d-flex align-items-center py-2">
                    <div className="container">
                        <div className="row w-100">
                            <div className="col-2"></div>
                            <div className="col-8 pe-0">
                                <div className="search-group">
                                    <div class="input-group search-bar">
                                        <input
                                            type="search"
                                            class="form-control rounded"
                                            placeholder="Search for products"
                                            aria-label="Search"
                                            aria-describedby="search-addon"
                                            onChange={searchHandler}
                                        />
                                        <button type="button" class="btn btn-outline-primary">
                                            Search
                                        </button>
                                    </div>
                                    <div className="search-content" style={displayStyle}>
                                        {getSearchContent()}
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 d-flex align-items-center">

                                <div className="d-flex align-items-center justify-content-start w-100">
                                    <div className="icon-links" onClick={() => navigate("/cart")}>
                                        <Badge badgeContent={storeContext.cartCount} color="primary">
                                            <FontAwesomeIcon icon={faCartArrowDown} size="xl"/>
                                        </Badge>
                                    </div>
                                    <div className="icon-links" onClick={() => navigate("/wishlist")}>
                                        <Badge badgeContent={storeContext.wishlistCount} color="primary">
                                            <FontAwesomeIcon icon={faHeart} size="xl"/>
                                        </Badge>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoreHeader;
