import {Box, Button, Card, Divider, Typography, useMediaQuery, useTheme} from '@mui/material'
import {Stack} from '@mui/system'
import {useQuery} from '@tanstack/react-query'
import React from 'react'
import ProductCards from '../../../../stores/ProductCards'
import FitnessContainer from '../../../fitnessCenter/components/FitnessContainer'
import FitnessProdCard from "../../../fitnessCenter/components/FitnessProdCard"
import CatalogSlider from '../../../gdstores/gdstorehome/components/CatalogSlider'
import {settings5items} from '../../../gdstores/sliderSettings/View5itemsOnlg'
import axios from '../../../../api'

function Recommended({children}) {
    // const data = ['https://cdn.shopify.com/s/files/1/1492/2278/products/c4-energy-still-12oz-fp.jpg?v=1652399874&width=1445', 'https://static-01.daraz.com.np/p/0e26ff3c196a4265140abb6c4ccd15e1.jpg', 'https://cdn.shopify.com/s/files/1/1492/2278/products/c4-energy-still-12oz-fp.jpg?v=1652399874&width=1445']

    const {data, isLoading, isError} = useQuery(
        ['latestViewed'],
        async () => (
            axios.get('/api/products?latest_views=desc').then(res => res.data.data)
        )
    )

    if (isLoading) {
        return
    }

    if (isError) {
        return
    }

    return (
        <>
            {children(data)}
        </>
    )
}

function RecommendedLaptop() {
    const theme = useTheme()
    const laptopView = useMediaQuery(theme.breakpoints.up('md'))
    let index = 0
    return laptopView &&
        <Recommended>
            {
                (data) => (
                    <Card variant='outlined'>

                        <Stack flexWrap='wrap'>
                            <Box py={1.5} px={2} sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <Typography variant='button' color='primary'>Recommended Product</Typography>
                            </Box>
                            <Stack flexWrap='wrap' alignItems='center' py={2} gap={1}>
                                {
                                    data.map((item, idx) => (
                                        idx < 4 &&
                                        <ProductCards data={item} key={idx}/>
                                    ))
                                }
                            </Stack>
                        </Stack>
                    </Card>
                )
            }
        </Recommended>
}

function RecommendedTablet() {
    const theme = useTheme()
    const laptopView = useMediaQuery(theme.breakpoints.up('md'))
    return !laptopView &&
        <Recommended>
            {
                (data) => (
                    <FitnessContainer title='Recommended Product'>
                        <CatalogSlider numItems={5} settingProps={settings5items}>
                            {
                                data.map((item, idx) => (
                                    <div>
                                        <ProductCards data={item} key={idx}/>
                                    </div>
                                ))
                            }
                        </CatalogSlider>
                    </FitnessContainer>
                )
            }
        </Recommended>
}

export {Recommended, RecommendedLaptop, RecommendedTablet}
