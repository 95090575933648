import {Box, Link, Stack, Typography} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React from 'react'
import axios from '../../api'
import {AccessTimeOutlined, LocationOnOutlined} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'

function MoreJobs() {
    const {data, isLoading, isError} = useQuery(
        ['vacancy'],
        async () => (
            axios.get('api/vacancy').then(res => res.data).catch(err => err)
        )
    )
    const navigate = useNavigate()
    if (isLoading) {
        return
    }
    return (
        <Box>
            <Stack gap={1}>
                {
                    data.map((item, idx) => (
                        <Link to={`/career/${item.slug}`} style={{textDecoration: 'none'}}
                              onClick={() => navigate(`/career/${item.slug}`)}>
                            <Box key={idx} border={1} borderColor={'divider'} borderRadius={2} p={1} sx={{
                                cursor: 'pointer',
                                transition: 'all 300ms',
                                '&:hover': {boxShadow: 5, transform: 'scale(1.02)'}
                            }}>
                                <Stack gap={1}>
                                    <Typography variant='h6'>
                                        {item.job_title}
                                    </Typography>
                                    <Box display='flex' gap={2}>
                                        <Box bgcolor='#f2f3f3' p={0.5} px={1} display='flex' alignItems='center' gap={1}
                                             borderRadius={1}>
                                            <LocationOnOutlined sx={{fontSize: '16px'}}/>
                                            <Typography textDecoration='none' fontWeight={600} fontSize='14px'
                                                        color='text.secondary'>{item.job_location}</Typography>
                                        </Box>
                                        <Box bgcolor='#f2f3f3' p={0.5} px={1} display='flex' alignItems='center' gap={1}
                                             borderRadius={1}>
                                            <AccessTimeOutlined sx={{fontSize: '16px'}}/>
                                            <Typography textDecoration='none' fontWeight={600} fontSize='14px'
                                                        color='text.secondary'>{item.job_type}</Typography>
                                        </Box>
                                    </Box>
                                </Stack>
                            </Box>
                        </Link>
                    ))
                }
            </Stack>
        </Box>
    )
}

export default MoreJobs
