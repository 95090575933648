import {ArticleOutlined, DeleteOutline, DeleteSweepOutlined, HistoryOutlined, SearchOutlined} from '@mui/icons-material'
import {Box, IconButton, Stack, Typography} from '@mui/material'
import React, {useEffect, useRef} from 'react'
import axios from '../../../api'
import {useChkLogin} from '../../../../middlewares/LoginMiddleware'
import {Link} from 'react-router-dom'
import {chkElementInParent} from '../../../../helperFunctions/chkElementInParent'

function SearchDropdown({show, open, searchResults, searchHistory, close, refetchHistory, onClick, dropRef}) {
    useEffect(() => {
        if (open) {
            const clickAway = (e) => {
                if (!chkElementInParent('.search-function', e.target)) {
                    close()
                }
            }
            window.addEventListener('click', clickAway)

            return () => {
                window.removeEventListener('click', clickAway)
            }
        }
    }, [open])
    if (!open) {
        return
    }
    return (
        <Box
            position='absolute'
            top='100%'
            left='0'
            width='100%'
            height='200px'
            sx={{zIndex: 50}}
        >
            <Box
                mx='1rem'
                p={2}
                bgcolor='white'
                border={1}
                borderColor='divider'
            >
                <Stack gap={1}>
                    {
                        show === 0 ?
                            <SearchHistory searchHistory={searchHistory} refetchHistory={refetchHistory}
                                           onClick={onClick}/>
                            :
                            <SearchList searchResults={searchResults} onClick={onClick}
                                        refetchHistory={refetchHistory}/>
                    }
                </Stack>
            </Box>

        </Box>
    )
}

function SearchHistory({searchHistory, onClick, refetchHistory}) {
    const {token} = useChkLogin()
    console.log()
    const historyItems = searchHistory.slice(0, 8)
    let historyArray = []

    function deleteAll() {
        axios.delete(`api/clear-history`, {
            headers: {'Authorization': `Bearer ${token}`},
            params: {
                id: historyArray.toString()
            },
        }).then(res => {
            console.log()
            refetchHistory()
        }).catch(err => console.log())
    }

    return (
        <>
            <Typography variant='subtitle1' color='text.disabled'>Search History</Typography>
            {
                searchHistory === undefined || searchHistory.length === 0 ?
                    <NoData/>
                    :
                    historyItems.map((item, idx) => {
                        historyArray.push(item.id)
                        return <SearchHistoryItem key={idx} item={item} onClick={onClick}
                                                  refetchHistory={refetchHistory}/>
                    })
            }
            <Box mt={1} display='flex' alignItems='center' gap={2}>
                <DeleteSweepOutlined sx={{color: 'error.main', cursor: 'pointer'}} onClick={deleteAll}/>
                <Typography variant='subtitle1' color='error.main'>Clear History</Typography>
            </Box>
        </>
    )
}

function SearchList({searchResults, onClick, refetchHistory}) {
    return (
        <>
            <Typography variant='subtitle1' color='text.disabled'>Search Results</Typography>
            {
                searchResults.length === 0 ?
                    <NoData/>
                    :
                    searchResults.map((item, idx) => (
                        <SearchItem key={idx} item={item} onClick={onClick} refetchHistory={refetchHistory}/>
                    ))
            }

        </>
    )
}

function SearchHistoryItem({item, refetchHistory, onClick}) {
    const {token} = useChkLogin()

    function deleteItem() {
        axios.delete(`/api/remove-history/${item.id}`, {headers: {'Authorization': `Bearer ${token}`}}).then(res => {
            refetchHistory()
        })
    }

    return (
        <Box display='flex' justifyContent='space-between' alignItems={'center'}
             sx={{cursor: 'pointer', '&:hover': {bgcolor: 'divider'}}}>
            <Box display='flex' flexGrow={1} alignItems='center' gap={2}>
                <HistoryOutlined sx={{fontSize: '1.3rem'}}/>
                <Box style={{textDecoration: 'none', color: 'inherit', flexGrow: 1}}
                     onClick={(e) => onClick(item.query)}>
                    <Typography variant='subtitle1'>{item.query}</Typography>
                </Box>
                {/* <Link to={`/gd-store/filter?keyword=${item.query}`} style={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                    <Typography variant='subtitle1'>{item.query}</Typography>
                </Link> */}
            </Box>
            <Box sx={{cursor: 'pointer'}} onClick={deleteItem}>
                <DeleteOutline sx={{fontSize: '1.3rem', color: 'text.secondary'}}/>
            </Box>
        </Box>
    )
}

function SearchItem({item, onClick, refetchHistory}) {
    return (
        <Box display='flex' justifyContent='space-between' alignItems={'center'}
             sx={{cursor: 'pointer', '&:hover': {bgcolor: 'divider'}}}>
            <Box display='flex' flexGrow={1} alignItems='center' gap={2}>
                <SearchOutlined sx={{fontSize: '1.3rem'}}/>
                <Box style={{textDecoration: 'none', color: 'inherit', flexGrow: 1}} onClick={(e) => {
                    onClick(item)
                    refetchHistory()
                }}>
                    <Typography variant='subtitle1'>{item}</Typography>
                </Box>
                {/* <Link to={`/gd-store/filter?keyword=${item.productName}`} style={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                    <Typography variant='subtitle1'>{item.productName}</Typography>
                </Link> */}
            </Box>
        </Box>
    )
}

function NoData() {
    return (
        <Box display='flex' gap={2} alignItems={'center'}>
            <ArticleOutlined sx={{fontSize: '1.3rem'}}/>
            <Typography variant='subtitle1'>No Data</Typography>
        </Box>
    )
}

export default SearchDropdown
