import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./slick3d.scss";
import axios from "./api";

const NextArrow = ({ onClick }) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <FontAwesomeIcon icon={faArrowRight} />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="arrow prev" onClick={onClick}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </div>
  );
};

function Slick3d() {
  const [packages, setPackages] = React.useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    axios
      .get("/api/package")
      .then((res) => {
        setPackages(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log();
      });
  }, []);

  const settings = {
    infinite: true,
    lazyload: true,
    speed: 300,
    slidesToShow: 2,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setItemIndex(next),

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [itemIndex, setItemIndex] = React.useState(0);

  return (
    <div className="packages">
      <div className="container py-5">
        <div className="text-white text-center heading">
          <h1>Packages</h1>
          <p className="text-white">
            Choose the best Package that is suitable for you and your family
          </p>
        </div>
        <div className="container">
          {packages.length > 3 ? (
            <div className="slick-3d">
              <Slider {...settings}>
                {packages.map((pg, idx) => (
                  <div
                    key={idx}
                    className={`slider-item package-item ${
                      idx === itemIndex ? "slide activeSlide" : "slide"
                    } h-100 d-flex flex-column px-5`}
                  >
                    <div className="pg-title pg-head-txt">
                      <span>{pg.package_type}</span>
                    </div>
                    <div className="pg-reg-fee">
                      <span className="pg-reg-label text-muted">
                        Registration Fee
                      </span>
                      <span className="pg-reg-value">
                        : Rs. {pg.registration_fee}/-
                      </span>
                    </div>
                    <div className="pg-monthly-fee pg-border">
                      <div className="pg-label">
                        <span>Monthly Fee</span>
                      </div>
                      <div className="pg-head-txt">
                        Rs.<span>{pg.monthly_fee}</span>/- per person
                      </div>
                    </div>
                    <div className="pg-num-visit pg-border">
                      <div className="pg-label">
                        <span>Number of Home Visit</span>
                      </div>
                      <div className="pg-head-txt">
                        <span>{pg.visits} times</span> /Year
                      </div>
                    </div>
                    <div className="pg-description">
                      <span className="pg-label">What's Included: </span>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${pg.description}`,
                        }}
                        className="mb-0"
                      ></p>
                    </div>
                    <div className="pg-button mt-auto">
                      <Link
                        to={`/single-package/${pg.slug}`}
                        state={{ pkg: pg }}
                        className="btn btn-primary"
                      >
                        Start Plan
                      </Link>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <div className="d-flex gap-4 justify-content-center">
              {packages.map((pg, idx) => (
                <div key={idx} style={{ width: "500px" }}>
                  <div className="card h-100 d-flex flex-column px-5 py-3">
                    <div className="pg-title pg-head-txt">
                      <span>{pg.package_type}</span>
                    </div>
                    <div className="pg-reg-fee">
                      <span className="pg-reg-label text-muted">
                        Registration Fee
                      </span>
                      <span className="pg-reg-value">
                        : Rs. {pg.registration_fee}/-
                      </span>
                    </div>
                    <div className="pg-monthly-fee pg-border">
                      <div className="pg-label">
                        <span>Monthly Fee</span>
                      </div>
                      <div className="pg-head-txt">
                        Rs.<span>{pg.monthly_fee}</span>/- per person
                      </div>
                    </div>
                    <div className="pg-num-visit pg-border">
                      <div className="pg-label">
                        <span>Number of Home Visit</span>
                      </div>
                      <div className="pg-head-txt">
                        <span>{pg.visits} times</span> /Year
                      </div>
                    </div>
                    <div className="pg-description">
                      <span className="pg-label">What's Included: </span>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${pg.description}`,
                        }}
                        className="mb-0"
                      ></p>
                    </div>
                    <div className="pg-button mt-auto">
                      <Link
                        to={`/single-package/${pg.slug}`}
                        state={{ pkg: pg }}
                        className="btn btn-primary"
                      >
                        Start Plan
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Slick3d;
