import React from 'react'
import NetworkError from './NetworkError';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: props.initialValue};
    }

    static getDerivedStateFromError(error) {
        console.log("Error is ", error)
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        if (this.props.setError) {
            this.props.setError(true)
        }

    }

    render() {
        if (this.state.hasError) {
            let online = window.navigator.onLine
            console.log("Online status is", online)
            if (online) {
                return <NetworkError type='Server Error'
                                     description='There was a server error. Please try again later.'/>
            }
            return (
                <NetworkError type='No Internet Connection'
                              description="You're offline. Check your connection and try again."/>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary
