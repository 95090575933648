import axios from "../api.js";
import React, {useCallback, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import "../../components/auth/SignUp.css";
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Logo from '../../images/white-logo.png';
import Background from '../../images/slider.png';
import {FileUploader} from "react-drag-drop-files";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";
import {useRef} from "react";
import useCaptchaVerify from "./captchaVerify.js";
import captchaVerify from "./captchaVerify.js";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";
import {NavPillItem, NavPillTab} from "./components/NavPillTab.jsx";
import {useContext} from "react";
import AuthButton from "../custom_componets/AuthButton.jsx";
import {LoginStateContext} from "../../contexts/LoginStateContext.js";

const imageTypes = ["JPG", "PNG", "GIF"];
const fileTypes = ["PDF"];

function BecomeADoctor() {
    const loginContext = useContext(LoginStateContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const currentParams = Object.fromEntries(searchParams)
    const [termsAgree, setTermsAgree] = useState(false)
    const [validateError, setValidateError] = useState({})
    const [submitting, setSubmitting] = useState(false)

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        watch: watch2,
        formState: {errors: errors2},
        control: control2
    } = useForm();
    const {
        register: register3,
        handleSubmit: handleSubmit3,
        watch: watch3,
        formState: {errors: errors3},
        control: control3
    } = useForm();
    const {
        register: register4,
        handleSubmit: handleSubmit4,
        watch: watch4,
        formState: {errors: errors4},
        control: control4
    } = useForm();
    let navigate = useNavigate();

    const [image, setImage] = useState(null);
    const [file, setFile] = useState();
    const [image2, setImage2] = useState();
    const [file2, setFile2] = useState();
    const [image3, setImage3] = useState();
    const [file3, setFile3] = useState();
    const [captchErr, setCaptchaErr] = useState(null);
    const [errorList, setErrorList] = useState([]);
    const [errorList2, setErrorList2] = useState([]);
    const [errorList3, setErrorList3] = useState([]);
    const [errorList4, setErrorList4] = useState([]);

    const [imageUser, setImageUser] = useState([]);


    const [imageDoctor, setImageDoctor] = useState([]);
    const [imageVendor, setImageVendor] = useState([]);
    const [imageNurse, setImageNurse] = useState([]);

    const [fileDoctor, setFileDoctor] = useState([]);
    const [fileVendor, setFileVendor] = useState([]);
    const [fileNurse, setFileNurse] = useState([]);

    const [captchaToken, setCaptchaToken] = useState()
    const [refreshCaptcha, setRefreshCaptcha] = useState(false)

    const captchaRef = useRef(null)

    const convertToBase64 = (inputFile, fileSet) => {
        var reader = new FileReader();
        reader.readAsDataURL(inputFile);
        console.log()
        var ext = inputFile.type.split('/')[1]
        reader.onload = () => {
            const strArr = reader.result.split('base64,')
            const base64Str = `data:image/${ext};base64,${strArr[1]}`
            console.log()
            fileSet(inputFile)
        }
        reader.onerror = error => {
            console.log();
        }
    }

    const handleImage = (image) => {
        // console.log()
        setImageDoctor(URL.createObjectURL(image))
        convertToBase64(image, setImage)
    }

    const handleFile = (file) => {
        console.log()
        setFileDoctor(URL.createObjectURL(file))
        convertToBase64(file, setFile)
    }

    const handleImage2 = (image2) => {
        setImageVendor(URL.createObjectURL(image2))
        convertToBase64(image2, setImage2)
    }
    const handleFile2 = (file2) => {
        setFileVendor(URL.createObjectURL(file2))
        convertToBase64(file2, setFile2)
    }

    const handleImage3 = (image3) => {
        setImageNurse(URL.createObjectURL(image3))
        convertToBase64(image3, setImage3)
    }
    const handleFile3 = (file3) => {
        setFileNurse(URL.createObjectURL(file3))
        convertToBase64(file3, setFile3)
    }

    async function validateHuman(token) {
        const secret = process.env.REACT_APP_RECAPTCHA_SECRET_KEY
        // return captchaVerify(secret, token)
        return true
    }

    const onVerify = useCallback((token) => {
        setCaptchaToken(token)
    }, [refreshCaptcha]);

    const registerVendor = (data, img, file) => {
        data.image = img;
        data.file = file;
        console.log()
        axios.post('api/vendor-profile/store', data, {'headers': {'Authorization': `Bearer ${loginContext.state.token}`}})
            .then(res => {
                setSubmitting(false)
                swal({
                    title: "Request Sent",
                    text: "Your Request is Sent. Will Notify you after verification",
                    icon: "success",
                }).then((value) => {
                    if (value) {
                        navigate("/", {replace: true});
                    }
                });
            })
            .catch((err) => {
                console.log()
                setSubmitting(false)
                setValidateError(err.response.data.message)
                captchaRef.current.reset()
                if (data['role'] === '6') {
                    setErrorList(err.response.data.message);
                }
                if (data['role'] === '4') {
                    setErrorList2(err.response.data.message);
                }
                if (data['role'] === '5') {
                    setErrorList3(err.response.data.message);
                }
                if (data['role'] === '7') {
                    setErrorList4(err.response.data.message);
                }

            });
    }

    const onSubmit = async (data) => {
        console.log();
        setSubmitting(true)
        const fd = new FormData();
        for (var key in data) {
            fd.append(key, data[key]);
        }
        fd.append('image', image);
        fd.append('file', file);
        fd.append('image', image2);
        fd.append('file', file2);
        fd.append('image', image3);
        fd.append('file', file3);

        console.log()

        const secret = process.env.REACT_APP_RECAPTCHA_SECRET_KEY
        // const captchaToken = await captchaRef.current.getValue()
        const response = await captchaVerify(secret, captchaToken)
        setRefreshCaptcha(prev => !prev)

        if (true) {
            switch (value) {
                case 0:
                    registerVendor(data, image2, file2)
            }
        } else {
            setCaptchaErr("Please Verify The Captcha")
        }

    };
    const [departments, setDepartment] = useState([]);
    const [vendortypes, setVendorType] = useState([]);
    const [value, setValue] = useState(0)

    useEffect(() => {
        axios
            .get('/api/admin/department')
            .then((res) => {
                setDepartment(res.data);
            })
            .catch((err) => {
                console.log();
            });
    }, []);
    useEffect(() => {
        axios
            .get('/api/vendor-type')
            .then((res) => {
                setVendorType(res.data);
            })
            .catch((err) => {
                console.log();
            });
    }, []);

    //function to view image before uploading
    const renderImage = (img) => {
        if (img == null || img.length == 0) {
            return
        }

        console.log()
        return (
            <div className="imgUpload">
                <img src={img} alt="upload img"/>
            </div>

        )
    }

    //function to view file before uploading
    const renderFile = (file) => {
        if (file == null || file.length == 0) {
            return
        }
        return (
            <div className="viewFileUpload">
                <iframe src={file} width="100%"/>
                Review PDF <a href={file} target="_blank">Click here</a>
            </div>

        )
    }
    if (loginContext.state.data.member.roles.length >= 2) {
        navigate(-1)
        return
    }
    return (
        <div className="container-fluid auth register">
            <div className="row">
                <div className="col-lg-6 image-side px-0">
                    <img src={Background} alt="" className='bg-image'/>
                    <div className="image-overlay"></div>
                    <div className="text-center bg-logo mx-auto">
                        <img src={Logo} alt=""/>
                    </div>
                </div>
                <div className="col-lg-6 offset-lg-6 login-form">
                    <div className="card border-0">
                        <div>
                            <h3 className="card-title">Become A Doctor</h3>
                            <p>Fill the form below to become a doctor </p>
                        </div>
                        <NavPillTab value={value} onChange={(e, newVal) => {
                            setValue(newVal)
                        }}>
                            <NavPillItem label="Vendor Register"/>
                            <NavPillItem label='Doctor Register'/>
                            <NavPillItem label='Nurse Register'/>
                        </NavPillTab>
                        {
                            value === 0
                            &&
                            <form onSubmit={handleSubmit3(onSubmit)} className="form">
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="form-label">Store Name</label>
                                        <input
                                            type="text"
                                            className="form-control input-field"
                                            required
                                            {...register3("store_name")}
                                        />
                                        {errors3.name?.type === "required" && (
                                            <p>Please enter your name.</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Type</label>
                                        <select {...register3("vendor_type")} required
                                                className="form-select input-field">
                                            <option value="">-- Select --</option>
                                            {vendortypes.map((vendortype) =>
                                                <option value={vendortype.id}
                                                        key={vendortype.id}>{vendortype.vendor_type}</option>
                                            )}
                                        </select>
                                        {errors3.vendor_type?.type === "required" && (
                                            <p>Please select your vendor type.</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Address</label>
                                        <input
                                            type="text"
                                            className="form-control input-field"
                                            required
                                            {...register3("address")}
                                        />
                                        {errors3.address?.type === "required" && (
                                            <p>Please enter your address.</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="password-confirm">
                                            Upload your logo
                                        </label>
                                        <FileUploader handleChange={handleImage2} name="image" types={imageTypes}
                                                      hoverTitle="Drop here" required/>
                                        {image2 ? <>{renderImage(imageVendor)}<span
                                            className="text-success">File Name: {image2.name}.Uploaded Successfully</span></> : ''}

                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="password-confirm">
                                            Upload file
                                        </label>
                                        <FileUploader handleChange={handleFile2} name="image" types={fileTypes}
                                                      hoverTitle="Drop here" required/>
                                        {file2 ? <>{renderFile(fileVendor)}<span
                                            className="text-success">File Name: {file2.name}.Uploaded Successfully</span></> : ''}
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value=""
                                                   id="vendorChecked"/>
                                            <label className="form-check-label" htmlFor="vendorChecked">
                                                Agree to our Terms of use and Privacy Policy
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mt-4 d-grid">
                                        <AuthButton
                                            type='submit'
                                            component='button'
                                            className='btn px-5 btn-primary'
                                            label='Register'
                                            loading={submitting}
                                        />
                                    </div>
                                </div>
                            </form>
                        }
                        {
                            value === 1
                            &&
                            <form onSubmit={handleSubmit2(onSubmit)} className="form">
                                <div className="row">
                                    <input type="hidden" {...register2("role")} value="4"/>
                                    <input type="hidden" {...register2("is_verified")} value="0"/>
                                    <div className="col-md-8">
                                        <label className="form-label">NMC/NAMC/NHPC Number</label>
                                        <input
                                            type="number"
                                            className="form-control input-field"
                                            required
                                            {...register2("nmc_no")}
                                        />
                                        {errors2.nmc_no?.type === "required" && (
                                            <p>Please enter your NMC Number.</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Department</label>
                                        <select {...register2("department")} required
                                                className="form-select input-field">
                                            <option value="">-- Select --</option>
                                            {departments.map((department) =>
                                                <option value={department.id}
                                                        key={department.id}>{department.department}</option>
                                            )}
                                        </select>
                                        {errors2.department?.type === "required" && (
                                            <p>Please select your department.</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Specialization</label>
                                        <input
                                            type="text"
                                            className="form-control input-field"
                                            required
                                            {...register2("specialization")}
                                        />
                                        {errors2.specialization?.type === "required" && (
                                            <p>Please enter your specialization.</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Years Practiced</label>
                                        <input
                                            type="number"
                                            className="form-control input-field"
                                            required
                                            {...register2("year_practiced")}
                                        />
                                        {errors2.year_practiced?.type === "required" && (
                                            <p>Please enter your years practiced.</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Qualification</label>
                                        <input
                                            type="text"
                                            className="form-control input-field"
                                            required
                                            {...register2("qualification")}
                                        />
                                        {errors2.qualification?.type === "required" && (
                                            <p>Please enter your qualification.</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="password-confirm">
                                            Upload your photo
                                        </label>

                                        <FileUploader handleChange={handleImage} name="image" types={imageTypes}
                                                      hoverTitle="Drop here" required/>
                                        {image ? <>{renderImage(imageDoctor)}<span
                                            className="text-success">File Name: {image.name}.Uploaded Successfully</span></> : ''}
                                        {/* <input type="file" name="image" className="form-control mb-4" onChange={handleImage} required/> */}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="password-confirm">
                                            Upload file
                                        </label>
                                        <FileUploader handleChange={handleFile} name="file" types={fileTypes}
                                                      hoverTitle="Drop here" required/>
                                        {file ? <>{renderFile(fileDoctor)}<span
                                            className="text-success">File Name: {file.name}.Uploaded Successfully</span></> : ''}
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value=""
                                                   id="doctorChecked"/>
                                            <label className="form-check-label" htmlFor="doctorChecked">
                                                Agree to our Terms of use and Privacy Policy
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mt-4 d-grid">
                                        <button className="btn px-5 btn-primary" type="submit">
                                            Sign Up
                                        </button>
                                    </div>
                                    <div className="text-center mt-3">
                                        <h6>Or</h6>
                                        <h6>Already have an account? <Link to="/login"
                                                                           className='signup-btn'>Login</Link></h6>
                                    </div>
                                </div>
                            </form>
                        }
                        {
                            value === 2
                            &&
                            <form onSubmit={handleSubmit4(onSubmit)} className="form">
                                <div className="row">
                                    <input type="hidden" {...register4("role")} value="7"/>
                                    <input type="hidden" {...register4("is_verified")} value="0"/>
                                    <div className="col-md-12">
                                        <label className="form-label">NNC Number</label>
                                        <input
                                            type="number"
                                            className="form-control input-field"
                                            required
                                            {...register4("nnc_no")}
                                        />
                                        {errors4.nnc_no?.type === "required" && (
                                            <p>Please enter your NNC Number.</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Full Name</label>
                                        <input
                                            type="text"
                                            className="form-control input-field"
                                            required
                                            {...register4("name")}
                                        />
                                        {errors4.name?.type === "required" && (
                                            <p>Please enter your name.</p>
                                        )}
                                    </div>
                                    {/* <div className="col-md-6">
                                        <label className="form-label">Nurse Type</label>
                                        <select {...register4("nurse_type")} required className="form-select input-field">
                                            <option value="">-- Select --</option>
                                            <option value="Homecare Nurse">Homecare Nurse</option>
                                            <option value="GD Office Nurse">GD Office Nurse</option>
                                        </select>
                                        {errors4.nurse_type?.type === "required" && (
                                            <p>Please select your nurse type.</p>
                                        )}
                                    </div> */}
                                    <div className="col-md-4">
                                        <label className="form-label">Gender</label>
                                        <select {...register4("gender")} required className="form-select input-field">
                                            <option value="">-- Select --</option>
                                            <option value="Female">Female</option>
                                            <option value="Male">Male</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        {errors4.gender?.type === "required" && (
                                            <p>Please select your gender.</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Years Practiced</label>
                                        <input
                                            type="number"
                                            className="form-control input-field"
                                            required
                                            {...register4("year_practiced")}
                                        />
                                        {errors4.year_practiced?.type === "required" && (
                                            <p>Please enter your years practiced.</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Qualification</label>
                                        <input
                                            type="text"
                                            className="form-control input-field"
                                            required
                                            {...register4("qualification")}
                                        />
                                        {errors4.qualification?.type === "required" && (
                                            <p>Please enter your qualification.</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="password-confirm">
                                            Upload your photo
                                        </label>

                                        <FileUploader handleChange={handleImage3} name="image" types={imageTypes}
                                                      hoverTitle="Drop here" required/>
                                        {image3 ? <>{renderImage(imageNurse)}<span
                                            className="text-success">File Name: {image3.name}.Uploaded Successfully</span></> : ''}

                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="password-confirm">
                                            Upload file
                                        </label>
                                        <FileUploader handleChange={handleFile3} name="file" types={fileTypes}
                                                      hoverTitle="Drop here" required/>
                                        {file3 ? <>{renderFile(fileNurse)}<span
                                            className="text-success">File Name: {file3.name}.Uploaded Successfully</span></> : ''}
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value=""
                                                   id="nurseChecked"/>
                                            <label className="form-check-label" htmlFor="nurseChecked">
                                                Agree to our Terms of use and Privacy Policy
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mt-4 d-grid">
                                        <button className="btn px-5 btn-primary" type="submit">
                                            Sign Up
                                        </button>
                                    </div>
                                </div>
                            </form>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BecomeADoctor
