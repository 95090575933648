import axios from "../api.js";
import React from "react";
import "./ShopByCategory.scss";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";
import FitnessContainer from "../pages/fitnessCenter/components/FitnessContainer.jsx";
import {Grid} from "@mui/material";
import FitnessCategoryCard from "../pages/fitnessCenter/components/FitnessCategoryCard.jsx";

const ShopByCategory = () => {
    const [categories, setCategories] = React.useState([]);
    const [categoryLoading, setCategoryLoading] = React.useState(true);
    const navigate = useNavigate()

    React.useEffect(() => {
        axios
            .get("api/categories")
            .then((res) => {
                setCategories(res.data);
                setCategoryLoading(false);
            })
            .catch((err) => {
                console.log();
                setCategoryLoading(false);
            });
    }, []);

    let catRenderCount = 0;

    // return (
    //   <div className="category-container mb-5">
    //     <div className="topic-head d-flex justify-content-between mb-4">
    //       <h2>Shop By Category</h2>
    //       <div className="topic-head-all">
    //         <Link to="" className="text-decoration-none">
    //           <span>See All</span>
    //           <FontAwesomeIcon icon={faCircleArrowRight} size="xl" />
    //         </Link>
    //       </div>
    //     </div>
    //     <div className="row w-100 g-1 mt-4">
    //       {categories.map((category) => {
    //         if (catRenderCount < 11) {
    //           return (
    //             <div className="col-4 col-md-3 col-lg-2">
    //               <div className="card category-card" onClick={() => navigate(`/gd-store/filter/${category.vendor_type_id}/${category.id}`)}>
    //                 <div className="card-header">
    //                   <div className="img-div">
    //                     <img src={category.image_path} alt="category image" />
    //                   </div>
    //                 </div>
    //                 <div className="card-body">
    //                   <div className="category-name">
    //                     <strong>{category.name}</strong>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           );
    //         }
    //       })}

    //     </div>
    //   </div>
    // );

    return (
        <FitnessContainer title={'Top Categories'}>
            <Grid container>
                {categories.map((category) => {
                    if (catRenderCount < 11) {
                        return (
                            <Grid item xs={6} md={4} lg={2}>
                                <FitnessCategoryCard
                                    img={category.image_path}
                                    name={category.name}
                                    onClick={() => navigate(`/gd-store/filter/${category.vendor_type_id}/${category.id}`)}/>
                            </Grid>
                        );
                    }
                })}
            </Grid>
        </FitnessContainer>
    )
};

export default ShopByCategory;
