import axios from 'axios';

const http = () => {
    /*
     * axios config
     * */
    const axiosConfig = {
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const instance = axios.create(axiosConfig);

    /*
     * intercepting request
     * */
    instance.interceptors.request.use((config) => {
        const token = localStorage.getItem('token_id');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });

    return instance;
};

export default http;
