import {InventoryOutlined} from '@mui/icons-material'
import {CardContent} from '@mui/material'
import {Box} from '@mui/material'
import {Button} from '@mui/material'
import {Typography} from '@mui/material'
import {Card} from '@mui/material'
import React from 'react'
import {useNavigate} from 'react-router-dom'

function LabTestItem({bgcolor = '#F1FAFE', data}) {
    const navigate = useNavigate()

    function handleClick(e) {
        navigate(`/health-service/${data.slug}`)
    }

    return (
        <Card sx={{bgcolor: bgcolor, maxWidth: '330px', textAlign: 'left', cursor: 'pointer', position: 'relative'}}>
            <CardContent>
                <Card sx={{bgcolor: '#58CAF4', color: 'white', p: 1, display: 'inline-block'}} elevation={0}>
                    <Typography variant='subtitle1'>GD Pharmacy</Typography>
                </Card>
                <Box height="130px">
                    <Typography variant='h6' lineHeight={1.2} my={1}>{data.service_name}</Typography>
                    <Typography variant='subtitle1' lineHeight={1.2} my={2}>{data.description}</Typography>
                </Box>
                <Typography variant='h4' my={1}>
                    रू 2000
                </Typography>
                <Button variant='outlined' startIcon={<InventoryOutlined/>} onClick={handleClick}>
                    Book Service
                </Button>
            </CardContent>
            <Box sx={{position: 'absolute', width: '25%', overflow: 'hiddden', bottom: 0, right: 0}}>
                <img
                    src="https://s3.ap-south-1.amazonaws.com/pe-neon-public/diagnostics/production/recommendationWidget/7e6ed82a2c373d1db2ea81a09e2fbb4c.png?dim=96x0"
                    alt="" style={{width: '100%', aspectRatio: '1/1'}}/>
            </Box>
        </Card>
    )
}

export default LabTestItem
