import {Rating} from "@mui/material";
import {RatingContext} from "../../contexts/RatingContext"
import {useContext} from 'react'
import {useQuery} from "@tanstack/react-query";
import {ProductContext} from "../pages/stores/ProductPage/ProductDetPage";
import axios from "../api";
import {useState} from "react";
import {useEffect} from "react";

const RatingDatas = () => {
    const productContext = useContext(ProductContext)
    // const { avgRating, ratingOneByOne } = { ...useContext(RatingContext) }
    const avgRating = productContext.product.averageRating

    const {data, isLoading, refetch} = useQuery(
        ['rating-one', productContext.product.id],
        async () => {
            return axios.get("api/admin/rating_onebyone?product_id=" + productContext.product.id)
                .then(res => res.data).catch(err => err)
        }
    )

    useChkProdRefetch(refetch)

    const getRatingPercent = (rating) => {
        let ratingElem = data.find((elem) => {
            return elem.rating == rating
        })
        if (ratingElem == undefined) {
            return 0
        }
        return Math.round((ratingElem.total / total) * 100)
    }

    const renderIndRating = () => {
        const renderElem = []
        for (let i = 5; i > 0; i--) {
            renderElem.push(
                <div className="rating-list-item d-flex align-items-center">
                    <Rating name="half-rating-read" value={i} readOnly size="large"/>
                    <div className="progress w-100">
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{width: `${getRatingPercent(i)}%`}}
                            aria-valuenow={30}
                            aria-valuemin={0}
                            aria-valuemax={100}
                        >
                            {getRatingPercent(i) + "%"}
                        </div>
                    </div>
                </div>
            )
        }
        return renderElem
    }

    if (isLoading) {
        return
    }

    let total = 0
    data.map((rating) => {
        total += rating.total
    })

    return (
        <div className="show-rating-data">
            <div className="tot-avg-rating">
                <h1>
                    {avgRating}<span>/5</span>
                </h1>
                <Rating
                    name="half-rating-read"
                    value={avgRating}
                    precision={0.5}
                    readOnly
                    className={"avg-rating"}
                />
                <h5>{total + " Reviews"}</h5>
            </div>

            <div className="rating-list my-5">
                {renderIndRating()}
            </div>
        </div>
    );
};

function useChkProdRefetch(refetch) {
    const [status, setStatus] = useState(false)
    const productCont = useContext(ProductContext)

    useEffect(() => {
        setStatus(!status)
        refetch()
    }, [productCont.product])

    return {status}
}

export default RatingDatas;
