import React, {useContext, useEffect, useState} from 'react'
import {FileUploader} from 'react-drag-drop-files';
import AuthButton from '../custom_componets/AuthButton';
import axios from "../api"
import {useForm} from 'react-hook-form';
import {
    convertToBase64,
    fileTypes,
    handleFiles,
    imageTypes,
    registerRole,
    renderFile,
    renderImage
} from './BecomeAMember';
import swal from 'sweetalert';
import {useNavigate} from 'react-router-dom';
import {LoginStateContext} from '../../contexts/LoginStateContext';

function BecomeAVendor({context}) {
    // const { imageObj, image, fileObj, file, register3, handleImage, handleFile, imageTypes, fileTypes, submitting, renderImage, renderFile, errors3 } = useContext(context)
    const navigate = useNavigate()
    const loginContext = useContext(LoginStateContext)
    // const [validateError, setValidateError] = useState()
    // const [errorList, setErrorList] = useState()
    const {
        register: register3,
        handleSubmit: handleSubmit3,
        watch: watch3,
        setValue: setFormValue,
        formState: {errors: errors3},
        control: control3
    } = useForm();
    const [vendortypes, setVendorType] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [imageObj, setImageObj] = useState()
    const [fileObj, setFileObj] = useState()
    const [image, setImage] = useState()
    const [file, setFile] = useState()
    const [ird, setIrd] = useState()
    const [irdObj, setIrdObj] = useState()
    const [tax, setTax] = useState()
    const [taxObj, setTaxObj] = useState()

    async function onSubmit(data) {
        setSubmitting(true)
        data.image = image;
        data.file = file;
        data.ird = ird;
        data.tax = tax;
        registerRole(data, '/api/vendor-profile/store', loginContext.state.token, setSubmitting, navigate)
    }

    // const registerRole = (data, api) => {
    //     data.image = image;
    //     data.file = file;
    //     data.ird = ird;
    //     data.tax = tax
    //     // console.log()
    //     axios.post(`api/vendor-profile/store`, data, { 'headers': { 'Authorization': `Bearer ${loginContext.state.token}` } })
    //         .then(res => {
    //             setSubmitting(false)
    //             if (res.data.error) {
    //                 swal({
    //                     title: "Error",
    //                     text: res.data.error,
    //                     icon: "error",
    //                 }).then((value) => {
    //                     if (value) {
    //                         navigate("/", { replace: true });
    //                     }
    //                 });
    //                 return
    //             }
    //             swal({
    //                 title: "Request Sent",
    //                 text: "Your Request is Sent. Will Notify you after verification",
    //                 icon: "success",
    //             }).then((value) => {
    //                 if (value) {
    //                     navigate("/", { replace: true });
    //                 }
    //             });
    //         })
    //         .catch((err) => {
    //             setSubmitting(false)
    //             setValidateError(err.response.data.message)
    //             setErrorList(err.response.data.message);
    //             if (err.response.data.error) {
    //                 swal({
    //                     title: "Error",
    //                     text: err.response.data.error,
    //                     icon: "error",
    //                 }).then((value) => {
    //                     if (value) {
    //                         navigate("/", { replace: true });
    //                     }
    //                 });
    //                 return
    //             }

    //         });
    // }
    useEffect(() => {
        axios
            .get('/api/vendor-type')
            .then((res) => {
                setVendorType(res.data);
            })
            .catch((err) => {
                console.log();
            });
    }, []);
    return (
        <form className="form" onSubmit={handleSubmit3(onSubmit)}>
            <div className="row">
                <div className="col-md-12">
                    <label className="form-label">Store Name</label>
                    <input
                        type="text"
                        className="form-control input-field"
                        required
                        {...register3("store_name")}
                    />
                    {errors3.name?.type === "required" && (
                        <p>Please enter your name.</p>
                    )}
                </div>
                <div className="col-md-6">
                    <label className="form-label">Company contact</label>
                    <input
                        type="number"
                        className='form-control input-field'
                        required
                        {...register3("company_contact")}
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Registration number</label>
                    <input
                        type='number'
                        className='form-control input-field'
                        required
                        {...register3("registration_no")}
                    />
                </div>

                <div className="col-md-6">
                    <label className="form-label">Type</label>
                    <select {...register3("vendor_type")} required className="form-select input-field">
                        <option value="">-- Select --</option>
                        {vendortypes.map((vendortype) =>
                            <option value={vendortype.id} key={vendortype.id}>{vendortype.vendor_type}</option>
                        )}
                    </select>
                    {errors3.vendor_type?.type === "required" && (
                        <p>Please select your vendor type.</p>
                    )}
                </div>
                <div className="col-md-6">
                    <label className="form-label">Address</label>
                    <input
                        type="text"
                        className="form-control input-field"
                        required
                        {...register3("address")}
                    />
                    {errors3.address?.type === "required" && (
                        <p>Please enter your address.</p>
                    )}
                </div>
                <div className="col-md-6">
                    <label htmlFor="password-confirm">
                        Upload your logo
                    </label>
                    <FileUploader handleChange={handleFiles(setImageObj, setImage)} name="image" types={imageTypes}
                                  hoverTitle="Drop here" required/>
                    {image ? <>{renderImage(imageObj)}<span className="text-success">File Name: {image.name}.Uploaded Successfully</span></> : ''}

                </div>
                <div className="mb-3 col-md-6">
                    <label htmlFor="password-confirm">
                        Company Registration
                    </label>
                    <FileUploader handleChange={handleFiles(setFileObj, setFile)} name="image" types={fileTypes}
                                  hoverTitle="Drop here" required/>
                    {file ? <>{renderFile(fileObj)}<span className="text-success">File Name: {file.name}.Uploaded Successfully</span></> : ''}
                </div>
                <div className="mb-3 col-md-6">
                    <label htmlFor="password-confirm">
                        Tax Clearance Document
                    </label>
                    <FileUploader handleChange={handleFiles(setTaxObj, setTax)} name="image" types={imageTypes}
                                  hoverTitle="Drop here" required/>
                    {image ? <>{renderFile(taxObj)}<span className="text-success">File Name: {image.name}.Uploaded Successfully</span></> : ''}

                </div>
                <div className="mb-3 col-md-6">
                    <label htmlFor="password-confirm">
                        IRD Document
                    </label>
                    <FileUploader handleChange={handleFiles(setIrdObj, setIrd)} name="image" types={fileTypes}
                                  hoverTitle="Drop here" required/>
                    {file ? <>{renderFile(irdObj)}<span className="text-success">File Name: {file.name}.Uploaded Successfully</span></> : ''}
                </div>

                <div className="col-md-6">
                    <label className="form-label">Guarantor name</label>
                    <input
                        type="text"
                        className='form-control input-field'
                        required
                        {...register3("guarantor_name")}
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Guarantor address</label>
                    <input
                        type="text"
                        className='form-control input-field'
                        required
                        {...register3("guarantor_address")}
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Guarantor contact</label>
                    <input
                        type="number"
                        className='form-control input-field'
                        required
                        {...register3("guarantor_contact")}
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Nominator name</label>
                    <input
                        type="text"
                        className='form-control input-field'
                        required
                        {...register3("nominator_name")}
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Nominator address</label>
                    <input
                        type="text"
                        className='form-control input-field'
                        required
                        {...register3("nominator_address")}
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Nominator contact</label>
                    <input
                        type="number"
                        className='form-control input-field'
                        required
                        {...register3("nominator_contact")}
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Membership time frame</label>
                    <input
                        type='text'
                        className='form-control input-field'
                        required
                        {...register3('membership_time_frame')}
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Membership fee</label>
                    <input
                        type='number'
                        className='form-control input-field'
                        required
                        {...register3('membership_fee')}
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Payment time frame</label>
                    <input
                        type='text'
                        className='form-control input-field'
                        required
                        {...register3('payment_time_frame')}
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Termination time frame</label>
                    <input
                        type='text'
                        className='form-control input-field'
                        required
                        {...register3('termination_time_frame')}
                    />
                </div>

                <div className="col-md-12">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="vendorChecked"/>
                        <label className="form-check-label" htmlFor="vendorChecked">
                            Agree to our Terms of use and Privacy Policy
                        </label>
                    </div>
                </div>
                <div className="mt-4 d-grid">
                    <AuthButton
                        type='submit'
                        component='button'
                        className='px-5 btn btn-primary'
                        label='Register'
                        loading={submitting}
                    />
                </div>
            </div>
        </form>
    )
}

export default BecomeAVendor
