import {Rating, Stack, useMediaQuery} from '@mui/material'
import React from 'react'
import {useSearchParams} from 'react-router-dom'
import {FilterItem, FilterTextLabel} from './FilterItem'


function ProductFilterRating() {
    const [searchParams, setSearchParams] = useSearchParams()

    function handleClick(e, rating) {
        const objectParams = Object.fromEntries(searchParams)
        setSearchParams({...objectParams, rating: rating})
    }

    function getRatingProps(rating) {
        return {
            onClick: (e) => handleClick(e, rating)
        }
    }

    return (
        <FilterItem filterTitle="Rating">
            <Stack direction='row' alignItems='center' {...getRatingProps(4)} sx={{cursor: 'pointer'}}>
                <Rating sx={{mr: 1}} readOnly value={4} size='small'/>
                <FilterTextLabel>Four Star and up</FilterTextLabel>
            </Stack>
            <Stack direction='row' alignItems='center' {...getRatingProps(3)} sx={{cursor: 'pointer'}}>
                <Rating sx={{mr: 1}} readOnly value={3} size='small'/>
                <FilterTextLabel>Three Star and up</FilterTextLabel>
            </Stack>
            <Stack direction='row' alignItems='center' {...getRatingProps(2)} sx={{cursor: 'pointer'}}>
                <Rating sx={{mr: 1}} readOnly value={2} size='small'/>
                <FilterTextLabel>Two Star and up</FilterTextLabel>
            </Stack>
            <Stack direction='row' alignItems='center' {...getRatingProps(1)} sx={{cursor: 'pointer'}}>
                <Rating sx={{mr: 1}} readOnly value={1} size='small'/>
                <FilterTextLabel>One Star and up</FilterTextLabel>
            </Stack>
        </FilterItem>
    )
}

export default ProductFilterRating
