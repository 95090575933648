import {AddCircleOutlined, Remove} from '@mui/icons-material'
import {Box, Button, Stack, TextField} from '@mui/material'
import React, {useEffect, useRef} from 'react'
import {useState} from 'react'

function QuantityStepper({
                             product, upperBound, disabled = false, qtyRef, inputListener = () => {
    }, defaultValue = 1
                         }) {
    const {quantity, inputQuantity, addQuantity, removeQuantity} = useQuantityLimit(upperBound, 1, defaultValue)
    const [itemQuan, setItemQuan] = useState(quantity)

    useEffect(() => {
        // console.log()
        console.log()
        inputListener(quantity)
    }, [quantity])


    function handleFocus(e) {
        if (quantity === '') {
            inputQuantity(1)
        }
    }

    function handleChange(e) {
        inputQuantity(e.target.value)
    }

    function handleClick(e) {
        console.log()
    }

    return (
        <Stack direction='row' alignItems='center' className='input-num-no-arrow'>
            <Button sx={{borderRadius: 0, minWidth: '20px', width: '40px'}} variant='outlined' onClick={removeQuantity}
                    disabled={disabled}><Remove/></Button>
            <TextField disabled={disabled} type='number'
                       sx={{width: '60px', height: '35px', p: 0, '& > div': {height: '100%', borderRadius: 0,}}}
                       onChange={handleChange} onBlur={handleFocus} value={quantity} inputRef={qtyRef} label={null}
                       variant='outlined'/>
            <Button disabled={disabled} onClick={addQuantity} sx={{borderRadius: 0, minWidth: '20px', width: '40px'}}
                    variant='contained'><AddCircleOutlined/></Button>
        </Stack>
    )
}

function useQuantityLimit(upperLimit = null, lowerLimit = null, defaultValue) {
    const [quantity, setQuantity] = useState(defaultValue)

    const addQuantity = () => {
        setQuantity((prev) => changeQuantity(Number(prev) + 1))
    }

    const removeQuantity = () => {
        setQuantity((prev) => changeQuantity(Number(prev) - 1))
    }

    const inputQuantity = (qty) => {
        setQuantity((prev) => changeQuantity(qty))
    }

    function changeQuantity(qty) {
        let resQty = qty
        if (qty === '') {
            return qty
        }
        if (upperLimit !== null) {
            if (qty > upperLimit) {
                resQty = upperLimit
            }
        }
        if (lowerLimit !== null) {
            if (qty < lowerLimit) {
                resQty = lowerLimit
            }
        }
        return `${resQty}`
    }

    return {quantity, addQuantity, removeQuantity, inputQuantity}
}

export default QuantityStepper
