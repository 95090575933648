import React, { useContext, useState } from "react";
import Logo from "../../../images/white-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { ProfileContext } from "../../hoc/protected";
import { clearToken } from "../../../utils/common/token.utils";
import { useMutation } from "@tanstack/react-query";
import ParentService from "../../../services/parent.service";
import { toast } from "react-hot-toast";

const DashboardNav = () => {
  const profile = useContext(ProfileContext);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubmenuVisible, setSubmenuVisible] = useState(false);

  const toggleSubmenu = () => {
    setSubmenuVisible(!isSubmenuVisible);
  };

  const { mutate: logout, isLoading } = useMutation({
    mutationFn: ParentService.parentLogout,
    onSuccess: (response) => {
      if (response?.data?.success) {
        clearToken();
        navigate("/login");
        toast.success("Log out successful.");
      }
    },
    onError: (err) => {
      if (err?.response) {
        console.log("Error response:", err?.response);
        toast.error("Something went wrong.");
      }
    },
  });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="navbar parent-dashboard navbar-expand-lg navbar-dark navbar-static d-flex align-items-center justify-content-between">
      <ul className="collapse navbar-collapse" id="navbar-mobile">
        <Link to="/">
          <img src={Logo} alt="Logo" width="70px" className="mr-2" />
          <span className="text-white" style={{ fontSize: "25px" }}>
            GHARGHARMA DOCTOR
          </span>
        </Link>
      </ul>
      <div className="d-flex gap-3 align-items-center">
        <div>{profile?.first_name}</div>
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={logout}
        >
          <i className="icon-switch2" />{" "}
          {isLoading ? "Logging out..." : "Logout"}{" "}
        </button>
      </div>

      <nav>
        <div
          className={`hamburger-menu ${isMenuOpen ? "active" : ""}`}
          onClick={toggleMenu}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className={`menu ${isMenuOpen ? "active" : ""}`}>
          <div className="sidebar-section">
            <ul className="nav nav-sidebar">
              <li className={"text-white py-3"}>
                <a href="#" className={`nav-link align-items-center`}>
                  <i className="icon-calendar"></i> <span>Medical Report</span>
                </a>
                <ul className="nav nav-group-sub" data-submenu-title="Layouts">
                  <li className="nav-item">
                    <Link
                      to="/parent"
                      className={`nav-link align-items-center`}
                    >
                      <i className="icon-dash"></i>
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/parent/children"
                      className={`nav-link align-items-center`}
                    >
                      <i className="icon-dash"></i>
                      <span>Children</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/parent/medical-history"
                      className={`nav-link align-items-center`}
                    >
                      <i className="icon-dash"></i>
                      <span>Medical History</span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/parent/vaccination"
                      className={`nav-link align-items-center`}
                    >
                      <i className="icon-dash"></i>
                      <span>Vaccination</span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/parent/consultation-history"
                      className={`nav-link align-items-center`}
                    >
                      <i className="icon-dash"></i>
                      <span>Consultation History</span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <div
                      onClick={toggleSubmenu}
                      className="nav-link align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <i className="icon-dash"></i>
                      <span>Insurance</span>
                    </div>
                    {isSubmenuVisible && (
                      <ul className="nav-submenu">
                        <span className="nav-item">
                          <a
                            href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                            download
                            className="nav-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {/* <i className="icon-download"></i> */}
                            <span>Insurance Claim Form</span>
                          </a>
                        </span>

                        <span>
                          <Link
                            to="/parent/insurance-claim"
                            className={`nav-link `}
                          >
                            <span>Insurance Claim Instruction</span>
                          </Link>
                        </span>
                      </ul>
                    )}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default DashboardNav;
