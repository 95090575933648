export function generateRandomString() {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 16; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export function encryptJSONStr(jsonData) {
    let key = 'ufxhqsy7ytiiibye'
    let keyBytes = new TextEncoder().encode(key);
    let dataBytes = new TextEncoder().encode(jsonData);
    let encryptedBytes = [];
    for (let i = 0; i < dataBytes.length; i++) {
      encryptedBytes.push(dataBytes[i] ^ keyBytes[i % keyBytes.length]);
    }
    let encryptedData = new TextDecoder().decode(new Uint8Array(encryptedBytes));
    return encryptedData;
}

