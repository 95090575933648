import { useQuery } from "@tanstack/react-query";
import ParentService from "../../../../../services/parent.service";
import React from "react";
import ConsultationCall from "../../../../consultation-call";
import { Box, CircularProgress } from "@mui/material";

const Loader = () => (
  <div className="d-flex gap-3 justify-content-center align-items-center py-5">
    <CircularProgress /> Loading...
  </div>
);

const ChildrenTable = ({ children }) => (
  <table className="table border-left border-right border-top responsive-table">
    <thead>
      <Box component="tr" bgcolor={"#"}>
        <th data-cell="S.N.">
          <span className="text-muted">S.N.</span>
        </th>
        <th data-cell="Full Name">
          <span className="text-muted">Full Name</span>
        </th>
        <th data-cell="Remaining Consultations">
          <span className="text-muted">Remaining Consultations</span>
        </th>
        <th data-cell="Action">
          <span className="text-muted">Action</span>
        </th>
      </Box>
    </thead>
    <tbody>
      {children.length ? (
        children.map((child, index) => (
          <tr key={index}>
            <td data-cell="S.N.">
              <span className="text-muted fs-6">{index + 1}</span>
            </td>
            <td data-cell="Full Name">
              <span className="text-muted fs-6"> {child.name}</span>
            </td>
            <td data-cell="Remaining Consultations">
              <span
                className={`fs-6 ${
                  child.member.family.online_consultation <= 3
                    ? "text-danger-consultation"
                    : child.member.family.online_consultation <= 5
                    ? "text-warning"
                    : "text-muted"
                }`}
              >
                {child.member.family.online_consultation}
              </span>
            </td>

            <td data-cell="Action">
              <div className={`action-consultation d-flex`}>
                <ConsultationCall
                  id={child.member.id}
                  remainingConsultation={
                    child.member.family.online_consultation
                  }
                  consultUserName={child.name}
                />
              </div>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={4}>
            <div className="w-100 d-flex justify-content-center">
              No Data Found.
            </div>
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

const ChildrenList = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["parent-children"],
    queryFn: () => ParentService.fetchChildren(),
  });

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-grid gap-2 mb-4">
          <div>
            <h4 className="fs-5 fw-bolder lh-sm">My Children</h4>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <ChildrenTable children={data?.data || []} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChildrenList;
