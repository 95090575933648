import {Box, Button, Card, FormControl, Grid, Stack, TextField, Typography} from '@mui/material'
import React from 'react'
import ReferImage from '../../../../images/refer.png'
import ReferImage2 from '../../../../images/refer2.png'
import {LoginStateContext} from '../../../../contexts/LoginStateContext';
import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import swal from 'sweetalert';
import usePostRequest from '../../../../customHooks/usePostRequest';
import ReferredList from './components/ReferredList';

function ReferHome() {
    const loginContext = useContext(LoginStateContext);
    const navigate = useNavigate()
    // const token = localStorage.getItem('token')
    const user = loginContext.state.data
    const preFormValues = {
        gd_id: user.member_id,
        email: user.member.email
    }
    const {
        register,
        handleSubmit,
        resetField,
        formState: {errors},
        control
    } = useForm({
        defaultValues: preFormValues
    });
    const [err, setErr] = useState(null)

    const referSubmit = usePostRequest(
        "/api/referralFriend",
        true,
        (stat, data) => {
            swal({
                title: "Referred A Friend Successfully!",
                icon: "success",
            }).then((value) => {
                if (value) {
                    // reloadContext.setReload(true)
                    resetField('first_name')
                    resetField('last_name')
                    resetField('referral_email')
                    resetField('phone')
                    resetField('address')
                }
            });
        },
        (err) => {
            setErr(err.response.data)
        }
    )

    console.log()

    const onSubmit = (data) => {
        const fd = new FormData();
        setErr(null)
        for (var key in data) {
            fd.append(key, data[key]);
        }
        console.log()
        referSubmit.submit(fd)
    }

    return (
        <Box className='referral-page d-flex flex-column mx-auto'>
            <Box className='w-100 py-2 mx-auto' width='60%'>
                <Typography variant='h1' textAlign='center' fontWeight='600 !important'>
                    Invite your friends to Ghargharmadoctor
                </Typography>
            </Box>
            {
                err != null &&
                <Box className="pt-2 mx-auto" width='50%'>
                    <Box className="alert alert-warning text-center">
                        {err.message}
                    </Box>
                </Box>
            }
            <Box display='flex' justifyContent='center' width='100%'>
                <Box width='25%' position='relative' overflow='hidden'>
                    <Box className='py-2 pe-2' position='absolute' width='100%' height='100%' top={0} left={0}
                         sx={{'& img': {width: '100%', height: '100%'}}}>
                        <img src={ReferImage2} alt=""/>
                    </Box>
                </Box>
                <Box className='py-2 mx-auto' width='50%'>
                    <Card elevation={0} sx={{
                        p: 2,
                        boxShadow: 'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;'
                    }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <TextField type='text' label='First Name' sx={{
                                            borderBottom: 1,
                                            borderColor: 'divider'
                                        }} {...register("first_name")} />
                                        {errors.name?.type === "required" && (
                                            <p>Please enter your name.</p>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <TextField type='text' label='Last Name' sx={{
                                            borderBottom: 1,
                                            borderColor: 'divider'
                                        }} {...register("last_name")} />
                                        {errors.name?.type === "required" && (
                                            <p>Please enter your name.</p>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <TextField type='text' label='Referral Email Address' sx={{
                                            borderBottom: 1,
                                            borderColor: 'divider'
                                        }}  {...register("referral_email", {
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Please enter a valid email.",
                                            },
                                        })} />
                                        {errors.email && (
                                            <p>{errors.email.message}</p>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <TextField type='text' label='Referral Phone Number' sx={{
                                            borderBottom: 1,
                                            borderColor: 'divider'
                                        }} {...register("phone")} />
                                    </FormControl>
                                    {errors.phone && (
                                        <p>Please enter a valid phone.</p>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <TextField type='text' label='Referral Address' sx={{
                                            borderBottom: 1,
                                            borderColor: 'divider'
                                        }} {...register("address")} />
                                    </FormControl>
                                    {errors.address?.type === "required" && (
                                        <p>Please enter your address.</p>
                                    )}
                                </Grid>
                                <Grid item xs={6} sx={{ml: 'auto'}}>
                                    <Box ml='auto' mt={'1rem'} width='fit-content'>
                                        <Button type='submit' variant='contained'>Send Invitation</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                        </form>
                    </Card>
                </Box>
                <Box width='25%' position='relative' overflow='hidden'>
                    <Box className='py-2' position='absolute' width='100%' height='100%' top={0} left={0}
                         sx={{'& img': {width: '100%', height: '100%'}}}>
                        <img src={ReferImage} alt=""/>
                    </Box>
                </Box>
            </Box>
            <Box className="py-4 mx-auto" width='50%'>
                <Box className='pb-1'>
                    <Typography variant='h4' fontWeight='600 !important'>Invited friends</Typography>
                </Box>
                <ReferredList/>
            </Box>
        </Box>
    )
}

export default ReferHome
