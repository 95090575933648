import {useQuery} from '@tanstack/react-query'
import React, {useState} from 'react'
import {FitnessContext} from '../FitnessContext'
import axios from '../../../api'

function FitnessProvider({children}) {
    const vendor = useQuery(
        ['vendor-fitness'],
        async () => (axios.get("/api/vendor-details?vendor_type=3").then(res => res).catch(err => err))
    )
    return (
        <FitnessContext.Provider value={{vendor: vendor}}>
            {children}
        </FitnessContext.Provider>
    )
}


export default FitnessProvider
