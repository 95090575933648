import {Backdrop, Box, CircularProgress, Modal} from "@mui/material";
import React, {useMemo, useState, useCallback} from "react";
import UploadFile from "../../custom_componets/UploadFile";
import {
    InputField,
    NepaliDateInput,
} from "../../custom_componets/FormComponents";
import PhoneInput from "react-phone-input-2";
import Loader from "../Loader";
import {useNavigate} from "react-router-dom";
import swal from "sweetalert";
import {useChkLogin} from "../../../middlewares/LoginMiddleware";
import axios from "../../api";
import {Check} from "@mui/icons-material";
import AddressModal from "./AddressModal";
import {schoolRegistrationInitialState} from "./school.schema";
import classNames from "classnames"

const debounceSearch = (searchFn) => {
    let timeout;

    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => searchFn(...args), 500);
    };
};


function SchoolProfileForm({
                               state = {},
                               type = "create",
                               apiLink = "/api/school-profile-register",
                               navigateTo = "/login?verify=verify_email",
                           }) {
    const {token, isLoading, data, isLoggedIn} = useChkLogin();
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [mapOpen, setMapOpen] = useState(false);
    const formattedToday = new Date().toISOString().split("T")[0];
    const [inputs, setInputs] = useState({...schoolRegistrationInitialState, ...state});
    const [errors, setErrors] = useState({});
    const [emailExist, setEmailExist] = useState(false)

    const chkDuplicateEmail = useCallback(
        debounceSearch((email) => {
            axios
                .get(`api/check-existing-email?email=${email}`)
                .then((res) => {
                    if (res.data.message == "Email Already Existed.") {
                        setEmailExist(true)
                    } else {
                        setEmailExist(false)
                    }
                })
                .catch((err) => console.log(err));
        }),
        []
    );

    const validatePassword = () => {
        const password = inputs.password
        return {
            hasLength: password.length >= 8,
            hasNumber: /\d/.test(password),
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
            hasSpecialChar: /[!@#$%^&*()_\-+={[}\]\\|:;"'<,>.]/.test(password)
        };
    }

    const chkValidEmail = useCallback((email) => {
        const splitEmail = email.split('@')
        if (splitEmail.length < 2) {
            return false
        }
        const splitEnd = splitEmail[1].split('.')
        if (splitEnd[1] !== 'com') {
            return false
        }
        return true
    }, [])

    function handleChange(e) {
        if (e.target.name === "email") {
            chkDuplicateEmail(e.target.value);
        }
        setInputs((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    }

    async function setAddressText(addr) {
        setInputs((prev) => ({...prev, company_address: addr}));
    }

    if (isLoading) {
        return (
            <Box p={10} display="flex" justifyContent="center">
                <Loader/>
            </Box>
        );
    }

    function handleLatLng(lat, lng) {
        console.log("Setting lat lng", lat, lng);
        setInputs((prev) => ({...prev, latitude: lat, longitude: lng}));
    }

    function handleFile(inputFile, name) {
        var reader = new FileReader();
        reader.readAsDataURL(inputFile);
        var ext = inputFile.type.split("/")[1];
        reader.onload = () => {
            const strArr = reader.result.split("base64,");
            const base64Str = `data:image/${ext};base64,${strArr[1]}`;
            setInputs({...inputs, [name]: base64Str});
        };
        reader.onerror = (error) => {
        };
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitting(true);
        if (isLoggedIn && type === "create") {
            apiLink = "/api/new-school";
        }
        axios
            .post(apiLink, inputs, {headers: {Authorization: `Bearer ${token}`}})
            .then((res) => {
                console.log(res);
                swal({
                    title: "Success",
                    text: `Your form is submitted successfully. ${
                        isLoggedIn ? "" : "Please verify your email to login."
                    }`,
                    icon: "success",
                    closeOnClickOutside: false,
                }).then((confirm) => {
                    if (confirm) {
                        navigate(navigateTo, {replace: true});
                    }
                });
            })
            .catch((err) => {
                let errData = err.response.data;
                console.log(errData)
                if(errData) {
                    setErrors(errData.errors)
                }

            })
            .finally(() => setSubmitting(false));
    }



    return (
        <div>
            <form className="form-horizontal" onSubmit={handleSubmit}>
                {type === "create" && !isLoggedIn && (
                    <>
                        <h3 className="m-0 mb-2 p-0">School Primary User Details</h3>
                        <div className="card">
                            <div className="card-body">
                                <div className="row g-3">
                                    <div className="col-md-4">
                                        <InputField
                                            label="First name"
                                            name="first_name"
                                            value={inputs.first_name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <InputField
                                            label="Middle name"
                                            name="middle_name"
                                            value={inputs.middle_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <InputField
                                            label="Last name"
                                            name="last_name"
                                            value={inputs.last_name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            label="Email address"
                                            name="email"
                                            type="email"
                                            value={inputs.email}
                                            error={errors.hasOwnProperty('email')}
                                            errorMsg={errors?.email ? errors.email[0] : ''}
                                            onChange={handleChange}
                                            appendContent={(chkValidEmail(inputs?.email) && !emailExist) ?
                                                <Check color="success"/> : null}
                                            required
                                        />
                                        {(inputs?.email?.length > 0 && emailExist) &&
                                            <small className="text-danger">Email Already Exists.</small>}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Phone number</label>
                                        <PhoneInput
                                            country={"np"}
                                            onlyCountries={["np"]}
                                            disableDropdown
                                            countryCodeEditable={false}
                                            inputProps={{
                                                name: "phone",
                                                required: true,
                                            }}
                                            value={inputs.phone}
                                            onChange={(phone) =>
                                                setInputs({...inputs, phone: phone})
                                            }
                                            inputStyle={{paddingLeft: "48px", width: "100%"}}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            label="Password"
                                            name="password"
                                            type="password"
                                            value={inputs.password}
                                            onChange={handleChange}
                                            error={errors.hasOwnProperty('password')}
                                            errorMsg={errors?.password ? errors.password[0] : ''}
                                            required
                                        />
                                        <ul style={{fontSize: 14, paddingLeft: 12}} className="pt-3 pl-0 opacity-75 d-flex gap-` flex-column">
                                            <li className={classNames({"text-success": validatePassword()?.hasLength})}>
                                                Password must be at least 8 characters long
                                            </li>
                                            <li className={classNames({"text-success": validatePassword()?.hasLowerCase})}>
                                               Password must be least one lowercase letter,
                                            </li>
                                            <li className={classNames({"text-success": validatePassword()?.hasUpperCase})}>
                                                Password must be one uppercase letter
                                            </li>
                                            <li className={classNames({"text-success": validatePassword()?.hasSpecialChar})}>
                                                Password must be  one special character.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            label="Confirm password"
                                            name="confirm_password"
                                            type="password"
                                            value={inputs.confirm_password}
                                            error={errors?.confirm_password}
                                            errorMsg={
                                                errors?.confirm_password
                                                    ? errors.confirm_password.message
                                                    : null
                                            }
                                            onChange={handleChange}
                                            required
                                        />
                                        {inputs?.confirm_password?.length > 0 &&
                                            inputs?.confirm_password !== inputs?.password && (
                                                <small className="text-danger">
                                                    Password doesn't match
                                                </small>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <h3 className="mt-4 p-0 mb-2">School Information</h3>
                <div className="card">
                    <div className="card-body">
                        <div className="row g-3">
                            <div className="col-md-4">
                                <InputField
                                    label="Owner name"
                                    name="owner_name"
                                    value={inputs.owner_name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="School name"
                                    name="company_name"
                                    value={inputs.company_name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="School address"
                                    name="company_address"
                                    value={inputs.company_address}
                                    onClick={(e) => setMapOpen(true)}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <NepaliDateInput
                                    label="School start date"
                                    name="company_start_date"
                                    value={inputs.company_start_date}
                                    onChange={handleChange}
                                    max={formattedToday}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="form-label">School contact number</label>
                                    <PhoneInput
                                        country={"np"}
                                        onlyCountries={["np"]}
                                        disableDropdown
                                        countryCodeEditable={false}
                                        inputProps={{
                                            name: "contact_number",
                                            required: true,
                                        }}
                                        value={inputs.contact_number}
                                        onChange={(phone) =>
                                            setInputs({...inputs, contact_number: phone})
                                        }
                                        inputStyle={{paddingLeft: "48px", width: "100%"}}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="PAN number"
                                    type="number"
                                    name="pan_number"
                                    value={inputs.pan_number}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>
                                        School description<code>*</code>
                                    </label>
                                    <textarea
                                        className="form-control mt-2"
                                        rows="4"
                                        name="description"
                                        onChange={handleChange}
                                        value={inputs.description}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className="mt-4 mb-2 p-0">Document Upload</h3>
                <div className="card">
                    <div className="card-body">
                        <div className="grid row">
                            <div className="col d-flex">
                                <UploadFile
                                    name="company_image"
                                    type={"img"}
                                    acceptFileTypes={['jpeg', 'png']}
                                    label="School Logo"
                                    value={inputs.company_image ? [inputs.company_image] : []}
                                    setImageInput={(fileG) => {
                                        handleFile(fileG[0], "company_image");
                                    }}
                                    required
                                />
                            </div>

                            <div className="col d-flex">
                                <UploadFile
                                    name="paper_work_pdf"
                                    type={"pdf"}
                                    accept={"application/pdf,application/vnd.ms-excel"}
                                    acceptFileTypes={['pdf', 'vnd.ms-excel']}
                                    label="PAN/VAT document"
                                    value={inputs.paper_work_pdf ? [inputs.paper_work_pdf] : []}
                                    setImageInput={(fileG) => {
                                        handleFile(fileG[0], "paper_work_pdf");
                                    }}
                                    required
                                />
                            </div>

                            <div className="col d-flex">
                                <UploadFile
                                    name="school_regd_file"
                                    type={"pdf"}
                                    accept={"application/pdf,application/vnd.ms-excel"}
                                    acceptFileTypes={['pdf', 'vnd.ms-excel']}
                                    label="School registration file"
                                    value={
                                        inputs.school_regd_file ? [inputs.school_regd_file] : []
                                    }
                                    setImageInput={(fileG) => {
                                        handleFile(fileG[0], "school_regd_file");
                                    }}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <button className="btn btn-primary" type="submit">
                        Submit form
                    </button>
                </div>
            </form>
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={submitting}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <AddressModal
                open={mapOpen}
                handleClose={() => setMapOpen(false)}
                setLatLong={handleLatLng}
                lat={inputs.latitude}
                lng={inputs.longitude}
                setAddress={setAddressText}
            />
        </div>
    );
}


export default SchoolProfileForm;
