import {Card, CardContent, CardMedia, Typography} from '@mui/material'
import React from 'react'

function FitnessCategoryCard({
                                 img = 'https://beta.apinouthemes.com/uploads/ff63a2eb476e45b1bfef05ee79115018.jpg',
                                 name = 'Gym Equipments',
                                 onClick
                             }) {
    return (
        <Card sx={{width: "90%", cursor: 'pointer'}} variant="outlined" onClick={onClick}>
            <CardMedia
                component={'img'}
                image={img}
                alt="Product Item 1"
                sx={{aspectRatio: "1/1"}}
            />
            <CardContent>
                <Typography>{name}</Typography>
            </CardContent>
        </Card>
    )
}

export default FitnessCategoryCard
