import {Box, Card, CardHeader, Grid, Typography} from '@mui/material'
import React, {createContext, useState} from 'react'
import {useParams} from 'react-router-dom'
import ChooseDepartment from './components/ChooseDepartment'
import LabProfile from './components/LabProfile'
import LabProfileItem from './components/LabProfileItem'
import LabTestContent from './components/LabTestContent'
import LabTestItem from './components/LabTestItem'
import {LabTestProvider} from './components/LabTestProvider'
import LabTests from './components/LabTests'
import LabTestSideBar from './components/LabTestSideBar'

function LabTestPage() {
    const params = useParams()
    const [department, setDepartment] = useState([])
    return (
        <LabTestProvider>
            <Box className='container' py={2}>
                <Grid container>
                    <Grid item xs={2.5}>
                        <LabTestSideBar/>
                    </Grid>
                    <Grid item xs={9.5} pl={2}>
                        {
                            params.department ?
                                <LabTestContent department={params.department}/>
                                :
                                <ChooseDepartment/>
                        }
                    </Grid>
                </Grid>
            </Box>
        </LabTestProvider>
    )
}

export default LabTestPage
