import axios from 'axios'
import {initializeApp} from 'firebase/app'
import {getMessaging, getToken, onMessage} from 'firebase/messaging'

const VAPID_KEY = 'BLShS6w1otKM97RZgJ3wmHGjCo2eKZDE3v8-E9AXlxK97E5O29k2L7XpU0LUxMPgZ7Zrd7rUfVCXf1BA2K8-8d4'

var firebaseConfig = {
    apiKey: "AIzaSyCx0y-siT1mFW4HYHQCRpoZoYJOJOz7xAI",
    authDomain: "ghargharma-doctor-cf9a5.firebaseapp.com",
    projectId: "ghargharma-doctor-cf9a5",
    storageBucket: "ghargharma-doctor-cf9a5.appspot.com",
    messagingSenderId: "604381450402",
    appId: "1:604381450402:web:1aed8549a56b9dbaa1c7fb",
    measurementId: "G-073PD5BK8C"
}

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const requestForToken = async (setFCM, setFCMLoading, token) => {
    try {
        const currentToken = await getToken(messaging, {vapidKey: VAPID_KEY})
        if (currentToken && token) {
            setFCM(currentToken)
            await axios.post('https://demo.ghargharmadoctor.com/api/admin/store-token', {
                device_key: currentToken, platform: 'WEB'
            }, {headers: {'Authorization': `Bearer ${token}`}})
        }
    } catch (e) {
        console.log(e)
    } finally {
        setFCMLoading(false)

    }
}

export const getFCMToken = async () => {
    return getToken(messaging, {vapidKey: VAPID_KEY}).then((currentToken) => {
        if (currentToken) {
            return currentToken
        }
    }).catch(err => {
        console.log()
    })
}

export const onMessageListener = () => {
    return new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            // console.log()
            resolve(payload)
        })
    })
}


