import React from "react";
import { useChkLogin } from "../../../../middlewares/LoginMiddleware";
import CompanyProfileForm from "../../companyProfileForm/CompanyProfileForm";
import axios from "../../../api";
import { useEffect } from "react";

function EditCorpProfile() {
  const { token, data } = useChkLogin();
  let compProfile = data?.school_profile;
  // useEffect(() => {
  //     axios.get('https://cors-anywhere.herokuapp.com/https://demo.ghargharmadoctor.com/storage/images/call-doctor-concept-doctors-answer-patient-questions-phone_1150-50289-removebg-preview_1680432004.png').then(res => console.log("Image fetch response", res))
  // }, [])

  console.log(data, "data");

  return (
    <CompanyProfileForm
      state={{
        owner_name: compProfile.owner_name,
        company_name: compProfile.company_name,
        company_address: compProfile.company_address,
        company_start_date: compProfile.company_start_date,
        contact_number: compProfile.contact_number,
        pan_number: compProfile.pan_number,
        description: compProfile.description,
        company_image_path: compProfile.company_image_path,
        paper_work_pdf_path: compProfile.paper_work_pdf_path,
        types: "corporate",
        latitude: compProfile.latitude,
        longitude: compProfile.longitude,
        registration_file_path: compProfile.school_regd_file_path,
      }}
      apiLink={`api/company-school-profile/${compProfile.id}`}
      navigateTo={"/user"}
    />
  );
}

export default EditCorpProfile;
