import React, {useState} from 'react'
import {StoreContext} from '../../../contexts/StoreContext'
import {useEffect, useContext} from 'react'
import './CancelOrder.scss'
import axios from '../../api'
import {useNavigate, useParams} from 'react-router-dom'
import swal from 'sweetalert'
import AuthButton from '../../custom_componets/AuthButton'

function CancelOrderProducts({product}) {
    return (
        <div className="order-item row w-100 align-items-center">
            <div className="item-image col-3 h-100">
                <div className="img-container">
                    <img src={product.image_path} alt=""/>
                </div>
            </div>
            <div className="item-name col-6">
                <h5>{product.productName}</h5>
            </div>
            <div className="item-qty col-3">
                <span><b>Qty<span className="text-muted">{product.pivot.quantity}</span></b></span>
            </div>
        </div>
    )
}

function CancelOrder() {
    const [order, setOrder] = useState({})
    const [cancelReasons, setCancelReasons] = React.useState([])
    const [cancelParams, setCancelParams] = useState({
        cancel_reason: null,
        terms_conditions: false,
        description: null
    })
    const [chkFields, setChkFields] = useState(false)
    const params = useParams()
    let storeContext = useContext(StoreContext)
    const token = localStorage.getItem('token')
    const navigate = useNavigate()
    useEffect(() => {
        // storeContext.setBreadCrumbs([
        //     ['/', "Home"], ["/store", "Store"], ['current_page', "Orders"]
        // ])
    }, [])
    useEffect(() => {

        axios.get("api/admin/order?order_number=" + params.orderNum, {
            headers: {Authorization: `Bearer ${token}`},
        })
            .then((res) => {
                console.log()
                setOrder(res.data[0])
            })
            .catch(err => {
                console.log()
            })

        axios.get("api/admin/order/cancelReason", {
            headers: {Authorization: `Bearer ${token}`},
        })
            .then((res) => {
                setCancelReasons(res.data);
            })
    }, [])
    useEffect(() => {
        chkFieldValidate()
    }, [cancelParams])

    const cancelOrder = async (e) => {
        console.log()
        try {
            let res = await axios.post("api/admin/order/cancelOrder/" + order.id, {
                cancel_reason: cancelParams.cancel_reason,
                description: cancelParams.description
            }, {
                headers: {Authorization: `Bearer ${token}`},
            })
            swal("Success", "Your Order Has Been Cancelled", "success");
            navigate("/user/orders")
        } catch (e) {

        }
        // let res = await axios.post("api/admin/order/cancelOrder/" + order.id, {
        //     cancel_reason: cancelParams.cancel_reason,
        //     description: cancelParams.description
        // }, {
        //     headers: { Authorization: `Bearer ${token}` },
        // })
        //     .then(res => {
        //         swal("Success", "Your Order Has Been Cancelled", "success");
        //         navigate("/orders")
        //     })
        //     .catch(err => {
        //         console.log()
        //     })
    }

    const handleInput = (e) => {

        if (e.target.name == "terms_conditions") {
            console.log()
            setCancelParams({...cancelParams, terms_conditions: e.target.checked})
        } else {
            setCancelParams({...cancelParams, [e.target.name]: e.target.value})
        }

    }

    const chkFieldValidate = () => {
        if (cancelParams.cancel_reason != null && cancelParams.terms_conditions) {
            setChkFields(true)
            return
        }
        setChkFields(false)
        console.log()
    }

    const getBtnDisableClass = () => {
        if (!chkFields) {
            return "disabled"
        }
        return ""
    }

    if (Object.keys(order).length == 0 || (order.status == "cancelled" || order.status == "delivered" || order.status == "processing")) {
        return (
            <>
            </>
        )
    }

    return (
        <div className="container mb-5">
            <h3 className="mt-3">Order Cancellation</h3>
            <div className="order-cancel-items row g-3">
                <div className="px-6 py-4 order-cancel-card card col-12">
                    <div className="order-cancel-row row w-100 g-3">
                        <div className="order-first order-cancel-reason col-12 col-md-4 order-md-last">
                            <select className="form-select" name='cancel_reason' onChange={handleInput}>
                                <option value="" disabled selected>Select Cancellation Reason</option>
                                {
                                    cancelReasons.map((reason) => {
                                        return <option value={reason.id}>{reason.cancel_reason}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="order-last order-items col-12 col-md-8 order-md-first">
                            {
                                Object.keys(order).length > 0 &&
                                (
                                    order.products.map((product) => {
                                        return <CancelOrderProducts product={product}/>
                                    })
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="order-cancel-details col-12">
                    <div className="order-cancel-details-card card">
                        <div className="order-cancel-detail-label card-header">
                            <h5>Cancellation Additional Details</h5>
                        </div>
                        <div className="p-4 order-cancel-desc">
                            <div class="form-outline">
                                <textarea className="form-control" id="textAreaExample1" rows="4"
                                          placeholder="Write Detail Description about cancellation reason"></textarea>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="order-cancel-policy col-12">
                    <div className="order-cancel-policy-card card">
                        <div className="order-cancel-policy-label card-header">
                            <h5>Cancellation Policy</h5>
                        </div>
                        <div className="p-3 order-cancel-body card-body">
                            <div className="bg-white p-2 order-cancel-desc">
                                <p>Read Through the following terms and policies before cancelling the order</p>
                                <ol>
                                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde autem voluptates
                                        voluptas dolorem, enim praesentium alias ex, ipsam possimus tenetur, omnis error
                                        laborum sit repellendus dolore saepe ratione ab provident!
                                    </li>
                                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde autem voluptates
                                        voluptas dolorem, enim praesentium alias ex, ipsam possimus tenetur, omnis error
                                        laborum sit repellendus dolore saepe ratione ab provident!
                                    </li>
                                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde autem voluptates
                                        voluptas dolorem, enim praesentium alias ex, ipsam possimus tenetur, omnis error
                                        laborum sit repellendus dolore saepe ratione ab provident!
                                    </li>
                                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde autem voluptates
                                        voluptas dolorem, enim praesentium alias ex, ipsam possimus tenetur, omnis error
                                        laborum sit repellendus dolore saepe ratione ab provident!
                                    </li>
                                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde autem voluptates
                                        voluptas dolorem, enim praesentium alias ex, ipsam possimus tenetur, omnis error
                                        laborum sit repellendus dolore saepe ratione ab provident!
                                    </li>
                                </ol>
                            </div>
                            <div className="my-3 bg-white p-2 agree-terms">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                           name="terms_conditions" onChange={handleInput}/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        I Agree To All Terms And Conditions
                                    </label>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>

                <div className="submit-btn">
                    <AuthButton callbackFn={cancelOrder} className={`${getBtnDisableClass()}`}
                                disabled={!chkFields}>Submit</AuthButton>
                    {/* // <div className={"btn btn-danger px-5 " + getBtnDisableClass()} onClick={cancelOrder}>
                    //     Submit
                    // </div> */}
                </div>
            </div>
        </div>
    )
}

export default CancelOrder
