import { useState } from "react"

const useVerifyPayment = () => {
    const [verifying, setVerifying] = useState(true)
    const [isVerified, setIsVerified] = useState()
    const [data, setData] = useState()

    const verifyPayment = async (apiCall) => {
        try {
            const res = await apiCall()
            setIsVerified(true)
            setData(res?.data)
        }
        catch(err) {
            setIsVerified(false)
            return err
        }
        finally {
            setVerifying(false)
        }
    }

    return {verifying, isVerified, verifyPayment, data}
}

export { useVerifyPayment }