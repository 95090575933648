import {Grid, Typography} from '@mui/material'
import React from 'react'
import Slider from 'react-slick'
import FitnessCategoryCard from './components/FitnessCategoryCard'
import FitnessCenters from './components/FitnessCenters'
import FitnessContainer from './components/FitnessContainer'
import FitnessHeader from './components/FitnessHeader'
import FitnessPackage from './components/FitnessPackage'
import FitnessProdCard from './components/FitnessProdCard'
import FitnessProvider from './components/FitnessProvider'
import FitnessTopCategories from './components/FitnessTopCategories'
import FitnessVendorCard from './components/FitnessVendorCard'
import PackagesFitness from './components/PackagesFitness'


const FitnessProdCardSlider = {
    responsive: [
        {
            breakpoint: 820,
            settings: {
                slidesToShow: 3,

            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 1150,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 350,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
}

function FitnessHome() {
    const settings = {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
        ...FitnessProdCardSlider,

    }
    return (
        <FitnessProvider>
            <div className="fitness-home-page">
                <FitnessHeader/>
                <div className="container">
                    <FitnessContainer title={'Fitness Center Membership Packages'}>
                        <PackagesFitness/>
                    </FitnessContainer>
                    <FitnessTopCategories/>
                    <FitnessCenters/>
                    <FitnessContainer title={'Fitness Supplements'}>
                        <Slider {...settings}>
                            <div>
                                <FitnessProdCard className='prodCard'
                                                 img='https://cdn.shopify.com/s/files/1/0471/3332/7519/products/JYM6370140-Raspberry-Lemon20-7-15_465x.jpg?v=1630530181'/>
                            </div>
                            <div>
                                <FitnessProdCard
                                    img='https://cdn.shopify.com/s/files/1/0471/3332/7519/products/Qualia-Resilience-15-serv-image-grey_465x.jpg?v=1673549314'/>
                            </div>
                            <div>
                                <FitnessProdCard
                                    img='https://cdn.shopify.com/s/files/1/0471/3332/7519/products/BCAA30-SERV-Rocket-Pop-front-grey_465x.jpg?v=1653506568'/>
                            </div>
                            <div>
                                <FitnessProdCard
                                    img='https://cdn.shopify.com/s/files/1/0471/3332/7519/products/JYM5080032-6821_465x.jpg?v=1623166851'/>
                            </div>
                            <div>
                                <FitnessProdCard
                                    img='https://storage.googleapis.com/bodybuilding-cms/media/2021/09/c7a3fe69-allmax-nutrition-quick-mass-e1668802999800.webp'/>
                            </div>
                            <div>
                                <FitnessProdCard/>
                            </div>
                            <div>
                                <FitnessProdCard/>
                            </div>
                            <div>
                                <FitnessProdCard/>
                            </div>
                        </Slider>

                    </FitnessContainer>
                    <FitnessContainer title={'Gym Equipments'}>
                        <Slider {...settings}>
                            <div>
                                <FitnessProdCard
                                    img='https://s.alicdn.com/@sc04/kf/H61eb7fee006b4989a2970e51ece9bf015.jpg_300x300.jpg'/>
                            </div>
                            <div>
                                <FitnessProdCard
                                    img='https://s.alicdn.com/@sc04/kf/H066fdb6ea6ff4308951f855a8b4f7c78o.jpg_300x300.jpg'/>
                            </div>
                            <div>
                                <FitnessProdCard
                                    img='https://s.alicdn.com/@sc04/kf/H23b5e6f68dcf4fe191028a2f540e2033b.jpg_300x300.jpg'/>
                            </div>
                            <div>
                                <FitnessProdCard
                                    img='https://s.alicdn.com/@sc04/kf/H7ef83e97c75a46b5b7e2c0361cbf5b16D.jpg_300x300.jpg'/>
                            </div>
                            <div>
                                <FitnessProdCard
                                    img='https://s.alicdn.com/@sc04/kf/H9eea7de7692246cc81bb010dfb2fc3afi.jpg_300x300.jpg'/>
                            </div>
                            <div>
                                <FitnessProdCard/>
                            </div>
                            <div>
                                <FitnessProdCard/>
                            </div>
                            <div>
                                <FitnessProdCard/>
                            </div>
                        </Slider>

                    </FitnessContainer>
                </div>
            </div>
        </FitnessProvider>
    )
}

export default FitnessHome
