import {useQuery} from '@tanstack/react-query';
import React from 'react'
import {useContext} from 'react';
import {LoginStateContext} from '../../../../contexts/LoginStateContext';
import axios from '../../../api'

function Recommendation({booking_id}) {
    const loginContext = useContext(LoginStateContext)
    const {isLoading, data} = useQuery(
        ['appointment_file', booking_id],
        async () => {
            return axios.get("api/admin/user-profile/medical-report?booking_id=" + booking_id, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => {
                    return res.data
                }).catch(err => {
                    console.log()
                })
        }
    )

    if (isLoading) {
        return (
            <h4>Getting Recommedation...</h4>
        )
    }

    if (data.length === 0) {
        return (
            <div className="alert alert-warning">
                No Recommendation
            </div>
        )
    }

    return (
        <>
            <div className="card">
                <h4><b>History:</b></h4>

                <div className="doctor-recommendation">
                    {data[0].history}
                </div>
            </div>
            <div className="card">
                <h4><b>Examination:</b></h4>

                <div className="doctor-recommendation">
                    {data[0].examination}
                </div>
            </div>
            <div className="card">
                <h4><b>Treatment:</b></h4>

                <div className="doctor-recommendation">
                    {data[0].treatment}
                </div>
            </div>
            <div className="card">
                <h4><b>Progress:</b></h4>

                <div className="doctor-recommendation">
                    {data[0].progress}
                </div>
            </div>
        </>

    )
}

export default Recommendation
