import { ArrowCircleUp, CallOutlined, SendOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Grow,
  IconButton,
  InputBase,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
} from "@mui/material";
import { blue, deepOrange } from "@mui/material/colors";
import React, { useEffect, useRef, useState } from "react";
import { useChkLogin } from "../../middlewares/LoginMiddleware";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "../api";
import { chatBotConfig } from "./ChatBodyConfig";
import ChatAvatar from "../../images/mascot.png";
import "./chat.css";

function ChatBody({ openChatModal }) {
  const loginObj = useChkLogin();
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexBasis: 0,
        flexGrow: 1,
        backgroundColor: "#f5f8fa",
      }}
    >
      <ChatBodyTop openChatModal={openChatModal} />
      {!loginObj.isLoading && <ChatBodyMessages />}
    </Box>
  );
}

function ChatBodyTop({ openChatModal }) {
  const loginObj = useChkLogin();
  const [submitting, setSubmitting] = useState(false);
  const pkgData = useQuery({
    queryKey: ["package_user"],
    queryFn: async () =>
      axios
        .get("api/admin/userpackage", {
          headers: { Authorization: `Bearer ${loginObj.token}` },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err)),
    enabled: loginObj.isLoggedIn,
  });
  const navigate = useNavigate();

  function handleCallConsultation() {
    if (!loginObj.isLoggedIn) {
      return openChatModal({
        title: "Call online package consultation",
        description:
          "You need to be logged in to make online package consultation. Please login and try again.",
        handleConfirm: function(callBackFn) {
          navigate("/login");
          callBackFn();
        },
      });
    }
    if (pkgData.data.package === null) {
      return openChatModal({
        title: "Call online package consultation",
        description:
          "You need to book package to make online consultation available in package. Please buy package and try again.",
        handleConfirm: function(callBackFn) {
          navigate("/packages");
          callBackFn();
        },
      });
    }
    if (pkgData.data.package.package.type === 4) {
      return openChatModal({
        title: "Call online package consultation",
        description:
          "Online package consultation is not available on school packages.",
        handleConfirm: function(callBackFn) {
          callBackFn();
        },
      });
    }
    let pkgStatus = pkgData.data.package.package_status;
    if (pkgStatus === "Deactivated" || pkgStatus === "Expired") {
      return openChatModal({
        title: "Call online package consultation",
        description:
          "Your package is expired or deactivated. Please buy or renew your package and try again.",
      });
    }
    openChatModal({
      title: "Call online consultation",
      description: `You are calling gd for online consultation. You have ${pkgData.data.package.familyname.online_consultation} consultation left.`,
      handleConfirm: function(callBackFn, setLoading) {
        setLoading(true);
        axios
          .post(
            "/api/online-consultation-meeting",
            { id: "123" },
            { headers: { Authorization: `Bearer ${loginObj.token}` } }
          )
          .then((res) => {
            callBackFn();
            setLoading(false);
            window.open(res.data.meeting_url, "_blank");
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });
  }

  function handleCallReception() {
    window.location.href = "tel:01-5917322";
  }

  if (loginObj.isLoading || pkgData.isLoading) {
    if (!loginObj.isLoading && !loginObj.isLoggedIn) {
    } else {
      return (
        <Box
          width="100%"
          display="flex"
          bgcolor={"#ddf4fd"}
          alignItems="center"
          gap={1}
          py={1}
          px={2}
        >
          <Box flexGrow={1}>
            <Skeleton width="100%" height="50px" />
          </Box>
          <Box flexGrow={1}>
            <Skeleton width="100%" height="50px" />
          </Box>
        </Box>
      );
    }
  }
  return (
    <Box
      width="100%"
      bgcolor={"#ddf4fd"}
      display="flex"
      alignItems="center"
      gap={1}
      py={1}
      px={1}
    >
      <CallButton bgcolor={blue[300]} onClick={handleCallReception}>
        <CallOutlined sx={{ fontSize: "14px" }} />
        Call Reception
      </CallButton>
      {loginObj.isLoggedIn ? (
        <Tooltip
          title="4/5 call remaining"
          sx={{ fontSize: "1rem !important" }}
        >
          <CallButton bgcolor={blue[900]} onClick={handleCallConsultation}>
            <CallOutlined sx={{ fontSize: "14px" }} />
            Package Consultation
          </CallButton>
        </Tooltip>
      ) : (
        <CallButton bgcolor={blue[900]} onClick={handleCallConsultation}>
          <CallOutlined sx={{ fontSize: "14px" }} />
          Package Consultation
        </CallButton>
      )}
    </Box>
  );
}

let config = [
  {
    id: 0,
    title: "start",
    message:
      "Hi $name$ ! My name is Doctor Saab. I am a chatbot. If you have any queries, please select from options below.",
    trigger: 1,
    // pushStack: true,
  },
  {
    id: 1,
    title: "Our products",
    options: true,
    options_list: [
      { label: "Primary Product", trigger: 2 },
      {
        label: "Other Queries",
        trigger: 3,
      },
      //   { label: "Clinical Queries", trigger: 4 },
      { label: "Technical Queries", trigger: 16 },
    ],
    pushStack: false,
    // message:'These are our products.'
  },
  {
    id: 2,
    title: "Primary Product",
    message: "Below are our primary products",
    trigger: 5,
    pushStack: true,
    back: true,
  },
  {
    id: 3,
    title: "Secondary Product",
    message: "Below are our secondary products or services.",
    request: true,
    responseType: "options",
    apiCall: async () => {
      let data = await axios.get("api/service");
      return data.data;
    },
    trigger: 10,
    pushStack: true,
    back: true,
  },
  //   {
  //     id: 4,
  //     title: "Clinical Product",
  //     message: "Choose any 5 symptoms from below",
  //     request: true,
  //     responseType: "options",
  //     apiCall: async () => {
  //       let data = await axios.get("api/admin/symptom");
  //       return data.data;
  //     },
  //     trigger: 11,
  //     pushStack: true,
  //     back: true,
  //   },
  {
    id: 5,
    title: "Primary Product List",
    options: true,
    options_list: [
      //   { label: "Family Package", trigger: 6 },
      //   { label: "Corporate Package", trigger: 7 },
      { label: "School package", trigger: 8 },
    ],
    back: false,
    pushStack: false,
  },
  {
    id: 6,
    title: "Family Package",
    message: "Redirecting to family package",
    redirect: true,
    redirect_to: "/packages",
    trigger: 12,
    pushStack: false,
  },
  {
    id: 7,
    title: "Corporate Package",
    message: "Below are our corporate packages.",
    request: true,
    responseType: "options",
    apiCall: async () => {
      let data = await axios.get("api/package");
      return data.data;
    },
    trigger: 9,
    pushStack: true,
    back: true,
  },
  {
    id: 8,
    title: "School Package",
    message: "Redirecting to school package",
    redirect: true,
    redirect_to: "/packages",
    pushStack: false,
  },
  {
    id: 9,
    title: "Corporate packages",
    options: true,
    setOptions: ({ prevOptions }) => {
      let optionList = [];
      prevOptions.forEach((item, idx) => {
        optionList.push({
          label: item.package_type,
          trigger: 12,
          redirect: true,
          message: "Redirecting",
          redirect_to: `/single-package/${item.slug}`,
        });
      });
      return optionList;
    },
    pushStack: false,
    // options:
  },
  {
    id: 10,
    title: "Secondary products",
    options: true,
    setOptions: ({ prevOptions }) => {
      let optionList = [];
      prevOptions.forEach((item, idx) => {
        optionList.push({
          label: item.service_title,
          trigger: 12,
          redirect: true,
          message: "Redirecting",
          redirect_to: `/service/${item.slug}`,
        });
      });
      return optionList;
    },
  },
  {
    id: 11,
    title: "symptoms",
    checkbox: true,
    setOptions: ({ prevOptions }) => {
      let optionList = [];
      prevOptions.forEach((item, idx) => {
        optionList.push({ label: item.name, id: item.id });
      });
      return optionList;
    },
    trigger: 13,
    pushStack: false,
  },
  {
    id: 13,
    title: "await symptoms",
    awaitUser: true,
    trigger: 14,
    pushStack: false,
  },
  {
    id: 14,
    title: "select departments",
    message:
      "Based on your symptoms you might need assistance from the following departments.",
    disclaimer:
      "This is machine generated response. Please refer actual doctor for precise advice.",
    request: true,
    responseType: "options",
    requestParams: true,
    apiCall: async ({ checkValues }) => {
      const formData = new FormData();
      console.log("form symptoms", checkValues);
      formData.append("symptoms[]", JSON.stringify(checkValues));
      let data = await axios.post(
        "/api/admin/department/departmentBySymptoms",
        formData
      );
      console.log(data);
      return data.data;
    },
    noDataTrigger: 18,
    trigger: 15,
    pushStack: true,
    back: true,
  },
  {
    id: 15,
    title: "Departments data",
    // message:'Here are the departments.',
    options: true,
    setOptions: ({ prevOptions }) => {
      let optionList = [];
      prevOptions.forEach((item, idx) => {
        optionList.push({
          label: item.department,
          trigger: 12,
          redirect: true,
          message: "Redirecting",
          redirect_to: `/booking/doctor?department=${item.id}`,
        });
      });
      return optionList;
    },
    pushStack: false,
  },
  {
    id: 16,
    title: "Technical Problemns",
    message: "Please select the questions from below.",
    request: true,
    responseType: "options",
    apiCall: async () => {
      let data = await axios.get("api/frequently-asked-question");
      console.log(data);
      return data.data;
    },
    trigger: 17,
    pushStack: true,
  },
  {
    id: 17,
    title: "Questions",
    // message:'Here are the departments.',
    options: true,
    setOptions: ({ prevOptions }) => {
      let optionList = [];
      prevOptions.forEach((item, idx) => {
        optionList.push({
          label: item.question,
          message: item.answer,
          trigger: 17,
        });
      });
      optionList.push({ label: "No questions", trigger: 12 });
      return optionList;
    },
    pushStack: false,
  },
  {
    id: 12,
    title: "End",
    message: "Do you have any more queries?",
    trigger: 19,
    pushStack: true,
  },
  {
    id: 18,
    title: "No Department",
    message:
      "There are no department for selected symptoms. Select another symptoms.",
    trigger: 11,
    pushStack: false,
    back: true,
  },
  {
    id: 19,
    title: "Our products end",
    options: true,
    options_list: [
      { label: "Primary Product", trigger: 2 },
      {
        label: "Secondary Product",
        trigger: 3,
      },
      //   { label: "Clinical Product", trigger: 4 },
      { label: "Technical", trigger: 16 },
      {
        label: "NoQueries",
        trigger: 20,
      },
    ],
    pushStack: false,
    // message:'These are our products.'
  },
  {
    id: 20,
    title: "Fin",
    message: "Thank you. Please remember me if you need any more assistance.",
  },
];

function ChatBodyMessages() {
  const login = useChkLogin();
  const navigate = useNavigate();
  const ref = useRef();
  const [botMessage, setBotMessage] = useState();
  const [userEnabled, setUserEnabled] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [botTyping, setTyping] = useState(false);

  // console.log("Message List",messageList)
  function handleOptions(value) {
    setMessageList((prev) => [
      ...prev,
      <ChatBubbleUser message={value.label} />,
    ]);
    userResponse(value);
  }

  function handleCheckbox(arrayObj, msg) {
    setMessageList((prev) => [...prev, <ChatBubbleUser message={msg} />]);
    userResponse(null, arrayObj);
  }

  function typing(bool) {
    setTyping(bool);
  }

  function listenBotMessage(res) {
    // console.log('Bot is listening', res)
    setBotMessage(res.message);
    // setTyping(false)
    if (res.options) {
      setMessageList((prev) => [
        ...prev,
        <ChatBubbleOptions
          options={res.options_list}
          handleOptions={handleOptions}
        />,
      ]);
    } else if (res.checkbox) {
      setMessageList((prev) => [
        ...prev,
        <ChatCheckbox
          options={res.options_list}
          handleCheckbox={handleCheckbox}
        />,
      ]);
    } else {
      setMessageList((prev) => [
        ...prev,
        <ChatBubbleBot
          message={res.message}
          disclaimer={res.disclaimer}
          back={res.back ? true : false}
          goBack={goBack}
        />,
      ]);
    }
  }

  let { botConfig, back } = chatBotConfig(
    config,
    listenBotMessage,
    typing,
    navigate
  );

  function userResponse(triggerMessage, handleCheck = null) {
    botConfig(triggerMessage, handleCheck, login?.data?.member?.name);
  }

  function goBack() {
    // let newMessageList = [...messageList]
    // console.log("Before pop", newMessageList, messageList)
    setMessageList((prev) => {
      let newList = [...prev];
      newList.splice(-1);
      return newList;
    });
    // console.log('After pop', newMessageList)
    back();
  }

  useEffect(() => {
    userResponse("start", null, login?.data?.member?.name || "Guest User");
  }, []);
  useEffect(() => {
    const lastChildElement = ref.current?.lastElementChild;
    lastChildElement?.scrollIntoView();
  }, [messageList, botTyping]);
  return (
    <>
      <Box flexGrow={1} position="relative" overflow="auto" bgcolor={"#ddf4fd"}>
        <Box position="absolute" top={0} left={0} p={2}>
          <Box display="flex" flexDirection="column" gap={2} ref={ref}>
            {messageList}
            {botTyping && <ChatBubbleBot typing />}
          </Box>
        </Box>
      </Box>
      <ChatBodyFooter userEnabled={userEnabled} />
    </>
  );
}

function ChatBubbleBot({ message, options, disclaimer, typing, back, goBack }) {
  const [showBack, setShowBack] = useState(true);
  const [show, setShow] = useState(true);

  function hideBack() {
    setShowBack(false);
    setShow(false);
  }

  if (!show) return;

  return (
    <Grow in={true}>
      <Box display="flex" gap={1}>
        <Avatar
          alt="Remy Sharp"
          src={ChatAvatar}
          sx={{ "& img": { objectFit: "contain" } }}
        />
        <Stack gap={1}>
          <Box
            px={2}
            py={1}
            bgcolor={"white"}
            border={1}
            borderColor="divider"
            borderRadius={2}
          >
            <span className="fs-6 fw-light">{message}</span>
            {typing && (
              <div className="typing-animation">
                <div className="dot" style={{ "--delay": "200ms" }}></div>
                <div className="dot" style={{ "--delay": "300ms" }}></div>
                <div className="dot" style={{ "--delay": "400ms" }}></div>
              </div>
            )}
          </Box>
          {disclaimer && (
            <div>
              <small className="text-danger">{disclaimer}</small>
            </div>
          )}
          {options && <ChatBubbleOptions />}
        </Stack>
        {back && showBack && (
          <Box ml="auto">
            <IconButton
              onClick={() => {
                goBack();
                hideBack();
              }}
            >
              <ArrowCircleUp sx={{ color: "primary.main", fontSize: "30px" }} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Grow>
  );
}

function ChatBubbleUser({ message }) {
  const login = useChkLogin();
  return (
    <Box display="flex" flexDirection="row-reverse" gap={1}>
      {!login.isLoggedIn ? (
        <Avatar sx={{ bgcolor: deepOrange[500] }}>G</Avatar>
      ) : login.data.image_path ? (
        <Avatar src={login.data.image_path} />
      ) : (
        <Avatar
          sx={{
            bgcolor: deepOrange[500],
            textTransform: "capitalize",
          }}
        >
          {login.data.member.name.substr(0, 1)}
        </Avatar>
      )}
      <Box px={2} py={1} bgcolor={"#0259A7"} color="white" borderRadius={2}>
        <span className="fs-6 fw-light">{message}</span>
      </Box>
    </Box>
  );
}

function ChatCheckbox({ options, handleCheckbox }) {
  const [show, setShow] = useState(true);
  const [symptoms, setSymptoms] = useState([]);
  const [msg, setMsg] = useState("");

  function onProceed() {
    handleCheckbox(symptoms, msg);
    setShow(false);
  }

  function handleCheck(e, id, name) {
    if (e.target.checked) {
      symptoms.push(id);
      setMsg((prev) => prev + `, ${name}`);
    } else {
      // let idx = symptoms.indexOf(id)
      let newSymptom = symptoms.filter((item) => item.id != id);
      setSymptoms(newSymptom);
    }
  }

  if (!show) {
    return;
  }
  return (
    <>
      <Box
        display="flex"
        justifyContent={"end"}
        gap={0.5}
        flexWrap={"wrap"}
        sx={{ "& > *": { flexShrink: 0 } }}
      >
        {options.map((item, idx) => (
          <Grow in={true}>
            <Box
              px={2}
              py={1}
              display="flex"
              alignItems="center"
              gap={0.3}
              bgcolor={"white"}
              border={1}
              borderColor="divider"
              borderRadius={2}
              onClick={() => {
                // setShow(false)
                // handleOptions(item)
              }}
              // sx={{ '&:hover': { bgcolor: '#0259A7', color: 'white' } }}
            >
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                onClick={(e) => handleCheck(e, item.id, item.label)}
              />
              <span style={{ fontSize: "12px" }}>{item.label}</span>
            </Box>
          </Grow>
        ))}
      </Box>
      <Button variant="contained" onClick={onProceed}>
        Proceed
      </Button>
    </>
  );
}

function ChatBubbleOptions({ options, handleOptions }) {
  const [show, setShow] = useState(true);
  if (!show) {
    return;
  }
  return (
    <Box
      display="flex"
      justifyContent={"end"}
      gap={0.5}
      flexWrap={"wrap"}
      sx={{ "& > *": { flexShrink: 0 } }}
    >
      {options.map((item, idx) => (
        <Grow in={true}>
          <Box
            px={2}
            py={1}
            bgcolor={"white"}
            border={1}
            borderColor="divider"
            borderRadius={2}
            onClick={() => {
              setShow(false);
              handleOptions(item);
            }}
            sx={{ "&:hover": { bgcolor: "#0259A7", color: "white" } }}
          >
            <span style={{ fontSize: "12px" }}>{item.label}</span>
          </Box>
        </Grow>
      ))}
    </Box>
  );
}

function ChatBodyFooter({ userEnabled }) {
  return (
    <Box>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Type your message."
          inputProps={{ "aria-label": "type your message" }}
          disabled={!userEnabled}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
          disabled={!userEnabled}
        >
          <SendOutlined />
        </IconButton>
      </Paper>
    </Box>
  );
}

function CallButton({ bgcolor = "", children, onClick }) {
  return (
    <Box
      fontSize="14px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={1}
      flexGrow={1}
      color="white"
      borderRadius={1}
      bgcolor={bgcolor}
      className="p-1"
      onClick={onClick}
      boxShadow={1}
    >
      {children}
    </Box>
  );
}

export default ChatBody;
