import React from 'react'
import {ResizeContext} from '../../contexts/ResizeContext'

function ResizeListener({children}) {
    // const [resize, setResize] = React.useState(false)

    // React.useEffect(() => {
    //     window.addEventListener('resize', () => {
    //         console.log()
    //         setResize(!resize)
    //     })
    // }, [])

    // return (
    //     <ResizeContext.Provider value={{ resize }}>
    //         {children}
    //     </ResizeContext.Provider>
    // )
}

function RespTxt({resTxtRatio, dataParent, children}) {
    const [style, setStyle] = React.useState({})
    const [resize, setResize] = React.useState(false)
    // const resizeContext = React.useContext(ResizeContext)

    React.useEffect(() => {
        function handleResize() {
            console.log()
            // setResize(!resize)
            let divWidth = document.getElementById(dataParent).offsetWidth
            let fontSize = divWidth * resTxtRatio
            setStyle({
                fontSize: fontSize + "px"
            })
        }

        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })

    React.useEffect(() => {
        resTxtDiv()
    }, [])

    const resTxtDiv = () => {
        let divWidth = document.getElementById(dataParent).offsetWidth
        let fontSize = divWidth * resTxtRatio
        setStyle({
            fontSize: fontSize + "px"
        })
    }

    return (
        <span className="res-txt" style={style}>
            {children}
        </span>
    )
}

function RespTxtDiv({resRatio, className, id, children}) {
    const [style, setStyle] = React.useState({})
    const [resize, setResize] = React.useState(false)
    const [divId, setDivId] = React.useState()

    return (
        <div className={`res-txt-div ${className}`} id={id} style={style}>
            {children}
        </div>
    )
}

export {RespTxtDiv, RespTxt, ResizeListener}
