import {Stack} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React from 'react'
import FitnessContainer from '../../../fitnessCenter/components/FitnessContainer'
import FitnessItem from '../../components/FitnessItem'
import {settings3items} from '../../sliderSettings/View5itemsOnlg'
import CatalogSlider from './CatalogSlider'
import axios from '../../../../api'
import BookFitnessModal from '../../gdvendors/components/BookFitnessModal'

function FitnessPricingPlans({vendor, title}) {
    let api = '/api/fitness/fitness-pricing'
    if (vendor) {
        api += '?vendor_id=' + vendor
    }
    const {data, isLoading, isError} = useQuery(
        ['fitnessPricing'],
        async () => (
            axios.get(api).then(res => res.data).catch(err => err)
        )
    )
    if (isLoading || isError) return
    if (data.length === 0) return
    return (
        <FitnessContainer title="Fitness Pricing Plans" subtitle='Pick Fitness Pricing from different fitness centers'>
            <CatalogSlider numItems={3} settingProps={settings3items}>
                {
                    data.map((item, idx) => (
                        <div key={idx}>
                            <Stack alignItems={'center'}>
                                <FitnessItem data={item}/>
                            </Stack>
                        </div>
                    ))
                }
                {/* <div>
                    <Stack alignItems={'center'}>
                        <FitnessItem />
                    </Stack>
                </div>
                <div>
                    <Stack alignItems={'center'}>
                        <FitnessItem />
                    </Stack>
                </div>
                <div>
                    <Stack alignItems={'center'}>
                        <FitnessItem />
                    </Stack>
                </div>
                <div>
                    <Stack alignItems={'center'}>
                        <FitnessItem />
                    </Stack>
                </div> */}
            </CatalogSlider>

        </FitnessContainer>
    )
}

export default FitnessPricingPlans
