import React, { useCallback, useEffect, useState } from "react";
import axios from "../api.js";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Logo from "../../images/white-logo.png";
import Background from "../../images/bg.png";
import "../../components/auth/Login.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";
import { LoginStateContext } from "../../contexts/LoginStateContext";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import captchaVerify from "./captchaVerify.js";
import AuthButton from "../custom_componets/AuthButton.jsx";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";
import { getFCMToken, onMessageListener } from "../../firebase.js";
import { encryptJSONStr } from "../../helperFunctions/stringEncrypt.js";
import { usePusher } from "../../customHooks/usePusher.js";

const Login = (props) => {
  const [qrvalue, setQrValue] = useState();
  const [qrLoading, setQrLoading] = useState(true);
  const [token, setToken] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [submitting, setSubmitting] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const [captchaToken, setCaptchaToken] = useState();
  const [refreshCaptcha, setRefreshCaptcha] = useState(false);
  const [searchParams, setSeachParams] = useSearchParams();
  const rememberRef = useRef(null);
  const [emailErr, setEmailErr] = useState(false);
  const [currentRole, setCurrentRole] = useState();

  const { channel } = usePusher();

  const loginContext = React.useContext(LoginStateContext);

  const objectParam = Object.fromEntries(searchParams);

  const onVerify = useCallback(
    (token) => {
      setCaptchaToken(token);
    },
    [refreshCaptcha]
  );

  const handleEmail = useCallback((e) => {
    const value = e.target.value;

    setEmail(value);

    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (isNaN(value) && !regex.test(value)) {
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }
  });

  async function allowNotification(e) {
    if (Notification.permission === "granted") {
      // this.displayNotification();
    } else if (Notification.permission === "denied") {
    } else {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            // this.displayNotification();
            // console.log()
            getFCMToken().then((fcm) => {
              props.setFCM(fcm);
            });
          }
        })
        .catch((err) => console.log());
    }
  }

  async function loginUser(credentials) {
    const secret = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;
    // const captchaToken = await captchaRef.current.getValue()

    // console.log()
    credentials.platform = "web";
    credentials.unique_id = localStorage.getItem("session_id");

    if (!Number.isNaN(Number(credentials.login))) {
      credentials.login = `+977${credentials.login}`;
    }

    const response = await captchaVerify(secret, captchaToken);

    if (true) {
      return axios
        .post("/api/auth/login", credentials)
        .then((res) => {
          // captchaRef.current.reset()
          return res.data.data;
        })
        .catch((err) => {
          // captchaRef.current.reset()
          setErrorMsg(err.response.data.message.error);
          window.setTimeout(() => {
            setErrorMsg(null);
          }, 2000);
        });
    }
  }

  const handlesubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const login = await loginUser({
      email: email,
      password,
    });

    setSubmitting(false);
    setRefreshCaptcha((prev) => !prev);

    // console.log(login?.user?.roles?.[0]?.roles_type, "login");
    console.log(login, ";login");
    if (login) {
      setCurrentRole(login?.user?.roles?.[0]?.role_type);
      let userRole = login.user.roles.filter(
        (item) => item.role_type === "User" || item.role_type === "Parent"
      );
      if (userRole.length === 0) {
        setErrorMsg("Invalid Credentials");
        return;
      }
      if (login.user.is_verified === 0) {
        setErrorMsg(
          "Please go to your email and verify your account before proceeding."
        );
        return;
      }
      if (
        login.logged_id === "Already Logged In Device" ||
        login.logged_id === "Two Factor Authorization Is Disabled"
      ) {
        localStorage.setItem("token_id", login.token_id);
        setToken(login.token);
      } else {
        return navigate("/two-factor-auth", { state: login.token });
      }
    } else {
      setErrorMsg("Invalid Credentials");
    }
  };

  if (token !== undefined) {
    // localStorage.setItem('token', token);
    let cookieCreate = `token=${token};path=/;`;
    if (rememberRef.current.checked) {
      cookieCreate += `expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/`;
    }
    document.cookie = cookieCreate;

    localStorage.setItem("token_id", token);

    loginContext.refetch(token);
    if (localStorage.getItem("queryString")) {
      navigate(localStorage.getItem("queryString"), { replace: true });
      localStorage.removeItem("queryString");
    }

    if (currentRole === "Parent") {
      navigate("/parent");
    } else navigate("/user/profile");
  }

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const getQrCode = async () => {
    setQrLoading(true);
    axios
      .post("api/login/create/qrcode", { fcm: "" })
      .then((res) => {
        console.log();
        setQrValue(res.data.key);
      })
      .catch((err) => console.log())
      .finally(() => setQrLoading(false));
  };

  useEffect(() => {
    const eventCallBack = (data) => {
      // setToken(data.message))
      console.log();
      if (data.message === qrvalue) {
        setToken(data.token);
      }
      console.log();
    };

    if (channel !== null) {
      channel.bind("my-event", eventCallBack);
    }
    return () => {
      if (channel !== null) {
        channel.unbind("my-event", eventCallBack);
      }
    };
  }, [channel, qrvalue]);

  useEffect(() => {
    // console.log()
    getQrCode();
    let interval = setInterval(getQrCode, 30000);
    return () => clearInterval(interval);
  }, []);

  onMessageListener()
    .then((payload) => {
      // console.log()
      if (payload?.data?.jwt) {
        setToken(payload.data.jwt);
      }
    })
    .catch((err) => console.log());

  return (
    <div className="container-fluid login auth">
      <div className="row">
        <div className="col-lg-6 image-side px-0">
          <img src={Background} alt="" className="bg-image" />
          <div className="image-overlay"></div>
          <div className="text-center bg-logo mx-auto">
            <img src={Logo} alt="" />
          </div>
        </div>
        <div className="col-lg-6 offset-lg-6 login-form">
          {objectParam.login && (
            <Alert>
              <AlertTitle>Email Verified</AlertTitle>
              <Typography variant="subtitle1">
                Your email is verified successfully. You can now login to your
                account
              </Typography>
            </Alert>
          )}
          {objectParam.verify && (
            <Alert>
              <AlertTitle>Verify Email</AlertTitle>
              <Typography variant="subtitle1">
                Verify your email to login.
              </Typography>
            </Alert>
          )}
          <div className="card border-0">
            <div className="row align-items-center">
              <div className="col-7 col-md-9">
                <h3 className="card-title">Welcome!</h3>
                <p>Please enter your login credentials</p>
              </div>
              <div className="col-5 col-md-3">
                <div className="w-100 d-flex justify-content-end position-relative ratio ratio-1x1">
                  <>
                    <Box
                      sx={{
                        "& canvas": {
                          width: "100% !important",
                          height: "100% !important",
                        },
                      }}
                    >
                      <QRCodeCanvas
                        id="qrCode"
                        value={encryptJSONStr(
                          JSON.stringify({
                            key: qrvalue,
                            type: "login",
                            platform: "web",
                          })
                        )}
                        // value={qrvalue}
                        // size={'auto'}
                        width="auto"
                        height="auto"
                        bgColor={"#fff"}
                        level={"H"}
                      />
                    </Box>
                    {qrLoading && (
                      <div className="position-absolute top-0 left-0 w-100 h-100">
                        <div className="position-absolute top-0 left-0 w-100 h-100 bg-black opacity-75"></div>
                        <div className="position-relative w-100 h-100 d-flex justify-content-center align-items-center z-index-1">
                          <CircularProgress />
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
            <div className="card-body px-0">
              {errorMsg && (
                <div className="alert alert-danger alert-dismissible fade show">
                  {errorMsg}
                </div>
              )}
              <form action="" method="post" onSubmit={handlesubmit}>
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Email or Number
                  </label>{" "}
                  <br />
                  <input
                    placeholder="Enter Your Email or Number"
                    name="email"
                    className={`form-control input-field ${
                      emailErr ? "is-invalid border-danger" : ""
                    }`}
                    required
                    onChange={handleEmail}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Password
                  </label>
                  <button
                    type="button"
                    onClick={togglePassword}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      float: "right",
                      color: "#333",
                    }}
                  >
                    {passwordType === "password" ? (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon icon={faEye} />
                    )}
                  </button>
                  <br />
                  <input
                    type={passwordType}
                    placeholder="Enter Your Password"
                    name="password"
                    className="form-control input-field"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        ref={rememberRef}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                      >
                        Keep me logged in
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 forget-psw-container">
                    <a href="/forgot-password" className="forget-psw">
                      Forget Password?
                    </a>
                  </div>
                </div>
                <h6 className="my-4">
                  By continuing, you agree to the{" "}
                  <Link to="/termscondition"> Terms of use</Link> and{" "}
                  <Link to="/policy">Privacy Policy</Link>.{" "}
                </h6>
                <div className="py-1 pb-3">
                  {/* <ReCAPTCHA
                                        ref={captchaRef}
                                        sitekey={captchaKey}
                                    /> */}
                  <GoogleReCaptcha
                    // ref={captchaRef}
                    onVerify={onVerify}
                    refreshReCaptcha={refreshCaptcha}
                  />
                </div>
                <div className="d-grid">
                  <AuthButton
                    type="submit"
                    component="button"
                    className="btn btn-primary login-btn"
                    label="Log in"
                    loading={submitting}
                  />
                  {/* <button type='submit' className='btn btn-primary login-btn'>Log in</button> */}
                </div>
                <div className="text-center mt-3">
                  <p>
                    Don’t have an acount?{" "}
                    <Link to="/register" className="signup-btn">
                      Sign up
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
