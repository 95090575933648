import * as yup from "yup";
import {FIELD_REQUIRED} from "../../../../utils/constant/common";

const VaccinationDocumentValidationSchema = yup.object().shape({
    vaccination_date: yup.string().required(FIELD_REQUIRED),
    vaccination_file: yup.mixed().test("required", FIELD_REQUIRED, (file) => {
        return !!file;
    }),
});

export {VaccinationDocumentValidationSchema};
