import React from 'react'
import GDBlogHeader from './GDBlogHeader'
import {Box, CircularProgress, Grid, Pagination, Stack} from '@mui/material'
import ContentContainer from './components/ContentContainer'
import NewsItemContainer from './components/NewsItemContainer'
import ItemDetails from './components/ItemDetails'
import PopularPosts from './PopularPosts'
import {useBlogsHome} from './GDBlogProvider'
import {useParams, useSearchParams} from 'react-router-dom'
import {useState} from 'react'
import {useEffect} from 'react'
import {useQuery} from '@tanstack/react-query'
import axios from '../../api'
import NoData from '../../custom_componets/NoData'
import SkeletonNewsItem from './components/SkeletonNewsItem'

function MenuBlogs() {
    const {relatedPosts} = useBlogsHome()
    const [searchParams, setSearchParams] = useSearchParams()
    const params = useParams()
    const [page, setPage] = useState(Number(Object.fromEntries(searchParams)?.page) || 1)
    const {data, isLoading, isError, refetch} = useQuery(
        ['blogsFiltered', params.id],
        async () => (
            axios.get(`/api/news`, {
                params: {
                    ...Object.fromEntries(searchParams),
                    menu_id: params.id
                }
            }).then(res => res.data).catch(err => err)
        )
    )

    useEffect(() => {
        if (!isLoading) {
            refetch()
        }
    }, [searchParams, params])

    function handlePageChange(e, page) {
        setPage(prev => page)
        setSearchParams({...Object.fromEntries(searchParams), page: page})
    }

    // if (isLoading) {
    //     return (
    //         <Box display='flex' justifyContent={'center'} alignItems={'center'} minHeight='200px' >
    //             <CircularProgress />
    //         </Box>
    //     )
    // }

    return (
        <Box className="container">
            {/* <GDBlogHeader /> */}
            <Grid container spacing={{lg: 6, xs: 0}}>
                <Grid item xs={12} lg={7.5}>
                    <ContentContainer header={'Search Results'}>
                        <Stack gap={2}>
                            {
                                isLoading ?
                                    [...Array(5).keys()].map((item) => (
                                        <SkeletonNewsItem/>
                                    ))

                                    :
                                    data.data.length === 0 ?
                                        <NoData title='No Blogs'
                                                description='There are currently no blogs for this menu.'/>
                                        :
                                        data.data.map((item, idx) => (
                                            <NewsItemContainer
                                                mediaComponent={
                                                    <img src={item.image_path}/>
                                                }
                                            >
                                                <ItemDetails
                                                    title={item.title_en}
                                                    author={item?.user?.name || 'Ghargharmadoctor'}
                                                    category={item.menu.title_en}
                                                    description={item.description_en}
                                                    time='Jun 14, 2023'
                                                    blog={item}
                                                />
                                            </NewsItemContainer>
                                        ))
                            }
                        </Stack>

                    </ContentContainer>
                    <Box my={1}>
                        {
                            !isLoading && data.data.length > 0 && data.last_page > 1 && (
                                <Pagination
                                    count={data.last_page}
                                    color='primary'
                                    page={page}
                                    onChange={handlePageChange}
                                />
                            )
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} lg={4.5}>
                    <ContentContainer header='Popular Posts'>
                        <PopularPosts/>
                    </ContentContainer>
                    {/* <ContentContainer header='Related Posts and Blogs'>
                        {
                            relatedPosts?.isLoading ? <CircularProgress /> : (
                                <Stack gap={2}>
                                    {
                                        relatedPosts.data.map((item, idx) => (
                                            <NewsItemContainer
                                                mediaComponent={
                                                    <img src={item.image_path} />
                                                }
                                            >
                                                <ItemDetails
                                                    title={item.title_en}
                                                    author={item.user.name}
                                                    category={item.menu.title_en}
                                                    time='Jun 14, 2023'
                                                    blog={item}
                                                />
                                            </NewsItemContainer>
                                        ))
                                    }
                                </Stack>
                            )
                        }

                    </ContentContainer> */}
                </Grid>
            </Grid>
        </Box>
    )
}

export default MenuBlogs
