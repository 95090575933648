import {faHandPointRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useQuery} from '@tanstack/react-query'
import React from 'react'
import {useContext} from 'react'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import axios from "../../../../api"

function GDBenefits() {
    const loginContext = useContext(LoginStateContext)
    const mypackage = useQuery(
        ['package-details-data'],
        async () => {
            return axios.get('/api/admin/userpackage', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )

    return (
        <div className="h-100 position-relative">
            <div className='top-0 position-absolute start-0 w-100 h-100 d-flex flex-column'>
                <div className="card-title-outer">
                    <h2 className="m-0 p-0 title-text-weight">
                        <b>Benefits of GD</b>
                    </h2>
                </div>
                <div className="shadow-sm card flex-grow-1">
                    <div className="card-body d-flex flex-column h-100">
                        {
                            !mypackage.isLoading &&
                            <>
                                {
                                    (mypackage.data.package !== null && mypackage.data.package.package_status !== "Not Booked") ?
                                        <div className="pb-2 border-bottom">
                                            <span className="text-muted">Number of Home Visits</span>
                                            <h4 className="m-0 p-0"
                                                style={{fontWeight: 600}}>{mypackage.data.package.package.visits} times
                                                / year</h4>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                        }

                        <div className="overflow-scroll position-relative flex-grow-1">
                            <div className="top-0 py-2 position-absolute start-0 w-100">
                                <div className='m-0 p-0 d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faHandPointRight} size="sm" className='text-info'/>
                                    <span className='ms-2'>Pathological Screening</span>
                                </div>
                                <div className='m-0 p-0 d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faHandPointRight} size="sm" className='text-info'/>
                                    <span className='ms-2'>Medical Check-up</span>
                                </div>
                                <div className='m-0 p-0 d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faHandPointRight} size="sm" className='text-info'/>
                                    <span className='ms-2'>Physical Fitness Plan</span>
                                </div>
                                <div className='m-0 p-0 d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faHandPointRight} size="sm" className='text-info'/>
                                    <span className='ms-2'>Nutrition Advice & Diet Plan</span>
                                </div>
                                <div className='m-0 p-0 d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faHandPointRight} size="sm" className='text-info'/>
                                    <span className='ms-2'>Free use of GD Application</span>
                                </div>
                                <div className='m-0 p-0 d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faHandPointRight} size="sm" className='text-info'/>
                                    <span className='ms-2'>Free Doctor Consultation booking</span>
                                </div>
                                <div className='m-0 p-0 d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faHandPointRight} size="sm" className='text-info'/>
                                    <span className='ms-2'>Free Ambulance Services for hospitalization</span>
                                </div>
                                <div className='m-0 p-0 d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faHandPointRight} size="sm" className='text-info'/>
                                    <span className='ms-2'>Hospital booking on payment</span>
                                </div>
                                <div className='m-0 p-0 d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faHandPointRight} size="sm" className='text-info'/>
                                    <span className='ms-2'>Free insurance up to 43.80 lakh per person</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GDBenefits
