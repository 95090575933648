import {useNavigate} from "react-router-dom"
import EmptyCartImg from "../images/emptyState.png"

const EmptyState = (props) => {
    const navigate = useNavigate()

    const emptyStyle = {
        width: "100%",
        height: "55vH",
        display: "flex",
        justifyContent: "center",
    }

    const emptyStyleImg = {
        width: "20%",
        aspectRatio: "1/1.2",
        objectFit: "contain"
    }

    return (
        <>
            <div className="empty-cart" style={emptyStyle}>
                <img src={EmptyCartImg} alt="" style={emptyStyleImg}/>
            </div>
            <div className="d-flex justify-content-center mb-4">
                <div className="continue-shopping">
                    <button className="btn btn-success" onClick={() => {
                        navigate(props.redirectLink)
                    }}>
                        Continue Shopping
                    </button>
                </div>
            </div>
        </>
    )
}

export default EmptyState
