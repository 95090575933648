import {Alert, AlertTitle, Box, Card, Tab, Tabs, Typography} from '@mui/material'
import DOMPurify from 'dompurify'
import React, {useContext, useState} from 'react'
import {ProductContext} from '../ProductDetPage'

function ProductDetailTabs() {
    const {product, isLoading} = useContext(ProductContext)
    const [value, setValue] = useState(0)
    const keys = ['description', 'how_to_use', 'precaution', 'side_effect']

    function changeTab(e, newValue) {
        setValue(newValue)
    }

    if (isLoading) {
        return
    }

    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <Card variant='outlined' sx={{px: 2}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} aria-label="basic tabs example" variant="scrollable" onChange={changeTab}>
                        <Tab label="Product Description"/>
                        {
                            product.how_to_use !== null && <Tab label="How To Use"/>
                        }
                        {
                            product.precaution !== null && <Tab label="Precautions"/>
                        }
                        {
                            product.side_effect !== null && <Tab label="Side Effects"/>
                        }
                    </Tabs>
                </Box>
                <TabPanel description={product[keys[value]]}/>
            </Card>
        </Box>
    )
}

function TabPanel({description}) {
    return (
        <Box py={3}>
            <Typography variant='subtitle1' sx={{
                '& p': {
                    mb: 2
                }
            }}>
                {
                    description === null ?
                        <Alert severity="info">
                            <AlertTitle>No Product Description</AlertTitle>
                            No product description found for this product.
                        </Alert>
                        :
                        <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(description)}}/>
                }
            </Typography>
        </Box>
    )
}

export default ProductDetailTabs
