export function ageCalculate(dateIn) {
    var today = new Date();
    var dateInObj = new Date(dateIn)

    let dateDiff = today - dateInObj

    let dayDiff = Math.floor(dateDiff / (1000 * 60 * 60 * 24))

    let age = dayDiff / 365.25
    let fullYears = Math.floor(age)
    let month = Math.floor((age - fullYears) * 12)
    
    return `${fullYears} years ${month==0 ? '' : `${month} months`}`
}
