import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import ItemDetails from "./components/ItemDetails";
import { useBlogsPopular } from "./GDBlogProvider";
import * as dayjs from "dayjs";
import SkeletonNewsItem from "./components/SkeletonNewsItem";

function PopularPosts() {
  const { data, isLoading } = useBlogsPopular();

  // Slice data if not loading
  const displayedData = !isLoading ? data.slice(0, 4) : null;

  return (
    <Stack gap={2}>
      {isLoading
        ? [...Array(5).keys()].map((item) => <SkeletonNewsItem key={item} />)
        : displayedData.map((item, idx) => (
            <Box display="flex" gap={1} key={item.id}>
              <Box>
                <Typography variant="h2" color="text.disabled" lineHeight={0.7}>
                  {String(idx + 1).padStart(2, "0")}
                </Typography>
              </Box>
              <ItemDetails
                title={item.title_en}
                author={item?.user?.name || "Ghargharmadoctor"}
                category={item.menu.title_en}
                time={dayjs(item.created_at).format("YYYY-MM-DD")}
                blog={item}
              />
            </Box>
          ))}
    </Stack>
  );
}

export default PopularPosts;
