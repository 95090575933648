import {CalendarMonth, CheckCircleOutlineOutlined, OndemandVideo} from '@mui/icons-material'
import {
    Alert,
    AlertTitle,
    Avatar,
    Button,
    Link,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material'
import {useQuery} from '@tanstack/react-query';
import axios from '../../../../api';
import React from 'react'
import {useContext} from 'react';
import {useEffect} from 'react';
import {LoginStateContext} from '../../../../../contexts/LoginStateContext';
import {DateFormat} from '../../../../DateFormat';
import {useNavigate} from 'react-router-dom';

function MyAppointment() {
    const loginContext = useContext(LoginStateContext)
    const {data, isLoading} = useQuery(
        ['appointments'],
        async () => {
            return axios.get('/api/admin/booking-review/followup-doctor', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )
    console.log('appointment followup data', data)
    const navigate = useNavigate()
    if (isLoading) {
        return (
            <Skeleton variant="rectangular" style={{width: "100%", height: "250px"}}/>
        )
    }

    return (
        <div className="dashboard-appointments position-relative h-100">
            <div className="position-absolute h-100 w-100 top-0 start-0 d-flex flex-column">
                <div className="card-title-outer">
                    <h2 className="m-0 p-0 title-text-weight">
                        <b>Appointment Followup</b>
                    </h2>
                </div>
                <div className="card m-0 flex-grow-1 w-100 shadow-sm">
                    <div className="card-body p-0 pb-1 h-100 overflow-hidden">
                        <TableContainer sx={{maxHeight: "100%"}}>
                            <Table sx={{minWidth: 650}} size="small" stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Doctor Name</TableCell>
                                        <TableCell align="right">Follow Up Date</TableCell>
                                        <TableCell align="right">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                <div className="d-flex align-items-center">
                                                    <Avatar src={row.followupdate?.image_path}/><b
                                                    className='text-success ms-1'>{row.followupdate?.user?.name}</b>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="d-flex align-items-center">
                                                    <CalendarMonth/><b
                                                    className="text-primary ms-1">{DateFormat(row.followUpDate).getFullDateText()}</b>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Join Meeting" placement="left-start">
                                                    <Link>
                                                        <OndemandVideo/>
                                                    </Link>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {
                            data.length === 0 &&
                            <div className="w-100 mt-2 px-2">
                                <Alert severity="error">
                                    <AlertTitle>No Data</AlertTitle>
                                    No Follow Up Found
                                </Alert>
                            </div>
                        }
                    </div>
                </div>

            </div>

        </div>
    )
}

export default MyAppointment
