import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { grey } from "@mui/material/colors";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { PaymentValidationSchema } from "./partials/paymentValidationSchema";
import {
  CHEQUE,
  CHEQUES,
  DEFAULT_VALUE_CHEQUE,
  DEFAULT_VALUE_ONLINE_TRANSFER,
  ONLINE_TRANSFER,
  ONLINE_TRANSFERS,
} from "../../../../utils/constant/payment";
import OnlineTransfer from "./partials/OnlineTransfer";
import PayCheque from "./partials/PayCheque";
import { useMutation, useQuery } from "@tanstack/react-query";
import SchoolPaymentVerificationService from "../../../../services/school-payment-verification.service";
import InputFieldLabel from "../../../form/InputFieldLabel";
import PaymentInformation from "./partials/PaymentInformation";
import AccountDetails from "./partials/AccountDetails";
import FormAction from "../../../form/FormAction";
import { filterParams } from "../../../../utils/common/common.utils";
import { toast } from "react-hot-toast";
import { LoginStateContext } from "../../../../contexts/LoginStateContext";
import { useNavigate } from "react-router-dom";

const defaultValues = {
  payment_type: "online_transfer",
  online_transfers: [DEFAULT_VALUE_ONLINE_TRANSFER],
  cheques: [DEFAULT_VALUE_CHEQUE],
};

const PaymentForm = () => {
  const [paymentType, setPaymentType] = useState(ONLINE_TRANSFER);
  const form = useForm({
    resolver: yupResolver(PaymentValidationSchema),
    defaultValues,
  });
  const { handleSubmit, reset, setValue, formState } = form;
  const { state } = useContext(LoginStateContext) || {};

  const navigate = useNavigate();

  /*
   * SUBMIT PAYMENT
   * */
  const { mutate } = useMutation({
    mutationFn: SchoolPaymentVerificationService.submitPayment,
    onSuccess: () => {
      toast.success("Payment successful.", {
        position: "top-right",
        background: "#333",
        color: "#fff",
      });
      navigate("/school/school-package");
    },
  });

  /*
   * FETCHING PAYMENT INFORMATION
   * */
  const {
    isLoading: isPaymentInformationLoading,
    data: paymentInformationData,
  } = useQuery({
    queryKey: ["school-payment-information"],
    queryFn: SchoolPaymentVerificationService.fetchPaymentInformation,
    enabled: true,
  });

  const onSubmit = (data) => {
    if (paymentType === ONLINE_TRANSFER) {
      data[ONLINE_TRANSFERS] = data?.online_transfers?.map(filterParams);
      delete data[CHEQUES];
    } else {
      data[CHEQUES] = data?.cheques?.map(filterParams);
      delete data[ONLINE_TRANSFERS];
    }

    const { number_of_students, total_payable_amount, academic_year } =
      paymentInformationData || {};

    const { id, package_id } = state?.data?.school_profile || {};
    const payload = {
      number_of_students,
      total_payable_amount,
      academic_year,
      school_profile_id: id,
      package_id,
      ...data,
    };
    mutate(payload);
  };

  const handleChange = (value) => {
    setPaymentType(value);
    setValue("payment_type", value);
  };

  if (formState?.errors) {
    console.log(formState.errors);
  }

  return (
    <>
      <div
        className="d-grid gap-3 rounded p-4 mb-4"
        style={{ background: grey["200"] }}
      >
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="fs-4 fw-bolder lh-sm">Payment Form</h4>
            <PaymentInformation
              isLoading={isPaymentInformationLoading}
              data={paymentInformationData}
            />
          </div>
          <AccountDetails />
        </div>
        <FormControl>
          <InputFieldLabel>Payment Method</InputFieldLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="payment_type"
            onChange={(e) => handleChange(e.target.value)}
          >
            <FormControlLabel
              checked={paymentType === ONLINE_TRANSFER}
              value={ONLINE_TRANSFER}
              control={<Radio />}
              label="Online Transfer"
              className="d-flex"
            />
            <FormControlLabel
              checked={paymentType === CHEQUE}
              value={CHEQUE}
              control={<Radio />}
              label="Cheque"
              className="d-flex"
            />
          </RadioGroup>
        </FormControl>
        <form
          className="d-grid"
          style={{ gap: 32 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {paymentType === ONLINE_TRANSFER ? (
            <OnlineTransfer form={form} />
          ) : (
            <PayCheque form={form} />
          )}
          <FormAction spinnerColor="white" isLoading={false} />
        </form>
      </div>
    </>
  );
};

export default PaymentForm;
