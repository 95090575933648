import React from 'react'

const FamilyRequest = ({
                           user,
                           family,
                           acceptRequest,
                           rejectRequest,
                           familyrequest,
                           acceptFamilyRequest,
                           rejectFamilyRequest
                       }) => {
    return (
        <table className="table table-bordered">
            <thead>
            {user.member_type === 'Primary Member' ?
                <tr>
                    <th>Family Member Name</th>
                    <th>Phone No.</th>
                    <th>Action</th>
                </tr>
                :
                <tr>
                    <th>Family Name</th>
                    <th>User</th>
                    <th>Phone</th>
                    <th>Action</th>
                </tr>
            }
            </thead>
            <tbody>
            {user.member_type === 'Primary Member' ?
                family.filter(f => f.primary_request === 0 && f.approved === 0).map((fam) =>
                    <tr key={fam.id}>
                        <td>{fam.member.user.name}</td>
                        <td>{fam.member.user.phone}</td>
                        <td>
                            <button className="btn btn-success btn-sm mr-2"
                                    onClick={() => acceptRequest(fam.id)}>Accept
                            </button>
                            <button className="btn btn-danger btn-sm" onClick={() => rejectRequest(fam.id)}>Reject
                            </button>
                        </td>
                    </tr>
                )
                :
                <>
                    {familyrequest.map((famreq) =>
                        <tr key={famreq.id}>
                            <td>-</td>
                            <td>{famreq.sendmember.user.name}</td>
                            <td>{famreq.sendmember.user.phone}</td>
                            <td>
                                <button className="btn btn-success btn-sm mr-2"
                                        onClick={() => acceptFamilyRequest(famreq.id)}>Accept
                                </button>
                                <button className="btn btn-danger btn-sm"
                                        onClick={() => rejectFamilyRequest(famreq.id)}>Reject
                                </button>
                            </td>
                        </tr>
                    )}
                    {family.filter(f => f.primary_request === 1 && f.approved === 0).map((fam) =>
                        <tr key={fam.id}>
                            <td>{fam.familyname.family_name}</td>
                            <td>{fam.familyname.primary.user.name}</td>
                            <td>{fam.familyname.primary.user.phone}</td>
                            <td>
                                <button className="btn btn-success btn-sm mr-2"
                                        onClick={() => acceptRequest(fam.id)}>Accept
                                </button>
                                <button className="btn btn-danger btn-sm" onClick={() => rejectRequest(fam.id)}>Reject
                                </button>
                            </td>
                        </tr>
                    )}
                </>
            }
            </tbody>
        </table>
    )
}

export default FamilyRequest
