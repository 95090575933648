import {useState} from "react";
import KhaltiCheckout from "khalti-checkout-web";
import axios from "../../api"
import swal from "sweetalert";
import {useNavigate} from "react-router-dom";
import {v4 as uuidv4} from 'uuid'
import {useChkLogin} from "../../../middlewares/LoginMiddleware";

function useKhalti(payAmount, id, success) {
    const [verifying, setVerifying] = useState(false)
    const usertoken = useChkLogin().token;
    const amount = 10 * 100;
    // const id = props.id;
    // const title = props.title;
    let navigate = useNavigate();
    const publicKey = process.env.REACT_APP_KhaltiPublicTestKey
    let config = {
        // replace this key with yours
        "publicKey": publicKey,
        "productName": "Ghargharma Doctor",
        "productIdentity": uuidv4(),
        "productUrl": "https://react.ghargharmadoctor.com/",
        "eventHandler": {
            async onSuccess(payload) {
                // console.log();
                // hit merchant api for initiating verfication
                if (verifying) return
                try {
                    await success(payload, id, usertoken)
                } catch (e) {
                    swal({
                        title: "Error",
                        icon: "warning",
                    }).then((value) => {
                        if (value) {
                            navigate("/user/orders")
                        }
                    });
                }
                setVerifying(false)
            },
            // onError handler is optional
            onError(error) {
                // handle errors
                setVerifying(false)
                console.log();
            },
            onClose() {
                setVerifying(false)
            }
        },
        "paymentPreference": ["KHALTI"],
    };
    let checkout = new KhaltiCheckout(config);

    function checkoutAmount() {
        setVerifying(true)
        checkout.show({amount: amount})
    }

    return {
        checkoutAmount,
        verifying
    }

}

export {useKhalti};
