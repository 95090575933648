import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./ServicePage.scss";
import axios from "../../api.js";
import Loader from "../Loader";

function ServicePage(props) {
  const { slug } = useParams();
  const [service, setService] = useState("");
  const [loading, setLoading] = useState(true);

  const servicesBtn = {};
  // servicesBtn['Doctor Consultation'] = (
  //     <Link to="/booking/doctor" className='btn btn-primary'>
  //         Consult Doctor
  //     </Link>
  // )

  servicesBtn[2] = (
    <Link to="/booking/doctor" className="btn btn-success">
      Consult Doctor
    </Link>
  );

  servicesBtn[3] = (
    <Link to="/gd-store" className="btn btn-success">
      Fitness Center
    </Link>
  );

  servicesBtn[5] = (
    <Link to="/gd-store" className="btn btn-success">
      Go To Pharmacy
    </Link>
  );

  servicesBtn[6] = (
    <Link to="/ambulance" className="btn btn-success">
      View Ambulance
    </Link>
  );

  useEffect(() => {
    axios
      .get(`/api/service/${slug}`)
      .then((res) => {
        //console.log();
        setService(res.data);
        setLoading(false);
      })
      .catch((err) => console.log());
  }, [slug]);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <div className="service-page">
          <div className="inner-package text-center py-5">
            <h1>{service.service_title}</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Services
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {service.service_title}
                </li>
              </ol>
            </nav>
          </div>
          <div className="service-page-container service-background">
            <div className="container">
              <div className="row g-4 service-row">
                <div className="col-12 col-md-6 service-details">
                  {/* <div className="service-icon">
                    <img src={service.icon_path} alt="" />
                  </div> */}
                  <div className="service-title">
                    <h1>{service.service_title}</h1>
                  </div>
                  <div className="service-description">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `${service.long_description}`,
                      }}
                      className="mb-0"
                    ></p>
                  </div>
                  {/* <div className="service-btn d-flex justify-content-start">
                    {servicesBtn[service.id]}
                  </div> */}
                </div>
                <div className="col-12 col-md-6">
                  <div className="service-image h-100">
                    <img src={service.image_path} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ServicePage;
