import {Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography} from '@mui/material'
import React, {useContext, useEffect, useState} from 'react'
import ProductCards from '../../../stores/ProductCards'
import FitnessProdCard from '../../fitnessCenter/components/FitnessProdCard'
import {GDVendorContext} from '../gdvendors/GDVendorContext'
import ProductFilterBar from './components/ProductFilterBar'
import axios from "../../../api"
import {useQuery} from '@tanstack/react-query'
import {useParams} from 'react-router-dom'
import ProductFIlterProvider from './ProductFIlterProvider'
import {ProductFilterContext} from './ProductFilterContext'
import ProductFilterResults from './components/ProductFilterResults'
import ProductFilterHeader from './components/ProductFilterHeader'

function ProductFilter({headerRef, hasSearchBar = false}) {
    const filterBarSticky = {
        position: 'sticky',
        top: `157px`
    }
    const {setFilters, filters} = useContext(ProductFilterContext)
    const [showResults, setShowResuts] = useState(false)
    const params = useParams()
    const {vendor} = useContext(GDVendorContext)
    useEffect(() => {
        if (Object.keys(filters).length === 0) {
            setFilters(prev => ({
                ...prev,
                vendor_id: vendor.id
            }))
        }
        setShowResuts(true)
    }, [])
    return (
        <div className="container">
            <Grid container spacing={5}>
                <Grid item xs={3}>
                    <ProductFilterBar sx={filterBarSticky} hasSearchBar={hasSearchBar}/>
                </Grid>
                <Grid item xs={9}>
                    <ProductFilterHeader/>
                    {
                        showResults && <ProductFilterResults/>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default ProductFilter
