import http from "../utils/http/http.utils";
import setFormData from "../utils/http/formdata.utils";

class VaccinationService {
  /**
   * Uploads a vaccination document.
   *
   * @return {Promise} A promise that resolves to the result of the upload.
   * @param params
   */
  static async uploadVaccinationDocument(params) {
    const formData = setFormData(params.data);
    try {
      return http().post(
        `api/parent/children/${params.id}/vaccination-history`,
        formData
      );
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * A function to get the vaccination information for a specific ID.
   *
   * @param {type} id - The ID of the child to retrieve vaccination history for
   * @return {type} The vaccination information data for the specified ID
   */
  static async getVaccinationInformation(id) {
    try {
      const response = await http().get(
        `api/parent/children/${id}/vaccinations`
      );
      return response?.data?.data;
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * A function to get the vaccination history for a specific ID.
   *
   * @param {type} id - The ID of the child to retrieve vaccination history for
   * @return {type} The vaccination history data for the specified ID
   */

  static async getVaccinationHistory(id) {
    try {
      const response = await http().get(
        `api/parent/children/${id}/vaccination-history`
      );
      return response?.data?.data;
    } catch (e) {
      console.log(e);
    }
  }

  static async fetchSearchedVaccination(search) {
    try {
      const response = await http().get(
        `api/parent/children/218/vaccinations?search=${search}`
      );
      return response.data;
    } catch (e) {
      console.log(e);
      throw new Error("Failed to fetch medical history");
    }
  }
}

export default VaccinationService;
