import React, {useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import UploadFile from "../../../../custom_componets/UploadFile";
import {FileUpload} from "@mui/icons-material";
import {Button, CircularProgress} from "@mui/material";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {AddMedicalValidation} from "./addMedicalValidation";
import {grey} from "@mui/material/colors";
import ParentService from "../../../../../services/parent.service";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-hot-toast";
import inputField from "../../../../form/InputField";

const defaultValues = {
    medical_document: "",
};

const fileToBase64 = (file, callback) => {
    if (!(file instanceof Blob)) {
        console.error("Input is not a Blob object.");
        return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
        const base64String = reader.result.split('base64,')[1];
        callback(base64String);
    };

    reader.onerror = error => {
        console.error("File reading failed:", error);
    };

    reader.readAsDataURL(file);
};

function Index() {
    const {id} = useParams()
    const navigate = useNavigate();

    const {mutate:uploadMutation, isLoading:isUploading, error} = useMutation({
        mutationFn: (data) => ParentService.uploadMedicalDocument(id, data),
        onSuccess: (data) => {
            if(data?.status === 200)   {
                toast.success("Medical document uploaded successfully.");
                navigate("/parent/medical-history")
            }
        }
    })

    const [inputs, setInputs] = useState({
        report: null
    })

    function handleFile(inputFiles) {
        const filesInput = []
        inputFiles.forEach((inputFile) => {
            var reader = new FileReader();
            reader.readAsDataURL(inputFile);
            console.log()
            var ext = inputFile.type.split('/')[1]
            reader.onload = () => {
                const strArr = reader.result.split('base64,')
                const base64Str = `data:image/${ext};base64,${strArr[1]}`
                filesInput.push(base64Str)
            }
            reader.onerror = error => {
                console.log(error);
            }
        })
        setInputs({...inputs, report: filesInput})
    }


    const onSubmit =  (e) =>  {
        e.preventDefault();
        uploadMutation(inputs)
    }


    return (
        <form onSubmit={onSubmit} className="card p-4" style={{background: grey["200"], borderRadius: 8}}>
            <div className="d-flex gap-2 align-items-center"> <h1>Add Medical Document</h1></div>
            <div className="d-grid gap-4">
                <div className="grid gap-1">
                    <label>Document</label>
                    <UploadFile
                        type={"pdf"}
                        accept={"application/pdf,application/vnd.ms-excel"}
                        aspectRatio="3/1"
                        setImageInput={(fileG) => {
                            handleFile(fileG)
                        }}
                    />
                </div>
                <div>
                    <Button variant="contained" startIcon={<FileUpload/>} fullWidth type='submit'>
                        { isUploading ?  <CircularProgress size={16} sx={{color: 'white'}}/> :
                                <span> Upload Medical History </span>
                        }
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default Index
