import {useQuery} from '@tanstack/react-query'
import React from 'react'
import {useState} from 'react'
import {createContext} from 'react'
import axios from '../../../api'

const LabTestContext = createContext(null)

function LabTestProvider({children}) {
    const [department, setDepartment] = useState([])
    const {data, isLoading, isError} = useQuery(
        ['labTestDepartments'],
        async () => (
            axios.get('/api/lab-test/department').then(res => res.data).catch(err => err)
        )
    )
    if (isLoading) {
        return
    }
    return (
        <LabTestContext.Provider value={{department, setDepartment, departments: data}}>
            {children}
        </LabTestContext.Provider>
    )
}

export {LabTestProvider, LabTestContext}
