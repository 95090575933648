import React from 'react'
import ConnectIPS from "../../../../images/payment/connectips.png"
import Khalti from "../../../../images/payment/khalti.png"
import Esewa from "../../../../images/payment/esewa.png"
import IMEPay from "../../../../images/payment/imepay.png"
import KhaltiLabTest from '../../khalti/KhaltiLabTest.js';

const PaymentDetails = ({labDetails}) => {
    return (
        <div className="col-md-6">
            <div className="card h-100">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6><b>Payment Details</b></h6>
                        {labDetails.status === 0 ?
                            <span className="badge badge-warning p-2">
                            Payment Pending
                        </span>
                            :
                            <span className="badge badge-success p-2">
                            Payment Completed
                        </span>
                        }
                    </div>
                    {labDetails.status === 0 ?
                        <div className="pay-with">
                            <div className="payment-amount my-3">
                                <span className="amount-label"><b>Total Amount:</b></span>
                                <span className="ms-3">NPR. {labDetails.price}</span>
                            </div>
                            <small className='text-info'>Can only be paid with Khalti at the moment</small>
                            <h5><b>Pay With:</b></h5>
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="payment-item my-2 ">
                                    <img src={Esewa} alt="Esewa"/>
                                </div>
                                <div className="payment-item my-2 ms-2 d-flex align-items-center">
                                    <KhaltiLabTest amount={labDetails.price} id={labDetails.id}
                                                   redirectLink={window.location.pathname}/>
                                </div>
                                <div className="payment-item my-2 ms-2">
                                    <img src={ConnectIPS} alt="Esewa"/>
                                </div>
                                <div className="payment-item my-2 ms-2">
                                    <img src={IMEPay} alt="Esewa"/>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="payment-details">
                            <div className="payment-amount my-3">
                                <span className="amount-label"><b>Total Amount Paid:</b></span>
                                <span className="ms-3">NPR. {labDetails.price}</span>
                            </div>
                            <div className="paid-with d-flex align-items-center">
                                <span className="paid-with-label"><b>Paid With:</b></span>
                                <div className="payment-item ms-4">
                                    {labDetails.payment_method === 'Khalti' &&
                                        <img src={Khalti} alt="Khalti"/>
                                    }
                                </div>
                            </div>
                            <div className="paid-at mt-2">
                                <span className="paid-at-label"><b>Paid At: </b></span>
                                <span>{labDetails.payment_date}</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PaymentDetails
