import {FileUpload} from '@mui/icons-material'
import {Box, Button, CircularProgress, Grid, Paper} from '@mui/material'
import React from 'react'
import {useContext} from 'react'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import swal from 'sweetalert'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import UploadFile from '../../../../custom_componets/UploadFile'
import axios from "../../../../api"
import {useQuery} from '@tanstack/react-query'

function AddMedical() {
    const [inputs, setInputs] = useState({
        doctor_id: null,
        check_date: null,
        hospital: null,
        doctor_name: null,
        doctor_nmc: null,
        report: null
    })
    const loginContext = useContext(LoginStateContext)
    const navigate = useNavigate()
    const [error, setError] = useState()
    const [submitting, setSubmitting] = useState(false)

    const gdDoctors = useQuery(
        ['gd-doctors'],
        async () => (
            axios.get('/api/admin/user-profile/gd-doctors', {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => res.data).catch(err => err)
        )
    )

    function handleFile(inputFiles) {
        const filesInput = []
        inputFiles.forEach((inputFile) => {
            var reader = new FileReader();
            reader.readAsDataURL(inputFile);
            console.log()
            var ext = inputFile.type.split('/')[1]
            reader.onload = () => {
                const strArr = reader.result.split('base64,')
                const base64Str = `data:image/${ext};base64,${strArr[1]}`
                filesInput.push(base64Str)
                // setInputs({ ...inputs, [name]: base64Str })
            }
            reader.onerror = error => {
                console.log(error);
            }
        })
        setInputs({...inputs, report: filesInput})
    }

    function handleInputs(e) {
        setInputs(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    function submitForm(e) {
        e.preventDefault()
        if (submitting) return;
        console.log()
        setSubmitting(true)
        if (Object.keys(inputs).length === 0) {
            setError("You Need To Select At Least One Document")
            return
        }
        axios.post("/api/admin/user-profile/upload-reports", inputs, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                swal({
                    title: "Medical Report",
                    text: "Medical Report Succesfully uploaded!",
                    icon: "success",
                }).then((value) => {
                    if (value) {
                        navigate("/user/lab-reports/medical-history")
                    }
                });
            })
            .catch(err => {
                swal({
                    title: "Medical Report",
                    text: "There was an error. Please try again!",
                    icon: "error",
                })
            })
            .finally(() => setSubmitting(false))
    }

    return (
        <Box>
            <Paper variant="outlined" square>
                <div className="insurance-form">
                    <Box component="div" sx={{p: 2, border: '1px'}}>
                        <h1 className='m-0 p-0' style={{fontWeight: '600'}}>Upload Medical History</h1>
                        <p className='text-muted'>Fill the fields below accurately and we will get back to you
                            shortly.</p>
                        <p className='text-danger'>
                            * Please only upload medical records that belong to you.
                        </p>
                    </Box>
                    <Box component="form" sx={{p: 4}} onSubmit={submitForm}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <UploadFile
                                    name="hand_written_letter"
                                    label="Upload Medical Document"
                                    type={"pdf"}
                                    accept={"application/pdf,application/vnd.ms-excel"}
                                    aspectRatio="4/1"
                                    setImageInput={(fileG) => {
                                        handleFile(fileG)
                                    }}
                                    error={error}
                                    required
                                    // multiple
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" startIcon={<FileUpload/>} fullWidth type='submit'>
                                    {
                                        submitting ?
                                            <CircularProgress size={16} sx={{color: 'white'}}/>
                                            :
                                            <span>
                      Upload Medical History
                    </span>
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Paper>
        </Box>
    )
}

export default AddMedical
