/* eslint-disable jsx-a11y/anchor-is-valid */
const ContentWrapper = (props) => {
  return (
    <div className="content-wrapper position-relative">
      <div className="content-inner">
        {/* Page header */}
        <div className="page-header page-header-light">
          <div className="page-header-content header-elements-lg-inline">
            <div className="page-title d-flex">
              <h4>
                <a href="/parent" className="header-elements-toggle text-body">
                  <i className="icon-arrow-left52 mr-2" />{" "}
                </a>
                <span className="font-weight-semibold">Home</span> - Dashboard
                <a
                  href="#"
                  className="header-elements-toggle text-body d-lg-none"
                >
                  <i className="icon-more" />
                </a>
              </h4>
            </div>
          </div>
          <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
            <div className="d-flex">
              <div className="breadcrumb">
                <a href="/" className="breadcrumb-item">
                  <i className="icon-home2 mr-2" /> Home
                </a>
                <span className="breadcrumb-item active">Dashboard</span>
              </div>
              <a
                href="#"
                className="header-elements-toggle text-body d-lg-none"
              >
                <i className="icon-more" />
              </a>
            </div>
          </div>
        </div>
        {/* /page header */}
        {/* Content area */}
        <div className="content">{props.children}</div>
        {/* /content area */}
        {/* Footer */}
        <div className="navbar navbar-expand-lg navbar-light border-bottom-0 border-top background-transparent">
          <div className="text-center d-lg-none w-100">
            <button
              type="button"
              className="navbar-toggler dropdown-toggle"
              data-toggle="collapse"
              data-target="#navbar-footer"
            >
              <i className="icon-unfold mr-2" />
              Footer
            </button>
          </div>
        </div>
      </div>
      <div className="btn-to-top">
        <button type="button" className="btn btn-dark btn-icon rounded-pill">
          <i className="icon-arrow-up8" />
        </button>
      </div>
    </div>
  );
};

export default ContentWrapper;
