function setCookie(name, value, remember) {
 let cookieCreate = `${name}=${value};`
        if (remember) {
            cookieCreate += `expires=Fri, 31 Dec 9999 23:59:59 GMT;`
        }
        document.cookie = cookieCreate
}

function getCookieToken() {
    const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith("token="))
    ?.split("=")[1];
    return cookieValue
}

export {setCookie, getCookieToken}
