import React from "react";
import axios from "../../api.js"
import {useNavigate, useSearchParams} from 'react-router-dom'
import swal from "sweetalert";
import PhoneInput from "react-phone-input-2";
import AddressAutoComp from "../../AddressAutoComp.js";
import usePostRequest from "../../../customHooks/usePostRequest.js";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Checkbox,
    CircularProgress,
    ImageList,
    ImageListItem,
    Paper,
    Radio
} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import {useContext} from "react";
import {LoginStateContext} from "../../../contexts/LoginStateContext.js";
import ConnectIPS from "../../../images/payment/connectips.png"
import Khalti from "../../../images/payment/khalti.png"
import Esewa from "../../../images/payment/esewa.png"
import IMEPay from "../../../images/payment/imepay.png"
import COD from "../../../images/payment/cod.png"
import {Stack} from "@mui/system";
import KhaltiOrder from "../../pages/khalti/KhaltiOrder.js";
import {useCart} from "../../../contexts/provider/GDCartProvider.js";
import OrderSummary from "./OrderSummary.jsx";

const FillDetails = (props) => {
    const gdCart = useCart()
    const loginContext = useContext(LoginStateContext)
    const queryParam = new URLSearchParams(window.location.search)
    // let chkOutCart = Object.fromEntries(queryParam).carts.split(",")
    // chkOutCart = chkOutCart.map(str => {
    //   return Number(str)
    // })

    // const products = JSON.parse(localStorage.getItem("cart")).filter(item => chkOutCart.includes(item.id))
    const products = gdCart.cart
    const [orderData, setOrderData] = React.useState({
        sub_total: "",
        total_amount: "",
        phone: "",
        address: "",
        shipping_id: ""
    });

    const [charges, setCharges] = React.useState({
        subTotal: "",
        totalDiscount: "",
        shipping_charge: "",
        total_cost: "",
    });

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const token = localStorage.getItem('token')

    const shippingDetails = useQuery(
        ['shippingDetails'],
        async () => {
            return axios.get("api/admin/order/shipping-details", {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data).catch(err => {
                })
        }
    )

    const postOrder = usePostRequest(
        "api/admin/order/store",
        true,
        (resStat, res) => {
            swal("Success", "Your Order Has Been Created", "success");
            setSearchParams({progress: 'payment', orderNum: res.order_number})
            gdCart.freshCart()
            // localStorage.setItem()
            // const filterCart = JSON.parse(localStorage.getItem('cart')).filter((item) => !chkOutCart.includes(item.id))
            // localStorage.setItem('cart', JSON.stringify(filterCart))
        },
        (err) => {
            console.log()
            swal("Error", "There was an error while posting your order", "error");
        }
    )

    React.useEffect(() => {
        props.setSideContent(<OrderSummary/>)
    }, [])

    React.useEffect(() => {
        const {discount, total} = calcAmount(products)
        let shipping = getShipping(shippingDetails.data, total)
        let shipping_charge = shipping === undefined ? 0 : shipping.price
        setCharges((prev) => ({
            ...prev,
            subTotal: Number(total),
            totalDiscount: Number(discount),
            shipping_charge: shipping === undefined ? 0 : shipping.price,
            total_cost: Number(total) + Number(shipping_charge) - Number(discount)
        }))
        setOrderData((prev) => ({
            ...prev,
            sub_total: Number(total),
            total_amount: Number(total) + Number(shipping_charge) - Number(discount),
            shipping_id: shipping === undefined ? null : shipping.id,
        }))
    }, [shippingDetails.isLoading, shippingDetails.data]);

    const cart = localStorage.getItem('cart')

    if (!cart) {
        return <h2>No Cart</h2>;
    }

    const handleChange = (e) => {
        setOrderData({...orderData, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let orders = [];
        products.map((product) => {
            orders.push({
                id: product.id,
                quantity: product.cartCount,
                vendor_id: product.vendor_id,
                prescription_image: product.prescription
            });
            return null;
        });
        orderData.orders = orders;
        let orderJSON = JSON.stringify(orderData);
        if (!postOrder.submitting) {
            postOrder.submit(orderJSON)
        }
    };

    if (shippingDetails.isLoading) {
        return <></>
    }

    return (
        <div>
            <div className="add-details-div">
                <Card variant="outlined">
                    <CardHeader title="Address Information" subheader="Fill Your Shipping Address Details"/>
                    <CardContent sx={{py: 0}}>
                        <form>
                            <div className="form-group mb-3">
                                <label htmlFor="exampleInputEmail1">Shipping Address</label>
                                <br/>
                                <small className="form-text text-muted">
                                    The product will be delivered to the specified address.
                                </small>
                                <AddressAutoComp
                                    onChange={(address) => {
                                        setOrderData({...orderData, address: address})
                                    }}
                                    getLocation={(locationObj) => {
                                        setOrderData({...orderData, address: locationObj.locationStr})
                                    }}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="exampleInputPassword1">Phone Num</label>
                                <br/>
                                <small className="form-text text-muted">
                                    Enter your phone number to which the product is to be
                                    delivered
                                </small>
                                <PhoneInput
                                    country={"np"}
                                    name="phone"
                                    className="phone-input"
                                    onChange={(phone) =>
                                        setOrderData({...orderData, phone: phone})
                                    }
                                />
                            </div>
                            <div className="d-flex justify-content-end">
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    onClick={handleSubmit}
                                >
                                    NEXT
                                    {
                                        postOrder.submitting && <CircularProgress color="secondary" size="1rem"/>
                                    }
                                </button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};


function calcAmount(products) {
    let discount = 0;
    let total = 0;
    products.forEach((i) => {
        total += i.cartCount * i.sale_price;
        discount += Math.round(
            (i.discountPercent * i.sale_price * i.cartCount) / 100
        );
    });
    return {discount, total}
}

function getShipping(shippingDetails, total) {
    if (shippingDetails === undefined || shippingDetails === null) return
    let shippingDetail = null
    shippingDetail = shippingDetails.filter((shippingDetail) => (total > shippingDetail.minimum && total < shippingDetail.maximum))
    shippingDetail = (shippingDetail === null && shippingDetails.length > 0) ? shippingDetails[shippingDetails.length - 1] : shippingDetail[0]
    return shippingDetail
}

export default FillDetails;
