import {Box, Grid, Stack, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {useRef} from 'react'
import {useCart} from '../../../contexts/provider/GDCartProvider'

function OrderItemList() {
    const {cart} = useCart()
    const listRef = useRef(null)
    useEffect(() => {
        listRef.current.style.maxHeight = '230px'
        listRef.current.style.overflowY = 'scroll'
    })
    return (
        <Grid container ref={listRef}>
            {
                cart.map((item, idx) => (
                    <OrderItem listRef={listRef} data={item}/>
                ))
            }
            {/* <OrderItem listRef={listRef} index={0} />
            <OrderItem listRef={listRef} index={1} />
            <OrderItem listRef={listRef} index={2} />
            <OrderItem listRef={listRef} index={3} /> */}
        </Grid>
    )
}

function OrderItem({listRef, index, data}) {
    const total = data.sale_price * data.cartCount
    return (
        <Grid item xs={12} py={1} sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Stack direction='row' alignItems='center'>
                <Box width='10%' minWidth='60px'
                     sx={{'& img': {width: '100%', aspectRatio: '1/1', objectFit: 'cover'}}}>
                    <img src={data.image_path} alt=""/>
                </Box>
                <Stack direction='row' alignItems='center' justifyContent={'space-between'} flexGrow={1} ml={1}>
                    <Box>
                        <Typography variant='subtitle1' fontWeight={550}>{data.productName}</Typography>
                        <Typography variant='subtitle1'>{data.cartCount} X NPR {data.sale_price}</Typography>
                        <Typography variant='caption'>{data.discountPercent}% discount</Typography>
                    </Box>
                    <Typography variant='subtitle1' fontWeight={550}>NPR {total}</Typography>
                </Stack>
            </Stack>
        </Grid>
    )
}

export default OrderItemList
