import {addCommasToNumber} from "../../../../../utils/common/common.utils";
import React from "react";
import {Skeleton} from "@mui/material";

const PaymentInformation = (props) => {
    const {data, isLoading} = props
    const { number_of_students, total_payable_amount, payable_amount, academic_year, discount_amount } = data || {};

    if(isLoading) {
        return (
            <div className="d-grid">
                <Skeleton variant="text" sx={{ height: 28, width: 200, borderRadius: '6px' }} />
                <Skeleton variant="text" sx={{ height: 28, width: 200, borderRadius: '6px' }} />
                <Skeleton variant="text" sx={{ height: 28, width: 200, borderRadius: '6px' }} />
            </div>
        )
    }

    const renderInformation = (label, value) => (
        <p style={{fontWeight: 600, color: "#555"}} className="mb-0">
            <span style={{whiteSpace: "nowrap", paddingRight: "6px", display: "inline-block"}} className="text-black  font-weight-bold">{label}:</span> {value || '-'}
        </p>
    );

    return (
        <div className="d-grid gap-1">
            {renderInformation("Academic Year", academic_year)}
            {renderInformation("Total Number of Students", addCommasToNumber(number_of_students))}
            {renderInformation("Total Payable Amount", `NRS. ${addCommasToNumber(total_payable_amount)}`)}
            {renderInformation("Discount Amount", addCommasToNumber(discount_amount))}
            {renderInformation("Payable Amount", addCommasToNumber(payable_amount))}
        </div>
    )
}

export default PaymentInformation
