import {Money} from '@mui/icons-material'
import {Avatar, Box, Button, Card, Grid, Stack, Typography} from '@mui/material'
import React from 'react'

const centerStyle = {
    display: 'flex',
    justifyContent: 'center'
}

function UnpaidItem({data, onPay}) {
    function handleClick(e) {
        onPay(data)
    }

    return (
        <Card variant='outlined' sx={{py: 2, px: 1, my: 1}}>
            <Grid container alignItems={'center'} justifyContent='center'>
                <Grid item xs={12} sm={3} sx={centerStyle}>
                    <Typography variant="h5">{data.fitnessprice.fitnesstype.fitness_name}</Typography>
                </Grid>
                <Grid item xs={12} sm={3} sx={centerStyle}>
                    <Stack direction='row' alignItems='center'>
                        <Avatar
                            alt="Remy Sharp"
                            src={"https://img.freepik.com/premium-vector/fitness-logo_25327-145.jpg?w=2000"}
                            sx={{width: 50, height: 50}}
                        />
                        <Typography variant="h6">Vendor Name</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={3} sx={centerStyle}>
                    <Stack direction='row' alignItems={'center'}>
                        <Typography variant='subtitle1' color='text.secondary'>Price:</Typography>
                        <Typography variant="h5" sx={{fontWeight: 550, ml: 1}}>NPR {data.total_amount}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={3} sx={centerStyle} onClick={handleClick}>
                    <Button variant='contained' startIcon={<Money/>}>Pay</Button>
                </Grid>
            </Grid>
        </Card>
    )
}

export default UnpaidItem
