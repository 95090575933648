import {Avatar, Box} from '@mui/material'
import React from 'react'

function FamilyDesign() {
    return (
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-3 border-right ps-5">
                            <div className="py-2 mb-1">
                                <span className='fs-6'>All family members</span>
                            </div>
                            <div className="py-2 mb-1">
                                <span className='fs-6'>Your requests</span>
                            </div>
                            <div className="py-2 mb-1">
                                <span className='fs-6'>Family join requests</span>
                            </div>
                            <div className="py-2 mb-1">
                                <span className='fs-6'>Leave requests</span>
                            </div>
                        </div>
                        <div className="col-9 ps-4">
                            <div className="py-2 mb-4">
                                <span className="fs-5 fw-bold">
                                    All Family Members
                                </span>
                            </div>
                            <div className='mb-3 d-flex align-items-center gap-2'>
                                <button className="btn btn-primary">Add Family</button>
                                <button className="btn btn-warning">Scan QR code</button>
                                <button className="btn btn-secondary">Switch Primary Member</button>
                            </div>
                            <div>
                                <table className='table border-left border-right border-top'>
                                    <thead>
                                    <Box component='tr' bgcolor={'#'}>
                                        <th><span className='text-muted'>User Name</span></th>
                                        <th><span className='text-muted'>Relationship</span></th>
                                        <th><span className="text-muted">Contact Number</span></th>
                                        <th></th>
                                    </Box>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center gap-2">
                                                <Avatar src='https://mui.com/static/images/avatar/1.jpg'/>
                                                <div>
                                                    <div>
                                                        <span className='fs-6'
                                                              style={{fontWeight: 550}}>Adam Messy</span>
                                                    </div>
                                                    <div>
                                                        <span className='fw-light'>Banepa, Kavre, Nepal</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span className="fs-6 text-muted">Brother in arms</span>
                                        </td>
                                        <td>
                                            <span className="fs-6 text-muted">+977 9850192819</span>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center gap-2 justify-content-end">
                                                <button className="btn btn-primary">Make Primary</button>
                                                <button className="btn btn-danger">Remove</button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center gap-2">
                                                <Avatar src='https://mui.com/static/images/avatar/1.jpg'/>
                                                <div>
                                                    <div>
                                                        <span className='fs-6'
                                                              style={{fontWeight: 550}}>Adam Messy</span>
                                                    </div>
                                                    <div>
                                                        <span className='fw-light'>Banepa, Kavre, Nepal</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span className="fs-6 text-muted">Brother in arms</span>
                                        </td>
                                        <td>
                                            <span className="fs-6 text-muted">+977 9850192819</span>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center gap-2 justify-content-end">
                                                <button className="btn btn-primary">Make Primary</button>
                                                <button className="btn btn-danger">Remove</button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center gap-2">
                                                <Avatar src='https://mui.com/static/images/avatar/1.jpg'/>
                                                <div>
                                                    <div>
                                                        <span className='fs-6'
                                                              style={{fontWeight: 550}}>Adam Messy</span>
                                                    </div>
                                                    <div>
                                                        <span className='fw-light'>Banepa, Kavre, Nepal</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span className="fs-6 text-muted">Brother in arms</span>
                                        </td>
                                        <td>
                                            <span className="fs-6 text-muted">+977 9850192819</span>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center gap-2 justify-content-end">
                                                <button className="btn btn-primary">Make Primary</button>
                                                <button className="btn btn-danger">Remove</button>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FamilyDesign
