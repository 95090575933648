import {Box} from '@mui/material';
import React from 'react'
import {useEffect} from 'react';
import {useContext} from 'react';
import {useRef} from 'react';
import {useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import ProductFilter from '../layouts/ProductFilter';
import ProductFIlterProvider from '../layouts/ProductFIlterProvider';
import GDVendorHeader from './components/GDVendorHeader';
import GDVendorNavigation from './components/GDVendorNavigation';
import {GDVendorContext} from './GDVendorContext';
import GdVendorHome from './GdVendorHome';
import GDVendorProfile from './GDVendorProfile';

function GDVendorRoute({children}) {
    const {vendor} = useContext(GDVendorContext)
    const [currentTab, setCurrentTab] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(null)
    const urlParams = useParams()
    const headerRef = useRef()
    const paths = {
        home: [
            vendor.is_exculsive == 2 ?
                <GdVendorHome/>
                :
                <ProductFIlterProvider>
                    <ProductFilter headerRef={headerRef}/>
                </ProductFIlterProvider>
        ],
        all_products: [
            <ProductFIlterProvider>
                <ProductFilter headerRef={headerRef}/>
            </ProductFIlterProvider>
        ],
        profile: [<GDVendorProfile/>]
    }
    if (vendor.is_exculsive != 2) {
        delete paths.home
    }
    useEffect(() => {
        const params = Object.fromEntries(
            new URLSearchParams(searchParams)
        )
        let currentPath = vendor.is_exculsive == 2 ? 'home' : 'all_products'
        if (params.pg) {
            currentPath = params.pg
        }
        if (urlParams.filter != undefined) {
            currentPath = "all_products"
        }
        let keys = Object.keys(paths)
        setCurrentTab(keys.indexOf(currentPath))
        setCurrentPage(paths[currentPath][0])
    }, [searchParams])

    if (currentPage === null) {
        return
    }
    return (
        <>
            <GDVendorHeader headerRef={headerRef}>
                <GDVendorNavigation tabValue={currentTab} setSearchParams={setSearchParams}/>
            </GDVendorHeader>
            <Box py={3}>
                {
                    currentPage
                }
            </Box>
        </>
    )

}

export default GDVendorRoute
