import TabPanel from "../TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useState} from 'react'
import Orders from './Orders'

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

const OrderNav = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: "100%"}}>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    aria-label="basic tabs example"
                >
                    <Tab label="All" {...a11yProps(0)} />
                    <Tab label="Pending Payment" {...a11yProps(1)} />
                    <Tab label="Shipping" {...a11yProps(2)} />
                    <Tab label="Delivered" {...a11yProps(3)} />
                    <Tab label="Cancelled" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} className="order-items">
                <Orders status="all"/>
            </TabPanel>
            <TabPanel value={value} index={1} className="order-items">
                <Orders status="pending" paymentStatus="unpaid"/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Orders status="on_the_way"/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Orders status="delivered"/>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Orders status="cancelled"/>
            </TabPanel>
        </Box>
    );
};

export default OrderNav;
