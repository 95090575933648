import {useQuery} from '@tanstack/react-query'
import axios from '../../../../api'
import React from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import FitnessContainer from '../../../fitnessCenter/components/FitnessContainer'
import FitnessProdCard from '../../../fitnessCenter/components/FitnessProdCard'
import CatalogSlider from './CatalogSlider'
import ProductCards from '../../../../stores/ProductCards'
import {settings5items} from '../../sliderSettings/View5itemsOnlg'

function GDPharmacyCatalog() {
    const {data, isLoading} = usePharmacyProducts()

    if (isLoading) {
        return
    }

    if (data.data.length === 0) {
        return
    }

    return (
        <FitnessContainer title={"GD Pharmacy Catalog"}>
            <CatalogSlider settingProps={settings5items}>
                {
                    data.data.map((prod) => (
                        <div>
                            <ProductCards data={prod}/>
                        </div>
                    ))
                }
            </CatalogSlider>
        </FitnessContainer>
    )
}

function usePharmacyProducts() {
    const vendor = useGetRequest("/api/vendor-details?vendor_type=2")
    const id = vendor?.data ? vendor.data[0]?.id : null
    const {data, isLoading} = useGetRequest(`/api/products?vendor_id=${id}`, id ? true : false)

    return {data, isLoading}
}

function useGetRequest(api, prevDepend = true) {
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (prevDepend) {
            axios.get(api).then((res) => {
                setData(res.data)
                setIsLoading(false)
            }).catch(err => {
                console.log()
                setIsLoading(false)
            })
        }
    }, [prevDepend])

    return {data, isLoading}
}

export default GDPharmacyCatalog
