import React, { useContext, useRef } from "react";
import "./index.scss";
import { LoginStateContext } from "../../../../contexts/LoginStateContext";
import { Link } from "react-router-dom";
import ProfileCard from "./components/ProfileCard";
import DashboardCharts from "./components/DashboardCharts";
import MedicalReports from "./components/MedicalReports";
import axios from "../../../api";
import { Box, CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import PhysicalCard from "./components/PhysicalCard";
import PackageInfo from "./components/PackageInfo";
import GDBenefits from "./components/GDBenefits";
import MyAppointment from "./components/MyAppointment";
import DashboardOverlay from "./components/DashboardOverlay";
import PackageTimeline from "./PackageTimeline";

function Dashboard() {
  const loginContext = useContext(LoginStateContext);
  // const breadLinks = useBreadCrumbs({home:'/home'})
  const ref = useRef(null);
  const user = loginContext.state.data;

  const appointmentsQuery = useQuery(["appointments"], async () => {
    return axios
      .get("/api/admin/booking-review", {
        headers: { Authorization: `Bearer ${loginContext.state.token}` },
      })
      .then((res) => res.data);
  });

  return (
    <div className="dashboard-home">
      <div className="row g-1" ref={ref}>
        <div className="col-xl-7 pt-3">
          <div
            className="position-relative w-100"
            style={{ minHeight: "100%", maxHeight: "100%", height: "500px" }}
          >
            <div className="position-absolute w-100 top-0 start-0 h-100">
              <div className="col-12 h-50 p-0">
                <Box
                  className="card dashboard-banner "
                  bgcolor={"primary.light"}
                >
                  <div className="card-body">
                    <div className="row h-100 align-items-center">
                      <div className="banner-content col-10 col-xl-9 ms-md-5 ms-1">
                        <h1 className="text-white font-weight-bold">
                          Welcome {user.member.name}
                        </h1>
                        <p className="text-white">
                          We help you to find information about doctors and
                          clinics who provide you the best health services.
                        </p>
                        {appointmentsQuery.isLoading ? (
                          <div className="btn btn-primary mt-2">
                            <Box sx={{ display: "flex" }}>
                              <CircularProgress />
                            </Box>
                          </div>
                        ) : (
                          <>
                            {appointmentsQuery.data.length > 0 ? (
                              <Link
                                to="/user/appointments/doctor"
                                className="btn btn-primary text-white mt-2"
                              >
                                View Your Appointments
                              </Link>
                            ) : (
                              <Link
                                to="/booking/doctor"
                                className="btn btn-primary text-white mt-2"
                              >
                                Make an Appointment
                              </Link>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="col-12 h-50 p-0 pt-2">
                <MyAppointment />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 pt-3">
          <div className="h-100">
            <ProfileCard />
          </div>
        </div>
        <PackageTimeline />
        <div className="col-12 pt-3">
          {/* <UserPhysDetails /> */}
          <div className="row">
            <div className="col-lg-4">
              <PackageInfo />
            </div>
            <div className="col-lg-4">
              <PhysicalCard />
            </div>
            <div className="col-lg-4">
              <GDBenefits />
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <DashboardCharts />
        </div>
        <div className="col-xl-6">
          <MedicalReports />
        </div>
        <div className="col-12"></div>
      </div>
      <DashboardOverlay blurDiv={ref} />
    </div>
  );
}

export default Dashboard;
