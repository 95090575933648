import React from 'react'
import PaymentItem from './components/PaymentItem'
import {Box, Grid} from '@mui/material'
import {useState} from 'react'
import {useContext} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import {useQuery} from '@tanstack/react-query'
import axios from '../../../api'
import NoData from '../../../custom_componets/NoData'

function PaymentHistory() {
    const loginContext = useContext(LoginStateContext)

    const {data, isLoading, isError} = useQuery(
        ['payment-history'],
        async () => (
            axios.get('/api/transaction-history', {headers: {'Authorization': `Bearer ${loginContext.state.token}`}})
                .then(res => {
                    console.log(res.data);
                    return res.data
                }).catch(err => err)
        )
    )
    console.log('Payment data=', data)

    if (isLoading) {
        return
    }
    if (data.length === 0) {
        return (
            <NoData
                title='Payment History'
                description='No payment history.'
            />
        )
    }
    return (
        <Box>
            <Grid container spacing={2}>
                {
                    data.map((item, idx) => (
                        <Grid item xs={4} key={idx}>
                            <PaymentItem data={item}/>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    )
}

export default PaymentHistory
