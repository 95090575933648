import {Alert, AlertTitle, Avatar, Box, Grid, Rating, Typography} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React, {useContext} from 'react'
import {GDVendorContext} from '../GDVendorContext'
import axios from '../../../../api'
import {DateFormat} from '../../../../DateFormat'

function GDVendorReviews({reviews}) {
    const {vendor} = useContext(GDVendorContext)
    const {data, isLoading} = reviews

    if (isLoading) {
        return
    }
    if (data.length === 0) {
        return (
            <div className="container">
                <Alert severity='error'>
                    <AlertTitle>No Reviews</AlertTitle>
                    <Typography variant='subtitle1'>
                        There are no reviews for this vendor yet.
                    </Typography>
                </Alert>
            </div>
        )
    }
    return (
        <>
            {
                data.map((review, idx) => (
                    <Box mt={2} key={idx}>
                        <Grid container alignItems={'center'}>
                            <Grid item xs={2}>
                                <Avatar
                                    src={"https://st2.depositphotos.com/1006318/5909/v/950/depositphotos_59095529-stock-illustration-profile-icon-male-avatar.jpg"}
                                    sx={{width: '70px', height: '70px'}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography varaint="subtitle1" fontWeight={450}>{review.user.name}</Typography>
                                <Typography variant="subtitle1"
                                            color='text.secondary'>{DateFormat(review.updated_at).getFullDateText()}</Typography>
                            </Grid>
                            <Grid items xs={3} sx={{ml: 'auto'}}>
                                <Rating readOnly value={3}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1'>
                                    {review.comment}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                ))
            }
        </>

    )
}

export default GDVendorReviews
