import React, {useEffect} from 'react'
import FitnessContainer from '../../../fitnessCenter/components/FitnessContainer'
import CatalogSlider from '../../../gdstores/gdstorehome/components/CatalogSlider'
import FitnessProdCard from "../../../fitnessCenter/components/FitnessProdCard"
import {Card} from '@mui/material'
import {settings5items} from '../../../gdstores/sliderSettings/View5itemsOnlg'
import {useQuery} from '@tanstack/react-query'
import ProductCards from '../../../../stores/ProductCards'
import axios from '../../../../api'

function RelatedProducts({product}) {
    console.log()
    const categoryId = product?.category_id
    const {data, isLoading, isError, refetch} = useQuery(
        {
            queryKey: ['related-products'],
            queryFn: async () => (
                axios.get('api/products?category_id=' + product.category_id)
                    .then(res => res.data.data)
            ),
            enabled: !!categoryId,
        }
    )
    console.log()
    if (isLoading) {
        return
    }
    if (isError) {
        return
    }
    return (
        <Card className='mt-5' variant='outlined'>
            <FitnessContainer title="Related Products">
                <CatalogSlider numItems={3} settingProps={settings5items}>
                    {
                        data.map((item, idx) => (
                            <ProductCards data={item} key={idx}/>
                        ))
                    }
                    <div className="d-flex justify-content-center">
                        <FitnessProdCard
                            img='https://media-cldnry.s-nbcnews.com/image/upload/newscms/2020_08/3239746/200221-esperion-ew-558p.jpg'/>
                    </div>
                    <div className="d-flex justify-content-center">
                        <FitnessProdCard
                            img='https://rukminim1.flixcart.com/image/416/416/koenl3k0/ayurvedic/k/j/o/cholesterol-medicine-heart-medicine-supplement-for-healthy-heart-original-imag2v9jfv9ecfwn.jpeg?q=70'/>
                    </div>
                    <div className="d-flex justify-content-center">
                        <FitnessProdCard img='https://healthcare-in-europe.com/media/story/5231/image-1413132764.jpg'/>
                    </div>
                    <div className="d-flex justify-content-center">
                        <FitnessProdCard/>
                    </div>
                </CatalogSlider>
            </FitnessContainer>
        </Card>
    )
}

export default RelatedProducts

