import {Box, Button, Card, Typography} from '@mui/material'
import React from 'react'

function GDPharmacyHeader() {
    let headerSx = {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        backgroundColor: '#52C8F4',
        opacity: '0.1',
        backgroundImage: 'radial-gradient(circle at center center, #52C8F4, #52C8F4), repeating-radial-gradient(circle at center center, #52C8F4, #52C8F4, 35px, transparent 70px, transparent 35px)',
        backgroundBlendMode: 'multiply'
    }
    return (
        <Box width='100%' pt={1.2} sx={{position: 'relative'}}>
            <Box sx={headerSx}></Box>
            <Box sx={{position: 'relative', py: 3}} className='container'>
                <Box sx={{width: '100%', textAlign: 'center'}}>
                    <Typography variant="h4" fontWeight={500}>
                        Your Health Is Our Top Priority
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default GDPharmacyHeader
