import {useQuery} from '@tanstack/react-query'
import axios from '../../../api'
import React, {useContext} from 'react'
import {useEffect} from 'react'
import {FitnessContext} from '../FitnessContext'
import {useState} from 'react'
import {Box, Button, FormControl, Grid, MenuItem, Modal, Select, TextField, Typography} from '@mui/material'
import FitnessPackage from './FitnessPackage'
import {InputLabel} from '@mui/material'

function PackagesFitness() {
    const fitnessContext = useContext(FitnessContext)
    const vendor = fitnessContext.vendor
    console.log()
    console.log()
    const fitness = useFitnessPricing(vendor)
    // console.log()
    const [open, setOpen] = useState(false)

    if (fitness.isLoading) {
        return
    }


    let fitnessDatas = fitness.fitness
    console.log()

    // console.log()

    function getVendorName(id) {
        let filtered = vendor.data.data.filter((item) => item.vendor_id === id)
        if (filtered.length === 0) {
            return null
        }
        return filtered[0].user
    }

    return (
        <>
            <Grid container>
                {
                    fitnessDatas.length === 0 ?
                        'No Data'
                        :
                        fitnessDatas.map((fitnessData) => (
                            <>
                                <Grid item xs={12} md={4} sm={4} lg={3}>
                                    <FitnessPackage data={fitnessDatas}
                                                    fitnesstype={fitnessData.fitnesstype.fitness_name}
                                                    duration='1 Month' price={fitnessData.one_month}
                                                    vendorName={getVendorName(fitnessData.vendor_id).name}
                                                    logo={getVendorName(fitnessData.vendor_id).image_path}
                                                    fitnesstypeid={fitnessData.fitness_name_id}
                                                    vendorId={fitnessData.vendor_id}/>

                                </Grid>

                                <Grid item xs={12} md={4} sm={4} lg={3}>
                                    <FitnessPackage data={fitnessDatas}
                                                    fitnesstype={fitnessData.fitnesstype.fitness_name}
                                                    duration='6 Months' price={fitnessData.six_month}
                                                    vendorName={getVendorName(fitnessData.vendor_id).name}
                                                    logo={getVendorName(fitnessData.vendor_id).image_path}
                                                    fitnesstypeid={fitnessData.fitness_name_id}
                                                    vendorId={fitnessData.vendor_id}/>
                                </Grid>
                            </>
                        ))
                }

            </Grid>
        </>
    )
}

async function fitnessPricing(vendor) {
    const res = await axios.get(`/api/fitness/fitness-pricing?vendor_id=${vendor}`)
    console.log()
    return res.data
}

function useFitnessPricing(vendor) {
    const [fitness, setFitness] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    console.log()

    useEffect(() => {
        if (vendor.isLoading === false) {
            console.log()
            vendor.data.data.every(async (item, idx) => {
                let res = await fitnessPricing(item.vendor_id)
                setFitness(res)
                setIsLoading(false)
                return false
            })
        }
    }, [vendor.isLoading])

    return {
        fitness,
        isLoading
    }
}

function BookFitnessModal({open, handleClose, data, isLoading}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [formVal, setFormVal] = useState({
        fitness_type: null,
        duration: null
    })

    const [totalAmt, setTotalAmt] = useState(0)

    const duration = [
        {
            name: "1 Month",
            value: "one_month"
        },
        {
            name: "3 Month",
            value: "three_month"
        },
        {
            name: "6 Month",
            value: "six_month"
        },
        {
            name: "1 Year",
            value: "one_year"
        }
    ]

    useEffect(() => {
        if (formVal.fitness_type !== null && formVal.duration !== null) {
            if (!isLoading) {
                const filtered = data.filter((item) => (item.fitness_name_id == formVal.fitness_type))[0]
                console.log()
                setTotalAmt(filtered[formVal.duration])
            }
        }
    }, [formVal])

    function handleChange(e) {
        setFormVal({...formVal, [e.target.name]: e.target.value})
    }

    if (isLoading) {
        return <></>
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography variant='h5'>Book Fitness Package</Typography>
                <Box component={'form'} mt={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Fitness Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='fitness_type'
                            value={formVal.fitness_type}
                            label="Age"
                            onChange={handleChange}
                        >
                            {
                                data.map((item) => (
                                    <MenuItem value={item.fitness_name_id}>{item.fitness_name_id}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{mt: 2}}>
                        <InputLabel id="demo-simple-select-label">Membership Duration</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='duration'
                            value={formVal.duration}
                            label="Age"
                            onChange={handleChange}
                        >
                            {
                                duration.map((item) => (
                                    <MenuItem value={item.value}>{item.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <TextField id="outlined-basic" value={`Total Amount: Rs. ${totalAmt}`} variant="outlined" fullWidth
                               sx={{mt: 2}} disabled/>

                    <Button variant="contained" sx={{mt: 2}}>
                        Book Fitness Package
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default PackagesFitness
