import { Box } from "@mui/material";
import React from "react";

function MedicalHistoryPDF({ pdf }) {
  return (
    <Box
      width="100%"
      maxWidth="900px"
      maxHeight="900px"
      position="absolute"
      top="50%"
      left="50%"
      sx={{ transform: "translate(-50%, -50%)" }}
    >
      <div className="card">
        <div className="card-header">User Pdf</div>
      </div>
      <div
        className="card-body"
        style={{ width: "100%", aspectRatio: "1 / 1.6", maxHeight: "700px" }}
      >
        <iframe
          src={pdf}
          frameborder="0"
          style={{ width: "100%", height: "100%" }}
        ></iframe>
      </div>
    </Box>
  );
}

export default MedicalHistoryPDF;
