import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Avatar, Box, CircularProgress } from "@mui/material";
import { LockClockOutlined } from "@mui/icons-material";
import dayjs from "dayjs";
import { getTimeDifference } from "../../../../helperFunctions/getTimeDifference";
import ImgPlaceHolder from "../../../../images/receptionist.png";
import ConsultationService from "../../../../services/consultation.service";

// Debounce function
function debounce(func, delay) {
  let timeoutId;
  return function(...args) {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

const ParentConsultationList = (props) => {
  const { childId } = props;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [search, setSearch] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  // Fetch consultation history
  const fetchConsultationHistory = async (childId) => {
    return await ConsultationService.getHistory(childId);
  };

  // Fetch searched consultation history
  const fetchSearchedConsultationHistory = async (search) => {
    return await ConsultationService.fetchSearchedConsultationHistory(search);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["consultation-history", childId],
    queryFn: () => fetchConsultationHistory(childId),
    enabled: Boolean(childId),
  });

  const fetchData = async (search) => {
    setIsSearching(true);
    if (search) {
      try {
        const response = await fetchSearchedConsultationHistory(search);
        setSearchedData(response?.data?.history || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setSearchedData([]);
    }
    setIsSearching(false);
  };

  // Debounced version of fetchData
  const debouncedFetchData = useMemo(
    () => debounce((search) => fetchData(search), 1000),
    []
  );

  useEffect(() => {
    debouncedFetchData(search);
  }, [search, debouncedFetchData]);

  const renderLoader = () => (
    <tr>
      <td colSpan={7}>
        <div className="d-flex gap-3 justify-content-center align-items-center py-5">
          <CircularProgress /> Loading...
        </div>
      </td>
    </tr>
  );

  const renderUI = () => {
    if (isLoading || isSearching) {
      return renderLoader();
    }
    const dataToRender = search ? searchedData : data?.history || [];
    if (dataToRender.length) {
      return (
        <>
          {dataToRender.map((item, index) => (
            <tr key={item.id}>
              <td>
                <div className="d-flex align-items-center gap-2">
                  <Avatar src={item?.doctor?.image_path || ImgPlaceHolder} />
                  <div>
                    <div>
                      <span className="fs-6" style={{ fontWeight: 550 }}>
                        {item?.doctor?.user?.name}
                      </span>
                    </div>
                    <div>
                      <span className="fw-light">
                        {item?.doctor?.qualification ||
                          "No qualification added"}
                      </span>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <span className="fs-6 text-muted">{item?.agenda}</span>
              </td>
              <td>
                <span className="fs-6 text-muted">
                  {item?.start_time?.split(" ")[1]}
                </span>
              </td>
              <td>
                <span className="fs-6 text-muted">
                  {item?.end_time?.split(" ")[1]}
                </span>
              </td>
              <td>
                <div
                  className="d-flex align-items-center gap-1 badge badge-success text-white"
                  style={{ width: "fit-content" }}
                >
                  <LockClockOutlined className="fs-6" />
                  <span className="fs-6">
                    {getTimeDifference(item?.start_time, item?.end_time)}
                  </span>
                </div>
              </td>
              <td>
                <span className="fs-6 text-muted">
                  {dayjs(item?.start_time).format("YYYY-MM-DD")}
                </span>
              </td>
              <td>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    navigate(`/parent/consultation-history-details/${item?.id}`)
                  }
                >
                  View Details
                </button>
              </td>
            </tr>
          ))}
        </>
      );
    }

    return (
      <tr>
        <td colSpan={7}>
          <p className="text-center">No data found.</p>
        </td>
      </tr>
    );
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-grid gap-2 mb-4">
          <div className="d-flex align-items-center justify-content-between pb-2">
            <h4 className="fs-5 fw-bolder lh-sm">Consultation History</h4>
          </div>
          <input
            style={{ width: "250px" }}
            className="ml-auto mr-4 rounded p-1"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <table className="table border-left border-right border-top">
            <thead>
              <Box component="tr" bgcolor={"#"}>
                <th>
                  <span className="text-muted">Doctor Name</span>
                </th>
                <th>
                  <span className="text-muted">Agenda</span>
                </th>
                <th>
                  <span className="text-muted">Start Time</span>
                </th>
                <th>
                  <span className="text-muted">End Time</span>
                </th>
                <th>
                  <span className="text-muted">Consultation Time</span>
                </th>
                <th>
                  <span className="text-muted">Consultation Date</span>
                </th>
                <th></th>
              </Box>
            </thead>
            <tbody>{renderUI()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ParentConsultationList;
