import classNames from "classnames";
import {yellow} from "@mui/material/colors";

const Warning = (props) => {
  const {classes, children} = props
    return (
        <div style={{background: yellow["50"], color: yellow["900"], borderRadius: 10, fontSize: 16}}  className={classNames("p-3 my-4", classes)}>
            {children}
        </div>
    )
}


export default Warning
