import React from "react";
import Slider from "react-slick";
import "./ShopPopularBrands.scss";
import axios from "../api.js"
import GDLogo from "../../images/gd-logo-web-03 2.png"
import {Link, useNavigate} from "react-router-dom";
import {Skeleton, Stack} from "@mui/material";
import FitnessContainer from "../pages/fitnessCenter/components/FitnessContainer";

const ShopPopularBrands = () => {

    const [brands, setBrands] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate()

    React.useEffect(() => {
        axios.get("api/brand")
            .then((res) => {
                setBrands(res.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log()
                setLoading(false)
            })
    }, [])

    const sliderSettings = {
        dots: true,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        initialSlide: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const renderBrands = () => {
        if (loading) {
            let skel = []
            for (let i = 0; i < 10; i++) {
                skel.push(
                    <div>
                        <div className="card brand-card">
                            <div className="card-header">
                                <div className="img-div brand-logo">
                                    <Skeleton variant="rectangular" width="100%" height="100%"/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            return skel
        }
        return brands.map((brand) => {
            return (
                <div>
                    <Stack direction='row' justifyContent='center'>
                        <div className="card brand-card" onClick={() => navigate(`/gd-store/filter/${brand.id}`)}>
                            <div className="card-header">
                                <div className="img-div brand-logo">
                                    <img src={brand.image_path == null ? GDLogo : brand.image_path} alt=''/>
                                </div>
                            </div>
                        </div>
                    </Stack>
                </div>
            )
        })
    };

    if (brands.length === 0) {
        return
    }

    return (
        <div className="section-brands">
            <FitnessContainer title='Popular Brands'>
                <div className="brand-list">
                    <div className="custom-slick">
                        <Slider {...sliderSettings}>
                            {renderBrands()}
                        </Slider>
                    </div>
                </div>
            </FitnessContainer>
        </div>
    );
};

export default ShopPopularBrands;
