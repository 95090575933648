import {faEye} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useQuery} from '@tanstack/react-query'
import MUIDataTable from 'mui-datatables'
import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import axios from "../../../api"
import Loader from '../../Loader'

const ClaimSettlement = () => {
    const loginContext = useContext(LoginStateContext)
    const userData = loginContext.state.data
    const claimSettlementQuery = useQuery(
        ['claim-settlement-data', userData.id],
        async () => {
            return axios.get('/api/admin/insurance-claim', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )
    if (claimSettlementQuery.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }
    const claimSettlementDetails = claimSettlementQuery.data;

    return (
        <ClaimSettlementTable claimSettlement={claimSettlementDetails.claims}/>
    )
}

const ClaimSettlementTable = ({claimSettlement}) => {
    const columns = [
        {
            name: "sn",
            label: "S.N."
        },
        {
            name: "insuranceType",
            label: "Insurance Type",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "claimAmount",
            label: "Claim Amount",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "insuranceStatus",
            label: "Insurance Status",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "claimActions",
            label: "Actions",
        },
    ]

    const data = []

    for (let i = 0; i < claimSettlement.length; i++) {
        let claim = claimSettlement[i]
        const dataObj = {}
        dataObj.sn = i + 1
        dataObj.insuranceType = claim.insurance.insurancetype.type
        dataObj.claimAmount = `Rs. ${claim.claim_amount}`
        dataObj.insuranceStatus = claim.insurance_status
        dataObj.claimActions = (
            <Link to={`/user/insurance/claim-settlement/${claim.slug}`} className='btn btn-primary'>
                <FontAwesomeIcon icon={faEye}/>
            </Link>
        )
        data.push(dataObj)
    }

    const options = {
        filterType: 'checkbox',
        responsive: 'standard'
    };

    return (
        <MUIDataTable
            title={"Claim Settlement"}
            data={data}
            columns={columns}
            options={options}
        />
    )
}

export default ClaimSettlement
