import React, { useContext, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ParentService from "../../../../services/parent.service";
import FormModalContext from "../../../custom_componets/globalFormModal/FormModalContext";
import PdfIcon from "../../../../images/pdf.png";
import MedicalHistoryPDF from "./partials/MedicalHistoryPDF";
import NoDataUi from "../../../../images/nodata.jpg";
import DocImg from "../../../../images/receptionist.png";
import dayjs from "dayjs";
import { DateFormat } from "../../../DateFormat";
import ParentChildrenSelection from "./partials/ParentChildrenSelection";
import { Avatar, Box, CircularProgress } from "@mui/material";
import {
  CalendarMonthOutlined,
  LocalHospitalOutlined,
} from "@mui/icons-material";
import { toast } from "react-hot-toast";

function Index() {
  const { openFormModal } = useContext(FormModalContext);
  const [historyData, setHistoryData] = useState([]);
  const [childrenId, setChildrenId] = useState(undefined);
  const [search, setSearch] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  // debounce.js
  function debounce(func, delay) {
    let timeoutId;
    return function(...args) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  }

  /*
   * MEDICAL HISTORY FETCHING BY CHILDREN
   * */
  const { data, isLoading } = useQuery({
    queryKey: ["children-medical-history", childrenId],
    queryFn: () =>
      childrenId
        ? ParentService.fetchMedicalHistoryChildren(childrenId)
        : undefined,
    onSuccess: (data) => {
      setHistoryData(data?.data);
    },
    onError: (err) => {
      if (err?.response) {
        console.log("Error response:", err?.response);
        toast.error("Something went wrong.");
      }
    },
    enabled: Boolean(childrenId),
  });

  const fetchData = async (search) => {
    setIsSearching(true);
    if (search) {
      try {
        const response = await ParentService.fetchSearchedMedicalHistoryChildren(
          search
        );
        const responseData = Array.isArray(response?.data?.histories)
          ? response?.data?.histories
          : [];
        setSearchedData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    setIsSearching(false);
  };

  // Debounced version of fetchData
  const debouncedFetchData = useMemo(
    () =>
      debounce((search) => {
        fetchData(search);
      }, 1000),
    [] // Empty dependency array to create it only once
  );

  useEffect(() => {
    debouncedFetchData(search);
  }, [search, debouncedFetchData]);

  const renderLoader = () => (
    <div className="d-flex gap-3 justify-content-center align-items-center py-5">
      <CircularProgress /> Loading...
    </div>
  );

  const renderHistoryDataUI = () => {
    if (isLoading || isSearching) {
      return (
        <tr>
          <td colSpan={7}>{renderLoader()}</td>
        </tr>
      );
    }

    const dataToRender = search
      ? searchedData
      : historyData?.data?.histories || [];

    if (dataToRender.length) {
      return (
        <>
          {dataToRender.map(
            (item, idx) =>
              item.medical_report.length > 0 && (
                <tr key={item.id}>
                  <td data-cell="S.N.">
                    <span className="text-muted fs-6">{idx + 1}</span>
                  </td>
                  <td data-cell="Medical PDF">
                    <div
                      className="d-flex gap-2 align-items-center"
                      role="button"
                      onClick={() =>
                        openFormModal(
                          <MedicalHistoryPDF
                            pdf={item.medical_report[0].report_path}
                            name="Medical History"
                          />
                        )
                      }
                    >
                      <div>
                        <img src={PdfIcon} alt="" style={{ height: "40px" }} />
                      </div>
                      <div>
                        <span className="fs-6" style={{ fontWeight: "550" }}>
                          #{item.medical_report[0].report.split(".")[0]}
                        </span>
                        <br />
                        <span className="fs-6 fw-light">
                          {dayjs(item?.created_at).format("YYYY-MM-DD")}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td data-cell="Translated By">
                    {item.doctor === null ? (
                      <span className="text-muted fs-6">
                        Doctor Not Assigned
                      </span>
                    ) : (
                      <div className="d-flex align-items-center gap-2">
                        <Avatar src={item.doctor.image_path || DocImg} />
                        <div>
                          <div>
                            <span className="fs-6" style={{ fontWeight: 550 }}>
                              Dr. {item.doctor.user.name}
                            </span>
                          </div>
                          {item.doctor.nmc_no && (
                            <div>
                              <span className="badge badge-info">
                                {item.doctor.nmc_no}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </td>
                  <td data-cell="Checked By">
                    <div>
                      <span className="fs-6 me-1">
                        {item.doctor_name || "-----"}
                      </span>
                      <small className="badge badge-success text-white">
                        {item.doctor_nmc}
                      </small>
                    </div>
                    {item.hospital && (
                      <div className="d-flex align-items-center gap-1">
                        <LocalHospitalOutlined className="fs-6" />
                        <span className="fw-light fs-6">{item.hospital}</span>
                      </div>
                    )}
                    {item.check_date && (
                      <div className="d-flex align-items-center gap-1">
                        <CalendarMonthOutlined className="fs-6" />
                        <span className="fw-light text-muted fs-6">
                          {/* {DateFormat(item.check_date).getFullDateText()} */}
                          {dayjs(item.check_date).format("YYYY-MM-DD")}
                        </span>
                      </div>
                    )}
                  </td>
                  <td data-cell="Department">
                    <span className="fs-6 text-muted">
                      {item.department ? (
                        <>{item.department.department}</>
                      ) : item.is_other ? (
                        <>{item.is_other}</>
                      ) : (
                        <>---</>
                      )}
                    </span>
                  </td>
                  <td data-cell="Status">
                    {item.status === 2 ? (
                      <span className="badge badge-danger">Rejected</span>
                    ) : item.status === 1 ? (
                      <span className="badge badge-success">Approved</span>
                    ) : (
                      <span className="badge badge-warning">Pending</span>
                    )}
                  </td>
                  <td data-cell="Action">
                    <div className="d-flex justify-content-end">
                      <Link
                        to={`/parent/medical-history-detail?member_id=${item.member_id}&id=${item.id}`}
                        className="btn btn-primary"
                      >
                        View Report
                      </Link>
                    </div>
                  </td>
                </tr>
              )
          )}
        </>
      );
    } else {
      return (
        <tr>
          <td colSpan={7}>
            {isLoading || isSearching ? (
              renderLoader()
            ) : (
              <div className="w-100 d-flex justify-content-center align-items-center">
                <span className="fs-6">No Data Found.</span>
              </div>
            )}
          </td>
        </tr>
      );
    }
  };

  const handleChildrenChange = (childrenID) => {
    setChildrenId(childrenID);
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center gap-4 p-3 pt-3">
        <div>
          <div>
            <span className="fs-5">External Medical History</span>
          </div>
          <span className="text-muted">
            These are your external medical history.
          </span>
        </div>
        <Box className="d-flex align-items-start justify-content-between pt-2 mb-3">
          {childrenId ? (
            <Link
              to={`/parent/add-medical-history/${childrenId}`}
              className="btn btn-primary"
            >
              + Add Medical History
            </Link>
          ) : (
            <button className="btn btn-primary" disabled>
              + Add Medical History
            </button>
          )}
        </Box>
      </div>
    );
  };

  return (
    <Box>
      <div>
        <ParentChildrenSelection handleChange={handleChildrenChange} />
        <div className="card">
          {renderHeader()}
          <div className="card-body">
            <div className="mb-3 d-flex justify-content-end">
              <input
                style={{ width: "250px" }}
                className="ml-auto mr-4 rounded p-1 border-lightwhite"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            {data?.length === 0 ? (
              <div
                className="w-100 d-flex justify-content-center align-items-center flex-column"
                style={{ aspectRatio: "2.8/1" }}
              >
                <div style={{ height: "60%" }}>
                  <img src={NoDataUi} alt="No Data" className="h-100" />
                </div>
                <div>
                  <span className="fs-4 fw-light">
                    No Medical History Added Yet.
                  </span>
                </div>
              </div>
            ) : (
              <table className="table border-left border-right border-top responsive-table">
                <thead>
                  <Box component="tr" bgcolor={"#"}>
                    <th data-cell="S.N.">
                      <span className="text-muted">S.N.</span>
                    </th>
                    <th data-cell="Medical PDF">
                      <span className="text-muted">Medical PDF</span>
                    </th>
                    <th data-cell="Translated By">
                      <span className="text-muted">Translated By</span>
                    </th>
                    <th data-cell="Checked By">
                      <span className="text-muted">Checked By</span>
                    </th>
                    <th data-cell="Department">
                      <span className="text-muted">Department</span>
                    </th>
                    <th data-cell="Status">
                      <span className="text-muted">Status</span>
                    </th>
                    <th data-cell="Action"></th>
                  </Box>
                </thead>
                <tbody>{renderHistoryDataUI()}</tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
}

export default Index;
