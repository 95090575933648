import React from 'react'
import GDBlogHeader from './GDBlogHeader'
import ContentContainer from './components/ContentContainer'
import LatestBlogs from './LatestBlogs'
import {Box, CircularProgress, Grid, Pagination, PaginationItem, Stack} from '@mui/material'
import PopularPosts from './PopularPosts'
import NewsItemContainer from './components/NewsItemContainer'
import ItemDetails from './components/ItemDetails'
import {useBlogsHome} from './GDBlogProvider'
import {useSearchParams} from 'react-router-dom'
import axios from '../../api'
import {useQuery} from '@tanstack/react-query'
import {useEffect} from 'react'
import {useState} from 'react'
import {DateFormat} from '../../DateFormat'

function GDBlogFilter() {
    const relatedPosts = useBlogsHome()
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState(Number(Object.fromEntries(searchParams)?.page) || 1)
    (page)
    (Object.fromEntries(searchParams))
    const {data, isLoading, isError, refetch} = useQuery(
        ['blogsFiltered', searchParams],
        async () => (
            axios.get(`/api/news`, {params: Object.fromEntries(searchParams)}).then(res => res.data).catch(err => err)
        )
    )

    useEffect(() => {
        if (!isLoading) {
            refetch()
        }
    }, [searchParams])

    function handlePageChange(e, page) {
        setPage(prev => page)
        setSearchParams({...Object.fromEntries(searchParams), page: page})
    }

    // (data)
    if (isLoading) {
        return <CircularProgress/>
    }
    return (
        <Box className="container">
            {/* <GDBlogHeader /> */}
            <Grid container spacing={{lg: 6, xs: 0}}>
                <Grid item xs={12} lg={7.5}>
                    <ContentContainer header={'Search Results'}>
                        <Stack gap={2}>
                            {data.data.map((item, idx) => (
                                <NewsItemContainer
                                    mediaComponent={
                                        <img src={item.image_path}/>
                                    }
                                >
                                    <ItemDetails
                                        title={item.title_en}
                                        author={item?.user?.name || 'Ghargharmadoctor'}
                                        category={item.menu.title_en}
                                        description={item.description_en}
                                        time={DateFormat(item.created_at).getFullDateText()}
                                        blog={item}
                                    />
                                </NewsItemContainer>
                            ))}
                        </Stack>
                    </ContentContainer>
                    <Box my={1}>
                        <Pagination
                            count={data.last_page}
                            color='primary'
                            page={page}
                            onChange={handlePageChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={4.5}>
                    <ContentContainer header='Popular Posts'>
                        <PopularPosts/>
                    </ContentContainer>
                    <ContentContainer header='Related Posts and Blogs'>
                        {
                            relatedPosts.isLoading ? <CircularProgress/> : (
                                <Stack gap={2}>
                                    {
                                        relatedPosts.data.map((item, idx) => (
                                            <NewsItemContainer
                                                mediaComponent={
                                                    <img src={item.image_path}/>
                                                }
                                            >
                                                <ItemDetails
                                                    title={item.title_en}
                                                    author={item.user.name}
                                                    category={item.menu.title_en}
                                                    time={DateFormat(item.created_at).getFullDateText()}
                                                    blog={item}
                                                />
                                            </NewsItemContainer>
                                        ))
                                    }
                                </Stack>
                            )
                        }

                    </ContentContainer>
                </Grid>
            </Grid>

        </Box>
    )
}

export default GDBlogFilter
