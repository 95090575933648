import { Close } from "@mui/icons-material";
import { Box, IconButton, Modal } from "@mui/material";
import React from "react";
import { useChkLogin } from "../../../../middlewares/LoginMiddleware";
import { useState } from "react";
import axios from "../../../api";
import swal from "sweetalert";
import CsvFormat from "../../../../files/student-list.csv";
import setFormData from "../../../../utils/http/formdata.utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  boxShadow: 24,
};

function CsvUpload({ open, handleClose, refetch }) {
  const [submitting, setSubmitting] = useState(false);
  const { token } = useChkLogin();
  const [csv, setCsv] = useState();
  const [fiscalYear, setFiscalYear] = useState();

  const fiscalYears = [
    { label: "2079/2080 BS", value: "2079/2080" },
    { label: "2080/2081 BS", value: "2080/2081" },
    { label: "2081/2082 BS", value: "2081/2082" },
    { label: "2082/2083 BS", value: "2082/2083" },
  ];

  function downloadCSV() {
    // Path to the CSV file
    var csvFilePath = CsvFormat;

    // Create a download link
    var link = document.createElement("a");
    link.setAttribute("href", csvFilePath);
    link.setAttribute("download", "student-data-sheet.csv");
    document.body.appendChild(link);

    // Trigger the download
    link.click();
  }

  function uploadCSV(e) {
    e.preventDefault();
    setSubmitting(true);

    console.log(csv);

    const formData = setFormData({ file: csv[0], academic_year: fiscalYear });

    axios
      .post(`api/admin/import`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        handleClose();
        refetch();
        swal({
          title: "Success",
          text: "CSV uploaded successfully",
          icon: "success",
          allowOutsideClick: false,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setSubmitting(false));
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <span>Upload CSV File</span>
            <IconButton onClick={handleClose}>
              <Close sx={{ fontSize: "18px" }} />
            </IconButton>
          </div>
          <div className="card-body">
            <div className="btn btn-primary mb-4" onClick={downloadCSV}>
              Download CSV Sample
            </div>
            <form
              onSubmit={uploadCSV}
              encType="multipart/form-data"
              className="d-grid gap-3"
            >
              <div className="form-group mb-2">
                <input
                  className="form-control"
                  type="file"
                  accept=".csv"
                  onChange={(e) => setCsv(e.target.files)}
                  required
                />
              </div>
              <div className="form-group">
                <select
                  className="form-select"
                  name="fiscal_year"
                  required
                  onChange={(e) => setFiscalYear(e.target.value)}
                  value={fiscalYear}
                >
                  <option value="">Select academic year</option>
                  {fiscalYears.map((fy, i) => (
                    <option key={i} value={fy.value}>
                      {fy.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className=" form-group">
                <button
                  type=" submit"
                  className="form-control btn btn-primary"
                  disabled={submitting}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default CsvUpload;
