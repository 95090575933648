import {Box} from '@mui/material'
import React from 'react'

function NewsItemContainer({direction = 'row', mediaComponent, children}) {
    return (
        <Box
            display='flex'
            flexDirection={direction}
            textAlign={'left'}
            gap={1}
            sx={{
                cursor: 'pointer',
                '&:hover img': {
                    transform: 'scale(1.2)'
                }
            }}
        >
            <Box
                minWidth='30%'
                flexShrink={0}
                flexGrow={0}
                position='relative'
                sx={{
                    ...(() => (direction === 'column' && {aspectRatio: '1.5/1'}))()
                }}
            >
                <Box
                    position='absolute'
                    height='100%'
                    width='100%'
                    overflow={'hidden'}
                    sx={{
                        '& img': {
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            transition: 'all 200ms'
                        }
                    }}
                >
                    {mediaComponent}
                </Box>
            </Box>
            {children}
        </Box>
    )
}

export default NewsItemContainer
