import React, {useEffect} from 'react'
import Chart from 'chart.js/auto'
import {XAxis} from 'recharts'
import annotationPlugin from 'chartjs-plugin-annotation';
import {Line} from 'react-chartjs-2'

Chart.register(annotationPlugin)

function ChartComponent({data, metadata, label}) {
    const dataSet = {
        datasets: [{
            data: data,
            parsing: {
                xAxisKey: 'date',
                yAxisKey: 'value'
            },
            label: label,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }]
    }
    console.log()
    let screenings = data.map((item, idx) => {
        return item.screening
    })
    return (
        <Line
            data={dataSet}
            options={{
                scales: {
                    x: {
                        ticks: {
                            beginAtZero: false,
                            callback: function (label) {
                                return `\$${this.getLabelForValue(label)}`
                            }
                        }
                    },
                    secondXAxis: {
                        axis: 'x',
                        labels: screenings,
                        grid: {
                            drawOnChartArea: false
                        }
                    },
                    y: {
                        min: metadata.red_min_range,
                        max: metadata.red_max_range,
                    },

                },
                plugins: {
                    autocolors: false,
                    annotation: {
                        annotations: {
                            box1: {
                                type: 'box',
                                yMin: metadata.amber_min_range,
                                yMax: metadata.min_range,
                                // backgroundColor: 'rgba(255,191,0, 0.5)',
                                backgroundColor: 'transparent'
                            },
                            box2: {
                                type: 'box',
                                yMin: metadata.max_range,
                                yMax: metadata.amber_max_range,
                                // backgroundColor: 'rgba(255,191,0, 0.5)'
                                backgroundColor: 'transparent'
                            },
                            box3: {
                                type: 'box',
                                yMin: metadata.red_min_range,
                                yMax: metadata.amber_min_range,
                                // backgroundColor: 'rgba(220, 53, 69, 0.5)'
                                backgroundColor: 'transparent'
                            },
                            box4: {
                                type: 'box',
                                yMin: metadata.amber_max_range,
                                yMax: metadata.red_max_range,
                                // backgroundColor: 'rgba(220, 53, 69, 0.5)'
                                backgroundColor: 'transparent'
                            }
                        }
                    }
                }
            }}
        />
    )
}

export default ChartComponent
