import React from "react";
import OrganicFood from "../images/organicfood.png";
// import GymEquipment from "../images/gym-equipment.png";
import Fitness from "../images/fitness.png";
import Pharmacy from "../images/pharmacy.png";
import Slider from "react-slick";

import "./TrustedVendors.css";
import { Link } from "react-router-dom";
import { Box, Card, CardMedia, Grid, Stack, Typography } from "@mui/material";
import SliderOne from "./slider-carousels/SliderOne";

const TrustedVendors = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="Trusted-Vendors ">
      <div className="container py-5">
        <div className="text-white text-center heading">
          <h1 className="primary-text">Our Trusted Vendors</h1>
          <p className="secondary-text">
            We provide you with a selection of
            <br /> curated vendors, medicines, health products and subscription
            partners.
          </p>
        </div>
        <Grid className="trusted-vendor-row" sx={{ mt: 4, mb: 8 }} container>
          <Grid item xs={4}>
            <TrustedVendorCard
              imgSrc={Pharmacy}
              title="Pharmacy"
              description="Are you looking for a pharmancy or looking for medicines? Get your medicines delivered to your door."
            />
          </Grid>
          <Grid item xs={4}>
            <TrustedVendorCard
              imgSrc={OrganicFood}
              title="Organic Foods"
              description="Get fresh fruits and vegetables delivered to your doorsteps. All our fruit is hand picked, while all vegetables are grown organically."
            />
          </Grid>
          <Grid item xs={4}>
            <TrustedVendorCard
              imgSrc={Fitness}
              title="Physical Fitness"
              description="Are you looking for a gym  subscription? Choose from a wide range of membership plans and services that will fit your needs and budget."
            />
          </Grid>
        </Grid>
        <Box className="mt-5 pt-2 vendor-slider ">
          <SliderOne numItems={1} settingOverride={settings}>
            <Box mt={8}>
              <TrustedVendorCard
                imgSrc={Pharmacy}
                title="Pharmacy"
                description="Are you looking for a pharmancy or looking for medicines? Get your medicines delivered to your door."
              />
            </Box>
            <Box mt={8}>
              <TrustedVendorCard
                imgSrc={OrganicFood}
                title="Organic Foods"
                description="Get fresh fruits and vegetables delivered to your doorsteps. All our fruit is hand picked, while all vegetables are grown organically."
              />
            </Box>
            <Box mt={8}>
              <TrustedVendorCard
                imgSrc={Fitness}
                title="Physical Fitness"
                description="Are you looking for a gym  subscription? Choose from a wide range of membership plans and services that will fit your needs and budget."
              />
            </Box>
          </SliderOne>
        </Box>
      </div>
    </div>
  );
};

function TrustedVendorCard({ imgSrc, title, description }) {
  return (
    <Box
      width="100%"
      px={1}
      sx={{ cursor: "pointer", "&:hover img": { mt: -15 } }}
    >
      <Card
        sx={{
          bgcolor: "inherit",
          color: "white",
          textAlign: "center",
          overflow: "visible !important",
        }}
        elevation={0}
      >
        <Stack gap={2}>
          <Box
            bgcolor="#77CEF1"
            width={"100%"}
            flexShrink={1}
            boxShadow={1}
            borderRadius={6}
            sx={{ aspectRatio: "1.86/1" }}
            position="relative"
          >
            <Box
              position="absolute"
              width="100%"
              height="100%"
              top={0}
              left={0}
              display={"flex"}
              justifyContent={"center"}
              sx={{
                "& img": {
                  width: "90%",
                  objectFit: "contain",
                  mt: -8,
                  pb: 2,
                  transition: "all 500ms",
                },
              }}
            >
              <img src={imgSrc} alt="" />
            </Box>
          </Box>
          <Box px={1}>
            <Typography variant="h4" className="primary-text" fontWeight={600}>
              {title}
            </Typography>
          </Box>
          <Box px={1}>
            <Typography
              variant="subtitle1"
              fontSize={"18px"}
              className="secondary-text"
              lineHeight={1.1}
            >
              {description}
            </Typography>
          </Box>
          {/* <Box sx={{'& a': {width: '60%'}}}>
                        <div className="text-center">
                            <a className="btn btn-primary btn-text " href="/gd-store">Browse</a>
                        </div>
                    </Box> */}
        </Stack>
      </Card>
    </Box>
  );
}

export default TrustedVendors;
