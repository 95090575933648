import React from 'react'
import Esewa from '../../../images/payment/esewa.png'
// import Khalti from '../../../images/payment/khalti.png'
import ImePay from '../../../images/payment/imepay.png'
import ConnectIPS from '../../../images/payment/connectips.png'
import KhaltiImg from '../../../images/payment/khalti.png'
import "../../../components/pages/ChooseDate/BookingPayment.js"
import {useLocation, useNavigate} from 'react-router-dom'
import KhaltiPackage from '../khalti/KhaltiPackage'
import PackagePaymentProvider, {usePackagePaymentCtx} from './PackagePaymentContext.js'
import {useChkLogin} from '../../../middlewares/LoginMiddleware.js'
import {payKhaltiConfig, payWithKhalti} from '../../../features/payment/paymentService.js'
import {packageBookingVerification} from '../../../features/payment/verifications.js'
import KhaltiPaymentComponent from '../../../features/khalti/components/KhaltiPaymentComponent.jsx'
import swal from 'sweetalert'

const PackagePaymentWrapper = () => {
    return (
        <PackagePaymentProvider>
            <PackagePayment/>
        </PackagePaymentProvider>
    )
}

const PackagePayment = () => {
    const {fee, id, monthly_fee, interval, redirectLink} = usePackagePaymentCtx()
    const {token} = useChkLogin()
    const navigate = useNavigate()

    const verifyPayment = (payload) => {
        const {amount, mobile, pidx, purchase_order_id, purchase_order_name, transaction_id, txnId} = payload
        const reqBody = {
            pidx: pidx,
            amount: amount,
            id: id,
            payment_interval: interval,
            prepay_status: 0,
            monthly_amount: monthly_fee
        }
        return packageBookingVerification(reqBody, token)
    }

    const onSuccess = (response) => {
        if (response.data.success) {
            swal({
                title: response.data.success,
                icon: "success",
                closeOnClickOutside: false
            }).then((value) => {
                if (value) {
                    navigate(redirectLink)
                }
            });
        }
        if (response.data.error) {
            swal({
                title: response.data.error,
                icon: "warning",
                closeOnClickOutside: false
            }).then((value) => {
                if (value) {
                    navigate("/book-package/payment", {state: {fee: fee, id: id}})
                }
            });
        }
    }

    const onFailure = (error) => {
        swal({
            title: "Request Fail",
            icon: "warning",
            closeOnClickOutside: false
        });
    }

    console.log();
    return (
        <div className="container my-5 booking-payment ">
            <div className="card">
                {fee !== null &&
                    <div className="card-header">
                        <h5>Payment Amount: <span>Rs. {fee}</span></h5>
                    </div>
                }
                <div className="card-body py-0 px-0">
                    <div className="row align-items-center">
                        <div className="col-md-2 py-4 px-2 text-center">
                            Pay Via:
                        </div>
                        <div className="col-md-10 px-3">
                            <div className="row text-center">
                                <div className="col-lg-3 col-md-6 my-3">
                                    <button type='submit'>
                                        <img src={Esewa} alt=""/>
                                    </button>
                                </div>
                                <div className="col-lg-3 col-md-6 my-3">
                                    <KhaltiPaymentComponent
                                        config={{
                                            name: 'Sabin Lawaju',
                                            email: 'lawazu.sabin@gmail.com',
                                            phone: '9860335814',
                                            purchaseOrderId: '1231',
                                            purchaseOrderName: 'asdjklasd asda',
                                            amount: 1000 //calcRes.total !== 0 ? calcRes.total : calcRes.result
                                        }}
                                        onVerify={verifyPayment}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        render={
                                            (isLoading, isSubmitting, makePayment) => (
                                                <button onClick={makePayment}>
                                                    <img src={KhaltiImg} alt=""/>
                                                </button>
                                            )
                                        }
                                    />
                                    {/* <KhaltiPackage amount={fee} id={id} interval={interval} monthly_amount={monthly_fee} redirectLink={redirectLink} /> */}
                                </div>
                                <div className="col-lg-3 col-md-6 my-3">
                                    <button type='submit'>
                                        <img src={ImePay} alt=""/>
                                    </button>
                                </div>
                                <div className="col-lg-3 col-md-6 my-3">
                                    <button type='submit'>
                                        <img src={ConnectIPS} alt=""/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PackagePaymentWrapper
