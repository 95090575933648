import StoreCarousel from "../../stores/StoreCarousel";
import "./StoreHome.scss";
import ShopByCategory from "../../stores/ShopByCategory";
import ShopByLatestProd from "../../stores/ShopByLatestProd";
import ShopVendorList from "../../stores/ShopVendorList";
import ShopPopularBrands from "../../stores/ShopPopularBrands";
import axios from "../../api.js";
import {StoreContext} from "../../../contexts/StoreContext"
import {useEffect, useContext} from 'react'
import {Breadcrumbs, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import HomeEventComp from "../../events/HomeEventComp";

const StoreHome = () => {
    const storeContext = useContext(StoreContext)

    return (
        <div className="store-home-page">
            <div className="container mb-5">
                <Breadcrumbs aria-label="breadcrumb" className="breadcrumb mt-4">
                    <Link className="text-decoration-none text-muted" to="/">
                        Home
                    </Link>
                    <Typography color="text.primary">GD Store</Typography>
                </Breadcrumbs>

                <div className="store-home-container">
                    <div className="row">
                        <div className="store-carousel mb-4">
                            <StoreCarousel/>
                        </div>
                    </div>

                    <div className="row">
                        <ShopByLatestProd/>
                    </div>

                    <HomeEventComp/>

                    <div className="row">
                        <ShopPopularBrands/>
                    </div>

                    <div className="row">
                        <ShopByCategory/>
                    </div>

                    <div className="row">

                        <ShopVendorList/>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default StoreHome;
