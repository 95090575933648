import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography
} from '@mui/material'
import {Stack} from '@mui/system'
import axios from '../../../api'
import React, {useEffect, useState} from 'react'
import {useContext} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import AuthButton from '../../../custom_componets/AuthButton'

function FitnessPackage({data, fitnesstype, price, duration, vendorName, vendorId, logo, fitnesstypeid}) {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Card sx={{p: 2}} elevation='3'>
                <CardContent>
                    {/* <Typography sx={{color:"text.secondary"}}>Daytime Membership</Typography> */}
                    <Typography variant='h5' sx={{fontWeight: 450}}>
                        {fitnesstype}
                    </Typography>
                    <Typography variant='h4' sx={{fontWeight: "600"}}>
                        NPR. {price}
                    </Typography>
                    <Typography sx={{fontWeight: "600"}}>
                        for {duration}
                    </Typography>
                    <Stack direction={"row"} alignItems='center' my={2}>
                        <Avatar
                            src={logo}
                            sx={{width: 50, height: 50, mr: 1}}
                        />
                        <Typography variant='h6'>{vendorName}</Typography>
                    </Stack>
                    <Button variant='contained' sx={{borderRadius: "20px"}} onClick={() => {
                        setOpen(true)
                    }}>
                        Buy Plan
                    </Button>
                </CardContent>
            </Card>
            <BookFitnessModal open={open} handleClose={() => {
                setOpen(false)
            }} data={data} fitnesstype={fitnesstype} durations={duration} price={price} isLoading={false}
                              vendorName={vendorName} logo={logo} fitnesstypeid={fitnesstypeid} vendorId={vendorId}/>
        </>
    )
}

function BookFitnessModal({
                              open,
                              handleClose,
                              data,
                              isLoading,
                              fitnesstype,
                              durations,
                              price,
                              vendorName,
                              logo,
                              fitnesstypeid,
                              vendorId
                          }) {
    const [submitting, setSubmitting] = useState(false)
    const loginContext = useContext(LoginStateContext)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    async function bookFitness(token, e) {
        const date = new Date()
        const data = {
            membership_type: fitnesstypeid,
            total_amount: price,
            vendor_id: vendorId,
            end_of_membership_date: date.toISOString().split('T')[0],
            payment_method: 'Khalti',
            payment_status: 'unpaid',
            status: 'pending'
        }
        setSubmitting((prev) => !prev)
        const res = await axios.post('api/fitness/fitness-booking', data, {headers: {Authorization: `Bearer ${token}`}})
        console.log()
        setSubmitting((prev) => !prev)
        handleClose()
    }

    function handleCloseModal(e) {
        if (!submitting) {
            handleClose()
        }
    }

    if (isLoading) {
        return <></>
    }

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography variant='h5'>Book Fitness Package</Typography>
                <Stack direction={"row"} alignItems='center' my={2}>
                    <Avatar
                        src={logo}
                        sx={{width: 50, height: 50, mr: 1}}
                    />
                    <Typography variant='h6'>{vendorName}</Typography>
                </Stack>
                <Box component={'form'} mt={2}>
                    <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">Fitness Type</InputLabel> */}
                        <TextField id="outlined-basic" value={`${fitnesstype}`} variant="outlined" fullWidth
                                   sx={{mt: 2}} disabled label='Fitness Type'/>
                    </FormControl>

                    <FormControl fullWidth sx={{mt: 2}}>
                        {/* <InputLabel id="demo-simple-select-label">Membership Duration</InputLabel> */}
                        <TextField id="outlined-basic" value={`${durations}`} variant="outlined" fullWidth sx={{mt: 2}}
                                   disabled label="Membership Duration"/>
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 2}}>
                        <TextField id="outlined-basic" value={`Total Amount: Rs. ${price}`} variant="outlined" fullWidth
                                   sx={{mt: 2}} disabled label="Total"/>
                    </FormControl>

                    <AuthButton auth={true} authVerfify={loginContext.verifyingLogin} callbackFn={bookFitness}
                                sx={{mt: 2}}>
                        Book Fitness Package
                    </AuthButton>
                </Box>
            </Box>
        </Modal>
    )
}

export default FitnessPackage
