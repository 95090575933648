import {Skeleton} from '@mui/material'
import React from 'react'

function ProductSkeleton() {
    return (
        <div className="prod-container">
            <div className="card product-card">
                <div className="img-div">
                    <Skeleton variant="rectangular" style={{height: "100%"}} animation="wave"/>
                </div>

                <div className="px-3 pt-2 prod-card-body">
                    <div>
                        <Skeleton variant="text" sx={{fontSize: '0.6rem'}} animation="wave"/>
                    </div>
                    <div>
                        <Skeleton variant="text" sx={{fontSize: '1rem'}} animation="wave"/>
                    </div>
                    <div>
                        <Skeleton variant="text" sx={{fontSize: '1rem'}} animation="wave"/>
                    </div>
                    <div>
                        <Skeleton variant="text" sx={{fontSize: '0.8rem'}} animation="wave"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductSkeleton
