import {useQuery} from '@tanstack/react-query'
import React from 'react'
import ProductCards from '../../../../stores/ProductCards'
import FitnessContainer from '../../../fitnessCenter/components/FitnessContainer'
import {settings5items} from '../../sliderSettings/View5itemsOnlg'
import CatalogSlider from './CatalogSlider'
import axios from "../../../../api"

function MostViewed() {
    const {data, isLoading} = useQuery(
        ['mostViewed'],
        async () => (
            axios.get('/api/products?highest_views=desc').then(res => res.data.data)
        )
    )
    if (isLoading) {
        return
    }
    if (data.length === 0) {
        return
    }
    return (
        <FitnessContainer title={"Most Viewed Products"}>
            <CatalogSlider settingProps={settings5items}>
                {
                    data.map((prods) => (
                        <div>
                            <ProductCards data={prods}/>
                        </div>
                    ))
                }
            </CatalogSlider>
        </FitnessContainer>
    )
}

export default MostViewed
