import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faCaretRight} from "@fortawesome/free-solid-svg-icons";
import DateCalendar from "./DateCalendar.js";
import {useNavigate} from 'react-router-dom';
import {Box, Stack} from '@mui/material';
import {useChkLogin} from '../../../middlewares/LoginMiddleware.js';

const BookingCard = (props) => {
    const booking = props.booking;
    const hospitals = props.hospitals;
    const {token} = useChkLogin()
    const today = new Date();
    let navigate = useNavigate();
    console.log("Booking", props.booking)
    const consultHandler = (slug) => {
        navigate(`/doctor/${slug}`);
    }

    const renderHospital = (hospital) => {
        // let hosp = hospital.replace('[', '').replace(']', '').replaceAll('"','').split(',');
        return (
            hospital.map((hos) =>
                hospitals.filter(singleHospital => singleHospital.id == hos).map((filteredHospital) => {
                    return (filteredHospital.name)
                })
            )
        )
    }

    return (
        <Box display='flex' flexWrap='wrap'>
            <Box width='430px' flexGrow={1} flexShrink={1}>
                <div className="doctor-part py-3">
                    <div className="row w-100 p-0 py-4 m-0">
                        <div className="col-12 col-sm-4 d-flex justify-content-center justify-content-sm-end">
                            <img src={booking.image_path} alt="" className="doctor-img align-self-start"/>
                        </div>

                        <div className='col-12 col-sm-8 d-flex justify-content-center justify-content-sm-start '>
                            <Box width='100%'>
                                <h5 className='w-100'
                                    style={{overflowWrap: 'break-word'}}>{booking.salutation} {booking.user.name}</h5>
                                <ul className="list-unstyled mb-4">
                                    <li><FontAwesomeIcon icon={faCaretRight}
                                                         className="me-2"/>{booking.departments !== null && booking.departments.department}
                                    </li>
                                    <li><FontAwesomeIcon icon={faCaretRight}
                                                         className="me-2"/>Experience: {booking.year_practiced} years
                                    </li>
                                    {booking.hospital ?
                                        <li><FontAwesomeIcon icon={faCaretRight}
                                                             className="me-2"/>{renderHospital(booking.hospital).join(', ')}
                                        </li>
                                        :
                                        ''
                                    }
                                    <li><FontAwesomeIcon icon={faCaretRight} className="me-2"/>Consultation Fee:
                                        Rs. {booking.fee}</li>
                                </ul>
                                <button className="btn btn-primary py-1"
                                        onClick={() => consultHandler(booking.slug)}>View Profile<FontAwesomeIcon
                                    icon={faAngleRight} className="ms-4"/></button>
                            </Box>
                        </div>
                    </div>
                </div>
            </Box>
            <Box width='65%' flexGrow={1}>
                <DateCalendar date={today} booking={booking} token={token}/>
            </Box>
        </Box>
    )
}

export default BookingCard
