import { Box, Button } from "@mui/material";
import React from "react";

function ChatModal({
  title,
  description,
  handleConfirm,
  isLoading,
  handleCLose,
  remainingConsultation,
}) {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      bottom={0}
      width="100%"
      height="100vH"
      sx={{ zIndex: 99999 }}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        bgcolor="black"
        sx={{ opacity: 0.7 }}
      ></Box>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        sx={{ transform: "translate(-50%, -50%)" }}
      >
        <Box
          bgcolor={"#fff"}
          borderRadius={1}
          boxShadow={1}
          maxWidth={"448px"}
          textAlign="left"
        >
          <Box px={2} py={1}>
            <span className="fs-5">{title}</span>
          </Box>
          <Box px={2} py={1}>
            <span className="fs-6 fw-light" style={{ fontWeight: 100 }}>
              {description}
            </span>
          </Box>
          <Box px={2} py={1}>
            <Box display={"flex"} gap={1}>
              <Box flexGrow={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={handleCLose}
                >
                  Cancel
                </Button>
              </Box>
              <Box flexGrow={1}>
                {remainingConsultation > 0 ? (
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleConfirm}
                    disabled={isLoading}
                  >
                    {isLoading ? "Calling.." : "Confirm"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleCLose}
                    disabled={isLoading}
                  >
                    Ok
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ChatModal;
