import {Box, Step, StepLabel, Stepper} from '@mui/material'
import React, {createContext, useContext, useRef, useState} from 'react'
import PersonalInformation from './globalFormComponents/PersonalInformation';

const GlobalFormContext = createContext(null)


const steps = ['Personal Information', 'Address Information', 'Professional Information', 'Declaration And Services', 'Upload Documents', 'KYC Verification'];

function GlobalForm() {
    const stepperRef = useRef(null)
    const [activeStep, setActiveStep] = useState(0)
    const [submissionId, setSubmissionId] = useState(null)
    return (
        <GlobalFormContext.Provider
            value={{
                setActiveStep: setActiveStep,
                submissionId: submissionId,
                setSubmissionId: setSubmissionId
            }}
        >
            <Box>
                <Box
                    width='100%'
                    py={2}
                    overflow='scroll'
                >
                    <Stepper activeStep={activeStep} ref={stepperRef}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
                <Box>
                    <PersonalInformation/>
                </Box>
            </Box>
        </GlobalFormContext.Provider>
    )
}

export function useNextStep(nextStep) {
    const {setActiveStep} = useContext(GlobalFormContext)

    function goToNextStep() {
        setActiveStep(prev => prev + 1)
    }

    return {goToNextStep}
}

export function useStoreSubmissionId() {
    const {submissionId, setSubmissionId} = useContext(GlobalFormContext)

    return {submissionId, setSubmissionId}
}

export default GlobalForm
