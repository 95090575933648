import React from "react";

const AccountDetails = () => {
  return (
    <div>
      <h3>
        <span className="font-weight-bold">GD Bank Account Details</span>
      </h3>
      <p>
        <span className="font-weight-bold pr-6">Name of Bank:</span>
        <span>NIC Asia Bank Limited</span>
      </p>
      <p>
        <span className="font-weight-bold pr-6">Branch Location:</span>{" "}
        <span>Anamnagar, Kathmandu</span>
      </p>
      <p>
        <span className="font-weight-bold pr-6">Account Number:</span>{" "}
        <span>3004150070983002</span>
      </p>
      <p>
        <span className="font-weight-bold pr-6">Account Name:</span>{" "}
        <span>GHARGHARMA DOCTOR PRIVATE LIMITED</span>
      </p>
    </div>
  );
};

export default AccountDetails;
