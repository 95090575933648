import {KeyboardArrowRightOutlined} from '@mui/icons-material'
import {Avatar, Button, Card, CardContent, CardHeader, Stack, Typography} from '@mui/material'
import React from 'react'
import {useNavigate} from 'react-router-dom'

function LabTestItem({data}) {
    const navigate = useNavigate()

    function handleClick() {
        navigate('/lab-tests/item', {state: {data: data, type: 'test'}})
    }

    return (
        <Card sx={{minWidth: '90px', minWidth: '200px'}}>
            <CardHeader avatar={<Avatar src="https://assets.pharmeasy.in/web-assets/dist/51ffd21a.svg"/>}
                        title={data.tests} titleTypographyProps={{fontWeight: 550, variant: 'h6'}}/>
            <CardContent sx={{pt: 0}}>
                <Stack direction={'row'} justifyContent='space-between'>
                    <Typography variant='h6' fontWeight={550}>NPR {data.price}</Typography>
                    <Button endIcon={<KeyboardArrowRightOutlined/>} onClick={handleClick}>Book Now</Button>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default LabTestItem
