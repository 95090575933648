import {
    Alert,
    AlertTitle,
    Box,
    Card,
    Grid,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme
} from '@mui/material'
import React from 'react'
import {useContext} from 'react'
import {Doughnut} from 'react-chartjs-2'
import Insurance from './Insurance'
import {InsuranceContext} from './InsuranceComponent'

const getDataSet = (data, palette) => {
    let zeroGraph = false;
    data.every((item, idx) => {
        if (item !== 0) {
            zeroGraph = false
            return false
        }
        zeroGraph = true
        return true
    })

    if (zeroGraph) {
        return {
            labels: [
                'No Data'
            ],
            datasets: [{
                label: 'Amount',
                data: ['4380000'],
                backgroundColor: [
                    'gray'
                ],
                hoverOffset: 4,
                weight: 1
            }]
        }
    }

    return {
        labels: [
            'Accidental Death',
            'Accidental Disability',
            'Critical Illness',
            'Accidenta Medicin Reimbursements',
            'Accidental Weekly Indemnity',
            'Medical OPD and Hospitalization',
            'Funeral and Dead Body Management'
        ],
        datasets: [{
            label: 'Amount',
            data: data,
            backgroundColor: [
                palette.error.dark,
                palette.primary.dark,
                palette.warning.dark,
                palette.success.dark,
                palette.primary.light,
                palette.warning.light,
                palette.success.light,
            ],
            hoverOffset: 4,
            weight: 1
        }]
    }
}

const getSumArray = (data) => {
    let sum = 0;
    data.every((item) => {
        sum += Number(item)
        return true
    })
    return sum
}

function getDataConfig(data, key, label, palette, total) {
    let dataLabel = data.map((item) => item[label])
    let dataValue = data.map((item) => item[key])

    if (dataValue.every(currentValue => currentValue === 0)) {
        return {
            labels: [
                'No Data'
            ],
            datasets: [{
                label: 'Amount',
                data: ['4380000'],
                backgroundColor: [
                    'gray'
                ],
                hoverOffset: 4,
                weight: 1
            }]
        }
    }

    return {
        labels: dataLabel,
        datasets: [{
            label: 'Amount',
            data: dataValue,
            backgroundColor: [
                palette.error.dark,
                palette.primary.dark,
                palette.warning.dark,
                palette.success.dark,
                palette.primary.light,
                palette.warning.light,
                palette.success.light,
            ],
            hoverOffset: 4,
            weight: 1
        }]
    }
}

function InsuranceDet() {
    const palette = useTheme().palette
    const {myinsurancedetails, pg} = useContext(InsuranceContext)

    let totalData = getDataConfig(myinsurancedetails.insurance, 'total', 'type', palette, myinsurancedetails.total)
    let claimedData = getDataConfig(myinsurancedetails.insurance, 'claimed', 'type', palette, myinsurancedetails.total)
    let remainingData = getDataConfig(myinsurancedetails.insurance, 'remaining', 'type', palette, myinsurancedetails.total)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Alert severity='success' sx={{justifyContent: 'center'}}>
                    <AlertTitle>
                        Insurance duration ends with Membership ending
                    </AlertTitle>
                </Alert>
            </Grid>

            <Grid item xs={12}>
                <InsuranceContainer title='Insurance infograph'>
                    <Box display='flex' flexWrap={'wrap'} gap={2}>
                        <Box flexBasis='350px' flexGrow={1}>
                            <InfographItem infoLabel='Total Insurance' amount={myinsurancedetails.total}
                                           color={palette.success.light} data={totalData}/>
                        </Box>
                        <Box flexBasis='350px' flexGrow={1}>
                            <InfographItem infoLabel='Total Claimed' amount={myinsurancedetails.claimed}
                                           color={palette.error.light} data={claimedData}/>
                            {/* <InfographItem infoLabel='Total Claimed' amount={4380000 - myinsurancedetails.total_insurance_amount} color={palette.error.light} data={data2} /> */}
                        </Box>
                        <Box flexBasis='350px' height='100%' flexGrow={1}>
                            {/* <InfographItem infoLabel='Remaining' amount={myinsurancedetails.total_insurance_amount} color={palette.primary.dark} data={data3} /> */}
                            <InfographItem infoLabel='Remaining' amount={myinsurancedetails.remaining}
                                           color={palette.primary.dark} data={remainingData}/>
                        </Box>
                    </Box>
                </InsuranceContainer>
            </Grid>

            <Grid item xs={12}>
                <InsuranceContainer title='Insurance Details'>
                    <TableContainer component={Paper}>
                        <Table className='border'>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 700}}>Insurance Criteria</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Total Amount</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Claimed</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Remaining Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    myinsurancedetails.insurance.map((item, idx) => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                {item.type}
                                            </TableCell>
                                            <TableCell sx={{color: palette.success.light}}>
                                                Rs. {item.total}
                                            </TableCell>
                                            <TableCell sx={{color: palette.error.light}}>
                                                Rs. {item.claimed}
                                            </TableCell>
                                            <TableCell sx={{color: palette.primary.light}}>
                                                Rs. {item.remaining}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                <TableRow>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell sx={{
                                        color: palette.success.light,
                                        fontWeight: 900
                                    }}>Rs. {myinsurancedetails.total}</TableCell>
                                    <TableCell sx={{
                                        color: palette.error.light,
                                        fontWeight: 900
                                    }}>Rs. {myinsurancedetails.claimed}</TableCell>
                                    <TableCell sx={{
                                        color: palette.primary.light,
                                        fontWeight: 900
                                    }}>Rs. {myinsurancedetails.remaining}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </InsuranceContainer>
            </Grid>

            <Grid item xs={12}>

                <Insurance/>
            </Grid>
        </Grid>
    )
}

function InsuranceContainer({title, children}) {
    return (
        <Box>
            <Box py={1}>
                <Typography variant='h4' sx={{fontWeight: '600 !important'}}>
                    {title}
                </Typography>
                <Box>
                    {children}
                </Box>
            </Box>
        </Box>
    )
}

function InfographItem({infoLabel, amount, color, data}) {
    return (
        <Card sx={{py: 2, px: 4, color: color, borderRadius: '10px', height: '100%'}} elevation={4}>
            <Box display={'flex'} alignItems={'center'} gap={4}>
                <Box width='130px' flexShrink={0} flexGrow={0}>
                    <Doughnut data={data} options={{
                        plugins: {
                            legend: {
                                display: false
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: true,
                        cutout: '60%',
                    }}/>
                </Box>
                <Stack flexGrow={1} sx={{textAlign: 'end'}}>
                    <Typography variant='h6' fontSize={'18px !important'}
                                sx={{margin: '0 !important', padding: '0 !important'}}>
                        {infoLabel}
                    </Typography>
                    <Typography variant='h6' fontSize={'24px !important'} fontWeight={700}
                                sx={{margin: '0 !important', padding: '0 !important'}}>
                        Rs. <b>{amount}</b>
                    </Typography>
                </Stack>
            </Box>
        </Card>
    )
}

export default InsuranceDet
