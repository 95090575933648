import http from "../../utils/http/http.utils";

class ConsultationService {
    static async getConsultation(id) {
        try {
            return http().post(`/api/online-consultation-meeting/${id}`)
        }catch (e) {
            console.log(e)
        }
    }
}

export default  ConsultationService
