import React from 'react'
import {useChkLogin} from '../../../../middlewares/LoginMiddleware'
import {useQuery} from '@tanstack/react-query'
import axios from '../../../api'
import {Alert} from '@mui/material'
import {useNavigate} from 'react-router-dom'

function StudentUnpaid() {
    const navigate = useNavigate()
    const {data, token} = useChkLogin()
    const {data: familyData, isLoading: familyLoading} = useQuery(
        ['family-data', token],
        async () => axios.get('/api/admin/addfamily', {headers: {"Authorization": `Bearer ${token}`}}).then(res => res.data)
    )
    if (familyLoading) return

    const unpaidStd = familyData.filter((item) => item.payment_status === 0)

    function handleAdditionalPayment() {
        let selected = unpaidStd.map((item) => item.id)
        navigate('/user/family/payment', {state: {selected: selected, type: 0, redirectLink: '/school/student-lists'}})
    }

    return unpaidStd.length > 0 && (
        <Alert severity='error' className='shadow'>
            <div>
                <span className='fs-6'>
                    Unpaid Students
                </span>
            </div>
            <div>
                <span className="fs-6 fw-light">
                    Please pay the fees of these students before proceeding to next step!
                </span>
            </div>
            <div className='gap-2 d-flex align-items-center'>
                <span className="fw-light fs-6">
                    Additional Students:
                </span>
                <span className="fs-5 fw-bold">
                    {unpaidStd?.length ?? ''}
                </span>
            </div>
            <div className='mt-2'>
                <button className="shadow-sm btn btn-primary" onClick={handleAdditionalPayment}>
                    Pay Now
                </button>
            </div>
        </Alert>
    )
}

export default StudentUnpaid
