import http from "../utils/http/http.utils";
import setFormData from "../utils/http/formdata.utils";
import {filterFormData} from "../utils/common/common.utils";

class SchoolPaymentVerificationService {

    /*
    * payment verification method for school
    * */
    static submitPayment(data) {
        const formData = setFormData(data)
        try {
            return http().post('api/company-school-profile/payments', filterFormData(formData))
        } catch (e) {
            console.log(e)
        }
    }

    /*
    * fetch information related to payment
    * */
    static async fetchPaymentInformation() {
        try {
            const response = await http().get('api/company-school-profile/payment-information');
            return await response?.data?.data
        } catch (e) {
            console.log(e)
        }
    }


    /*
   * fetch payment history details
   * */
    static async fetchPaymentHistory() {
        try {
            const response = await http().get('api/company-school-profile/payments')
            return await response?.data
        } catch (e) {
            console.log(e)
        }
    }


}

export default SchoolPaymentVerificationService
