import profilePic from "../../images/blankProfile.webp";
import {Rating} from "@mui/material";

const ReviewListItem = (props) => {
    const review = props.review;

    return (
        <div className="prod-review-list-item mt-4">
            <div className="prod-review-list-head d-flex align-items-center">
                <div className="head-prof-img">
                    <img src={profilePic} alt="Profile Picture"/>
                </div>
                <div className="head-prof-name px-3">
                    <span className="prof-name-txt">{review.member && review.member.user.name}</span>
                </div>
            </div>
            <div className="prod-review-list-rating">
                <div className="d-flex align-items-center">
                    <Rating name="half-rating-read" value={review.rating} readOnly/>
                    <b className="mx-3 text-secondary">2/12/2020</b>
                </div>
            </div>
            <div className="prod-review-list-review mt-2 p-2">
                {review.comment}
            </div>
        </div>
    );
};

export default ReviewListItem;
