import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./TermsandCondition.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";
import VaccinationService from "../../../services/vaccination.service";
import TermsAndConditionService from "../../../services/termsandcondition.service";
import { DATEFORMAT } from "../../../utils/constant/common";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

export default function TermsandConditions() {
  const location = useLocation();

  let mybutton = document.getElementById("myBtn");
  window.onscroll = function() {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  const { data } = useQuery({
    queryKey: ["terms-and-condition", location?.search],
    queryFn: () => TermsAndConditionService.get(),
  });

  const [type, setType] = useState(location?.search);

  useEffect(() => {
    setType(location?.search);
  }, [location?.search]);

  console.log(data?.data, "data");

  return (
    <div>
      <Box className="termsandcondition">
        {/* <img src={Page404} alt="" /> */}
        <h1>Conditions of Use </h1>
        <h3>
          Last updated: {dayjs(data?.data?.updated_at).format(DATEFORMAT)}{" "}
        </h3>

        <div dangerouslySetInnerHTML={{ __html: data?.data?.details }} />

        <button onClick={topFunction} id="myBtn">
          <FontAwesomeIcon icon={faCircleArrowUp} />
        </button>
      </Box>
    </div>
  );
}
