import React, { useContext, useEffect, useRef, useState } from "react";
import { createContext } from "react";
import PhoneInput from "react-phone-input-2";
import { grey } from "@mui/material/colors";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import classNames from "classnames";
import * as dayjs from "dayjs";

export function InputField({
  name,
  type = "text",
  id,
  value,
  label,
  className,
  onChange,
  onClick,
  error,
  errorMsg,
  required,
  disabled,
  appendContent,
  inputProps,
  isPasswordToggleable = false,
  placeholder,
}) {
  const [show, setShow] = useState(false);

  const handleInputChange = (event) => {
    const { value } = event.target;

    // Checking if name is "pan_number" or "ward_number" and filter out negative numbers
    if (
      (name === "pan_number" || name === "ward_no" || name === "ward_number") &&
      value !== "" &&
      Number(value) < 1
    ) {
      return;
    }
    // Propagate the change event
    if (onChange) {
      onChange(event);
    }
  };

  const renderPasswordToggle = () => {
    if (isPasswordToggleable) {
      return (
        <div style={{ cursor: "pointer" }} onClick={() => setShow(!show)}>
          <div
            aria-label="toggle password visibility"
            style={{
              position: "absolute",
              width: 32,
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 22,
            }}
          >
            {show ? <Visibility /> : <VisibilityOff />}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="form-group">
      <label className="form-label">
        {label}
        <code>{required ? "*" : "(Optional)"}</code>
      </label>
      <div
        className={classNames("input-group", {
          "password-toggleable": isPasswordToggleable,
        })}
        style={{ position: "relative" }}
      >
        {type === "phone" && (
          <div
            style={{
              fontSize: 14,
              position: "absolute",
              left: 0,
              background: grey[50],
              top: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 48,
              color: "#333",
              zIndex: 22,
              border: "1px solid #dee2e6",
              borderLeft: 0,
            }}
          >
            +977
          </div>
        )}

        <input
          type={
            type === "password" && show
              ? "text"
              : type === "phone"
              ? "tel"
              : type
          }
          id={id}
          style={{ paddingLeft: type === "phone" ? 56 : 12 }}
          className={`form-control ${className}`}
          onClick={onClick}
          name={name}
          value={value}
          onChange={handleInputChange}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
        />
        {type === "password" && isPasswordToggleable && renderPasswordToggle()}
        {!!appendContent && (
          <div className="input-group-append">
            <span className="input-group-text">{appendContent}</span>
          </div>
        )}
      </div>
      {error && <div className="text-danger">{errorMsg}</div>}
    </div>
  );
}

export function NepaliDateInput({
  label,
  name,
  id,
  value,
  className,
  onChange,
  error,
  errorMsg,
  required,
  disabled,
  max = null,
  inputProps,
}) {
  const dateInputRef = useRef();

  useEffect(() => {
    dateInputRef.current.nepaliDatePicker({ dateFormat: "YYYY-MM-DD" });
  }, []);

  return (
    <div className="form-group">
      <label className="form-label">
        {label}
        <code>{required ? "*" : "(Optional)"}</code>
      </label>
      <input
        ref={dateInputRef}
        type="date"
        id={id}
        className={`form-control`}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        max={max}
      />
      {error && <div className="text-danger">{errorMsg}</div>}
    </div>
  );
}

export function SelectInput({
  name,
  id,
  value,
  label,
  className,
  onChange,
  error,
  errorMsg,
  required,
  children,
}) {
  return (
    <div className="form-group">
      <label className="form-label">
        {label}
        <code>{required ? "*" : "Optional"}</code>
      </label>
      <select
        name={name}
        className={`form-select ${className}`}
        value={value}
        onChange={onChange}
        required={required}
      >
        <option value="" disabled>
          --Select--
        </option>
        {children}
      </select>
      {error && <div className="alert alert-danger">{errorMsg}</div>}
    </div>
  );
}

export function SelectOption({ name, id, value, className, children }) {
  return (
    <option id={id} value={value}>
      {children}
    </option>
  );
}

const RadioContext = createContext(null);

export function RadioInputContain({
  name,
  children,
  required,
  label,
  direction = "row",
}) {
  return (
    <RadioContext.Provider value={{ name: name, required: required }}>
      <div className="form-group">
        <label className="form-label">
          <h5>{label}</h5>
        </label>
        <div className={`d-flex flex-${direction}`}>{children}</div>
      </div>
    </RadioContext.Provider>
  );
}

export function RadioInput({ id, value, type, label, checked, onChange }) {
  const radioContext = useContext(RadioContext);
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type={type}
        value={value}
        name={radioContext.name}
        id={id}
        required={radioContext.required}
        checked={checked}
        onChange={(e) => {
          console.log();
          onChange(e);
        }}
      />
      <label className="form-check-label me-4" htmlFor={id}>
        <h6>{label}</h6>
      </label>
    </div>
  );
}

export function PhoneNumberInput({
  country,
  value,
  onChange,
  required,
  error,
  errorMsg,
}) {
  return (
    <>
      <PhoneInput
        country={country}
        value={value}
        onChange={onChange}
        required
      />
      {error && <div className="text-danger">{errorMsg}</div>}
    </>
  );
}
