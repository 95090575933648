import {Box, Button, Modal, Stack, TextField, Typography} from '@mui/material'
import React, {useRef} from 'react'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function CancelReasonModal({open, handleClose, setCancelReason}) {
    const cancelRef = useRef(null)
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <TextField label="Cancel Reason" inputRef={cancelRef} fullWidth/>
                <Stack direction={'row'} mt={2}>
                    <Button variant='outlined' color="success" sx={{mr: 1}}>
                        Save
                    </Button>
                    <Button variant='contained' color="error" onClick={handleClose}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Modal>
    )
}

export default CancelReasonModal
