import {useQuery} from '@tanstack/react-query';
import React from 'react'
import {useParams} from 'react-router-dom';
import axios from "../../../api"
import Loader from '../../Loader';
import ConnectIPS from "../../../../images/payment/connectips.png"
import Khalti from "../../../../images/payment/khalti.png"
import Esewa from "../../../../images/payment/esewa.png"
import IMEPay from "../../../../images/payment/imepay.png"
import KhaltiNurse from '../../khalti/KhaltiNurse'
import {useContext} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'

const MyNurseAppointmentDetail = () => {
    const params = useParams()
    const loginContext = useContext(LoginStateContext)

    const nurseAppointmentQuery = useQuery(
        [`nurse-appointment-data-${params.slug}`],
        async () => {
            return axios.get('/api/admin/nurses/bookings?slug=' + params.slug, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )

    if (nurseAppointmentQuery.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }
    const appointment = nurseAppointmentQuery.data;
    console.log()

    return (
        <div className='row'>
            <div className="col-md-6 my-2">
                <div className="card h-100">
                    <div className="card-body">
                        <div className="d-flex flex-column align-items-center">
                            <div style={{borderRadius: '50%', width: '120px', height: '120px', overflow: 'hidden'}}>
                                <img src={appointment.shift.nurse.image_path} alt="" className='img-fluid'/>
                            </div>
                            <div className="text-center">
                                <h3 className='text-primary font-weight-bold m-0 '>{appointment.shift.nurse.user.name}</h3>
                            </div>
                        </div>

                        <div className="row w-100 mt-3">
                            <div className="col-6 d-flex align-items-center">
                                <h6 className="font-weight-bold text-primary font-weight-light m-0">
                                    NNC No.:
                                </h6>
                                <h6 className="mb-0 ms-2">
                                    {appointment.shift.nurse.nnc_no}
                                </h6>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <h6 className="font-weight-bold text-primary font-weight-light m-0">
                                    Nurse Type:
                                </h6>
                                <h6 className="mb-0 ms-2">
                                    {appointment.shift.nurse.nurse_type}
                                </h6>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <h6 className="font-weight-bold text-primary font-weight-light m-0">
                                    Gender:
                                </h6>
                                <h6 className="mb-0 ms-2">
                                    {appointment.shift.nurse.gender}
                                </h6>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <h6 className="font-weight-bold text-primary font-weight-light m-0">
                                    Qualification:
                                </h6>
                                <h6 className="mb-0 ms-2">
                                    {appointment.shift.nurse.qualification}
                                </h6>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <h6 className="font-weight-bold text-primary font-weight-light m-0">
                                    Experience:
                                </h6>
                                <h6 className="mb-0 ms-2">
                                    {appointment.shift.nurse.year_practiced} years
                                </h6>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <h6 className="font-weight-bold text-primary font-weight-light m-0">
                                    Fee:
                                </h6>
                                <h6 className="mb-0 ms-2">
                                    NPR. {appointment.shift.nurse.fee}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 my-2">
                <div className="card h-100">
                    <div className="card-body">
                        <h4><b>Booking Details</b></h4>
                        <table className="table table-bordered">
                            <tbody>
                            <tr>
                                <th className='fontStyle'>Booked Date</th>
                                <td>{appointment.shift.date}</td>
                            </tr>
                            <tr>
                                <th className='fontStyle'>Shift</th>
                                <td>{appointment.shift.shift}</td>
                            </tr>
                            <tr>
                                <th className='fontStyle'>Booking Status</th>
                                <td>{appointment.booking_status}</td>
                            </tr>
                            <tr>
                                <th className='fontStyle'>Message</th>
                                <td>{appointment.messages}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-md-12 my-2">
                <div className="card payment-details h-100">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4><b>Payment Details</b></h4>
                            {appointment.status === 0 ?
                                <span className="badge badge-warning p-2">
                                    Payment Pending
                                </span>
                                :
                                <span className="badge badge-success p-2">
                                    Payment Completed
                                </span>
                            }
                        </div>
                        {appointment.status === 0 ?
                            <div className="pay-with">
                                <div className="payment-amount my-3">
                                    <span className="amount-label"><b>Total Amount:</b></span>
                                    <span className="ms-3">NPR. {appointment.shift.nurse.fee}</span>
                                </div>
                                <small className='text-info'>Can only be paid with Khalti at the moment</small>
                                <h5><b>Pay With:</b></h5>
                                <div className="d-flex flex-wrap align-items-center">
                                    <div className="payment-item">
                                        <img src={Esewa} alt="Esewa"/>
                                    </div>
                                    <div className="payment-item ms-2 d-flex align-items-center">
                                        <KhaltiNurse amount={appointment.shift.nurse.fee} id={appointment.id}
                                                     redirectLink={window.location.pathname}/>
                                    </div>
                                    <div className="payment-item ms-2">
                                        <img src={ConnectIPS} alt="Esewa"/>
                                    </div>
                                    <div className="payment-item ms-2">
                                        <img src={IMEPay} alt="Esewa"/>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="payment-details">
                                <div className="payment-amount my-3">
                                    <span className="amount-label"><b>Total Amount Paid:</b></span>
                                    <span className="ms-3">NPR. {appointment.shift.nurse.fee}</span>
                                </div>
                                <div className="paid-with d-flex align-items-center">
                                    <span className="paid-with-label"><b>Paid With:</b></span>
                                    <div className="payment-item ms-4">
                                        {appointment.payment_method === 'Khalti' &&
                                            <img src={Khalti} alt="Khalti"/>
                                        }
                                    </div>
                                </div>
                                <div className="paid-at mt-2">
                                    <span className="paid-at-label"><b>Paid At: </b></span>
                                    <span>{appointment.payment_date}</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {appointment.reports.length !== 0 &&
                <div className="col-md-12 my-2">
                    <div className="card h-100">
                        <div className="card-header text-center ">
                            <h4 className='card-title'><b>Nursing Record</b></h4>
                        </div>
                        <div className="card-body">
                            <div className="row my-3">
                                <div className="col-md-4">
                                    <p><span className="text-primary"
                                             style={{fontWeight: '700'}}>Name: </span>{appointment.member.user.name}</p>
                                    <p><span className="text-primary"
                                             style={{fontWeight: '700'}}>Sex: </span>{appointment.member.gender}</p>
                                    <p><span className="text-primary"
                                             style={{fontWeight: '700'}}>Blood Group: </span>{appointment.member.blood_group}
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <p><span className="text-primary"
                                             style={{fontWeight: '700'}}>Contact No.: </span>{appointment.member.user.phone}
                                    </p>
                                    <p><span className="text-primary"
                                             style={{fontWeight: '700'}}>DOB: </span>{appointment.member.dob}</p>
                                    <p><span className="text-primary"
                                             style={{fontWeight: '700'}}>Height: </span>{appointment.member.height}</p>
                                </div>
                                <div className="col-md-4">
                                    <p><span className="text-primary"
                                             style={{fontWeight: '700'}}>Address: </span>{appointment.member.address}
                                    </p>
                                    <p><span className="text-primary"
                                             style={{fontWeight: '700'}}>Age: </span>{appointment.member.dob ? new Date().getFullYear() - appointment.member.dob.slice(0, 4) : ''}
                                    </p>
                                    <p><span className="text-primary"
                                             style={{fontWeight: '700'}}>Weight: </span>{appointment.member.weight}</p>
                                </div>
                            </div>
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>P./min</th>
                                    <th>R.R/min</th>
                                    <th>T.°C</th>
                                    <th>BP. mmHg</th>
                                    <th>Nursing Report</th>
                                </tr>
                                </thead>
                                <tbody>
                                {appointment.reports.map((report) =>
                                    <tr>
                                        <td>{report.date}</td>
                                        <td>{report.time}</td>
                                        <td>{report.pulse_rate}</td>
                                        <td>{report.respiratory_rate}</td>
                                        <td>{report.temperature}</td>
                                        <td>{report.bp}</td>
                                        <td dangerouslySetInnerHTML={{__html: `${report.description}`}}
                                            width='45%'></td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default MyNurseAppointmentDetail
