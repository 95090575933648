import { Link, useLocation } from "react-router-dom";
import { useFamilyNotify } from "./DashboardProvider";
import { useChkLogin } from "../../../middlewares/LoginMiddleware";
import InsuranceInfo from "../../insuranceInfo";

const SideBar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { memberCount, familyReqCount } = useFamilyNotify();
  const loginObj = useChkLogin();
  return (
    <div className="sidebar sidebar-dark sidebar-main sidebar-expand-lg">
      {/* Sidebar content */}
      <div className="sidebar-content">
        {/* User menu */}
        <div className="sidebar-section sidebar-user my-1 ml-3 align-self-center">
          <button
            type="button"
            className="btn btn-outline-light-100 text-white border-transparent btn-icon rounded-pill btn-sm sidebar-mobile-main-toggle d-lg-none"
          >
            <i className="icon-cross2" />
          </button>
        </div>
        {/* /user menu */}
        {/* Main navigation */}
        {loginObj.data.school_profile == null ||
        loginObj.data.school_profile?.types == "corporate" ? (
          <div className="sidebar-section">
            <ul className="nav nav-sidebar" data-nav-type="accordion">
              {/* Main */}
              <li className="nav-item-header">
                <div className="text-uppercase font-size-xs line-height-xs">
                  Main
                </div>{" "}
                <i className="icon-menu" title="Main" />
              </li>
              <li className="nav-item">
                <Link
                  to="/"
                  className={`nav-link ${pathname == "/" && "active"}`}
                >
                  <i className="icon-home4" />
                  <span>HOME</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/user"
                  className={`nav-link ${pathname == "/user" && "active"}`}
                >
                  <i className="icon-home4" />
                  <span>USER PROFILE</span>
                </Link>
              </li>
              {loginObj.data.school_profile !== null && (
                <li className="nav-item">
                  <Link
                    to="/user/view-corporate-profile"
                    className={`nav-link align-items-center ${pathname ==
                      "/user/view-corporate-profile" && "active"}`}
                  >
                    <i className="icon-users" /> <span>School Profile</span>
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link
                  to="/user/family"
                  className={`nav-link ${pathname == "/user/family" &&
                    "active"}`}
                >
                  <i className="icon-users" />{" "}
                  <span>
                    My Family &nbsp;
                    <span className="badge badge-warning">
                      {familyReqCount > 0 && familyReqCount}
                    </span>
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/user/mypackages"
                  className={`nav-link ${pathname == "/user/mypackages" &&
                    "active"}`}
                >
                  <i className="icon-calendar" />{" "}
                  <span>Subscription Package</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/user/payment-history"
                  className={`nav-link ${pathname == "/user/payment-history" &&
                    "active"}`}
                >
                  <i className="icon-calendar" /> <span>Payment History</span>
                </Link>
              </li>
              <li
                className={`nav-item nav-item-submenu ${pathname.includes(
                  "/user/lab-reports"
                ) && "nav-item-open"}`}
              >
                <a
                  href="/"
                  className={`nav-link ${pathname.includes(
                    "/user/lab-reports"
                  ) && "nav-item-open active"}`}
                >
                  <i className="icon-calendar"></i> <span>Medical Report</span>
                </a>

                <ul
                  className="nav nav-group-sub"
                  data-submenu-title="Layouts"
                  style={{
                    display: pathname.includes("/user/lab-reports")
                      ? "block"
                      : "none",
                  }}
                >
                  <li className="nav-item">
                    <Link
                      to="/user/lab-reports/pathology-screening"
                      className={`nav-link ${pathname.includes(
                        "/user/lab-reports/pathology-screening"
                      ) && "active"}`}
                    >
                      <i className="icon-dash"></i>
                      <span>Pathology Screening</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/user/lab-reports/labtest-bookings"
                      className={`nav-link ${pathname.includes(
                        "/user/lab-reports/labtest-bookings"
                      ) && "active"}`}
                    >
                      <i className="icon-dash"></i>
                      <span>Lab Test Report</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/user/lab-reports/medical-history"
                      className={`nav-link ${pathname.includes(
                        "/user/lab-reports/medical-history"
                      ) && "active"}`}
                    >
                      <i className="icon-dash"></i>
                      <span>External Medical Details</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`nav-item`}>
                <Link
                  to="/user/consultation-history"
                  className={`nav-link ${pathname ==
                    "/user/consultation-history" && "active"}`}
                >
                  <i className="icon-calendar" />{" "}
                  <span>Consultation History</span>
                </Link>
              </li>
              <li
                className={`nav-item nav-item-submenu ${pathname.includes(
                  "/user/insurance"
                ) && "nav-item-open"}`}
              >
                <a
                  href="/"
                  className={`nav-link ${pathname.includes("/user/insurance") &&
                    "nav-item-open active"}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-calendar"></i>
                  <div className="d-flex align-items-center">
                    <span>My Insurance</span> <InsuranceInfo />
                  </div>
                </a>

                <ul
                  className="nav nav-group-sub"
                  data-submenu-title="Layouts"
                  style={{
                    display: pathname.includes("/user/insurance")
                      ? "block"
                      : "none",
                  }}
                >
                  <li className="nav-item">
                    <Link
                      to="/user/insurance/details"
                      className={`nav-link ${pathname.includes(
                        "/user/insurance/details"
                      ) && "active"}`}
                    >
                      <i className="icon-dash"></i>
                      <span>Insurance Details</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/user/insurance/claim-settlement"
                      className={`nav-link ${pathname.includes(
                        "/user/insurance/claim-settlement"
                      ) && "active"}`}
                    >
                      <i className="icon-dash"></i>
                      <span>Claim Settlement</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`nav-item nav-item-submenu ${pathname.includes(
                  "/user/appointments"
                ) && "nav-item-open"}`}
              >
                <a
                  href="/"
                  className={`nav-link ${pathname.includes(
                    "/user/appointments"
                  ) && "nav-item-open active"}`}
                >
                  <i className="icon-calendar"></i> <span>My Appointments</span>
                </a>

                <ul
                  className="nav nav-group-sub"
                  data-submenu-title="Layouts"
                  style={{
                    display: pathname.includes("/user/appointments")
                      ? "block"
                      : "none",
                  }}
                >
                  <li className="nav-item">
                    <Link
                      to="/user/appointments/doctor"
                      className={`nav-link ${pathname.includes(
                        "/user/appointments/doctor"
                      ) && "active"}`}
                    >
                      <i className="icon-dash"></i>
                      <span>Doctor Appointments</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/user/appointments/nurse"
                      className={`nav-link ${pathname.includes(
                        "/user/appointments/nurse"
                      ) && "active"}`}
                    >
                      <i className="icon-dash"></i>
                      <span>Nurse Appointments</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  to="/user/orders"
                  className={`nav-link ${pathname == "/user/orders" &&
                    "active"}`}
                >
                  <i className="icon-calendar" /> <span>My Orders</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/user/refer-friend"
                  className={`nav-link ${pathname == "/user/refer-friend" &&
                    "active"}`}
                >
                  <i className="icon-calendar" /> <span>Refer A Friend</span>
                </Link>
              </li>
              {/* <li className="nav-item">
              <Link
                to="/user/lab-test"
                className={`nav-link ${pathname == "/user/lab-test" && 'active'}`}
              >
                <i className="icon-calendar" /> <span>Lab Test Record</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/user/medical-history"
                className={`nav-link ${pathname == "/user/medical-history" && 'active'}`}
              >
                <i className="icon-calendar" /> <span>External Medical History</span>
              </Link>
            </li> */}
              <li className="nav-item">
                <Link
                  to="/user/calorie-intake"
                  className={`nav-link ${pathname == "/user/calorie-intake" &&
                    "active"}`}
                >
                  <i className="icon-calendar" /> <span>Calorie Intake</span>
                </Link>
              </li>
              {/* <li className="nav-item nav-item-submenu">
              <a href="#" className="nav-link">
                <i className="icon-color-sampler" /> <span>Themes</span>
              </a>
              <ul className="nav nav-group-sub" data-submenu-title="Themes">
                <li className="nav-item">
                  <a href="index.html" className="nav-link active">
                    Default
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://demo.interface.club/limitless/demo/Template/layout_1/LTR/material/full/index.html"
                    className="nav-link"
                  >
                    Material
                  </a>
                </li>
              </ul>
            </li> */}
              {/* /main */}
            </ul>
          </div>
        ) : (
          <div className="sidebar-section">
            <ul className="nav nav-sidebar" data-nav-type="accordion">
              {loginObj.data.school_profile !== null && (
                <>
                  <li className="nav-item">
                    <Link
                      to="/school"
                      className={`nav-link ${pathname == "/school" &&
                        "active"}`}
                    >
                      <i className="icon-users" /> <span>School Profile</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/school/student-lists"
                      className={`nav-link align-items-center ${pathname ==
                        "/school/student-lists" && "active"}`}
                    >
                      <i className="icon-users" /> <span>Student Lists</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/school/school-package"
                      className={`nav-link align-items-center ${pathname ==
                        "/school/school-package" && "active"}`}
                    >
                      <i className="icon-users" /> <span>School Package</span>
                    </Link>
                  </li>

                  <li
                    className={`nav-item nav-item-submenu ${pathname.includes(
                      "/school/deactivate"
                    ) && "nav-item-open"}`}
                  >
                    <a
                      href="/"
                      className={`nav-link align-items-center school-deactivation ${pathname.includes(
                        "/school/deactivate"
                      ) && "nav-item-open active"}`}
                    >
                      <i className="icon-calendar"></i>{" "}
                      <span>Deactivation</span>
                    </a>

                    <ul
                      className="nav nav-group-sub"
                      data-submenu-title="Layouts"
                      style={{
                        display: pathname.includes("/school/deactivate")
                          ? "block"
                          : "none",
                      }}
                    >
                      <li className="nav-item">
                        <Link
                          to="/school/deactivate/requests"
                          className={`nav-link ${pathname.includes(
                            "/school/deactivate/requests"
                          ) && "active"}`}
                        >
                          <i className="icon-dash"></i>
                          <span>Deactivate Requests</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/school/deactivate/students"
                          className={`nav-link ${pathname.includes(
                            "/school/deactivate/students"
                          ) && "active"}`}
                        >
                          <i className="icon-dash"></i>
                          <span>Deactivated Students List</span>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={`nav-item nav-item-submenu ${pathname.includes(
                      "/parent/insurance"
                    ) && "nav-item-open"}`}
                  >
                    <a
                      href="/"
                      className={`nav-link align-items-center ${pathname.includes(
                        "/parent/insurance"
                      ) && "nav-item-open active"}`}
                    >
                      <i className="icon-insurance"></i> <span>Insurance</span>
                    </a>

                    <ul
                      className="nav nav-group-sub"
                      data-submenu-title="Insurance"
                      style={{
                        display: pathname.includes("/parent/insurance")
                          ? "block"
                          : "none",
                      }}
                    >
                      {/* <li className="nav-item">
                        <a
                          href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                          download
                          className="nav-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="icon-dash"></i>
                          <span>Insurance Claim Form</span>
                        </a>
                      </li> */}
                      <span>
                        <Link
                          to="/school/insurance-claim"
                          className={`nav-link `}
                        >
                          <i className="icon-dash"></i>
                          <span>Insurance Claim Instruction</span>
                        </Link>
                      </span>
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </div>
        )}

        {/* /main navigation */}
      </div>
      {/* /sidebar content */}
    </div>
  );
};

export default SideBar;
