/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import React, { useState } from "react";

const ParentSidebar = () => {
  const [isSubmenuVisible, setSubmenuVisible] = useState(false);

  const toggleSubmenu = () => {
    setSubmenuVisible(!isSubmenuVisible);
  };
  return (
    <div className="sidebar sidebar-dark sidebar-main sidebar-expand-lg">
      <div className="sidebar-content">
        <div className="sidebar-section sidebar-user my-1 ml-3 align-self-center">
          <button
            type="button"
            className="btn btn-outline-light-100 text-white border-transparent btn-icon rounded-pill btn-sm sidebar-mobile-main-toggle d-lg-none"
          >
            <i className="icon-cross2" />
          </button>
        </div>
        {
          <div className="sidebar-section">
            <ul className="nav nav-sidebar">
              <li className={"text-white py-3"}>
                <a href="#" className={`nav-link align-items-center`}>
                  <i className="icon-calendar"></i> <span>Medical Report</span>
                </a>
                <ul className="nav nav-group-sub" data-submenu-title="Layouts">
                  <li className="nav-item">
                    <Link
                      to="/parent"
                      className={`nav-link align-items-center`}
                    >
                      <i className="icon-dash"></i>
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/parent/children"
                      className={`nav-link align-items-center`}
                    >
                      <i className="icon-dash"></i>
                      <span>Children</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/parent/medical-history"
                      className={`nav-link align-items-center`}
                    >
                      <i className="icon-dash"></i>
                      <span>Medical History</span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/parent/vaccination"
                      className={`nav-link align-items-center`}
                    >
                      <i className="icon-dash"></i>
                      <span>Vaccination</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/parent/consultation-history"
                      className={`nav-link`}
                    >
                      <i className="icon-dash"></i>
                      <span>Consultation History</span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <div
                      onClick={toggleSubmenu}
                      className="nav-link align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <i className="icon-dash"></i>
                      <span>Insurance</span>
                    </div>
                    {isSubmenuVisible && (
                      <ul className="nav-submenu">
                        {/* <span className="nav-item">
                          <a
                            href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                            download
                            className="nav-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                          
                            <span>Insurance Claim Form</span>
                          </a>
                        </span> */}

                        <span>
                          <Link
                            to="/parent/insurance-claim"
                            className={`nav-link `}
                          >
                            <span>Insurance Claim Instruction</span>
                          </Link>
                        </span>
                      </ul>
                    )}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        }
      </div>
    </div>
  );
};

export default ParentSidebar;
