import React, { useEffect, useReducer } from 'react'
import { LoginStateContext } from '../contexts/LoginStateContext'
import axios from '../components/api'
import { useLocation } from 'react-router-dom'
import { useContext } from 'react'
import { toast } from 'react-hot-toast'

const initialState = {
    loading: true,
    data: '',
    error: '',
    loggedIn: false,
    token: null,
    expired:false
}

const actions = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    LOADING: "LOADING",
    FAILED: "FAILED",
    TOKEN_REFRESHED: 'TOKEN_REFRESHED',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED'
}

function getCookieToken() {
    return document.cookie
    .split("; ")
    .find((row) => row.startsWith("token="))
    ?.split("=")[1];
}



const reducer = (state, action) => {
    if (action.type == actions.LOGIN) {
        return {
            ...state,
            data: action.payload,
            loggedIn: true,
            loading: false,
            token: getCookieToken(),
            expired:false,
            error: ''
        }
    }
    if (action.type == actions.LOGOUT) {
        return {
            ...state,
            data: '',
            loggedIn: false,
            loading: false,
            token: null,
            expired:false,
            error: ''
        }
    }
    if (action.type == actions.FAILED) {
        return {
            ...state,
            data: '',
            loggedIn: false,
            loading: false,
            token: null,
            expired:false,
            error: action.payload
        }
    }
    if (action.type == actions.LOADING) {
        return {
            ...state,
            loading: action.payload,
            error: ''
        }
    }
    if(action.type==actions.TOKEN_REFRESHED) {
        return {
            ...state,
            token: action.payload,
            expired:false
        }
    }
    if(action.type=actions.TOKEN_EXPIRED) {
        return {
            ...state,
            data: '',
            loggedIn: false,
            loading: false,
            token: null,
            expired: true,
        }
    }
}


function LoginMiddleware({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState)

    const location = useLocation()

    useEffect(() => {
        const token = getCookieToken()
        if (state.token!=null && token == state.token) {
            return
        }
        if (token != null && token != '') {
            fetchUser(token)
            return
        }
        dispatch({ type: actions.LOGOUT })
    }, [location])

    const setNewToken = (token) => {
        dispatch({type: actions.TOKEN_REFRESHED, payload: token})
    }

    const expireToken = () => {
        dispatch({type: actions.TOKEN_EXPIRED, payload:''})
    }

    const fetchUser = (token) => {
        // console.log()
        dispatch({ type: actions.LOADING, payload: true })
        const promise = axios.get("/api/admin/user-profile", {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(res => {
                dispatch({ type: actions.LOGIN, payload: res.data })
            })
            .catch(err => {
                // localStorage.removeItem("token");
                document.cookie = `token=;path=/;`
                dispatch({ type: actions.FAILED, payload: err.message })
            })
    }

    const verifyingLogin = async () => {
        return new Promise((resolve, reject) => {
            var loginInterval = setInterval(() => {
                if (!state.LOADING) {
                    if (state.loggedIn) {
                        // console.log()
                        resolve({ status: 200, state: state })
                    }
                    else {
                        reject({ status: 401 })
                    }
                    clearInterval(loginInterval)
                }
            }, 10)
        })
    }

    const logout = () => {
        axios.delete('api/delete-fcm-while-logout', {headers: {'Authorization':`Bearer ${state.token}`}}).then(res=>console.log())
        document.cookie = `token= ; expires = Thu, 1 Jan 1970 00:00:00 GMT; path=/;`
        // document.cookie = `token= ; expores = Thu, 1 Jan 1970 00:00:00 GMT; path=/user;`
        dispatch({ type: actions.LOGOUT })
        toast.success('Logged out successfully', {position:'top',background: '#333', color: '#fff',})
    }

    return (
        <LoginStateContext.Provider value={{setNewToken:setNewToken, state: state, dispatch: dispatch, refetch: fetchUser, logout: logout, verifyingLogin: verifyingLogin, expireToken: expireToken }}>
            <div>
                {children}
            </div>
        </LoginStateContext.Provider>
    )
}

export function useChkLogin() {
    const {state, logout} = useContext(LoginStateContext)

    return {isLoading:state.loading, data:state.data, isLoggedIn:state.loggedIn, token: state.token, logout: logout}
}

export default LoginMiddleware
