import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Container,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ContactService from "./contact.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const IconContainer = ({ bgcolor, children }) => {
    return (
      <Box
        sx={{
          background: bgcolor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          width: "30px !important",
          height: "30px !important",
          aspectRatio: "1/1",
          color: "#fff !important",
          fontSize: "18px",
          position: "relative",
          zIndex: 99,
          margin: "0 5px",
        }}
      >
        {children}
      </Box>
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const data = {
      first_name: formData.get("first-name"),
      last_name: formData.get("last-name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      subject: formData.get("subject"),
      message: formData.get("message"),
    };

    try {
      await ContactService.submitContactForm(data);
      setIsSuccess(true);
      setModalMessage("Your message has been sent successfully.");
    } catch (error) {
      console.log(error);
      setIsSuccess(false);
      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        const errorMessages = Object.values(errors)
          .flat()
          .join("\n");

        setModalMessage(`${errorMessages}`);
      } else {
        setModalMessage(
          "There was an error sending your message. Please try again."
        );
      }
    }

    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{ pt: 5 }}
      className="contact-us-section"
    >
      <Box className="text-center">
        <Typography variant="h4" component="h2" sx={{ fontWeight: "bold" }}>
          Contact Us
        </Typography>
        <Typography variant="body1">
          Any question or remarks? Just write us a message.
        </Typography>
      </Box>

      <Box className="enquiry-form" sx={{ pt: 5 }}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box className="info-section" sx={{ flexGrow: 1 }}>
              <div>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{ fontWeight: "bold" }}
                >
                  Contact Information
                </Typography>
                <Typography variant="body2">
                  Fill up the form and our team will get back to you soon.
                </Typography>
              </div>

              <Box>
                <Typography>+977 01-5917322</Typography>
                <Typography>info@ghargharmadoctor.com</Typography>
                <Typography>Sinamangal, Kathmandu, Nepal</Typography>
                <Typography>9:30AM to 5:30PM; Thursday to Tuesday</Typography>
              </Box>

              <Box className="social-media-icons" sx={{ mt: 2 }}>
                <IconContainer bgcolor="#0077B5">
                  <Link
                    href="https://www.linkedin.com/company/ghargharmadoctor/?originalSubdomain=np"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedin} color="white" />
                  </Link>
                </IconContainer>

                <IconContainer bgcolor="#0D8CF1">
                  <Link
                    href="https://www.facebook.com/ghargharmadr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebook} color="white" />
                  </Link>
                </IconContainer>

                <IconContainer bgcolor="radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)">
                  <Link
                    href="https://www.instagram.com/ghargharmadoctor/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} color="white" />
                  </Link>
                </IconContainer>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              component="form"
              className="form-section"
              sx={{ flexGrow: 1 }}
              onSubmit={handleSubmit}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="first-name"
                    name="first-name"
                    label="First Name"
                    placeholder="Enter your first name"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="last-name"
                    name="last-name"
                    label="Last Name"
                    placeholder="Enter your last name"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    type="email"
                    label="Mail"
                    placeholder="Enter your email"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="phone"
                    name="phone"
                    type="tel"
                    label="Phone"
                    placeholder="Enter your phone number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="subject"
                    name="subject"
                    label="Subject"
                    placeholder="Enquiry Subject"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="message"
                    name="message"
                    label="Your Message"
                    placeholder="Enter your message"
                    multiline
                    required
                  />
                </Grid>
                <Grid item xs={12} className="submit-btn">
                  <Button type="submit" variant="contained" color="primary">
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={openModal} onClose={handleClose}>
        <DialogTitle>
          {isSuccess ? "Submitted Successfully" : "Submission Failed"}
        </DialogTitle>
        <DialogContent>
          <Typography>{modalMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Contact;
