import {
    Avatar,
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    Skeleton,
    Tooltip,
    Typography
} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React, {useContext} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import axios from "../../../../api"
import BlankProfile from "../../../../../images/blankProfile.webp"
import {
    Info,
    ReportGmailerrorredOutlined,
    ReportOutlined,
    VerifiedOutlined,
    VerifiedUserOutlined,
    VisibilityOutlined
} from '@mui/icons-material'
import {data} from 'jquery'
import {feetInchText} from '../../../../../helperFunctions/meterToFeetInch'
import ProfileProgress from './ProfileProgress'
import InfoPreview from '../../../../../images/infoPreview.png'
import {useState} from 'react'
import {ageCalculate} from '../../../../../helperFunctions/ageCalculate'

function ProfileCard() {
    const loginContext = useContext(LoginStateContext)
    const user = loginContext.state.data
    const navigate = useNavigate()
    const [infoOpen, setInfoOpen] = useState(false)

    console.log("User ", user)

    const referredBy = useRefferedBy(loginContext.state.data.id, loginContext.state.token)


    const primaryProfileQuery = useQuery(
        ["familyData", user.id],
        async () => {
            return axios.get('/api/admin/family', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        },
    )

    const kycStatus = useQuery(
        ['kyc_status'],
        async () => {
            return axios.get('/api/kycstatus', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data).catch(err => err)
        }
    )

    const packageData = useQuery(
        ['package'],
        async () => (
            axios.get('api/admin/userpackage', {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => {
                console.log("Package response", res.data)
                return res.data
            })
        )
    )

    function openInfo() {
        setInfoOpen(prev => true)
    }

    function closeInfo() {
        setInfoOpen(prev => false)
    }

    const pg = packageData.data?.package
    // console.log()


    if (primaryProfileQuery.isLoading || packageData.isLoading) {
        return <div className='h-100'>
            <Skeleton variant="rounded" className="w-100 h-100"/>
        </div>
    }

    if (!kycStatus.isLoading) {
        // console.log()
    }

    const primary = primaryProfileQuery.data

    return (
        <>
            <div className="card profile-card h-100 shadow-sm">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h5 className="card-title">
                            <span className="font-weight-bold">User Profile</span>
                        </h5>
                        <ProfileProgress pg={pg}/>

                        <Link to="/user/profile/edit" type="button"
                              className="btn btn-primary-100 text-primary align-self-start" state={{user: user}}>
                            Edit Profile
                        </Link>
                    </div>

                </div>
                <div className="card-body mt-2">

                    <div className="profile-top d-flex flex-column align-items-center">
                        <div className="profile-name text-center">
                            <h2 className='text-indigo font-weight-bold m-0 p-0'>{user.member.name}</h2>
                            {
                                !packageData.isLoading
                                &&
                                <>
                                    {
                                        packageData.data.package != null && packageData.data.package?.package_status !== 'Not Booked'
                                        &&
                                        <h4 className='text-indigo m-0 p-0'>
                                            {packageData.data.package.package_type}
                                        </h4>
                                    }
                                </>

                            }
                            <h2 className="text-indigo m-0 p-0">{user.gd_id}</h2>
                            {
                                !packageData.isLoading
                                &&
                                <h5>{(pg === null || pg.year === 1) && 'New Member'}</h5>
                            }
                            <p>{user.address}</p>
                        </div>
                    </div>

                    <div className="row w-100 mt-1">
                        <div className="col-12 d-flex align-items-center">
                            <span className="text-primary font-weight-light m-0">
                                Member Type:
                            </span>
                            <span className="text-indigo font-weight-bold mb-0 ms-2">
                                <Box display='flex' gap={0.2} alignItems={'center'}>
                                    {user.member_type}
                                    {
                                        user.member_type == 'Primary Member' && (
                                            <Tooltip title='Click to view roles and responsibilities of primary member'
                                                     arrow>
                                                <Info sx={{
                                                    color: 'palette.primary.main',
                                                    fontSize: '1rem',
                                                    cursor: 'pointer'
                                                }} onClick={openInfo}/>
                                            </Tooltip>
                                        )
                                    }

                                </Box>
                            </span>
                        </div>
                        <div className="col-12 d-flex align-items-center">
                            <span className="text-primary font-weight-light m-0">
                                Family Name:
                            </span>
                            {user.member_type === null ?
                                <span className="text-indigo font-weight-bold mb-0 ms-2"></span> :
                                <Link to="family" className="text-decoration-none">
                                    {user.member_type === 'Primary Member' ?
                                        <span
                                            className="text-indigo font-weight-bold mb-0 ms-2">{primary.family_name}</span>
                                        :
                                        primary.payment_status === 0 ?
                                            <td>{primary.familyname.family_name} <small className='text-danger'>(Pending.
                                                Payment due)</small></td>
                                            :
                                            primary && (primary.approved !== 0 ?
                                                <td>{primary.familyname.family_name} <span
                                                    className='text-success'>(Verified)</span></td>
                                                :
                                                <td>{primary.familyname.family_name} <span className='text-danger'>(Waiting for Verification)</span>
                                                </td>)
                                    }
                                </Link>
                            }
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <span className="text-primary font-weight-light m-0">
                                Sex:
                            </span>
                            <span className="text-indigo font-weight-bold mb-0 ms-2">
                                {user.gender}
                            </span>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <span className="text-primary font-weight-light m-0">
                                DOB:
                            </span>
                            <span className="text-indigo font-weight-bold mb-0 ms-2">
                                {user.dob ? user.dob : ''}
                            </span>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <span className="text-primary font-weight-light m-0">
                                Age:
                            </span>
                            <span className="text-indigo font-weight-bold mb-0 ms-2">
                                {user.dob ? ageCalculate(user.dob) : ''}
                            </span>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <span className="text-primary font-weight-light m-0">
                                Blood Group:
                            </span>
                            <span className="text-indigo font-weight-bold mb-0  ms-2">
                                {user.blood_group}
                            </span>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <span className="text-primary font-weight-light m-0">
                                Weight:
                            </span>
                            <span className="text-indigo font-weight-bold mb-0 ms-2">
                                {user.weight}
                            </span>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <span className="text-primary font-weight-light m-0">
                                Height:
                            </span>
                            <span className="text-indigo font-weight-bold mb-0 ms-2">
                                {
                                    feetInchText(user.height)
                                }
                            </span>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className='btn btn-primary-100 text-primary disabled w-100'>
                        Reffered
                        By: {user.member.referrer === null ? 'Self' : `${user.member.referrer.name} ${user.member.referrer.subroles === null ? '' : `(${user.member.referrer?.subroles && user.member.referrer.subroles.subrole})`} `}
                    </div>
                </div>
            </div>

            <PrimaryDetails open={infoOpen} handleClose={closeInfo}/>
        </>
    )
}

export default ProfileCard

function useRefferedBy(id, token) {
    return useQuery(
        ['referredBy', id],
        async () => {
            return axios.get("api/referralFriend/referredBy", {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                    return res.data
                }).catch(err => err)
        }
    )
}

function PrimaryDetails({open, handleClose}) {
    return (
        <Backdrop
            sx={{color: '#fff', zIndex: 9999}}
            open={open}
            onClick={handleClose}
        >
            <Card sx={{position: 'relative'}}>
                <CardContent
                    sx={{
                        position: 'relative',
                        zIndex: 2,
                        '& *': {
                            color: 'white'
                        }
                    }}
                >
                    <Box position='absolute' width='100%' height='100%' top={0} left={0} zIndex={1} bgcolor={'#3466FF'}>
                        <Box position='absolute' top={-75} right={-400} width='fit-content'
                             sx={{opacity: 0.3, '& img': {width: '60%'}}}>
                            <img src={InfoPreview} alt=""/>
                        </Box>
                    </Box>
                    <Box position='relative' zIndex={2}>
                        <Typography variant='h4' fontSize={'26px !important'}>Primary Member</Typography>
                        <Box mt={3}>
                            <Typography variant='subtitle1'>
                                You are Primary Member of your family and you have following roles and responsibilities:
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Box display='flex' gap={1}>
                                <Box
                                    sx={{
                                        aspectRatio: '1/1',
                                        borderRadius: '50%'
                                    }}
                                >
                                    <Typography variant='subtitle1'>1.</Typography>
                                </Box>
                                <Typography variant='subtitle1'>Only Primary Member of the family can make package
                                    payment</Typography>
                            </Box>
                            <Box display='flex' gap={1}>
                                <Box
                                    sx={{
                                        aspectRatio: '1/1',
                                        borderRadius: '50%'
                                    }}
                                >
                                    <Typography variant='subtitle1'>2.</Typography>
                                </Box>
                                <Typography variant='subtitle1'>Add or Remove members to your family.</Typography>
                            </Box>
                            <Box display='flex' gap={1}>
                                <Box
                                    sx={{
                                        aspectRatio: '1/1',
                                        borderRadius: '50%'
                                    }}
                                >
                                    <Typography variant='subtitle1'>3.</Typography>
                                </Box>
                                <Typography variant='subtitle1'>Additional payment should be done in case family members
                                    is added on an ongoing package.</Typography>
                            </Box>
                        </Box>

                        <Box mt={3} width='fit-content' ml='auto'>
                            <Button variant='contained' sx={{px: 4}} onClick={handleClose}>Close</Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Backdrop>
    )
}
