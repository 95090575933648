import {MenuItem, Select} from "@mui/material";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import {grey} from "@mui/material/colors";

function formatDataKeys(data) {
    return Object.keys(data).map(key => {
        return {
            label: key,
            value: data[key]
        };
    });
}


const AsyncSelect = (props) => {

    const {filterFetchData, queryKey, handleChange, label, value, handleClear} = props
    /**
     * REACT QUERY
     * */
    const {data} = useQuery({
        queryKey: [queryKey],
        queryFn: () =>
            filterFetchData(),

    });

    const options = data && formatDataKeys(data)

    const onChange = (e) => {
        handleChange(queryKey, e.target.value)
    }


    return (
        <div className="d-flex gap-1 flex-column">
            <span style={{fontSize: 14}}>{label}</span>
            <div className="d-flex align-items-center gap-2">
                <Select
                    value={value}
                    disableUnderline={true}
                    sx={{
                        background: "#eeeeeeaa",
                        borderRadius: '6px',
                        height: 44,
                        paddingLeft: '12px',
                        marginTop: 0,
                        width: 200,
                    }}
                    onChange={onChange}
                >
                    {
                        options?.map((item) => (
                            <MenuItem key={item.value} color="primary" value={item.value}>
                                {item?.label}
                            </MenuItem>
                        ))
                    }
                </Select>
                <div style={{color: grey[900], cursor: "pointer"}} onClick={() => handleClear(queryKey)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="19" height="19">
                        <path
                            fill="currentColor"
                            d="M6.1,6.1C.64,11.56,.64,20.44,6.1,25.9s14.34,5.46,19.8,0,5.46-14.34,0-19.8S11.56,.64,6.1,6.1Zm15.56,5.66l-4.24,4.24,4.24,4.24c.94,.94-.48,2.35-1.41,1.41l-4.24-4.24-4.24,4.24c-.94,.94-2.35-.48-1.41-1.41l4.24-4.24-4.24-4.24c-.94-.94,.48-2.35,1.41-1.41l4.24,4.24,4.24-4.24c.94-.94,2.35,.48,1.41,1.41Z"
                        />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default AsyncSelect
