import {Alert, AlertTitle, Box} from '@mui/material'
import React, {useContext} from 'react'
import {useChkLogin} from '../../../../middlewares/LoginMiddleware'
import {useQuery} from '@tanstack/react-query'
import axios from '../../../api'
import Loader from '../../Loader'
import {DateFormat} from '../../../DateFormat'
import {HealingOutlined, Pending} from '@mui/icons-material'
import FormModalContext from '../../../custom_componets/globalFormModal/FormModalContext'
import BecomePrimaryReqView from './BecomePrimaryReqView'

function BecomePrimaryRequests() {
    const {data, token} = useChkLogin()
    const {openFormModal} = useContext(FormModalContext)
    const {isLoading: isRequestsLoading, data: requests, isError: isRequestError} = useQuery(
        ['primary-request', token],
        async () => axios.get('api/admin/family/become-primary-member-list', {headers: {'Authorization': `Bearer ${token}`}}).then(res => res.data).catch(err => console.log(err))
    )
    if (isRequestsLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center py-4">
                <Loader/>
            </div>
        )
    }

    return (
        <div className="card-body">
            {
                requests.length === 0 ?
                    <Alert severity='error'>
                        <AlertTitle>
                            You haven't sent any primary change request.
                        </AlertTitle>
                    </Alert>
                    :
                    <table className='table border-left border-right border-top'>
                        <thead>
                        <Box component='tr' bgcolor={'#'}>
                            <th><span className='text-muted'>S.N.</span></th>
                            <th><span className='text-muted'>Request Date</span></th>
                            <th><span className='text-muted'>Type</span></th>
                            <th><span className="text-muted">Request Status</span></th>
                            <th><span className='text-muted'>Actions</span></th>
                        </Box>
                        </thead>
                        <tbody>
                        {
                            requests.map((item, idx) => (
                                <tr key={item.id}>
                                    <td>{idx + 1}</td>
                                    <td>{DateFormat(item.created_at).getFullDateText()}</td>
                                    <td>
                                        <div className="badge badge-secondary d-flex align-items-center gap-1"
                                             style={{width: 'fit-content'}}>
                                            <HealingOutlined className='fs-6'/>
                                            <span>Death Case</span>
                                        </div>
                                    </td>
                                    <td>
                                        {
                                            item?.status == 0 ?
                                                <div className="badge badge-warning d-flex align-items-center gap-1"
                                                     style={{width: 'fit-content'}}>
                                                    <Pending className='fs-6'/>
                                                    <span>Pending</span>
                                                </div>
                                                :
                                                item?.status === 1 ?
                                                    <div className="badge badge-success d-flex align-items-center gap-1"
                                                         style={{width: 'fit-content'}}>
                                                        <HealingOutlined className='fs-6'/>
                                                        <span>Approved</span>
                                                    </div>
                                                    :
                                                    <div className="badge badge-danger d-flex align-items-center gap-1"
                                                         style={{width: 'fit-content'}}>
                                                        <HealingOutlined className='fs-6'/>
                                                        <span>Rejected</span>
                                                    </div>
                                        }
                                    </td>
                                    <td>
                                        <button className="btn btn-primary"
                                                onClick={() => openFormModal(<BecomePrimaryReqView data={item}/>)}>
                                            View Request
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                        {/* <tbody>
                    {
                        data.map((item, idx) => (
                            item.doctor !== null && (
                                <tr key={item.id}>
                                    <td>
                                        <div className="d-flex align-items-center gap-2">
                                            <Avatar src={item.doctor.image_path || ImgPlaceHolder} />
                                            <div>
                                                <div>
                                                    <span className='fs-6' style={{ fontWeight: 550 }}>{item.doctor.user.name}</span>
                                                </div>
                                                <div>
                                                    <span className='fw-light'>{item.doctor.qualification || 'No qualification added'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="fs-6 text-muted">{item.agenda}</span>
                                    </td>
                                    <td>
                                        <span className="fs-6 text-muted">{item.start_time.split(' ')[1]}</span>
                                    </td>
                                    <td>
                                        <span className="fs-6 text-muted">{item.end_time.split(' ')[1]}</span>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center gap-1 badge badge-success text-white" style={{ width: 'fit-content' }}>
                                            <LockClockOutlined className='fs-6' />
                                            <span className="fs-6 ">{getTimeDifference(item.start_time, item.end_time)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="fs-6 text-muted">{DateFormat(item.start_time).getFullDateText()}</span>
                                    </td>
                                    <td>
                                        <button className="btn btn-primary" onClick={() => navigate(`/user/consultation-history/${item.id}`)}>View Details</button>
                                    </td>
                                </tr>
                            )
                        ))
                    }
                </tbody> */}
                    </table>
            }

        </div>
    )
}

export default BecomePrimaryRequests
