import {MessageOutlined} from '@mui/icons-material'
import {Box} from '@mui/material'
import {blue} from '@mui/material/colors'
import React from 'react'
import Mascot from '../../images/mascot.png'

function ChatFloatingBtn({openWindow}) {
    return (
        <Box className='chat-floating-btn' width='80px' height='80px' borderRadius='50%' display='flex'
             justifyContent='center' alignItems='center' bgcolor={'#0259A7'} boxShadow={1} onClick={openWindow}>
            <img src={Mascot} alt="" style={{width: '90%', height: '90%', objectFit: 'contain'}}/>
            {/* <MessageOutlined sx={{color:'white', fontSize:'40px'}}/> */}
        </Box>
    )
}

export default ChatFloatingBtn
