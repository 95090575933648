import {faCartPlus, faHeart, faEye} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import swal from "sweetalert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {addToLocalStorage, getFromLocalStorage} from "./LocalDatabaseQ";
import axios from "../api.js";
import React, {useState, useReducer} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Rating, Breadcrumbs, Typography, Card, CardMedia, Box, Stack, Button, CardContent} from "@mui/material";
import AuthButton from "../custom_componets/AuthButton";
import {StoreContext} from "../../contexts/StoreContext"
import {RespTxt, RespTxtDiv} from "../custom_componets/RespTxtDiv";
import {GDStoreContext} from "../../contexts/GDStoreContext";
import {AddShoppingCart, FavoriteBorderOutlined, VisibilityOutlined} from "@mui/icons-material";
import {useContext} from "react";
import {LoginStateContext} from "../../contexts/LoginStateContext";
import {useCart} from "../../contexts/provider/GDCartProvider";

// const ProductCards = (props) => {
//   const product = props.data;
//   const setCartCount = props.setCartCount;
//   const addToWishList = props.addToWishList;
//   const cardNum = props.cardNum

//   const storeContext = React.useContext(StoreContext)
//   const gdStore = React.useContext(GDStoreContext)

//   const wishListAdd = (e) => {
//     e.preventDefault();
//     gdStore.addToWishList(product.id)
//   };

//   const storeCart = (e) => {
//     e.preventDefault();
//     product.cartCount = 1;
//     let res = addToLocalStorage("cart", product);
//     if (res) {
//       swal("Success", "Product Added To Cart", "success");
//     } else {
//       swal("Error", "Product Already Present In Cart", "error");
//     }
//     // storeContext.setCartCount(getFromLocalStorage("cart").length);
//     gdStore.setUpdate(prev => !prev)
//   };

//   let cartStatus = "absent";


//   return (
//     <Link
//       to={`../product/${product.slug}`}
//       className={`remove-txt-decor ${product.stock <= 0 && "stock-0"}`}

//     >
//       <div className={`card product-card`}>
//         {
//           product.stock <= 0 &&
//           <div className="stock-info">
//             Out Of Stock
//           </div>
//         }

//         <div className="img-div">
//           <img className="card-img-top" src={product.image_path} />
//         </div>
//         <div className="overlay-card" />
//         <div className="action-bar">
//           <div className="row g-1">
//             <div className="col-4 text-center ic-con" onClick={wishListAdd}>
//               <Tooltip title="Wishlist" arrow>
//                 <FontAwesomeIcon icon={faHeart} size="xl" />
//               </Tooltip>
//             </div>

//             <div className="col-4 text-center ic-con">
//               <Tooltip title="View">
//                 <FontAwesomeIcon icon={faEye} size="xl" />
//               </Tooltip>
//             </div>

//             <div
//               className={"col-4 text-center ic-con " + cartStatus}
//               onClick={storeCart}
//             >
//               <Tooltip title="Add To Cart">
//                 <FontAwesomeIcon icon={faCartPlus} size="xl" />
//               </Tooltip>
//             </div>
//           </div>
//         </div>

//         <div className="prod-card-body px-3 py-2">
//           <div className="prod-card-name">
//             <span className="mb-0 ">{product.productName}</span>

//           </div>
//           <div className="prod-card-price d-flex justify-content-between align-items-center">
//             <span className="price-text mt-2 business-color-1">
//               {"Rs. " + (product.sale_price - product.discountPercent * product.sale_price / 100)}
//             </span>
//             {
//               product.discountPercent != null && (
//                 <span className="original-price text-muted">
//                   <s>{"Rs." + product.sale_price}</s>
//                   {"  -" + product.discountPercent + "%"}
//                 </span>
//               )
//             }

//           </div>
//           <div className="prod-card-rating d-flex align-items-center">
//             <Rating name="read-only" value={product.averageRating} readOnly className="prod-card-rating-star" />
//             <span className="blue-highlight-txt mx-2">({product.totalReviews})</span>
//           </div>
//         </div>
//       </div>
//     </Link>
//   );
// };

const ProductCards = (props) => {
    const loginContext = useContext(LoginStateContext)
    const gdCart = useCart()
    const product = props.data;
    const setCartCount = props.setCartCount;
    const addToWishList = props.addToWishList;
    const cardNum = props.cardNum

    const storeContext = React.useContext(StoreContext)
    const gdStore = React.useContext(GDStoreContext)
    const navigate = useNavigate()

    const wishListAdd = async (token, e) => {
        await gdStore.addToWishList(product.id, token)
    };

    const storeCart = (e) => {
        e.preventDefault();
        if (e && e.stopPropagation) e.stopPropagation();
        // product.cartCount = 1;
        // let res = addToLocalStorage("cart", product);
        const res = gdCart.addToCart(product, 1)
        if (res) {
            swal("Success", "Product Added To Cart", "success");
        }
        // else {
        //   swal("Error", "Product Already Present In Cart", "error");
        // }

        // storeContext.setCartCount(getFromLocalStorage("cart").length);
        gdStore.setUpdate(prev => !prev)
    };

    let cartStatus = "absent";

    return (
        <Card sx={{
            maxWidth: '230px', width: '100%', position: 'relative', mb: 2, "&:hover": {
                cursor: "pointer",
                "& .overlay-action-div": {
                    background: 'rgba(46, 46, 46, 0.5)',
                    "& .overlay-action-card": {
                        bottom: 0
                    }
                }
            },
            textAlign: 'left'
        }} onClick={() => navigate(`/gd-store/product/${product.slug}`)}>
            <CardMedia
                component="img"
                image={product.image_path}
                alt="Paella dish"
                sx={{width: '100%', aspectRatio: '1/1'}}
            />
            <Box component='div' className="overlay-action-div" sx={{
                position: 'absolute', top: '0', left: 0, width: '100%', aspectRatio: '1/1', zIndex: '1', border: 'none'
            }}>
                <Card className='overlay-action-card' sx={{
                    position: 'absolute',
                    bottom: '-80px',
                    width: '100%',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: 0,
                    boxSizing: 'border-box',
                    p: 2,
                    zIndex: '2',
                    transition: 'all 100ms'
                }} variant='outlined'>
                    <Stack justifyContent='space-around' direction='row'>
                        <AuthButton auth={true} authVerfify={loginContext.verifyingLogin} callbackFn={wishListAdd}
                                    preventPropagation><FavoriteBorderOutlined/></AuthButton>
                        {/* <Button sx={{ color: 'black' }} onClick={wishListAdd}><FavoriteBorderOutlined /></Button> */}
                        <Button sx={{color: 'black', cursor: 'pointer'}}><VisibilityOutlined/></Button>
                        <Button sx={{color: 'black', cursor: 'pointer'}} onClick={storeCart}><AddShoppingCart/></Button>
                    </Stack>
                </Card>
            </Box>
            <CardContent sx={{position: 'relative', zIndex: '3', background: 'inherit', height: '135px'}}>
                <Typography variant="subtitle1" sx={{
                    overflow: "hidden",
                    lineHeight: 'normal',
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                }} color="#0D59A8" component='div'>
                    {product.productName}
                </Typography>
                <Stack direction='row' alignItems='center' mt={1}>
                    <Rating name="read-only" value={product.averageRating} readOnly size='small'/><Typography
                    variant="caption" sx={{ml: 1}}>{`(${product.totalReviews})`}</Typography>
                </Stack>
                <Stack direction='row' alignItems={'center'} justifyContent='space-between' mt={1}
                       sx={{flexWrap: 'wrap'}}>
                    <Typography variant="body1" color="#52C8F4" sx={{fontWeight: 600}}>
                        {"NPR " + (product.sale_price - product.discountPercent * product.sale_price / 100)}
                    </Typography>
                    <Typography variant="caption" color='text.secondary'
                                sx={{textDecoration: 'line-through'}}>{"Rs." + product.sale_price}</Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default ProductCards;
