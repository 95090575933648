import {Box, Container, styled, Tab, Tabs} from '@mui/material'
import React from 'react'

export const AntTabs = styled(Tabs)({
    width: '100%',
    '& .MuiTabs-flexContainer': {
        background: 'white',
        width: 'fit-content',
        borderRadius: '10px',
        '.Mui-selected': {
            background: '#2F9AC9',
            color: 'white'
        },
        'button': {
            borderRadius: '10px',
        }
    },
    '& .MuiTabs-indicator': {
        background: 'none'
    }
})

export const AntTab = styled((props) => <Tab disableRipple {...props} />)({})

export const OuterTabs = styled(Tabs)({
    width: '100%',
    '& .MuiTabs-flexContainer': {
        background: 'white',
        width: 'fit-content',
    },
    '& .MuiTabs-indicator': {
        top: 0,
    },
    'button': {
        border: '1px solid #C0C0C0',
        background: '#2F9AC9',
        color: 'white',
        ':first-child': {
            borderLeft: '0'
        }
    },
    '.Mui-selected': {
        borderBottom: '1px solid #fff',
        background: 'white',
        boxShadow: '0px 10px white',
    },
})

export const InnerTabs = styled(Tabs)({
    width: '100%',
    '& .MuiTabs-flexContainer': {
        background: 'white',
        width: 'fit-content',
    },
    '& .MuiTabs-indicator': {
        width: '100%',
    },
})

export const ButtonTabs = styled(Tabs)({
    width: '100%',
    '& .MuiTabs-flexContainer': {
        // width: 'fit-content',
        gap: '2rem'
    },
    '& .MuiTabs-indicator': {
        // top: 0,
        display: 'none'
    },
    'button': {
        background: '#D9D9D9',
        color: 'black',
        borderRadius: '10px'
    },
    '.Mui-selected': {
        background: '#063B9D',
        color: 'white'
    },
})

export const ButtonTabsCentered = styled(ButtonTabs)({
    '& .MuiTabs-flexContainer': {
        justifyContent: 'center'
    },
    '.Mui-selected': {
        background: '#063B9D',
        color: 'white'
    },
})

function CustomTab() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box>
            <AntTabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                // scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
            >
                <Tab label="Pathology Screening"/>
                <Tab label="Lab Test Report"/>
                <Tab label="External Medical Details"/>
            </AntTabs>
        </Box>
    )
}

export default CustomTab
