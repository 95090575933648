export function getDateStatus(status) {
    // alert(status)
    let dateStatus = {
        'Sample to be Collected': 0,
        'Sample Collected': 1,
        'Sample Deposited': 2,
        'Draft Report': 3,
        'Report Published': 4,
        'Report Verified': 5,
        'Doctor Visit': 6,
        'Doctor Visit Completed': 7,
        'Consultation Report': 8,
        'Completed': 9
    }

    return dateStatus[status] || -1
}