import React, {useState} from 'react'
import {Visibility, VisibilityOff} from '@mui/icons-material'
import {
    Alert,
    AlertTitle,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField
} from '@mui/material'
import {useMutation} from "@tanstack/react-query";
import ParentService from "../../../services/parent.service";
import {useNavigate} from "react-router-dom";
import {setToken} from "../../../utils/common/token.utils";
import {toast} from "react-hot-toast";

function StudentLogin() {
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    })
    const [showPwd, setShowPwd] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState()


    const {mutate: login} = useMutation({
        mutationFn: () => ParentService.parentLogin(loginData),
        onSuccess: (response) => {
            if (response?.data.success) {
                const token = response?.data?.data.token
                setToken(token)
                navigate('/parent')
                toast.success("Log in successful.")
            } else {
                setError(true)
            }
        },
        enabled: Boolean(loginData.email && loginData.password),
        onError: (error) => {
            if(error?.response.status === 401) {
                setError(true)
            }
        },
        onSettled: () => {
            setSubmitting(false)
        }
    })

    function handleChange(e) {
        setLoginData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    return (
        <>
            <Box
                minHeight='85vH'
                display='flex'
                flexDirection='column'
            >
                <Box
                    sx={{
                        background: "white",
                        margin: "auto",
                        padding: "32px",
                        borderRadius: "10px",
                        maxWidth: "600px",
                        border: "1px solid lightgrey",
                    }}
                >
                    <Box display='flex' justifyContent='center' sx={{'& img': {height: '60px'}, mx: 'auto'}}>
                        <img src="http://localhost:3000/static/media/blue-logo.ff09da899c73345d0fb7.png" alt=""/>
                    </Box>
                    <Box className='pt-5 text-center'>
                        <span className='fs-5 fw-light'> <b>Log in</b> to view student report</span>
                    </Box>
                    <div className="py-4">
                        {
                            error && (
                                <Alert severity='error'>
                                    <AlertTitle>Invalid Credentials.</AlertTitle>
                                </Alert>
                            )
                        }
                    </div>
                    <Box pt={2} pb={4}>
                        <TextField id="outlined-basic" label="Username" variant="outlined" name='email'
                                   onChange={handleChange} value={loginData.email} fullWidth/>
                        <FormControl sx={{mt: 2}} fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPwd ? 'text' : 'password'}
                                name='password'
                                value={loginData.password}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPwd(prev => !prev)}
                                            edge="end"
                                        >
                                            {showPwd ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <Box mt={2}>
                            <span className='text-primary fw-light'>Forgot password?</span>
                        </Box>
                    </Box>
                    <Box py={2} display='flex' justifyContent='end'>
                        <Button variant='contained' fullWidth onClick={login}>
                            Log in
                        </Button>
                    </Box>

                </Box>
            </Box>
            <Backdrop open={submitting} sx={{zIndex: 9999}}>
                <CircularProgress/>
            </Backdrop>
        </>
    )
}

export default StudentLogin
