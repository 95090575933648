import {Avatar, Box, Card, CardContent, Stack, Typography} from '@mui/material'
import React from 'react'

function FitnessVendorCard({
                               logo = "https://img.freepik.com/premium-vector/fitness-logo_25327-145.jpg?w=2000",
                               name = "Fitness Vendor",
                               email = "fitnessEmail@gmail.com",
                               number = '9860192019',
                               onClick
                           }) {
    return (
        <Card sx={{width: "90%", py: 5, cursor: 'pointer'}} elevation={4} onClick={onClick}>
            <CardContent>
                <Box width="100%">
                    <Stack direction={'row'} justifyContent='center'>
                        <Avatar
                            alt="Remy Sharp"
                            src={logo}
                            sx={{width: 56, height: 56}}
                        />
                    </Stack>
                </Box>
                <div className="text-center">
                    <Typography sx={{fontWeight: 600}}>
                        {name}
                    </Typography>
                    <Typography sx={{color: '#52C8F4', fontSize: '14px'}}>
                        {email}
                    </Typography>
                    <Typography sx={{color: 'text.secondary', fontSize: '14px'}}>
                        {number}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    )
}

export default FitnessVendorCard
