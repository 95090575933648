import React from "react";
import { RateReview, Send } from "@mui/icons-material";
import { Avatar, Rating } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ImgPlaceHolder from "../../../../images/receptionist.png";
import { DateFormat } from "../../../DateFormat";
import { getTimeDifference } from "../../../../helperFunctions/getTimeDifference";
import Loader from "../../Loader";
import ConsultationService from "../../../../services/consultation.service";

function CardHeader({ title, subtitle }) {
  return (
    <div className="card-header">
      <span className="fw-bold fs-6">{title}</span>
      <br />
      <span className="fw-light fs-6">{subtitle}</span>
    </div>
  );
}

function ConsultationHistoryDetails() {
  const params = useParams();
  const { data, isLoading } = useQuery(
    ["consultation-history-item", params.id],
    () => ConsultationService.getDetails(params.id)
  );

  if (isLoading) {
    return <Loader />;
  }

  const history = data?.data?.history;

  return (
    <div className="container">
      <div className="row">
        <div className="col-7">
          <div className="card mb-2">
            <div className="card-body">
              <div className="row border-bottom pb-3">
                <div className="col-7">
                  <div className="d-flex align-items-center gap-3">
                    <Avatar
                      src={history?.doctor?.image_path || ImgPlaceHolder}
                    />
                    <div>
                      <div className="mb-1">
                        <span className="fw-bold fs-6">
                          {history?.doctor?.user?.name}
                        </span>
                      </div>
                      <div>
                        <span className="fs-6 fw-light">
                          {history?.doctor?.address}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 d-flex justify-content-end align-items-center">
                  <div className="btn btn-primary d-flex align-items-center gap-2">
                    <RateReview className="fs-6" />
                    <span className="fs-6">Give Review</span>
                  </div>
                </div>
              </div>
              <div className="row py-3 g-3">
                <div className="col-6">
                  <div className="mb-1">
                    <span className="fs-6 fw-light">Address</span>
                  </div>
                  <div>
                    <span className="fs-6">
                      {history?.doctor?.address || "No Data"}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-1">
                    <span className="fs-6 fw-light">Qualification</span>
                  </div>
                  <div>
                    <span className="fs-6">
                      {history?.doctor?.qualification || "No Data"}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-1">
                    <span className="fs-6 fw-light">NMC Number</span>
                  </div>
                  <div>
                    <span className="fs-6">
                      {history?.doctor?.nmc_no || "No Data"}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-1">
                    <span className="fs-6 fw-light">Specialization</span>
                  </div>
                  <div>
                    <span className="fs-6">
                      {history?.doctor?.specialization || "No Data"}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-1">
                    <span className="fs-6 fw-light">Experience</span>
                  </div>
                  <div>
                    <span className="fs-6">
                      {history?.doctor?.year_practiced || "No Data"}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-1">
                    <span className="fs-6 fw-light">Gender</span>
                  </div>
                  <div>
                    <span className="fs-6">
                      {history?.doctor?.gender || "No Data"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <CardHeader
              title="Medical History"
              subtitle="Your medical history based on the consultation"
            />
            <div className="card-body">
              <p className="fs-6">{history?.history}</p>
            </div>
          </div>
          <div className="card">
            <CardHeader
              title="Patient Feedback"
              subtitle="Give feedback about your experience in this online consultation. Give your honest review. You are not obligated to review."
            />
            <div className="card-body">
              <div className="mb-2">
                <div className="mb-1">
                  <span className="fs-6">Your rating</span>
                </div>
                <div>
                  <Rating name="size-large" defaultValue={2} size="large" />
                </div>
              </div>
              <div className="mb-4">
                <div className="mb-1">
                  <span className="fs-6">Your review</span>
                </div>
                <div>
                  <textarea
                    className="form-control"
                    cols="30"
                    rows="4"
                  ></textarea>
                </div>
              </div>
              <div>
                <div
                  className="btn btn-primary d-flex align-items-center gap-2"
                  style={{ width: "fit-content" }}
                >
                  <Send className="fs-6" />
                  <span className="fs-7">Submit Feedback</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-5">
          <div className="card mb-2">
            <CardHeader
              title="Consultation Time"
              subtitle="Your Consultation Time Details"
            />
            <div className="card-body">
              <div className="mb-2 fs-6">
                <span className="fw-light me-2">Consultation Date: </span>
                <span>{DateFormat(history?.start_time).getFullDateText()}</span>
              </div>
              <div className="mb-2 d-flex gap-4 fs-6">
                <div>
                  <span className="fw-light me-2">Start Time: </span>
                  <span>{history?.start_time?.split(" ")?.[1]}</span>
                </div>
                <div>
                  <span className="fw-light me-2">End Time: </span>
                  <span>{history?.end_time?.split(" ")?.[1]}</span>
                </div>
              </div>
              <div className="fs-6 mb-2">
                <span className="fw-light me-2">Duration: </span>
                <span className="badge badge-warning fs-6">
                  {getTimeDifference(history?.start_time, history?.end_time)}
                </span>
              </div>
              <div className="fs-6 mt-4">
                <span className="fw-light me-2">Agenda:</span>
                <span className="fw-bold fs-6">{history?.agenda}</span>
              </div>
            </div>
          </div>
          <div className="card mb-2">
            <CardHeader
              title="Examination"
              subtitle="Your examination based on the consultation"
            />
            <div className="card-body">
              <p className="fs-6">{history?.examination}</p>
            </div>
          </div>
          <div className="card mb-2">
            <CardHeader
              title="Treatment"
              subtitle="Your treatment based on the consultation"
            />
            <div className="card-body">
              <p className="fs-6">{history?.treatment}</p>
            </div>
          </div>
          <div className="card">
            <CardHeader
              title="Progress"
              subtitle="Your progress based on the consultation"
            />
            <div className="card-body">
              <p className="fs-6">{history?.progress}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultationHistoryDetails;
