import React from 'react'
import InformationComponent from './InformationComponent'
import {Typography} from '@mui/material'

function PackageBookInfo() {
    return (
        <InformationComponent title='Package Details'>
            <Typography variant='subtitle1'>This is the package details</Typography>
        </InformationComponent>
    )
}

export default PackageBookInfo
