import React from 'react'
import KhaltiCheckout from "khalti-checkout-web";
import KhaltiLogo from '../../../images/payment/khalti.png';
import axios from "../../api.js";
import swal from "sweetalert";
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import OrderVerifyingUI from '../../stores/checkout/OrderVerifyingUI';
import {useChkLogin} from '../../../middlewares/LoginMiddleware';

const KhaltiAdditional = ({familyId, payment, redirectLink, type = 0}) => {
    const [verifying, setVerifying] = useState();
    const {token} = useChkLogin()
    const usertoken = token;
    const amount = payment.total_payment * 100;
    let navigate = useNavigate();
    const publicKey = process.env.REACT_APP_KhaltiPublicTestKey
    let config = {
        // replace this key with yours
        "publicKey": publicKey,
        "productIdentity": "1234567890",
        "productName": "Ghargharma Doctor",
        "productUrl": "https://react.ghargharmadoctor.com/",
        "eventHandler": {
            onSuccess(payload) {
                //console.log();
                // hit merchant api for initiating verfication
                setVerifying(true)
                let apiLink = ''
                let reqBody = {token: payload.token, payment_days: payment.payment_days, amount: payload.amount}
                if (type === 0) {
                    apiLink = '/api/admin/userpackage/additional-payment'
                    reqBody = {...reqBody, family_id: familyId, userpackage_id: payment.userpackage.id}
                } else {
                    apiLink = 'api/company-school-profile/reactivate-payment'
                    reqBody = {
                        ...reqBody,
                        user_id: familyId[0],
                    }
                }
                console.log("Reqeust body", reqBody)
                axios
                    .post(apiLink, reqBody, {headers: {"Authorization": `Bearer ${usertoken}`}})
                    .then((res) => {
                        setVerifying(false)
                        if (res.data.success) {
                            swal({
                                title: res.data.success,
                                icon: "success",
                            }).then((value) => {
                                if (value) {
                                    navigate(redirectLink)
                                }
                            });
                        }
                        if (res.data.error) {
                            swal({
                                title: res.data.error,
                                icon: "warning",
                            }).then((value) => {
                                if (value) {
                                    navigate(`/user/family/payment/${familyId}`)
                                }
                            });
                        }
                    }).catch(err => {
                    swal({
                        title: 'Payment Error',
                        icon: 'danger'
                    })
                })

            },
            // onError handler is optional
            onError(error) {
                // handle errors
                console.log();
            },
            onClose() {
                // console.log();
            }
        },
        "paymentPreference": ["KHALTI"],
    };
    let checkout = new KhaltiCheckout(config);

    return (
        <div>
            <button onClick={() => checkout.show({amount: 1000})} className="border-0">
                {/* <button onClick={() => checkout.show({amount: amount})} className="border-0"> */}
                <img src={KhaltiLogo} alt=""/>
            </button>
            {
                verifying && <OrderVerifyingUI/>
            }
        </div>
    )
}

export default KhaltiAdditional
