import React, {useEffect} from "react";
import {useMutation} from "@tanstack/react-query";
import VaccinationService from "../../../../../services/vaccination.service";
import UploadFile from "../../../../custom_componets/UploadFile";
import {FileUpload} from "@mui/icons-material";
import {Button, CircularProgress} from "@mui/material";
import DatePickerReactHookForm from "../../../../date-picker/date-picker-react-hook-form";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {VaccinationDocumentValidationSchema} from "../vaccinationDocumentValidationSchema";
import {grey} from "@mui/material/colors";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

const defaultValues = {
    vaccination_date: "",
    vaccination_file: "",
};

function Index() {
    const [searchParam, _] = useSearchParams();
    const navigate = useNavigate()

    /*
    * UPLOAD FORM
    * */
    const {mutate: uploadMutation, isLoading: isUploading, error} = useMutation({
        mutationFn: (id, data) => VaccinationService.uploadVaccinationDocument(id, data),
        onSuccess: (response) => {
            navigate("/parent/vaccination")
        }
    })

    const {
        control,
        setValue,
        clearErrors,
        register,
        handleSubmit,
        formState: {errors}
    } = useForm({resolver: yupResolver(VaccinationDocumentValidationSchema), defaultValues});

    const onSubmit = (data) => {
        const id = searchParam.get('id');
        uploadMutation({data, id})
    }

    /*
    * creating a field on mounting.
    * */
    useEffect(() => {
        control.register("vaccination_file")
    }, [])

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="card p-4" style={{background: grey["200"], borderRadius: 8}}>
            <h1>Upload vaccination document</h1>
            <div className="d-grid gap-4">
                <DatePickerReactHookForm label="Vaccination Date"
                                         required={true}
                                         control={control}
                                         name={`vaccination_date`}
                                         error={errors?.vaccination_date && errors.vaccination_date.message}
                />
                <div className="grid gap-1">
                    <label>Document</label>
                    <UploadFile
                        type={"pdf"}
                        aspectRatio="3/1"
                        setImageInput={files => {
                            if (files[0]) {
                                setValue("vaccination_file", files[0])
                                clearErrors("vaccination_file")
                            }
                        }}
                        error={errors?.vaccination_file && errors.vaccination_file.message}
                    />
                </div>
                <div>
                    <Button variant="contained" startIcon={<FileUpload/>} fullWidth type='submit'>
                        {isUploading ? <CircularProgress size={16} sx={{color: 'white'}}/> :
                            <span> Upload Vaccination document </span>
                        }
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default Index
