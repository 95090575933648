import {Edit} from '@mui/icons-material'
import {Avatar} from '@mui/material'
import React from 'react'

function MyProfile() {
    return (
        <div>
            <div className="p-4">
                <div className='mb-4'>
                    <span className='fw-bold fs-4'>My Profile</span>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-8">
                                <div className="d-flex gap-4 align-items-center">
                                    <Avatar src='https://mui.com/static/images/avatar/1.jpg'
                                            sx={{width: 85, height: 85}}/>
                                    <div>
                                        <div className='mb-1'>
                                            <span className="fs-5" style={{fontWeight: 600}}>Rafiqur Rahman</span>
                                        </div>
                                        <div className="mb-1">
                                            <span className="fs-6 text-muted">
                                                Primary Member
                                            </span>
                                        </div>
                                        <div>
                                            <span className='fw-light'>Leeds, United Kingdom</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-4">
                                <div className="d-flex w-100 justify-content-end">
                                    <button className='btn btn-primary' style={{borderRadius: 20}}>
                                        <div className="d-flex gap-2 align-items-center">
                                            <span className="fs-6">Edit</span>
                                            <Edit className='fs-6'/>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-body">
                        <div className='mb-4 d-flex justify-content-between align-items-center'>
                            <span className="fs-5" style={{fontWeight: 600}}>GD Member Information</span>
                            <button className='btn btn-primary' style={{borderRadius: 20}}>
                                <div className="d-flex gap-2 align-items-center">
                                    <span className="fs-6">Edit</span>
                                    <Edit className='fs-6'/>
                                </div>
                            </button>
                        </div>
                        <div className="row g-4">
                            <div className="col-5">
                                <div><span className="fw-light">Member Type</span></div>
                                <div><span className="fs-6" style={{fontWeight: 600}}>Primary Member</span></div>
                            </div>
                            <div className="col-5">
                                <div><span className="fw-light">GD ID</span></div>
                                <div><span className="fs-6" style={{fontWeight: 600}}>GD-188</span></div>
                            </div>
                            <div className="col-5">
                                <div><span className="fw-light">Family Name</span></div>
                                <div><span className="fs-6" style={{fontWeight: 600}}>Tamang188</span></div>
                            </div>
                            <div className="col-5">
                                <div><span className="fw-light">Blood Group</span></div>
                                <div><span className="fs-6" style={{fontWeight: 600}}>B +</span></div>
                            </div>
                            <div className="col-5">
                                <div><span className="fw-light">BMI</span></div>
                                <div><span className="fs-6" style={{fontWeight: 600}}>25.83</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-body">
                        <div className='mb-4 d-flex justify-content-between align-items-center'>
                            <span className="fs-5" style={{fontWeight: 600}}>Personal Information</span>
                            <button className='btn btn-primary' style={{borderRadius: 20}}>
                                <div className="d-flex gap-2 align-items-center">
                                    <span className="fs-6">Edit</span>
                                    <Edit className='fs-6'/>
                                </div>
                            </button>
                        </div>
                        <div className="row g-4">
                            <div className="col-5">
                                <div><span className="fw-light">First Name</span></div>
                                <div><span className="fs-6" style={{fontWeight: 600}}>Rafiqur</span></div>
                            </div>
                            <div className="col-5">
                                <div><span className="fw-light">Last Name</span></div>
                                <div><span className="fs-6" style={{fontWeight: 600}}>Rahman</span></div>
                            </div>
                            <div className="col-5">
                                <div><span className="fw-light">Email Address</span></div>
                                <div><span className="fs-6" style={{fontWeight: 600}}>Rafiqur@gmail.com</span></div>
                            </div>
                            <div className="col-5">
                                <div><span className="fw-light">Phone Number</span></div>
                                <div><span className="fs-6" style={{fontWeight: 600}}>+977 9850391829</span><span
                                    className="text-danger px-2 border border-danger ms-2 rounded-pill">Unverified</span>
                                </div>
                            </div>
                            <div className="col-5">
                                <div><span className="fw-light">Member Type</span></div>
                                <div><span className="fs-6" style={{fontWeight: 600}}>Primary Member</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProfile
