const DateFormat = (date_in) => {
    const date = new Date(date_in)
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    function getMonthText() {
        return `${months[date.getMonth()]}`
    }

    function getDay() {
        return date.getDate()
    }

    function getYear() {
        return date.getFullYear()
    }

    function getFullDateText() {
        console.log()
        return `${getMonthText()} ${date.getDate()}, ${date.getFullYear()}`
    }

    function getFullDate(format) {
        const dateFormat = {
            'm/d/y': `${date.getMonth()} ${date.getDate()}, ${date.getFullYear()}`
        }
        return dateFormat[format]
    }

    function getMonthShort() {
        return `${months[date.getMonth()]}`.substring(0, 3)
    }


    return {
        getMonthText: getMonthText,
        getFullDateText: getFullDateText,
        getFullDate: getFullDate,
        getDay: getDay,
        getYear: getYear,
        getMonthShort: getMonthShort
    }
}

export {DateFormat}
