import React from "react";

const InsuranceClaim = () => {
  return (
    <div className="insurance-form">
      <div className="top-section pt-2">
        <a
          href="../insurance-form.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Download Form
        </a>
        <h2 className="pt-4">Claim Procedure</h2>
      </div>
      <ol className="pt-2">
        <li className="text-md">
          Download the{" "}
          <a
            href="../insurance-form.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Insurance Claim Form
          </a>
          and fill it.
        </li>
        <li className="text-md">
          Email the scanned copy of the form along with required documents to
          <a href="mailto:insurance.claims@ghargharmadoctor.com">
            {" "}
            insurance.claims@ghargharmadoctor.com
          </a>
          .
        </li>
        <li className="text-md">
          Details will then be processed and the physical form along with
          evidence will be collected by relevant personnel.
        </li>
        <li className="text-md">
          If further details are required, they will be collected at a later
          date.
        </li>
        <li className="text-md">
          Insurance money will be released to relevant stakeholders based on the
          terms and conditions and decision from our Insurance Partner.
        </li>
      </ol>
    </div>
  );
};

export default InsuranceClaim;
