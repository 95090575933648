export const getToken = () => {
    if (typeof localStorage !== 'undefined') {
        return localStorage.getItem('token_id');
    }
    return undefined;
};

export const setToken = (token) => {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('token_id', token);
    }
};

export const clearToken = () => {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('token_id');
    }
};
