import {Box, Typography} from '@mui/material'
import React from 'react'

function ContentContainer({header, children}) {
    return (
        <Box
            py={5}
            display='flex'
            flexDirection='column'
            gap={4}
        >
            {
                header && (
                    <Typography variant='h5' fontWeight='600' sx={{textDecoration: 'underline'}}>
                        {header}
                    </Typography>
                )
            }
            <Box>
                {children}
            </Box>
        </Box>
    )
}

export default ContentContainer
