import React from 'react'
import {Link} from 'react-router-dom'

const PackageAlert = ({user, pkg, prepay, checkFamilySize, extended_date, expire}) => {
    return (
        <>
            {user.member_type === 'Primary Member' && pkg !== null && pkg.package_status === 'Expired' &&
                <div className="alert alert-danger" style={{paddingTop: '0.4375rem', paddingBottom: '0.4375rem'}}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <b>Your package is expired!</b> Either renew package or buy new package.
                        </div>
                        <div className="text-end">
                            <button className="btn btn-primary me-1" onClick={checkFamilySize}>
                                Renew Package
                            </button>
                            <Link className="btn btn-primary" to='/packages'>
                                Buy New Package
                            </Link>
                        </div>
                    </div>
                </div>
            }
            {user.member_type === 'Primary Member' && pkg !== null && pkg.package_status === 'Booked' && pkg.dates.length === 0 &&
                <div className="alert alert-success">
                    <b>Your package is booked successfully!</b> We will soon notify your first screening date.
                </div>
            }
            {(user.member_type === 'Primary Member' || user.member_type === null) && pkg !== null && pkg.package_status === 'Not Booked' &&
                <div className="alert alert-danger d-flex justify-content-between align-items-center"
                     style={{paddingTop: '0.4375rem', paddingBottom: '0.4375rem'}}>
                    <p className='mb-0'><b>Subscription Not Booked!</b> Please pay for your subscription package.</p>
                    <a href="#pay" className="btn btn-danger">Pay Your Package</a>
                </div>
            }
            {user.member_type === 'Primary Member' && pkg !== null && pkg.package_status === 'Deactivated' &&
                <div className="alert alert-danger d-flex justify-content-between align-items-center"
                     style={{paddingTop: '0.4375rem', paddingBottom: '0.4375rem'}}>
                    <p className='mb-0'><b>Subscription has been deactivated!</b> Please pay for your subscription
                        package.</p>
                    <a href="#pay" className="btn btn-danger">Pay Your Package</a>
                </div>
            }
            {user.member_type === 'Primary Member' && pkg !== null && pkg.package_status === 'Booked' && pkg.dates.length !== 0 && pkg.dates[0].status === 'Pending' &&
                <div className="alert alert-success">
                    <b>Your subscription package will be activated on {pkg.dates[0].screening_date}.</b>
                </div>
            }
            {user.member_type === 'Primary Member' && pkg !== null && pkg.package_status === 'Activated' && expire === true &&
                <div className="alert alert-warning">
                    <b>Your subscription package will be expired on {pkg.payment[0].expiry_date}.</b>
                </div>
            }
            {user.member_type === 'Primary Member' && pkg !== null && pkg.package_status === 'Activated' && prepay === true &&
                <div className="alert alert-warning d-flex justify-content-between align-items-center"
                     style={{paddingTop: '0.4375rem', paddingBottom: '0.4375rem'}}>
                    <p className='mb-0'><b>Your subscription package will be expired on {pkg.payment[0].expiry_date}.Prepay
                        for your subscription.</b></p>
                    <a href="#pay" className="btn btn-warning">Make Prepayment</a>
                </div>
            }
            {user.member_type === 'Primary Member' && pkg !== null && pkg.package_status === 'Activated' && pkg.grace_period === 1 &&
                <div className="alert alert-warning d-flex justify-content-between align-items-center"
                     style={{paddingTop: '0.4375rem', paddingBottom: '0.4375rem'}}>
                    <p className='mb-0'><b>Your subscription package has been extended until {extended_date}.Please pay
                        for your subscription package.</b></p>
                    <a href="#pay" className="btn btn-warning">Pay Your Package</a>
                </div>
            }
            {user.member_type === 'Primary Member' && pkg !== null && pkg.package_status === 'Activated' &&
                <div className="alert alert-info">
                    <b>Your subscription package is activated on {pkg.activated_date} and will expire
                        on {pkg.expiry_date}.</b>
                </div>
            }
        </>
    )
}

export default PackageAlert
