import React from 'react'
import {Outlet} from 'react-router-dom'
import {DatatableColumnVisibility} from '../../../assets/js/DatabaseTableColvis'
import ParentSidebar from "./ParentDashboardSidebar";
import Protected from "../../hoc/protected";
import ParentDashboardNav from "./ParentDashboardNav";
import ParentDashboardContentWrapper from "./ParentDashboardContentWrapper";

const DashboardLayout = () => {

    React.useEffect(() => {
        DatatableColumnVisibility.init();
    }, [])

    return (
        <Protected>
            <div className="dashboard-style">
                <ParentDashboardNav/>
                <div className="page-content custom-scrollbars">
                    <ParentSidebar/>
                    <ParentDashboardContentWrapper>
                        <Outlet/>
                    </ParentDashboardContentWrapper>
                </div>
            </div>
        </Protected>
    )
}

export default DashboardLayout
