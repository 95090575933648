import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import { Box } from "@mui/material";

const ParentDashboard = () => {
  return (
    <Box className="card dashboard-banner h-100" bgcolor={"primary.light"}>
      <div className="card-body">
        <div className="row h-100 align-items-center">
          <div className="banner-content col-10 col-xl-9 ms-md-5 ms-1">
            <h1 className="text-white font-weight-bold">Welcome</h1>
            <p className="text-white">
              We help you to find information about doctors and clinics who
              provide you the best health services.
            </p>

            <Link
              to="/parent/children"
              className="btn btn-primary text-white mt-2"
            >
              View Children{" "}
            </Link>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default ParentDashboard;
