import {Box, Button, Typography} from '@mui/material'
import {Stack} from '@mui/system'
import React from 'react'

function FitnessContainer({title, subtitle = null, children}) {
    return (
        <div className="my-1">
            <Box>
                <Stack direction={'row'} justifyContent='space-between' alignItems={'center'}
                       sx={{borderBottom: 1, borderColor: "gray", py: 1}}>
                    <Box component='div'>
                        <Typography variant='h6'>
                            {title}
                        </Typography>
                        {
                            subtitle !== null
                            &&
                            <Typography variant='subtitle1'>{subtitle}</Typography>
                        }
                    </Box>
                    <Button sx={{flexShrink: 0, ml: 2}}>
                        View All
                    </Button>
                </Stack>
                <Box py={3}>
                    {children}
                </Box>
            </Box>
        </div>

    )
}

export default FitnessContainer
