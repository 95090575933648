import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom';
import DateCalendar from '../ChooseDate/DateCalendar'
import axios from "../../api"
import Loader from '../Loader.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight, faCircleCheck} from '@fortawesome/free-solid-svg-icons';
import "./DoctorProfile.scss"
import Review from './Review';
import {Box} from '@mui/material';


function initMap(hospitals) {

    for (let i = 0; i < hospitals.length; i++) {
        const centerPt = {lat: parseFloat(hospitals[i].latitude), lng: parseFloat(hospitals[i].longitude)}
        const map = new window.google.maps.Map(document.getElementsByClassName('hospital-map')[i], {
            zoom: 17,
            center: centerPt,
        })
        const marker = new window.google.maps.Marker({
            position: centerPt,
            map: map
        })
    }
}

function DoctorProfile() {
    const [hospitals, setHospital] = useState([]);
    const [doctorHospitals, setDoctorHospitals] = useState([])
    const [loading, setLoading] = useState(true);
    const [doctor, setDoctor] = useState({});
    const today = new Date();
    const token = localStorage.getItem("token");
    const params = useParams()

    useEffect(() => {
        axios
            .get(`/api/booking/date`, {params: {slug: params.slug}})
            .then((res) => {
                setDoctor(res.data.data[0]);
                setLoading(false);
            })
            .catch((err) => {
                console.log();
            });
    }, [params.slug]);

    useEffect(() => {
        if (doctorHospitals.length > 0) {
            initMap(doctorHospitals)
        }
    }, [doctorHospitals])

    useEffect(() => {
        axios
            .get('/api/hospital')
            .then((res) => {
                setHospital(res.data);
            })
            .catch((err) => {
                console.log();
            });
    }, []);

    useEffect(() => {
        if (hospitals.length > 0 && Object.keys(doctor).length > 0) {
            if (doctor.hospital == null) {
                return;
            }
            let doc_hos = []
            doctor.hospital.map((hos) =>
                hospitals.filter(singleHospital => singleHospital.id == hos).map((filteredHospital) => {
                    doc_hos.push(filteredHospital)
                })
            )
            setDoctorHospitals(doc_hos)
        }
    }, [hospitals, doctor])

    const renderLogo = (hospital) => {
        return (
            hospital.map((hos) =>
                hospitals.filter(singleHospital => singleHospital.id == hos).map((filteredHospital) => {
                    return (
                        <div className="col-6">
                            <div className="logo-container">
                                <img src={filteredHospital.image_path} alt={filteredHospital.name}/>
                            </div>
                        </div>
                    )
                })
            )
        )
    }

    const renderHospital = () => {
        return doctorHospitals.map((hospital) => {
            return (
                <div className="col-12 col-md-4">
                    <div className='hospital-map'></div>
                    <div className="py-3 hospital-desc">
                        <div className="label-txt hospital-name sub-title">
                            <span>{hospital.name}</span>
                        </div>
                        <div className="hospital-address">
                            <span>{hospital.address}</span>
                        </div>
                    </div>
                </div>
            )
        })
    }

    return (
        <>
            {loading ?
                <div className="text-center">
                    <Loader/>
                </div>
                :
                <div className="doctor-prof-page">
                    <div className="container py-4">
                        <div className="pt-4 row">
                            <div className="col-12 col-lg-7">
                                <Box className='profile-head' display='flex' alignItems='center' width='100%' gap={3}>
                                    <Box width='25%' display='flex' justifyContent='center' alignItems='center' sx={{
                                        '& img': {
                                            width: '100%',
                                            aspectRatio: '1/1',
                                            borderRadius: '50%',
                                            objectFit: 'cover'
                                        }
                                    }}>
                                        <img src={doctor.image_path} alt=""/>
                                    </Box>
                                    <Box width='70%' className='profile-desc'>
                                        <div className="profile-desc-name">
                                            <h2 style={{overflowWrap: 'break-word'}}>{doctor.salutation + " " + doctor.user.name + " (" + doctor.qualification + ")"}</h2>
                                        </div>
                                        <div className="profile-desc-department label-txt d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCaretRight} size="lg" className='me-2'/>
                                            <span>{doctor.departments.department} Department</span>
                                        </div>
                                        <div className="profile-desc-experience label-txt d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCaretRight} size="lg" className='me-2'/>
                                            <span>Experience: {doctor.year_practiced} years</span>
                                        </div>
                                        <div className="profile-desc-verified d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCircleCheck} size="sm" className='me-2'/>
                                            <span className="label-txt">GD Verified</span>
                                        </div>
                                    </Box>
                                </Box>
                                {/* <Box className="profile-head w-100" display='flex' sx={{}}>
                                    <div className="profile-image">
                                        <img src={doctor.image_path} alt="" />
                                    </div>
                                    <div className="profile-desc ms-3">
                                        <div className="profile-desc-name">
                                            <h2>{doctor.salutation + " " + doctor.user.name + " (" + doctor.qualification + ")"}</h2>
                                        </div>
                                        <div className="profile-desc-department label-txt d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCaretRight} size="lg" className='me-2' />
                                            <span>{doctor.departments.department} Department</span>
                                        </div>
                                        <div className="profile-desc-experience label-txt d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCaretRight} size="lg" className='me-2' />
                                            <span>Experience: {doctor.year_practiced} years</span>
                                        </div>
                                        <div className="profile-desc-verified d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCircleCheck} size="sm" className='me-2' />
                                            <span className="label-txt">GD Verified</span>
                                        </div>
                                    </div>
                                </Box> */}

                                <div className="mt-3 profile-nav d-flex align-items-center">
                                    <div className="profile-nav-item me-3 label-txt">
                                        <span>About</span>
                                    </div>
                                    {/* <div className="mx-3 profile-nav-item label-txt">
                                        <span>Reviews</span>
                                    </div>
                                    <div className="mx-3 profile-nav-item label-txt">
                                         <span>Location</span>
                                    </div>
                                    <div className="mx-3 profile-nav-item label-txt">
                                        <span>FAQs</span>
                                    </div> */}
                                </div>

                                <div className="mb-4 py-4 about-section" id='review-part'>
                                    {/* <div className="mb-2 section-title label-txt">
                                        <span>About Dr. {doctor.user.name}</span>
                                    </div>

                                    <div className="mb-4 about-section-desc">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate, assumenda! Ipsam blanditiis eveniet saepe ullam fugit distinctio dicta, omnis maxime delectus, id quos! Assumenda sit porro aliquam omnis adipisci expedita quos libero quod repudiandae ipsam molestias labore ab velit perferendis eaque, nemo in consectetur. Cum quisquam animi provident aperiam explicabo!</p>
                                        <div className="d-flex justify-content-end">
                                            <Link to="">See More</Link>
                                        </div>

                                    </div> */}

                                    <div className="about-section-infos row g-4">
                                        <div className="about-info col-6">
                                            <div className="about-info-title label-txt">
                                                <span>Practices</span>
                                            </div>
                                            <div className="about-info-detail">
                                                <div className="row g-3">
                                                    {doctor.hospital ?
                                                        renderLogo(doctor.hospital)
                                                        :
                                                        ''
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                        <div className="about-info col-6">
                                            <div className="about-info-title label-txt">
                                                <span>Specialization</span>
                                            </div>
                                            <div className="about-info-detail">
                                                <div>{doctor.specialization}</div>
                                                {/* <div>Cardiologist</div>
                                                <div>Cardiac Electrophysiologist</div> */}
                                            </div>
                                        </div>

                                        <div className="about-info col-6">
                                            <div className="about-info-title label-txt">
                                                <span>Qualifications</span>
                                            </div>
                                            <div className="about-info-detail">
                                                <div>{doctor.qualification}</div>
                                            </div>
                                        </div>

                                        <div className="about-info col-6">
                                            <div className="about-info-title label-txt">
                                                <span>Medical Registry</span>
                                            </div>
                                            <div className="about-info-detail">
                                                <div>Nepal Medical Council: {doctor.nmc_no}</div>
                                                {/* <div>National Specialist Register: 35131</div> */}
                                            </div>
                                        </div>

                                        <div className="about-info col-6">
                                            <div className="about-info-title label-txt">
                                                <span>NMC Number</span>
                                            </div>
                                            <div className="about-info-detail">
                                                <div>{doctor.nmc_no}</div>
                                            </div>
                                        </div>

                                        <div className="about-info col-6">
                                            <div className="about-info-title label-txt">
                                                <span>Gender</span>
                                            </div>
                                            <div className="about-info-detail">
                                                <div>{doctor.gender}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {/* {Object.keys(doctor).length>0 && <Review image_path={doctor.image_path} doctor_id={doctor.id} averageRating = {doctor.averageRating} averageReview={doctor.averageReview} />} */}
                                <div className="py-4 hospital-locations">
                                    <div className="mb-2 section-title label-txt">
                                        Hospital Locations
                                    </div>
                                    <div className="hospital-maps row g-3">
                                        {renderHospital()}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5">
                                <div className="border-0 card">
                                    <div className="card-header">
                                        <h4 className='mb-0 card-title'>Booking Details</h4>
                                    </div>
                                    <div className="card-body single-doctor-booking">
                                        <DateCalendar date={today} booking={doctor} token={token} colSize={[12, 12]}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default DoctorProfile
