import React from "react"
import Slider from "react-slick";
import axios from "../../api.js"
import {RespTxtDiv} from "../../custom_componets/RespTxtDiv.js";
import ProductCards from "../ProductCards.js";

const VendorProducts = ({category, vendor}) => {
    const [products, setProducts] = React.useState([{data: []}])
    const [loading, setLoading] = React.useState(true)
    let cardNum = 0;

    React.useEffect(() => {
        axios.get("api/products?category_id=" + category.id + "&vendor_id=" + vendor.id)
            .then((res) => {
                setProducts(res.data.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log()
                setLoading(false)
            })
    }, [])

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    const sliderProds = (product) => {
        return (
            <RespTxtDiv>
                <ProductCards
                    data={product}
                    cardNum={cardNum++}
                    // pageNum={pageNum}
                />
                {/* <div className="card latest-prod-card">
          <div className="card-header">
            <div className="latest-prod-img">
              <div className="img-div">
                <img
                  src={product.image_path}
                  alt="Product Image"
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="latest-prod-desc">
              <div>
                <strong>{product.productName}</strong>
              </div>

              <div>
                <span className="price">{product.sale_price}</span>
              </div>

              <Rating
                name="read-only"
                value={product.averageRating}
                readOnly
                className="prod-card-rating-star"
              />
            </div>
          </div>
        </div> */}
            </RespTxtDiv>
        );
    };

    if (!loading) {
        if (products.length == 0) {
            return
        }
    }

    return (
        <div className="latest-products mb-5 custom-slick">
            <h4>{category.name}</h4>
            <div className="latest-products-list mt-4">
                {
                    // !category ?
                }
                <Slider {...sliderSettings}>

                    {products.map((product) => {
                        return (
                            <RespTxtDiv>
                                <ProductCards
                                    data={product}
                                    cardNum={cardNum++}
                                    // pageNum={pageNum}
                                />
                            </RespTxtDiv>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

export default VendorProducts;
