import {faEye} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useQuery} from '@tanstack/react-query';
import MUIDataTable from 'mui-datatables';
import React from 'react'
import {Link} from 'react-router-dom';
import axios from '../../../api.js';
import Loader from '../../Loader';
import {useChkLogin} from '../../../../middlewares/LoginMiddleware.js';

const LabTestBooking = () => {
    // const token = localStorage.getItem('token');
    const {token} = useChkLogin()

    const labBookingsQuery = useQuery(
        ['lab-test-booking'],
        async () => {
            return axios.get('/api/admin/lab-test', {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => res.data)
        }
    )

    if (labBookingsQuery.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }
    const labBookings = labBookingsQuery.data;
    //console.log();

    return (
        // <div className='card'>
        //     <div className="card-body">
        //         <table className='table'>
        //             <thead>
        //                 <tr>
        //                     <th>S.N.</th>
        //                     <th>Lab Test</th>
        //                     <th>Booked Date</th>
        //                     <th>Booked Time</th>
        //                     <th>Amount</th>
        //                     <th>Payment Status</th>
        //                     <th>Booking Status</th>
        //                     <th>Action</th>
        //                 </tr>
        //             </thead>
        //             <tbody>
        //                 {labBookings.map((lab, index) =>
        //                     <tr key={index}>
        //                         <td>{index + 1}</td>
        //                         <td>{lab.service.service_name}</td>
        //                         <td>{lab.date}</td>
        //                         <td>{lab.time}</td>
        //                         <td>Rs. {lab.service.price}</td>
        //                         <td>{lab.status === 1 ? <span className='text-success'>Completed</span> : <span className='text-danger'>Payment Due</span>}</td>
        //                         <td>{lab.booking_status}</td>
        //                         <td>
        //                             <Link to={`/user/lab-test/${lab.id}`} className='btn btn-primary'>
        //                                 <FontAwesomeIcon icon={faEye} />
        //                             </Link>
        //                         </td>
        //                     </tr>
        //                 )}
        //             </tbody>
        //         </table>
        //     </div>
        // </div>
        <LabBookingTable labBookings={labBookings}/>
    )
}

const LabBookingTable = ({labBookings}) => {
    const columns = [
        {
            name: "sn",
            label: "S.N."
        },
        {
            name: "labprofile",
            label: "Lab Profile",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "labTest",
            label: "Lab Test",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "bookDate",
            label: "Booked Date",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "bookTime",
            label: "Booked Time",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "amount",
            label: "Amount",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "paymentStat",
            label: "Payment Status",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "bookingStat",
            label: "Booking Status",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "labActions",
            label: "Actions",
        },
    ]

    const data = []

    console.log('Lab Bookings', labBookings)

    for (let i = 0; i < labBookings.length; i++) {
        let lab = labBookings[i]
        const dataObj = {}
        dataObj.sn = i + 1
        dataObj.labprofile = lab.labprofile_id !== null ? lab.labprofile.profile_name : '-'
        dataObj.labTest = lab.labtest_id !== null ? lab.labtest.tests : '-'
        dataObj.bookDate = lab.date
        dataObj.bookTime = lab.time
        dataObj.amount = `Rs. ${lab.price}`
        dataObj.paymentStat = lab.status === 1 ? <span className='text-success'>Completed</span> :
            <span className='text-danger'>Payment Due</span>
        dataObj.bookingStat = lab.booking_status
        dataObj.labActions = (
            <Link to={`/user/lab-report/${lab.id}`} className='btn btn-primary'>
                <FontAwesomeIcon icon={faEye}/>
            </Link>
        )
        data.push(dataObj)
    }

    const options = {
        filterType: 'checkbox',
        responsive: 'standard'
    };

    return (
        <MUIDataTable
            title={"Lab Test Bookings"}
            data={data}
            columns={columns}
            options={options}
        />
    )
}

export default LabTestBooking
