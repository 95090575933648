/**
 * Generates an array of incremental numbers from 1 to the limit.
 * @param limit - The upper limit of the array.
 * @returns an array of incremental numbers.
 */
const generateIncrementalArray = (limit) => {
  return Array.from({ length: limit }, (_, index) => index + 1);
};

export const phoneNumberRegex = /^\d{1,10}$/;
export const landlineNumberRegex = /^[0-9]{7,9}$/;

/**
 * Adds comma to the number
 * @param number
 * @param format
 * @returns number with comma added
 */
const addCommasToNumber = (number, format = "en-IN") => {
  if (Number.isNaN(number) || number === undefined) {
    return "";
  }
  return number.toLocaleString(format);
};

/**
 * REMOVES EMPTY OR UNDEFINED KEYS FROM AN OBJECT
 * @param params
 * @returns
 */
const filterParams = (params) => {
  Object.keys(params).forEach((key) => {
    if (
      params[key] === undefined ||
      params[key] === "" ||
      params[key]?.length === 0
    ) {
      delete params[key];
    }
  });
  return params;
};

const filterFormData = (formData) => {
  const newFormData = formData;
  const entries = formData.entries();
  let currentEntry = entries.next();

  while (!currentEntry.done) {
    const [key, value] = currentEntry.value;
    if (value !== undefined && value !== null && value !== "") {
      formData.set(key, value);
    } else if (value === "") {
      formData.delete(key);
    }
    currentEntry = entries.next();
  }
  return newFormData;
};

const validatePassword = (password) => {
  return {
    hasLength: password.length >= 8,
    hasNumber: /\d/.test(password),
    hasUpperCase: /[A-Z]/.test(password),
    hasLowerCase: /[a-z]/.test(password),
    hasSpecialChar: /[!@#$%^&*()_\-+={[}\]\\|:;"'<,>.]/.test(password),
  };
};

const checkValidEmail = (email) => {
  const splitEmail = email.split("@");
  if (splitEmail.length < 2) {
    return false;
  }
  const splitEnd = splitEmail[1].split(".");
  if (splitEnd[1] !== "com") {
    return false;
  }
  return true;
};

export {
  filterParams,
  filterFormData,
  addCommasToNumber,
  validatePassword,
  checkValidEmail,
  generateIncrementalArray,
};
