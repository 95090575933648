import React from 'react';
import classNames from 'classnames';
import {FormLabel} from "@mui/material";

const InputFieldLabel = ({ classes, children, required }) => {
    return (
        <label className={classNames(classes, 'text-base')}>
            {children}
            {required && (
                <span className="text-danger pl-0.5" style={{fontSize: 18}}>*</span>
            )}
        </label>
    );
};

export default InputFieldLabel;
