import React from "react";
import NewsItemContainer from "./components/NewsItemContainer";
import ItemDetails from "./components/ItemDetails";
import { Stack } from "@mui/material";
import { useBlogsHome } from "./GDBlogProvider";
import * as dayjs from "dayjs";
import LatestBlogsSkeleton from "./components/LatestBlogsSkeleton";

function LatestBlogs() {
  const { data, isLoading } = useBlogsHome();

  if (isLoading) return <LatestBlogsSkeleton />;

  return (
    <Stack gap={2}>
      {data.map((item, idx) => (
        <NewsItemContainer
          key={item.id}
          mediaComponent={<img src={item.image_path} alt={item.title_en} />}
        >
          <ItemDetails
            title={item.title_en}
            author={item?.user?.name || "Ghargharmadoctor"}
            category={item?.menu?.title_en}
            // description={item.description_en}
            time={dayjs(item.created_at).format("YYYY-MM-DD")}
            blog={item}
          />
        </NewsItemContainer>
      ))}
    </Stack>
  );
}

export default LatestBlogs;
