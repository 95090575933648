import React from 'react'
import NewsItemContainer from './NewsItemContainer';
import {Box, Grid, Skeleton, Stack} from '@mui/material';
import SkeletonNewsItem from './SkeletonNewsItem';

function HomePostSkeleton() {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
                <NewsItemContainer
                    direction='column'
                    mediaComponent={
                        <Skeleton variant="rectangular" width='100%' height='100%'/>
                    }
                >
                    <Box>
                        <Skeleton width='100%' height='40px'/>
                        <Skeleton width='30%' height='40px'/>
                    </Box>
                    <Skeleton width='60%' height='30px'/>
                </NewsItemContainer>
            </Grid>
            <Grid item xs={12} md={4}>
                <Stack gap={2}>
                    <SkeletonNewsItem/>
                    <SkeletonNewsItem/>
                    <SkeletonNewsItem/>
                </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
                <Stack gap={2}>
                    <SkeletonNewsItem/>
                    <SkeletonNewsItem/>
                    <SkeletonNewsItem/>
                </Stack>
            </Grid>


        </Grid>
    )
}

export default HomePostSkeleton
