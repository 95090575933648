const ONLINE_TRANSFER = "online_transfer"
const ONLINE_TRANSFERS = "online_transfers"

const CHEQUES = "cheques"
const CHEQUE = "cheque"

const COLLECTED = "collected"
const DEPOSITED = "deposited"

const DEFAULT_VALUE_ONLINE_TRANSFER = {
    transaction_source: '',
    transaction_date: '',
    amount_transferred: '',
    transaction_receipt: ''
}

const DEFAULT_VALUE_CHEQUE = {
    cheque_number: '',
    cheque_issue_date: '',
    cheque_issue_bank: '',
    cheque_amount: '',

    cheque_status: COLLECTED,

    depositor_name: '',
    deposited_date: '',
    deposited_voucher_scan: '',

    collect_on: ''
}

export {ONLINE_TRANSFER,ONLINE_TRANSFERS, CHEQUES, COLLECTED, DEPOSITED, DEFAULT_VALUE_ONLINE_TRANSFER, DEFAULT_VALUE_CHEQUE, CHEQUE}
