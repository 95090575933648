import axios from "../../api";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { GDStoreContext } from "../../../contexts/GDStoreContext";
import Footer from "../../Footer";
import Header from "./components/Header";
import "./GDStoreLayout.scss";
import { LoginStateContext } from "../../../contexts/LoginStateContext";
import swal from "sweetalert";
import { Box, Card, CardContent, Modal, Typography } from "@mui/material";
import UploadFile from "../../custom_componets/UploadFile";
import { GDCartProvider } from "../../../contexts/provider/GDCartProvider";
import WebFooter from "../WebFooter";
import GDLogo from "../GDStore/components/GDLogo";
import { Link } from "react-router-dom";

function GDStoreLayout() {
  const [cart, setCart] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const location = useLocation();
  const [update, setUpdate] = useState();
  const loginContext = useContext(LoginStateContext);
  const [prescOpen, setPrescOpen] = useState(false);
  const [cartProduct, setCartProduct] = useState(null);
  const [prodCartCount, setProdCartCount] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("cart") == null) {
      localStorage.setItem("cart", JSON.stringify([]));
    }
    setCart(JSON.parse(localStorage.getItem("cart")));
  }, [location, update]);

  const getProductStock = async (slug) => {
    return axios
      .get("api/products?slug=" + slug)
      .then((res) => {
        return res.data.data.stock;
      })
      .catch((err) => {
        // console.log()
      });
  };

  function addProductToCart(product, cartCount, prescription = null) {
    let cart = JSON.parse(localStorage.getItem("cart"));
    let cartFiltered = [];
    console.log();
    cartFiltered = cart.filter((c) => {
      return c.id == product.id;
    });
    let cartUpdateVal = product;
    if (cartFiltered.length > 0) {
      cartUpdateVal = cartFiltered[0];
      cartUpdateVal.cartCount = cartFiltered[0].cartCount + cartCount;
    } else {
      cartUpdateVal.cartCount = cartCount;
    }
    cartUpdateVal.prescription = prescription;
    let arr = cart.filter((c) => {
      return c.id != product.id;
    });
    arr.push(cartUpdateVal);
    localStorage.setItem("cart", JSON.stringify(arr));
    swal("Success", "Product Added To Cart", "success");
  }

  const addToCart = async (product, cartCount) => {
    let prescription = null;
    // if (product.drug_type === "Narcotics") {
    //   setPrescOpen(true)
    //   setCartProduct(product)
    //   setProdCartCount(cartCount)
    //   return
    // }
    addProductToCart(product, cartCount);
  };

  const addToWishList = async (product_id, token) => {
    let postBody = { product_id: product_id };
    try {
      let result = await axios.post(
        "api/admin/wishlist/addToWishlist",
        postBody,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      swal("Success", "Product Added To Wishlist", "success");
    } catch (e) {
      swal("Error", "Product Already Present In Wishlist", "error");
    }
  };

  return (
    <GDStoreContext.Provider
      value={{
        getProductStock: getProductStock,
        setCart: setCart,
        addToCart: addToCart,
        cart: cart,
        wishlist: wishlist,
        setWishlist: setWishlist,
        addToWishList: addToWishList,
        setUpdate: setUpdate,
      }}
    >
      <GDCartProvider>
        <div className="gd-store">
          <Header />
          {/* <Outlet /> */}
          <div className="gd-store-coming-soon">
            <div className="logo">
              <GDLogo />
            </div>
            <div className="coming-soon">
              <span>OPENING</span> <span>SOON</span>
            </div>
            <hr />
            <p>
              We will be there shortly! We constantly work to provide you with
              the greatest experience.
            </p>
            <Link to="/">Back to Home Page</Link>
          </div>

          <PrescriptionModal
            open={prescOpen}
            handleClose={() => {
              setPrescOpen(false);
            }}
            addProductToCart={addProductToCart}
            prodCartCount={prodCartCount}
            cartProduct={cartProduct}
          />
          <WebFooter />
        </div>
      </GDCartProvider>
    </GDStoreContext.Provider>
  );
}

function PrescriptionModal({
  open,
  handleClose,
  addProductToCart,
  prodCartCount,
  cartProduct,
}) {
  const [prescription, setPrescription] = useState(null);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
  };

  function handleSubmit(e) {
    e.preventDefault();
    addProductToCart(cartProduct, prodCartCount, prescription);
    handleClose();
  }

  function handleFile(file) {
    let inputFile = file[0];
    var reader = new FileReader();
    reader.readAsDataURL(inputFile);
    console.log();
    var ext = inputFile.type.split("/")[1];
    reader.onload = () => {
      const strArr = reader.result.split("base64,");
      const base64Str = `data:image/${ext};base64,${strArr[1]}`;
      setPrescription(base64Str);
    };
    reader.onerror = (error) => {
      console.log();
    };
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component={"form"} sx={style}>
        <Card>
          <CardContent>
            <Typography variant="h5">
              Upload Prescription for this product
            </Typography>
            <Typography color="text.secondary">
              You need to provide prescription for narcottic products
            </Typography>
            <UploadFile
              name="prescription"
              label="Upload Prescription"
              type={"img"}
              setImageInput={handleFile}
            />
            <div className="w-100">
              <div
                className="btn btn-success mt-2 mx-auto"
                onClick={handleSubmit}
              >
                Upload Prescription
              </div>
            </div>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}

export default GDStoreLayout;
