import {useQuery} from '@tanstack/react-query'
import React, {useContext, useEffect, useRef, useState} from 'react'
import axios from '../../../api'
import {useChkLogin} from '../../../../middlewares/LoginMiddleware'
import PackagePayment from '../packages/PackagePayment'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import {useLocation, useNavigate} from 'react-router-dom'
import swal from 'sweetalert'
import {Doughnut} from 'react-chartjs-2'
import {Alert, AlertTitle, Typography} from '@mui/material'
import PackageAlert from '../packages/PackageAlert'
import Loader from '../../Loader'
import StudentUnpaid from '../studentLists/StudentUnpaid'
import {SchoolPackageTimeline} from '../dashboardHome/PackageTimeline'
import PaymentHistory from "../school-payment/partials/PaymentHistory";

function SchoolPackage() {
    const loginContext = useContext(LoginStateContext)
    const loginObj = useChkLogin()
    const userData = loginObj.data
    const [interval, setInterval] = useState('');
    const [additionalPayment, setAdditionalPayment] = useState();
    const location = useLocation()
    const paymentElement = useRef(null)
    const navigate = useNavigate();
    const [calcRes, setCalcRes] = useState({
        totalMonth: 0,
        discountTotal: 0,
        discount: 0,
        result: 0,
        total: 0,
    })

    const mypackage = useQuery(
        ['package-details-data', userData.id],
        async () => {
            return axios.get('/api/admin/userpackage', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )

    const {data: calcAmtData, isLoading: calcAmtLoading} = useQuery({
        queryKey: ['calc-amount', userData.id],
        queryFn: async () => {
            return axios.get(`/api/admin/userpackage/calculate-amount/${mypackage?.data?.package?.package_id}`, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}}).then(res => res.data)
        },
        enabled: !!mypackage.data
    })

    const schoolPkg = useQuery(
        ['packages'],
        async () => axios.get('api/package').then(res => {
            return res.data.filter((item) => item.id === 10)[0]
        })
    )
    const csvStatus = useQuery(
        ['csvstatus'],
        async () => axios.get('api/admin/import/status', {headers: {'Authorization': `Bearer ${loginObj.token}`}}).then(res => res.data)
    )


    if (schoolPkg.isLoading || mypackage.isLoading || csvStatus.isLoading) {
        return (
            <div className="w-100 d-flex justify-content-center p-5"><Loader/></div>
        )
    }
    const user = loginObj.data
    const pkg = mypackage.data.package;
    // console.log('School Package Data', schoolPkg.data)

    const months = mypackage.data.month_count;
    const remainingDays = mypackage.data.remainingDays;
    const completedDays = mypackage.data.completedDays;
    const prepay = mypackage.data.prepay;
    const extended_date = mypackage.data.extended_date;
    const expire = mypackage.data.expire;

    const calculateFee = (e) => {
        if (e.target.value === '') {
            setCalcRes({registration: 0, monthly: 0, totalMonth: 0, discountTotal: 0, discount: 0, result: 0})
        } else {
            setInterval(e.target.value)
            const feeInterval = {
                Yearly: {
                    month: 12,
                    discount: 0.05
                },
                Half_Yearly: {
                    month: 6,
                    discount: 0.02
                },
                Quarterly: {
                    month: 3,
                    discount: 0.01
                },
                Monthly: {
                    month: 1,
                    discount: 0
                }
            }
            let paymentInterval = e.target.value;
            let totalmonth = 0;
            let discounttotal = 0;
            let res = 0;
            if (pkg.renew_status === 2) {
                totalmonth = pkg.familyfee.family_size * pkg.familyfee.two_monthly_fee * feeInterval[paymentInterval].month;
                discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
                if (pkg.payment.length === 0) {
                    res = pkg.familyfee.two_continuation_fee + discounttotal;
                } else {
                    res = discounttotal;
                }
                if (pkg.additonal_members > 0) {
                    axios.post(`/api/admin/userpackage/calculate-additional-payment/${pkg.id}`, {payment_interval: paymentInterval}, {headers: {Authorization: `Bearer ${loginContext.state.token}`}})
                        .then((response) => {
                            setAdditionalPayment(response.data)
                            setCalcRes({
                                totalMonth: totalmonth,
                                discountTotal: discounttotal,
                                discount: feeInterval[paymentInterval].discount * 100,
                                result: res,
                                total: res + response.data.total_payment
                            })
                        })
                } else {
                    setCalcRes({
                        totalMonth: totalmonth,
                        discountTotal: discounttotal,
                        discount: feeInterval[paymentInterval].discount * 100,
                        result: res,
                        total: 0
                    })
                }
            } else if (pkg.renew_status === 3) {
                totalmonth = pkg.familyfee.family_size * pkg.familyfee.three_monthly_fee * feeInterval[paymentInterval].month;
                discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
                if (pkg.payment.length === 0) {
                    res = pkg.familyfee.three_continuation_fee + discounttotal;
                } else {
                    res = discounttotal;
                }
                if (pkg.additonal_members > 0) {
                    axios.post(`/api/admin/userpackage/calculate-additional-payment/${pkg.id}`, {payment_interval: paymentInterval}, {headers: {Authorization: `Bearer ${loginContext.state.token}`}})
                        .then((response) => {
                            setAdditionalPayment(response.data)
                            setCalcRes({
                                totalMonth: totalmonth,
                                discountTotal: discounttotal,
                                discount: feeInterval[paymentInterval].discount * 100,
                                result: res,
                                total: res + response.data.total_payment
                            })
                        })
                } else {
                    setCalcRes({
                        totalMonth: totalmonth,
                        discountTotal: discounttotal,
                        discount: feeInterval[paymentInterval].discount * 100,
                        result: res,
                        total: 0
                    })
                }
            } else {
                totalmonth = pkg.familyfee.family_size * pkg.familyfee.one_monthly_fee * feeInterval[paymentInterval].month;
                discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
                if (pkg.payment.length === 0) {
                    res = pkg.familyfee.one_registration_fee + discounttotal;
                } else {
                    res = discounttotal;
                }
                if (pkg.additonal_members > 0) {
                    axios.post(`/api/admin/userpackage/calculate-additional-payment/${pkg.id}`, {payment_interval: paymentInterval}, {headers: {Authorization: `Bearer ${loginContext.state.token}`}})
                        .then((response) => {
                            setAdditionalPayment(response.data)
                            setCalcRes({
                                totalMonth: totalmonth,
                                discountTotal: discounttotal,
                                discount: feeInterval[paymentInterval].discount * 100,
                                result: res,
                                total: res + response.data.total_payment
                            })
                        })
                } else {
                    setCalcRes({
                        totalMonth: totalmonth,
                        discountTotal: discounttotal,
                        discount: feeInterval[paymentInterval].discount * 100,
                        result: res,
                        total: 0
                    })
                }
            }
        }
    }

    const checkFamilySize = () => {
        axios.get(`/api/admin/userpackage/check-family-size/${pkg.id}`, {headers: {Authorization: `Bearer ${loginContext.state.token}`}})
            .then((res) => {
                if (res.data.message === 'success') {
                    navigate(`/${pkg.package.slug}/book-package`)
                } else {
                    swal({
                        title: 'Renew Package',
                        text: res.data.message,
                        icon: "warning",
                    }).then((value) => {
                        if (value) {
                            navigate('/packages')
                        }
                    });
                }
            })

    }

    const data = {
        labels: [
            'Remaining',
            'Completed',
        ],
        datasets: [{
            label: 'Days',
            data: [remainingDays, completedDays],
            backgroundColor: [
                'rgb(54, 162, 235)',
                'rgba(255, 99, 132, 0.2)',
            ],
            hoverOffset: 4,
            weight: 1
        }]
    }

    const daysCountData = {
        labels: [
            'Remaining',
            'Completed',
        ],
        datasets: [{
            label: 'Days',
            data: [366 - mypackage.data.completedDays, completedDays],
            backgroundColor: [
                'rgb(54, 162, 235)',
                'rgba(255, 99, 132, 0.2)',
            ],
            hoverOffset: 4,
            weight: 1
        }]
    }

    return (
        <>
            <PackageAlert user={user} pkg={pkg} prepay={prepay} extended_date={extended_date} expire={expire}
                          checkFamilySize={checkFamilySize} paymentRef={paymentElement}/>
            <Alert severity='info' className="mt-2 mb-4" sx={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;'}}
                   sx={{border: 1, borderColor: "divider"}}
            >
                <AlertTitle>Buy Package</AlertTitle>
                <span className='fs-6 fw-light'>You haven't bought any packages. Buy package now.</span>
                <br/>
                <button className="btn btn-primary mt-2"
                        onClick={() => navigate('/school/payment')}>
                    Buy Package
                </button>
            </Alert>
            <PaymentHistory/>
            {
                pkg == null ? (
                    Object.keys(csvStatus.data).length === 0 ?
                        (

                            <Alert severity='error' sx={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;'}}>
                                <AlertTitle>CSV not uploaded</AlertTitle>
                                <span className='fs-6 fw-light'>Upload CSV to buy package.</span> <br/>
                            </Alert>

                        ) : csvStatus.data.status == 0 ? (
                            <Alert severity='info' color='warning'
                                   sx={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;'}}>
                                <AlertTitle>CSV status</AlertTitle>
                                <span className='fs-6 fw-light'>Your CSV is being verified. You can buy package once its verified.</span>
                                <br/>
                            </Alert>
                        ) : ""
                ) : (
                    <>
                        <div className="mb-4">
                            <StudentUnpaid/>
                        </div>
                        <div className='row mypackages g-4'>

                            <div className="col-md-3">
                                <div className="card h-100">
                                    {pkg.grace_period === 0 &&
                                        <p style={{color: '#787878', fontSize: '12px'}} className='text-center mb-0'><b>Remaining
                                            Payment Days</b></p>}
                                    {pkg.grace_period === 1 && <p style={{color: '#787878', fontSize: '12px'}}
                                                                  className='text-center text-danger mb-0'><b>Extended
                                        Payment Days</b></p>}
                                    {pkg.grace_period === 2 && pkg.package_status === 'Deactivated' &&
                                        <p style={{color: '#787878', fontSize: '12px'}}
                                           className='text-center text-danger mb-0'><b>Deactivated</b></p>}
                                    {pkg.package_status === 'Expired' && <p style={{color: '#787878', fontSize: '12px'}}
                                                                            className='text-center text-danger mb-0'>
                                        <b>Expired</b></p>}
                                    <div className="d-flex justify-content-center"
                                         style={{width: '100%', height: '250px', position: 'relative'}}>
                                        <Doughnut data={data} options={{
                                            responsive: true,
                                            maintainAspectRatio: true,
                                            cutout: '70%',
                                        }}/>

                                        <div style={{
                                            position: 'absolute',
                                            width: '100%',
                                            top: '60%',
                                            left: 'auto',
                                            right: 'auto',
                                            textAlign: 'center',
                                            marginTop: '-28px',
                                            lineHeight: '20px'
                                        }}>
                                            <span style={{
                                                fontSize: '30px',
                                                fontWeight: '600'
                                            }}>{remainingDays} days</span>
                                        </div>
                                    </div>
                                    {
                                        (pkg.dates.length === 0 && pkg.package_status === 'Booked')
                                        &&
                                        <Typography variant='subtitle1' color='error'>* Your days count will start after
                                            first screening date</Typography>
                                    }
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card h-100 ">
                                    <p style={{color: '#787878', fontSize: '12px'}}
                                       className='text-center mb-3'>Remaining Days</p>
                                    <div className="d-flex justify-content-center"
                                         style={{width: '100%', height: '250px', position: 'relative'}}>
                                        <Doughnut data={daysCountData} options={{
                                            responsive: true,
                                            maintainAspectRatio: true,
                                            cutout: '70%',
                                        }}/>

                                        <div style={{
                                            position: 'absolute',
                                            width: '100%',
                                            top: '60%',
                                            left: 'auto',
                                            right: 'auto',
                                            textAlign: 'center',
                                            marginTop: '-28px',
                                            lineHeight: '20px'
                                        }}>
                                            <span style={{
                                                fontSize: '30px',
                                                fontWeight: '600'
                                            }}>{366 - mypackage.data.completedDays} days</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="card h-100 px-2 py-1">
                                    <p style={{color: '#787878', fontSize: '12px'}}
                                       className='text-center mb-3'>Subscription Package</p>
                                    <div className="package-item-circle mx-auto" style={{position: 'relative'}}>
                                        <div style={{
                                            position: 'absolute',
                                            width: '100%',
                                            top: '20%',
                                            left: 'auto',
                                            right: 'auto',
                                            textAlign: 'center'
                                        }}>
                                            <span style={{
                                                fontWeight: '600',
                                                fontSize: '25px'
                                            }}>{pkg.package.package_type}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card h-100 px-2 py-1">
                                    <p style={{color: '#787878', fontSize: '12px'}} className='text-center mb-3'>Family
                                        Size</p>
                                    <div className="package-item-circle mx-auto" style={{position: 'relative'}}>
                                        {pkg.additonal_members > 0 ?
                                            <div style={{
                                                position: 'absolute',
                                                width: '100%',
                                                top: '20%',
                                                left: 'auto',
                                                right: 'auto',
                                                textAlign: 'center'
                                            }}>
                                                <span style={{
                                                    fontWeight: '600',
                                                    fontSize: '35px'
                                                }}>{calcAmtData?.packagefee?.family_size}</span><br/>
                                                <span style={{
                                                    fontWeight: '600',
                                                    fontSize: '12px'
                                                }}>{pkg.additonal_members} Additional Members</span>
                                            </div>
                                            :
                                            <div style={{
                                                position: 'absolute',
                                                width: '100%',
                                                top: '30%',
                                                left: 'auto',
                                                right: 'auto',
                                                textAlign: 'center'
                                            }}>
                                                <span style={{
                                                    fontWeight: '600',
                                                    fontSize: '35px'
                                                }}>{calcAmtData?.packagefee?.family_size}</span><br/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <SchoolPackageTimeline/>
                            </div>

                            <div className={pkg.dates.length !== 0 ? `col-md-6` : `col-md-12`}>
                                <div className="card h-100">
                                    <div className="card-body">
                                        <h3 style={{fontWeight: '700', textAlign: 'center'}} className='mb-4'>Booking
                                            Details</h3>
                                        {
                                            pkg.dates.length == 0 && (
                                                <Alert severity='info' sx={{boxShadow: 1}}>
                                                    <AlertTitle>Screening Date pending.</AlertTitle>
                                                </Alert>
                                            )
                                        }
                                        <table className="table table-responsive-sm">
                                            <tbody>
                                            <tr>
                                                <th style={{fontWeight: '700'}}>Subscription Package</th>
                                                <td>{pkg.package.package_type}</td>
                                            </tr>
                                            <tr>
                                                <th style={{fontWeight: '700'}}>Family Size</th>
                                                <td>{calcAmtData?.packagefee?.family_size} {pkg.additonal_members > 0 ? <> ({pkg.additonal_members} Additional
                                                    Members)</> : ''}</td>
                                            </tr>
                                            <tr>
                                                <th style={{fontWeight: '700'}}>Booked Date</th>
                                                <td>{pkg.booked_date}</td>
                                            </tr>
                                            <tr>
                                                <th style={{fontWeight: '700'}}>Expiry Date</th>
                                                <td>{pkg.expiry_date}</td>
                                            </tr>
                                            <tr>
                                                <th style={{fontWeight: '700'}}>Subscription Status</th>
                                                <td>{pkg.package_status}</td>
                                            </tr>
                                            <tr>
                                                <th style={{fontWeight: '700'}}>Payment Status</th>
                                                {pkg.status === 1 ? <td className='text-success'>Paid</td> :
                                                    <td className='text-danger'>Payment Due</td>}
                                            </tr>
                                            <tr>
                                                {pkg.renew_status >= 2 &&
                                                    <th style={{fontWeight: '700'}}>Renew Status/Renew Year</th>
                                                }
                                                {pkg.renew_status >= 2 &&
                                                    <td>Renewed / Year {pkg.year} </td>
                                                }
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {pkg.dates.length !== 0 &&
                                <div className="col-md-6">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <h3 style={{fontWeight: '700', textAlign: 'center'}}
                                                className='mb-4'>Screening Details</h3>
                                            <table className="table table-responsive-sm">
                                                <tbody>
                                                <tr>
                                                    <th style={{fontWeight: '700'}}>Screening</th>
                                                    <th style={{fontWeight: '700'}}>Screening Date</th>
                                                    <th style={{fontWeight: '700'}}>Screening Status</th>
                                                </tr>
                                                {pkg.dates.map((date) =>
                                                    <tr key={date.id}>
                                                        <td>{date.screening.screening}</td>
                                                        <td>{date.screening_date}</td>
                                                        {date.status === 'Pending' ?
                                                            <td className='text-danger'>{date.status}</td>
                                                            :
                                                            <td className='text-success'>{date.status}</td>
                                                        }

                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            }


                            <div className="col-lg-12">
                                <PackagePayment user={user} pkg={pkg} prepay={prepay} months={months} calcRes={calcRes}
                                                interval={interval} calculateFee={calculateFee}
                                                additionalPayment={additionalPayment}
                                                setAdditionalPayment={setAdditionalPayment}
                                                packagefee={calcAmtData?.packagefee}/>
                            </div>
                            <div className="col-lg-12">
                                <div className="card mt-2">
                                    <div className="card-body">
                                        <div className="row">
                                            {pkg.package_status !== 'Not Booked' &&
                                                <div className="col-md-12">
                                                    <div>
                                                        <div>
                                                            <h3 style={{fontWeight: '700'}}>Subscription Payment
                                                                History</h3>
                                                            <table className="table table-responsive">
                                                                <thead>
                                                                <tr>
                                                                    <th style={{fontWeight: '700'}}>Payment Interval
                                                                    </th>
                                                                    {/* <th style={{fontWeight:'700'}}>Payment Date</th> */}
                                                                    <th style={{fontWeight: '700'}}>Expiry Date</th>
                                                                    <th style={{fontWeight: '700'}}>Paid Amount</th>
                                                                    <th style={{fontWeight: '700'}}>Extended Days</th>
                                                                    <th style={{fontWeight: '700'}}>Prepaid Days</th>
                                                                    <th style={{fontWeight: '700'}}>Payment Status</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {pkg.payment.map((payment) =>
                                                                    <tr key={payment.id}>
                                                                        <td>{payment.payment_interval}</td>
                                                                        {/* <td>{payment.payment_date}</td> */}
                                                                        <td>{payment.expiry_date !== null ? payment.expiry_date :
                                                                            <span className='text-danger'>Package not activated yet.</span>}</td>
                                                                        <td>NPR. {payment.amount}</td>
                                                                        <td>{payment.grace_days !== null ? payment.grace_days : 0} days</td>
                                                                        <td>{payment.prepay_days !== null ? payment.prepay_days : 0} days</td>
                                                                        <td>{payment.expiry_date !== null ? payment.expiry_date > new Date().toISOString().slice(0, 10) ?
                                                                                <span className='text-success'>Paid</span> :
                                                                                <span
                                                                                    className='text-danger'>Expired</span> :
                                                                            <span
                                                                                className='text-success'>Paid</span>}</td>
                                                                    </tr>
                                                                )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )

}

export default SchoolPackage
