import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import axios from "../components/api"
import { LoginStateContext } from '../contexts/LoginStateContext'

function usePostRequest(api, token=false, successFunc = () => { }, failFunc = () => { }) {
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)
    const loginContext = useContext(LoginStateContext)
    const verifyingLogin = loginContext.state.loading
    const loggedIn = loginContext.state.loggedIn

    function submit(data) {
        let header = {}
        if (!submitting) {
            setSubmitting(true)
            setSuccess(null)
            setError(null)
            if (token) {
                header = {
                    headers: { "Authorization": `Bearer ${loginContext.state.token}` }
                }
            }
            if ((!verifyingLogin && loggedIn) || !token) {
                axios.post(api, data, header)
                    .then((res) => {
                        successFunc(res.status, res.data)
                        setSuccess(true)
                        setSubmitting(false)
                    })
                    .catch((err) => {
                        failFunc(err)
                        setError(err)
                        setSubmitting(false)
                    })
            }

        }
    }

    return {
        verifyingLogin, submitting, success, setSuccess, submit
    }

}

export default usePostRequest