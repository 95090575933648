import React, {createContext, useContext, useRef} from 'react'

const WebLayoutContext = createContext(null)

function WebLayoutProvider({children}) {
    const navRef = useRef(null)

    return (
        <WebLayoutContext.Provider value={{
            navRef,
        }}>
            {children}
        </WebLayoutContext.Provider>
    )
}

function useWebNav() {
    const {navRef} = useContext(WebLayoutContext)

    return {navRef}
}

export {WebLayoutProvider, useWebNav}
