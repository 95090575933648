import React from 'react'
import ChartComponent from './pages/dashboard/pathologyreport/ChartComponent'

function DemoChart() {
    let data = [
        {
            date: '2022-01-03',
            value: 4,
            min_range: 3,
            max_range: 5,
            amber_min_range: 2,
            amber_max_range: 6,
            red_min_range: 1,
            red_max_range: 7
        },
        {
            date: '2022-03-03',
            value: 2.3,
            min_range: 3,
            max_range: 5,
            amber_min_range: 2,
            amber_max_range: 6,
            red_min_range: 1,
            red_max_range: 7
        },
        {
            date: '2022-04-03',
            value: 5,
            min_range: 3,
            max_range: 5,
            amber_min_range: 2,
            amber_max_range: 6,
            red_min_range: 1,
            red_max_range: 7
        },
    ]
    return (
        <div style={{width: '400px', height: '350px'}}>
            <ChartComponent data={data} label='HB'/>
        </div>
    )
}

export default DemoChart
