import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import InsuranceInfo from "../../insuranceInfo";
import LabInfo from "../../labtestinfo";

function PackageItem({ className, data, hasBtn = true }) {
  console.log("DAta", data);
  let recommended = data.id == 2 && true;
  let familySize =
    data?.fees?.length &&
    `${data.fees[0]?.family_size || 0} to ${data.fees[data.fees.length - 1]
      ?.family_size || 0}`;

  return (
    <Box
      textAlign={"left"}
      className={`package-item h-100 d-flex flex-column page-loaded`}
    >
      {/* <div className="pg-info-desc">
                <span>MOST POPULAR</span>
            </div> */}
      <div className="pg-title pg-head-txt">
        <span>{data.package_type}</span>
      </div>
      <div className="pg-reg-fee pg-head-txt">
        <span className="pg-reg-label text-muted fs-6 fw-normal">
          Registration Fee:{" "}
        </span>
        <span className="fw-bold"> Rs. {data.registration_fee}/-</span>
      </div>
      <div className="pg-monthly-fee pg-border">
        <div className="pg-label">
          <span>{data.type === 4 ? "Student Size" : "Family Size"}</span>
        </div>
        <div className="pg-head-txt">
          <span className="fw-bold">{familySize}</span> member per package
        </div>
      </div>
      <div className="pg-monthly-fee pg-border">
        <div className="pg-label">
          <span>Monthly Fee</span>
        </div>
        <div className="pg-head-txt">
          Rs.<span>{data.monthly_fee}</span>/- per person
        </div>
      </div>
      <div className="pg-num-visit pg-border">
        <div className="pg-label">
          <span>Number of Home Visit</span>
        </div>
        <div className="pg-head-txt">
          <span>{data.visits} times</span> /Year
        </div>
      </div>
      <div className="pg-num-visit pg-border">
        <div className="items-center pg-label d-flex">
          <span>Number of Lab Test</span>
          <LabInfo id={data?.id} />
        </div>
        <div className="pg-head-txt">
          <span>{data.tests || 0}</span>
        </div>
      </div>
      <>
        <div className="pg-num-visit pg-border">
          <div className="pg-label">
            <span>Free Online Consultation</span>
          </div>
          <div className="pg-head-txt">
            <span>{data.online_consultation} times</span> /Year
          </div>
        </div>
        <div className="pg-num-visit pg-border">
          <div className="items-center pg-label d-flex">
            <span>Insurance Coverage</span>
            <InsuranceInfo id={data?.id} />
          </div>
          <div className="pg-head-txt">
            <span>Rs. {data.insurance_amount}</span>/-
          </div>
        </div>
      </>

      <div className="pg-description">
        <span className="pg-label">What's Included: </span>
        {/* <ul>
                    <li>Pathological Screening</li>
                    <li>Medical Checkup</li>
                    <li>Physical Fitness Plan</li>
                    <li>Nutrition Advice and Diet Plan</li>
                    <li>Free use of GD Application</li>
                    <li>Free Doctor Consultation booking</li>
                    <li>Free Ambulance Services</li>
                    <li>Hospital booking on Payment</li>
                    <li>Free Insurance upto 43.80 lakh/person</li>
                </ul> */}
        <p
          dangerouslySetInnerHTML={{ __html: `${data.description}` }}
          className="mb-0"
        ></p>
      </div>
      {hasBtn && (
        <div className="mt-auto pg-button">
          <Link
            to={`/single-package/${data.slug}`}
            className="px-5 py-2 btn btn-primary"
          >
            Get Started
          </Link>
        </div>
      )}
    </Box>
  );
}

export default PackageItem;
