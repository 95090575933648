import {Alert, AlertTitle, Box} from '@mui/material'
import React from 'react'
import {useNavigate} from 'react-router-dom'

function KycVerification({handleBack, handleNext, allFormData, setAllFormData, errors, setErrors}) {
    const navigate = useNavigate()
    return (
        <React.Fragment>
            <Box>
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    Your Global Form is submitted Successfully. — <strong>We will notify you once it is verified. Thank
                    you for joining us.</strong>
                </Alert>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                    <Box></Box>
                    <Box sx={{flex: '1 1 auto'}}/>
                    <button type="submit" className="btn btn-primary" onClick={(e) => {
                        navigate("/user")
                    }}>
                        Finish
                    </button>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default KycVerification
