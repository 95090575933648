import { Button } from "@mui/material";
import React from "react";
import axios from "../../components/api";

const KhaltiPayment = ( { config, renderView } ) => {
  const key = process.env.REACT_APP_KhaltiPublicTestKey;
  const handlePayment = () => {
    axios.post(
      "/api/admin/payment/initiate",
      config
    ).then(res=>console.log(res)).catch(err=>console.log(err));
  };
  return (
    {
      renderView
    }
  );
};

export default KhaltiPayment;
