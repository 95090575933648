import React from 'react'
import Slider from 'react-slick'
import "./CarouselSlider.scss"

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear'
}

function CarouselSlider({children}) {
    return (
        <div className="carouselSlickSlider">
            <Slider {...settings}>
                {children}
            </Slider>
        </div>
    )
}

export default CarouselSlider
