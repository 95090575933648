import {Box, Card, Tab} from '@mui/material'
import React from 'react'
import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import CustomTab, {AntTabs, OuterTabs} from '../../../custom_componets/CustomTab'

let mapPaths = {
    ['0']: 'pathology-screening',
    ['1']: 'lab-test-report',
    ['2']: 'external-medical-details'
}

function LabReportsPage({tabValue, setTabValue}) {
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box>
            <Card sx={{borderRadius: 0, width: 'fit-content'}}>
                <OuterTabs
                    value={tabValue}
                    onChange={handleChange}
                    variant="scrollable"
                    // scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    <Tab label="Pathology Screening "/>
                    <Tab label="Lab Test Report"/>
                    <Tab label="External Medical Details"/>
                </OuterTabs>
            </Card>
        </Box>
    )
}

export default LabReportsPage
