import {
  faBucket,
  faBullseye,
  faEye,
  faGem,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import BecomeMember from "../../BecomeMember";
import "./AboutPage.scss";
import axios from "../../api";

function AboutPage() {
  const [about, setAbout] = React.useState(null);
  React.useEffect(() => {
    axios
      .get("api/about")
      .then((res) => {
        setAbout(res.data[0]);
      })
      .catch((err) => {
        console.log();
      });
  }, []);

  React.useEffect(() => {
    function resizeListener(e) {
      var textContainer = document.getElementsByClassName("about-slogan")[0];
      var descContainer = document.getElementsByClassName("slogan-desc")[0];
      var slogan = document.getElementsByClassName("about-slogan-details")[0];

      var text = descContainer.textContent;
      var textArr = text.split("");

      var setTxt = [];

      while (textContainer.offsetHeight > slogan.offsetHeight) {
        let popped = textArr.pop();
        setTxt.unshift(popped);

        let reassembled = textArr.join("");
        descContainer.textContent = reassembled;
      }

      var secondTxtContainer = document.getElementsByClassName(
        "slogan-desc-overflow-content"
      )[0];
      var secondtxt = setTxt.join("");
      secondTxtContainer.textContent = secondtxt;
    }

    if (about != null) {
      resizeListener();
      window.addEventListener("resize", resizeListener);
    }

    return () => {
      window.removeEventListener("reize", resizeListener);
    };
  }, [about]);
  return (
    <div className="about-page">
      <div className="inner-package text-center py-5">
        <h1>About Us</h1>
      </div>
      {about != null && (
        <>
          <div className="about-us-section py-5">
            <div className="container py-5">
              <div className="row g-4 about-slogan-container">
                <div className="col-12 col-md-6 h-100 about-slogan-details">
                  <div className="about-slogan">
                    <div className="slogan-title">
                      <h1>{`${about.tagline}`} </h1>
                    </div>
                    <div className="slogan-desc">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${about.what_we_do}`,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 h-100">
                  <div className="about-image h-100">
                    <img
                      src="https://media.istockphoto.com/photos/smiling-medical-team-standing-together-outside-a-hospital-picture-id998313080?k=20&m=998313080&s=612x612&w=0&h=ZnM0_9x63aPOfV4-hmn3QxazCDJpUXymwAEN-Jr5aMo="
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="slogan-desc-overflow-content"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="drive-us">
            <div className="container">
              <div className="row pb-5 g-5">
                <div className="col-12 col-md-6">
                  <div className="drive-us-title d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faEye}
                      className="mr-3 eye-icon text-black about-icons"
                      size="sm"
                    />
                    <span className="">OUR VISION</span>
                  </div>
                  <div className="drive-us-desc">
                    <p
                      dangerouslySetInnerHTML={{ __html: `${about.vision}` }}
                      className="text-white"
                    ></p>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="drive-us-title d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faBullseye}
                      className="mr-3 eye-icon text-black about-icons"
                      size="sm"
                    />
                    <span>OUR MISSION</span>
                  </div>
                  <div className="drive-us-desc">
                    <p
                      dangerouslySetInnerHTML={{ __html: `${about.mission}` }}
                      className="text-white"
                    ></p>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <div className="drive-us-title d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faTrophy}
                      className="mr-3 eye-icon text-black about-icons"
                      size="sm"
                    />
                    <span>OUR GOAL</span>
                  </div>
                  <div className="drive-us-desc">
                    <p
                      dangerouslySetInnerHTML={{ __html: `${about.goal}` }}
                    ></p>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <div className="drive-us-title d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faGem}
                      className="mr-3 eye-icon text-black about-icons"
                      size="sm"
                    />
                    <span>OUR OBJECTIVE</span>
                  </div>
                  <div className="drive-us-desc">
                    <p
                      dangerouslySetInnerHTML={{ __html: `${about.objective}` }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* <BecomeMember /> */}
    </div>
  );
}

export default AboutPage;
