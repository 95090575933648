import {Alert, AlertTitle} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React from 'react'
import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import DocImg from "../../../../../images/doc.png"
import axios from "../../../../api"
import Loader from '../../../Loader'
import NoData from '../../../../custom_componets/NoData'

// import PdfImg from "../../../../images/pdf.png"r

function MedicalReports() {
    const loginContext = useContext(LoginStateContext)
    const user = loginContext.state.data
    const navigate = useNavigate();
    const medicalReportQuery = useQuery(
        ["medical-report-pdf", user.id],
        async () => {
            return axios.get('/api/admin/medical-report/pdf', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        },
    )

    if (medicalReportQuery.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }
    if (medicalReportQuery.isError) {
        return (
            <Alert severity='error'>
                <AlertTitle>Server error</AlertTitle>
            </Alert>
        )
    }
    const reports = medicalReportQuery.data

    const viewReport = (id, name) => {
        navigate(`/user/view-report/${id}/${name}`);
    }


    return (
        <div className="medical-reports h-100">
            <div className="card h-100">
                <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h2 className="card-title m-0">
                                <span className="font-weight-bold">
                                    Medical Reports Document
                                </span>
                            </h2>
                            {/* <span className="text-muted">1-7 December 2022</span> */}
                        </div>
                        <button type="button" className="btn btn-primary-100 text-primary align-self-start">
                            View All
                        </button>
                    </div>
                </div>
                <div className="card-body py-1">
                    {
                        reports.length === 0 ?
                            // <Alert severity="error">
                            //     <AlertTitle>No Data</AlertTitle>
                            //     No Medical Reports Found!
                            // </Alert>
                            <NoData/>
                            :
                            <div className="report-list">
                                {reports.map((report) =>
                                    <div className="row w-100 mb-2">
                                        <div className="col-md-2">
                                            <div className="icon-image">
                                                <img src={DocImg} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="report-title  ">
                                                <h5 className="font-weight-bold m-0" style={{fontSize: '16px'}}>
                                                    {report.report}
                                                </h5>
                                                {/* <span className="text-muted">2 Mb</span> */}
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-end">
                                            <button type='button' className="btn btn-light"
                                                    onClick={() => viewReport(report.id, report.report)}>
                                                <span class="material-symbols-outlined">
                                                    visibility
                                                </span>
                                            </button>
                                        </div>
                                        {/* <div className="col-md-9">
                                    <div className="d-flex align-items-center">
                                        <div className="icon-image">
                                            <img src={DocImg} alt="" />
                                        </div>
                                        <div className="report-title ms-3 ">
                                            <h5 className="font-weight-bold m-0">
                                                {report.report}
                                            </h5>
                                            <span className="text-muted">2 Mb</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="d-flex justify-content-end align-items-center">
                                        <button type='button' className="btn btn-light" onClick={()=>viewReport(report.id, report.report)}>
                                            <span class="material-symbols-outlined">
                                                visibility
                                            </span>
                                        </button>
                                        <button type='button' className="btn btn-light">
                                            <span class="material-symbols-outlined">
                                               download
                                            </span>
                                        </button>
                                    </div>
                                </div> */}
                                    </div>
                                )}
                            </div>

                    }

                </div>
            </div>
        </div>
    )
}


export default MedicalReports
