import {Skeleton, Typography} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import React, {useState} from "react";
import {useContext} from "react";
import {Link} from "react-router-dom";
import {LoginStateContext} from "../../../../../contexts/LoginStateContext";
import axios from "../../../../api";
import {DateFormat} from "../../../../DateFormat";
import InsuranceInfo from "../../../../insuranceInfo";

function getInsuranceStatus(data, isLoading) {
    if (isLoading) return;
    let status = null;
    if (data) {
        status = data.status;
    }
    switch (status) {
        case 0:
            return "In Progress";
            break;
        case 1:
            return "Activated";
            break;
        case 2:
            return "Deactivated";
            break;
        default:
            return "Package Not Booked";
    }
}

function PackageInfo() {
    const loginContext = useContext(LoginStateContext);
    const [insuranceErr, setInsuranceErr] = useState("");
    const mypackage = useQuery(["package-details-data"], async () => {
        return axios
            .get("/api/admin/userpackage", {
                headers: {Authorization: `Bearer ${loginContext.state.token}`},
            })
            .then((res) => res.data);
    });
    const insuranceDetails = useQuery(
        ["insurance", loginContext.state.data.id],
        async () => {
            return axios
                .get("/api/admin/insurance-details", {
                    headers: {Authorization: `Bearer ${loginContext.state.token}`},
                })
                .then((res) => res.data);
        }
    );

    const myInsuranceStatus = useQuery(
        ["insurance_status", loginContext.state.token],
        async () =>
            axios
                .get(`api/insurance`, {
                    headers: {Authorization: `Bearer ${loginContext.state.token}`},
                })
                .then((res) => res.data)
                .catch((err) => {
                    setInsuranceErr(err.response.message.error);
                })
    );

    return (
        <>
            <div className="card-title-outer">
                <h2 className="m-0 p-0 title-text-weight">
                    <b>Package Details</b>
                </h2>
            </div>
            <div className="card shadow-sm">
                <div className="card-body">
                    <div className="row pb-3">
                        <div className="col-6">
                            <span className="text-muted">Subscription Package Type</span>
                            <h3
                                className="m-0 p-0"
                                style={{
                                    fontWeight: "600",
                                    fontSize: "30px",
                                    lineHeight: "1.1",
                                }}
                            >
                                {mypackage.isLoading ? (
                                    <Skeleton
                                        variant="text"
                                        sx={{fontSize: "1.4rem"}}
                                        className="mt-1"
                                    />
                                ) : mypackage.data.package === null ? (
                                    "Package not Booked"
                                ) : (
                                    mypackage.data.package.package.package_type
                                )}
                            </h3>
                            {mypackage.isLoading ? (
                                <Skeleton
                                    variant="rectangular"
                                    width={90}
                                    height={30}
                                    className="mt-2"
                                />
                            ) : (
                                <>
                                    {mypackage.data.package === null ? (
                                        <Link to="/packages" className="btn btn-success w-100">
                                            Buy Package Now
                                        </Link>
                                    ) : mypackage.data.package.package_status === "Not Booked" ? (
                                        <div className="w-100">
                                            <span className="text-danger">*Your Payment is Due</span>
                                            <div className="w-100">
                                                <div className="btn btn-primary w-100">
                                                    Make Payment
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="btn btn-info-100 text-info disabled">
                                            <h6 className="m-0 p-0">Package purchased</h6>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="col-6">
                            <div>
                <span className="text-muted">
                  Subscription Package Start Date
                </span>
                                {mypackage.isLoading ? (
                                    <Skeleton
                                        variant="text"
                                        sx={{fontSize: "1.4rem"}}
                                        className="mt-1"
                                    />
                                ) : (
                                    <h5
                                        className="m-0 p-0 text-success"
                                        style={{fontWeight: "600"}}
                                    >
                                        {mypackage.data.package === null ||
                                        mypackage.data.package.package_status != "Activated"
                                            ? "Not Available"
                                            : `${mypackage.data.package.activated_date}`}
                                    </h5>
                                )}
                            </div>
                            <div className="mt-2">
                <span className="text-muted">
                  Subscription Package End Date
                </span>
                                {mypackage.isLoading ? (
                                    <Skeleton
                                        variant="text"
                                        sx={{fontSize: "1.4rem"}}
                                        className="mt-1"
                                    />
                                ) : (
                                    <h5
                                        className="m-0 p-0 text-danger"
                                        style={{fontWeight: "600"}}
                                    >
                                        {mypackage.data.package === null ||
                                        mypackage.data.package.expiry_date === null
                                            ? "Not Available"
                                            : `${mypackage.data.package.expiry_date}`}
                                    </h5>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row py-3 border-top align-items-center">
                        <div className="col-6">
                            <span className="text-muted">Next Screening Date</span>
                            <div>
                                <div className="btn btn-primary-100 text-primary">
                                    {mypackage.isLoading ? (
                                        <Skeleton
                                            variant="text"
                                            sx={{fontSize: "1.4rem"}}
                                            className="mt-1"
                                        />
                                    ) : (
                                        <>
                                            {mypackage.data.package === null ? (
                                                <span
                                                    className="m-0 p-0 text-primary"
                                                    style={{fontWeight: "600"}}
                                                >
                          Not Available
                        </span>
                                            ) : mypackage.data.package.dates.length === 0 ? (
                                                <span
                                                    className="m-0 p-0 text-primary"
                                                    style={{fontWeight: "600"}}
                                                >
                          In Progress
                        </span>
                                            ) : (
                                                <span
                                                    className="m-0 p-0 text-primary"
                                                    style={{fontWeight: "600"}}
                                                >
                          {DateFormat(
                              mypackage.data.package.dates[
                              mypackage.data.package.dates.length - 1
                                  ].screening_date
                          ).getFullDateText()}
                        </span>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex align-items-center">
                                <span className="text-muted">Insurance Verification</span>
                                <InsuranceInfo/>
                            </div>
                            <div>
                                <div className="btn btn-warning-100 text-warning w-100">
                  <span className="m-0 p-0">
                    {getInsuranceStatus(
                        myInsuranceStatus?.data?.insurance,
                        myInsuranceStatus.isLoading
                    )}
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!mypackage.isLoading &&
                        mypackage.data.package !== null &&
                        mypackage.data.package.package_status === "Booked" &&
                        mypackage.data.package.dates.length === 0 && (
                            <Typography variant="caption" color="error">
                                * Your days count will start after first screening date.
                            </Typography>
                        )}
                </div>
            </div>
        </>
    );
}

export default PackageInfo;
