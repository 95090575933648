import React from 'react'
import Esewa from '../../../images/payment/esewa.png'
// import Khalti from '../../../images/payment/khalti.png'
import ImePay from '../../../images/payment/imepay.png'
import ConnectIPS from '../../../images/payment/connectips.png'
import "../../../components/pages/ChooseDate/BookingPayment.js"
import {useLocation} from 'react-router-dom'
import KhaltiNurse from '../khalti/KhaltiNurse'

const NursePayment = () => {
    const location = useLocation();
    const fee = location.state.fee;
    const id = location.state.id;
    //console.log();
    return (
        <div className="container my-5 booking-payment ">
            <div className="card">
                {location.state.fee !== null &&
                    <div className="card-header">
                        <h5>Payment Amount: <span>Rs. {fee}</span></h5>
                    </div>
                }
                <div className="card-body py-0 px-0">
                    <div className="row align-items-center">
                        <div className="col-md-2 py-4 px-2 text-center">
                            Pay Via:
                        </div>
                        <div className="col-md-10 px-3">
                            <div className="row text-center">
                                <div className="col-lg-3 col-md-6 my-3">
                                    <button type='submit'>
                                        <img src={Esewa} alt=""/>
                                    </button>
                                </div>
                                <div className="col-lg-3 col-md-6 my-3">
                                    {/* <button type='submit'>
                                        <img src={Khalti} alt="" />
                                    </button> */}
                                    <KhaltiNurse amount={fee} id={id} redirectLink="/booking/nurse"/>
                                </div>
                                <div className="col-lg-3 col-md-6 my-3">
                                    <button type='submit'>
                                        <img src={ImePay} alt=""/>
                                    </button>
                                </div>
                                <div className="col-lg-3 col-md-6 my-3">
                                    <button type='submit'>
                                        <img src={ConnectIPS} alt=""/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NursePayment
