import React, { useContext, useState } from "react";
import axios from "../../../api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useChkLogin } from "../../../../middlewares/LoginMiddleware";
import { useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import FormModalContext from "../../../custom_componets/globalFormModal/FormModalContext";
import DeactivateModal from "./DeactivateModal";
import * as dayjs from "dayjs";
import { Box, CircularProgress, Pagination } from "@mui/material";

import {
  CallOutlined,
  ConfirmationNumberOutlined,
  DateRangeOutlined,
  Done,
  GroupOutlined,
  MailOutline,
  SchoolOutlined,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ConsultationCall from "../../../consultation-call";
import SchoolService from "../../../../services/school.service";
import Filter from "./Filter";

function StudentLists() {
  const { token, data: userData } = useChkLogin();
  const { openFormModal } = useContext(FormModalContext);
  const [selected, setSelected] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  /*
   * FETCH OVERALL STUDENTS
   * */
  const { data: studentData, isLoading: studentLoading } = useQuery({
    queryKey: ["student-list", [...searchParams.values()]],
    queryFn: () =>
      SchoolService.getStudents({ ...Object.fromEntries(searchParams) }),
  });

  /*
   * STUDENT DE-ACTIVATION
   * */
  const { mutate: deactivateMutation } = useMutation({
    mutationFn: (data) => SchoolService.deactivate(data),
    onSuccess: (response) => {
      if (response?.status === 200) {
        swal({
          title: "Students Deactivation!",
          text:
            "The selected students deactivation request sent successfully. Waiting for GD Admin approval.",
          icon: "success",
          closeOnClickOutside: false,
        });
        setAllChecked(false);
        setSelected([]);
      }
    },
    onError: (error) => {
      const errors = error?.response?.data?.message?.error;
      if (errors?.length) {
        swal({
          title: "Students Deactivation failed.",
          text: errors[0],
          icon: "error",
          closeOnClickOutside: false,
        });
        setAllChecked(false);
        setSelected([]);
      }
    },
  });

  const { data: pkgData, isLoading: pkgLoading } = useQuery(
    ["mypackage", token],
    async () =>
      axios
        .get("/api/admin/userpackage", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          return res.data;
        })
  );

  function handleCheck(e, val) {
    let value = Number(val);
    setAllChecked(false);
    if (selected?.includes(value)) {
      let filtered = selected?.filter((item) => item !== value) || [];
      setSelected(filtered);
    } else {
      let selArr = selected;
      selArr.push(value);
      setSelected([...selArr]);
    }
  }

  const handleCheckAll = (e) => {
    setAllChecked(e.target.checked);
    let addSel = studentData?.data?.map((item) => item.member.user.id);
    if (e.target.checked) {
      let newArr = addSel?.filter((item) => !selected.includes(item));
      if (newArr?.length) {
        setSelected((prev) => [...prev, ...newArr]);
      }
    } else {
      let newAddSel = selected?.filter((item) => {
        return !!addSel.includes(item.id);
      });
      setSelected((prev) => newAddSel);
    }
  };

  const handlePageChange = (e, page) => {
    setAllChecked(false);
    setSearchParams({ ...Object.fromEntries(searchParams), page });
  };

  const applyFilter = (filters) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      ...filters,
      page: 1,
    });
  };

  function deactivateStd() {
    let paramData = selected.join();
    if (paramData.length === 0) {
      return swal({
        title: "No Students",
        text:
          "You haven't selected any students. Please select students to proceed",
        icon: "error",
      });
    }

    const handleDeactivate = (reason) => {
      deactivateMutation({
        reason,
        id: userData.school_profile.id,
        data: paramData,
      });
    };

    openFormModal(<DeactivateModal deactivateFn={handleDeactivate} />);
  }

  const handleOrder = (type) => {
    let order = isCurrentOrderAsc() ? "class:dsc" : "class:asc";
    setSearchParams({
      ...Object.fromEntries(searchParams),
      ...{ sort: order },
    });
  };

  const isCurrentOrderAsc = () => {
    return searchParams.get("sort") === "class:asc";
  };

  const findCount = (index) => {
    const currentPage =
      studentData?.meta?.current_page &&
      Number(studentData?.meta?.current_page);
    const count =
      studentData?.meta?.per_page && Number(studentData?.meta?.per_page);

    if (currentPage > 1) {
      return count * (currentPage - 1) + index;
    }
    return index;
  };

  const renderLoader = () => {
    return (
      <tr>
        <td colSpan={9}>
          <div className="d-flex gap-3 justify-content-center align-items-center py-4">
            <CircularProgress /> Loading...
          </div>
        </td>
      </tr>
    );
  };

  const renderUI = () => {
    if (studentLoading) {
      return renderLoader();
    }
    if (studentData?.data?.length) {
      return (
        <>
          {studentData?.data?.map((item, idx) => (
            <tr key={item.id}>
              <td>
                <input
                  type="checkbox"
                  value={item.id}
                  style={{ width: 16, height: 16 }}
                  onClick={(e) => handleCheck(e, item.member.user.id)}
                  checked={selected.includes(Number(item.member.user.id))}
                />
              </td>
              <td data-cell="S.N.">{findCount(idx + 1)}</td>
              <td data-cell="Student Name">
                <div>
                  <small className="fw-light">{item.member.gender}</small>
                </div>
                <div>
                  <span className="fs-6">
                    {item.member.user.first_name +
                      " " +
                      (item.member.user.middle_name || " ") +
                      " " +
                      item.member.user.last_name}
                  </span>
                </div>
                <div>
                  <span className="text-primary">
                    {item.member.user.user_name}
                  </span>
                </div>
              </td>
              <td data-cell="Address">
                <span>{item.member.address}</span>
              </td>
              <td data-cell="DOB">
                <span> {dayjs(item.member.dob).format("YYYY-MM-DD")}</span>
              </td>
              <td data-cell="Parent Contact">
                <div className="d-flex gap-1 align-items-center">
                  <MailOutline className="fs-6" />
                  <span>{item.parent_email}</span>
                </div>
                <div className="d-flex gap-1 align-items-center">
                  <CallOutlined className="fs-6" />
                  <span>{item.parent_phone}</span>
                </div>
              </td>
              <td data-cell="Class Detail">
                <div className="d-flex gap-1 align-items-center">
                  <DateRangeOutlined className="fs-6" />
                  <span>{item.year}</span>
                </div>
                <div className="d-flex gap-2">
                  <div className="d-flex gap-1 align-items-center">
                    <SchoolOutlined className="fs-6" />
                    <span>{item.class}</span>
                  </div>
                  <div className="d-flex gap-1 align-items-center">
                    <GroupOutlined className="fs-6" />
                    <span>{item.section}</span>
                  </div>
                  <div className="d-flex gap-1 align-items-center">
                    <ConfirmationNumberOutlined className="fs-6" />
                    <span>{item.roll}</span>
                  </div>
                </div>
              </td>
              <td data-cell="Student Status">
                {pkgData?.package === null ||
                pkgData?.package?.status === "Not Booked" ? (
                  <div
                    className="badge badge-info d-flex align-items-center gap-1"
                    style={{ width: "fit-content" }}
                  >
                    <Done sx={{ fontSize: "14px" }} />
                    <span>Listed</span>
                  </div>
                ) : (
                  <div
                    className="badge badge-success d-flex align-items-center gap-1"
                    style={{ width: "fit-content" }}
                  >
                    <Done sx={{ fontSize: "14px" }} />
                    <span>Activated</span>
                  </div>
                )}
              </td>
              <td data-cell="Remaining Consultations">
                <span
                  className={`fs-6 ${
                    item.member.family.online_consultation <= 3
                      ? "text-danger-consultation"
                      : item.member.family.online_consultation <= 5
                      ? "text-warning"
                      : "text-muted"
                  }`}
                >
                  {item.member.family.online_consultation}
                </span>
              </td>

              <td data-cell="Action">
                <div className={`action-consultation d-flex`}>
                  <ConsultationCall
                    id={item?.member_id}
                    remainingConsultation={
                      item?.member?.family?.online_consultation
                    }
                    consultUserName={`${item?.member?.user?.first_name} ${item?.member?.user?.last_name}`}
                  />
                </div>
              </td>
            </tr>
          ))}
        </>
      );
    }
    return (
      <tr>
        <td colSpan={9}>
          <p className="text-center">No data found.</p>
        </td>
      </tr>
    );
  };

  return (
    <div className="card p-3">
      <Filter
        applyFilter={applyFilter}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      <table
        className="table my-2 responsive-table"
        style={{ background: "#f0f0f0" }}
      >
        <thead>
          <Box component="tr" bgcolor={"#"}>
            <th data-cell="Checkbox">
              <input
                type="checkbox"
                style={{ width: 16, height: 16 }}
                checked={allChecked}
                onChange={handleCheckAll}
              />
            </th>
            <th data-cell="S.N." className="text-muted text-uppercase">
              S.N.
            </th>
            <th data-cell="Student Name">
              <span className="text-muted text-uppercase">Student Name</span>
            </th>
            <th data-cell="Address">
              <span className="text-muted text-uppercase">Address</span>
            </th>
            <th data-cell="DOB">
              <span className="text-muted text-uppercase">DOB</span>
            </th>
            <th data-cell="Parent Contact">
              <span className="text-muted text-uppercase">Parent Contact</span>
            </th>
            <th data-cell="Class Detail">
              <span
                onClick={handleOrder}
                className="d-flex gap-1 text-muted text-uppercase cursor-pointer"
              >
                <span>Class Detail</span>{" "}
                <span className="text-black">
                  {isCurrentOrderAsc() ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </span>
              </span>
            </th>
            <th data-cell="Student Status">
              <span className="text-muted text-uppercase">Student Status</span>
            </th>
            <th data-cell="No. of consultation">
              <span className="text-muted text-uppercase">
                No. of consultation
              </span>
            </th>
            <th data-cell="Action">
              <span className="text-muted text-uppercase"> Action</span>
            </th>
          </Box>
        </thead>
        <tbody
          style={{ background: "#ffffff" }}
          className="border-left border-right shadow-sm"
        >
          {renderUI()}
        </tbody>
      </table>
      <div className="mt-2 d-flex justify-content-between align-items-center">
        <button className="btn btn-primary" onClick={deactivateStd}>
          Deactivate
        </button>
        <Pagination
          count={studentData?.meta?.last_page}
          size="large"
          page={searchParams.get("page") ? Number(searchParams.get("page")) : 1}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default StudentLists;
