import {Alert, AlertTitle, Box} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import axios from '../../../../api'
import React, {useContext, useState} from 'react'
import UnpaidItem from './UnpaidItem'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import FitnessPayment from '../../../fitnessCenter/components/FitnessPayment'
import {useEffect} from 'react'

function UnpaidLists() {
    const loginContext = useContext(LoginStateContext)
    const [open, setOpen] = useState(false)
    const [payItemData, setPayItemData] = useState({})
    const {data, isLoading} = useQuery(
        ['myFitness'],
        async () => axios.get("/api/fitness/fitness-booking", {headers: {"Authorization": `Bearer ${loginContext.state.token}`}}).then(res => res.data)
    )

    const [membershipPackage, setMembershipPackage] = useState([])
    // console.log()

    useEffect(() => {
        if (!isLoading && data !== null && data !== undefined) {
            const filtered = data.filter((item) => item.payment_status === 'unpaid')
            setMembershipPackage(filtered)
        }
    }, [isLoading])

    function handleClose() {
        setOpen(false)
    }

    function handlePay(data) {
        setPayItemData(data)
        setOpen((prev) => !prev)
    }

    if (isLoading) {
        return
    }

    if (data.length === 0) {
        return (
            <Alert severity="error">
                <AlertTitle>No Data Found</AlertTitle>
                We couldn't find any fitness packages
            </Alert>
        )
    }

    return (
        <Box>
            {
                membershipPackage.map((item, idx) => (
                    <UnpaidItem key={idx} data={item} onPay={handlePay}/>
                ))
            }

            {
                open &&
                <FitnessPayment open={open} handleClose={handleClose} data={payItemData}/>
            }
        </Box>
    )
}

export default UnpaidLists
