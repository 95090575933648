import React from 'react'
import NoInternet from '../../images/nointernet.jpg'

function NetworkError({type, description}) {
    return (
        <div className='container'>
            <div className="d-flex flex-column gap-3 align-items-center mt-5">
                <div>
                    <img src={NoInternet} alt="" style={{width: '200px'}}/>
                </div>
                <div>
                    <span className='fs-4 fw-bold'>{type}</span>
                </div>
                <div>
                    <span className='fw-light'>{description}</span>
                </div>
                <div>
                    <button className="btn btn-primary" onClick={() => window.location.reload()}>
                        Refresh
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NetworkError
