import {Box} from "@mui/material"
import Page404 from '../../images/notfound.jpg'

const PageNotFound = () => {
    return (
        <Box sx={{width: "100%", '& img': {width: '100%', objectFit: 'cover'}}}>
            <img src={Page404} alt=""/>
        </Box>
    )
}

export default PageNotFound
