import {useQuery} from '@tanstack/react-query'
import axios from '../../../../api'
import React, {useContext} from 'react'
import {GDVendorContext} from '../GDVendorContext'
import ProductCards from '../../../../stores/ProductCards'
import FitnessContainer from '../../../fitnessCenter/components/FitnessContainer'
import CatalogSlider from '../../gdstorehome/components/CatalogSlider'


const bestSellingslider = {
    responsive: [

        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 760,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
}


function BestSellingProducts() {
    const {vendor} = useContext(GDVendorContext)
    const {data, isLoading} = useQuery(
        ['bestSelling', vendor.id],
        async () => (
            axios.get('/api/products?vendor_id=' + vendor.id).then(res => res.data).catch(err => err)
        )
    )
    if (isLoading) {
        return
    }
    if (data.data.length === 0) {
        return
    }
    return (
        <FitnessContainer title="Best Selling Products">
            <CatalogSlider numItems={5} settingProps={bestSellingslider}>
                {
                    data.data.map((item, idx) => (
                        <div key={idx}>
                            <ProductCards data={item}/>
                        </div>
                    ))
                }

            </CatalogSlider>
        </FitnessContainer>
    )
}

export default BestSellingProducts
