import {Box, Card, Grid} from '@mui/material'
import React, {useRef, useState} from 'react'
import GDVendorProvider from './GDVendorProvider'
import GDVendorRoute from './GDVendorRoute'

function GDVendors() {
    return (
        <GDVendorProvider>
            <GDVendorRoute/>
        </GDVendorProvider>
    )
}


export default GDVendors
