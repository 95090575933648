import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./HealthCenter.scss";
import axios from "./api";
import SliderOne from "./slider-carousels/SliderOne";
import { Card, CardMedia, Stack, Typography } from "@mui/material";
import { Box } from "@mui/material";
import * as dayjs from "dayjs";

function HealthCenter() {
  const [blogs, setBlogs] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    axios.get("api/homepage?take").then((res) => {
      setBlogs(res.data);
    });
  }, []);

  const slickSettings = {
    responsive: [
      {
        breakpoint: 810,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const blogsUI = (blog) => {
    return (
      <Box
        px={0.5}
        sx={{
          "&:hover img": {
            transform: "scale(1.4)",
          },
        }}
      >
        <Card
          elevation={0}
          sx={{ width: "100%", cursor: "pointer", textAlign: "left" }}
          onClick={() => navigate("/blog_detail/" + blog.id)}
        >
          <Box
            width="100%"
            border={1}
            borderColor="divider"
            borderRadius={"5px"}
            overflow="hidden"
            sx={{
              aspectRatio: "1.4/1",
              "& img": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                transition: "transform 300ms",
              },
            }}
          >
            <img src={blog.image_path} alt="" />
          </Box>
          {/* <CardMedia
                        image={blog.image_path}
                        title={blog.title_en}
                        sx={{
                            width: '100%',
                            aspectRatio: '1.5/1',
                            border: 1,
                            borderColor: 'divider',
                            borderRadius: '5px',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                backgroundSize: '160%'
                            }
                        }} /> */}
          <Stack gap={1} mt={1}>
            <Typography
              variant="subtitle1"
              fontSize={14}
              className="text-uppercase"
            >
              {blog?.menu?.title_en}
            </Typography>
            <Typography
              variant="subtitle1"
              fontSize={17}
              lineHeight={1.2}
              fontWeight={600}
            >
              {blog.title_en}
            </Typography>
            <Typography variant="subtitle1">
              {dayjs(blog.created_at).format("YYYY-MM-DD")}
            </Typography>
          </Stack>
        </Card>
      </Box>
    );
  };

  return (
    <div className="container py-5 health-center">
      <div className="row row-eq-height">
        <div className="col-md-8 heading">
          <p>Health Center</p>
          <p>Get health news and articles exclusively at our health center.</p>
        </div>
        <div className="text-end col-md-4">
          <Link to="/blog" className="btn btn-outline-dark services-btn">
            View More
          </Link>
        </div>
      </div>

      <div className="mt-1 row health-news">
        {blogs.length > 0 && (
          <SliderOne numItems={4} settingOverride={slickSettings}>
            {blogs.map((blog) => {
              return blogsUI(blog);
            })}
          </SliderOne>
        )}
      </div>
    </div>
  );
}

export default HealthCenter;
