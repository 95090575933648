import React from 'react'

const BookingDetails = ({labDetails}) => {
    return (
        <div className="col-md-6">
            <div className="card h-100">
                <div className="card-body">
                    <h6><b>Booking Details</b></h6>
                    <table className="table mt-3">
                        <tbody>
                        <tr>
                            <th className='fontStyle'>Booked Date</th>
                            <td>{labDetails.date}</td>
                        </tr>
                        <tr>
                            <th className='fontStyle'>Booked Time</th>
                            <td>{labDetails.time}</td>
                        </tr>
                        <tr>
                            <th className='fontStyle'>Booking Status</th>
                            <td>{labDetails.booking_status}</td>
                        </tr>
                        <tr>
                            <th className='fontStyle'>Amount</th>
                            <td>NPR. {labDetails.price}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default BookingDetails
