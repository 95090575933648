import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import GDLogo from "../../images/logoBeta.png";
// import GooglePlay from "../../images/google.png";
// import AppStore from "../../images/apple.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faFacebook,
  faFacebookF,
  faInstagram,
  // faLinkedin,
  faLinkedinIn,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import WebFooterProvider, {
  useFooterAbout,
  useFooterServices,
} from "./WebFooterProvider";
import { Link } from "react-router-dom";
import { Call, LocationOn, Mail } from "@mui/icons-material";
import Logo from "../../images/blue-logo.png";
import LeafletMap from "../leafletMap/LeafletMap";

const layoutSpacing = 4;

const IconContainer = ({ bgcolor, children }) => {
  return (
    <Box
      sx={{
        background: bgcolor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        width: "30px !important",
        height: "30px !important",
        aspectRatio: "1/1",
        color: "#fff !important",
        fontSize: "18px",
        position: "relative",
        zIndex: 99,
      }}
    >
      {children}
    </Box>
  );
};

const socialIcons = {
  linkedin: (
    <IconContainer bgcolor="#0077B5">
      <FontAwesomeIcon icon={faLinkedinIn} color="white" />
    </IconContainer>
  ),
  facebook: (
    <IconContainer bgcolor="#0D8CF1">
      <FontAwesomeIcon icon={faFacebookF} color="white" />
    </IconContainer>
  ),
  youtube: (
    <IconContainer bgcolor="#FD0000">
      <FontAwesomeIcon icon={faYoutube} color="white" />
    </IconContainer>
  ),
  instagram: (
    <IconContainer bgcolor="radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)">
      <FontAwesomeIcon icon={faInstagram} color="white" />
    </IconContainer>
  ),
  twitter: (
    <IconContainer bgcolor="#1DA1F2">
      <FontAwesomeIcon icon={faTwitter} color="white" />
    </IconContainer>
  ),
  tiktok: (
    <IconContainer bgcolor="#000000">
      <FontAwesomeIcon icon={faTiktok} color="white" />
    </IconContainer>
  ),
};

const FooterServices = () => {
  const { data, isLoading } = useFooterServices();

  return (
    <Grid item xs={12} md={6} lg={2}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <FooterItemContainer>
          <FooterItemHeader title="Our Services" />

          <Box className="our-services-links">
            {data.map((item, idx) => (
              <Link
                to={`/service/${item.slug}`}
                key={idx}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <FooterItemSubHeader title={item.service_title} hover />
              </Link>
            ))}
          </Box>
        </FooterItemContainer>
      )}
    </Grid>
  );
};

const latitude = 27.6964243;
const longitude = 85.3537138;

const FooterMap = () => {
  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      sx={{ textAlign: { xs: "center", lg: "left" } }}
    >
      <Box>
        <LeafletMap
          style={{ border: 0, width: "100%", aspectRatio: "1.5/1" }}
          aspectRatio="1.5/1"
          initPos={{ lat: latitude, lng: longitude }}
        />
      </Box>
    </Grid>
  );
};

const FooterContact = () => {
  const { data, isLoading } = useFooterAbout();
  return (
    <Grid item xs={12} md={6} lg={3}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <FooterStack>
          <FooterItemContainer>
            <FooterItemHeader title="Contact Us" />
            <Box>
              <FooterItemList>
                <Call />
                <FooterItemSubHeader title={data[0].phone_no} />
              </FooterItemList>
              <FooterItemList>
                <Mail />
                <FooterItemSubHeader title={data[0].email} />
              </FooterItemList>
              <FooterItemList>
                <LocationOn />
                <FooterItemSubHeader title={data[0].address} />
              </FooterItemList>
            </Box>
          </FooterItemContainer>
          <FooterItemContainer>
            <FooterItemHeader title="Follow Us" />
            <Box>
              <SocialMediasList />
            </Box>
          </FooterItemContainer>
        </FooterStack>
      )}
    </Grid>
  );
};

const FooterGD = () => {
  const { isLoading } = useFooterAbout();
  return (
    <Grid item xs={12} md={6} lg={3}>
      <FooterStack>
        <Box>
          <Box
            display="flex"
            alignItems={"center"}
            gap={1}
            sx={{
              "& img": {
                flexShrink: 0,
                height: "100px",
              },
            }}
          >
            <img
              src={Logo}
              alt="Ghargharma Doctor"
              style={{ width: "80%", height: "auto" }}
            />
          </Box>
          <Box>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Typography variant="h6">
                Nepal's Best Preventive Healthcare Platform.
              </Typography>
            )}
          </Box>
        </Box>

        {/* <Box>
          <FooterItemContainer>
            <FooterItemList>
              <Box
                sx={{
                  "& img": {
                    width: "100%",
                  },
                }}
              >
                <img src={GooglePlay} />
              </Box>
              <Box
                sx={{
                  "& img": {
                    width: "100%",
                  },
                }}
              >
                <img src={AppStore} />
              </Box>
            </FooterItemList>
          </FooterItemContainer>
        </Box> */}
      </FooterStack>
    </Grid>
  );
};

const SocialMediasList = () => {
  const { data, isLoading } = useFooterAbout();
  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <Box display="flex" alignItems="center" flexWrap={"wrap"} gap={2}>
      {data.length > 0 &&
        Object.keys(socialIcons).map(
          (item, idx) =>
            data[0][item] !== null && (
              <a
                href={data[0][item]}
                target="_blank"
                rel="noreferrer"
                style={{ lineHeight: 0 }}
                key={idx}
              >
                {socialIcons[item]}
              </a>
            )
        )}
    </Box>
  );
};

function WebFooterRender() {
  const [firstCol, setFirstCol] = useState("services");
  const [secondCol, setSecondCol] = useState("map");
  const [thirdCol, setThirdCol] = useState("contact");
  const [fourthCol, setFourthCol] = useState("gdinfo");

  const theme = useTheme();

  const lgXlMatched = useMediaQuery(theme.breakpoints.up("lg"));
  const mdMatched = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    if (lgXlMatched) {
      setFirstCol("services");
      setSecondCol("map");
      setThirdCol("contact");
      setFourthCol("gdinfo");
    }
  }, [mdMatched, lgXlMatched]);

  function renderFooterComponent(componentName) {
    if (componentName === "services") return <FooterGD />;
    if (componentName === "map") return <FooterServices />;
    if (componentName === "contact") return <FooterMap />;
    if (componentName === "gdinfo") return <FooterContact />;
  }

  return (
    <Box bgcolor={"#C6DFF6"} textAlign={"left"}>
      <Box className="container" py={8}>
        <Grid container spacing={layoutSpacing}>
          {renderFooterComponent(firstCol)}
          {renderFooterComponent(secondCol)}
          {renderFooterComponent(thirdCol)}
          {renderFooterComponent(fourthCol)}
        </Grid>
      </Box>
      <Box bgcolor={"#d7e9f9"} py={1}>
        <Box className="container">
          {/* <Box
                        sx={{
                            '& img': {
                                height: '80px'
                            }
                        }}
                    >
                        <img src={GDLogo} alt="Ghargharma Doctor" />
                    </Box> */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            gap={layoutSpacing}
            rowGap={0}
          >
            <div className="d-flex justify-content-center align-align-items-center gap-4">
              <Link
                to="/gd-store"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <FooterItemSubHeader title="GD Store" hover />
              </Link>
              <Link
                to="/career"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <FooterItemSubHeader title="Career" hover />
              </Link>
              <Link
                to="/blog"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <FooterItemSubHeader title="Health Center" hover />
              </Link>
              <Link
                to="/about"
                style={{ textDecoration: "none", color: "inherit" }}
                hover
              >
                <FooterItemSubHeader title="About Us" hover />
              </Link>
              {/* <a href="https://forms.gle/Pw8afXCXuAbyDLgv6" target='_blank'></a> */}
              <a
                href="/contact"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <FooterItemSubHeader title="Enquiry" hover />
              </a>
            </div>

            <div className="d-flex justify-content-center align-align-items-center gap-4">
              <Link to="/policy" style={{ color: "inherit" }} hover>
                <FooterItemSubHeader title="Privacy Policy" hover />
              </Link>

              <Link to="/termscondition" style={{ color: "inherit" }} hover>
                <FooterItemSubHeader title="Terms of Use" hover />
              </Link>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function WebFooter() {
  return (
    <WebFooterProvider>
      <WebFooterRender />
    </WebFooterProvider>
  );
}

function FooterStack({ children }) {
  return <Stack gap={layoutSpacing}>{children}</Stack>;
}

function FooterItemContainer({ children }) {
  return <Stack gap={1}>{children}</Stack>;
}

function FooterItemList({ children }) {
  return (
    <Stack
      flexDirection="row"
      gap={1}
      sx={{ width: "100%", alignItems: "center" }}
    >
      {children}
    </Stack>
  );
}

function FooterItemHeader({ title }) {
  return (
    <Typography variant="h5" fontWeight={600}>
      {title}
    </Typography>
  );
}

function FooterItemSubHeader({ title, hover = false }) {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        ...(() =>
          hover && {
            "&:hover": { color: "#0259A7", transform: "scale(1.05)" },
          })(),
      }}
    >
      {title}
    </Typography>
  );
}

export default WebFooter;
