import React, {useState, useEffect, useRef} from 'react'
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import Image from '../images/image.png'
import Slider1 from '../images/slider.png'
import axios from './api.js';
import '../components/Slider.css'
import {Link} from 'react-router-dom';
import Slider from "react-slick";
import {useWebNav} from './layouts/WebLayoutProvider';
import SliderOne from './slider-carousels/SliderOne';
import {Box, Skeleton, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';

const HomeSlider = () => {
    const [sliders, setSlider] = useState([]);
    const [isLoading, setLoading] = useState(true)
    const {navRef} = useWebNav()
    const sliderRef = useRef(null)
    const overlayRef = useRef(null)

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 0,
    };

    // useEffect(() => {
    //     console.log()
    //     sliderRef.current.style.height = `calc(100vH - ${navRef.current.offsetHeight}px)`
    //     overlayRef.current.style.height = `calc(100vH - ${navRef.current.offsetHeight}px)`
    // },[])

    useEffect(() => {
        axios
            .get("/api/slider")
            .then((res) => {
                setSlider(res.data);
            })
            .catch((err) => {
                console.log();
            }).finally(() => {
            setLoading(false)
        });

    }, []);

    if (isLoading) {
        return (
            <div className="container-fluid px-0 sliders">
                <Box className='slide-skeleton item' bgcolor={grey[300]}>
                    <Box
                        position='absolute'
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                    >
                        <div className="container h-100 d-flex align-items-center">
                            <Box className="description" width='100%' maxWidth={'600px'} textAlign='left'>
                                <Skeleton variant='rectangular' width='80%' height='40px'/>
                                <Skeleton variant='rectangular' width='100%' height='40px' sx={{mt: 1}}/>
                                <Skeleton variant='rectangular' width='30%' height='40px' sx={{mt: 1}}/>
                                <Skeleton variant='rectangular' width='100%' height='24px' sx={{mt: 3}}/>
                                <Skeleton variant='rectangular' width='50%' height='24px' sx={{mt: 1}}/>
                                <Skeleton variant='rectangular' width='150px' height='50px' sx={{mt: 3}}/>
                                {/* <Link to="/booking" className='btn btn-primary mt-4'>Make an Appointment</Link> */}
                            </Box>
                        </div>
                    </Box>
                </Box>
            </div>
        )
    }
    return (
        <div className="container-fluid px-0 sliders">
            {/* <OwlCarousel items={1}
            className="owl-theme sliders"
            responsiveClass={true}
            dots={false}>  */}

            <SliderOne numItems={1}
                       settingOverride={{fade: true, cssEase: 'linear', infinite: 'true', autoplay: 'true'}}>
                {
                    sliders.map((slider, idx) => (
                        <div className='item w-100' style={{zIndex: '9'}}>
                            <img src={slider.image_path} alt="" className="slider" ref={sliderRef} loading='lazy'/>
                            <div className='overlay-slider' ref={overlayRef}></div>
                            <Box
                                position='absolute'
                                top={0}
                                left={0}
                                right={0}
                                bottom={0}
                            >
                                <div className="container h-100 d-flex align-items-center">
                                    <Box className="description" width='100%' maxWidth={'600px'} textAlign='left'>
                                        <Typography variant='h2' fontSize={{xs: '24px', md: '60px'}} fontWeight='800'
                                                    lineHeight={1}
                                                    className='text-white'>{slider.banner_title}</Typography>
                                        <Typography sx={{mt: 1}} variant='subtitle1' fontSize={{xs: '14px', lg: '24px'}}
                                                    className='text-white'
                                                    lineHeight={1}>{slider.banner_body} </Typography>
                                        {/* <Link to="/booking" className='btn btn-primary mt-4'>Make an Appointment</Link> */}
                                    </Box>
                                </div>
                            </Box>

                        </div>
                    ))
                }

            </SliderOne>
        </div>
    )
}

export default HomeSlider
