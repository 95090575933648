import React, {useState} from 'react'
import axios from '../api.js';
import {Link, useNavigate, useLocation} from "react-router-dom";
import Logo from '../../images/white-logo.png';
import Background from '../../images/bg.png';
import '../../components/auth/Login.css'
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import swal from "sweetalert";
import {LogInContext} from "../../contexts/LogInContext"
import AuthButton from '../custom_componets/AuthButton.jsx';

function ForgotPassword() {
    const [token, setToken] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [errorMsg, setErrorMsg] = useState();
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false)
    let navigate = useNavigate();
    const location = useLocation();

    const handlesubmit = async e => {
        e.preventDefault();
        setSubmitting(true)
        setErrors({})
        console.log()
        axios.post("api/forgot-password", {email})
            .then(res => {
                setSubmitting(false)
                console.log()
                navigate(`/reset-password?email=${email}`)
            })
            .catch((err) => {
                console.log()
                setSubmitting(false)
                setErrors(err.response.data.errors)
                setErrorMsg('Invalid Credentials');
                window.setTimeout(() => {
                    setErrorMsg(null);
                })
            })
    }
    return (
        <div className="container-fluid login auth">
            <div className="row">
                <div className="col-lg-6 image-side px-0">
                    <img src={Background} alt="" className='bg-image'/>
                    <div className="image-overlay"></div>
                    <div className="text-center bg-logo mx-auto">
                        <img src={Logo} alt=""/>
                    </div>
                </div>
                <div className="col-lg-6 offset-lg-6 login-form">
                    <div className="card border-0">
                        <div>
                            <h3 className="card-title">Forgot your password?</h3>
                            <p>Please enter your account that you want to reset the password.</p>
                        </div>
                        <div className="card-body px-0">
                            {errorMsg &&
                                <div className="alert alert-danger alert-dismissible fade show">
                                    {errorMsg}
                                </div>
                            }
                            <form action="" method="post" onSubmit={handlesubmit}>
                                <div className="form-group">
                                    <label htmlFor="" className='form-label'>Email</label> <br/>
                                    <input type="email" name="email" className='form-control input-field' required
                                           onChange={e => setEmail(e.target.value)}
                                           placeholder="Please enter your email"/>
                                    {errors.email && <p className='text-danger'>{errors.email}</p>}
                                </div>
                                <div className='d-grid'>
                                    <AuthButton
                                        type='submit'
                                        component='button'
                                        className="btn btn-primary login-btn"
                                        label="Send Password Reset Link"
                                        loading={submitting}
                                    />
                                    {/* <button type='submit' className='btn btn-primary login-btn'>Send Password Reset Link</button> */}
                                </div>
                                <div className='text-center mt-3'>
                                    <p><Link to="/login" className='signup-btn'>Go Back</Link></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
