import { Typography } from "@mui/material";
import React from "react";
import InfoModal from "../infoModal";
import { InsuranceInfoData } from "../../utils/constant/common";

const InsuranceModal = () => {
  return (
    <Typography>
      <h6 className="fw-bold mb-4">Insurance Information</h6>

      <table>
        <tr>
          <th className="border p-2">Coverage</th>
          <th className="border p-2">Insured Amount/ Person (Rs.)</th>
        </tr>{" "}
        {InsuranceInfoData.map((item) => (
          <tr>
            <td className="border p-2">{item?.coverage}</td>
            <td className="border p-2">{item?.amount}</td>
          </tr>
        ))}
      </table>
    </Typography>
  );
};

const InsuranceInfo = () => {
  return (
    <InfoModal
      title="Click to view insurance informations."
      modal={<InsuranceModal />}
    />
  );
};

export default InsuranceInfo;
