import React from "react";
import Appointment from "../../../components/Appointment";
import TopDoctors from "../../../components/TopDoctors";
import Advertisement from "../../../components/Advertisement";
import MainServices from "../../../components/MainServices";
import Packages from "../../../components/Packages";
import HomeSlider from "../../../components/HomeSlider";
import Services from "../../../components/Services";
import Footer from "../../Footer";
import FooterBottom from "../../FooterBottom";
import TrustedVendors from "../../TrustedVendors";
import SymptomIdentify from "../../SymptomIdentify";
import HealthCenter from "../../HealthCenter";
import BecomeMember from "../../BecomeMember";
import Slick3d from "../../Slick3d";
import HomeEventComp from "../../events/HomeEventComp";
import DoctorHome from "./DoctorHome";

const Home = () => {
  return (
    <div>
      <HomeSlider />
      <Services />
      {/* <Packages /> */}
      <Slick3d />
      <Appointment />
      {/* <TopDoctors/> */}
      <DoctorHome />
      <HealthCenter />
      <SymptomIdentify />
      <MainServices />
      <TrustedVendors />
      <Advertisement />
      <HomeEventComp />
    </div>
  );
};

export default Home;
