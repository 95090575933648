import http from "../../../utils/http/http.utils";

class ContactService {
  static async submitContactForm(data) {
    try {
      return await http().post("/api/contact-information", data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}

export default ContactService;
