import React, {useState} from "react";
import ParentChildrenSelection from "../medical-history/partials/ParentChildrenSelection";
import UploadedVaccination from "./partials/UploadedVaccination";
import VaccinationInfo from "./partials/VaccinationInfo";

const Vaccination = () => {
    const [childId, setChildId] = useState(undefined)
    const handleChange = (id) => setChildId(id)

    return (
        <div className="d-grid gap-1">
            <ParentChildrenSelection handleChange={handleChange} />
            <div className="d-grid gap-3">
                <VaccinationInfo childId={childId} />
                <UploadedVaccination childId={childId}/>
            </div>
        </div>
    )
}

export default Vaccination
