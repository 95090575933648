import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import ParentService from "../../../../../services/parent.service";

const formatItem = (item) => {
  return {
    label: `${item?.first_name} ${item?.last_name}`,
    value: item?.id,
  };
};

const ParentChildrenSelection = (props) => {
  const { handleChange } = props;
  const [value, setValue] = useState(undefined);

  /*
   * CHILDREN FETCHING
   * */
  const { data } = useQuery({
    queryKey: ["parent-children"],
    queryFn: () => ParentService.fetchChildren(),
    onSuccess: (response) => {
      const data = response?.data;
      if (data?.length === 1) {
        const item = formatItem(data[0]);
        setValue(item);
        handleChange(item?.value);
      }
    },
  });

  const formattedChildrenOptions = data?.data?.map(formatItem);

  const onChange = (e) => {
    setValue(e.target);
    handleChange(e.target?.value);
  };

  return (
    <div className="d-flex flex-column pb-4">
      <label>Select children</label>
      <Select
        color="primary"
        defaultValue={value?.value}
        key={value}
        disableUnderline
        renderValue={
          value !== ""
            ? undefined
            : () => <div style={{ color: "#B1B1B1" }}>Select a field</div>
        }
        classes="custom-select"
        sx={{
          background: "#fff",
          borderRadius: "6px",
          height: 44,
          paddingLeft: "12px",
          marginTop: 0,
          width: 200,
        }}
        onChange={(e, data) => onChange(e)}
      >
        {formattedChildrenOptions?.length === 1 ? (
          <MenuItem key={"test"} color="primary" value={value?.value}>
            {value?.label}
          </MenuItem>
        ) : (
          formattedChildrenOptions?.map((item) => (
            <MenuItem
              key={item.value}
              color="primary"
              value={item.value}
              selected
            >
              {item?.label}
            </MenuItem>
          ))
        )}
      </Select>
    </div>
  );
};

export default ParentChildrenSelection;
