import axios from '../api'
import React, {useEffect} from 'react'

async function captchaVerify(secret, token) {
    const data = {
        token: token
    }
    try {
        let response = await axios.post(`/api/recaptcha`, data)
        console.log()
        return response
    } catch (err) {
        console.log()
        console.log()
    }

}

export default captchaVerify
