import {useState, useEffect} from "react";
import {useParams, useNavigate} from "react-router-dom";
import axios from "../api.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCartArrowDown,
    faHeart,
    faChevronRight,
    faBars,
    faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "@mui/material/Slider";
import {Rating} from "@mui/material";
import {ACTIONS} from "../pages/stores/Store";

const StoreFilterBar = (props) => {
    const [categories, setCategories] = useState([]);
    const [catLoading, setCatLoading] = useState(true);

    const [brands, setBrands] = useState([]);
    const [brandLoading, setBrandLoading] = useState(true);

    const [priceRange, setPriceRange] = useState([0, 1000]);
    const [searchKey, setSearchKey] = useState("");

    const toggleClicked = props.toggleClicked

    const setSearchParams = props.setSearchParams;
    const loadingRoller = props.loadingRoller;

    const currentParams = props.currentParams;

    let params = useParams();
    console.log()

    const navigate = useNavigate();

    const [filterToggleStatus, setFilterToggleStatus] =
        useState("collapse-filter");

    const fetchCategories = (params) => {
        let apiLink = `api/categories`
        if (params.vendorType) {
            apiLink += `/vendortype`
        }
        axios.get(apiLink + params).then((res) => {
            setCategories(res.data);
            setCatLoading(false);
        })
            .catch((err) => {
                console.log()
                setCatLoading(false);
            })
    }

    useEffect(() => {
        // let getParam = new URLSearchParams([["vendor_type", params.vendorType]]);
        if (params.vendorType != null) {
            fetchCategories("?vendor_type_id=" + params.vendorType)
        }
        if (params.brand != null) {
            fetchCategories("?brand=" + params.brand)
        }
        fetchCategories('')

        axios
            .get("api/brand")
            .then((res) => {
                setBrands(res.data);
                setBrandLoading(false);
            })
            .catch((err) => {
                console.log();
                setBrandLoading(false);
            });
    }, [params.vendorType]);

    useEffect(() => {
        if (params.category != null) {
            categories.map((category) => {
                if (category.id == params.category) {
                    props.setTitle(category.name)
                }
            })
        }
        if (params.brand != null || currentParams.brand != null) {
            brands.map((brand) => {
                if (brand.id == params.brand || brand.id == currentParams.brand) {
                    props.setTitle(brand.brand_name)
                }
            })
        }
    }, [params, categories, brands])

    useEffect(() => {
        if (toggleClicked) {
            toggleSideBar()
        }
    }, [toggleClicked])

    const setFilter = (filterObj) => {
        setSearchParams({...currentParams, ...filterObj})
    }

    const setBrandFilter = (e) => {
        setFilter({brand: e.target.value});
    };

    const setCatFilter = (e, vendorType) => {
        function filter(filterP) {
            if (params[filterP] == null)
                return ''
            return `${filterP}=${params[filterP]}`
        }

        navigate(
            `/gd-store/filter/${vendorType}/${e.target.getAttribute(
                "data-filter-value"
            )}?${filter('brand')}`
        );
    };

    //updates the price range
    const updateRange = (e, item) => {
        setPriceRange(item);
    };

    const updateSearchRange = (e) => {
        let timeout = setTimeout(() => {
            setFilter({min_price: priceRange[0], max_price: priceRange[1]})

            clearTimeout(timeout);
        }, 300);
    };

    const searchHandler = (e) => {
        setSearchKey(e.target.value);
        setFilter({keyword: e.target.value})
    };

    const toggleSideBar = (e) => {
        if (filterToggleStatus == "expand-filter") {
            setFilterToggleStatus("collapse-filter");
        } else {
            setFilterToggleStatus("expand-filter");
        }
    };

    const renderCatList = () => {
        if (catLoading) {
            return loadingRoller;
        }
        return categories.map((category) => {
            if (category.parent_id == null) {
                return (
                    <li
                        className="list-group-item"
                        data-filter-name="category_id"
                        data-filter-value={category.id}
                        onClick={(e) => setCatFilter(e, category.vendor_type_id)}
                    >
                        {category.name}
                    </li>
                );
            }
        });
    };

    const renderBrands = () => {
        if (brandLoading) {
            return loadingRoller;
        } else {
            return brands.map((brand) => {
                return (
                    <li
                        className="list-group-item"
                        key={brand.id}
                        onClick={setBrandFilter}
                        value={brand.id}
                    >
                        {brand.brand_name}
                    </li>
                );
            });
        }
    };

    const renderRatingFilter = () => {
        let ratingFilter = [];
        for (let i = 4; i > 0; i--) {
            ratingFilter.push(
                <li className="list-group-item d-flex" onClick={(e) => {
                    setFilter({rating: i})
                }}>
                    <Rating name="half-rating-read" value={i} readOnly size="medium"/>
                    <span className="mx-2">And Up</span>
                </li>
            );
        }
        return ratingFilter;
    };

    return (
        <div className="filter-side-bar">
            <div className="side-bar-expand">
                <div className={"side-bar-filter " + filterToggleStatus}>
                    <div className="close-side-bar w-100 d-flex justify-content-end mb-4">
                        <FontAwesomeIcon
                            icon={faCircleXmark}
                            size="xl"
                            onClick={() => {
                                toggleSideBar();
                                props.chkToggleClick()
                            }}
                        />
                    </div>
                    {/* <div class="input-group mb-4 search-bar">
            <input
              type="search"
              class="form-control rounded"
              placeholder="Search for products"
              aria-label="Search"
              aria-describedby="search-addon"
              onChange={searchHandler}
            />
          </div> */}

                    <h5 className="filter-title">Categories</h5>
                    <div className="filter-list mb-4">
                        <ul className="list-unstyled list-group">{renderCatList()}</ul>
                    </div>

                    <h5 className="filter-title">Brands</h5>
                    <div className="filter-list mb-4">
                        <ul className="list-unstyled list-group">
                            {renderBrands()}
                            <li className="d-grid list-group-item">
                                <a href="" className="btn btn-primary p-1 rounded">
                                    View More
                                </a>
                            </li>
                        </ul>
                    </div>

                    <h5 className="filter-title">Price Range</h5>
                    <div className="filter-list mb-4">
                        <div className="row">
                            <div className="col-6 min-max-div">
                                <label>Min</label>
                                <div className="price-input">
                                    <input
                                        type="text"
                                        value={priceRange[0]}
                                        onChange={(e) => {
                                            setFilter({min_price: e.target.value})
                                            setPriceRange([e.target.value, priceRange[1]]);
                                        }}
                                        className="form-control text-center"
                                    />
                                </div>
                            </div>
                            <div className="col-6 min-max-div">
                                <label>Max</label>
                                <div className="price-input">
                                    <input
                                        type="text"
                                        value={priceRange[1]}
                                        className="form-control text-center"
                                    />
                                </div>
                            </div>
                        </div>
                        <Slider
                            value={priceRange}
                            valueLabelDisplay="auto"
                            max="5000"
                            onChange={updateRange}
                            onChangeCommitted={updateSearchRange}
                        />
                    </div>

                    <h5 className="filter-title">Ratings</h5>
                    <div className="filter-list mb-4">
                        <ul className="list-unstyled list-group">
                            {renderRatingFilter()}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoreFilterBar;
