import React, {useEffect, useState} from 'react'
import {useQuery} from '@tanstack/react-query';
import swal from 'sweetalert';
import axios from '../../../api.js';
import Loader from '../../Loader.js';
import Modal from 'react-bootstrap/Modal';
import {Controller, useForm} from 'react-hook-form';
import FamilyRequest from './FamilyRequest.js';
import MyRequests from './MyRequests.js';
import FamilyMembers from './FamilyMembers.js';
import {useContext} from 'react';
import {LoginStateContext} from '../../../../contexts/LoginStateContext.js';
import LeaveRequests from './LeaveRequests.js';
import {useLocation, useNavigate} from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import {useFamilyNotify} from '../../../layouts/Dashboard/DashboardProvider.js';
import AuthButton from '../../../custom_componets/AuthButton'
import {QrCode} from '@mui/icons-material';
import {QRCodeCanvas} from 'qrcode.react';
import {encryptJSONStr} from '../../../../helperFunctions/stringEncrypt.js';
import {onMessageListener} from '../../../../firebase.js';
import {usePusher} from '../../../../customHooks/usePusher.js';
import UploadFile from '../../../custom_componets/UploadFile.jsx';
import BecomePrimaryRequests from './BecomePrimaryRequests.jsx';
// import { encryptJSONStr } from './stringEncrypt.js';

const AddFamily = () => {
    const loginContext = useContext(LoginStateContext)
    const userData = loginContext.state.data
    const navigate = useNavigate();
    const location = useLocation()
    const [tabIndex, setTabIndex] = useState(0)

    const {memberCount, familyReqCount, myRequestCount} = useFamilyNotify()
    const [formSubmitting, setFormSubmitting] = useState(false)

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [error, setError] = useState('');

    const [showQr, setShowQr] = useState(false)
    const [qrValue, setQrValue] = useState('Hello World')

    const {channel} = usePusher(`my-family-channel.${userData.id}`)

    const {
        register,
        handleSubmit,
        resetField,
        setValue,
        control
    } = useForm({
        defaultValues: {
            phone: "",
            family_relation: ""
        }
    });
    const handleClose = () => {
        setShow(false);
        resetField("phone");
        resetField("family_relation");
        setError('');
    };
    const handleShow = () => setShow(true);

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        resetField: resetField2,
    } = useForm({
        defaultValues: {
            newmember_id: "",
            family_relation: "",
            change_reason: ""
        }
    });

    const {
        register: becomePrimaryRegister,
        handleSubmit: becomePrimaryMember,
        resetField: resetBecomePrimaryField,
        setValue: setBecomePrimaryValue,
        watch,
        formState
    } = useForm({
        defaultValues: {
            change_reason: '',
            family_relation: '',
            death_case: true,
        }
    })

    const {isSubmitting: becomePrimarySubmitting} = formState

    const handleClose2 = () => {
        setShow2(false);
        resetField2("newmember_id");
        resetField2("family_relation");
        resetField2("change_reason");
    };
    const handleShow2 = () => setShow2(true);

    const userQuery = useQuery(
        ["user-profile-data", userData.id],
        async () => {
            return axios.get('/api/admin/user-profile', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        },
    );

    const familyQuery = useQuery(
        ["family-request-data", userData.id],
        async () => {
            return axios.get('/api/admin/addfamily', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    );

    const myRequestQuery = useQuery(
        ["myfamily-request-data", userData.id],
        async () => {
            return axios.get(`/api/admin/addfamily/myrequest`, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    );

    const familyRequestQuery = useQuery(
        ["addfamily-request-data", userData.id],
        async () => {
            return axios.get(`/api/admin/addfamily/family-request`, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    );

    const leavefamilyQuery = useQuery(
        ["leave-family-request", userData.id],
        async () => {
            return axios.get('/api/admin/family/get-leave-request', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    );

    const primaryMemberChangeListQuery = useQuery(
        ["primary-change-list", userData.id],
        async () => {
            return axios.get('/api/admin/family/change-primary-member-list', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    );

    const packagestatus = useQuery(
        ['package-status-data', userData.id],
        async () => {
            return axios.get('/api/admin/userpackage', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )

    const checkprimaryProfileQuery = useQuery(
        ["checkfamilyData", userData.id],
        async () => {
            return axios.get('/api/admin/family', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        },
    )

    function handleFile(inputFile, name) {
        var reader = new FileReader();
        reader.readAsDataURL(inputFile);
        console.log()
        var ext = inputFile.type.split('/')[1]
        reader.onload = () => {
            const strArr = reader.result.split('base64,')
            const base64Str = `data:image/${ext};base64,${strArr[1]}`
            // setInputs(prev => ({ ...prev, [name]: base64Str }))
            setBecomePrimaryValue('death_certificate', base64Str)
        }
        reader.onerror = error => {
            console.log();
        }
    }

    onMessageListener().then((payload) => {
        // console.log()
        familyRequestQuery.refetch()
        setTabIndex(1)
        setShowQr(false)
        // if (payload?.data?.jwt) {
        //     setToken(payload.data.jwt)
        // }
    })
        .catch(err => console.log())

    useEffect(() => {
        if (location.state?.componentState) {
            let compState = location.state.componentState
            setValue('phone', compState.data.phone)
            setShow(true)
            onSubmit(compState.data)
        }
    }, [location])

    useEffect(() => {
        const eventCallBack = (data) => {
            // setToken(data.message))
            axios.post('api/admin/addfamily/store', {phone: data.phone}, {headers: {'Authorization': `Bearer ${loginContext.state.token}`}})
                .then(res => console.log('Success')).catch(err => console.log(err))
            console.log("Event Data is", data)
            // if (data.message === qrvalue) {
            //     setToken(data.token)
            // }
            // console.log()
        }

        if (channel !== null) {
            channel.bind(`my-family-event.${userData.id}`, eventCallBack)
        }
        return () => {
            if (channel !== null) {
                channel.unbind(`my-family-event.${userData.id}`, eventCallBack)
            }
        }
    }, [channel])

    if (userQuery.isLoading || familyQuery.isLoading || leavefamilyQuery.isLoading || primaryMemberChangeListQuery.isLoading || packagestatus.isLoading || checkprimaryProfileQuery.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }


    const user = userQuery.data
    const family = familyQuery.data
    const myrequest = myRequestQuery.data
    const familyrequest = familyRequestQuery.data
    const leave = leavefamilyQuery.data
    const change = primaryMemberChangeListQuery.data
    const userpackage = packagestatus.data.package
    const primary = checkprimaryProfileQuery.data


    const getUserData = async () => {
        try {
            const res = await axios.get('api/admin/user-profile', {headers: {'Authorization': `Bearer ${loginContext.state.token}`}})
            return res.data
        } catch (e) {
            return false
        }
    }

    async function onSubmit(data) {
        setFormSubmitting(true)
        const userData = await getUserData()
        if (!userData) {
            setFormSubmitting(false)
            return
        }
        if (userData.dob === '' || userData.dob === null) {
            swal({
                title: "User Profile",
                text: "Please update your profile!",
                icon: "warning",
            }).then((value) => {
                if (value) {
                    navigate("/user/profile/edit", {
                        state: {
                            redirect_to: window.location.pathname,
                            componentState: {data: data}
                        }
                    })
                }
            });
            setFormSubmitting(false)
            return
        }
        data.phone = `+${data.phone}`
        axios.post('/api/admin/addfamily/store', data, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                if (res.data.success) {
                    setShow(false);
                    resetField("phone");
                    resetField("family_relation");
                    swal({
                        title: res.data.success,
                        icon: "success",
                    }).then((value) => {
                        if (value) {
                            userQuery.refetch();
                            familyQuery.refetch();
                            myRequestQuery.refetch();
                            familyRequestQuery.refetch();
                            checkprimaryProfileQuery.refetch();
                        }
                    });
                } else {
                    setError(res.data.message);
                }
                setFormSubmitting(false)
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    setError(err.response.data.message.error);
                }
                setFormSubmitting(false)
            })
    }

    const onSubmit2 = (data) => {
        axios.post('/api/admin/family/change-primary-member', data, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                // console.log();
                if (res.data.success) {
                    setShow2(false);
                    resetField2("newmember_id");
                    resetField2("family_relation");
                    resetField2("change_reason");
                    swal({
                        title: res.data.success,
                        icon: "success",
                    }).then((value) => {
                        if (value) {
                            familyQuery.refetch();
                            primaryMemberChangeListQuery.refetch();
                        }
                    });
                }
            })
            .catch((err) => {
                console.log();
            })
    }

    const becomePrimaryRequest = (data) => {
        console.log("Become Primary Member request data", data)
        return axios.post('api/admin/family/become-primary-member', data, {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => {
            console.log(res.data)
            swal({
                title: "Request Sent!",
                text: "Your become primary request was sent successfully.",
                icon: "success",
                closeOnClickOutside: false,
            });
        }).catch(err => {
            console.log("There was an error", err)
            swal({
                title: "Become Primary Request",
                text: err.response.data.message.error[0],
                icon: "error",
                closeOnClickOutside: false,
            })
        })
    }

    const acceptRequest = (id) => {
        if (user.gender == null || user.dob == null) {
            swal({
                title: "User Profile",
                text: "Please update your profile!",
                icon: "warning",
            }).then((value) => {
                if (value) {
                    navigate("/user/profile/edit", {state: {redirect_to: window.location.pathname}})
                }
            });
        } else {
            axios.post(`/api/admin/addfamily/approved`, {id: id}, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => {
                    // console.log()
                    if (res.data.message === 'Payment Due') {
                        navigate(`/user/family/payment`, {state: {type: 0, selected: [id]}})
                    } else if (res.data.message === 'Deactivated') {
                        navigate(`/user/family-and-package/payment/${id}`)
                    } else {
                        swal({
                            title: res.data.message,
                            icon: "success",
                        }).then((value) => {
                            if (value) {
                                userQuery.refetch();
                                checkprimaryProfileQuery.refetch();
                                familyQuery.refetch();
                                myRequestQuery.refetch();
                                familyRequestQuery.refetch();
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.log()
                });
        }
    }

    const acceptFamilyRequest = (id) => {
        if (user.gender == null || user.dob == null) {
            swal({
                title: "User Profile",
                text: "Please update your profile!",
                icon: "warning",
            }).then((value) => {
                if (value) {
                    navigate("/user/profile/edit", {state: {redirect_to: window.location.pathname}})
                }
            });
        } else {
            axios.post('/api/admin/addfamily/accept-family-request', {id: id}, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => {
                    // console.log();
                    swal({
                        title: res.data.message,
                        icon: "success",
                    }).then((value) => {
                        if (value) {
                            userQuery.refetch();
                            familyQuery.refetch();
                            myRequestQuery.refetch();
                            familyRequestQuery.refetch();
                            leavefamilyQuery.refetch();
                        }
                    });
                })
                .catch((error) => {
                    console.log()
                });
        }
    }

    const rejectRequest = (id) => {
        axios.delete(`/api/admin/addfamily/reject/${id}`, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                swal({
                    title: res.data.success,
                    icon: "success",
                }).then((value) => {
                    if (value) {
                        userQuery.refetch();
                        familyQuery.refetch();
                        myRequestQuery.refetch();
                        familyRequestQuery.refetch();
                    }
                });
            })
            .catch((error) => {
                console.log()
            });
    }

    const rejectFamilyRequest = (id) => {
        axios.delete(`/api/admin/addfamily/reject-family-request/${id}`, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                swal({
                    title: res.data.success,
                    icon: "success",
                }).then((value) => {
                    if (value) {
                        userQuery.refetch();
                        familyQuery.refetch();
                        myRequestQuery.refetch();
                        familyRequestQuery.refetch();
                    }
                });
            })
            .catch((error) => {
                console.log()
            });
    }

    const cancelRequest = (id) => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: ["Close", "Cancel Request"],
            dangerMode: true,
        }).then((value) => {
            if (value) {
                axios.delete(`/api/admin/addfamily/cancel/${id}`, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                    .then(res => {
                        swal({
                            title: res.data.success,
                            icon: "success",
                        }).then((value) => {
                            if (value) {
                                userQuery.refetch();
                                checkprimaryProfileQuery.refetch();
                                familyQuery.refetch();
                                myRequestQuery.refetch();
                                familyRequestQuery.refetch();
                            }
                        });
                    })
                    .catch((error) => {
                        console.log()
                    });
            }
        });
    }

    const cancelFamilyRequest = (id) => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: ["Close", "Cancel Request"],
            dangerMode: true,
        }).then((value) => {
            if (value) {
                axios.delete(`/api/admin/addfamily/cancel-family-request/${id}`, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                    .then(res => {
                        swal({
                            title: res.data.success,
                            icon: "success",
                        }).then((value) => {
                            if (value) {
                                userQuery.refetch();
                                familyQuery.refetch();
                                myRequestQuery.refetch();
                                familyRequestQuery.refetch();
                            }
                        });
                    })
                    .catch((error) => {
                        console.log()
                    });
            }
        });
    }


    return (
        <>
            {primary.approved === 0 && user.member_type === 'Dependent Member' &&
                <div className="alert alert-danger">
                    <b>Request sent to {primary.familyname.family_name} family.</b> Waiting for family acceptance.
                </div>
            }
            {user.member_type === 'Primary Member' &&
                <div className='d-flex align-items-center mb-3'>
                    <button className='btn btn-primary mr-2' onClick={handleShow}>Send Family Request</button>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Phone Number</Modal.Title>
                        </Modal.Header>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Modal.Body>
                                <div className="form-group">
                                    <label htmlFor="">Phone Number</label>
                                    <Controller
                                        name='phone'
                                        control={control}
                                        required
                                        render={
                                            ({field: {onChange, value}}) => (
                                                <PhoneInput
                                                    country={'np'}
                                                    onlyCountries={['np']}
                                                    disableDropdown
                                                    countryCodeEditable={false}
                                                    value={value}
                                                    inputProps={{
                                                        name: 'phone',
                                                        required: true,
                                                        autoFocus: true,
                                                    }}
                                                    onChange={onChange}
                                                    inputStyle={{paddingLeft: '48px', width: '100%'}}
                                                />
                                            )
                                        }
                                    />
                                    <p className='text-danger'>{error}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Family Relation</label>
                                    <input type="text" {...register("family_relation")} className="form-control"
                                           required/>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="dashboard-style">
                                <button className='btn btn-secondary mr-2' onClick={handleClose}>
                                    Close
                                </button>
                                <AuthButton component='button' type='submit' clasName='btn btn-primary'
                                            loading={formSubmitting}>
                                    Send Request
                                </AuthButton>
                            </Modal.Footer>
                        </form>
                    </Modal>

                    {Object.keys(change).length === 0 ?
                        <>
                            <button className='btn btn-primary' onClick={handleShow2}>Switch Primary Member</button>
                            <Modal show={show2} onHide={handleClose2}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Switch Primary Member</Modal.Title>
                                </Modal.Header>
                                <form onSubmit={handleSubmit2(onSubmit2)}>
                                    <Modal.Body>
                                        <div className="form-group mb-3">
                                            <label htmlFor="">Select New Primary Member</label>
                                            <select {...register2("newmember_id")} required
                                                    className="form-select input-field">
                                                <option value="">-- Select --</option>
                                                {family.filter(f => f.approved === 1 && f.payment_status === 1).map((fam) =>
                                                    <option value={fam.member_id}
                                                            key={fam.id}>{fam.member.user.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="">Your Family Relation with selected member</label>
                                            <input type="text" {...register2("family_relation")}
                                                   className="form-control" required/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Switch Reason</label>
                                            <textarea type="text" {...register2("change_reason")}
                                                      className="form-control" required></textarea>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="dashboard-style">
                                        <button className='btn btn-secondary mr-2' onClick={handleClose2}>
                                            Close
                                        </button>
                                        <button className='btn btn-primary' type="submit">
                                            Switch
                                        </button>
                                    </Modal.Footer>
                                </form>
                            </Modal>
                        </>
                        :
                        <span className="alert alert-danger mb-0" style={{padding: '0.4375rem 0.875rem'}}>
                            Switch primary member to {change.newprimary.user.name} has been made. Waiting for approval.
                        </span>
                    }
                    <button className='btn btn-primary mx-2' onClick={() => setShowQr(true)}>
                        <QrCode/>
                    </button>

                </div>
            }
            {user.member_type === null &&
                <div className='d-flex align-items-center mb-3'>
                    <button className='btn btn-primary mr-2' onClick={handleShow}>Send Family Request</button>
                    <button className='btn btn-primary mx-2' onClick={() => setShowQr(true)}>
                        <QrCode/>
                    </button>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Phone Number</Modal.Title>
                        </Modal.Header>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Modal.Body>
                                <div className="form-group">
                                    <label htmlFor="">Phone Number</label>
                                    <Controller
                                        name='phone'
                                        control={control}
                                        required
                                        render={
                                            ({field: {onChange, value}}) => (
                                                <PhoneInput
                                                    country={'np'}
                                                    onlyCountries={['np']}
                                                    disableDropdown
                                                    countryCodeEditable={false}
                                                    value={value}
                                                    inputProps={{
                                                        name: 'phone',
                                                        required: true,
                                                        autoFocus: true,
                                                    }}
                                                    onChange={onChange}
                                                    inputStyle={{paddingLeft: '48px', width: '100%'}}
                                                />
                                            )
                                        }
                                    />
                                    <p className='text-danger'>{error}</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="dashboard-style">
                                <button className='btn btn-secondary mr-2' onClick={handleClose}>
                                    Close
                                </button>
                                <AuthButton component='button' type='submit' className='btn btn-primary'
                                            loading={formSubmitting}>
                                    Send Request
                                </AuthButton>
                            </Modal.Footer>
                        </form>
                    </Modal>
                </div>
            }
            {
                user.member_type === 'Dependent Member' && (
                    <div className='d-flex align-items-center mb-3'>
                        <button className='btn btn-primary mr-2' onClick={handleShow}>Become Primary Member</button>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Phone Number</Modal.Title>
                            </Modal.Header>
                            <form onSubmit={becomePrimaryMember(becomePrimaryRequest)}>
                                <Modal.Body>
                                    <div className="form-group">
                                        <label htmlFor="">Change Reason</label>
                                        <input type="text"
                                               className='form-control' {...becomePrimaryRegister('change_reason')} />
                                        <p className='text-danger'>{error}</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Family Relation</label>
                                        <input type="text"
                                               className='form-control' {...becomePrimaryRegister('family_relation')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Death Case</label>
                                        <div className="form-check">
                                            <input type="checkbox"
                                                   className="form-check-input" {...becomePrimaryRegister('death_case')} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Death Certificate</label>
                                        <UploadFile
                                            name="hand_written_letter"
                                            label="Upload Hand Written Letter"
                                            type={"img"}
                                            accept={"application/jpg,application/png"}
                                            setImageInput={(fileG) => {
                                                handleFile(fileG[0], 'hand_written_letter')
                                            }}
                                        />
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="dashboard-style">
                                    <button className='btn btn-secondary mr-2' onClick={handleClose}>
                                        Close
                                    </button>
                                    <AuthButton component='button' type='submit' className='btn btn-primary'
                                                loading={becomePrimarySubmitting}>
                                        Send Request
                                    </AuthButton>
                                </Modal.Footer>
                            </form>
                        </Modal>
                    </div>
                )
            }
            <div className="card">
                <div className="card-body">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${tabIndex === 0 && 'active'}`} id="familymember-tab"
                                    data-bs-toggle="tab" dtype="button" role="tab" aria-controls="familymember"
                                    aria-selected="true" onClick={() => setTabIndex(0)}>
                                Family Members&nbsp;<span
                                className="badge badge-warning">{memberCount > 0 && memberCount}</span>
                            </button>
                        </li>
                        {user.member_type !== 'Dependent Member' &&
                            <>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${tabIndex === 1 && 'active'}`} id="familyrequest-tab"
                                            data-bs-toggle="tab" type="button" role="tab" aria-controls="familyrequest"
                                            aria-selected="false" onClick={() => setTabIndex(1)}>
                                        Family Requests&nbsp;<span
                                        className="badge badge-warning">{familyReqCount > 0 && familyReqCount}</span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${tabIndex === 2 && 'active'}`} id="myrequest-tab"
                                            data-bs-toggle="tab" type="button" role="tab" aria-controls="myrequest"
                                            aria-selected="false" onClick={() => setTabIndex(2)}>
                                        My Requests&nbsp;<span
                                        className="badge badge-warning">{myRequestCount > 0 && myRequestCount}</span>
                                    </button>
                                </li>
                            </>
                        }
                        {user.member_type === 'Dependent Member' && primary.approved === 0 &&
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${tabIndex === 3 && 'active'}`} id="myrequest-tab"
                                        data-bs-toggle="tab" type="button" role="tab" aria-controls="myrequest"
                                        aria-selected="false" onClick={() => setTabIndex(3)}>
                                    My Requests
                                    <span
                                        className="badge badge-warning badge-pill ml-auto ml-lg-0">{myRequestCount}</span>
                                </button>
                            </li>
                        }
                        {user.member_type === 'Primary Member' &&
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${tabIndex === 4 && 'active'}`} id="leaverequest-tab"
                                        data-bs-toggle="tab" type="button" role="tab" aria-controls="leaverequest"
                                        aria-selected="false" onClick={() => setTabIndex(4)}>Leave Requests
                                </button>
                            </li>
                        }
                        {
                            user.member_type === 'Dependent Member' && (
                                <li className='nav-item' role='presentation'>
                                    <button className={`nav-link ${tabIndex === 5 && 'active'}`} id='switchprimary-tab'
                                            data-bs-toggle="tab" type="button" role="tab" aria-controls="leaverequest"
                                            aria-selected="false" onClick={() => setTabIndex(5)}>Become Primary Requests
                                    </button>
                                </li>
                            )
                        }
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className={`tab-pane fade show ${tabIndex === 0 && 'active'}`} id="familymember"
                             role="tabpanel" aria-labelledby="familymember-tab">
                            <FamilyMembers family={family} user={user} token={loginContext.state.token} leave={leave}
                                           leavefamilyQuery={leavefamilyQuery} familyQuery={familyQuery}
                                           userpackage={userpackage}/>
                        </div>
                        {user.member_type !== 'Dependent Member' &&
                            <>
                                <div className={`tab-pane test-classname fade show ${tabIndex === 1 && 'active'}`}
                                     id="familyrequest" role="tabpanel" aria-labelledby="familyrequest-tab">
                                    <FamilyRequest family={family} user={user} acceptRequest={acceptRequest}
                                                   rejectRequest={rejectRequest}
                                                   acceptFamilyRequest={acceptFamilyRequest}
                                                   rejectFamilyRequest={rejectFamilyRequest}
                                                   familyrequest={familyrequest}/>
                                </div>
                                {/* <div className="test-content">
                                    This is the test content
                                </div> */}
                                <div className={`tab-pane fade show ${tabIndex === 2 && 'active'}`} id="myrequest"
                                     role="tabpanel" aria-labelledby="myrequest-tab">
                                    <MyRequests family={family} user={user} cancelRequest={cancelRequest}
                                                myrequest={myrequest} cancelFamilyRequest={cancelFamilyRequest}/>
                                </div>
                            </>
                        }
                        {user.member_type === 'Dependent Member' && primary.approved === 0 ?
                            <>
                                <div className={`tab-pane fade show ${tabIndex === 3 && 'active'}`} id="myrequest"
                                     role="tabpanel" aria-labelledby="myrequest-tab">
                                    <MyRequests family={family} user={user} cancelRequest={cancelRequest}
                                                myrequest={myrequest} cancelFamilyRequest={cancelFamilyRequest}/>
                                </div>
                                <div className={`tab-pane fade show ${tabIndex === 2 && 'active'}`} id="myrequest"
                                     role="tabpanel" aria-labelledby="myrequest-tab">
                                    <MyRequests family={family} user={user} cancelRequest={cancelRequest}
                                                myrequest={myrequest} cancelFamilyRequest={cancelFamilyRequest}/>
                                </div>
                            </>
                            :
                            <div className={`tab-pane fade show ${tabIndex === 5 && 'active'}`}
                                 id='becomeprimaryrequest' role='tabpanel' aria-labelledby='becomeprimary-tab'>
                                <BecomePrimaryRequests/>
                            </div>
                        }
                        {user.member_type === 'Primary Member' &&
                            <div className={`tab-pane fade show ${tabIndex === 4 && 'active'}`} id="leaverequest"
                                 role="tabpanel" aria-labelledby="leaverequest-tab">
                                <LeaveRequests leave={leave} leavefamilyQuery={leavefamilyQuery}
                                               token={loginContext.state.token} familyQuery={familyQuery}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal show={showQr} onHide={() => setShowQr(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Scan qr code to join the family.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="w-100 d-flex justify-content-center py-4">
                        <QRCodeCanvas
                            id='qrCode'
                            value={encryptJSONStr(JSON.stringify({
                                key: userData.member.phone,
                                type: 'family',
                                platform: 'web',
                                id: userData.id,
                                familyType: userData.member_type === null ? 'None' : userData.member_type
                            }))}
                            size={300}
                            bgColor={'#fff'}
                            level={"H"}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddFamily
