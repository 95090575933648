import {createTheme, CssBaseline, responsiveFontSizes, ThemeProvider} from '@mui/material'
import React from 'react'

let theme = createTheme({
    typography: {
        fontFamily: "'Source Sans Pro', sans-serif"
    },
    palette: {
        white: '#fff',
    },

})
theme = responsiveFontSizes(theme)

function ThemeMUI({children}) {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {children}
        </ThemeProvider>
    )
}

export default ThemeMUI
