import {useQuery} from '@tanstack/react-query'
import axios from '../api'
import React from 'react'
import Slider from 'react-slick'
import {Link} from 'react-router-dom'

function HomeEventComp({bgColor}) {
    const {isLoading, data} = useQuery(
        ['homeEvents'],
        async () => {
            return axios.get("api/event-expo")
                .then(res => res.data).catch(err => err)
        }
    )
    const settings = {
        dots: true,
        speed: 500,
        autoPlay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    if (isLoading) {
        return <h5>Loading...</h5>
    }

    console.log()

    if (data.length === 0) {
        return
    }

    return (
        <div className="section-home-event py-4" style={{backgroundColor: bgColor}}>
            <div className="container">
                <Slider {...settings} className="custom-slick">
                    {
                        data.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className="row align-items-center">
                                        <div className="col-md-4">
                                            <div
                                                className="evnt-img-contain w-100 h-100 d-flex align-items-center justify-content-center">
                                                <img className='w-75 ratio ratio-1x1' src={item.image_path} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="event-details w-75">
                                                <div className="event-time">
                                                    <h5>Join Our Event On</h5>
                                                    <h2><b>{item.start_date}</b> to <b>{item.end_date}</b></h2>
                                                </div>
                                                <div className="event-title">
                                                    <h3><b>{item.title}</b></h3>
                                                </div>
                                                <div className="event-desc">
                                                    <h5>Provide your employees with the best for their healthcare needs.
                                                        Our tailored services will keep your costs down, while
                                                        delivering exceptional service.</h5>
                                                </div>
                                                <Link to={`/events/${item.slug}`}
                                                      className="btn btn-success px-5 rounded">
                                                    View Event Details
                                                </Link>
                                                <div className="btn btn-primary rounded p-0 py-2 px-5 ms-2">
                                                    Get Ticket Now
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}

HomeEventComp.defaultProps = {
    bgColor: "#d8ecf3"
}

export default HomeEventComp
