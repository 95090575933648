import axios from '../../components/api'

const doctorBookingVerification = (body, token) => {
    return ()=>axios.post('/api/admin/booking-review/payment', body, {headers: {'Authorization':`Bearer ${token}`} })
}

const packageBookingVerification = (body, token) => {
    return ()=>axios.post(`/api/admin/userpackage/payment`, body, {headers: {'Authorization':`Bearer ${token}`}})
}

export { doctorBookingVerification, packageBookingVerification }