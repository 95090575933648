import {
    AccessTimeOutlined,
    ArrowDownwardOutlined,
    Description,
    DescriptionOutlined,
    LocationOnOutlined,
    LockClockOutlined,
    WorkOffOutlined
} from '@mui/icons-material'
import {Box, Button, Card, CardContent, Grid, Stack, Typography} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React, {useRef} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import axios from '../../api'
import {DateFormat} from '../../DateFormat'
import Salary from '../../../images/salary.jpg'
import InsuranceImg from '../../../images/insurance.jpg'
import CreativeFreedom from '../../../images/creativeFreedom.jpg'
import Mentorship from '../../../images/mentorship.jpg'
import GDLogo from '../../../images/blue-logo.png'
import Career from '../../../images/career1.png'
import Loader from '../Loader'

function Vacancy() {
    const {data, isLoading, isError} = useQuery(
        ['vacancy'],
        async () => (
            axios.get(`/api/vacancy`).then(res => res.data).catch(err => err)
        )
    )
    const jobRef = useRef()

    function browseJobs() {
        jobRef.current.scrollIntoView()
    }

    if (isLoading) {
        return (
            <Box flexGrow={1} display='flex' justifyContent='center' alig width='100%' height='100%'>
                <Loader/>
            </Box>
        )
    }
    return (
        <Box bgcolor='#fff' textAlign={'left'}>
            <Box py={8}>
                <Box className="container-lg">
                    <Grid container sx={{alignItems: 'center'}}>
                        <Grid item xs={12} sm={6}>
                            <Stack gap={2}>
                                <Box>
                                    <Typography variant='h6' fontWeight={600} color='#2469B0'>CAREER
                                        OPPORTUNITY</Typography>
                                    <Typography variant='h2' fontWeight={700}>Lets work together</Typography>
                                </Box>
                                <Box>
                                    <Typography variant='h6' component='p'>
                                        We are a team of diverse individuals who are working together with the mission
                                        of making healthcare accessible to all. Join the Ghargharma Doctor family today.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Button variant='contained' sx={{px: 3, py: 1.5}} onClick={browseJobs}>Browse All
                                        Jobs</Button>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box width='100%' display='flex' justifyContent={'center'} alignItems='center'
                                 sx={{'& img': {height: '100%', maxHeight: '280px'}}}>
                                <img src={Career} alt=""/>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box py={8} bgcolor='#cbeffc'>
                <Box className="container">
                    <Stack gap={4}>
                        <Box>
                            <Typography variant='h3' fontWeight={600} textAlign={{xs: 'center', md: 'left'}}>Wondering
                                why a career at Ghargharma Doctor?</Typography>
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4}>
                                        <Box height='100%' sx={{'& img': {width: '100%', height: '100%'}}}>
                                            <img src={Salary} alt=""/>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Card elevation={0} sx={{p: 4, height: '100%'}}>
                                            <Typography variant='h4' fontWeight='600' sx={{mb: 2}}>Competitive
                                                Salary</Typography>
                                            <Typography component='p' variant='h6'>
                                                We ensure your hard work and dedication is always incentivised with a
                                                competitive salary.
                                            </Typography>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box height='100%' sx={{'& img': {width: '100%', height: '100%'}}}>
                                            <img src={InsuranceImg} alt=""/>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4}>
                                        <Card elevation={0} sx={{p: 4, height: '100%'}}>
                                            <Typography variant='h4' fontWeight='600' sx={{mb: 2}}>Health and Medical
                                                Insurance</Typography>
                                            <Typography component='p' variant='h6'>
                                                Your health and fitness is of paramount importance to Ghargharma Doctor.
                                            </Typography>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box height='100%' display='flex' justifyContent='center' alignItems='center'
                                             sx={{'& img': {width: '60%', height: 'auto'}}}>
                                            <img src={GDLogo} alt=""/>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Card elevation={0} sx={{p: 4, height: '100%'}}>
                                            <Typography variant='h4' fontWeight='600' sx={{mb: 2}}>Creative
                                                Freedom</Typography>
                                            <Typography component='p' variant='h6'>
                                                We are looking for creative individuals with critical thinking ability
                                                who are able to express their ideas.
                                            </Typography>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4}>
                                        <Box height='100%' sx={{'& img': {width: '100%', height: '100%'}}}>
                                            <img src={CreativeFreedom} alt=""/>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Card elevation={0} sx={{p: 4, height: '100%'}}>
                                            <Typography variant='h4' fontWeight='600'
                                                        sx={{mb: 2}}>Mentorship</Typography>
                                            <Typography component='p' variant='h6'>
                                                Grow with Ghargharma Doctor and its constructive feedback environment.
                                            </Typography>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box height='100%' sx={{'& img': {width: '100%', height: '100%'}}}>
                                            <img src={Mentorship} alt=""/>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
            </Box>
            <Box ref={jobRef} className='container-lg' py={11}>
                <Stack gap={1.5}>
                    <Box textAlign={{xs: 'center', md: 'left'}}>
                        <Typography variant='h4' fontWeight={600}>
                            Job Openings
                        </Typography>
                        <Typography variant='subtitle1'>
                            We are looking for motivated candidates for the following positions.
                        </Typography>
                    </Box>

                    <Box>
                        {
                            data.length === 0 ?
                                <Box textAlign='center'>
                                    <Typography variant='h2' textAlign={'center'} fontWeight='400'>
                                        <WorkOffOutlined sx={{fontSize: 'inherit'}}/>
                                    </Typography>
                                    <Typography variant='h4'>No Openings</Typography>
                                    <Typography variant='subtitle1' color='text.secondary'>We have no open positions
                                        currently. Please check again at a later date.</Typography>
                                </Box>
                                :
                                <Grid container spacing={2}>
                                    {
                                        data.map((item, idx) => (
                                            <Grid item xs={12} md={6} key={idx}>
                                                <VacancyCard data={item}/>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                        }
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}


function VacancyCard({data}) {
    const navigate = useNavigate()

    function applyNow() {
        navigate(`/career/${data.slug}`)
    }

    return (
        <Card variant='outlined' sx={{
            height: '100%', borderRadius: '10px', transition: 'all 300ms', '&:hover': {
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
                transform: 'scale(1.028) translateZ(0)'
            }
        }}>
            <Stack sx={{height: '100%'}}>
                <CardContent sx={{p: 2}}>
                    <Stack gap={1}>
                        <Typography variant='h5' fontWeight={700} color='#0D59A8'>{data.job_title}</Typography>
                        <Box display='flex' gap={2}>
                            <Box bgcolor='#f2f3f3' p={0.5} px={1} display='flex' alignItems='center' gap={1}
                                 borderRadius={1}>
                                <LocationOnOutlined sx={{fontSize: '16px'}}/>
                                <Typography fontWeight={600} fontSize='14px'
                                            color='text.secondary'>{data.job_location}</Typography>
                            </Box>
                            <Box bgcolor='#f2f3f3' p={0.5} px={1} display='flex' alignItems='center' gap={1}
                                 borderRadius={1}>
                                <AccessTimeOutlined sx={{fontSize: '16px'}}/>
                                <Typography fontWeight={600} fontSize='14px'
                                            color='text.secondary'>{data.job_type}</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box display='flex' gap={2}>
                                <Typography variant='subtitle1' fontSize='14px' color='text.secondary'>Number of
                                    Openings:</Typography>
                                <Typography variant='subtitle1' fontSize='14px' color={'text.primary'}
                                            fontWeight='700'>{data.no_of_vacancy}</Typography>
                            </Box>
                            <Box display='flex' gap={2}>
                                <Box display='flex' gap={1} flexWrap={'wrap'}>
                                    <Typography variant='subtitle1' fontSize='14px' color='text.secondary'
                                                sx={{flexShrink: 0}}>Key Skills:</Typography>
                                    {
                                        data.vacancyskill.map((item, idx) => (
                                            <Box bgcolor={'#f2f3f3'} borderRadius={2} border={1} borderColor={'divider'}
                                                 px={0.5}>
                                                <Typography variant='subtitle1'
                                                            fontSize='14px'>{item.skill.skill}</Typography>
                                            </Box>
                                        ))
                                    }

                                    {/* <Box bgcolor={'#f2f3f3'} borderRadius={2} border={1} borderColor={'divider'} px={0.5}>
                                        <Typography variant='subtitle1' fontSize='14px'>Communication</Typography>
                                    </Box>
                                    <Box bgcolor={'#f2f3f3'} borderRadius={2} border={1} borderColor={'divider'} px={0.5}>
                                        <Typography variant='subtitle1' fontSize='14px'>Multitasking</Typography>
                                    </Box>
                                    <Box bgcolor={'#f2f3f3'} borderRadius={2} border={1} borderColor={'divider'} px={0.5}>
                                        <Typography variant='subtitle1' fontSize='14px'>Decision Making</Typography>
                                    </Box> */}
                                </Box>
                            </Box>
                        </Box>
                    </Stack>
                </CardContent>
                <Box mt='auto' px={2} py={1} borderTop={1} borderColor='divider' fontSize={'14px !important'}
                     display='flex' justifyContent='space-between'>
                    <Box display='flex' gap={1} alignItems={'center'}>
                        <AccessTimeOutlined sx={{fontSize: 'inherit'}}/>
                        <Typography variant='subtitle1' fontSize='inherit'>
                            <span className='text-muted'>Apply Before: </span><Box component='span'
                                                                                   fontWeight={600}>{DateFormat(data.job_deadline).getFullDateText()}</Box>
                        </Typography>
                    </Box>
                    <Box>
                        <Button onClick={applyNow} sx={{fontSize: 'inherit', padding: 0}}>
                            View Details
                        </Button>
                    </Box>
                </Box>
            </Stack>
        </Card>
    )
}

export default Vacancy
