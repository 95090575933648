import React from "react";
import {useQuery} from "@tanstack/react-query";
import SchoolPaymentVerificationService from "../../../../../services/school-payment-verification.service";
import {Skeleton} from "@mui/material";
import {grey} from "@mui/material/colors";
import {addCommasToNumber, generateIncrementalArray} from "../../../../../utils/common/common.utils";
import PaymentHistoryItem from "./PaymentHistoryItem";

const PaymentHistory = () => {
    const {isLoading, data} = useQuery({
        queryKey: ['school-payment-history'], queryFn: SchoolPaymentVerificationService.fetchPaymentHistory
    })

    const renderUI = () => {
        if (isLoading) {
            return (
                <div>
                    {generateIncrementalArray(6).map(item => <Skeleton key={item} style={{padding: 40,}}/>)}
                </div>
            )
        }
        if (data?.data?.length) {
            return (<div className="d-grid" style={{gap: "16px"}}>
                {data?.data?.map(paymentItem => <PaymentHistoryItem key={paymentItem?.id} paymentItem={paymentItem}/>)}
            </div>)
        } else {
            return (<div className="p-2">
                <div className="p-5 text-center" style={{background: grey[200], borderRadius: 8, fontSize: 20}}>
                    No payment history.
                </div>
            </div>)
        }
    }

    return (
        <div className="d-grid gap-2 mb-4  payment-details">
            <div>
                <h4 className="fs-4 fw-bolder lh-sm">
                    Payment History
                </h4>
            </div>
            {renderUI()}
        </div>
    )
}

export default PaymentHistory
