import {Box, Button, Container, Typography} from '@mui/material';
import React from 'react'
import Slider from 'react-slick';

function FitnessHeader() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
    };
    return (
        <Box sx={{bgcolor: "#F9F6EE", p: 6, mb: 4}}>
            <Container>
                <Slider {...settings}>
                    <div>
                        <div className="fitness-slider-item py-4">
                            <Typography sx={{color: "#52C8F4"}}>Sub title fitness center</Typography>
                            <Typography variant='h4' sx={{color: "text.primary"}}>Fitness Slider Title One</Typography>
                            <Button variant='contained'>
                                Do Something
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div className="fitness-slider-item py-4">
                            <Typography sx={{color: "#52C8F4"}}>Sub title fitness center</Typography>
                            <Typography variant='h4' sx={{color: "text.primary"}}>Fitness Slider Title Two</Typography>
                            <Button variant='contained'>
                                Do Something
                            </Button>
                        </div>
                    </div>
                </Slider>
            </Container>
        </Box>
    )
}

export default FitnessHeader
