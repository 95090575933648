import http from "../utils/http/http.utils";
import setFormData from "../utils/http/formdata.utils";

class PackageService {
    static async registration(params) {
        if(params?.phone){
            params.phone = params.phone.replace("+977", "")
        }
        if(params?.contact_number) {
            params.contact_number = params.phone.replace("+977", "")
        }

        const formdata = setFormData(params);
        try {
            return http().post("api/school/register", formdata)
        } catch (e) {
            console.log(e)
            throw  e
        }
    }

    static async getPackages(slug) {
        try {
            const response = await http().get('api/package', {params: {slug}});
            return response.data;
        } catch (e) {
            console.log(e)
        }
    }

}

export default PackageService
