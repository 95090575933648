import {Grid, Stack} from '@mui/material'
import React from 'react'
import ProductCards from '../../../stores/ProductCards'
import FitnessProdCard from '../../fitnessCenter/components/FitnessProdCard'

function ProductGrid({lgCount, mdCount, smCount, xsCount, data}) {
    return (
        <Grid container>
            {
                data.map((item, idx) => (
                    <Grid item xs={xsCount} md={mdCount} sm={smCount} lg={lgCount} pb={2}>
                        <Stack direction='row' width='100%' height='100%' justifyContent={'center'}>
                            <ProductCards data={item}/>
                        </Stack>
                    </Grid>
                ))
            }
            {/* <Grid item xs={xsCount} pb={2}>
                <FitnessProdCard />
            </Grid>
            <Grid item xs={xsCount} pb={2}>
                <FitnessProdCard />
            </Grid>
            <Grid item xs={xsCount} pb={2}>
                <FitnessProdCard />
            </Grid>
            <Grid item xs={xsCount} pb={2}>
                <FitnessProdCard />
            </Grid>
            <Grid item xs={xsCount} pb={2}>
                <FitnessProdCard />
            </Grid>
            <Grid item xs={xsCount} pb={2}>
                <FitnessProdCard />
            </Grid>
            <Grid item xs={xsCount} pb={2}>
                <FitnessProdCard />
            </Grid>
            <Grid item xs={xsCount} pb={2}>
                <FitnessProdCard />
            </Grid>
            <Grid item xs={xsCount} pb={2}>
                <FitnessProdCard />
            </Grid>
            <Grid item xs={xsCount} pb={2}>
                <FitnessProdCard />
            </Grid> */}
        </Grid>
    )
}

export default ProductGrid
