import {Box, Card, CardHeader} from "@mui/material";
import React, {useContext, useState} from "react";
import {LoginStateContext} from "../../../../contexts/LoginStateContext";
import {
    NodeAlternateContent,
    NodeContent,
    TimeNode,
    UserTimeline,
} from "./components/UserTimeline";
import axios from "../../../api";
import {useQuery} from "@tanstack/react-query";
import {
    ArticleOutlined,
    CheckCircleOutline,
    FlagCircleOutlined,
    MedicationOutlined,
    ScienceOutlined,
    VaccinesOutlined,
} from "@mui/icons-material";

function PackageTimeline() {
    const loginContext = useContext(LoginStateContext);
    const {data, isLoading, isError} = useQuery(["mypackage"], async () =>
        axios
            .get("api/admin/userpackage", {
                headers: {Authorization: `Bearer ${loginContext.state.token}`},
            })
            .then((res) => res.data)
            .catch((err) => err)
    );

    if (isLoading) {
        return;
    }

    if (isError) {
        return;
    }
    // console.log()
    const dates = data.package?.dates || [];

    // if (dates.length === 0) return

    return (
        <div className="col-12 py-3">
            <Card>
                <CardHeader title="Screening Timeline"/>
                <UserTimeline>
                    {dates.length === 0 ? (
                        <TimeLineComp data={null} userDates={null}/>
                    ) : (
                        dates.map((date) => (
                            <TimeLineComp data={date} userDates={date.reports[0] || null}/>
                        ))
                    )}
                </UserTimeline>
            </Card>
        </div>
    );
}

function PackageTimeline2() {
    const loginContext = useContext(LoginStateContext);

    const {data, isLoading, isError} = useQuery(["mypackage"], async () =>
        axios
            .get("api/admin/userpackage", {
                headers: {Authorization: `Bearer ${loginContext.state.token}`},
            })
            .then((res) => res.data)
            .catch((err) => err)
    );

    if (isLoading) {
        return;
    }

    if (isError) {
        return;
    }

    const dates = data.package?.dates || [];

    const getTimelineData = (date) => {
        const personalDate = !!date?.reports ? date?.reports[0] : {};
        return {
            start: {
                name: "First Screening Start",
                date: date?.screening_date,
                pendingStatuses: [],
                skipReason: null,
            },
            sampleDeposited: {
                name: "Sample Deposited",
                date: personalDate?.deposited_date,
                pendingStatuses: ["Sample to be collected", "Sample Collected"],
                skipReason: personalDate?.skip_reason || null,
            },
            reportVerified: {
                name: "Report Verified",
                date: personalDate?.report_date || null,
                pendingStatuses: ["Draft Report", "Report Published"],
                skipReason: null,
            },
            doctorVisit: {
                name: "Doctor Visit",
                date: personalDate?.doctorvisit_date || null,
                pendingStatuses: [],
                skipReason: null,
            },
            doctorVisitCompleted: {
                name: "Doctor Visit Completed",
                date: personalDate?.doctor_visit_date || null,
                pendingStatuses: ["Consultation Skipped"],
                skipReason:
                    personalDate?.homeskip?.reason && personalDate?.advice == null
                        ? personalDate?.homeskip?.reason
                        : null,
            },
            consultationReport: {
                name: "Consultation Report",
                date: personalDate?.consultation_report_date || null,
                pendingStatuses: [],
                skipReason: null,
            },
            completed: {
                name: "Completed",
                date: personalDate?.doctorreport_date || null,
                pendingStauses: [],
                skipReason: null,
            },
        };
    };

    return (
        <div className="col-12 py-3">
            <Card>
                <CardHeader title="Screening Timeline"/>
                <UserTimeline>
                    {dates.length === 0 ? (
                        <TimeLineComp2
                            screeing={null}
                            activeStatus={null}
                            timelineData={getTimelineData({})}
                        />
                    ) : (
                        dates.map((date) => (
                            <TimeLineComp2
                                screening={date?.screening?.screening}
                                activeStatus={date?.reports[0]?.status || null}
                                timelineData={getTimelineData(date)}
                            />
                        ))
                    )}
                </UserTimeline>
            </Card>
        </div>
    );
}

function TimeLineComp({data, userDates}) {
    const [currentActive, setCurrentActive] = useState(null);
    let screening = data?.screening?.screening;
    // return (
    //     userDates.status
    // )

    function getStatus(
        status,
        date,
        index,
        pendingStatus = [],
        skipReason = null
    ) {
        if (userDates === null) {
            return;
        }
        // console.log('***************start')
        // console.log(status, index, skipReason, currentActive)
        // console.log(pendingStatus.includes(userDates?.status))
        // console.log(status === userDates?.status)
        // console.log(skipReason != null && index===1)
        if (
            (status === userDates?.status ||
                pendingStatus.includes(userDates?.status)) &&
            index !== currentActive
        ) {
            // console.log("Setting active index for status")
            setCurrentActive(index);
        }
        if (skipReason != null) {
            return {
                skipped: true,
                skipReason: skipReason,
            };
        }
        if (pendingStatus.includes(userDates?.status)) {
            return {
                pending: true,
            };
        }
        if (index < currentActive) {
            return {
                active: true,
            };
        }
        if (index === currentActive) {
            if (status === userDates?.status) {
                return {
                    active: true,
                };
            } else {
                return {
                    pending: true,
                };
            }
        }
    }

    return (
        <>
            <TimeNode icon={FlagCircleOutlined} active={userDates != null}>
                <NodeContent>
                    <Box textAlign="center">
                        <div>
                            <b>{data?.screening_date || "Waiting for date"}</b>
                        </div>
                        <h5>{screening} Start</h5>
                    </Box>
                </NodeContent>
            </TimeNode>

            <TimeNode
                icon={ScienceOutlined}
                {...getStatus(
                    "Sample Deposited",
                    userDates?.deposited_date,
                    1,
                    ["Sample to be collected", "Sample Collected"],
                    userDates?.skip_reason
                )}
            >
                <NodeAlternateContent>
                    <Box textAlign="center">
                        <div>
                            <b>
                                {userDates?.sample_date ||
                                    (userDates?.skip_reason != null
                                        ? "Skipped"
                                        : "Waiting for date")}
                            </b>
                        </div>
                        <h5>Sample Collection</h5>
                    </Box>
                </NodeAlternateContent>
                <NodeContent>
                    {userDates?.skip_reason != null && (
                        <div className="px-2 fs-6 fw-light text-danger">
                            {userDates?.skip_reason}
                        </div>
                    )}
                </NodeContent>
            </TimeNode>

            <TimeNode
                icon={ArticleOutlined}
                {...getStatus("Report Verified", userDates?.report_date, 2, [
                    "Draft Report",
                    "Report Published",
                ])}
            >
                <NodeContent>
                    <Box textAlign="center">
                        <div>
                            <b>{userDates?.report_date || "Waiting for date"}</b>
                        </div>
                        <h5>Lab Report Generated</h5>
                    </Box>
                </NodeContent>
            </TimeNode>

            <TimeNode {...getStatus("Doctor Visit", data?.doctorvisit_date, 3)}>
                <NodeAlternateContent>
                    <Box textAlign="center">
                        <h5>{screening} - Second Phase</h5>
                    </Box>
                </NodeAlternateContent>
            </TimeNode>

            <TimeNode
                icon={VaccinesOutlined}
                {...getStatus(
                    "Doctor Visit Completed",
                    data?.doctorvisit_date,
                    4,
                    ["Consultation Skipped"],
                    userDates?.homeskip?.reason && userDates?.advice == null
                        ? userDates?.homeskip?.reason
                        : null
                )}
            >
                <NodeContent>
                    <Box textAlign="center">
                        <div>
                            <b>{data?.doctorvisit_date || "Waiting for date"}</b>
                        </div>
                        <h5>Doctor Consultation</h5>
                    </Box>
                </NodeContent>
            </TimeNode>

            <TimeNode
                icon={MedicationOutlined}
                {...getStatus(
                    "Consultation Report",
                    userDates?.consultation_report_date,
                    5
                )}
            >
                <NodeAlternateContent>
                    <Box textAlign="center">
                        <div>
                            <b>{userDates?.consultation_report_date || "Waiting for date"}</b>
                        </div>
                        <h5>Consultation Report Generated</h5>
                    </Box>
                </NodeAlternateContent>
            </TimeNode>

            <TimeNode
                icon={CheckCircleOutline}
                {...getStatus("Completed", data?.doctorreport_date, 5)}
            >
                <NodeContent>
                    <Box textAlign="center">
                        <div>
                            <b>{data?.doctorreport_date || "Waiting for date"}</b>
                        </div>
                        <h5>{screening} - Completed</h5>
                    </Box>
                </NodeContent>
            </TimeNode>
        </>
    );
}

function TimeLineComp2({screening, activeStatus, timelineData}) {
    const [currentActive, setCurrentActive] = useState(null);

    // let screening = data?.screening?.screening

    function getStatus(
        status,
        date,
        index,
        pendingStatus = [],
        skipReason = null
    ) {
        if (activeStatus === null) {
            return;
        }
        if (
            (status === activeStatus || pendingStatus.includes(activeStatus)) &&
            index !== currentActive
        ) {
            setCurrentActive(index);
        }
        if (skipReason != null) {
            return {
                skipped: true,
                skipReason: skipReason,
            };
        }
        if (pendingStatus.includes(activeStatus)) {
            return {
                pending: true,
            };
        }
        if (index < currentActive) {
            return {
                active: true,
            };
        }
        if (index === currentActive) {
            if (status === activeStatus) {
                return {
                    active: true,
                };
            } else {
                return {
                    pending: true,
                };
            }
        }
    }

    const {
        start,
        sampleDeposited,
        reportVerified,
        doctorVisit,
        doctorVisitCompleted,
        consultationReport,
        completed,
    } = timelineData;

    return (
        <>
            <TimeNode icon={FlagCircleOutlined} active={!!screening}>
                <NodeContent>
                    <Box textAlign="center">
                        <div>
                            <b>{start?.date || "Waiting for date"}</b>
                        </div>
                        <h5>{start?.name}</h5>
                    </Box>
                </NodeContent>
            </TimeNode>

            <TimeNode
                icon={ScienceOutlined}
                {...getStatus(
                    sampleDeposited.name,
                    sampleDeposited.date,
                    1,
                    sampleDeposited.pendingStatuses,
                    sampleDeposited.skipReason
                )}
            >
                <NodeAlternateContent>
                    <Box textAlign="center">
                        <div>
                            <b>
                                {sampleDeposited?.date ||
                                    (sampleDeposited?.skipReason != null
                                        ? "Skipped"
                                        : "Waiting for date")}
                            </b>
                        </div>
                        <h5>{sampleDeposited.name}</h5>
                    </Box>
                </NodeAlternateContent>
                <NodeContent>
                    {sampleDeposited?.skipReason != null && (
                        <div className="px-2 fs-6 fw-light text-danger">
                            {sampleDeposited?.skipReason}
                        </div>
                    )}
                </NodeContent>
            </TimeNode>

            <TimeNode
                icon={ArticleOutlined}
                {...getStatus(
                    reportVerified.name,
                    reportVerified.date,
                    2,
                    reportVerified.pendingStatuses
                )}
            >
                <NodeContent>
                    <Box textAlign="center">
                        <div>
                            <b>{reportVerified.date || "Waiting for date"}</b>
                        </div>
                        <h5>Lab Report Generated</h5>
                    </Box>
                </NodeContent>
            </TimeNode>

            <TimeNode {...getStatus(doctorVisit.name, doctorVisit.date, 3)}>
                <NodeAlternateContent>
                    <Box textAlign="center">
                        <h5>{screening} - Second Phase</h5>
                    </Box>
                </NodeAlternateContent>
            </TimeNode>

            {/* ((userDates?.homeskip?.reason && userDates?.advice==null) ? userDates?.homeskip?.reason : null) */}

            <TimeNode
                icon={VaccinesOutlined}
                {...getStatus(
                    doctorVisitCompleted.name,
                    doctorVisitCompleted.date,
                    4,
                    doctorVisitCompleted.pendingStatuses,
                    doctorVisitCompleted.skipReason
                )}
            >
                <NodeContent>
                    <Box textAlign="center">
                        <div>
                            <b>{doctorVisitCompleted?.date || "Waiting for date"}</b>
                        </div>
                        <h5>Doctor Consultation</h5>
                    </Box>
                </NodeContent>
            </TimeNode>

            <TimeNode
                icon={MedicationOutlined}
                {...getStatus(consultationReport?.name, consultationReport?.date, 5)}
            >
                <NodeAlternateContent>
                    <Box textAlign="center">
                        <div>
                            <b>{consultationReport?.date || "Waiting for date"}</b>
                        </div>
                        <h5>Consultation Report Generated</h5>
                    </Box>
                </NodeAlternateContent>
            </TimeNode>

            <TimeNode
                icon={CheckCircleOutline}
                {...getStatus(completed?.name, completed?.date, 5)}
            >
                <NodeContent>
                    <Box textAlign="center">
                        <div>
                            <b>{completed?.date || "Waiting for date"}</b>
                        </div>
                        <h5>{screening} - Completed</h5>
                    </Box>
                </NodeContent>
            </TimeNode>
        </>
    );
}

function PackageTimeNode({data}) {
    return (
        <TimeNode active>
            <NodeContent>
                <Box textAlign="center">
                    <div>
                        <b>July 1st, 2022</b>
                    </div>
                    <h5>First Screening Start</h5>
                </Box>
            </NodeContent>
        </TimeNode>
    );
}

export default PackageTimeline2;

export const SchoolPackageTimeline = () => {
    const loginContext = useContext(LoginStateContext);

    const {data, isLoading, isError} = useQuery(["mypackage"], async () =>
        axios
            .get("api/admin/userpackage", {
                headers: {Authorization: `Bearer ${loginContext.state.token}`},
            })
            .then((res) => res.data)
            .catch((err) => err)
    );

    if (isLoading) {
        return;
    }

    if (isError) {
        return;
    }

    const dates = data.package?.dates || [];

    const getTimelineData = (date) => {
        const personalDate = !!date?.reports ? date?.reports[0] : {};
        return {
            start: {
                name: "First Screening Start",
                date: date?.screening_date,
                pendingStatuses: [],
                skipReason: null,
            },
            sampleDeposited: {
                name: "Sample Deposited",
                date: personalDate?.deposited_date,
                pendingStatuses: [],
                skipReason: personalDate?.skip_reason || null,
            },
            reportVerified: {
                name: "Report Generated",
                date: personalDate?.report_date || null,
                pendingStatuses: [],
                skipReason: null,
            },
            doctorVisit: {
                name: "Doctor Visit",
                date: personalDate?.doctorvisit_date || null,
                pendingStatuses: [],
                skipReason: null,
            },
            doctorVisitCompleted: {
                name: "Doctor Visit Completed",
                date: personalDate?.doctor_visit_date || null,
                pendingStatuses: [],
            },
            consultationReport: {
                name: "Completed",
                date: personalDate?.consultation_report_date || null,
                pendingStatuses: [],
                skipReason: null,
            },
            completed: {
                name: "Completed",
                date: personalDate?.doctorreport_date || null,
                pendingStauses: [],
                skipReason: null,
            },
        };
    };

    return (
        <Card>
            <CardHeader title="Screening Timeline"/>
            <UserTimeline>
                {dates.length === 0 ? (
                    <TimeLineComp2
                        screeing={null}
                        activeStatus={null}
                        timelineData={getTimelineData({})}
                    />
                ) : (
                    dates.map((date) => (
                        <TimeLineComp2
                            screening={date?.screening?.screening}
                            activeStatus={date?.reports[0]?.status || null}
                            timelineData={getTimelineData(date)}
                        />
                    ))
                )}
            </UserTimeline>
        </Card>
    );
};
