import {createContext, useContext} from "react";
import {useLocation} from "react-router-dom";

const PackagePaymentContext = createContext()

const PackagePaymentProvider = ({children}) => {
    const location = useLocation();
    const fee = location.state.fee;
    const id = location.state.id;
    const monthly_fee = location.state.monthly_fee;
    const interval = location.state.interval;
    const redirectLink = location.state.packageType === 10 ? '/school/school-package' : '/user/mypackages'
    return (
        <PackagePaymentContext.Provider
            value={{fee, id, monthly_fee, interval, redirectLink}}
        >
            {children}
        </PackagePaymentContext.Provider>
    )
}

export default PackagePaymentProvider

export const usePackagePaymentCtx = () => useContext(PackagePaymentContext)
