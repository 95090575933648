import axios from '../../api'
import React from 'react'
import {useState} from 'react'
import {useContext, useEffect} from 'react'
import {StoreContext} from '../../../contexts/StoreContext'
import "./OrderDetail.scss"
import {useNavigate, useParams} from 'react-router-dom'


const status = {
    ['new']: "Payment Pending",
    ['cancel']: "Cancelled Order",
}

function OrderItem({product, order}) {
    return (


        <div className="order-detail-item row w-100 align-items-center ">

            <div className='row'>
                <div className='col1 xs-6'>

                    <div className="order-item-image col-sm-3 h-100 ">

                        <div className="img-container image">
                            <img src={product.image_path} alt=""/>
                        </div>


                    </div>
                </div>
                {/* <div className='col2-sm-2'> */}


                <div className="order-item-description col-5">
                    <div className='col2 xs-6'>
                        <div className='row w-100'>
                            <div className='col3 xs-6'>


                                <div className="order-item-name ">
                                    <h4>{product.productName}</h4>
                                </div>

                                <div className="order-item-status">
                                    <div className="text-muted">{status[order.status]}</div>
                                </div>
                                {/* </div> */}
                                <div className="order-item-price col-2 ">
                                    <h5 className="text-muted"><b>Rs. {product.sale_price * product.pivot.quantity}</b>
                                    </h5>
                                </div>
                                <div className="order-item-qty col-2 ">
                                    <b>Qty:</b><span className="text-muted">{product.pivot.quantity}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


    )
}

function OrderDetail() {
    const [order, setOrder] = useState({})
    let storeContext = useContext(StoreContext)
    const params = useParams()
    const token = localStorage.getItem("token")
    const navigate = useNavigate()

    useEffect(() => {
        // storeContext.setBreadCrumbs([
        //     ['/', "Home"], ["/store", "Store"], ['current_page', "Orders"]
        // ])
    }, [])
    useEffect(() => {
        axios.get("api/admin/order?order_number=" + params.orderNum, {
            headers: {Authorization: `Bearer ${token}`},
        })
            .then((res) => {
                setOrder(res.data[0])
            })
            .catch(err => {
                console.log()
            })
    }, [])
    return (
        <div>
            {/* <StoreNavVendor /> */}
            <h3 className="mt-3">Order Details</h3>
            <div className="order-detail mb-5">
                <div className="order-detail-card card">
                    <div className="card-header order-detail-card-header">
                        <div className="order-detail-header d-flex justify-content-between design">
                            <div className="order-detail-header-left">
                                <div className="order-detail-number">
                                    Order <span>{order.order_number}</span>
                                </div>
                                <div className="order-detail-date">
                                    Placed On 20 October 2022
                                </div>
                            </div>
                            <div className="order-detail-header-right">
                                <b>{status[order.status]}</b>
                            </div>
                        </div>
                    </div>
                    <div className="card-body order-detail-card-body">
                        <div className="order-detail-items">
                            {
                                Object.keys(order).length > 0 && (
                                    order.products.map((product) => {
                                        return <OrderItem product={product} order={order}/>
                                    })
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="order-info-section row g-2">

                <div className="order-info-summary col-12 col-md-6">
                    <div className="order-info-card card py-2 px-4">
                        <div className="order-info-title">
                            <h5>Order Summary Details</h5>
                            <hr/>
                            <div className="order-sub-total row w-100">
                                <div className="sub-total-label col-6">
                                    <span><b>Sub Total</b></span>
                                </div>
                                <div className="sub-total-amt col-6 d-flex justify-content-end">
                                    <span className="text-muted"><b>Rs. {order.sub_total}</b></span>
                                </div>
                            </div>
                            <div className="order-delivery row w-100">
                                <div className="order-delivery-label col-6">
                                    <span><b>Delivery Charge</b></span>
                                </div>
                                <div className="order-delivery-amt col-6 d-flex justify-content-end">
                                    <span
                                        className="text-muted"><b>Rs. {order.total_amount - order.sub_total}</b></span>
                                </div>
                            </div>
                            <hr/>
                            <div className="order-total row w-100">
                                <div className="order-total-label col-6">
                                    <span><b>Total Amount</b></span>
                                </div>
                                <div className="order-total-amt col-6 d-flex justify-content-end">
                                    <span className="text-muted"><b>Rs. {order.total_amount}</b></span>
                                </div>
                            </div>

                            <div className="order-paid-by row w-100 mt-3">
                                <div className="order-pay-label col-6">
                                    <span><b>Paid By</b></span>
                                </div>
                                <div className="order-total-amt col-6">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="order-info-shipping col-12 col-md-6">
                    <div className="order-info-card card py-2 px-4">
                        <div className="order-info-title">
                            <h5>Shipping Details</h5>
                            <hr/>
                            <div className="order-info-address">
                                <span><b>Shipping Address: </b><span
                                    className='text-muted'>{order.address}</span></span>
                            </div>
                            <div className="order-info-number">
                                <span><b>Contact Number: </b><span className='text-muted'>+{order.phone}</span></span>
                            </div>
                        </div>
                    </div>
                    {
                        (order.status == "new") && (
                            <div className="order-info-btns mt-5 d-flex justify-content-end">
                                <div className="btn btn-success mx-2">
                                    Pay
                                </div>
                            </div>
                        )
                    }
                    {
                        order.status != "cancelled" &&
                        <div className="btn btn-danger"
                             onClick={(e) => navigate("/user/cancel-order/" + order.order_number)}>
                            Cancel Order
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}

export default OrderDetail
