import React, {useEffect} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {useVerifyPayment} from "../../../features/payment/useVerifyPayment";
import {useChkLogin} from "../../../middlewares/LoginMiddleware";
import {getPaymentType} from "../../../features/payment/paymentTypes";

const PaymentPage = () => {
    const params = useParams();
    const [searchParams] = useSearchParams()

    const paymentFunction =  getPaymentType(params.payment_type)
    const {verifying, isVerified, verifyPayment, data} = useVerifyPayment()
    const {token} = useChkLogin()

    useEffect(() => {
        verifyPayment(paymentFunction(
            {
                pidx: searchParams.get('pidx'),
                id: params.id
            },
            token
        ))
    }, [])

    if (verifying) {
        return <h1>Verifying....</h1>
    }

    if (!!isVerified) {
        return (
            <div className="payment-page">
                <div className="container my-5">
                    <div className="d-flex justify-content-center align-items-center py-5">
              <span className="text-success fs-4">
                Payment Completed Successfully
              </span>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="payment-page">
                <div className="container my-5">
                    <div className="d-flex justify-content-center align-items-center py-5">
                    <span className="fs-4 text-danger">
                        Payment Verification Failed
                    </span>
                    </div>
                </div>
            </div>
        )
    }

};

export default PaymentPage;
