import React, { useState } from "react";
import ParentChildrenSelection from "../medical-history/partials/ParentChildrenSelection";
import ParentConsultationList from "./ParentConsultationList";
import ChildSelector from "./ChildSelector";

const ParentConsultation = () => {
  const [childId, setChildId] = useState(undefined);
  const handleChange = (id) => setChildId(id);

  return (
    <div className="d-grid gap-1">
      <ChildSelector handleChange={handleChange} />
      <div className="d-grid gap-3">
        <ParentConsultationList childId={childId} />
      </div>
    </div>
  );
};

export default ParentConsultation;
