import React from 'react'

const MyRequests = ({user, family, cancelRequest, myrequest, cancelFamilyRequest}) => {
    return (
        <>
            <table className="table table-bordered">
                <thead>
                {user.member_type === 'Primary Member' ?
                    <tr>
                        <th>Family Member Name</th>
                        <th>Phone No.</th>
                        <th>Action</th>
                    </tr>
                    :
                    <tr>
                        <th>Family Name</th>
                        <th>User</th>
                        <th>Phone No.</th>
                        <th>Action</th>
                    </tr>
                }
                </thead>
                <tbody>
                {user.member_type === 'Primary Member' ?
                    <>
                        {family.filter(f => f.primary_request === 1 && f.approved === 0).map((fam) =>
                            <tr key={fam.id}>
                                <td>{fam.member.user.name}</td>
                                <td>{fam.member.user.phone}</td>
                                <td>
                                    <button className="btn btn-danger btn-sm"
                                            onClick={() => cancelRequest(fam.id)}>Cancel
                                    </button>
                                </td>
                            </tr>
                        )}
                        {myrequest.map((famreq) =>
                            <tr key={famreq.id}>
                                <td>{famreq.receivemember.user.name}</td>
                                <td>{famreq.receivemember.user.phone}</td>
                                <td>
                                    <button className="btn btn-danger btn-sm"
                                            onClick={() => cancelFamilyRequest(famreq.id)}>Cancel Request
                                    </button>
                                </td>
                            </tr>
                        )}
                    </>
                    :
                    <>
                        {myrequest.map((famreq) =>
                            <tr key={famreq.id}>
                                <td>-</td>
                                <td>{famreq.receivemember.user.name}</td>
                                <td>{famreq.receivemember.user.phone}</td>
                                <td>
                                    <button className="btn btn-danger btn-sm"
                                            onClick={() => cancelFamilyRequest(famreq.id)}>Cancel Request
                                    </button>
                                </td>
                            </tr>
                        )}
                        {family.filter(f => f.primary_request === 0 && f.approved === 0).map((fam) =>
                            <tr key={fam.id}>
                                <td>{fam.familyname.family_name}</td>
                                <td>{fam.familyname.primary.user.name}</td>
                                <td>{fam.familyname.primary.user.phone}</td>
                                <td>
                                    <button className="btn btn-danger btn-sm"
                                            onClick={() => cancelRequest(fam.id)}>Cancel Request
                                    </button>
                                </td>
                            </tr>
                        )}
                    </>
                }
                </tbody>
            </table>
        </>
    )
}

export default MyRequests
