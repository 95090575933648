import React, { useState, useEffect, useMemo, useContext } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import * as dayjs from "dayjs";
import VaccinationService from "../../../../../services/vaccination.service";
import FormModalContext from "../../../../custom_componets/globalFormModal/FormModalContext";

// Debounce function
function debounce(func, delay) {
  let timeoutId;
  return function(...args) {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

const VaccinationInfo = (props) => {
  const { childId } = props;
  const [search, setSearch] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const { openFormModal } = useContext(FormModalContext);
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ["uploaded-vaccination-data", childId],
    queryFn: () => VaccinationService.getVaccinationInformation(childId),
    enabled: Boolean(childId),
  });

  const fetchSearchedVaccination = async (search) => {
    setIsSearching(true);
    if (search) {
      try {
        const response = await VaccinationService.fetchSearchedVaccination(
          search
        );
        setSearchedData(response?.data || []);
      } catch (error) {
        console.error("Error fetching searched data:", error);
      }
    } else {
      setSearchedData([]);
    }
    setIsSearching(false);
  };

  const debouncedFetchSearchedVaccination = useMemo(
    () => debounce((search) => fetchSearchedVaccination(search), 1000),
    []
  );

  useEffect(() => {
    debouncedFetchSearchedVaccination(search);
  }, [search, debouncedFetchSearchedVaccination]);

  const renderLoader = () => (
    <td colSpan={6}>
      <div className="d-flex gap-3 justify-content-center align-items-center py-5">
        <CircularProgress /> Loading...
      </div>
    </td>
  );

  const renderUI = () => {
    if (isLoading || isSearching) {
      return renderLoader();
    }

    const dataToRender = search
      ? searchedData?.vaccinations || []
      : data?.vaccinations || [];

    if (dataToRender.length) {
      return (
        <>
          {dataToRender.map((item, index) => (
            <tr key={item.id}>
              <td data-cell="S.N">{index + 1}</td>
              <td data-cell="Vaccination Date">
                {dayjs(item.vaccination_date).format("YYYY-MM-DD")}
              </td>
              <td data-cell="Vaccine Name">{item.vaccine_name}</td>
              <td data-cell="Dose Information">{item.dose_information}</td>
              <td data-cell="Location">{item.place_of_vaccination}</td>
              <td data-cell="Provider">{item.provider}</td>
            </tr>
          ))}
        </>
      );
    }

    return (
      <tr>
        <td colSpan={6}>
          <p className="text-center">No data found.</p>
        </td>
      </tr>
    );
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-grid gap-2 mb-4">
          <div className="d-flex align-items-center justify-content-between pb-2">
            <h4 className="fs-5 fw-bolder lh-sm">Vaccinations</h4>
          </div>

          <input
            style={{ width: "250px" }}
            className="ml-auto mr-4 rounded p-1"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <table className="table border-left border-right border-top responsive-table">
            <thead>
              <Box component="tr" bgcolor={"#"}>
                <th data-cell="S.N">
                  <span className="text-muted">S.N.</span>
                </th>
                <th data-cell="Vaccination Date">
                  <span className="text-muted">Vaccination Date</span>
                </th>
                <th data-cell="Vaccine Name">
                  <span className="text-muted">Vaccine Name</span>
                </th>
                <th data-cell="Dose Information">
                  <span className="text-muted">Dose Information</span>
                </th>
                <th data-cell="Location">
                  <span className="text-muted">Location</span>
                </th>
                <th data-cell="Provider">
                  <span className="text-muted">Provider</span>
                </th>
              </Box>
            </thead>
            <tbody>{renderUI()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VaccinationInfo;
