import { Box } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function AccountSide() {
  return (
    <div className="p-4">
      {/* <Box className='mb-1 p-2' bgcolor='primary.main' width='fit-content' borderRadius={10} color='white' >
                <span className='fw-light fs-6'>My Profile</span>
            </Box> */}

      <AccountSideItem label="My Profile" urllink={"/user/account"} />
      <AccountSideItem
        label="Security Setting"
        urllink={"/user/account/security-setting"}
      />
      <AccountSideItem
        label="Payment Methods"
        urllink={"/user/account/payment-methods"}
      />
      <AccountSideItem
        label="Notification Settings"
        urllink={"/user/account/notification-setting"}
      />
      <AccountSideItem
        label="Billing/Delivery Address"
        urllink={"/user/account/billing-address"}
      />
      {/* <div className='mb-1 p-2'>
                <span className='fw-light fs-6'>Security Setting</span>
            </div>
            <div className="mb-1 p-2">
                <span className="fw-light fs-6">Payment Methods</span>
            </div>
            <div className="mb-1 p-2">
                <span className="fw-light fs-6">Notification Setting</span>
            </div>
            <div className="mb-1 p-2">
                <span className="fw-light fs-6">Billing/Delivery Address</span>
            </div> */}
    </div>
  );
}

function AccountSideItem({ label, urllink }) {
  const navigate = useNavigate();
  // const [active, setActive] = useState(false)
  console.log("ZThe location is == &&", window.location.pathname);

  function handleClick() {
    navigate(urllink);
  }

  function getActiveProps() {
    let active = urllink === window.location.pathname;
    if (active) {
      return {
        bgcolor: "primary.main",
        color: "white",
        borderRadius: 10,
      };
    }
  }

  return (
    <Box
      className="mb-1 p-2"
      width="fit-content"
      {...getActiveProps()}
      onClick={handleClick}
      role="button"
    >
      <span className="fw-light fs-6">{label}</span>
    </Box>
  );
}

export default AccountSide;
