import {Button} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";
import classNames from "classnames";

const DeleteRow = (props) => {
    const {fieldsLength, handleRemove, index, handleAppend, classes} = props

    return (
        <div className={classNames("d-flex gap-3", classes)}>
            {fieldsLength > 1 &&
                <Button htlmType={"button"} type="primary" style={{
                    border: "1px dashed #ff5555",
                    borderRadius: 6,
                    padding: '8px 2px',
                    color: '#ff5555'
                }} onClick={() => handleRemove(index)}>
                    <DeleteOutlinedIcon />
                </Button>}
            {(fieldsLength - 1 === 0 && index === 0) || (fieldsLength - 1 === index) ?
                <Button htlmType={"button"} type="primary"
                        style={{
                            border: "1px dashed #063b9d",
                            borderRadius: 6,
                            padding: '8px 2px',
                            color: "#063b9d"
                        }} onClick={handleAppend}>
                    <AddIcon />
                </Button>
                : ""}
        </div>
    )
}

export default DeleteRow
