import {createContext, useContext} from "react";
import {useLocation} from "react-router-dom";

const BookingContext = createContext()

const BookingProvider = ({children}) => {
    const location = useLocation();
    const fee = location.state.fee || null;
    const id = location.state.id;
    const redirectLink = '/booking/doctor'

    return (
        <BookingContext.Provider
            value={{fee, id}}
        >
            {children}
        </BookingContext.Provider>
    )
}

export default BookingProvider

export const useBookingCtx = () => useContext(BookingContext)
