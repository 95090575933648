import { useChkLogin } from "../../middlewares/LoginMiddleware";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "../api";
import { Box } from "@mui/material";
import { blue } from "@mui/material/colors";
import { CallOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import Modal from "./partial/Modal";
import ConsultationService from "./consultation.service";

function CallButton({ bgColor = "", children, onClick }) {
  return (
    <Box
      fontSize="14px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={1}
      flexGrow={1}
      color="white"
      borderRadius={1}
      bgcolor={bgColor}
      className="p-1"
      onClick={onClick}
      boxShadow={1}
      style={{ cursor: "pointer" }}
    >
      {children}
    </Box>
  );
}

function ConsultationCall(props) {
  const { id, remainingConsultation = 0, consultUserName } = props;
  const loginObj = useChkLogin();

  const pkgData = useQuery({
    queryKey: ["package_user"],
    queryFn: async () =>
      axios
        .get("api/admin/userpackage", {
          headers: { Authorization: `Bearer ${loginObj.token}` },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err)),
    enabled: false,
  });

  const [openModal, setOpenModal] = useState(false);

  const { isLoading, mutate: consultMutation } = useMutation({
    mutationFn: ConsultationService.getConsultation,
    onSuccess: (res) => {
      if (res && res?.data?.data?.meeting_url) {
        window.open(res.data?.data?.meeting_url, "_blank");
      }
    },
  });

  function handleCallConsultation() {
    if (id) {
      consultMutation(id);
    }
  }

  const handleCLose = () => {
    setOpenModal(false);
  };

  const description = (
    <p>
      You are calling gd for online consultation. You have{" "}
      <b className="font-weight-bold">{remainingConsultation}</b> consultation
      call left{" "}
    </p>
  );

  return (
    <div className="d-flex">
      <CallButton bgColor={blue[900]} onClick={() => setOpenModal(true)}>
        <CallOutlined sx={{ fontSize: "14px" }} />
        Package Consultation
      </CallButton>
      {openModal && (
        <Modal
          title={
            <p>
              Call online consultation for{" "}
              <b className="font-weight-bold">{consultUserName}</b>
            </p>
          }
          description={description}
          handleConfirm={() => handleCallConsultation()}
          isLoading={isLoading}
          handleCLose={handleCLose}
          remainingConsultation={remainingConsultation}
        />
      )}
    </div>
  );
}

export default ConsultationCall;
