const App = function () {

    function getRectAfterScroll(elem) {
        var rect = elem.getBoundingClientRect()
        // rect.y = rect.y + window.scrollY
        // rect.top = rect.top + window.scrollY
        // rect.x = rect.x + window.scrollX
        // rect.left = rect.left + window.scrollX

        return {
            y: rect.y + window.scrollY,
            top: rect.top + window.scrollY,
            x: rect.x + window.scrollX,
            left: rect.left + window.scrollX,
            width: rect.width,
            height: rect.height
        }

        // return rect
    }

    function imageZoomerPosition(e) {
        let zoomDiv = this.getElementsByClassName('zoom-overlay')[0];
        let imgDiv = this.getElementsByTagName('img')[0]
        var rect = getRectAfterScroll(imgDiv)
        var thisRect = getRectAfterScroll(this)

        var width = 150;
        var height = 100;

        var x = e.clientX + window.scrollX;
        var y = e.clientY + window.scrollY;

        let leftCoordinate = (x - thisRect.left) - width / 2
        let topCoordinate = (y - thisRect.top) - height / 2

        if ((leftCoordinate + width) >= (thisRect.width - (rect.left - thisRect.left))) {
            leftCoordinate = (thisRect.width - (rect.left - thisRect.left)) - width
        }
        if (leftCoordinate <= (rect.left - thisRect.left)) {
            leftCoordinate = rect.left - thisRect.left
        }
        if ((topCoordinate + height) >= (thisRect.height - (rect.top - thisRect.top))) {
            topCoordinate = (thisRect.height - (rect.top - thisRect.top)) - height
        }
        if (topCoordinate <= (rect.top - thisRect.top)) {
            topCoordinate = rect.top - thisRect.top
        }

        function hoverBox() {
            zoomDiv.style.width = width + 'px';
            zoomDiv.style.height = height + 'px';

            zoomDiv.style.left = leftCoordinate + "px"
            zoomDiv.style.top = topCoordinate + 'px'

            zoomDiv.style.overflow = "hidden";
            // zoomDiv.style.opacity="1"
        }

        function zoomContent(elem) {
            var reactImgMagnify = elem
            var imgZoomContent = reactImgMagnify.getElementsByClassName('image-zoom-content');

            if (reactImgMagnify.getElementsByClassName('image-zoom-content').length == 0) {
                var zoomDiv = document.createElement("div")
                zoomDiv.classList.add('image-zoom-content')
                zoomDiv.style.width = width * 4 + 'px'
                zoomDiv.style.height = height * 4 + 'px'

                var img = document.createElement("img");

                zoomDiv.appendChild(img);

                reactImgMagnify.appendChild(zoomDiv)
            }

            var zoomdiv = document.getElementsByClassName('image-zoom-content')[0]
            var imgZoom = zoomdiv.getElementsByTagName('img')[0]
            imgZoom.src = imgDiv.src;
            imgZoom.style.width = imgDiv.clientWidth * 4 + 'px';
            imgZoom.style.height = imgDiv.clientHeight * 4 + 'px';
            imgZoom.style.top = "-" + (topCoordinate - (rect.top - thisRect.top)) * 4 + 'px';
            imgZoom.style.left = "-" + (leftCoordinate - (rect.left - thisRect.left)) * 4 + 'px';
        }

        function imageMagnifyingGlass() {
            var img = document.createElement("img");
            img.src = imgDiv.src
            img.style.width = imgDiv.clientWidth * 2 + "px";
            img.style.height = imgDiv.clientHeight * 2 + 'px';
            zoomDiv.appendChild(img);
        }

        hoverBox()
        zoomContent(this)
        // imageMagnifyingGlass()
    }

    function removeImageZoom(e) {
        let imageZoomCon = this.getElementsByClassName('image-zoom-content')[0]

        this.removeChild(imageZoomCon)

    }

    function initalizeZoom() {
        let imageMagnifiers = document.getElementsByClassName("image-magnifier")

        for (var i = 0; i < imageMagnifiers.length; i++) {
            imageMagnifiers[i].addEventListener('mousemove', imageZoomerPosition)
            imageMagnifiers[i].addEventListener('mouseleave', removeImageZoom)
        }
    }

    return {
        init: function () {
            console.log()
            initalizeZoom()
        }
    }
}()

export {App}
