import {Box, CircularProgress} from '@mui/material'
import React from 'react'

function ContentLoader() {
    return (
        <Box width='100%' display='flex' justifyContent='center' my={5}>
            <CircularProgress/>
        </Box>
    )
}

export default ContentLoader
