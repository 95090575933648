import React from "react";
import Esewa from "../../../images/payment/esewa.png";
import Khalti from "../../../images/payment/khalti.png";
import ImePay from "../../../images/payment/imepay.png";
import ConnectIPS from "../../../images/payment/connectips.png";
import {useSearchParams} from "react-router-dom";
import axios from "../../api.js";
import KhaltiOrder from "../../pages/khalti/KhaltiOrder";
import OrderVerifyingUI from "./OrderVerifyingUI";
import {Box, Button, Card, CardContent, CardHeader, Stack, Typography} from "@mui/material";
import {useKhalti} from "../../pages/khalti/useKhalti";
import {useEffect} from "react";
import {LocationCity, ShoppingCartCheckout} from "@mui/icons-material";

const OrderPayment = ({setSideContent}) => {
    const [order, setOrder] = React.useState({});
    const [orderLoading, setOrderLoading] = React.useState(true)
    const [searchParams, setSearchParams] = useSearchParams();
    const [orderVerifying, setOrderVerifying] = React.useState(false);
    const currentParam = Object.fromEntries(searchParams);
    const token = localStorage.getItem("token");
    const {checkoutAmount, verifying} = useKhalti(order?.total_amount, order?.id, async (payload, id, usertoken) => {
        let res = await axios.post('/api/admin/order/paymentOrder', {
            token: payload.token,
            amount: payload.amount,
            id: id
        }, {headers: {"Authorization": `Bearer ${usertoken}`}})
    })

    useEffect(() => {
        if (!orderLoading) {
            setSideContent(
                <Card variant='outlined'>
                    <CardHeader title={<><ShoppingCartCheckout/> Order Summary </>}
                                subheader={<><LocationCity/>{order.address}</>}/>
                    <CardContent sx={{py: 0, pb: '0px !important'}}>
                        <Box py={2}>
                            <Stack direction={'row'} justifyContent='space-between'>
                                <Typography variant='subtitle1'>
                                    Sub Total
                                </Typography>
                                <Typography variant='subtitle1' color="primary" fontWeight={600}>
                                    NPR. {order?.sub_total}
                                </Typography>
                            </Stack>
                            <Stack direction={'row'} justifyContent='space-between'>
                                <Typography variant='subtitle1'>
                                    Shipping Charge
                                </Typography>
                                <Typography variant='subtitle1' color="primary" fontWeight={600}>
                                    NPR. {order?.shipping?.price}
                                </Typography>
                            </Stack>
                            <Stack direction={'row'} justifyContent='space-between'>
                                <Typography variant='subtitle1'>
                                    Total Discount
                                </Typography>
                                <Typography variant='subtitle1' color="primary" fontWeight={600}>
                                    NPR. {order?.sub_total + Number(order?.shipping?.price) - order?.total_amount}
                                </Typography>
                            </Stack>
                        </Box>
                        <Stack direction={'row'} justifyContent='space-between'
                               sx={{borderTop: 1, borderColor: 'divider'}} py={2}>
                            <Typography variant='h6'>
                                Total Payable
                            </Typography>
                            <Typography variant='h6' color="primary" fontWeight={600}>
                                NPR. {order?.total_amount}
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>
            )
        }
    }, [orderLoading])

    React.useEffect(() => {

        const getParam = new URLSearchParams([
            ["order_number", currentParam.orderNum],
        ]);
        axios
            .get("api/admin/order?order_number=" + currentParam.orderNum, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {

                setOrder(res.data[0])
                setOrderLoading(false)
            })
            .catch((err) => {
                setOrderLoading(false)
            })
    }, []);

    if (orderLoading) {
        return
    } else {
        if (!order || order.payment_status != "unpaid") {
            return <h2>You encounterd a glitch.</h2>
        }
    }

    return (
        <>
            <Card variant='outlined'>
                <CardHeader title="Payment Details" subheader="Choose Your Payment Method"/>
                <CardContent sx={{py: 0, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                    <Button onClick={checkoutAmount}>
                        <img src={Khalti} style={{width: '140px'}}/>
                    </Button>
                    <Button>
                        <img src={Esewa} style={{width: '140px'}}/>
                    </Button>
                    <Button>
                        <img src={ImePay} style={{width: '140px'}}/>
                    </Button>
                    <Button>
                        <img src={ConnectIPS} style={{width: '140px'}}/>
                    </Button>
                    <Button>
                        <img src={ConnectIPS} style={{width: '140px'}}/>
                    </Button>

                </CardContent>
            </Card>

            {
                orderVerifying && <OrderVerifyingUI/>
            }
        </>

    );
};

export default OrderPayment;
