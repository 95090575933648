import {useQuery} from '@tanstack/react-query'
import React from 'react'
import FitnessContainer from '../../../fitnessCenter/components/FitnessContainer'
import FitnessProdCard from '../../../fitnessCenter/components/FitnessProdCard'
import CatalogSlider from './CatalogSlider'
import axios from '../../../../api'
import ProductCards from '../../../../stores/ProductCards'
import {settings5items} from '../../sliderSettings/View5itemsOnlg'

function LatestProducts() {
    const {data, isLoading} = useQuery(
        ['latestProds'],
        async () => (
            axios.get("/api/products?latest=desc").then(res => res.data.data)
        )
    )
    if (isLoading) {
        return
    }
    return (
        <FitnessContainer title={"Latest Products"}>
            <CatalogSlider settingProps={settings5items}>
                {
                    data.map((prods) => (
                        <div>
                            <ProductCards data={prods}/>
                        </div>
                    ))
                }
            </CatalogSlider>
        </FitnessContainer>
    )
}

export default LatestProducts
