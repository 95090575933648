import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight, faCaretRight} from '@fortawesome/free-solid-svg-icons';
import React from 'react'
import {useState} from 'react';
import {useEffect} from 'react';
import axios from "../../api.js";
import "./NurseBooking.css";
import {Link, useSearchParams} from 'react-router-dom';
import NurseShift from './NurseShift.js';
import Loader from '../Loader.js';
import Pagination from "react-js-pagination";
import AddressAutoComp from '../../AddressAutoComp.js';
import Slider from '@mui/material/Slider';
import {useChkLogin} from '../../../middlewares/LoginMiddleware.js';

const NurseBooking = () => {
    const [nursePaginate, setNursePaginate] = useState([]);
    const {token, isLoading} = useChkLogin();
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useSearchParams();
    const currentParams = Object.fromEntries([...search]);
    const [filterParams, setFilterParams] = React.useState({})
    const [locationTxt, setLocationTxt] = React.useState('')
    const [coordinates, setCoordinates] = React.useState(null)
    const [defRadius, setDefRadius] = React.useState(5)
    const distanceSlider = React.useRef()

    const nurses = nursePaginate.data || []

    useEffect(() => {
        setLoading(true);
        if (Object.keys(currentParams).length > 0) {
            axios
                .get(`/api/nurse`, {params: {...currentParams}})
                .then((res) => {
                    //console.log();
                    if (res.data !== "") {
                        setNursePaginate(res.data);
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log();
                });
        } else {
            axios
                .get(`/api/nurse`)
                .then((res) => {
                    //console.log();
                    setNursePaginate(res.data);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log();
                });
        }
    }, [search]);

    const searchNurse = e => {
        setSearch({...currentParams, keyword: e.target.value});

        if (e.target.value === '') {
            delete currentParams.keyword;
            setSearch({...currentParams});

        }
    };

    const searchNurseType = e => {
        setFilterParams({...filterParams, nurse_type: e.target.value});
        if (e.target.value === '') {
            delete filterParams.nurse_type
            setFilterParams({...filterParams});
        }
    };

    function valuetext(e, value) {
        setDefRadius(value)
        setRadiusFilter(value)
    }

    function setRadiusFilter(value) {
        if ('latitude' in filterParams) {
            setFilterParams({...filterParams, distance: value})
        }
    }

    function changePage(page) {
        setSearch({...currentParams, page: page})
    }

    function autoCompLocation(coordinates) {
        setLocationTxt(coordinates.locationStr)
        setCoordinates(coordinates)
        let radius = defRadius
        if (defRadius === "" || defRadius === 0) {
            radius = 0.1
            setDefRadius(0.1)
        }
        setFilterParams({
            ...filterParams,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
            distance: radius
        })
    }

    function filterResults() {
        //console.log()
        setSearch({...filterParams})
    }

    function clearFilters() {
        let selectInp = document.getElementsByTagName('select');
        for (let i = 0; i < selectInp.length; i++) {
            selectInp[i].selectedIndex = 0;
        }
        setLocationTxt('')
        setFilterParams({})
        if ('page' in currentParams) {
            let pg = currentParams.page
            delete currentParams.page
            if (currentParams.length === 0) {
                setSearch({page: pg})
            }
        } else {
            setSearch({})
        }
    }

    return (
        <>
            <div className="booking">
                <div className="heading">
                    <div className="container text-center">
                        <h3>Search Nurse, Book Nurse</h3>
                        <p>
                            Discover the best nurses, clinic &amp; hospital nearest to you.
                        </p>
                    </div>
                </div>
                <div className="search">
                    <div className="container py-3">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="doctor-search">Search Nurse</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Nurse"
                                        onChange={searchNurse}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="doctor-search">Location</label>
                                    <AddressAutoComp
                                        getLocation={autoCompLocation}
                                        inputValue={locationTxt}
                                        onChange={(address) => {
                                            setLocationTxt(address)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 d-flex align-items-end justify-content-end ">
                                <div className="form-group mt-3 w-100">
                                    <label htmlFor="doctor-search" className="me-4">Radius in Kilometer</label>
                                    <div className="row">
                                        <div className="slider-container col-9">
                                            {/* <Slider
                            aria-label="Small steps"
                            onChange={valuetext}
                            min={0.1}
                            max={5}
                            valueLabelDisplay="auto"
                            value={defRadius}
                          /> */}
                                            <Slider
                                                value={(typeof defRadius === 'number' || defRadius != 0) ? defRadius : 0.1}
                                                onChange={valuetext}
                                                min={0.1}
                                                max={5}
                                                aria-labelledby="input-slider"
                                            />
                                        </div>

                                        <input ref={distanceSlider} type="number" value={defRadius} className="col-3"/>
                                    </div>

                                </div>
                            </div>
                            {/* <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="doctor-search">Find by Nurse Type</label>
                  <select name="nurse_type" id="" className="form-select" onChange={searchNurseType} value={filterParams.nurse_type}>
                    <option value="">--Select--</option>
                    <option value="Homecare Nurse">Homecare Nurse</option>
                    <option value="GD Office Nurse">GD Office Nurse</option>
                  </select>
                </div>
              </div> */}
                            <div className="col-md-4"></div>
                            <div className="col-md-4 d-flex align-items-end justify-content-end ">
                                <div className="form-group mt-3 w-100">
                                </div>
                            </div>
                            <div className="col-md-4 d-flex align-items-end justify-content-end mt-3">
                                <div className="filter-btns">
                                    <button className="btn btn-outline-primary me-3" onClick={filterResults}>
                                        Apply Filter
                                    </button>
                                    <button className="btn btn-outline-danger" onClick={clearFilters}>
                                        Clear Filters
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading || isLoading ?
                    <div className="text-center">
                        <Loader/>
                    </div>
                    :
                    <div className="container py-3">
                        <div className="d-flex flex-wrap">
                            {nurses.map((nurse) =>
                                <>
                                    <div className="col-lg-5 col-md-6 my-4 flex-fill" key={nurse.id}>
                                        <div className="card py-2" style={{borderRadius: '0', height: '100%'}}>
                                            <div className="card-body">
                                                <div className="d-flex align-items-center flex-wrap">
                                                    <img src={nurse.image_path} alt="" className='nurse-img'/>
                                                    <div className='ms-4'>
                                                        <ul className="list-unstyled mb-2">
                                                            <h5>{nurse.user.name}</h5>
                                                            <li><FontAwesomeIcon icon={faCaretRight} className="me-2"/>NNC
                                                                No. : {nurse.nnc_no}</li>
                                                            <li><FontAwesomeIcon icon={faCaretRight}
                                                                                 className="me-2"/>{nurse.nurse_type}
                                                            </li>
                                                            <li><FontAwesomeIcon icon={faCaretRight} className="me-2"/>Experience
                                                                : {nurse.year_practiced} years
                                                            </li>
                                                            <li><FontAwesomeIcon icon={faCaretRight} className="me-2"/>Booking
                                                                Fee : Rs. {nurse.fee}</li>
                                                        </ul>
                                                        <Link to={`/nurse/${nurse.slug}`}
                                                              className="btn btn-primary py-1">View
                                                            Profile<FontAwesomeIcon icon={faAngleRight}
                                                                                    className="ms-4"/></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-6 my-4 flex-fill">
                                        <div className="w-100 h-100">
                                            <NurseShift token={token} nurse={nurse}/>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <Pagination
                            activePage={nursePaginate.current_page}
                            totalItemsCount={nursePaginate.total}
                            itemsCountPerPage={nursePaginate.per_page}
                            onChange={changePage}
                            itemClass="page-item"
                            linkClass="page-link"
                            firstPageText="First"
                            lastPageText="Last"
                        />
                    </div>
                }
            </div>
        </>
    );
}

export default NurseBooking
