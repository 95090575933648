import React from "react";
import Logo from "../../../../images/blue-logo.png";

function GDLogo() {
  const style = {
    width: "100%",
  };
  return (
    <div className="gd-logo" style={{ width: "20rem" }}>
      <img src={Logo} alt="Ghargharmadoctor logo" style={style} />
    </div>
  );
}

export default GDLogo;
