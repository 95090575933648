import React, {useEffect, useReducer} from 'react'
import {createContext} from 'react'
import {useState} from 'react'

const ReloadContext = createContext(null)

function ReloadComponent({children, setDependStat, dependStat = true}) {
    const [reload, setReload] = useState(false)
    const [status, setStatus] = useState(true)
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    // const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        if (reload) {
            setStatus(false)
            setDependStat(false)
            setReload(false)
            return
        }

        if (!reload) {
            setStatus(true)
            setDependStat(true)
        }
    }, [reload])

    if (!dependStat) {
        return
    }
    if (!status) {
        return
    }
    return (
        <ReloadContext.Provider
            value={{setReload: setReload, setError: setError, error: error, success: success, setSuccess: setSuccess}}>
            {children}
        </ReloadContext.Provider>
    )
}

export default ReloadComponent
export {ReloadContext}

ReloadComponent.defaultProps = {
    setDependStat: () => {
        return
    }
}
