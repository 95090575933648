import React, { Suspense, useEffect, useRef, useState } from "react";
import "./App.css";
import Bot from "./components/chatbot/Bot";
import Home from "./components/pages/homes/Home";
import Contact from "./components/pages/contacts/Contact";
import Service from "./components/pages/service/Services";
import { Route, Routes } from "react-router-dom";
import Login from "./components/auth/Login";
import SignUp from "./components/auth/SignUp";
import VendorRegister from "./components/VendorRegister";
import TrustedVendors from "./components/TrustedVendors";
import Store from "./components/pages/stores/Store";
import Booking from "./components/pages/ChooseDate/Booking";
import BookingForm from "./components/pages/ChooseDate/BookingForm";
import Payment from "./components/pages/payment/Payment";
import PackageBooking from "./components/pages/packages/PackageBooking";
import WishList from "./components/pages/stores/WishList";
import ProductPage from "./components/pages/stores/ProductPage";
import SinglePackage from "./components/pages/packages/SinglePackage";
import Checkout from "./components/pages/stores/Checkout";
import PageNotFound from "./components/pages/PageNotFound";
import BookingPayment from "./components/pages/ChooseDate/BookingPayment";
import StoreHome from "./components/pages/stores/StoreHome";
import OrderPage from "./components/pages/stores/OrderPage";
import StoreLayout from "./components/layouts/StoreLayout";
import VendorPage from "./components/pages/stores/VendorPage";
import WebLayout from "./components/layouts/WebLayout";
import DashboardLayout from "./components/layouts/Dashboard/DashboardLayout";
import Dashboard from "./components/pages/dashboard/dashboardHome/Dashboard";
import ScrollToTop from "./components/ScrollToTop";
import Profile from "./components/pages/dashboard/profile/Profile";
import EditProfile from "./components/pages/dashboard/profile/EditProfile";
import ServiceBooking from "./components/pages/service/ServiceBooking";
import OrderDetail from "./components/pages/stores/OrderDetail";
import CancelOrder from "./components/pages/stores/CancelOrder";
import NurseBooking from "./components/pages/nurse/NurseBooking";
import MyAppointments from "./components/pages/dashboard/appointments/MyAppointments";
import AboutPage from "./components/pages/abouts/AboutPage";
import Symptom from "./components/pages/symptoms/Symptom";
import ServicePage from "./components/pages/servicePage.js/ServicePage";
import AllSpeciality from "./components/pages/speciality/AllSpeciality";
import PackagePage from "./components/pages/packages/PackagePage";
import Team from "./components/pages/team/Team";
import DoctorProfile from "./components/pages/doctors/DoctorProfile";
import CircularSlider from "./components/CircularSlider";
import PackageDetails from "./components/pages/packages/PackageDetails";
import ServiceFilter from "./components/pages/labServices/ServiceFilter";
import GDStoreLayout from "./components/layouts/GDStore/GDStoreLayout";
import AddFamily from "./components/pages/dashboard/family/AddFamily";
import ServiceDetail from "./components/pages/service/ServiceDetail";
import NurseForm from "./components/pages/nurse/NurseForm";
import NursePayment from "./components/pages/nurse/NursePayment";
import PackagePayment from "./components/pages/packages/PackagePayment";
import NurseProfile from "./components/pages/nurse/NurseProfile";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MyPackageDetail from "./components/pages/dashboard/packages/MyPackageDetail";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import LabTestBooking from "./components/pages/dashboard/labTestBooking/LabTestBooking";
import ServicePayment from "./components/pages/service/ServicePayment";
import AppointmentDetail from "./components/pages/dashboard/appintmentDetail/AppointmentDetail";
import LoginMiddleware from "./middlewares/LoginMiddleware";
import ProtectedRoute from "./middlewares/ProtectedRoute";
import RestrictMiddleware from "./middlewares/RestrictMiddleware";
import Ambulance from "./components/ambulance/Ambulance";
import LabTestBookingDetail from "./components/pages/dashboard/labTestBooking/LabTestBookingDetail";
import ChangePassword from "./components/pages/dashboard/changePassword/ChangePassword";
import MyNurseAppointment from "./components/pages/dashboard/nurseAppointment/MyNurseAppointment";
import MyNurseAppointmentDetail from "./components/pages/dashboard/nurseAppointment/MyNurseAppointmentDetail";
import Notification from "./components/pages/dashboard/notification/Notification";
import ViewReport from "./components/pages/dashboard/dashboardHome/components/ViewReport";
import ProductDetPage from "./components/pages/stores/ProductPage/ProductDetPage";
import ComparePg from "./components/pages/packageComparision/ComparePg";
import GMap from "./components/GMap";
import { HelmetProvider } from "react-helmet-async";
import EventDetailPage from "./components/events/EventDetailPage";
import ClaimSettlement from "./components/pages/dashboard/insurance/ClaimSettlement";
import ClaimSettlementDetails from "./components/pages/dashboard/insurance/ClaimSettlementDetails";
import MedicalHistory from "./components/pages/dashboard/medicalHistory/MedicalHistory";
import AddMedical from "./components/pages/dashboard/medicalHistory/components/AddMedical";
import AddVaccination from "./components/pages/parent/vaccination/add";
import CalorieIntake from "./components/pages/dashboard/calorieIntake/CalorieIntake";
import ShareReport from "./components/pages/dashboard/appointments/ShareReport";
import FitnessHome from "./components/pages/fitnessCenter/FitnessHome";
import ThemeMUI from "./components/custom_componets/ThemeMUI";
import RecaptchaV3 from "./middlewares/RecaptchaV3";
import DemoChart from "./components/DemoChart";
import UserFitnessPackages from "./components/pages/dashboard/fitnessPackages/UserFitnessPackages";
import FamilyPayment from "./components/pages/dashboard/family/FamilyPayment";
import StoreHomeGD from "./components/pages/gdstores/gdstorehome/StoreHomeGD";
import GDVendors from "./components/pages/gdstores/gdvendors/GDVendors";
import BecomeADoctor from "./components/auth/BecomeADoctor";
import LabReport from "./components/pages/dashboard/labreport/LabReport";
import GDPharmacy from "./components/pages/gdstores/gdpharmacy/GDPharmacy";
import FamilyPackagePayment from "./components/pages/dashboard/family/FamilyPackagePayment";
import BecomeAMember from "./components/auth/BecomeAMember";
import CartPage from "./components/pages/cart/CartPage";
import CheckoutPage from "./components/pages/checkout/CheckoutPage";
import LabTestPage from "./components/pages/labTests/LabTestPage";
import LabTestDetail from "./components/pages/labTestDetail/LabTestDetail";
import TermsandConditions from "./components/auth/components/TermsandConditions";
import Policy from "./components/auth/components/Policy";

import GlobalFormView from "./components/pages/dashboard/globalFormView/GlobalFormView";
import TermsAndConditions from "./components/pages/terms_conditions/TermsAndConditions";

import "./firebase";
import { Toaster } from "react-hot-toast";

import GDNotification from "./components/GDNotification";
import InsuranceComponent from "./components/pages/dashboard/insurance/InsuranceComponent";
import PathologyReport from "./components/pages/dashboard/labreport/PathologyReport";
import Vacancy from "./components/pages/vacancy/Vacancy";
import VacancyDetail from "./components/pages/vacancy/VacancyDetail";
import { GlobalFormProvider } from "./components/pages/dashboard/kyc/GlobalFormProvider";
import TokenRefresh from "./middlewares/TokenRefresh";
import PhoneVerification from "./components/pages/dashboard/phoneVerification/PhoneVerification";
import TwoFactorAuth from "./components/auth/twoFactorAuth/TwoFactorAuth";
import ReferHome from "./components/pages/dashboard/referFriend/ReferHome";
import PackageBookingPage from "./components/pages/packageBooking/PackageBookingPage";
import axios from "./components/api";
import PaymentHistory from "./components/pages/dashboard/paymentHistory/PaymentHistory";
import GDBlogProvider from "./components/pages/gd_blogs/GDBlogProvider";
import GDBlogs from "./components/pages/gd_blogs/GDBlogs";
import GDBlogFilter from "./components/pages/gd_blogs/GDBlogFilter";
import GDBlogDetails from "./components/pages/gd_blogs/GDBlogDetails";
import MenuBlogs from "./components/pages/gd_blogs/MenuBlogs";
import ErrorBoundary from "./components/custom_componets/ErrorBoundry";
import { v4 as uuidv4 } from "uuid";
import GDChatbot from "./components/customChatBot/GDChatbot";
import CompanyProfileForm from "./components/pages/companyProfileForm/CompanyProfileForm";
import CorporateProfile from "./components/pages/dashboard/viewCorporate/CorporateProfile";
import CsvUpload from "./components/pages/schoolDashboard/csvupload/CsvUpload";
import EditCorpProfile from "./components/pages/dashboard/editcorpprofile/EditCorpProfile";
import FillSchoolProfileForm from "./components/pages/schoolProfileForm/FillSchoolProfileForm";
import StudentLists from "./components/pages/dashboard/studentLists/StudentLists";
import SchoolPackage from "./components/pages/dashboard/schoolPackage/SchoolPackage";
import StudentReport from "./components/pages/studentReport/StudentReport";
import EditSchoolProfile from "./components/pages/dashboard/editcorpprofile/EditSchoolProfile";
import AccountSetting from "./components/pages/dashboard/accountSettings/AccountSetting";
import InsuranceClaim from "./components/pages/insuranceClaim/InsuranceClaim";
import ConsultationHistory from "./components/pages/dashboard/consultationHistory/ConsultationHistory";
import ConsultationHistoryDetails from "./components/pages/dashboard/consultationHistory/ConsultationHistoryDetails";
import FamilyDesign from "./components/pages/dashboard/family/FamilyDesign";
import { FormModal } from "./components/custom_componets/globalFormModal/FormModal";
import FormModalContext from "./components/custom_componets/globalFormModal/FormModalContext";
import StudentDeactivateRequest from "./components/pages/dashboard/studentDeactivateRequest/StudentDeactivateRequest";
import MedicalHistoryDetail from "./components/pages/dashboard/medicalHistory/MedicalHistoryDetail";
import MyProfile from "./components/pages/dashboard/accountSettings/myprofile/MyProfile";
import SecuritySetting from "./components/pages/dashboard/accountSettings/securitySetting/SecuritySetting";
import DeactivateStudents from "./components/pages/dashboard/deactivatedStudents/DeactivateStudents";
import KhaltiPayment from "./features/payment/KhaltiPayment";
import PaymentPage from "./components/pages/paymentPage/PaymentPage";
import VerifyKhaltiPayment from "./features/khalti/components/VerifyKhaltiPayment";
import SchoolPayment from "./components/pages/dashboard/school-payment";
import ParentDashBoardLayout from "./components/layouts/parent/ParentDashBoardLayout";
import ParentDashboard from "./components/pages/parent/ParentDashboard";
import MedicalDashboard from "./components/pages/parent/medical-history";
import AddMedicalHistory from "./components/pages/parent/medical-history/add-history";
import ChildrenList from "./components/pages/parent/medical-history/children-list";
import Vaccination from "./components/pages/parent/vaccination";
import ParentConsultation from "./components/pages/parent/consultation-history";
import InsuranceClaimParent from "./components/pages/parent/insurance-claim/insurance-claim";
import InsuranceClaimSchool from "./components/pages/dashboard/insurance-claim/insurance-claim";

function App() {
  const formModalRef = useRef(null);

  const [fcm, setFCM] = useState();
  const [FCMLoading, setFCMLoading] = useState(true);
  const [address, setAddress] = useState("Koteshwor, Kathmandu");
  const [addressLoading, setAddressLoading] = useState(true);

  if (!localStorage.getItem("session_id")) {
    localStorage.setItem("session_id", uuidv4());
  }

  if (localStorage.getItem("cart") == null) {
    localStorage.setItem("cart", JSON.stringify([]));
  }

  useEffect(() => {
    if (!addressLoading) {
      axios.post("api/visitors", { location: address });
    }
  }, [addressLoading]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        let loc = await axios.get(
          `https://api.geoapify.com/v1/ipinfo?apiKey=68725a46c50b4690bf4c6d41efde7963`
        );
        let latLng = loc.data.location;
        axios
          .get(
            `https://api.geoapify.com/v1/geocode/reverse?apiKey=68725a46c50b4690bf4c6d41efde7963&lat=${latLng.latitude}&lon=${latLng.longitude}`
          )
          .then((result) => {
            let location = result.data.features[0].properties;
            setAddress(`${location.address_line1}, ${location.city}`);
          })
          .finally(() => setAddressLoading(false));
      },
      (err) => {}
    );
  }, []);

  return (
    <ErrorBoundary>
      <ScrollToTop>
        <Toaster />
        <HelmetProvider>
          <ThemeMUI>
            <RecaptchaV3>
              <LoginMiddleware>
                <TokenRefresh />
                <GDNotification setFCM={setFCM} setFCMLoading={setFCMLoading} />
                <Suspense>
                  <FormModal ref={formModalRef} />
                  <FormModalContext.Provider
                    value={{
                      openFormModal:
                        formModalRef != null &&
                        formModalRef?.current?.openModal,
                    }}
                  >
                    <Routes>
                      {/* All components that uses Web Layout are nested inside this route */}
                      <Route
                        path="/two-factor-auth"
                        element={<TwoFactorAuth />}
                      />

                      <Route
                        path="/verify-khalti-payment"
                        element={<VerifyKhaltiPayment />}
                      />

                      <Route
                        path="/khalti-payment"
                        element={<KhaltiPayment />}
                      />

                      <Route path="/" element={<WebLayout />}>
                        <Route
                          path="/package-book-details"
                          element={<PackageBookingPage />}
                        />
                        <Route index element={<Home />} />
                        <Route
                          path="/school-report"
                          element={<StudentReport />}
                        />
                        <Route
                          path="/insurance-claim"
                          element={<InsuranceClaim />}
                        />
                        <Route
                          path="/terms-conditions"
                          element={<TermsAndConditions />}
                        />
                        <Route path="/demo-chart" element={<DemoChart />} />
                        <Route
                          path="/termscondition"
                          element={<TermsandConditions />}
                        />
                        <Route path="/policy" element={<Policy />} />
                        <Route
                          path="/events/:slug"
                          element={<EventDetailPage />}
                        />
                        <Route
                          path="/package-compare"
                          element={<ComparePg />}
                        />
                        <Route path="/about" element={<AboutPage />} />
                        <Route
                          path="/doctor/:slug"
                          element={<DoctorProfile />}
                        />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/ambulance" element={<Ambulance />} />
                        <Route path="/service" element={<Service />} />
                        <Route
                          path="/service/:slug"
                          element={<ServicePage />}
                        />
                        <Route
                          path="/lab-services"
                          element={<ServiceFilter />}
                        />
                        <Route path="/lab-tests" element={<LabTestPage />} />
                        <Route
                          path="/lab-tests/:department"
                          element={<LabTestPage />}
                        />
                        <Route
                          path="/lab-tests/item"
                          element={<LabTestDetail />}
                        />
                        <Route path="/register" element={<SignUp />} />
                        <Route
                          path="/forgot-password"
                          element={<ForgotPassword />}
                        />
                        <Route
                          path="/reset-password"
                          element={<ResetPassword />}
                        />
                        <Route path="/vendor" element={<VendorRegister />} />
                        <Route path="/booking/doctor" element={<Booking />} />
                        <Route
                          path="/booking/nurse"
                          element={<NurseBooking />}
                        />
                        <Route path="/book-nurse" element={<NurseForm />} />
                        <Route path="/nurse/:slug" element={<NurseProfile />} />
                        <Route element={<ProtectedRoute />}>
                          <Route
                            path="/book-nurse/payment"
                            element={<NursePayment />}
                          />
                        </Route>
                        <Route
                          path="/trustedVendor"
                          element={<TrustedVendors />}
                        />
                        <Route
                          path="/specialities"
                          element={<AllSpeciality />}
                        />
                        <Route path="/symptoms" element={<Symptom />} />
                        <Route path="/team/:slug" element={<Team />} />
                        <Route path="/bot" element={<Bot />} />

                        <Route path="/slider" element={<CircularSlider />} />

                        <Route path="/book-doctor" element={<BookingForm />} />
                        <Route path="/packages" element={<PackagePage />} />
                        <Route path="/payment" element={<Payment />} />
                        <Route
                          path="/single-package"
                          element={<SinglePackage />}
                        />
                        <Route
                          path="/single-package/:slug"
                          element={<PackageDetails />}
                        />
                        <Route
                          path="/company-profile-form"
                          element={<CompanyProfileForm />}
                        />
                        <Route
                          path="/school-profile/:slug"
                          element={<FillSchoolProfileForm />}
                        />

                        <Route path="/career" element={<Vacancy />} />
                        <Route
                          path="/career/:params"
                          element={<VacancyDetail />}
                        />

                        {/* <Route path="/health-service/:slug" element={<SingleService />} /> */}
                        <Route
                          path="/health-service/:slug"
                          element={<ServiceDetail />}
                        />
                        <Route element={<ProtectedRoute />}>
                          <Route
                            path="/payment-complete/:payment_type/:id"
                            element={<PaymentPage />}
                          />
                          <Route
                            path="/book-lab-test"
                            element={<ServiceBooking />}
                          />
                          <Route
                            path="/book-lab-test/payment"
                            element={<ServicePayment />}
                          />
                          <Route
                            path="become-a-doctor"
                            element={<BecomeADoctor />}
                          />
                        </Route>

                        <Route path="*" element={<PageNotFound />} />
                        <Route
                          path="/book-doctor/payment"
                          element={<BookingPayment />}
                        />

                        <Route element={<ProtectedRoute />}>
                          <Route
                            path="/become-partner"
                            element={<BecomeAMember />}
                          />
                        </Route>
                        <Route element={<ProtectedRoute userRole={[0, 1]} />}>
                          <Route
                            path="/:slug/book-package"
                            element={<PackageBooking />}
                          />
                          <Route
                            path="/book-package/payment"
                            element={<PackagePayment />}
                          />
                        </Route>

                        <Route element={<StoreLayout />}>
                          <Route
                            path="/store/:vendorType/:category"
                            element={<Store />}
                          />
                          <Route path="/store" element={<StoreHome />} />
                          <Route path="/store/:brand" element={<Store />} />
                          <Route path="/cart" element={<CartPage />} />
                          <Route
                            path="/product/:slug"
                            element={<ProductPage />}
                          />
                          <Route
                            path="/vendor/:slug"
                            element={<VendorPage />}
                          />
                          <Route element={<ProtectedRoute />}>
                            <Route path="/wishlist" element={<WishList />} />
                            {/* <Route path="/orders" element={<OrderPage />} /> */}
                            <Route path="/checkout" element={<Checkout />} />
                            <Route
                              path="/order-details/:orderNum"
                              element={<OrderDetail />}
                            />
                            <Route
                              path="/cancel-order/:orderNum"
                              element={<CancelOrder />}
                            />
                          </Route>
                        </Route>

                        <Route element={<RestrictMiddleware />}>
                          <Route
                            path="/login"
                            element={
                              <Login
                                fcm={fcm}
                                FCMLoading={FCMLoading}
                                setFCM={setFCM}
                              />
                            }
                          />
                        </Route>
                        <Route path="/blog" element={<GDBlogProvider />}>
                          <Route index element={<GDBlogs />} />
                          <Route path="filter" element={<GDBlogFilter />} />
                          <Route
                            path=":blog_slug"
                            element={<GDBlogDetails />}
                          />
                          <Route path="menu/:id" element={<MenuBlogs />} />
                        </Route>
                      </Route>

                      {/* new store pages */}
                      <Route path="storetest" element={<GDStoreLayout />}>
                        <Route index element={<StoreHomeGD />} />
                        <Route
                          path="seller-store/:slug"
                          element={<GDVendors />}
                        />
                        <Route
                          path="seller-store/:slug/:filter"
                          element={<GDVendors />}
                        />
                        <Route path="gd-pharmacy" element={<GDPharmacy />} />
                      </Route>

                      <Route path="/gd-store" element={<GDStoreLayout />}>
                        <Route index element={<StoreHomeGD />} />
                        <Route
                          path="seller-store/:slug"
                          element={<GDVendors />}
                        />
                        <Route
                          path="fitness-center"
                          element={<FitnessHome />}
                        />
                        <Route path="cart" element={<CartPage />} />
                        <Route path="gd-cart" element={<CartPage />} />
                        <Route
                          path="filter/:vendorType/:category"
                          element={<Store />}
                        />
                        <Route path="filter/:brand" element={<Store />} />
                        <Route path="filter" element={<Store />} />
                        <Route
                          path="product/:slug"
                          element={<ProductDetPage />}
                        />
                        <Route
                          path="seller-store/:slug"
                          element={<GDVendors />}
                        />
                        <Route
                          path="seller-store/:slug/:filter"
                          element={<GDVendors />}
                        />
                        <Route path="gd-pharmacy" element={<GDPharmacy />} />
                        <Route element={<ProtectedRoute />}>
                          <Route path="chkout" element={<CheckoutPage />} />
                          <Route path="wishlist" element={<WishList />} />
                          <Route path="checkout" element={<Checkout />} />
                        </Route>
                      </Route>

                      {/* All components that uses Dashboard Layout are nested inside this route */}
                      <Route element={<ProtectedRoute />}>
                        <Route path="/user" element={<DashboardLayout />}>
                          <Route index element={<Dashboard />} />
                          <Route
                            path="family-design"
                            element={<FamilyDesign />}
                          />
                          <Route
                            path="consultation-history"
                            element={<ConsultationHistory />}
                          />
                          <Route
                            path="consultation-history/:id"
                            element={<ConsultationHistoryDetails />}
                          />
                          <Route
                            path="phone-verification"
                            element={<PhoneVerification />}
                          />
                          <Route
                            path="view-global-form"
                            element={<GlobalFormView />}
                          />
                          <Route
                            path="view-corporate-profile"
                            element={<CorporateProfile />}
                          />
                          <Route
                            path="your-fitness"
                            element={<UserFitnessPackages />}
                          />
                          <Route
                            path="insurance/details"
                            element={<InsuranceComponent />}
                          />
                          <Route
                            path="insurance/claim-settlement"
                            element={<ClaimSettlement />}
                          />
                          <Route
                            path="insurance/claim-settlement/:slug"
                            element={<ClaimSettlementDetails />}
                          />
                          <Route
                            path="calorie-intake"
                            element={<CalorieIntake />}
                          />
                          <Route
                            path="medical-history/add"
                            element={<AddMedical />}
                          />
                          <Route path="home" element={<Dashboard />} />
                          <Route path="g-map" element={<GMap />} />
                          <Route
                            path="appointments/doctor"
                            element={<MyAppointments />}
                          />
                          <Route path="orders" element={<OrderPage />} />
                          <Route
                            path="order-details/:orderNum"
                            element={<OrderDetail />}
                          />
                          <Route
                            path="cancel-order/:orderNum"
                            element={<CancelOrder />}
                          />
                          <Route path="family" element={<AddFamily />} />

                          <Route
                            path="family-and-package/payment"
                            element={<FamilyPackagePayment />}
                          />
                          <Route path="refer-friend" element={<ReferHome />} />
                          <Route
                            path="appointments/doctor/:slug"
                            element={<AppointmentDetail />}
                          />
                          <Route
                            path="appointments/doctor/share/:slug"
                            element={<ShareReport />}
                          />
                          <Route
                            path="mypackages"
                            element={<MyPackageDetail />}
                          />
                          <Route
                            path="change-password"
                            element={<ChangePassword />}
                          />
                          <Route
                            path="appointments/nurse"
                            element={<MyNurseAppointment />}
                          />
                          <Route
                            path="appointments/nurse/:slug"
                            element={<MyNurseAppointmentDetail />}
                          />
                          <Route path="kyc" element={<GlobalFormProvider />} />
                          <Route
                            path="notifications"
                            element={<Notification />}
                          />
                          <Route
                            path="notifications"
                            element={<Notification />}
                          />
                          <Route
                            path="view-report/:id/:name"
                            element={<ViewReport />}
                          />
                          <Route path="lab-report" element={<LabReport />} />
                          <Route
                            path="lab-report/:id"
                            element={<LabTestBookingDetail />}
                          />
                          <Route path="lab-reports">
                            <Route
                              path="pathology-screening"
                              element={<PathologyReport />}
                            />
                            <Route
                              path="labtest-bookings"
                              element={<LabTestBooking />}
                            />
                            <Route
                              path="medical-history"
                              element={<MedicalHistory />}
                            />
                            <Route
                              path="medical-history/:id"
                              element={<MedicalHistoryDetail />}
                            />
                          </Route>
                          <Route
                            path="payment-history"
                            element={<PaymentHistory />}
                          />
                          <Route
                            path="edit-corporate-profile"
                            element={<EditCorpProfile />}
                          />
                          <Route path="account" element={<AccountSetting />}>
                            <Route index element={<MyProfile />} />
                            <Route
                              path="security-setting"
                              element={<SecuritySetting />}
                            />
                          </Route>
                        </Route>
                      </Route>

                      <Route path="/parent" element={<ParentDashBoardLayout />}>
                        <Route index element={<ParentDashboard />} />
                        <Route path={"children"} element={<ChildrenList />} />
                        <Route
                          path="consultation-history-details/:id"
                          element={<ConsultationHistoryDetails />}
                        />
                        <Route
                          path={"consultation-history"}
                          element={<ParentConsultation />}
                        />

                        <Route
                          path={"medical-history"}
                          element={<MedicalDashboard />}
                        />
                        <Route path="vaccination" element={<Vaccination />} />
                        <Route
                          path="vaccination/add"
                          element={<AddVaccination />}
                        />
                        <Route
                          path="medical-history-detail"
                          element={<MedicalHistoryDetail />}
                        />
                        <Route
                          path="add-medical-history/:id"
                          element={<AddMedicalHistory />}
                        />

                        <Route
                          path="insurance-claim"
                          element={<InsuranceClaimParent />}
                        />
                      </Route>

                      <Route element={<ProtectedRoute userRole={[0, 1]} />}>
                        <Route path="/user" element={<DashboardLayout />}>
                          <Route path="profile" element={<Profile />} />
                          <Route
                            path="profile/edit"
                            element={<EditProfile />}
                          />
                          <Route
                            path="family/payment"
                            element={<FamilyPayment />}
                          />
                        </Route>
                      </Route>

                      <Route element={<ProtectedRoute userRole={[1]} />}>
                        <Route path="/school" element={<DashboardLayout />}>
                          <Route index element={<CorporateProfile />} />
                          <Route path="upload-csv" element={<CsvUpload />} />
                          <Route path="payment" element={<SchoolPayment />} />
                          <Route
                            path="student-lists"
                            element={<StudentLists />}
                          />
                          <Route
                            path="edit-corporate-profile"
                            element={<EditCorpProfile />}
                          />

                          <Route
                            path="school-package"
                            element={<SchoolPackage />}
                          />
                          <Route
                            path="edit-corporate-profile"
                            element={<EditSchoolProfile />}
                          />
                          <Route
                            path="deactivate/requests"
                            element={<StudentDeactivateRequest />}
                          />
                          <Route
                            path="deactivate/students"
                            element={<DeactivateStudents />}
                          />
                          <Route
                            path="insurance-claim"
                            element={<InsuranceClaimSchool />}
                          />
                        </Route>
                      </Route>

                      <Route path="chat-float" element={<GDChatbot />} />
                    </Routes>
                  </FormModalContext.Provider>
                </Suspense>
              </LoginMiddleware>
            </RecaptchaV3>
          </ThemeMUI>
        </HelmetProvider>
      </ScrollToTop>
    </ErrorBoundary>
  );
}

export default App;
