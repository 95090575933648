import {useQuery} from '@tanstack/react-query'
import React, {useEffect, useRef, useState} from 'react'
import axios from '../api'
import {Box, Card, CardContent, Stack, Typography} from '@mui/material'
import {LocationOnOutlined, WrongLocationOutlined} from '@mui/icons-material'

export function AutoCompleteInput({onPlaceChange, setValue, children}) {
    const [inputValue, setInputValue] = useState('')
    const [show, setShow] = useState(false)
    const inputRef = useRef(null)

    function handleChange(e) {
        setValue(e.target.value)
        if (e.target.value.length < 3) return setShow(false)
        setShow(true)
        setInputValue(e.target.value)
    }

    return (
        <Box
            position='relative'
        >
            {
                children(handleChange, inputRef)
            }
            {/* <input type="text" placeholder='Search Place' ref={inputRef} style={{ width: '100%' }} onChange={handleChange} /> */}
            <AutoCompleteResults inputRef={inputRef} closeList={() => setShow(false)} show={show}
                                 inputValue={inputValue} onPlaceChange={onPlaceChange}/>
        </Box>
    )
}

function AutoComplete({onPlaceChange, children}) {
    return (
        <Box className="container">
            <AutoCompleteInput>
                {
                    (handleChange, inputRef) => (
                        <input type="text" ref={inputRef} onChange={handleChange} placeholder='Search Place'/>
                    )
                }
            </AutoCompleteInput>
        </Box>
    )
}

function AutoCompleteResults({show, closeList, inputRef, inputValue, onPlaceChange}) {
    const [data, setData] = useState()
    const [isError, setError] = useState(false)
    const fetchPlace = (inputValue) => {
        axios.get(`https://api.geoapify.com/v1/geocode/autocomplete?text=${inputValue}&type=locality&filter=countrycode:np&apiKey=68725a46c50b4690bf4c6d41efde7963`)
            .then(res => setData(res.data)).catch(err => setError(true))
    }
    useEffect(() => {
        if (show) {
            fetchPlace(inputRef.current.value)
        }
    }, [show, inputValue])

    function handleClick(location) {
        return () => {
            onPlaceChange(location)
            closeList()
        }
    }

    if (isError) {
        return
    }
    return (
        <>
            {show && (
                <Box position='absolute' top={'105%'} left={0} width='100%' textAlign={'left'} zIndex='10'>
                    <Card sx={{border: 1, borderColor: 'divider'}}>
                        <Stack>
                            {
                                data?.features.length > 0
                                    ?
                                    data.features.map((item, idx) => (
                                        <Box p={1.3} display='flex' alignItems={'center'} gap={2}
                                             sx={{cursor: 'pointer', '&:hover': {bgcolor: '#F4F9FF'}}}
                                             onClick={handleClick(item)}>
                                            <LocationOnOutlined sx={{fontSize: '18px'}}/>
                                            <Typography lineHeight={1} variant='subtitle1'>
                                                {item.properties.formatted}
                                            </Typography>
                                        </Box>
                                    ))
                                    :
                                    <Box p={2} display='flex' alignItems={'center'} gap={1} sx={{cursor: 'pointer'}}>
                                        <WrongLocationOutlined sx={{fontSize: '18px'}}/>
                                        <Typography lineHeight={1} variant='subtitle1'>
                                            No Place Found
                                        </Typography>
                                    </Box>
                            }
                        </Stack>
                    </Card>
                </Box>
            )}
        </>
    )
}


export default AutoComplete
