import {Info} from "@mui/icons-material"
import {Tooltip} from "@mui/material"
import React, {useState} from "react"
import ModalRender from "./components/modal"

const InfoModal = ({modal, title}) => {
    const [openInfo, setOpenInfo] = useState(false)
    const handleClick = () => {
        setOpenInfo(true)
    }
    return (
        <>
            <Tooltip title={title} arrow onClick={handleClick}>
                <div className='ms-2' style={{cursor: 'pointer'}}>
                    <Info sx={{fontSize: '18px', color: 'Highlight'}}/>
                </div>
            </Tooltip>
            <ModalRender open={openInfo} handleClose={() => setOpenInfo(false)}>
                {modal}
            </ModalRender>
        </>
    )
}

export default InfoModal
