import {Modal} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React, {useState} from 'react'
import {useContext} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import axios from "../../../api"
import ReportModal from './ReportModal'

function AppointmentFiles({booking_id}) {
    const loginContext = useContext(LoginStateContext)
    const [modalShow, setModalShow] = useState(false)
    const {isLoading, data} = useQuery(
        ['appointment_file', booking_id],
        async () => {
            return axios.get("api/admin/user-profile/medical-report?booking_id=" + booking_id, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => {
                    return res.data
                }).catch(err => {
                    console.log()
                })
        }
    )

    if (isLoading) {
        return (
            <h4>Getting Files....</h4>
        )
    }

    // console.log()

    if (data.length === 0) {
        return (
            <div className="alert alert-warning">
                No Medical Reports.
            </div>
        )
    }
    if (data[0].image_path == null) {
        return (
            <div className="alert alert-warning">
                No Medical Reports.
            </div>
        )
    }

    return (
        <div className="card related-docs">
            <h4><b>Related Files/Documents:</b></h4>


            <div className="document-list">
                <table className="table">
                    <tbody>
                    <tr>
                        <td>
                                <span className="material-symbols-outlined">
                                    diagnosis
                                </span>
                        </td>
                        <td className='file-name-col'>
                            <div className="file-name">
                                {data[0].image}
                            </div>

                        </td>
                        <td>
                            <div className="btn d-flex align-items-center justify-content-center">
                                    <span className="material-symbols-outlined ms-3" onClick={() => {
                                        setModalShow(true)
                                    }}>
                                        visibility
                                    </span>
                                <span className='ms-2'>View Document</span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <ReportModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                pdfLink={data[0].image_path}
            />
        </div>
    )
}

export default AppointmentFiles


