import React, { useContext, useState } from "react";
import GDLogo from "./GDLogo";
import Logo from "../../../../images/blue-logo.png";
import SearchBar from "./SearchBar";
import "./Header.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { LoginStateContext } from "../../../../contexts/LoginStateContext";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  Card,
  CardMedia,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { GDStoreContext } from "../../../../contexts/GDStoreContext";
import swal from "sweetalert";
import {
  DashboardOutlined,
  FavoriteBorderOutlined,
  KeyboardArrowDownOutlined,
  LockOutlined,
  LoginOutlined,
  ManageAccountsOutlined,
  MenuOutlined,
  PowerSettingsNew,
  ShoppingBagOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import { useRef } from "react";
import { useCart } from "../../../../contexts/provider/GDCartProvider";
import { useWebNav } from "../../WebLayoutProvider";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { useChkLogin } from "../../../../middlewares/LoginMiddleware";
import { CircularProgress } from "@mui/material";
import {
  faArrowRightFromBracket,
  faBagShopping,
  faHouse,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  CallOutlined,
  MailOutline,
  DirectionsOutlined,
} from "@mui/icons-material";

function VendorCategory({ vendor_type }) {
  const [category, setCategory] = React.useState([]);
  const loginContext = useContext(LoginStateContext);

  React.useEffect(() => {
    axios
      .get("api/categories/vendortype?vendor_type_id=" + vendor_type)
      .then((res) => {
        setCategory(res.data);
      });
  }, []);

  if (category.length == 0) {
    return;
  }

  return (
    <div className="sub-header-inner" id={"inner-item-" + vendor_type}>
      <div className="sub-header-inner-item-wrap">
        <div className="container">
          <div className="py-4">
            <div className="sub-header-inner-item">
              <ul>
                {category.map((cat) => {
                  return (
                    <li>
                      <Link
                        to={`/gd-store/filter/${vendor_type}/${cat.id}`}
                        className="text-decoration-none text-dark"
                      >
                        {cat.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Header() {
  const [vendorType, setVendorType] = React.useState([]);
  const loginContext = useContext(LoginStateContext);
  const storeContext = useContext(GDStoreContext);
  const location = useLocation();
  const gdCart = useCart();
  const mobileScreen = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const loginRef = useRef(null);
  const [open, setOpen] = useState(false);

  // const { navRef } = useWebNav();
  const [scrolled, setScrolled] = useState(false);
  const [toggle, setToggle] = useState(false);
  const pathname = window.location.pathname;
  const [mainService, setMainService] = React.useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  React.useEffect(() => {
    axios.get("api/vendor-type").then((res) => {
      setVendorType(res.data);
    });

    if (loginContext.state.loggedIn) {
      axios
        .get("api/admin/wishlist", {
          headers: { Authorization: `Bearer ${loginContext.state.token}` },
        })
        .then((res) => {
          storeContext.setWishlist(res.data);
        });
    }
  }, []);

  React.useEffect(() => {
    let links = document.getElementsByClassName("sub-header-item-link");
    let subHeadCover = document.getElementsByClassName("sub-header-cover")[0];

    function removeActive(elem) {
      console.log();
      var subHeaderWrapper = document.getElementsByClassName(
        "sub-header-inner-wrapper"
      )[0];
      subHeaderWrapper.classList.remove("active");
      for (var i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
    }

    function linkClickListen(e) {
      var target = this.getAttribute("data-target");
      var elem = document.querySelector(`${target}`);
      if (elem === null) {
        console.log();
        return;
      }
      var subHeaderWrapper = document.getElementsByClassName(
        "sub-header-inner-wrapper"
      )[0];
      subHeaderWrapper.classList.add("active");

      let hasActive = this.classList.contains("active");

      if (this.classList.contains("active")) {
        subHeaderWrapper.classList.remove("active");
      }

      var innerHeaders = document.getElementsByClassName("sub-header-inner");
      var headerLinks = document.getElementsByClassName("sub-header-item-link");
      for (let i = 0; i < headerLinks.length; i++) {
        headerLinks[i].classList.remove("active");
      }
      for (let i = 0; i < innerHeaders.length; i++) {
        innerHeaders[i].classList.remove("active");
      }

      elem.classList.add("active");
      if (!hasActive) {
        this.classList.add("active");
      }
    }

    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener("click", linkClickListen);
    }

    subHeadCover.addEventListener("click", removeActive);

    removeActive();

    return () => {
      for (let i = 0; i < links.length; i++) {
        links[i].removeEventListener("click", linkClickListen);
      }
    };
  }, [vendorType, location]);

  React.useEffect(() => {
    axios.get("api/service").then((res) => {
      setMainService(res.data);
    });
  }, []);

  const logout = () => {
    localStorage.clear();
    swal({
      title: "Logged out successfully!",
      icon: "success",
    }).then((value) => {
      if (value) {
        // console.log()
        // navigate("/",{ replace: true });
        loginContext.logout();
      }
    });
  };

  function handleClose(e) {
    if (loginRef.current && loginRef.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  }

  function handleToggle() {
    setOpen((prev) => !prev);
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  return (
    <div>
      <Box py={1} bgcolor="#0259A7" color="#fff">
        <div className="container-lg">
          <Box display="flex" gap={{ xs: 1, lg: 5 }} alignItems="center">
            <Box display="flex" gap={2} alignItems="center">
              <CallOutlined sx={{ fontSize: "1.1rem" }} />
              <span>01-5917322</span>
            </Box>
            <Box
              display={{ xs: "none", sm: "flex" }}
              gap={1}
              alignItems="center"
            >
              <MailOutline sx={{ fontSize: "1.1rem" }} />
              <span>info@ghargharmadoctor.com</span>
            </Box>
            <Box
              display={{ xs: "none", lg: "flex" }}
              gap={1}
              alignItems="center"
            >
              <span>Opening time:</span>
              <span>9:30AM to 5:30PM</span>
            </Box>
            <Box ml="auto">
              <Box
                component="button"
                px={3}
                border={0}
                borderRadius={2}
                display="flex"
                alignItems="center"
                gap={1}
                color="#0259A7"
                fontWeight={700}
                onClick={() => {
                  window.open(
                    "https://maps.google.com/maps?ll=27.696597,85.353972&z=16&t=m&hl=en&gl=NP&mapclient=embed&cid=851041201172928665",
                    "_blank"
                  );
                }}
              >
                <DirectionsOutlined sx={{ fontSize: "1.1rem" }} />
                <span>Get directions</span>
              </Box>
            </Box>
          </Box>
        </div>
      </Box>

      <div className="sticky-top">
        <div className="navigation bg-white">
          <nav className="navbar navbar-expand-lg container py-0 px-sm-3">
            <a className="navbar-brand py-0" href="/">
              <motion.img
                src={Logo}
                className="logo my-1"
                alt=""
                animate={{
                  height: scrolled ? "3.4rem" : "4rem",
                }}
                transition={{ duration: 1 }}
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => setToggle(!toggle)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            {toggle === true && (
              <div className=" navbar-collapse px-4">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li
                    className={
                      "nav-item me-3 " + (pathname === "/" ? "active" : "")
                    }
                  >
                    <Link
                      to="/"
                      className="nav-link"
                      onClick={() => setToggle(!toggle)}
                    >
                      {" "}
                      Home{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      "nav-item me-3 " +
                      (pathname === "/packages" ? "active" : "")
                    }
                  >
                    <Link
                      to="/packages"
                      className="nav-link"
                      onClick={() => setToggle(!toggle)}
                    >
                      Packages
                    </Link>
                  </li>

                  <li
                    className={
                      "nav-item me-3 " +
                      (pathname === "/gd-store" ? "active" : "")
                    }
                  >
                    <Link
                      to="/gd-store"
                      className="nav-link"
                      onClick={() => setToggle(!toggle)}
                    >
                      GD Store
                    </Link>
                  </li>
                  <li
                    className={
                      "nav-item me-3 dropdown " +
                      (pathname === "/service" ? "active" : "")
                    }
                  >
                    <a
                      className="nav-link dropdown-toggle "
                      href="/"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Services
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      {mainService.map((serv, index) => {
                        return (
                          <li key={index}>
                            <Link
                              className="dropdown-item"
                              to={`/service/${serv.slug}`}
                              onClick={() => setToggle(!toggle)}
                            >
                              {serv.service_title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  <li
                    className={
                      "nav-item me-3 " + (pathname === "/blog" ? "active" : "")
                    }
                  >
                    <Link
                      to="/blog"
                      className="nav-link"
                      onClick={() => setToggle(!toggle)}
                    >
                      Blog
                    </Link>
                  </li>

                  <li
                    className={
                      "nav-item me-3 " + (pathname === "/about" ? "active" : "")
                    }
                  >
                    <Link
                      to="/about"
                      className="nav-link"
                      onClick={() => setToggle(!toggle)}
                    >
                      About Us
                    </Link>
                  </li>

                  {!loginContext.state.loggedIn ? (
                    <li
                      className={
                        "nav-item me-1 " +
                        (pathname === "/career" ? "active" : "")
                      }
                    >
                      <Link to="/career" className="nav-link career-btn">
                        Career Opportunity
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {!loginContext.state.loggedIn ? (
                    <li
                      className={
                        "nav-item me-1 " +
                        (pathname === "/contact" ? "active" : "")
                      }
                    >
                      <Link to="/contact" className="nav-link enquiry-btn">
                        Enquiry
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  <li className="nav-item me-3 login-border"></li>
                  <AuthNavItem />
                </ul>
              </div>
            )}
            {toggle === false && (
              <div
                className="collapse navbar-collapse "
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li
                    className={
                      "nav-item me-1 " + (pathname === "/" ? "active" : "")
                    }
                  >
                    <Link to="/" className="nav-link">
                      {" "}
                      Home{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      "nav-item me-1 " +
                      (pathname === "/packages" ? "active" : "")
                    }
                  >
                    <Link to="/packages" className="nav-link">
                      Packages
                    </Link>
                  </li>
                  <li
                    className={
                      "nav-item me-1 " +
                      (pathname === "/gd-store" ? "active" : "")
                    }
                  >
                    <Link to="/gd-store" className="nav-link">
                      GD Store
                    </Link>
                  </li>
                  <li
                    className={
                      "nav-item me-1 dropdown " +
                      (pathname === "/service" ? "active" : "")
                    }
                  >
                    <a
                      className="nav-link dropdown-toggle "
                      href="/"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Services
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      {mainService.map((serv, index) => {
                        return (
                          <li key={index}>
                            <Link
                              className="dropdown-item"
                              to={`/service/${serv.slug}`}
                            >
                              {serv.service_title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  <li
                    className={
                      "nav-item me-1 " + (pathname === "/blog" ? "active" : "")
                    }
                  >
                    <Link to="/blog" className="nav-link">
                      Blog
                    </Link>
                  </li>

                  <li
                    className={
                      "nav-item me-1 " + (pathname === "/about" ? "active" : "")
                    }
                  >
                    <Link to="/about" className="nav-link">
                      About Us
                    </Link>
                  </li>

                  {!loginContext.state.loggedIn ? (
                    <li
                      className={
                        "nav-item me-1 " +
                        (pathname === "/career" ? "active" : "")
                      }
                    >
                      <Link to="/career" className="nav-link career-btn">
                        Career Opportunity
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {!loginContext.state.loggedIn ? (
                    <li
                      className={
                        "nav-item me-1 " +
                        (pathname === "/contact" ? "active" : "")
                      }
                    >
                      <Link to="/contact" className="nav-link enquiry-btn">
                        Enquiry
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {!loginContext.state.loading &&
                  loginContext.state.loggedIn ? (
                    loginContext.state.data.school_profile === null && (
                      <>
                        {loginContext.state.data?.member?.roles.length >= 2 ? (
                          <li className={"nav-item me-3 "}>
                            <a
                              target="_blank"
                              href={`${baseUrl}/login`}
                              className="nav-link btn btn-outline-primary p-0 px-4 py-2"
                            >
                              {" "}
                              Partner Dashboard
                            </a>
                          </li>
                        ) : (
                          <li
                            className={
                              "nav-item me-3 " +
                              (pathname === "/become-partner" ? "active" : "")
                            }
                          ></li>
                        )}
                      </>
                    )
                  ) : (
                    <li
                      className={
                        "nav-item me-3 " +
                        (pathname === "/become-partner" ? "active" : "")
                      }
                    ></li>
                  )}
                  <li className="nav-item me-3 login-border"></li>
                  <AuthNavItem />
                </ul>
              </div>
            )}
          </nav>
        </div>
        {/* <div className="justify-self-end col-10 col-lg-4 order-lg-1">
            <div className="header-items d-flex align-items-center justify-content-end">
              <Badge badgeContent={gdCart.getCartCount()} color="primary">
                <Link
                  to="cart"
                  className="header-item d-flex flex-column justify-content-center align-items-center text-decoration-none"
                >
                  <ShoppingCartOutlined
                    sx={{ color: "black", fontSize: "2rem" }}
                  />
                  <span className="text-muted">Cart</span>
                </Link>
              </Badge>

              <Badge
                badgeContent={storeContext.wishlist.length}
                color="secondary"
              >
                <Link
                  to="wishlist"
                  className="header-item d-flex flex-column justify-content-center align-items-center text-decoration-none"
                >
                  <FavoriteBorderOutlined
                    sx={{ color: "black", fontSize: "2rem" }}
                  />
                  <span className="text-muted">Wishlist</span>
                </Link>
              </Badge>

              <Link
                to="/user/orders"
                className="header-item d-flex flex-column justify-content-center align-items-center text-decoration-none"
              >
                <ShoppingBagOutlined
                  sx={{ color: "black", fontSize: "2rem" }}
                />
                <span className="text-muted">Orders</span>
              </Link>

              {loginContext.state.loading ? (
                <span>Checking...</span>
              ) : (
                <>
                  {!loginContext.state.loggedIn ? (
                    <Link
                      to="/login"
                      className="header-item d-flex flex-column justify-content-center align-items-center text-decoration-none text-dark algin-self-end"
                    >
                      <LoginOutlined
                        sx={{ color: "black", fontSize: "2rem" }}
                      />
                      <span className="text-muted">Login</span>
                    </Link>
                  ) : (
                    <>
                      <div className="header-item d-flex flex-column justify-content-center text-decoration-none text-dark">
                        <div>
                          <Avatar
                            src={loginContext.state.data.image_path}
                            sx={{
                              width: "2rem",
                              height: "2rem",
                              marginLeft: ".4rem",
                            }}
                          />
                        </div>
                        <div
                          className="d-flex align-items-center"
                          ref={loginRef}
                          onClick={handleToggle}
                        >
                          <span className="text-muted">
                            {loginContext.state.data.member.name}
                          </span>
                          <KeyboardArrowDownOutlined />
                        </div>
                      </div>
                      <Popper
                        open={open}
                        anchorEl={loginRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                        sx={{ zIndex: "99" }}
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom-start"
                                  ? "left top"
                                  : "left bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={open}
                                  id="composition-menu"
                                  aria-labelledby="composition-button"
                                  onKeyDown={handleListKeyDown}
                                >
                                  <MenuItem
                                    onClick={(e) => {
                                      handleClose(e);
                                      navigate("/user/profile/edit");
                                    }}
                                  >
                                    <ListItemIcon>
                                      <ManageAccountsOutlined fontSize="small" />
                                    </ListItemIcon>
                                    Edit Profile
                                  </MenuItem>
                                  <MenuItem
                                    onClick={(e) => {
                                      handleClose(e);
                                      navigate("/user/");
                                    }}
                                  >
                                    <ListItemIcon>
                                      <DashboardOutlined fontSize="small" />
                                    </ListItemIcon>
                                    My Dashboard
                                  </MenuItem>
                                  <MenuItem
                                    onClick={(e) => {
                                      handleClose(e);
                                      logout();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <PowerSettingsNew fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6 order-lg-6">
            <SearchBar />
          </div> */}
      </div>

      <div className="sub-header-container">
        <div className="container main-sub-header">
          <div className="sub-header-wrapper">
            <div className="sub-header">
              {/* Sub menu items */}
              {/* <div className="sub-header-item">
                <Link to="/gd-store" className="text-decoration-none text-dark">
                  Store Home
                </Link>
              </div> */}
              {/* {vendorType.map((menu) =>
                menu.vendor_type === "Fitness Center" ? (
                  <></>
                ) : (
                  <div className="sub-header-item">
                    <div
                      className="sub-header-item-link"
                      data-target={"#inner-item-" + menu.id}
                    >
                      <span className="text-decoration-none text-dark">
                        {menu.vendor_type}
                      </span>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        size="sm"
                        className="text-muted ms-2"
                      />
                    </div>
                  </div>
                )
              )} */}
              {/* <div className="sub-header-item">
                <Link
                  to="/gd-store/fitness-center"
                  className="text-decoration-none text-dark"
                >
                  Fitness Center
                </Link>
              </div> */}
            </div>
          </div>
        </div>

        <div className="sub-header-inner-wrapper">
          {vendorType.map((typ) => {
            if (typ.vendor_type === "Fitness Center") return;
            return <VendorCategory vendor_type={typ.id} />;
          })}
          <div className="sub-header-cover"></div>
        </div>
      </div>
    </div>
  );

  function AuthNavItem() {
    const { isLoading, data, token, isLoggedIn, logout } = useChkLogin();
    const navigate = useNavigate();

    if (isLoading) {
      return <CircularProgress size={"2rem"} />;
    }
    return (
      <>
        {!isLoggedIn ? (
          <>
            <li className="nav-item me-3 d-flex nav-login">
              <Link to="/login" className="align-self-center">
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ color: "#b4b4b4" }}
                  className="me-2"
                />
                Login
              </Link>
            </li>
          </>
        ) : (
          <li className="nav-item dropdown  me-3">
            <a
              className="nav-link dropdown-toggle me-0 px-0"
              href="/"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <FontAwesomeIcon
                icon={faUser}
                style={{ color: "#b4b4b4" }}
                className="me-2"
              />
              {data.member.name}
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <Link
                  to={`${
                    data.school_profile == null ||
                    data.school_profile.types === "corporate"
                      ? "/user"
                      : "/school"
                  }`}
                  className="dropdown-item"
                >
                  <FontAwesomeIcon icon={faHouse} className="me-2" />
                  My Dashboard
                </Link>
              </li>
              <li>
                {/* <Link to="/orders" className="dropdown-item">
                          My Orders
                        </Link> */}
                <button
                  className="dropdown-item"
                  onClick={() => navigate("user/orders")}
                >
                  <FontAwesomeIcon icon={faBagShopping} className="me-2" />
                  My Orders
                </button>
              </li>
              <li>
                <button className="dropdown-item" onClick={logout}>
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    className="me-2"
                  />
                  Logout
                </button>
              </li>
            </ul>
          </li>
        )}
      </>
    );
  }

  // return (
  //     <Box className='container' py={0.3} p={0} sx={{position:'sticky', top:-5, zIndex:99, bgcolor:'white'}}>
  //         <Grid container alignItems={'center'} justifyContent='space-between' spacing={1}>
  //             <Grid item xs={1.4}>
  //                 <IconButton>
  //                     <MenuOutlined fontSize='large'/>
  //                 </IconButton>
  //             </Grid>
  //             <Grid item xs={1.4} sm={1} md={0.5}>
  //                 <Card sx={{ width: '100%' }} elevation={0}>
  //                     <CardMedia component='img' image={Logo} />
  //                 </Card>
  //             </Grid>
  //             <Grid item xs={6}>
  //                 <SearchBar />
  //             </Grid>
  //             <Grid item xs={2.5}>
  //                 <Stack direction={'row'} alignItems='center' sx={{ width: '100%', justifyContent: 'space-between' }}>
  //                     {/* <IconButton>
  //                         <ShoppingBagOutlined fontSize='large' fontColor='text.primary' />
  //                     </IconButton>
  //                     <IconButton>
  //                         <FavoriteBorderOutlined fontSize='large' />
  //                     </IconButton>

  //                     <IconButton>
  //                         <Badge badgeContent={gdCart.getCartCount()} color="primary">
  //                             <ShoppingCartOutlined fontSize='large' />
  //                         </Badge>
  //                     </IconButton> */}

  //                     <Button endIcon={<KeyboardArrowDownOutlined />}>
  //                         <Avatar src='https://www.w3schools.com/howto/img_avatar.png' />
  //                     </Button>
  //                 </Stack>
  //             </Grid>
  //         </Grid>
  //     </Box>
  // )
}

export default Header;
