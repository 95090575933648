import {ManageAccountsOutlined} from '@mui/icons-material'
import {Avatar, Box, Button, CircularProgress, Typography} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import axios from '../../../../api'
import BlankProfile from "../../../../../images/blankProfile.webp"

function ProfileProgress({pg}) {
    // console.log('package' ,pg)
    // return;
    const loginContext = useContext(LoginStateContext)
    const user = loginContext.state.data
    const navigate = useNavigate()
    const [progress, setProgress] = useState(0)
    const [progressSteps, setProgressSteps] = useState({
        profile_updated: false,
        global_form_updated: false,
        global_form_verifying: false,
        global_form_verified: false
    })

    const kycStatus = useQuery(
        ['kyc_status'],
        async () => {
            return axios.get('/api/kycstatus', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data).catch(err => err)
        }
    )

    const updateProgress = () => {
        Object.keys(progressSteps).forEach((item, idx) => {
            let res = progressSteps[item]
            switch (item) {
                case 'profile_updated':
                    if (res) setProgress(prev => (prev + 30))
                    return
                case 'global_form_updated':
                    if (res) setProgress(prev => (prev + 30))
                    return
                case 'global_form_verifying':
                    if (res) setProgress(prev => (prev + 20))
                    return
                case 'global_form_verified':
                    if (res) setProgress(prev => (prev + 20))
                    return
            }
        })
    }

    useEffect(() => {
        updateProgress()
    }, [progressSteps])

    useEffect(() => {
        if (user.dob !== null) {
            setProgressSteps(prev => ({
                ...prev, profile_updated: true
            }))
        }
    }, [])

    useEffect(() => {
        if (!kycStatus.isLoading) {
            let globalStatus = kycStatus.data
            // console.log()
            setProgress(0)
            switch (globalStatus.message) {
                case 'kyc form not submitted.':
                    return
                case 'kyc not verified.':
                    return setProgressSteps(prev => ({...prev, global_form_updated: true, global_form_verifying: true}))
                case 'kyc rejected.':
                    return setProgressSteps(prev => ({
                        ...prev,
                        global_form_updated: false,
                        global_form_verifying: false
                    }))
                case 'kyc verified.':
                    return setProgressSteps(prev => ({
                        ...prev,
                        global_form_updated: true,
                        global_form_verifying: true,
                        global_form_verified: true
                    }))
            }
        }
    }, [kycStatus.data, kycStatus.isLoading])


    function ProgressButton({progressStep}) {
        let buttonlink = null;
        let btn = null
        let buttontxt = null;
        // console.log()
        if (!progressStep.profile_updated) {
            btn = (
                <Button variant='contained' startIcon={<ManageAccountsOutlined/>}
                        onClick={() => navigate('/user/profile/edit')}>
                    Update Profile
                </Button>
            )
            buttonlink = '/user/profile/edit'
            buttontxt = 'Update Profile'
        } else if (!progressStep.global_form_updated && pg !== null && pg?.package_status === 'Pending') {
            btn = (
                <Button variant='contained' color='warning' startIcon={<ManageAccountsOutlined/>}
                        onClick={() => navigate('/user/kyc')}>
                    Update Global Form
                </Button>
            )
            buttonlink = '/user/profile/edit'
            buttontxt = 'Update Global Form'
        } else if (!progressStep.global_form_verified && pg !== null && pg.package_status === 'Pending') {
            btn = (
                <Button variant='contained' color='info' startIcon={<ManageAccountsOutlined/>}>
                    Global Form Verifying
                </Button>
            )
        } else if (progressStep.global_form_verified && pg !== null && pg.package_status === 'Pending') {
            btn = (
                <Button color='success' startIcon={<ManageAccountsOutlined/>}
                        onClick={() => navigate('/user/view-global-form')}>
                    View Global Form
                </Button>
            )
            buttonlink = '/user/profile/edit'
            buttontxt = 'Global Form Verified'
        }
        return btn
    }


    return (
        <Box>
            <ProgressButton progressStep={progressSteps}/>
            <Box position='relative' width='10rem' height='10rem'>
                <Box position='absolute' left={0} top={0} width='10rem' height='10rem'>
                    <Box position='absolute' width='8.2rem' height='8.2rem' top={0} bottom={0} left={0} right={0}
                         sx={{margin: 'auto'}}>
                        <Avatar src={user.image_path || BlankProfile} sx={{width: '100%', height: '100%'}}/>
                        <Box position='absolute' width='100%' height='100%' bgcolor={'black'} left={0} top={0}
                             borderRadius='50%' sx={{opacity: 0.4}}></Box>
                    </Box>
                    <Box position='absolute' top={0} left={0} right={0}>
                        <CircularProgress variant="determinate" value={progress} size='10rem'
                                          color={progress == 100 ? 'primary' : 'warning'}/>
                    </Box>
                    <Box position={'absolute'} width='fit-content' height='fit-content' top={0} bottom={0} left={0}
                         right={0} sx={{margin: 'auto'}}>
                        {
                            progress != '100' &&
                            <Typography textAlign={'center'} variant='subtitle1'
                                        sx={{fontSize: '40px !important', fontWeight: '800 !important'}}
                                        color='#29B6F6'>{progress}%</Typography>
                        }
                    </Box>
                </Box>
                {/* <Box width='fit-content' height='fit-content' position='absolute' top={0} left={0} right={0} margin='auto'>
                    <Button variant='contained' color='primary'>Update Profile</Button>
                </Box> */}
            </Box>
        </Box>
    )
}


export default ProfileProgress
