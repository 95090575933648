import {Box, Checkbox, FormControl, FormControlLabel, Grid, Stack, Typography} from '@mui/material'
import React from 'react'
import {Card} from 'react-bootstrap'

function FilterItem({filterTitle, children}) {
    return (
        <Box py={2} borderBottom={1} borderColor='divider'>
            <Typography variant='h6' fontWeight={450}>
                {filterTitle}
            </Typography>
            <Box mt={1}>
                {children}
            </Box>
        </Box>
    )
}

function FilterTextLabel({children, selected, onClick}) {
    const selectedSx = {
        color: selected ? '#0D59A8' : 'text.primary',
        fontWeight: selected && 500
    }
    return (
        <Typography variant='subtitle1' sx={{cursor: 'pointer', ...selectedSx}} onClick={onClick}>
            {children}
        </Typography>
    )
}

function FilterItemCheck({label, onChange, checked}) {
    return (
        <FormControl fullWidth>
            <FormControlLabel control={<Checkbox onClick={onChange} checked={checked}/>} label={label}/>
        </FormControl>

        // <Stack direction={'row'} justifyContent='space-between' alignItems={'center'}>
        //     <FilterTextLabel>{children}</FilterTextLabel>
        //     <Checkbox/>
        // </Stack>
    )
}

function FilterTagItem({children}) {
    return (
        <Box bgcolor="#ECECEC" mb={0.5} mr={0.5}
             sx={{borderRadius: 10, border: 1, borderColor: 'divider', display: 'inline-block', px: 2, py: 0.5}}>
            <FilterTextLabel>{children}</FilterTextLabel>
        </Box>
    )
}

export {FilterItem, FilterTextLabel, FilterItemCheck, FilterTagItem}
