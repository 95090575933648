import {ShoppingBasketOutlined} from '@mui/icons-material'
import {Box, Button, Card, CardContent, CardHeader, Grid, Stack, Typography} from '@mui/material'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useCart} from '../../../contexts/provider/GDCartProvider'
import CartItem from './components/CartItem'
import CartSummary from './components/CartSummary'

function CartPage() {
    const gdCart = useCart()
    const navigate = useNavigate()

    if (gdCart.cart.length === 0) {
        return (
            <Box my={2} sx={{
                width: '100%', height: '100%', '& img': {
                    width: '100%',
                    maxWidth: '600px',
                    margin: 'auto'
                }
            }}>
                <Stack>
                    <img
                        src='https://media.istockphoto.com/id/1139666909/vector/shopping-cart-shop-trolley-or-basket-in-the-supermarket.jpg?s=612x612&w=0&k=20&c=_HajO7ifYKxuwzKFf-Fx9lsLKBa_1Rq9vuzGiPq8Q5Q='/>
                    <Typography variant='h3' sx={{margin: 'auto', mb: 2,}}>Your Cart is Empty</Typography>
                    <Button sx={{width: '100%', maxWidth: '250px', margin: 'auto'}} variant='contained'
                            startIcon={<ShoppingBasketOutlined/>} onClick={() => navigate("/gd-store")}>
                        Continue Shopping
                    </Button>
                </Stack>
            </Box>
        )
    }
    return (
        <Box className='container' py={2}>
            <Grid container>
                <Grid item md={8} px={1}>
                    <Box mt={2}>
                        {
                            gdCart.cart.map((item, idx) => (
                                <CartItem key={idx} item={item}/>
                            ))
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} px={1}>
                    <CartSummary data={gdCart.cart}/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CartPage
