import http from "../utils/http/http.utils";

class SchoolService {
    static async getStudents(params) {
        try {
            const res = await http().get('api/company-school-profile/student-details', {params})
            return res?.data
        } catch (e) {
            console.log(e)
        }
    }


    static async getClasses() {
        try {
            const response = await http().get('/api/company-school-profile/lists/classes')
            return await response?.data?.data
        } catch (e) {
            console.log(e)
        }
    }

    static async getYears() {
        try {
            const response = await http().get('/api/company-school-profile/lists/years')
            return await response?.data?.data
        } catch (e) {
            console.log(e)
        }
    }

    static async getSections() {
        try {
            const response = await http().get('/api/company-school-profile/lists/sections')
            return await response?.data?.data
        } catch (e) {
            console.log(e)
        }
    }

    static async deactivate(params) {
        const {reason, data, id} = params
        try {
            return await http()
                .post(
                    `api/company-school-profile/deactivate-users?id=${data}`,
                    {
                        profile_id: id,
                        deactivate_reason: reason,
                    },
                )
        } catch (e) {
            console.log(e)
            throw e
        }
    }
}

export default SchoolService
