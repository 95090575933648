import {GoogleMap, LoadScript} from '@react-google-maps/api'
import React from 'react'

function GMap() {
    return (
        <MapInput/>
    )
}

function MapInput() {
    return (
        <>
            <h4>Google Map</h4>
            <GoogleMap
                mapContainerStyle={{width: "400px", height: "400px"}}
                center={{lat: -3.745, lng: -38.523}}
                zoom={10}
            />
        </>
    )
}

export default GMap
