import React, {useState} from "react";
import {addCommasToNumber} from "../../../../../utils/common/common.utils";
import {CHEQUE, ONLINE_TRANSFER} from "../../../../../utils/constant/payment";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {Chip} from "@mui/material";
import classNames from "classnames";
import dayjs from "dayjs";
import {DATEFORMAT} from "../../../../../utils/constant/common";

const TransactionInfo = ({label, transactionId}) => {
    return (
        <div className="d-flex gap-1">
            <label>{label}</label> :
            <b style={{textTransform: "capitalize", fontSize: 13}}>{transactionId}</b>
        </div>
    );
};


const PaymentItem = ({item, index}) => {
    const {payment_type, status, cheque_amount, amount_transferred} = item;

    const amount = addCommasToNumber(payment_type === CHEQUE ? cheque_amount : amount_transferred)

    return (
        <div style={{gridTemplateColumns: "repeat(4, 1fr)"}}
             className={classNames("d-grid gap-5 align-items-center py-2", index > 0 && "border-top")}>
            <TransactionInfo label="Payment Type" transactionId={"Online Transfer"}/>
            <TransactionInfo label={payment_type === CHEQUE ? "Cheque Amount" : "Amount Transferred"} transactionId={`NRS. ${amount}`}/>
            <TransactionInfo label="Status" transactionId={status}/>
        </div>
    );

};

const StatusBadge = ({status}) => {
    switch (status) {
        case 'rejected':
            return <span style={{padding: "3px 5px"}}
                         className="badge badge-danger">Rejected</span>;
        case 'approved':
            return <span style={{padding: "3px 5px"}}
                         className="badge badge-success">Approved</span>;
        case 'completed':
            return <span style={{padding: "3px 5px"}}
                         className="badge badge-success">Completed</span>;
        default:
            return <span style={{padding: "3px 5px"}}
                         className="badge badge-warning">Pending</span>;
    }
};

const PaymentHistoryItem = (props) => {
    const {paymentItem} = props
    const {payment_details, payment_date} = paymentItem
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <Accordion style={{position: "relative", borderRadius: 6}}
                   className="bg-white shadow-sm  fs-7 lh-sm">
            <AccordionSummary
                onClick={() => setIsExpanded(!isExpanded)}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <div className="d-flex flex-column gap-1 " style={{width: "100%"}}>
                    <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center gap-2">
                            <div className="fs-6">Nrs.<b>{addCommasToNumber(paymentItem?.total_paid_amount)}</b></div>
                            <StatusBadge status={paymentItem?.status}/>
                        </div>

                        <Chip size="small" style={{marginLeft: "auto"}} label={isExpanded ? 'Show Less' : 'Show More'}/>
                    </div>
                    <p className="text-sm text-black-50">
                        {dayjs(payment_date).format(DATEFORMAT)}
                    </p>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className="d-grid" style={{gap: "12px"}}>
                    <b>Payment Details</b>
                    <div>
                        {payment_details?.length ? payment_details?.map((item, index) => <PaymentItem key={index} index={index}
                                                                                                      item={item}/>) : <span style={{opacity: 0.5}}>No payment details</span>}
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

export default PaymentHistoryItem
