import React, { useEffect, useState } from "react";
import { useChkLogin } from "../../../middlewares/LoginMiddleware";
import Loader from "../Loader";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import UploadFile from "../../custom_componets/UploadFile";
import {
  InputField,
  NepaliDateInput,
} from "../../custom_componets/FormComponents";
import axios from "../../api";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { submitCompanyProfile } from "./companyprofileservice";
import { getCompanyProfile } from "./companyprofileservice";
import { Modal, Typography, Button } from "@mui/material";
import AddressModal from "../schoolProfileForm/AddressModal";
import LeafletMap from "../../leafletMap/LeafletMap";

function CompanyProfileForm({
  state = {
    owner_name: "",
    company_name: "",
    company_address: "",
    company_start_date: "",
    contact_number: "",
    landline: "",
    pan_number: "",
    description: "",
    company_image_path: "",
    paper_work_pdf_path: "",
    school_regd_file_path: "",
    latitude: "",
    longitude: "",
    types: "corporate",
  },
}) {
  const { token, isLoading } = useChkLogin();
  const [submitting, setSubmitting] = useState(false);
  const [inputs, setInputs] = useState(state);
  const [modal, setModal] = useState({ open: false, title: "", message: "" });
  const [mapOpen, setMapOpen] = useState(false);
  const [latitude, setLatitude] = useState(inputs?.latitude);
  const [longitude, setLongitude] = useState(inputs?.longitude);

  useEffect(() => {
    setLatitude(inputs?.latitude);
    setLongitude(inputs?.longitude);
  }, [inputs?.latitude, inputs?.longitude]);

  console.log(latitude, longitude);

  const { data: provinceOptions } = useQuery({
    queryKey: ["province"],
    queryFn: async () => axios.get("api/provinces"),
    onError: (err) => {
      toast.error("Failed to fetch provinces");
    },
  });

  const { data: DistrictOptions } = useQuery({
    queryKey: ["district", inputs?.province_id],
    queryFn: async () => axios.get(`api/districts/${inputs?.province_id}`),
    enabled: Boolean(inputs?.province_id),
    onError: (err) => {
      toast.error("Failed to fetch districts");
    },
  });

  const { data: municipalitiesOptions } = useQuery({
    queryKey: ["municipalities", inputs?.district_id],
    queryFn: async () => axios.get(`api/municipalities/${inputs?.district_id}`),
    enabled: Boolean(inputs?.district_id),
    onError: (err) => {
      toast.error("Failed to fetch municipalities");
    },
  });

  //TODO: EXTRACT THIS COMPONENT IN THE NEW FOLDER
  const MessageModal = ({ open, onClose, title, message }) => {
    const borderColor = title === "Updated Successfully" ? "green" : "red";

    const handleClick = () => {
      if (borderColor === "green") {
        window.location.href = "./";
      } else {
        onClose();
      }
    };

    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
            border: `1px solid ${borderColor}`,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography
            id="modal-description"
            sx={{ mt: 2, textTransform: "capitalize" }}
          >
            {message}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    );
  };

  // GETTING VALUE FOR THE FORM
  useEffect(() => {
    const fetchProfileData = async () => {
      if (!token) return;

      try {
        const data = await getCompanyProfile(token);
        const {
          company_image,
          school_regd_file,
          paper_work_pdf,
          ...filteredData
        } = data?.data?.school_profile || {};
        setInputs(filteredData);
      } catch (err) {
        console.log(err);
      } finally {
      }
    };

    fetchProfileData();
  }, [token]);

  if (isLoading) {
    return (
      <Box p={10} display="flex" justifyContent="center">
        <Loader />
      </Box>
    );
  }

  //HANDLES FILE CHANGES
  function handleFile(inputFile, name) {
    setInputs((prevInputs) => ({ ...prevInputs, [name]: inputFile }));
  }

  function handleChange(e) {
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  function handleLatLng(lat, lng) {
    setInputs((prev) => ({ ...prev, latitude: lat, longitude: lng }));
  }

  //SUBMITTING THE FORM
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const formData = new FormData();

      // Append all inputs to FormData, including files and other data
      Object.entries(inputs).forEach(([key, value]) => {
        if (value instanceof File) {
          // If the value is a file, append it directly as binary data
          formData.append(key, value);
        } else {
          // Otherwise, append the regular input data
          formData.append(key, value);
        }
      });

      if (inputs.reject_reason) {
        formData.set("status", "rejected");
      }

      await submitCompanyProfile(formData, token);

      // Set success modal
      setModal({
        open: true,
        title: "Updated Successfully",
        message: "The form has been updated successfully",
      });
    } catch (error) {
      let errorMessage = "An error occurred while updating";
      if (error?.response?.data?.message === "The given data was invalid.") {
        const validationErrors = error?.response?.data?.errors || {};
        errorMessage = Object.entries(validationErrors)
          .map(([key, value]) => `${value[0]}`)
          .join(" ");
      } else {
        errorMessage =
          error?.response?.data?.message || "An error occurred while updating";
      }
      // Set error modal
      setModal({
        open: true,
        title: "Update Failed",
        message: errorMessage,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseModal = () => setModal({ ...modal, open: false });

  function setAddressText(addr) {
    console.log(addr);
    setInputs({ ...inputs, company_address: addr });
  }

  return (
    <div className="container my-5">
      <form className="form-horizontal" onSubmit={handleSubmit}>
        <h3 className="m-0 p-0 mb-2">School Information</h3>
        <div className="card">
          <div className="card-body">
            <div className="row g-3">
              <div className="col-md-4">
                <InputField
                  label="Owner Name"
                  name="owner_name"
                  value={inputs.owner_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <InputField
                  label="School Name"
                  name="company_name"
                  value={inputs.company_name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-md-4">
                <NepaliDateInput
                  label="School Start Date"
                  name="company_start_date"
                  value={inputs.company_start_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <InputField
                    label="Contact Number"
                    name="contact_number"
                    type="phone"
                    value={inputs.contact_number}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-4">
                <InputField
                  label="PAN Number"
                  type="number"
                  name="pan_number"
                  value={inputs.pan_number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <InputField
                  label="Landline"
                  type="phone"
                  name="landline_number"
                  value={inputs.landline_number}
                  onChange={handleChange}
                />
              </div>
              <div className=" d-flex flex-column col-md-4 mb-2">
                <label for="province_id">
                  Select Province : <code>*</code>
                </label>
                <select
                  name="province_id"
                  className="form-select input-field"
                  onChange={handleChange}
                  value={inputs.province_id}
                  required
                >
                  <option value="" disabled selected hidden>
                    Choose Province...
                  </option>
                  {provinceOptions?.data?.data?.provinces &&
                    Object.keys(provinceOptions?.data?.data?.provinces).map(
                      (key) => {
                        return (
                          <option value={key}>
                            {provinceOptions?.data?.data?.provinces[key]}{" "}
                          </option>
                        );
                      }
                    )}
                </select>
              </div>

              {inputs.province_id && (
                <div className=" d-flex mb-2 flex-column col-md-4">
                  <label for="district_id">
                    Select District : <code>*</code>
                  </label>

                  <select
                    name="district_id"
                    className="form-select input-field"
                    onChange={handleChange}
                    value={inputs.district_id}
                    required
                  >
                    <option value="" disabled selected hidden>
                      Choose District...
                    </option>
                    {DistrictOptions?.data?.data?.districts &&
                      Object.keys(DistrictOptions?.data?.data?.districts).map(
                        (key) => {
                          return (
                            <option value={key}>
                              {DistrictOptions?.data?.data?.districts[key]}{" "}
                            </option>
                          );
                        }
                      )}
                  </select>
                </div>
              )}

              {inputs.district_id && (
                <div className="d-flex mb-2 flex-column col-md-4">
                  <label htmlFor="municipality_id">
                    Select Municipality: <code>*</code>
                  </label>

                  <select
                    name="municipality_id"
                    className="form-select input-field"
                    onChange={handleChange}
                    value={inputs.municipality_id}
                    required
                  >
                    <option value="" disabled selected hidden>
                      Choose Municipality...
                    </option>
                    {municipalitiesOptions?.data?.data?.municipality?.map(
                      (municipality) => (
                        <option key={municipality.id} value={municipality.id}>
                          {municipality.english_name}
                        </option>
                      )
                    )}
                  </select>
                </div>
              )}

              {inputs.municipality_id && (
                <div className=" col-md-4">
                  <InputField
                    label="Ward Number"
                    type="number"
                    name="ward_no"
                    value={inputs.ward_no}
                    onChange={handleChange}
                    required
                    placeholder="Select Ward Number (1-99)"
                  />
                </div>
              )}

              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    School Description<code>*</code>
                  </label>
                  <textarea
                    className="form-control mt-2"
                    rows="4"
                    name="description"
                    onChange={handleChange}
                    value={inputs.description}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h3 className="mt-4 mb-2 p-0">Document Upload</h3>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <UploadFile
                  name="company_image"
                  type={"img"}
                  label="School Image"
                  value={[inputs.company_image_path]}
                  setImageInput={(fileG) =>
                    handleFile(fileG[0], "company_image")
                  }
                />
              </div>

              <div className="col-md-4">
                <UploadFile
                  name="paper_work_pdf"
                  type={"pdf"}
                  accept={"application/pdf,application/vnd.ms-excel"}
                  label="Paper Work Pdf"
                  value={[inputs.paper_work_pdf_path]}
                  setImageInput={(fileG) => {
                    handleFile(fileG[0], "paper_work_pdf");
                  }}
                />
              </div>

              <div className="col-md-4">
                <UploadFile
                  name="school_regd_file"
                  type={"pdf"}
                  accept={"application/pdf,application/vnd.ms-excel"}
                  label="School Registration File"
                  value={[inputs.school_regd_file_path]}
                  setImageInput={(fileG) => {
                    handleFile(fileG[0], "school_regd_file");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <h3 className="mt-4 mb-2">School Addresss</h3>

        <div>
          <Box>
            <LeafletMap
              height="300px"
              aspectRatio="1.5/1"
              initPos={{ lat: latitude, lng: longitude }}
            />
          </Box>

          <div className="cursor-pointer update-map">
            <div onClick={() => setMapOpen(true)}>Update Map</div>
          </div>

          <AddressModal
            open={mapOpen}
            handleClose={() => setMapOpen(false)}
            setLatLong={handleLatLng}
            lat={inputs.latitude}
            lng={inputs.longitude}
            setAddress={setAddressText}
          />
        </div>
        <div className="mt-4">
          <button className="btn btn-primary" type="submit">
            Submit form
          </button>
        </div>
      </form>

      <MessageModal
        open={modal.open}
        onClose={handleCloseModal}
        title={modal.title}
        message={modal.message}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitting}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default CompanyProfileForm;
