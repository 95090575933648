import {Card, CardContent, CardMedia, Rating, Typography} from '@mui/material'
import React from 'react'


// const prodcardSlider = {
//     responsive: [
//         {
//             breakpoint: 800,
//             settings: {
//                 slidesToShow: 3,

//             }
//         },
//         {
//             breakpoint: 600,
//             settings: {
//                 slidesToShow: 2
//             }
//         },
//         {
//             breakpoint:1150,
//             settings: {
//                 slidesToShow: 4,
//             }
//         },
//         {
//             breakpoint:340,
//             settings: {
//                 slidesToShow: 1,
//             }
//         }
//     ]
// }

function FitnessProdCard({img = 'https://beta.apinouthemes.com/uploads/ff63a2eb476e45b1bfef05ee79115018.jpg'}) {
    return (
        <Card sx={{width: "230px"}}>
            <CardMedia
                component={'img'}
                image={img}
                height="200"
                alt="Product Item 1"
            />
            <CardContent>
                <Typography color="text.primary">
                    Product Long Name - short detail
                </Typography>
                <Rating size='small' value={5} readOnly/>
                <Typography color="text.secondary">
                    NPR. 3,499
                </Typography>
            </CardContent>
        </Card>
    )
}

export default FitnessProdCard
