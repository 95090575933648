import {ClosedCaption, HighlightOffOutlined} from '@mui/icons-material';
import {Backdrop, Box, Button, CircularProgress, IconButton, Modal, Stack, Typography} from '@mui/material';
import React, {useEffect} from 'react'
import {useKhalti} from '../khalti/useKhalti';
import axios from '../../api'
import Khalti from '../../../images/payment/khalti.png'
import Esewa from '../../../images/payment/esewa.png'
import swal from 'sweetalert';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function paymentApi(data, id, token) {
    console.log()
    axios.post(`/api/admin/lab-test/payment`, {
        amount: data.amount,
        id: id
    }, {headers: {'Authorization': `Bearer ${token}`}}).then(res => swal('Payment', 'Payment Success', 'success')).catch(err => swal('Payment', 'Payment Error', 'error'))
}

function PaymentModal({open, handleClose, data, setVerifying}) {
    const {checkoutAmount, verifying} = useKhalti(data.price, data.id, paymentApi)
    const btnStyle = {
        height: '70px',
        '& img': {
            height: '100%'
        }
    }
    useEffect(() => {
        setVerifying(verifying)
    }, [verifying])
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{...style, width: 500}}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='h6'>Payment Method</Typography>
                        <IconButton>
                            <HighlightOffOutlined color='error'/>
                        </IconButton>
                    </Stack>
                    <Button sx={btnStyle} onClick={checkoutAmount}>
                        <img src={Khalti} alt=""/>
                    </Button>
                    <Button sx={btnStyle}>
                        <img src={Esewa} alt=""/>
                    </Button>
                </Box>


            </Modal>

        </>
    )
}

export default PaymentModal
