import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Tab, Tabs} from '@mui/material'
import React from 'react'
import {useState} from 'react';
import TabPanel from '../../../TabPanel';
import DocBookNotify from './DocBookNotify';
import DriverNotify from './DriverNotify';
import NewsNotifcation from './NewsNotifcation';
import './Notification.scss'
import NurseBookNotify from './NurseBookNotify';
import OrderNotification from './OrderNotification';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Notification() {
    const [value, setValue] = useState(0)

    const handleChange = (e, newVal) => {
        setValue(newVal)
    }

    return (
        <div className="notification-page">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Doctor Bookings" {...a11yProps(0)} />
                                    <Tab label="Order Notification" {...a11yProps(1)} />
                                    <Tab label="Driver Bookings" {...a11yProps(2)} />
                                    <Tab label="News Notification" {...a11yProps(3)} />
                                    <Tab label="Nurse Notification" {...a11yProps(4)} />

                                </Tabs>
                            </Box>
                        </div>
                        <div className="card-body">
                            <TabPanel value={value} index={0}>
                                <DocBookNotify/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <OrderNotification/>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <DriverNotify/>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <NewsNotifcation/>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <NurseBookNotify/>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notification
