import React from "react";

import { Box, Grid } from "@mui/material";
import ContentContainer from "./components/ContentContainer";

import HomePosts from "./HomePosts";
import LatestBlogs from "./LatestBlogs";
import PopularPosts from "./PopularPosts";

function GDBlogs() {
  return (
    <Box className="container">
      {/* <GDBlogHeader/> */}

      <ContentContainer header="Trending Posts">
        <HomePosts />
      </ContentContainer>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <ContentContainer header="Recent Blogs and Posts">
            <LatestBlogs />
          </ContentContainer>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ContentContainer header="Popular Posts">
            <PopularPosts />
          </ContentContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GDBlogs;
