import React, {createContext, useState} from 'react'
import Insurance from './Insurance'
import axios from "../../../api"
import Loader from '../../Loader'
import {useQuery} from '@tanstack/react-query'
import {useContext} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import PackageStatus from '../packageStatuses/PackageStatus'
import {Link, useNavigate} from 'react-router-dom'
import InsuranceDet from './InsuranceDet'

export const InsuranceContext = createContext(null)

function InsuranceComponent({children}) {
    const loginContext = useContext(LoginStateContext)
    const userData = loginContext.state.data
    const navigate = useNavigate()
    const [insuranceErr, setInsuranceErr] = useState('')

    const packageData = useQuery(
        ['package'],
        async () => (
            axios.get('api/admin/userpackage', {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => res.data)
        )
    )

    const pg = packageData?.data?.package

    const myinsurance = useQuery(
        ['insurance-details-data', userData.id],
        async () => {
            return axios.get('/api/admin/insurance-details/insurance', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )
    const myInsuranceStatus = useQuery(
        ['insurance_status', loginContext.state.token],
        async () => axios.get(`api/insurance`, {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => res.data).catch(err => {
            console.log(err)
            setInsuranceErr(err.response.data.message.error)
        })
    )
    if (myinsurance.isLoading || myInsuranceStatus.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }
    const myinsurancedetails = myinsurance.data;
    // console.log('insurance error', myInsuranceStatus.error)

    // return
    // if(myInsuranceStatus.)

    return (
        <InsuranceContext.Provider
            value={{
                myinsurancedetails,
                pg
            }}
        >
            {
                JSON.stringify(myinsurancedetails) === '{}' ?
                    <PackageStatus data={pg}/>
                    :
                    <>
                        {
                            myInsuranceStatus.isError ?
                                <div className="alert alert-danger">
                                    <p className='mb-0'><b>{insuranceErr}</b></p>
                                </div>
                                :
                                <>
                                    {myInsuranceStatus.data.insurance.status === 0 &&
                                        <div className="alert alert-danger">
                                            <p className='mb-0'><b>Insurance Activation Pending.</b></p>
                                        </div>
                                    }
                                    {myInsuranceStatus.data.insurance.status === 2 &&
                                        <div className="alert alert-danger">
                                            <p className='mb-0'><b>Your insurance has been frozen. Please pay for your
                                                subscription package to activate your insurance.</b></p>
                                        </div>
                                    }
                                    {
                                        myInsuranceStatus.data.insurance.status === 1 &&
                                        <InsuranceDet/>
                                    }
                                </>
                        }

                    </>
            }
        </InsuranceContext.Provider>
    )
}

export default InsuranceComponent
