import React, { useState } from "react";
import axios from "../../api";
import { Backdrop, Box, CircularProgress, Modal } from "@mui/material";
import LeafletMap from "../../leafletMap/LeafletMap";
import "./map-modal.scss";

function AddressModal({ open, handleClose, setLatLong, lat, lng, setAddress }) {
  const [loading, setLoading] = useState(false);

  console.log(lat, lng);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 800,
    width: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  async function handleAddress(lat, lng) {
    setLoading(true);
    let result = await axios.get(
      `https://api.geoapify.com/v1/geocode/reverse?apiKey=68725a46c50b4690bf4c6d41efde7963&lat=${lat}&lon=${lng}`
    );
    let location = result.data.features[0].properties;
    setAddress(`${location.address_line1} ${location.city}`);
    setLatLong(lat, lng);
    setLoading(false);
    handleClose();
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <MapInput setLatLong={setLatLong} lat={lat} lng={lng} handleAddress={handleAddress} /> */}
          <LeafletMap
            aspectRatio={"1/1"}
            autoComplete
            getLatLng={(latlng) => {
              handleAddress(latlng.lat, latlng.lng);
            }}
            initPos={{ lat: lat, lng: lng }}
          />
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default AddressModal;
