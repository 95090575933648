import { Skeleton } from "@mui/material";
import React from "react";

function PackageSkeleton({ theme }) {
  return (
    <div className={theme !== "white" ? "package-page" : ""}>
      <div className="container">
        <div className="package-header text-center pt-5">
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
        </div>

        <div className="d-flex w-100 justify-content-end mb-4">
          <Skeleton variant="rectangular" width={100} height={60} />
        </div>

        <div className="packages-list">
          <div className="row g-3 pb-5">
            {[...Array(6).keys()].map((pg) => {
              return (
                <div className="col-12 col-md-6 col-lg-4" key={pg}>
                  {/* <PackageItem data={pg} /> */}
                  <Skeleton variant="rectangular" width="100%" height="500px" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageSkeleton;
