import {Box, Stack, Typography} from '@mui/material'
import React from 'react'

function TermsAndConditions() {
    return (
        <Box>
            <Box py={1} boxShadow={2}>
                <Box className="container" display='flex' justifyContent='space-between' alignItems={'center'}>
                    <Typography variant='h4'>Terms and Conditions</Typography>
                    <Typography variant='subtitle1'>Last Updated on <Typography component={'span'} fontWeight='55 0'>Jan
                        12 1997</Typography></Typography>
                </Box>
            </Box>
            <Box className="container">
                <Box display='flex' gap={2} sx={{'& > div': {py: 2}}}>
                    <Box className='side-nav-progress' px={2} width='350px' position='sticky' top={'80px'}
                         alignSelf='start'>
                        <Stack gap={3}>
                            <SideProgressItem index={1} value={'Introduction'} active/>
                            <SideProgressItem index={2} value={'General Terms of service'}/>
                            <SideProgressItem index={3} value={'Using Cheklt'}/>
                            <SideProgressItem index={4} value={'Cancellation Policy'}/>
                            <SideProgressItem index={5} value={'Privacy Policy'}/>
                        </Stack>
                    </Box>
                    <Box flexGrow={1}>
                        <Stack gap={4}>
                            <ProgressItem title='Introduction' index={1} active>
                                <DetailItem title='Age Restrictions' point='a'>
                                    You must be above the age of 12 years old when using ghargharmadoctor. anyone found
                                    to be in violation ofthis rule will pay a fine of $100 or more, as it is a form of
                                    forgery.
                                </DetailItem>
                                <DetailItem title='Age Restrictions' point='a'>
                                    You must be above the age of 12 years old when using ghargharmadoctor. anyone found
                                    to be in violation ofthis rule will pay a fine of $100 or more, as it is a form of
                                    forgery.
                                </DetailItem>
                                <DetailItem title='Age Restrictions' point='a'>
                                    You must be above the age of 12 years old when using ghargharmadoctor. anyone found
                                    to be in violation ofthis rule will pay a fine of $100 or more, as it is a form of
                                    forgery.
                                </DetailItem>
                                <DetailItem title='Age Restrictions' point='a'>
                                    You must be above the age of 12 years old when using ghargharmadoctor. anyone found
                                    to be in violation ofthis rule will pay a fine of $100 or more, as it is a form of
                                    forgery.
                                </DetailItem>
                            </ProgressItem>
                            <ProgressItem title='General Terms of Service' index={2}>
                                <DetailItem title='Age Restrictions' point='a'>
                                    You must be above the age of 12 years old when using ghargharmadoctor. anyone found
                                    to be in violation ofthis rule will pay a fine of $100 or more, as it is a form of
                                    forgery.
                                </DetailItem>
                            </ProgressItem>
                            <ProgressItem title='Using Ghargharmadocto' index={1}>
                                <DetailItem title='Age Restrictions' point='a'>
                                    You must be above the age of 12 years old when using ghargharmadoctor. anyone found
                                    to be in violation ofthis rule will pay a fine of $100 or more, as it is a form of
                                    forgery.
                                </DetailItem>
                                <DetailItem title='Age Restrictions' point='a'>
                                    You must be above the age of 12 years old when using ghargharmadoctor. anyone found
                                    to be in violation ofthis rule will pay a fine of $100 or more, as it is a form of
                                    forgery.
                                </DetailItem>
                                <DetailItem title='Age Restrictions' point='a'>
                                    You must be above the age of 12 years old when using ghargharmadoctor. anyone found
                                    to be in violation ofthis rule will pay a fine of $100 or more, as it is a form of
                                    forgery.
                                </DetailItem>
                                <DetailItem title='Age Restrictions' point='a'>
                                    You must be above the age of 12 years old when using ghargharmadoctor. anyone found
                                    to be in violation ofthis rule will pay a fine of $100 or more, as it is a form of
                                    forgery.
                                </DetailItem>
                            </ProgressItem>
                            <ProgressItem title='Cancellation Policy' index={1}>
                                <DetailItem title='Age Restrictions' point='a'>
                                    You must be above the age of 12 years old when using ghargharmadoctor. anyone found
                                    to be in violation ofthis rule will pay a fine of $100 or more, as it is a form of
                                    forgery.
                                </DetailItem>
                            </ProgressItem>
                            <ProgressItem title='Privacy Policy' index={1}>
                                <DetailItem title='Age Restrictions' point='a'>
                                    You must be above the age of 12 years old when using ghargharmadoctor. anyone found
                                    to be in violation ofthis rule will pay a fine of $100 or more, as it is a form of
                                    forgery.
                                </DetailItem>
                            </ProgressItem>
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

function SideProgressItem({index, value, active = false, children}) {
    const activeProps = {
        color: 'green'
    }
    return (
        <Box textAlign='left'>
            <Typography lineHeight={1.1} fontWeight={600} variant='h5' {...(active && {...activeProps})}>
                {index}. {value}
            </Typography>
        </Box>
    )
}

function ProgressItem({index, title, active, children}) {
    const activeProps = {
        color: 'green'
    }
    return (
        <Stack gap={2}>
            <Typography variant='h3' {...(active && {...activeProps})}>{index}. {title}</Typography>
            {children}
        </Stack>
    )
}

function DetailItem({title, point, children}) {
    return (
        <Stack gap={1}>
            <Typography variant='h6' fontWeight={550}>
                {point}. {title}
            </Typography>
            <Box>
                {children}
            </Box>
        </Stack>
    )
}

export default TermsAndConditions
