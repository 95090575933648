import {
    AccessTimeOutlined,
    FacebookOutlined,
    LinkedIn,
    LocationOnOutlined,
    Twitter,
    WhatsApp
} from '@mui/icons-material'
import {Box, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, Stack, Typography} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import parse from 'html-react-parser'
import DOMPurify from 'dompurify'
import React from 'react'
import {useParams} from 'react-router-dom'
import axios from '../../api'
import {DateFormat} from '../../DateFormat'
import Loader from '../Loader'
import MoreJobs from './MoreJobs'

function VacancyDetail() {
    const slug = useParams().params
    const {data, isLoading, isError} = useQuery(
        ['vacancy_detail', slug],
        async () => (
            axios.get(`/api/vacancy?slug=${slug}`).then(res => res.data).catch(err => err)
        )
    )
    if (isLoading) {
        return (
            <Box flexGrow={1} display='flex' justifyContent='center' alig width='100%' height='100%'>
                <Loader/>
            </Box>
        )
    }

    let vacancy = data
    const stringToHTML = function (str) {
        return parse(DOMPurify.sanitize(str))
    };

    function applyVacancy() {
        window.open(vacancy.form_link, '_blank');
    }

    return (
        <>
            <Box
                height='400px'
                color='white'
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{
                    background: 'radial-gradient(circle, rgba(36,105,176,0.8932365182401085) 27%, rgba(0,212,255,0.4) 100%), url(https://images.wallpaperscraft.com/image/single/office_design_desks_80537_2560x1024.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                    backgroundPosition: 'center'
                }}>
                <Stack gap={3} alignItems='center'>
                    <Typography variant='h3' textAlign='center' fontWeight={700}>{vacancy.job_title}</Typography>
                    <Box display='flex' gap={2}>
                        <Box bgcolor='divider' p={0.5} px={1} display='flex' alignItems='center' gap={1}
                             borderRadius={1}>
                            <LocationOnOutlined sx={{fontSize: '16px'}}/>
                            <Typography fontWeight={600}>Kathmandu, Nepal</Typography>
                        </Box>
                        <Box bgcolor='divider' p={0.5} px={1} display='flex' alignItems='center' gap={1}
                             borderRadius={1}>
                            <AccessTimeOutlined sx={{fontSize: '16px'}}/>
                            <Typography fontWeight={600}>{vacancy.job_type}</Typography>
                        </Box>
                    </Box>
                </Stack>
            </Box>

            {/* <Box py={10}>
                <Box width='100%' maxWidth={'900px'} px={1} margin='auto'>
                    <Typography variant='subtitle1' fontSize={18}>
                        {vacancy.job_description}
                    </Typography>
                </Box>
            </Box> */}
            <Box py={10} bgcolor='#F8F9FA'>
                <Box className='container-lg'>
                    <Grid container sx={{flexDirection: {xs: 'column-reverse', md: 'row'}}}>
                        <Grid item xs={12}>
                            <Box mt={4} display={{xs: 'block', md: 'none'}}>
                                <Typography variant='subtitle1' fontWeight={600} color='#589EEC'>MORE JOBS</Typography>
                                <Box textAlign='left'>
                                    <MoreJobs/>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={8} sx={{mt: {xs: 4, sm: 0}}}>
                            <Stack gap={4} sx={{borderRight: 1, borderColor: 'divider', px: 2}}>
                                <Typography variant='h4' fontWeight={600}
                                            sx={{display: {xs: 'none', md: 'block'}}}>{vacancy.job_title}</Typography>
                                {/* <DescContainer title={'Job Description'}>
                                    <Typography variant='subtitle1'>
                                        {vacancy.job_description}
                                    </Typography>
                                </DescContainer> */}
                                <DescContainer title={'Key Skills'}>
                                    <Box display='flex' gap={1} flexWrap={'wrap'}>
                                        {
                                            vacancy.vacancyskill.map((item, idx) => (
                                                <Box bgcolor={'#f2f3f3'} borderRadius={2} border={1}
                                                     borderColor={'divider'} px={0.5}>
                                                    <Typography variant='subtitle1'
                                                                fontSize='14px'>{item.skill.skill}</Typography>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </DescContainer>
                                <DescContainer title={'Job Responsibility'}>
                                    <Typography variant='subtitle1'>
                                        {stringToHTML(vacancy.job_responsibility)}
                                    </Typography>
                                </DescContainer>
                                <DescContainer title={'Job Requirements'}>
                                    <Typography variant='subtitle1'>
                                        {stringToHTML(vacancy.job_requirements)}
                                    </Typography>
                                </DescContainer>
                                <Box display='flex' justifyContent='center' onClick={applyVacancy}>
                                    <Button variant='contained'>
                                        Apply For This Job
                                    </Button>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4} px={2}>
                            <Stack gap={2}>
                                <Box>
                                    <Typography variant='subtitle1' fontWeight={600} color='#589EEC'>JOB
                                        OVERVIEW</Typography>
                                    <Box border={1} borderColor='divider' p={2} textAlign='left'>
                                        <table class="mb-0 table border table-striped">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    Job Title
                                                </td>
                                                <td>
                                                    {vacancy.job_title}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Openings
                                                </td>
                                                <td>
                                                    {vacancy.no_of_vacancy}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Position Type
                                                </td>
                                                <td>
                                                    {vacancy.job_type}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Experience
                                                </td>
                                                <td>
                                                    {vacancy.experience}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Education
                                                </td>
                                                <td>
                                                    {vacancy.education_level}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Job Location</td>
                                                <td>{vacancy.job_location}</td>
                                            </tr>
                                            <tr>
                                                <td>Job Salary</td>
                                                <td>{vacancy.salary === 'Negotiable' ? 'Negotiable' : vacancy.salary_range}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Posted Date
                                                </td>
                                                <td>
                                                    {DateFormat(vacancy.created_at).getFullDateText()}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Apply Before
                                                </td>
                                                <td>
                                                    {DateFormat(vacancy.job_deadline).getFullDateText()}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Box>
                                </Box>
                                <Button className='w-100' variant='contained' sx={{display: {xs: 'none', md: 'block'}}}
                                        onClick={applyVacancy}>
                                    Apply For This Job
                                </Button>
                                {/* <Box>
                                    <Typography variant='subtitle1' fontWeight={600} color='#589EEC'>SHARE THIS JOB</Typography>
                                    <Box border={1} borderColor='divider'>
                                        <Box display='flex' justifyContent='center'>
                                            <IconButton>
                                                <FacebookOutlined sx={{ fontSize: '50px', color: '#139BF7' }} />
                                            </IconButton>
                                            <IconButton>
                                                <Twitter sx={{ fontSize: '50px', color: '#1DA1F2' }} />
                                            </IconButton>
                                            <IconButton>
                                                <LinkedIn sx={{ fontSize: '50px', color: '#0077B5' }} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box> */}
                                <Box display={{xs: 'none', md: 'block'}}>
                                    <Typography variant='subtitle1' fontWeight={600} color='#589EEC'>MORE
                                        JOBS</Typography>
                                    <Box textAlign='left'>
                                        <MoreJobs/>
                                    </Box>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

function DescContainer({children, title}) {
    return (
        <Card elevation={0} sx={{background: 'inherit', textAlign: 'left'}}>
            <CardHeader sx={{p: '0 !important', m: '0', mb: 1}}
                        title={<Typography variant='h5' fontWeight={600} color='#589EEC'>{title}</Typography>}/>
            <CardContent sx={{p: '0 !important', m: '0 !important'}}>
                {
                    children
                }
            </CardContent>
        </Card>
    )
}

export default VacancyDetail
