import {Box, Card, CardContent, CardHeader, Grid, Typography} from '@mui/material'
import React, {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {LabTestContext} from './LabTestProvider'

function ChooseDepartment() {
    const {departments} = useContext(LabTestContext)
    const navigate = useNavigate()
    return (
        <Box>
            <Card variant='outlined'>
                <CardHeader
                    title="Specialitites"
                    subheader="We offer a comprehensive range of diagnostic pathology services for clinicians."
                />
            </Card>
            <Grid container my={1} spacing={2}>
                {
                    departments.map((data, idx) => (
                        <Grid item xs={3} key={idx}>
                            <Card sx={{
                                background: 'radial-gradient(circle, rgba(13,89,168,1) 0%, rgba(82,200,244,1) 100%)',
                                color: 'white',
                                textAlign: 'center',
                                cursor: 'pointer'
                            }} onClick={() => navigate(`/lab-tests/${data.id}`)}>
                                <CardContent>
                                    <Typography variant="h6">
                                        {data.department}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    )
}

export default ChooseDepartment
